import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types";
import { LightgalleryProvider } from "react-lightgallery";
import { setGallery, setArrow } from '../../../modules/media/gallery'
import NoSearchResult from '../../common/NoSearchResult'

import "lightgallery.js/dist/css/lightgallery.css";
import "lg-autoplay.js";

const ArticleList = ({ articles, itemComponent, type, className, onNextPage, onPrevPage }) => {

  const dispatch = useDispatch()

  const ItemComponent = itemComponent;
  const listType = type || "grid";

  console.log("ArticleList", { articles });

  const [seq, setSeq] = React.useState(articles && articles.length > 0 ? articles[0].artcSeq : 0)
  const isGallery = useSelector(state => state.gallery.isGallery)
  const arrow = useSelector(state => state.gallery.arrow)
  const [galleryActive, setGalleryActive] = React.useState(isGallery)
  const [goArrow, setGoArrow] = React.useState(arrow)

  React.useEffect(() => {
    return () => {
      dispatch(setGallery(galleryActive))
      dispatch(setArrow(goArrow))
    }
  })


  React.useEffect(() => {
    (articles && articles.length > 0) && setSeq(articles[0].artcSeq);
  }, [articles])


  React.useEffect(() => {
    console.log('galleryActive', galleryActive)
  }, [galleryActive])

  React.useEffect(() => {
    console.log('goArrow', goArrow)
  }, [goArrow])


  const itemsRef = useCallback(node => {
    console.log('node', node)
    if (node !== null) {
      if (isGallery) {
        console.log('isGallery : ', isGallery)
        console.log('goArrow : ', goArrow)
      }
    }
  }, [isGallery, goArrow]);

  return (
    <>
      {(articles && articles.length > 0)
        ?
        <>
          <div className={`board_list ${listType}_view ${className}`}>
            <LightgalleryProvider
              key={seq}
              ref={itemsRef}
              lightgallerySettings={{
                // mode: 'lg-fade',
                cssEasing: "ease-out",
                speed: 300,
                download: false,
                actualSize: false,
                thumbnail: true,
                showThumbByDefault: false,
                autoplay: false,
                autoplayControls: true,
                // loop: true
              }}
              onBeforePrevSlide={e => {
                setGoArrow('prev')
              }}
              onBeforeNextSlide={e => {
                setGoArrow('next')
              }}
              onAfterOpen={e => {
                setGalleryActive(true)
              }}
              onCloseAfter={e => {
                setGalleryActive(false)
                console.log('onCloseAfter')
              }}
              onBeforeSlide={e => {
                console.log('prevIndex', e.detail.prevIndex, 'index', e.detail.index)
                if (e.detail.prevIndex === 11 && e.detail.index === 0) {
                  onNextPage();
                }
                if (e.detail.prevIndex === 0 && e.detail.index === 11) {
                  onPrevPage();
                }
              }}
            >
              <ul>
                {articles && articles.map((article, index) => <ItemComponent key={index} article={article} index={index} isGallery={isGallery} goArrow={goArrow} />)}
              </ul>
            </LightgalleryProvider>
          </div>
        </>
        :
        <>
          <NoSearchResult />
        </>
      }
    </>
  );
};

ArticleList.propTypes = {
  articles: PropTypes.array,
  itemComponent: PropTypes.func,
  type: PropTypes.oneOf(["list", "grid"])
};

export default ArticleList;
