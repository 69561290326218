import React from 'react'
import ContentTemplate from '../../components/common/ContentTemplate'
import SubContentTemplate from '../../components/common/SubContentTemplate'
import PageHeaderContainer from '../../containers/common/PageHeaderContainer'
import PageTitleTemplate from '../../components/common/PageTitleTemplate'
import BreadcrumbContainer from '../../containers/common/BreadcrumbContainer'
import PageTemplate from '../../components/common/PageTemplate'
import { FUTURES_LEAGUE_SCHEDULE, FUTURES_LEAGUE_TAB } from '../../constants/page'
import FuturesLeagueGameScheduleContainer from '../../containers/game/FuturesLeagueGameScheduleContainer'

const FuturesLeaguePitcherStatPage = ({ match }) => {
  return (
    <PageTemplate className="game">
      <PageHeaderContainer title="퓨처스 리그" subtitle="kt wiz의 퓨처스리그 경기 일정을 알려 드립니다." image={require('../../assets/img/game/sub_tit_game.png')} forwardTabs={FUTURES_LEAGUE_TAB} currentPath={match.path} />
      <ContentTemplate className="game_schedule">
        <PageTitleTemplate>
          <BreadcrumbContainer page={FUTURES_LEAGUE_SCHEDULE} />
        </PageTitleTemplate>
        <SubContentTemplate>
          <FuturesLeagueGameScheduleContainer />
        </SubContentTemplate>
      </ContentTemplate>
    </PageTemplate>
  )
}

export default FuturesLeaguePitcherStatPage
