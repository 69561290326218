// action types
export const WIZ_DATA_REQUEST = 'wiz/WIZ_DATA_REQUEST'
export const WIZ_DATA_SUCCESS = 'wiz/WIZ_DATA_SUCCESS'
export const WIZ_DATA_FAILURE = 'wiz/WIZ_DATA_FAILURE'
export const WIZ_DATA_CANCEL = 'wiz/WIZ_DATA_CANCEL'
export const WIZ_DATA_CLEAR = 'wiz/WIZ_DATA_CLEAR'

// action creators
// req { api, params, dataType }
// 예를 들어  api/v2/article/listByCategory?article.boardCode=001&search.sort=200&search.searchWord=야구
// api : [api/v2]/article/listByCategory
// params: { 'article.boardCode': '001', 'search.sort': '200', 'search.searchWord': '야구' }
// dataType: 'news'
export const dataRequest = (api, params, dataType) => ({ type: WIZ_DATA_REQUEST, req: { api, params, type: dataType } })
export const dataSuccess = (data, dataType) => ({ type: WIZ_DATA_SUCCESS, payload: { data, type: dataType } })
export const dataFailure = (error, dataType) => ({ type: WIZ_DATA_FAILURE, payload: { error, type: dataType } })
export const dataCancel = (dataType) => ({ type: WIZ_DATA_CANCEL, payload: { type: dataType } })
export const dataClear = (dataType) => ({ type: WIZ_DATA_CLEAR, payload: { type: dataType } })


// reducers
const initialState = {
  data: {},
  error: {},
  loading: {},
}

export default (state = initialState, action) => {
  const { data, error, type } = action.payload || {}
  switch (action.type) {
    case WIZ_DATA_REQUEST:
      return {
        ...state,
        error: { ...state.error, [type]: null },
        loading: { ...state.loading, [type]: true },
      }
    case WIZ_DATA_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, [type]: false },
        data: { ...state.data, [type]: data },
      }
    case WIZ_DATA_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [type]: false },
        error: { ...state.error, [type]: error },
      }
    case WIZ_DATA_CLEAR:
      return {
        ...state,
        loading: { ...state.loading, [type]: null },
        error: { ...state.error, [type]: null },
        data: { ...state.data, [type]: null }
      }
    default:
      return state
  }
}
