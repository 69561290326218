import React from "react";
import Link from "../common/Link";
import { useHistory } from "react-router-dom";

const SiteMap = ({ onClose }) => {
  const history = useHistory();

  const go = (link) => {
    onClose(false);
    history.push(link);
  };

  return (
    <div className="util_popup sitemap">
      <div className="util_popup_top">
        <h4>사이트 맵</h4>
        <span>kt wiz 홈페이지 전체 메뉴를 안내해드립니다. </span>
      </div>
      <div className="util_popup_content">
        <ul>
          <li className="dep1">
            <h5>kt wiz</h5>
            <ul>
              <li className="dep2 first">
                <h6>
                  <Link to="/ktwiz/about" onClick={() => go("/ktwiz/about")}>
                    kt wiz는?
                  </Link>
                </h6>
                <ul>
                  <li className="dep3">
                    <Link to="/ktwiz/about" onClick={() => go("/ktwiz/about")}>
                      구단 소개
                    </Link>
                  </li>
                  <li className="dep3">
                    <Link to="/ktwiz/history" onClick={() => go("/ktwiz/history")}>
                      구단 연혁
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="dep2">
                <h6>
                  <Link to="/ktwiz/bi/symbol" onClick={() => go("/ktwiz/bi/symbol")}>
                    구단 BI
                  </Link>
                </h6>
                <ul>
                  <li className="dep3">
                    <Link to="/ktwiz/bi/symbol" onClick={() => go("/ktwiz/bi/symbol")}>
                      심볼마크
                    </Link>
                  </li>
                  <li className="dep3">
                    <Link to="/ktwiz/bi/wordmark" onClick={() => go("/ktwiz/bi/wordmark")}>
                      워드마크
                    </Link>
                  </li>
                  <li className="dep3">
                    <Link to="/ktwiz/bi/emblem" onClick={() => go("/ktwiz/bi/emblem")}>
                      엠블럼
                    </Link>
                  </li>
                  <li className="dep3">
                    <Link to="/ktwiz/bi/mascot" onClick={() => go("/ktwiz/bi/mascot")}>
                      마스코트
                    </Link>
                  </li>
                  <li className="dep3">
                    <Link to="/ktwiz/bi/uniform" onClick={() => go("/ktwiz/bi/uniform")}>
                      유니폼
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="dep2">
                <h6>
                  <Link to="/ktwiz/policy/regular" onClick={() => go("/ktwiz/policy/regular")}>
                    회원 정책
                  </Link>
                </h6>
                <ul>
                  <li className="dep3">
                    <Link to="/ktwiz/policy/regular" onClick={() => go("/ktwiz/policy/regular")}>
                      일반회원
                    </Link>
                  </li>
                  {/* <li className="dep3">
                    <Link to="/ktwiz/policy/child" onClick={() => go("/ktwiz/policy/child")}>
                      선등급/어린이회원
                    </Link>
                  </li> */}
                  <li className="dep3">
                    <Link to="/ktwiz/policy/donation" onClick={() => go("/ktwiz/policy/donation")}>
                      기부 프로그램
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="dep2">
                <h6>
                  <Link to="/ktwiz/sponsor" onClick={() => go("/ktwiz/sponsor")}>
                    스폰서
                  </Link>
                </h6>
              </li>
              <li className="dep2">
                <h6>
                  <Link to="/ktwiz/wallpaper" onClick={() => go("/ktwiz/wallpaper")}>
                    월페이퍼
                  </Link>
                </h6>
              </li>
            </ul>
          </li>
          <li className="dep1">
            <h5>wiz park</h5>
            <ul>
              <li className="dep2 first">
                <h6>
                  <Link to="/wizpark/intro" onClick={() => go("/wizpark/intro")}>
                    Suwon kt wiz park
                  </Link>
                </h6>
                <ul>
                  <li className="dep3">
                    <Link to="/wizpark/intro" onClick={() => go("/wizpark/intro")}>
                      구장 소개
                    </Link>
                  </li>
                  <li className="dep3">
                    <Link to="/wizpark/guide" onClick={() => go("/wizpark/guide")}>
                      구장 안내도
                    </Link>
                  </li>
                </ul>
              </li>
              {/* <li className="dep2">
                <h6><Link to="/wizpark/stadium" onClick={() => go('/wizpark/stadium')}>5G 스타디움</Link></h6>
              </li> */}
              <li className="dep2">
                <h6>
                  <Link to="/wizpark/parking" onClick={() => go("/wizpark/parking")}>
                    주차 예약
                  </Link>
                </h6>
                <ul>
                  <li className="dep3">
                    <Link to="/wizpark/parking" onClick={() => go("/wizpark/parking")}>
                      주차 예약 안내
                    </Link>
                  </li>
                  {/* <li className="dep3">
                    <Link to="/wizpark/shuttle" onClick={() => go("/wizpark/shuttle")}>
                      셔틀버스 안내
                    </Link>
                  </li> */}
                </ul>
              </li>
              <li className="dep2">
                <h6>
                  <Link to="/wizpark/location" onClick={() => go("/wizpark/location")}>
                    찾아오기
                  </Link>
                </h6>
              </li>
              <li className="dep2">
                <h6>
                  <Link to="/wizpark/iksan" onClick={() => go("/wizpark/iksan")}>
                    익산야구장
                  </Link>
                </h6>
              </li>
            </ul>
          </li>
          <li className="dep1">
            <h5>Game</h5>
            <ul>
              <li className="dep2 first">
                <h6>
                  <Link to="/game/regular/schedule" onClick={() => go("/game/regular/schedule")}>
                    정규리그
                  </Link>
                </h6>
                <ul>
                  <li className="dep3">
                    <Link to="/game/regular/schedule" onClick={() => go("/game/regular/schedule")}>
                      경기 일정
                    </Link>
                  </li>
                  <li className="dep3">
                    <Link to="/game/regular/boxscore" onClick={() => go("/game/regular/boxscore")}>
                      박스스코어
                    </Link>
                  </li>
                  <li className="dep3">
                    <Link to="/game/regular/ranking/team" onClick={() => go("/game/regular/ranking/team")}>
                      순위기록
                    </Link>
                  </li>
                  <li className="dep3">
                    <Link to="/game/regular/watchPoint" onClick={() => go("/game/regular/watchPoint")}>
                      관전포인트
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="dep2">
                <h6>
                  <Link to="/game/futures/schedule" onClick={() => go("/game/futures/schedule")}>
                    퓨처스리그
                  </Link>
                </h6>
                <ul>
                  <li className="dep3">
                    <Link to="/game/futures/schedule" onClick={() => go("/game/futures/schedule")}>
                      경기 일정
                    </Link>
                  </li>
                  <li className="dep3">
                    <Link to="/game/futures/boxscore" onClick={() => go("/game/futures/boxscore")}>
                      박스스코어
                    </Link>
                  </li>
                  <li className="dep3">
                    <Link to="/game/futures/ranking/team" onClick={() => go("/game/futures/ranking/team")}>
                      순위기록
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li className="dep1">
            <h5>Player</h5>
            <ul>
              <li className="dep2 first">
                <h6>
                  <Link to="/player/coach" onClick={() => go("/player/coach")}>
                    코칭스텝
                  </Link>
                </h6>
              </li>
              <li className="dep2">
                <h6>
                  <Link to="/player/pitcher" onClick={() => go("/player/pitcher")}>
                    투수
                  </Link>
                </h6>
              </li>
              <li className="dep2">
                <h6>
                  <Link to="/player/catcher" onClick={() => go("/player/catcher")}>
                    타자
                  </Link>
                </h6>
                <ul>
                  <li className="dep3">
                    <Link to="/player/catcher" onClick={() => go("/player/catcher")}>
                      포수
                    </Link>
                  </li>
                  <li className="dep3">
                    <Link to="/player/infielder" onClick={() => go("/player/infielder")}>
                      내야수
                    </Link>
                  </li>
                  <li className="dep3">
                    <Link to="/player/outfielder" onClick={() => go("/player/outfielder")}>
                      외야수
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="dep2">
                <h6>
                  <Link to="/player/cheer" onClick={() => go("/player/cheer")}>
                    응원단
                  </Link>
                </h6>
              </li>
              <li className="dep2">
                <h6>
                  <Link to="/player/song" onClick={() => go("/player/song")}>
                    응원가
                  </Link>
                </h6>
                {/* <ul>
                  <li className="dep3"><Link to="/player/song" onClick={() => go('/player/song')}>팀 응원가</Link></li>
                  <li className="dep3"><Link to="/player/song" onClick={() => go('/player/song')}>선수별 응원가</Link></li>
                </ul> */}
              </li>
            </ul>
          </li>
          <li className="dep1">
            <h5>Media</h5>
            <ul>
              <li className="dep2 first">
                <h6>
                  <Link to="/media/wiznews" onClick={() => go("/media/wiznews")}>
                    wiz 뉴스
                  </Link>
                </h6>
                <ul>
                  <li className="dep3">
                    <Link to="/media/wiznews" onClick={() => go("/media/wiznews")}>
                      wiz 소식
                    </Link>
                  </li>
                  <li className="dep3">
                    <Link to="/media/wizpress" onClick={() => go("/media/wizpress")}>
                      wiz 보도자료
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="dep2">
                <h6>
                  <Link to="/media/wizstory" onClick={() => go("/media/wizstory")}>
                    wiz 스토리
                  </Link>
                </h6>
              </li>
              <li className="dep2">
                <h6>
                  <Link to="/media/firstpitch" onClick={() => go("/media/firstpitch")}>
                    시구자 정보
                  </Link>
                </h6>
              </li>
              <li className="dep2">
                <h6>
                  <Link to="/media/photos/1" onClick={() => go("/media/photos/1")}>
                    wiz 포토
                  </Link>
                </h6>
                <ul>
                  <li className="dep3">
                    <Link to="/media/photos/1" onClick={() => go("/media/photos/1")}>
                      경기
                    </Link>
                  </li>
                  <li className="dep3">
                    <Link to="/media/photos/2" onClick={() => go("/media/photos/2")}>
                      훈련
                    </Link>
                  </li>
                  <li className="dep3">
                    <Link to="/media/photos/3" onClick={() => go("/media/photos/3")}>
                      행사
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="dep2">
                <h6>
                  <Link to="/media/highlight" onClick={() => go("/media/highlight")}>
                    하이라이트
                  </Link>
                </h6>
              </li>
              <li className="dep2">
                <h6>
                  <Link to="/media/live/pts">Live 영상 모음</Link>
                </h6>
                <ul>
                  {/* <li className="dep3"><Link to="/media/live/position" onClick={() => go('/media/live/position')}>포지션 뷰</Link></li>
                  <li className="dep3"><Link to="/media/live/matrix" onClick={() => go('/media/live/matrix')}>매트릭스 뷰</Link></li> */}
                  <li className="dep3">
                    <Link to="/media/live/pts" onClick={() => go("/media/live/pts")}>
                      피칭분석
                    </Link>
                  </li>
                  <li className="dep3">
                    <Link to="/media/live/fts" onClick={() => go("/media/live/fts")}>
                      모션트레킹
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li className="dep1">
            <h5>Ticket</h5>
            <ul>
              <li className="dep2 first">
                <h6>
                  <Link to="/ticket/price" onClick={() => go("/ticket/reservation")}>
                    일반 티켓
                  </Link>
                </h6>
                <ul>
                  <li className="dep3">
                    <Link to="/ticket/reservation" onClick={() => go("/ticket/reservation")}>
                      티켓 예매
                    </Link>
                  </li>
                  <li className="dep3">
                    <Link to="/ticket/price" onClick={() => go("/ticket/price")}>
                      입장요금
                    </Link>
                  </li>
                  <li className="dep3">
                    <Link to="/ticket/discount" onClick={() => go("/ticket/discount")}>
                      할인 혜택
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="dep2">
                <h6>
                  <Link to="/ticket/skybox" onClick={() => go("/ticket/skybox")}>
                    스카이 박스 / 단체 관람
                  </Link>
                </h6>
                <ul>
                  <li className="dep3">
                    <Link to="/ticket/group" onClick={() => go("/ticket/group")}>
                      단체 관람 안내
                    </Link>
                  </li>
                  <li className="dep3">
                    <Link to="/ticket/skybox" onClick={() => go("/ticket/skybox")}>
                      스카이 박스
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="dep2">
                <h6>
                  <Link to="/ticket/seatmap" onClick={() => go("/ticket/seatmap")}>
                    입장 및 좌석 정보
                  </Link>
                </h6>
                <ul>
                  <li className="dep3">
                    <Link to="/ticket/seatmap" onClick={() => go("/ticket/seatmap")}>
                      좌석 배치도
                    </Link>
                  </li>
                  <li className="dep3">
                    <Link to="/ticket/entrance" onClick={() => go("/ticket/entrance")}>
                      입장 시간 및 방법
                    </Link>
                  </li>
                  <li className="dep3">
                    <Link to="/ticket/store" onClick={() => go("/ticket/store")}>
                      구장 내 매장정보
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <button className="btn_popup_close" onClick={() => onClose(false)}>
        닫기
      </button>
      {/* <span className="back_arrow"></span> */}
    </div>
  );
};

export default SiteMap;
