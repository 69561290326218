import React from 'react'
import ContentTemplate from '../../components/common/ContentTemplate'
import SubContentTemplate from '../../components/common/SubContentTemplate'
import PageHeaderContainer from '../../containers/common/PageHeaderContainer'
import PageTitleTemplate from '../../components/common/PageTitleTemplate'
import BreadcrumbContainer from '../../containers/common/BreadcrumbContainer'
import PageTemplate from '../../components/common/PageTemplate'
import { TICKET_RESERVATION_AND_BENEFIT, TICKET_REGULAR_TAB } from '../../constants/page'
import DiscountAndBenefit from '../../components/ticket/DiscountAndBenefit'

const DiscountAndBenefitPage = ({ match }) => {
  return (
    <PageTemplate className="ticket">
      <PageHeaderContainer title="일반티켓" subtitle="kt wiz 경기를 경기장에서 직접 만나 보세요" image={require('../../assets/img/ticket/sub_tit_ticket.png')} forwardTabs={TICKET_REGULAR_TAB} currentPath={match.path} />
      <ContentTemplate>
        <PageTitleTemplate>
          <BreadcrumbContainer page={TICKET_RESERVATION_AND_BENEFIT} />
        </PageTitleTemplate>
        <SubContentTemplate className="sub_content_wrap ticket regular discount">
          <DiscountAndBenefit />
        </SubContentTemplate>
      </ContentTemplate>
    </PageTemplate>
  )
}

export default DiscountAndBenefitPage
