import React from 'react'
import PitcherDetailSecondPcTabRegular from './PitcherDetailSecondPcTabRegular'
import PitcherDetailSecondPcTabLastest from './PitcherDetailSecondPcTabLastest'
import PitcherDetailSecondPcTabTotal from './PitcherDetailSecondPcTabTotal'
import PitcherDetailSecondPcTabFutures from './PitcherDetailSecondPcTabFutures'

const dataTables = {
  PITCHER_DETAIL_REGULAR_SEASON_PC: {
    title: '시즌 정규리그 기록',
    data: [
      { lineNum: 1, cols: '*', head: '평균자책점', bodyParam: 'era' },
      { lineNum: 1, cols: '78px', head: '경기', bodyParam: 'gamenum' },
      { lineNum: 1, cols: '78px', head: '완투', bodyParam: 'wCg' },
      { lineNum: 1, cols: '78px', head: '완봉', bodyParam: 'sho' },
      { lineNum: 1, cols: '78px', head: '승', bodyParam: 'w' },
      { lineNum: 1, cols: '78px', head: '패', bodyParam: 'l' },
      { lineNum: 1, cols: '78px', head: '세', bodyParam: 'sv' },
      { lineNum: 1, cols: '78px', head: '홀', bodyParam: 'hold' },
      { lineNum: 1, cols: '78px', head: '승률', bodyParam: 'wra' },
      { lineNum: 1, cols: '78px', head: '타자', bodyParam: 'bf' },
      { lineNum: 1, cols: '78px', head: '투구수', bodyParam: 'tugucount' },
      { lineNum: 1, cols: '78px', head: '이닝', bodyParam: 'innDisplay' },
      { lineNum: 1, cols: '78px', head: '피안타', bodyParam: 'hit' },
      { lineNum: 1, cols: '78px', head: '피홈런', bodyParam: 'hr' },
      { lineNum: 2, cols: '73px', head: '희비', bodyParam: 'sf' },
      { lineNum: 2, cols: '73px', head: '희타', bodyParam: 'sh' },
      { lineNum: 2, cols: '73px', head: '볼넷', bodyParam: 'bb' },
      { lineNum: 2, cols: '73px', head: '고의4구', bodyParam: 'ib' },
      { lineNum: 2, cols: '73px', head: '사구', bodyParam: 'hp' },
      { lineNum: 2, cols: '73px', head: '탈삼진', bodyParam: 'kk' },
      { lineNum: 2, cols: '73px', head: '폭투', bodyParam: 'wp' },
      { lineNum: 2, cols: '73px', head: '보크', bodyParam: 'bk' },
      { lineNum: 2, cols: '73px', head: '실점', bodyParam: 'r' },
      { lineNum: 2, cols: '73px', head: '자책점', bodyParam: 'er' },
      { lineNum: 2, cols: '*', head: '블론 세이브', bodyParam: 'bs' },
      { lineNum: 2, cols: '73px', head: 'WHIP', bodyParam: 'whip' },
      { lineNum: 2, cols: '73px', head: '피안타율', bodyParam: 'oavg' },
      { lineNum: 2, cols: '73px', head: 'QS', bodyParam: 'qs' },
      { lineNum: 2, cols: '73px', head: 'K/BB', bodyParam: 'kbb' },
    ]
  },
  PITCHER_DETAIL_FUTURES_PC: {
    title: ['시즌 퓨처스리그 기록', '퓨처스리그 최근 5경기 기록'],
    data: [
      [
        { cols: '*', head: '평균자책점', bodyParam: 'era' },
        { cols: '73px', head: '경기', bodyParam: 'gamenum' },
        { cols: '73px', head: '승', bodyParam: 'w' },
        { cols: '73px', head: '패', bodyParam: 'l' },
        { cols: '73px', head: '세', bodyParam: 'sv' },
        { cols: '73px', head: '홀', bodyParam: 'hold' },
        { cols: '73px', head: '승률', bodyParam: 'wra' },
        { cols: '73px', head: '이닝', bodyParam: 'innDisplay' },
        { cols: '73px', head: '피안타', bodyParam: 'hit' },
        { cols: '73px', head: '피홈런', bodyParam: 'hr' },
        { cols: '73px', head: '볼넷', bodyParam: 'bb' },
        { cols: '73px', head: '사구', bodyParam: 'hp' },
        { cols: '73px', head: '탈삼진', bodyParam: 'kk' },
        { cols: '73px', head: '실점', bodyParam: 'r' },
        { cols: '73px', head: '자책점', bodyParam: 'er' },
      ],
      [
        { cols: '84px', head: '일자', bodyParam: 'displayDate' },
        { cols: '84px', head: '상대', bodyParam: 'matchTeamName' },
        { cols: '84px', head: '결과', bodyParam: 'wls' },
        { cols: '*', head: '평균자책점', bodyParam: 'era' },
        { cols: '84px', head: '타자', bodyParam: 'pa' },
        { cols: '84px', head: '이닝', bodyParam: 'innDisplay' },
        { cols: '84px', head: '피안타', bodyParam: 'hit' },
        { cols: '84px', head: '피홈런', bodyParam: 'hr' },
        { cols: '84px', head: '볼넷', bodyParam: 'bb' },
        { cols: '84px', head: '사구', bodyParam: 'hp' },
        { cols: '84px', head: '탈삼진', bodyParam: 'kk' },
        { cols: '84px', head: '실점', bodyParam: 'r' },
        { cols: '84px', head: '자책점', bodyParam: 'er' },
      ]
    ]
  },
  PITCHER_DETAIL_LASTEST_GAME_PC: {
    title: '최근 5경기 기록',
    data: [
      { cols: '84px', head: '일자', bodyParam: 'displayDate' },
      { cols: '84px', head: '상대', bodyParam: 'matchTeamName' },
      { cols: '84px', head: '결과', bodyParam: 'wls' },
      { cols: '*', head: '평균자책점', bodyParam: 'era' },
      { cols: '84px', head: '타자', bodyParam: 'pa' },
      { cols: '87px', head: '이닝', bodyParam: 'innDisplay' },
      { cols: '84px', head: '피안타', bodyParam: 'hit' },
      { cols: '84px', head: '피홈런', bodyParam: 'hr' },
      { cols: '84px', head: '볼넷', bodyParam: 'bb' },
      { cols: '84px', head: '사구', bodyParam: 'hp' },
      { cols: '84px', head: '탈삼진', bodyParam: 'kk' },
      { cols: '84px', head: '실점', bodyParam: 'r' },
      { cols: '84px', head: '자책점', bodyParam: 'er' },
    ]
  },
  PITCHER_DETAIL_TOTAL_PC: {
    title: '통산 기록 (kt)',
    data: [
      { cols: '58px', head: '연도', bodyParam: 'gyear' },
      { cols: '51px', head: '팀', bodyParam: 'teamName' },
      { cols: '84px', head: '평균자책점', bodyParam: 'era' },
      { cols: '51px', head: '경기', bodyParam: 'gamenum' },
      { cols: '51px', head: '완투', bodyParam: 'wCg' },
      { cols: '51px', head: '완봉', bodyParam: 'sho' },
      { cols: '51px', head: '승', bodyParam: 'w' },
      { cols: '51px', head: '패', bodyParam: 'l' },
      { cols: '51px', head: '세', bodyParam: 'sv' },
      { cols: '51px', head: '홀', bodyParam: 'hold' },
      { cols: '62px', head: '승률', bodyParam: 'wra' },
      { cols: '51px', head: '타자', bodyParam: 'bf' },
      { cols: '*', head: '이닝', bodyParam: 'innDisplay' },
      { cols: '51px', head: '피안타', bodyParam: 'hit' },
      { cols: '51px', head: '피홈런', bodyParam: 'hr' },
      { cols: '51px', head: '볼넷', bodyParam: 'bb' },
      { cols: '51px', head: '사구', bodyParam: 'hp' },
      { cols: '51px', head: '탈삼진', bodyParam: 'kk' },
      { cols: '51px', head: '실점', bodyParam: 'r' },
      { cols: '51px', head: '자책점', bodyParam: 'er' },
    ]
  },
}

const PitcherDetailSecondPc = ({ contentType, data, isFuturesLeague, onClickIsFuturesLeague, selectedGyear }) => {

  const seasonsummary = data && data.seasonsummary
  const recentgamerecordlist = data && data.recentgamerecordlist
  const yearrecordlist = data && data.yearrecordlist

  const seasonsummaryfutures = data && data.seasonsummaryfutures
  const recentgamerecordlistfutures = data && data.recentgamerecordlistfutures

  return (
    <>
      {/* 정규리그 기록 */}
      {
        (contentType === 'regular_league' && !isFuturesLeague) &&
        <PitcherDetailSecondPcTabRegular dataTable={dataTables.PITCHER_DETAIL_REGULAR_SEASON_PC} seasonsummary={seasonsummary} recentgamerecordlist={recentgamerecordlist} onClickIsFuturesLeague={onClickIsFuturesLeague} selectedGyear={selectedGyear} />
      }

      {/* 퓨처스리그 기록 */}
      {
        (contentType === 'regular_league' && isFuturesLeague) &&
        <PitcherDetailSecondPcTabFutures dataTable={dataTables.PITCHER_DETAIL_FUTURES_PC} seasonsummary={seasonsummaryfutures} recentgamerecordlist={recentgamerecordlistfutures} onClickIsFuturesLeague={onClickIsFuturesLeague} selectedGyear={selectedGyear} />
      }

      {/* 최근 5경기 */}
      {
        contentType === 'latest_game' &&
        <PitcherDetailSecondPcTabLastest dataTable={dataTables.PITCHER_DETAIL_LASTEST_GAME_PC} recentgamerecordlist={recentgamerecordlist} />
      }

      {/* 통산 기록 */}
      {
        contentType === 'total' &&
        <PitcherDetailSecondPcTabTotal dataTable={dataTables.PITCHER_DETAIL_TOTAL_PC} yearrecordlist={yearrecordlist} />
      }

    </>
  )
}

export default PitcherDetailSecondPc
