import React from 'react'
import ContentTemplate from '../../components/common/ContentTemplate'
import SubContentTemplate from '../../components/common/SubContentTemplate'
import PageHeaderContainer from '../../containers/common/PageHeaderContainer'
import PageTitleTemplate from '../../components/common/PageTitleTemplate'
import BreadcrumbContainer from '../../containers/common/BreadcrumbContainer'
import PageTemplate from '../../components/common/PageTemplate'
import { REGULAR_LEAGUE_BOXSCORE, REGULAR_LEAGUE_TAB } from '../../constants/page'
import RegularLeagueBoxScoreContainer from '../../containers/game/RegularLeagueBoxScoreContainer'

const RegularLeagueBoxScorePage = ({ match }) => {
  return (
    <PageTemplate className="game">
      <PageHeaderContainer title="정규 리그" subtitle="박스스코어 정보를 알려 드립니다." image={require('../../assets/img/game/sub_tit_game.png')} forwardTabs={REGULAR_LEAGUE_TAB} currentPath={REGULAR_LEAGUE_BOXSCORE.path} />
      <ContentTemplate className="game_boxscore">
        <PageTitleTemplate>
          <BreadcrumbContainer page={REGULAR_LEAGUE_BOXSCORE} />
        </PageTitleTemplate>
        <SubContentTemplate>
          <RegularLeagueBoxScoreContainer match={match} />
        </SubContentTemplate>
      </ContentTemplate>
    </PageTemplate>
  )
}

export default RegularLeagueBoxScorePage
