import React from 'react'

const ContentTemplate = ({ children, className }) => {
  return (
    <div className={`container${className === undefined ? '' : ' ' + className}`}>
      {children}
    </div>
  )
}

export default ContentTemplate
