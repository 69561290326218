import React from 'react'
import Link from '../../common/Link'

const PitcherDetailSecondPcTabFutures = ({ dataTable, seasonsummary, recentgamerecordlist, onClickIsFuturesLeague, selectedGyear }) => {

  const evalParams = (value, subValue) => {
    switch (subValue.bodyParam) {
      // 승패 출력
      case 'wls':
        return value[subValue.bodyParam] === "W" ? "승" : value[subValue.bodyParam] === "L" ? "패" : value[subValue.bodyParam] === "S" ? "세" : " "
      // 평균자책점 계산
      case 'era':
        return (value.er && value.er > 0 && value.innDisplay && value.innDisplay)
          ? (parseFloat(value.er * 9) / (parseFloat(value.innDisplay.split(' ')[0]) + parseFloat(value.innDisplay.split(' ')[1] ? value.innDisplay.split(' ')[1].split('/').reduce((p, c) => p / c) : 0))).toFixed(2)
          : 0
      default:
        return value[subValue.bodyParam]
    }
  }

  return (
    <>
      {/* 시즌 퓨처스리그 기록 pc */}
      <article className="player_record_content futures_league pc_mode">
        <div className="player_record">
          <div className="container">
            <h5>{selectedGyear} {dataTable.title[0]}</h5>
            <Link onClick={onClickIsFuturesLeague} className="btn_regular">정규리그 기록 보기</Link>
          </div>
          <div className="container table">
            <table className="btD7161b">
              <caption>{selectedGyear} {dataTable.title[0]}</caption>
              <colgroup>
                {
                  dataTable.data[0].map((v, i) => (
                    <col key={i} width={v.cols} />
                  ))
                }
              </colgroup>
              <thead>
                <tr>
                  {
                    dataTable.data[0].map((v, i) => (
                      <th key={i}>{v.head}</th>
                    ))
                  }
                </tr>
              </thead>
              <tbody>
                <tr>
                  {
                    seasonsummary
                      ? dataTable.data[0].map((v, i) => (
                        <td key={i}>{seasonsummary[v.bodyParam]}</td>
                      ))
                      : <td colSpan={dataTable.data[0].length}>데이터가 없습니다.</td>
                  }
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </article>
      {/* // 시즌 퓨처스리그 기록 pc */}

      {/* 시즌 퓨처스리그 최근 5경기 기록 pc */}
      <article className="player_record_content futures_latest_game pc_mode">
        <div className="player_record">
          <div className="container">
            <h5>{dataTable.title[1]}</h5>
          </div>
          <div className="container table">
            <table className="btD7161b td13">
              <caption>{dataTable.title[1]}</caption>
              <colgroup>
                {
                  dataTable.data[1].map((v, i) => (
                    <col key={i} width={v.cols} />
                  ))
                }
              </colgroup>
              <thead>
                <tr>
                  {
                    dataTable.data[1].map((v, i) => (
                      <th key={i}>{v.head}</th>
                    ))
                  }
                </tr>
              </thead>
              <tbody>
                {
                  (recentgamerecordlist && recentgamerecordlist.length > 0)
                    ? recentgamerecordlist.map((v, i) => (
                      <tr key={i}>
                        {
                          dataTable.data[1].map((sv, si) => (
                            <td key={si}>{evalParams(v, sv)}</td>
                          ))
                        }
                      </tr>
                    ))
                    : <tr><td colSpan={dataTable.data[1].length}>데이터가 없습니다.</td></tr>
                }
              </tbody>
            </table>
          </div>
        </div>
      </article>
      {/* // 시즌 퓨처스리그 최근 5경기 기록 pc */}
    </>
  )
}

export default PitcherDetailSecondPcTabFutures
