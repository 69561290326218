import React from 'react'

const PitcherDetailSecondMobileTabRegular = ({ dataTable, seasonsummary, selectedGyear }) => {
  return (
    <>
      {/* 시즌 정규리그 기록 mobile */}
      <article className="player_record_content regular_league mb_mode">
        <div className="player_record">
          <div className="container">
            <h5>{selectedGyear} {dataTable.title}</h5>
          </div>
          <div className="container table">
            <table className="btD7161b wid100per">
              <caption>{selectedGyear} {dataTable.title}</caption>
              <colgroup>
                {
                  dataTable.data.map((v, i) => (
                    <col key={i} width={v.cols} />
                  ))
                }
              </colgroup>
              <tbody>
                {/* 라인1 */}
                <tr>
                  {
                    dataTable.data.map((v, i) => (
                      v.head[0] &&
                      <th key={i}>{v.head[0]}</th>
                    ))
                  }
                </tr>
                <tr>
                  {
                    seasonsummary
                      ? dataTable.data.map((v, i) => (
                        v.bodyParam[0] &&
                        <td key={i}>{seasonsummary[v.bodyParam[0]]}</td>
                      ))
                      : <td colSpan={dataTable.data.length}>데이터가 없습니다.</td>
                  }
                </tr>
                {/* 라인2 */}
                <tr>
                  {
                    dataTable.data.map((v, i) => (
                      v.head[1] &&
                      <th key={i}>{v.head[1]}</th>
                    ))
                  }
                </tr>
                <tr>
                  {
                    seasonsummary
                      ? dataTable.data.map((v, i) => (
                        v.bodyParam[1] &&
                        <td key={i}>{seasonsummary[v.bodyParam[1]]}</td>
                      ))
                      : <td colSpan={dataTable.data.length}>데이터가 없습니다.</td>
                  }
                </tr>
                {/* 라인3 */}
                <tr>
                  {
                    dataTable.data.map((v, i) => (
                      v.head[2] &&
                      <th key={i}>{v.head[2]}</th>
                    ))
                  }
                </tr>
                <tr>
                  {
                    seasonsummary
                      ? dataTable.data.map((v, i) => (
                        v.bodyParam[2] &&
                        <td key={i}>{seasonsummary[v.bodyParam[2]]}</td>
                      ))
                      : <td colSpan={dataTable.data.length}>데이터가 없습니다.</td>
                  }
                </tr>
                {/* 라인4 */}
                <tr>
                  {
                    dataTable.data.map((v, i, arr) => (
                      v.head[3] &&
                      <th key={i} colSpan={
                        (arr.length > v.head.length && v.head.length === i)
                          ? arr.length - i
                          : 1
                      }>{v.head[3]}</th>
                    ))
                  }
                </tr>
                <tr>
                  {
                    seasonsummary
                      ? dataTable.data.map((v, i, arr) => (
                        v.bodyParam[3] &&
                        <td key={i} colSpan={
                          (arr.length > v.bodyParam.length && v.bodyParam.length === i)
                            ? arr.length - i
                            : 1
                        }>{seasonsummary[v.bodyParam[3]]}</td>
                      ))
                      : <td colSpan={dataTable.data.length}>데이터가 없습니다.</td>
                  }
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </article>
      {/* // 시즌 정규리그 기록 mobile */}
    </>
  )
}

export default PitcherDetailSecondMobileTabRegular
