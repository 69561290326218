import React from 'react'
import Link from '../../common/Link'
import { download } from '../../../modules/common/download'

const Wordmark = () => {
  return (
    <>
      <article className='title'>
        <div className='txt'>
          <h6>워드마크</h6>
        </div>
        <div className='txt-bar'></div>
        <div className="desc">
          <p>
            워드 마크는 이니셜 심볼의 역동적인 외곽라인과 이미지 심볼의 눈(Burst 형상)을 활용하여 <br />
            전체 심볼의 이미지와 통일감을 가질 수 있도록 디자인하였습니다.
          </p>
        </div>
      </article>
      <div className='images-wrap'>
        <div className='col'>
          <div className='images'>
            <div className='img-wrap'>
              <img className='pc' src={require('../../../assets/img/ktwiz/bi_word-mark_white_en.png')} alt="" />
              <img className='m' src={require('../../../assets/img/ktwiz/bi_word-mark_white_en_m.png')} alt="" />
            </div>
            <div className='img-wrap'>
              <img className='pc' src={require('../../../assets/img/ktwiz/bi_word-mark_black_en.png')} alt="" />
              <img className='m' src={require('../../../assets/img/ktwiz/bi_word-mark_black_en_m.png')} alt="" />
            </div>
          </div>
        </div>
        <div className='col'>
          <div className='images'>
            <div className='img-wrap'>
              <img className='pc' src={require('../../../assets/img/ktwiz/bi_word-mark_white_ko.png')} alt="" />
              <img className='m' src={require('../../../assets/img/ktwiz/bi_word-mark_white_ko_m.png')} alt="" />
            </div>
            <div className='img-wrap'>
              <img className='pc' src={require('../../../assets/img/ktwiz/bi_word-mark_black_ko.png')} alt="" />
              <img className='m' src={require('../../../assets/img/ktwiz/bi_word-mark_black_ko_m.png')} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className='pc'>
        <div className='buttons-wrap'>
          <div className="buttons">
            <Link className='btn-download l' to={require('../../../assets/download/WordMark_eng_ai.zip')} target='_blank' download>
              AI 다운로드
            </Link>
            <Link className='btn-download r' onClick={() => download(require('../../../assets/download/WordMark_eng_jpg.jpg'), 'WordMark_eng.jpg')}>
              JPG 다운로드
            </Link>
          </div>
          <div className="buttons">
            <Link className='btn-download l' to={require('../../../assets/download/WordMark_ai.zip')} target='_blank' download>
              AI 다운로드
            </Link>
            <Link className='btn-download r' onClick={() => download(require('../../../assets/download/WordMark_jpg.jpg'), 'WordMark.jpg')}>
              JPG 다운로드
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default Wordmark
