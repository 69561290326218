export const ALERT_OPEN = 'dialog/ALERT_OPEN'
export const ALERT_POSITIVE = 'dialog/ALERT_POSITIVE'

export const CONFIRM_OPEN = 'dialog/CONFIRM_OPEN'
export const CONFIRM_POSITIVE = 'dialog/CONFIRM_POSITIVE'
export const CONFIRM_NEGATIVE = 'dialog/CONFIRM_NEGATIVE'

export const alertOpen = (message, positiveButton, style, isIframe) => ({ type: ALERT_OPEN, message, positiveButton, style, isIframe })
export const alertPositive = () => ({ type: ALERT_POSITIVE })
export const confirmOpen = (message, positiveButton, negativeButton, style, isIframe) => ({ type: CONFIRM_OPEN, message, positiveButton, negativeButton, style, isIframe })
export const confirmPositive = () => ({ type: CONFIRM_POSITIVE })
export const confirmNegative = () => ({ type: CONFIRM_NEGATIVE })

const initialState = {
  isAlert: false,
  isConfirm: false,
  message: '',
  positiveButton: '확인',
  negativeButton: '취소',
  style: null,
  isIframe: false,
}

const dialog = (state = initialState, action) => {

  switch (action.type) {

    case ALERT_OPEN:
      return {
        ...state,
        isAlert: true,
        message: action.message,
        positiveButton: action.positiveButton || initialState.positiveButton,
        style: action.style,
        isIframe: action.isIframe
      }
    case ALERT_POSITIVE:
      return {
        ...state,
        isAlert: false,
        style: null,
        isIframe: false,
      }
    case CONFIRM_OPEN:
      return {
        ...state,
        isConfirm: true,
        message: action.message,
        positiveButton: action.positiveButton || initialState.positiveButton,
        negativeButton: action.negativeButton || initialState.negativeButton,
        style: action.style,
        isIframe: action.isIframe
      }
    case CONFIRM_POSITIVE:
      return {
        ...state,
        isConfirm: false,
        style: null,
        isIframe: false,
      }
    case CONFIRM_NEGATIVE:
      return {
        ...state,
        isConfirm: false,
        style: null,
        isIframe: false,
      }
    default:
      return state
  }

}

export default dialog
