import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { createListRequest } from '../../../modules/player/playerList'
import CheerPc from './CheerPc'
import CheerMobile from './CheerMobile'
import Prepairing from '../../common/Prepairing'

const Cheer = ({ type }) => {

  const list = useSelector(state => state.wizData.data && state.wizData.data[type])
  // const error = useSelector(state => state.wizData.error && state.wizData.error[type])
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(createListRequest({}, type))
  }, [type])

  return (
    <>
      <CheerPc cheerleaderList={list} />
      <CheerMobile cheerleaderList={list} />
    </>
  )
}

export default Cheer
