import client from './client';
import qs from 'query-string';

const BASE_URI = '/api/v2';

const doRequest = method => ({ api, params, data }) => {
  const url = `${BASE_URI}/${api}`;
  console.log(`wiz_doRequest_${method}`, { api, params, data });
  return client[method](`${url}?${qs.stringify(params)}`, data)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

const method = {
  GET: doRequest('get'),
  POST: doRequest('post'),
  PUT: doRequest('put'),
  DELETE: doRequest('delete')
}

const wiz = {
  API: {
    LIST_BY_CATEGORY: { url: 'article/listByCategory', method: method.GET },
    GET_HOTISSUE_LIST: { url: 'article/hotissue', method: method.GET },
    GET_ARTICLE_DETAIL: { url: 'article/detail', method: method.GET },
    GET_GAME_VIDEO: { url: 'article/highlight', method: method.GET },
    GET_RESOURCE: { url: 'resource', method: method.GET },
    GET_FINEDUST_LIST: { url: 'iot/iotInfoList', method: method.GET },
    GET_PLAYER_LIST: { url: 'game/playerlist', method: method.POST },
    GET_COACH_LIST: { url: 'game/coachsteplist', method: method.GET },
    GET_CHEERLEADER_LIST: { url: 'guide/cheerLeaderList', method: method.GET },
    GET_CHEERSONG_LIST: { url: 'guide/cheerSongWithPlayerImg', method: method.GET },
    GET_COACH_DETAIL: { url: 'game/coachstep', method: method.GET },
    GET_PITCHER_DETAIL: { url: 'game/pitcherdetail', method: method.GET },
    GET_BATTER_DETAIL: { url: 'game/batterdetail', method: method.GET },
    GET_CHEERLEADER_DETAIL: { url: 'guide/cheerLeader/detail', method: method.GET },
    GET_CHEERLEADER_DETAIL_IMG: { url: 'guide/cheerLeader/imageList', method: method.GET },
    GET_REGULAR_GAMESCHEDULE: { url: 'game/monthschedule', method: method.GET },
    GET_REGULAR_GAMESCHEDULE_ALL: { url: 'game/monthschedule/all', method: method.GET },
    GET_REGULAR_RECENT_GAMES: { url: 'game/recentGames', method: method.GET },
    GET_REGULAR_DAY_SCHEDULE: { url: 'game/dayschedule', method: method.GET },
    GET_REGULAR_SCORE_BOARD: { url: 'game/schedule/score', method: method.GET },
    GET_REGULAR_BOXSCORE: { url: 'game/boxscore', method: method.GET },
    GET_FUTURES_GAMESCHEDULE: { url: 'game/monthminorschedule', method: method.GET },
    GET_FUTURES_GAMESCHEDULE_ALL: { url: 'game/monthminorschedule/all', method: method.GET },
    GET_FUTURES_RECENT_GAMES: { url: 'game/recentMinorGames', method: method.GET },
    GET_FUTURES_DAY_SCHEDULE: { url: 'game/dayschedule', method: method.GET },
    GET_FUTURES_SCORE_BOARD: { url: 'game/schedule/score/minor', method: method.GET },
    GET_FUTURES_BOXSCORE: { url: 'game/boxscore/minor', method: method.GET },
    GET_PITCHER_RANKING_LIST: { url: 'game/ranking/pitcher', method: method.GET },
    GET_FUTURES_PITCHER_RANKING_LIST: { url: 'game/ranking/pitcher/minor', method: method.GET },
    GET_BATTER_RANKING_LIST: { url: 'game/ranking/batter', method: method.GET },
    GET_FUTURES_BATTER_RANKING_LIST: { url: 'game/ranking/batter/minor', method: method.GET },
    GET_TRACKING_LIST: { url: 'media/trackingList', method: method.GET },
    GET_INTERACTIVE_LIST: { url: 'media/interactiveList', method: method.GET },
    GET_HIGHLIGHT_LIST: { url: 'highlight/vodList', method: method.GET },
    GET_TEAM_CROWD_RANKING_LIST: { url: 'game/ranking/crowd', method: method.GET },
    GET_TEAM_RANKING_LIST: { url: 'game/teamrankbyyear', method: method.GET },
    GET_TEAM_PITCHING_RANKING_LIST: { url: 'game/ranking/team/pitching', method: method.GET },
    GET_TEAM_BATTING_RANKING_LIST: { url: 'game/ranking/team/batting', method: method.GET },
    GET_TEAM_WINLOSE_LIST: { url: 'game/team/vs', method: method.GET },
    GET_WATCH_POINT: { url: 'game/watchPoint', method: method.GET },
    GET_FUTURES_TEAM_RANKING_LIST: { url: 'game/teamrankbyyear/minor', method: method.GET },
    GET_WIZ_STORY_LIST: { url: 'guide/wizStory', method: method.GET },
    GET_WIZ_STORY_DETAIL: { url: 'guide/wizStory/detail', method: method.GET },
    GET_TEAM_RANK: { url: 'game/ktwizteamrank', method: method.GET },
    GET_PERIOD_TEAM_RANK: { url: 'game/periodteamrank', method: method.GET },
    GET_PERIOD_MINOR_TEAM_RANK: { url: 'game/periodteamrank/minor', method: method.GET },
    USER_ID_CHECK: { url: 'user/id/check', method: method.POST },
    GET_AUTOSCREENSTATUS: { url: 'ai/non/autoscreenStatus', method: method.GET },
    GET_YEARLIST: { url: 'game/yearcombo', method: method.GET }
  }
}

export default wiz;
