import { put, all, call, fork, takeEvery } from 'redux-saga/effects'
import { getWallpaper } from '../../api/ktwiz/wallpaper'
import * as actions from '../../modules/ktwiz/wallpaper'

function* requestWallpaper(action) {
  const response = yield call(getWallpaper, action.seq);

  if (response.data.status !== 0) {
    yield call(alert, response.data.message)
    yield put({ type: actions.WALLPAPER_FAILURE })
  } else {
    const wallpaper = response.data.data.wallpaper

    yield put({ type: actions.WALLPAPER_SUCCESS, wallpaper: wallpaper })
  }
}

function* watchWallpaperRequest() {
  yield takeEvery(actions.WALLPAPER_REQUEST, requestWallpaper)
}

export default function* wallpaperSaga() {
  yield all([
    fork(watchWallpaperRequest),
  ])
}
