import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { wallpaperRequest } from '../../modules/ktwiz/wallpaper';
import Wallpaper from '../../components/ktwiz/Wallpaper';

const WallpaperContainer = () => {

  const dispatch = useDispatch()

  const wallpaper = useSelector(state => state.wallpaper)

  const onWallpaperRequest = (seq) => dispatch(wallpaperRequest(seq))

  useEffect(() => {
    dispatch(wallpaperRequest())
  }, [])

  return (
    wallpaper.wallpaper != null ? <Wallpaper wallpaper={wallpaper.wallpaper} onRequestWallpaper={onWallpaperRequest} /> : ''
  )
}

export default WallpaperContainer
