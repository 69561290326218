import ContentTemplate from 'components/common/ContentTemplate'
import PageTemplate from 'components/common/PageTemplate'
import IndiLogContainer from 'containers/auth/IndiLogContainer'
import React from 'react'

const IndiLogPage = () => {
  return (
    <PageTemplate>
        <ContentTemplate className='sign_up_step1'>
        <IndiLogContainer />
        </ContentTemplate>
    </PageTemplate>
  )
}

export default IndiLogPage