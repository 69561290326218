import React from 'react'
import ContentTemplate from '../../components/common/ContentTemplate'
import SubContentTemplate from '../../components/common/SubContentTemplate'
import PageHeaderContainer from '../../containers/common/PageHeaderContainer'
import PageTitleTemplate from '../../components/common/PageTitleTemplate'
import BreadcrumbContainer from '../../containers/common/BreadcrumbContainer'
import PageTemplate from '../../components/common/PageTemplate'
import { KTWIZ_HISTORY, KTWIZ_INTRO_TAB } from '../../constants/page'
import History from '../../components/ktwiz/about/History'

// const tabs = [
//   { title: '구단 소개', link: '/ktwiz/about', isActive: false },
//   { title: '구단 연혁', link: '/ktwiz/history', isActive: true },
// ]

const AboutPage = ({ match }) => {
  return (
    <PageTemplate className="ktwiz">
      <PageHeaderContainer title="kt wiz는?" subtitle="한국 프로야구의 ‘10번째 심장’ kt wiz를 소개합니다!" image={require('../../assets/img/ktwiz/sub_tit_ktwiz.png')} forwardTabs={KTWIZ_INTRO_TAB} currentPath={match.path} />
      <ContentTemplate>
        <PageTitleTemplate>
          <BreadcrumbContainer page={KTWIZ_HISTORY} />
        </PageTitleTemplate>
        <SubContentTemplate className="ktwiz team history">
          <History />
        </SubContentTemplate>
      </ContentTemplate>
    </PageTemplate>
  )
}

export default AboutPage
