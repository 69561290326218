// action types
export const WALLPAPER_REQUEST = 'wallpaper/WALLPAPER_REQUEST'
export const WALLPAPER_SUCCESS = 'wallpaper/WALLPAPER_SUCCESS'
export const WALLPAPER_FAILURE = 'wallpaper/WALLPAPER_FAILURE'

// action creators
export const wallpaperRequest = (seq) => ({ type: WALLPAPER_REQUEST, seq: seq })
export const wallpaperSuccess = () => ({ type: WALLPAPER_SUCCESS })
export const wallpaperFailure = () => ({ type: WALLPAPER_FAILURE })

// reducers
const initialState = {
  wallpaper: null,
  error: null,
}

export const wallpaper = (state = initialState, action) => {
  switch (action.type) {
    case WALLPAPER_REQUEST:
      return {
        ...state
      }
    case WALLPAPER_SUCCESS:
      return {
        ...state,
        wallpaper: action.wallpaper
      }
    case WALLPAPER_FAILURE:
      return {
        ...state,
        error: action.error
      }
    default:
      return state
  }
}

export default wallpaper
