import React from 'react'

const NoSearchResult = () => {
  return (
    <div className="txt_wrap">
      <p style={{ textAlign: "center", padding: "20px" }}>
        검색 결과가 없습니다.
        </p>
    </div>
  )
}

export default NoSearchResult
