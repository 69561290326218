import React from 'react'
import Link from '../../common/Link'

const SongPcPlayerListItem = ({ playerSongList, onClickIsPlayerPopup }) => {

  return (
    <>
      {
        playerSongList &&
        playerSongList.map((v, i) => (

          <li key={i}>
            <article className="article">
              <div className="tit_img">
                <img src={v.playerPrvwImg}
                  onError={(e) => {
                    e.target.onError = null
                    e.target.src = require('../../../assets/img/player/no_img_cheersong_player.png')
                  }}
                  alt={`${v.playerName} 선수`} />
              </div>
              <div className="tit_detail">
                <strong>{`${v.playerName} 선수`}</strong>
                <span>응원가</span>
                <Link onClick={() => onClickIsPlayerPopup(true, {
                  playerName: v.playerName,
                  playerPrvwImg: v.playerPrvwImg,
                  cheerSongUrl: v.cheerSongUrl
                })} className="btn_song_list_play"></Link>
              </div>
            </article>
          </li>

        ))
      }

    </>
  )
}

export default SongPcPlayerListItem
