import React, { useEffect } from 'react'
import TitleBanner from '../../components/common/TitleBanner'
import PageSubTab from '../../components/common/PageSubTab'
import PageForwardSubTab from '../../components/common/PageForwardSubTab'

const PageHeaderContainer = ({ title, subtitle, image, tabs, subTabs, onClickTab, onClickSubTab, forwardTabs, forwardSubtabs, currentPath, currentSubPath }) => {

  useEffect(() => { })

  return (
    <>
      <TitleBanner title={title} subtitle={subtitle} image={image} tabs={tabs} forwardTabs={forwardTabs} onClickTab={onClickTab} currentPath={currentPath} />

      {subTabs && <PageSubTab subTabs={subTabs} onClickSubTab={onClickSubTab} />}
      {forwardSubtabs && <PageForwardSubTab forwardSubtabs={forwardSubtabs} currentSubPath={currentSubPath} />}
    </>
  )
}

export default PageHeaderContainer
