import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import MyInfo from "../../components/mypage/MyInfo";
import { myInfoRequest, updateMyInfoRequest, withdrawRequest, updateTermsRequest } from "../../modules/mypage/myinfo";
import { getLoginUser } from "../../modules/utils/auth";
import { useHistory } from "react-router-dom";

const MyInfoContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const login = useSelector((state) => state.login);
  const myInfo = useSelector((state) => state.myInfo);
  const [encodedTerms, setEncodedTerms] = useState(null);

  const onMyInfoUpdateRequest = (user) => dispatch(updateMyInfoRequest(user));

  const onWithdrawRequest = (withdraw) => dispatch(withdrawRequest(withdraw));

  const onUpdateTermsRequest = (terms) => dispatch(updateTermsRequest(terms));

  useEffect(() => {
    if (login.user == null || login.user.tid == null) {
      if (window.confirm("로그인이 필요한 서비스 입니다.\n로그인 하시겠습니까?")) {
        window.location.assign("/login");
      } else {
        window.location.assign("/");
      }
    }

    const loginUser = getLoginUser();
    if (loginUser == null) {
      history.push("/");
    } else if (loginUser.confirm == null || loginUser.confirm === false) {
      history.push("/mypage/check");
    } else {
      dispatch(myInfoRequest());
    }
  }, [dispatch, history, login.user]);

  useEffect(() => {
    console.log(myInfo);
    if (myInfo.info !== null) {
      const {
        info: { terms },
      } = myInfo;

      const tempTermsObj = {};

      terms.forEach((item) => {
        tempTermsObj[item.termsKind] = item;
      });

      setEncodedTerms(tempTermsObj);
    }
  }, [myInfo]);

  if (myInfo.info == null) {
    return null;
  } else {
    return myInfo.info && encodedTerms && <MyInfo info={myInfo.info} terms={encodedTerms} onMyInfoUpdateRequest={onMyInfoUpdateRequest} onWithdrawRequest={onWithdrawRequest} onUpdateTermsRequest={onUpdateTermsRequest} />;
  }
};

export default MyInfoContainer;
