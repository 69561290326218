import React from 'react'

const SongMobilePopupLyrics = ({ lyricsPopupData, onClickIsLyricsPopup, windowHeight }) => {
  return (
    <>
      <div className="popup_song popup_lyrics" id="popup_lyrics" style={{ top: windowHeight * 0.5 }}>
        <h6>가사보기</h6>
        <div className="popup_content_box">
          <article className="article">
            <div className="lyrics">
              <strong>{lyricsPopupData && lyricsPopupData.cheerSongTitle}</strong>
              <p>
                {
                  (lyricsPopupData && lyricsPopupData.cheerSongLyrics) &&
                  lyricsPopupData.cheerSongLyrics.split('\n').map((v, i) => (
                    <React.Fragment key={i}>
                      {v}<br />
                    </React.Fragment>
                  ))
                }
              </p>
            </div>
          </article>
          <button className="btn_close lyrics_close" onClick={() => onClickIsLyricsPopup(false)}>팝업 닫기 버튼</button>
        </div>
        <button className="btn_close" onClick={() => onClickIsLyricsPopup(false)}>팝업 닫기 버튼</button>
      </div>
      <div className="pop_back_black"></div>
    </>
  )
}

export default SongMobilePopupLyrics
