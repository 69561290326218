import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { resourceRequest, useResource } from '../../modules/resource/resource'
import * as dialogActions from "../../modules/common/dialog"
import Prepairing from '../common/Prepairing'

const TicketPrice = () => {
  const TICKET_PRICE_IMAGE_PC = 'TICKET_PRICE_IMAGE_PC'
  const TICKET_PRICE_IMAGE_M = 'TICKET_PRICE_IMAGE_M'
  const [imagePc, errorPc] = useResource(TICKET_PRICE_IMAGE_PC)
  const [imageM, errorM] = useResource(TICKET_PRICE_IMAGE_M)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(resourceRequest(TICKET_PRICE_IMAGE_PC))
    dispatch(resourceRequest(TICKET_PRICE_IMAGE_M))
  }, [])

  useEffect(() => {
    if (errorPc && errorPc.message) {
      dispatch(dialogActions.alertOpen(errorPc.message))
    }
    if (errorM && errorM.message) {
      dispatch(dialogActions.alertOpen(errorM.message))
    }
  }, [errorPc, errorM])

  // const isPrePage = process.env.REACT_APP_IS_PREPAIRING_PAGE
     const isPrePage = "N"
  return (
    <>
      {
        isPrePage === "Y" &&
        <Prepairing />
      }

      {
        isPrePage === "N" &&
        <>
          <article class='arti'>
            <div class='tit'>
              <h6>입장 요금</h6>
            </div>
            <div class='desc'>
              <div class='img-whole'>
                <img class='pc' src={`${imagePc && imagePc.resource && imagePc.resource.value}`.replace('localhost:8080', 'test.ktwiz.co.kr:17400')} alt="" />
                <img class='m' src={`${imageM && imageM.resource && imageM.resource.value}`.replace('localhost:8080', 'test.ktwiz.co.kr:17400')} alt="" />
              </div>
            </div>
          </article>
        </>
      }
    </>
  )
}

export default TicketPrice
