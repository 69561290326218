import { getLoginUser, setLoginUser } from "../utils/auth";
// action types

export const PASSWORD_CONFIRM_REQUEST = "myinfo/PASSWORD_CONFIRM_REQUEST";
export const PASSWORD_CONFIRM_SUCCESS = "myinfo/PASSWORD_CONFIRM_SUCCESS";
export const PASSWORD_CONFIRM_FAILURE = "myinfo/PASSWORD_CONFIRM_FAILURE";

export const MY_INFO_REQUEST = "myinfo/MY_INFO_REQUEST";
export const MY_INFO_SUCCESS = "myinfo/MY_INFO_SUCCESS";
export const MY_INFO_FAILURE = "myinfo/MY_INFO_FAILURE";

export const MY_INFO_UPDATE_REQUEST = "myinfo/MY_INFO_UPDATE_REQUEST";
export const MY_INFO_UPDATE_SUCCESS = "myinfo/MY_INFO_UPDATE_SUCCESS";
export const MY_INFO_UPDATE_FAILURE = "myinfo/MY_INFO_UPDATE_FAILURE";

export const WITHDRAW_REQUEST = "myinfo/WITHDRAW_REQUEST";
export const WITHDRAW_SUCCESS = "myinfo/WITHDRAW_SUCCESS";
export const WITHDRAW_FAILURE = "myinfo/WITHDRAW_FAILURE";

//약관 수정 actions
//21.04.01 추가
//auth : 이지훈

export const UPDATE_TERMS_REQUEST = "myinfo/UPDATE_TERMS_REQUEST";
export const UPDATE_TERMS_SUCCESS = "myinfo/UPDATE_TERMS_SUCCESS";
export const UPDATE_TERMS_FALURE = "myinfo/UPDATE_TERMS_FALURE";

// action creators
export const confirmPassword = (user) => ({ type: PASSWORD_CONFIRM_REQUEST, user: user });
export const myInfoRequest = () => ({ type: MY_INFO_REQUEST });
export const updateMyInfoRequest = (user) => ({ type: MY_INFO_UPDATE_REQUEST, user: user });
export const withdrawRequest = (withdraw) => ({ type: WITHDRAW_REQUEST, withdraw: withdraw });
export const updateTermsRequest = (terms) => ({ type: UPDATE_TERMS_REQUEST, terms: terms });

// reducers
const myInfoInitialState = {
  info: null,
  user: null,
  withdraw: null,
  memberPwConfirm: false,
  error: null,
};

export const myInfo = (state = myInfoInitialState, action) => {
  switch (action.type) {
    case MY_INFO_REQUEST:
      return {
        ...state,
      };
    case MY_INFO_SUCCESS:
      return {
        ...state,
        info: action.info,
      };
    case MY_INFO_FAILURE:
      return {
        ...state,
      };
    case MY_INFO_UPDATE_REQUEST:
      return {
        ...state,
        user: action.user,
      };
    case MY_INFO_UPDATE_SUCCESS:
      return {
        ...state,
      };
    case MY_INFO_UPDATE_FAILURE:
      return {
        ...state,
      };
    case WITHDRAW_REQUEST:
      return {
        ...state,
        withdraw: action.withdraw,
      };
    case WITHDRAW_SUCCESS:
      return {
        ...state,
      };
    case WITHDRAW_FAILURE:
      return {
        ...state,
      };
    case PASSWORD_CONFIRM_REQUEST:
      return {
        ...state,
        user: action.user,
      };
    case PASSWORD_CONFIRM_SUCCESS:
      const sessionUser = getLoginUser();
      sessionUser.confirm = true;
      setLoginUser(sessionUser);
      return {
        ...state,
        memberPwConfirm: action.memberPwConfirm,
      };
    case PASSWORD_CONFIRM_FAILURE:
      return {
        ...state,
      };
    case UPDATE_TERMS_REQUEST:
      return {
        ...state,
      };
    case UPDATE_TERMS_SUCCESS:
      return {
        ...state,
      };
    case UPDATE_TERMS_FALURE:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default myInfo;
