import client from "../client";
import qs from "query-string"

/**
 * 인증요청 API
 * @param {*} updateUserInfo
 */
export const resetPw = (updateUserInfo) => {

  const data = qs.stringify({
    simpleId: updateUserInfo.simpleId,
    newPw: updateUserInfo.newPw
  })

  return client.post("/api/v2/user/web/pw/update", data)
    .then(response => {
      console.log('### CMS API :: /api/v2/user/web/pw/update ###', response.data)
      return response.data
    })
    // TODO : 에러 어떻게 처리할 것인지?
    .catch(error => {
      console.log(error);
      return error
    })

}

