import React from 'react'
import BatterDetailSecondPcTabRegular from './BatterDetailSecondPcTabRegular'
import BatterDetailSecondPcTabFutures from './BatterDetailSecondPcTabFutures'
import BatterDetailSecondPcTabLastest from './BatterDetailSecondPcTabLastest'
import BatterDetailSecondPcTabTotal from './BatterDetailSecondPcTabTotal'

const dataTables = {
  BATTER_DETAIL_REGULAR_SEASON_PC: {
    title: '시즌 정규리그 기록',
    data: [
      { lineNum: 1, cols: '*', head: '타율', bodyParam: 'hra' },
      { lineNum: 1, cols: '78px', head: '경기', bodyParam: 'gamenum' },
      { lineNum: 1, cols: '78px', head: '타석', bodyParam: 'pa' },
      { lineNum: 1, cols: '78px', head: '타수', bodyParam: 'ab' },
      { lineNum: 1, cols: '78px', head: '득점', bodyParam: 'run' },
      { lineNum: 1, cols: '78px', head: '안타', bodyParam: 'hit' },
      { lineNum: 1, cols: '78px', head: '2루타', bodyParam: 'h2' },
      { lineNum: 1, cols: '78px', head: '3루타', bodyParam: 'h3' },
      { lineNum: 1, cols: '78px', head: '홈런', bodyParam: 'hr' },
      { lineNum: 1, cols: '78px', head: '타점', bodyParam: 'rbi' },
      { lineNum: 1, cols: '78px', head: '도루', bodyParam: 'sb' },
      { lineNum: 1, cols: '78px', head: '도실', bodyParam: 'cs' },
      { lineNum: 1, cols: '78px', head: '희타', bodyParam: 'sh' },
      { lineNum: 1, cols: '78px', head: '희비', bodyParam: 'sf' },
      { lineNum: 2, cols: '84px', head: '볼넷', bodyParam: 'bb' },
      { lineNum: 2, cols: '84px', head: '고의4구', bodyParam: 'ib' },
      { lineNum: 2, cols: '84px', head: '사구', bodyParam: 'hp' },
      { lineNum: 2, cols: '84px', head: '삼진', bodyParam: 'kk' },
      { lineNum: 2, cols: '84px', head: '병살', bodyParam: 'gd' },
      { lineNum: 2, cols: '84px', head: '장타율', bodyParam: 'slg' },
      { lineNum: 2, cols: '84px', head: '출루율', bodyParam: 'bra' },
      { lineNum: 2, cols: '84px', head: '실책', bodyParam: 'cs' },
      { lineNum: 2, cols: '84px', head: '도루성공률', bodyParam: 'sba' },
      { lineNum: 2, cols: '84px', head: 'BB/K', bodyParam: 'bbkk' },
      { lineNum: 2, cols: '84px', head: '장타/안타', bodyParam: 'xbhrun' },
      { lineNum: 2, cols: '84px', head: 'OPS', bodyParam: 'ops' },
      { lineNum: 2, cols: '*', head: '득점권타율', bodyParam: 'spHra' },
    ]
  },
  BATTER_DETAIL_FUTURES_PC: {
    title: ['시즌 퓨처스리그 기록', '퓨처스리그 최근 5경기 기록'],
    data: [
      [
        { cols: '*', head: '타율', bodyParam: 'hra' },
        { cols: '73px', head: '경기', bodyParam: 'gamenum' },
        { cols: '73px', head: '타수', bodyParam: 'ab' },
        { cols: '73px', head: '득점', bodyParam: 'run' },
        { cols: '73px', head: '안타', bodyParam: 'hit' },
        { cols: '73px', head: '2루타', bodyParam: 'h2' },
        { cols: '73px', head: '3루타', bodyParam: 'h3' },
        { cols: '73px', head: '홈런', bodyParam: 'hr' },
        { cols: '73px', head: '타점', bodyParam: 'rbi' },
        { cols: '73px', head: '도루', bodyParam: 'sb' },
        { cols: '73px', head: '볼넷', bodyParam: 'bb' },
        { cols: '73px', head: '사구', bodyParam: 'hp' },
        { cols: '73px', head: '삼진', bodyParam: 'kk' },
        { cols: '74px', head: '장타율', bodyParam: 'slg' },
        { cols: '74px', head: '출루율', bodyParam: 'bra' },
      ],
      [
        { cols: '80px', head: '일자', bodyParam: 'displayDate' },
        { cols: '67px', head: '상대', bodyParam: 'matchTeamName' },
        { cols: '*', head: '타율', bodyParam: 'hra' },
        { cols: '67px', head: '타수', bodyParam: 'ab' },
        { cols: '67px', head: '득점', bodyParam: 'run' },
        { cols: '67px', head: '안타', bodyParam: 'hit' },
        { cols: '67px', head: '2루타', bodyParam: 'h2' },
        { cols: '67px', head: '3루타', bodyParam: 'h3' },
        { cols: '67px', head: '홈런', bodyParam: 'hr' },
        { cols: '67px', head: '타점', bodyParam: 'rbi' },
        { cols: '67px', head: '도루', bodyParam: 'sb' },
        { cols: '67px', head: '도실', bodyParam: 'cs' },
        { cols: '67px', head: '볼넷', bodyParam: 'bb' },
        { cols: '67px', head: '사구', bodyParam: 'hp' },
        { cols: '67px', head: '삼진', bodyParam: 'kk' },
        { cols: '67px', head: '병살', bodyParam: 'gd' },
      ]
    ]
  },
  BATTER_DETAIL_LASTEST_GAME_PC: {
    title: '최근 5경기 기록',
    data: [
      { cols: '80px', head: '일자', bodyParam: 'displayDate' },
      { cols: '67px', head: '상대', bodyParam: 'matchTeamName' },
      { cols: '*', head: '타율', bodyParam: 'hra' },
      { cols: '67px', head: '타수', bodyParam: 'ab' },
      { cols: '67px', head: '득점', bodyParam: 'run' },
      { cols: '67px', head: '안타', bodyParam: 'hit' },
      { cols: '67px', head: '2루타', bodyParam: 'h2' },
      { cols: '67px', head: '3루타', bodyParam: 'h3' },
      { cols: '67px', head: '홈런', bodyParam: 'hr' },
      { cols: '67px', head: '타점', bodyParam: 'rbi' },
      { cols: '67px', head: '도루', bodyParam: 'sb' },
      { cols: '67px', head: '도실', bodyParam: 'cs' },
      { cols: '67px', head: '볼넷', bodyParam: 'bb' },
      { cols: '67px', head: '사구', bodyParam: 'hp' },
      { cols: '67px', head: '삼진', bodyParam: 'kk' },
      { cols: '67px', head: '병살', bodyParam: 'gd' },
    ]
  },
  BATTER_DETAIL_TOTAL_PC: {
    title: '통산 기록 (kt)',
    data: [
      { cols: '58px', head: '연도', bodyParam: 'gyear' },
      { cols: '57px', head: '팀', bodyParam: 'teamName' },
      { cols: '*', head: '타율', bodyParam: 'hra' },
      { cols: '57px', head: '경기', bodyParam: 'gamenum' },
      { cols: '57px', head: '타수', bodyParam: 'ab' },
      { cols: '57px', head: '득점', bodyParam: 'run' },
      { cols: '57px', head: '안타', bodyParam: 'hit' },
      { cols: '57px', head: '2루타', bodyParam: 'h2' },
      { cols: '57px', head: '3루타', bodyParam: 'h3' },
      { cols: '57px', head: '홈런', bodyParam: 'hr' },
      { cols: '57px', head: '타점', bodyParam: 'rbi' },
      { cols: '57px', head: '도루', bodyParam: 'sb' },
      { cols: '57px', head: '도실', bodyParam: 'cs' },
      { cols: '57px', head: '볼넷', bodyParam: 'bb' },
      { cols: '57px', head: '사구', bodyParam: 'hp' },
      { cols: '57px', head: '삼진', bodyParam: 'kk' },
      { cols: '57px', head: '병살', bodyParam: 'gd' },
      { cols: '62px', head: '장타율', bodyParam: 'slg' },
      { cols: '62px', head: '출루율', bodyParam: 'bra' },
    ]
  },
}

const BatterDetailSecondPc = ({ contentType, data, isFuturesLeague, onClickIsFuturesLeague, selectedGyear }) => {

  const seasonsummary = data && data.seasonsummary
  const recentgamerecordlist = data && data.recentgamerecordlist
  const yearrecordlist = data && data.yearrecordlist

  const seasonsummaryfutures = data && data.seasonsummaryfutures
  const recentgamerecordlistfutures = data && data.recentgamerecordlistfutures

  return (
    <>
      {/* 정규리그 기록 */}
      {
        (contentType === 'regular_league' && !isFuturesLeague) &&
        <BatterDetailSecondPcTabRegular dataTable={dataTables.BATTER_DETAIL_REGULAR_SEASON_PC} seasonsummary={seasonsummary} onClickIsFuturesLeague={onClickIsFuturesLeague} selectedGyear={selectedGyear} />
      }

      {/* 퓨처스리그 기록 */}
      {
        (contentType === 'regular_league' && isFuturesLeague) &&
        <BatterDetailSecondPcTabFutures dataTable={dataTables.BATTER_DETAIL_FUTURES_PC} seasonsummary={seasonsummaryfutures} recentgamerecordlist={recentgamerecordlistfutures} onClickIsFuturesLeague={onClickIsFuturesLeague} selectedGyear={selectedGyear} />
      }

      {/* 최근 5경기 */}
      {
        contentType === 'latest_game' &&
        <BatterDetailSecondPcTabLastest dataTable={dataTables.BATTER_DETAIL_LASTEST_GAME_PC} recentgamerecordlist={recentgamerecordlist} />
      }

      {/* 통산 기록 */}
      {
        contentType === 'total' &&
        <BatterDetailSecondPcTabTotal dataTable={dataTables.BATTER_DETAIL_TOTAL_PC} yearrecordlist={yearrecordlist} />
      }
    </>
  )
}

export default BatterDetailSecondPc
