import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import JoinStep1 from '../../components/auth/join/JoinStep1'
import JoinStep2 from '../../components/auth/join/JoinStep2'
import JoinStep3 from '../../components/auth/join/JoinStep3'
import JoinStep4 from '../../components/auth/join/JoinStep4'
import JoinComplete from '../../components/auth/join/JoinComplete'
import { selectUserType, joinTermRequest, setApprovedTerms, /*checkUserIdRequest, authNoRequest,*/ joinRequest, setJoinUser, init } from '../../modules/join/join'
import { certRequest, certConfirmRequest } from '../../modules/auth/cert'
import { CERT_REQ_TYPES, CERT_TYPES } from '../../constants/enum'

const JoinContainer = () => {
  const dispatch = useDispatch()
  const join = useSelector(state => state.join)
  const joinFormCheck = useSelector(state => state.joinFormCheck)
  const cert = useSelector(state => state.cert)

  const onSelectUserType = userType => dispatch(selectUserType(userType))

  const onApproveTerms = agreeTerms => dispatch(setApprovedTerms(agreeTerms))

  const onCertNoRequest = mobpNo => dispatch(certRequest({ reqType: CERT_REQ_TYPES.MYPAGE, certType: CERT_TYPES.MOBILE, mobpNo: mobpNo }))

  const onConfirmCertNo = (mobpNo, certSeq, certConfirmNo) => dispatch(certConfirmRequest({ reqType: CERT_REQ_TYPES.MYPAGE, certType: CERT_TYPES.MOBILE, mobpNo: mobpNo, certSeq: certSeq, certNo: certConfirmNo }, true, true))

  const onIdentifyCallback = (type, data) => {
    const joinUser = { ...join.joinUser }
    joinUser.realNameAuthType = type
    joinUser.certData = data
    dispatch(joinRequest(joinUser))
  }

  const onJoinSubmit = joinUser => {
    dispatch(setJoinUser(joinUser))
  }

  useEffect(() => {
    dispatch(joinTermRequest())
    dispatch(init())
  }, [dispatch])

  useEffect(() => {
    if (join.error != null) {
      alert(join.error.message)
      join.error = null
    }
  }, [join.error])

  console.log(join)

  if (join.joinUser.isAdult == null) {
    return <JoinStep1 onSelectUserType={onSelectUserType} />
  } else if (join.joinUser.isAdult != null && join.approvedTerms == null) {
    return <JoinStep2 join={join} onApproveTerms={onApproveTerms} />
  } else if (join.approvedTerms != null && join.joinUser.simpleId == null) {
    return <JoinStep3 join={join} joinFormCheck={joinFormCheck} cert={cert} onCertNoRequest={onCertNoRequest} onConfirmCertNo={onConfirmCertNo} onJoinSubmit={onJoinSubmit} />
  } else if (join.memberId == null) {
    return <JoinStep4 join={join} onIdentifyCallback={onIdentifyCallback} />
  } else if (join.memberId != null) {
    return <JoinComplete join={join} />
  }
}

export default JoinContainer
