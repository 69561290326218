import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import GameSchedule from '../../components/game/GameSchedule'
import { regularGameScheduleRequest, regularGameAllScheduleRequest, recentRegularGamesRequest, regularDayScheduleRequest, regularScoreBoardRequest } from '../../modules/game/gameSchedule'
import format from 'date-fns/format'
import { useHistory, useRouteMatch } from 'react-router-dom'

const RegularLeagueGameScheduleContainer = () => {

  const history = useHistory()

  const yearMonthParam = useRouteMatch('/game/regular/schedule/:yearMonth');

  const schedule = useSelector(state => state.wizData.data && state.wizData.data['GAME_SCHEDULE_REGULAR'])
  // const scheduleError = useSelector(state => state.wizData.error && state.wizData.error['GAME_SCHEDULE_REGULAR'])

  const scheduleAll = useSelector(state => state.wizData.data && state.wizData.data['GAME_ALL_SCHEDULE_REGULAR'])
  // const scheduleAllError = useSelector(state => state.wizData.error && state.wizData.error['GAME_ALL_SCHEDULE_REGULAR'])

  const recentGames = useSelector(state => state.wizData.data && state.wizData.data['RECENT_REGULAR_GAMES'])
  // const recentGamesError = useSelector(state => state.wizData.error && state.wizData.error['RECENT_REGULAR_GAMES'])

  const daySchedule = useSelector(state => state.wizData.data && state.wizData.data['DAY_SCHEDULE_REGULAR'])
  // const dayScheduleError = useSelector(state => state.wizData.error && state.wizData.error['DAY_SCHEDULE_REGULAR'])

  const scoreBoard = useSelector(state => state.wizData.data && state.wizData.data['SCORE_BOARD_REGULAR'])
  // const scoreBoardError = useSelector(state => state.wizData.error && state.wizData.error['SCORE_BOARD_REGULAR'])

  const onScoreBoardRequest = (gmkey, gameDate) => dispatch(regularScoreBoardRequest(gmkey, gameDate))

  const onChangeMonth = (yearMonth) => { history.push('/game/regular/schedule/' + yearMonth) }

  const dispatch = useDispatch()

  const yearMonth = (yearMonthParam && yearMonthParam.params.yearMonth) ? yearMonthParam.params.yearMonth : format(new Date(), 'yyyyMM')

  useEffect(() => {
    dispatch(recentRegularGamesRequest())
    dispatch(regularDayScheduleRequest())
  }, [])

  useEffect(() => {
    dispatch(regularGameScheduleRequest(yearMonth))
    dispatch(regularGameAllScheduleRequest(yearMonth))
  }, [yearMonth])

  return (
    <GameSchedule league="regular" onChangeMonth={onChangeMonth} onScoreBoardRequest={onScoreBoardRequest} year={yearMonth.substring(0, 4)} month={yearMonth.substring(4, 6)} recentGames={recentGames} scheduleData={schedule} daySchedule={daySchedule} allTeamSchedules={scheduleAll} scoreBoard={scoreBoard} />
  )
}

export default RegularLeagueGameScheduleContainer
