import React from 'react'
import Link from '../common/Link'
// import { createRequireFromPath } from 'module'

const Location = () => {
  return (
    <>
      <div class='map-wrap'>
        <img class='pc' src={require('../../assets/img/park/map_img.png')} alt="" />
        <img class='m' src={require('../../assets/img/park/map_img_m.png')} alt="" />
      </div>
      <table class='guide'>
        <tbody>
          <tr>
            <th>
              <img src={require('../../assets/img/park/map_pin.png')} alt="" />
              <span class='txt'>
                주소
                  </span>
            </th>
            <td>
              <ul>
                <li className='first'>
                  <p>
                    <span>주소 : 경기도 수원시 장안구 경수대로 893(조원동) 수원</span>
                    <span>케이티 위즈 파크 (구 : 경기도 수원시 장안구 조원동 775)</span>
                  </p>
                </li>
                <li>
                  <Link to='http://naver.me/xAZRSRRd' isExt={true} className='btn_location'>찾아오기</Link>
                  <span className='notice'>※ 찾아오기 버튼을 누르시면, 출발지에서 야구장으로 가는 길을 쉽게 확인하실 수 있습니다.</span>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <th>
              <img src={require('../../assets/img/park/map_bus.png')} alt="" />
              <span class='txt'>
                버스
                  </span>
            </th>
            <td class='bus'>
              <div>
                <span class='chip green'>일반</span>
                <p>
                  25, 25-2, 27, 36, 55, 62-1, 99, 99-2, 300-1
                      </p>
              </div>
              <div>
                <span class='chip green'>일반</span>
                <p>
                  310, 777
                      </p>
              </div>
              <div>
                <span class='chip red'>직행</span>
                <p>
                  2007, 3000, 7770
                      </p>
              </div>
              <div>
                <span class='chip blue'>좌석</span>
                <p>
                  300, 900
                      </p>
              </div>
            </td>
          </tr>
          <tr>
            <th>
              <img src={require('../../assets/img/park/map_subway.png')} alt="" />
              <span class='txt'>
                지하철
                  </span>
            </th>
            <td>
              <p>
                화서역 하차 (택시로 10분)<br />
                · 37, 39번 버스이용 수성중 사거리 하차 후 도보 3분<br />
                · 55분 버스이용 종합운동장 하차 수원역하차 (택시로 20분)<br />
                · 1번, 5번, 8번 버스이용 수성중 사거리 하차 후 도보 3분<br />
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  )
}

export default Location
