import client from "../client";
import qs from "query-string"

/**
 * 인증요청 API
 * @param {*} userCert
 */
export const callCert = (userCert) => {

  console.log(userCert);

  const data = qs.stringify({

    reqType: userCert.reqType,
    certType: userCert.certType,
    mobpNo: userCert.mobpNo,
    memberEmail: userCert.memberEmail,
    simpleId: userCert.simpleId,

  })

  return client.post("/api/v2/user/web/authNo/request", data)
    .then(response => {

      console.log(response.data);

      // 성공 처리
      if ((response.status === 200 || response.status === "200") && response.data.status === 0) {
        console.log('###### API :: CERT REQUEST =>', response.data.data)
        return response.data.data
      }
      // 실패 처리
      else {
        return {
          error: {
            status: response.data.status,
            message: response.data.message
          }
        }
      }

    })
    // TODO : 에러 어떻게 처리할 것인지?
    .catch(error => {

      console.log(error);

      return {
        error
      }
    })

}

/**
 * 인증확인요청 API
 * @param {*} userCert
 */
export const callCertConfirm = (userCert) => {

  const data = qs.stringify({

    reqType: userCert.reqType,
    certType: userCert.certType,
    mobpNo: userCert.mobpNo,
    memberEmail: userCert.memberEmail,
    simpleId: userCert.simpleId,
    certSeq: userCert.certSeq,
    certNo: userCert.certNo

  })

  return client.post("/api/v2/user/web/checkAuthNo/request", data)
    .then(response => {

      console.log(response.data);

      // 성공 처리
      if ((response.status === 200 || response.status === "200") && response.data.status === 0) {
        console.log(`###### API :: CERT REQUEST => ${JSON.stringify(response.data.data)}`)
        return response.data.data
      }
      // 실패 처리
      else {
        return {
          error: {
            status: response.data.status,
            message: response.data.message
          }
        }
      }

    })
    // TODO : 에러 어떻게 처리할 것인지?
    .catch(error => {

      console.log(error);

      return {
        error
      }
    })

}
