import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { watchPointRequest } from '../../modules/game/ranking'
import WatchPoint from '../../components/game/WatchPoint'

const RegularLeagueWatchPointContainer = ({ match }) => {

  const watchPoint = useSelector(state => state.wizData.data && state.wizData.data['WATCH_POINT'])
  // const watchPointError = useSelector(state => state.wizData.error && state.wizData.error['WATCH_POINT'])

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(watchPointRequest({ 'gameSearch.gmkey': match.params.gameId, 'gameSearch.gameDate': match.params.gameDate }, 'WATCH_POINT'))
  }, [match.params.gameId, match.params.gmkey])


  return (
    <WatchPoint watchPoint={watchPoint} />
  )
}

export default RegularLeagueWatchPointContainer
