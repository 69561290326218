import ContentTemplate from "components/common/ContentTemplate";
import PageTemplate from "components/common/PageTemplate";
import React from "react";
import { useHistory } from "react-router-dom";
import { presentLog } from "../privacyPolicyText";

const MobileTermsPage = () => {
  const history = useHistory()

  const onClickBtn = () => {
    history.push('/mobile/menu')
  };

  return (
    <PageTemplate>
      <ContentTemplate className="sign_up_step1">
            <h1 className="indi_title">개인정보 수집·이용 동의</h1>
            {presentLog.map((item, idx) => (
              <p key={idx}>{item}</p>
            ))}
            <button className="btn_textlog" onClick={onClickBtn}>
              변경전 개인정보 수집·이용 동의 보기 &gt;
            </button>
      </ContentTemplate>
    </PageTemplate>
  );
};

export default MobileTermsPage;
