import { put, call, fork, take } from 'redux-saga/effects'
// import wiz from '../../api/wiz'
import * as actions from '../../modules/common/wizData'
// import * as loadingActions from '../../modules/common/loading'
// import * as dialogActions from '../../modules/common/dialog'

export function* fetch(api, params, type) {
  //yield put(loadingActions.setLoading(true));

  try {
    const response = yield call(api.method, { api: api.url, params, type });
    if (response.data.status !== 0) {
      const error = { message: response.data.message, status: response.data.status };
      yield put(actions.dataFailure(error, type));
    } else if (response.data.data.list) {
      yield put(actions.dataSuccess(response.data.data.list, type));
    } else {
      yield put(actions.dataSuccess(response.data.data, type));
    }
  } catch (error) {
    yield put(actions.dataFailure(error));
  }
  //yield put(loadingActions.setLoading(false));
}

export default function* wizDataSaga() {
  while (true) {
    const action = yield take(actions.WIZ_DATA_REQUEST);
    // starts the task in the background
    const { api, params, type } = action.req;
    yield fork(fetch, api, params, type);
  }
}
