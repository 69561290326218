import React, { useEffect, useState } from 'react'
import Link from '../common/Link'

const Wallpaper = ({ wallpaper, onRequestWallpaper }) => {

  const [wallpaperType, setWallpaperType] = useState('200')

  const [titleImage, setTitleImage] = useState('')

  useEffect(() => {
    setTitleImage(wallpaper.images.filter(image => image.type === wallpaperType).map(image => image.imagePath)[0])
  }, [wallpaper, wallpaperType])

  return (
    <div className='wall'>
      <div className='header'>
        <Link className={'btn-move prev' + (wallpaper.prevSeq ? '' : ' disabled')} onClick={() => onRequestWallpaper(wallpaper.prevSeq)}></Link>
        <div className='year-date'>
          {wallpaper.year}년 {wallpaper.month}월
        </div>
        <Link className={'btn-move next' + (wallpaper.nextSeq ? '' : ' disabled')} onClick={() => onRequestWallpaper(wallpaper.nextSeq)}></Link>
      </div>
      <div className='body'>
        <div className='tab-wrap'>
          <Link className={'tab' + (wallpaperType === '200' ? ' selected' : '')} onClick={() => setWallpaperType('200')}>달력형</Link>
          <Link className={'tab' + (wallpaperType === '100' ? ' selected' : '')} onClick={() => setWallpaperType('100')}>일반형</Link>
        </div>
        <div className='con'>
          <div className='img-wrap'>
            <img src={titleImage} alt='' />
          </div>
          <div className='buttons'>
            {
              wallpaper.images.map((image, i) => (
                image.type === wallpaperType && <a key={image.type + image.resolution} className={'btn-download wallpaper w' + image.resolution} href={`/api/v2/wallpaper/download?type=${image.type}&resolution=${image.resolution}&seq=${wallpaper.seq}`}>{image.resolution}</a>
              ))
            }
          </div>
        </div>
      </div >
    </div >
  )
}

export default Wallpaper
