import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fineDustListRequest } from '../../../modules/park/finedust'

const FineDust = ({ pm }) => {

  const dispatch = useDispatch()
  const data = useSelector(state => state.wizData.data && state.wizData.data['FINEDUST_LIST'])
  // const error = useSelector(state => state.wizData.error && state.wizData.error['FINEDUST_LIST'])

  useEffect(() => {
    dispatch(fineDustListRequest())
  }, [])

  console.log(data)

  const serialPoint = {
    'V10O1610554': 'point1',
    'V10O1610572': 'point3',
    'V10O1610568': 'point2',
    'V10O1610570': 'point4',
    'V10O1610571': 'point5',
    'V10O1610561': 'point6',
    'V10O1610569': 'point7',
    'V10O1610573': 'point8',
  }

  const gradeImage = {
    '1': 'good',
    '2': 'normal',
    '3': 'bad',
    '4': 'reallybad'
  }

  return (
    <>
      <div className="dust_stadium_wrap">
        <img src={require('../../../assets/img/park/dust_stadium.png')} alt='' />
        {data && data.map(dust => (
          <div key={dust.serial} className={'point ' + serialPoint[dust.serial] + ' ' + gradeImage[dust[pm]]}></div>
        ))}
      </div>
    </>
  )
}

export default FineDust
