import React from 'react'
import ContentTemplate from '../../components/common/ContentTemplate'
import SubContentTemplate from '../../components/common/SubContentTemplate'
import PageHeaderContainer from '../../containers/common/PageHeaderContainer'
import PageTitleTemplate from '../../components/common/PageTitleTemplate'
import BreadcrumbContainer from '../../containers/common/BreadcrumbContainer'
import PageTemplate from '../../components/common/PageTemplate'
import { TICKET_RESERVATION_AND_REFUND, TICKET_REGULAR_TAB } from '../../constants/page'
import ReservationAndRefund from '../../components/ticket/ReservationAndRefund'

const ReservationAndRefundPage = ({ match }) => {
  return (
    <PageTemplate className="ticket">
      <PageHeaderContainer title="일반티켓" subtitle="kt wiz 경기를 경기장에서 직접 만나 보세요." image={require('../../assets/img/ticket/sub_tit_ticket.png')} forwardTabs={TICKET_REGULAR_TAB} currentPath={match.path} />
      <ContentTemplate>
        <PageTitleTemplate>
          <BreadcrumbContainer page={TICKET_RESERVATION_AND_REFUND} />
        </PageTitleTemplate>
        <SubContentTemplate className="ticket regular reserve">
          <ReservationAndRefund />
        </SubContentTemplate>
      </ContentTemplate>
    </PageTemplate>
  )
}

export default ReservationAndRefundPage
