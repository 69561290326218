import React from 'react'
import ContentTemplate from '../../components/common/ContentTemplate'
import SubContentTemplate from '../../components/common/SubContentTemplate'
import PageHeaderContainer from '../../containers/common/PageHeaderContainer'
import PageTitleTemplate from '../../components/common/PageTitleTemplate'
import BreadcrumbContainer from '../../containers/common/BreadcrumbContainer'
import PageTemplate from '../../components/common/PageTemplate'
import { WIZPARK_LOCATION } from '../../constants/page'
import Location from '../../components/wizpark/Location'

const WizparkLocationPage = () => {
  return (
    <PageTemplate className="park">
      <PageHeaderContainer title="찾아오기" subtitle="오시는 길을 상세하게 알려드립니다." image={require('../../assets/img/park/sub_tit_park.png')} />
      <ContentTemplate>
        <PageTitleTemplate>
          <BreadcrumbContainer page={WIZPARK_LOCATION} />
        </PageTitleTemplate>
        <SubContentTemplate className="park map">
          <Location />
        </SubContentTemplate>
      </ContentTemplate>
    </PageTemplate>
  )
}

export default WizparkLocationPage
