export const BLOCK_RESET_PW = 'resetPw/BLOCK_RESET_PW'
export const RESET_PW_REQUEST = 'resetPw/RESET_PW_REQUEST'
export const RESET_PW_SUCCESS = 'resetPw/RESET_PW_SUCCESS'
export const RESET_PW_FAIL = 'resetPw/RESET_PW_FAIL'

export const blockResetPw = () => ({ type: BLOCK_RESET_PW })
export const resetPwRequest = (updateUserInfo) => ({ type: RESET_PW_REQUEST, updateUserInfo })
export const restPwSuccess = () => ({ type: RESET_PW_SUCCESS })
export const resetpwFail = () => ({ type: RESET_PW_FAIL })

const initialState = {
  isRequesting: false,
  isReqeusted: false
}

const resetPw = (state = initialState, action) => {
  switch (action.type) {
    case BLOCK_RESET_PW:
      return {
        ...state
      }
    case RESET_PW_REQUEST:
      return {
        ...state,
        isRequesting: true,
        isReqeusted: false
      }
    case RESET_PW_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        isReqeusted: true
      }
    case RESET_PW_FAIL:
      return {
        ...state,
        isRequesting: false,
        isReqeusted: false
      }
    default:
      return state
  }
}

export default resetPw
