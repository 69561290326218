import React from 'react'

const PlayerSearch = ({ onChangeName, onClickSearchName }) => {
  return (
    <div className='searchbox_wrap mb_hide'>
      <div className='searchbox_form active'>
        <form action='' method='' name='search_frm' onSubmit={(e) => { e.preventDefault(); onClickSearchName(); }}>
          <fieldset>
            <legend className='blind'>게시물 검색</legend>
            <div className='searchbox clearfix'>
              <div>
                <input type='text' name='keyword' className='float_l' placeholder='검색어를 입력해주세요' onChange={onChangeName} />
              </div>
              <button type='button' onClick={onClickSearchName}>검색</button>
            </div>
          </fieldset>
        </form>
      </div>
    </div >
  )
}

export default PlayerSearch
