import wiz from '../../api/wiz'
import { dataRequest } from '../common/wizData'

export const createFindDustListRequest = (params, dataType) => {
  return dataRequest(
    wiz.API.GET_FINEDUST_LIST,
    params,
    dataType
  )
}

export const fineDustListRequest = id => createFindDustListRequest({}, 'FINEDUST_LIST')
