import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import FirstFitcherInfoPage from './FirstFitcherInfoPage';
import HighlightPage from './HighlightPage';
import LivePage from './LivePage';
import WizNewsPage from './WizNewsPage';
import WizPhotoPage from './WizPhotoPage';
import WizStoryPage from './WizStoryPage';

function makeRoutes(pages) {
  console.log('○○○○○○○○ wiznews');
  return (
    <Switch>
      {pages.map(({ path, component, redirect }) => {
        return (
          redirect
            ? <Redirect key={path} to={redirect} />
            : <Route key={path} path={path} component={component} />
        )
      })}
      <Redirect to={pages[0].path} />
    </Switch>
  );
}

export default function WizMediaPage() {
  const pages = [
    { path: '/media/wiznews/:seq', title: 'wiz 뉴스', component: WizNewsPage },
    { path: '/media/wiznews', title: 'wiz 뉴스', component: WizNewsPage },
    { path: '/media/wizpress/:seq', title: 'wiz 보도자료', component: WizNewsPage },
    { path: '/media/wizpress', title: 'wiz 보도자료', component: WizNewsPage },
    { path: '/media/firstpitch/:seq', title: '시구자정보', component: FirstFitcherInfoPage },
    { path: '/media/firstpitch', title: '시구자정보', component: FirstFitcherInfoPage },
    { path: '/media/wizstory/:seq', title: 'wiz 스토리', component: WizStoryPage },
    { path: '/media/wizstory', title: 'wiz 스토리', component: WizStoryPage },
    { path: '/media/photos/:cat', title: 'wiz 포토', component: WizPhotoPage },
    { path: '/media/highlight/:seq', title: '하이라이트', component: HighlightPage },
    { path: '/media/highlight', title: '하이라이트', component: HighlightPage },
    { path: '/media/live/position', title: '포지션 뷰', component: LivePage },
    { path: '/media/live/matrix', title: '매트릭스 뷰', component: LivePage },
    { path: '/media/live/pts', title: '피칭분석', component: LivePage },
    { path: '/media/live/fts', title: '모션 트래킹', component: LivePage },
    { path: '/media/photos', redirect: '/media/photos/1' }
  ];

  return makeRoutes(pages);
}
