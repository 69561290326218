import React from 'react'
import ContentTemplate from '../../components/common/ContentTemplate'
import SubContentTemplate from '../../components/common/SubContentTemplate'
import PageHeaderContainer from '../../containers/common/PageHeaderContainer'
import PageTemplate from '../../components/common/PageTemplate'
import { WIZPARK_PARKING_AND_SHUTTLE_TAB } from '../../constants/page'
import ShuttleBus from '../../components/wizpark/parking/ShuttleBus'


const WizparkShuttleBusPage = ({ match }) => {
  return (
    <PageTemplate className="park">
      <PageHeaderContainer title="주차예약/셔틀버스" subtitle=" 사전 주차 예약 및 셔틀버스 운행 을 안내드립니다." image={require('../../assets/img/park/sub_tit_park.png')} forwardTabs={WIZPARK_PARKING_AND_SHUTTLE_TAB} currentPath={match.path} />
      <ContentTemplate>
        {/* <PageTitleTemplate>
          <BreadcrumbContainer page={WIZPARK_SHUTTLE} />
        </PageTitleTemplate> */}
        <SubContentTemplate className="park shuttle">
          <ShuttleBus />
        </SubContentTemplate>
      </ContentTemplate>
    </PageTemplate>
  )
}

export default WizparkShuttleBusPage
