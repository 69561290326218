import client from "../client";

export const getEncryptTlReqParam = () => {

  return client.post("/api/v2/common/getEncryptedTlReqParam", null)
    .then(response => {
      console.log('### CMS API :: /api/v2/common/getEncryptedTlReqParam ###', response.data)
      return response.data
    })
    // TODO : 에러 어떻게 처리할 것인지?
    .catch(error => {
      console.log(error);
      return error
    })

}

export const getSeasonTicketInfo = () => {
  return client.get(`/api/v2/game/seasonInfo`).then(res=>{
    return res.data
  }).catch(err=>{
    console.log(err);
    return err
  });
};
