export const CERT_REQUEST = 'cert/CERT_REQUEST'
export const CERT_SUCCESS = 'cert/CERT_SUCCESS'
export const CERT_FAIL = 'cert/CERT_FAIL'
export const CERT_CONFIRM_REQUEST = 'cert/CERT_CONFIRM_REQUEST'
export const CERT_CONFIRM_SUCCESS = 'cert/CERT_CONFIRM_SUCCESS'
export const CERT_CONFIRM_FAIL = 'cert/CERT_CONFIRM_FAIL'
export const CERT_RESET = 'cert/CERT_RESET'

export const certRequest = (userCert) => ({ type: CERT_REQUEST, userCert })
export const certSuccess = (certSeq) => ({ type: CERT_SUCCESS, certSeq })
export const certFail = () => ({ type: CERT_FAIL })
export const certConfirmRequest = (userCert, isSuccessAlert, isFailAlert) => ({ type: CERT_CONFIRM_REQUEST, userCert, isSuccessAlert, isFailAlert })
export const certConfirmSuccess = () => ({ type: CERT_CONFIRM_SUCCESS })
export const certConfirmFail = () => ({ type: CERT_CONFIRM_FAIL })
export const certReset = () => ({ type: CERT_RESET })


const initialState = {
  isRequesting: false,
  isReqeusted: false,
  certSeq: null,
  isConfirmRequesting: false,
  isConfirmRequested: false
}

const cert = (state = initialState, action) => {

  switch (action.type) {

    case CERT_REQUEST:
      return {
        ...state,
        isRequesting: true,
        isReqeusted: false,
        certSeq: null
      }
    case CERT_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        isReqeusted: true,
        certSeq: action.certSeq
      }
    case CERT_FAIL:
      return {
        ...state,
        isRequesting: false,
        isReqeusted: false,
        certSeq: null
      }
    case CERT_CONFIRM_REQUEST:
      return {
        ...state,
        isConfirmRequesting: true,
        isConfirmReqeusted: false
      }
    case CERT_CONFIRM_SUCCESS:
      return {
        ...state,
        isConfirmRequesting: false,
        isConfirmReqeusted: true,
      }
    case CERT_CONFIRM_FAIL:
      return {
        ...state,
        isConfirmRequesting: false,
        isConfirmReqeusted: false,
      }
    case CERT_RESET:
      return {
        ...state,
        isRequesting: false,
        isReqeusted: false,
        isConfirmRequesting: false,
        isConfirmReqeusted: false,
        certSeq: null
      }
    default:
      return state

  }
}

export default cert
