export const SPONSOR_REQ_REQUEST = 'sponsorReq/SPONSOR_REQ_REQUEST'
export const SPONSOR_REQ_SUCCESS = 'sponsorReq/SPONSOR_REQ_SUCCESS'
export const SPONSOR_REQ_FAILURE = 'sponsorReq/SPONSOR_REQ_FAILURE'

export const sponsorReqRequest = (sponsorReq, onClickShowRegister) => ({ type: SPONSOR_REQ_REQUEST, sponsorReq, onClickShowRegister })
export const sponsorReqSuccess = () => ({ type: SPONSOR_REQ_SUCCESS })
export const sponsorReqFailure = () => ({ type: SPONSOR_REQ_FAILURE })

const initialState = {
  isRequesting: false,
  isRequested: false,
}

const sponsorReq = (state = initialState, action) => {

  switch (action.type) {
    case SPONSOR_REQ_REQUEST:
      return {
        ...state,
        isRequesting: true,
        isRequested: false,
      }
    case SPONSOR_REQ_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        isRequested: true,
      }
    case SPONSOR_REQ_FAILURE:
      return {
        ...state,
        isRequesting: false,
        isRequested: false,
      }
    default:
      return state
  }
}

export default sponsorReq
