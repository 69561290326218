import React from 'react'
import ContentTemplate from '../../components/common/ContentTemplate'
import SubContentTemplate from '../../components/common/SubContentTemplate'
import PageHeaderContainer from '../../containers/common/PageHeaderContainer'
import PageTitleTemplate from '../../components/common/PageTitleTemplate'
import BreadcrumbContainer from '../../containers/common/BreadcrumbContainer'
import PageTemplate from '../../components/common/PageTemplate'
import { KTWIZ_INTRO, KTWIZ_INTRO_TAB } from '../../constants/page'
import Intro from '../../components/ktwiz/about/Intro'

const AboutPage = ({ match }) => {
  return (
    <PageTemplate className="ktwiz">
      <PageHeaderContainer title="kt wiz는?" subtitle="한국 프로야구의 ‘10번째 심장’ kt wiz를 소개합니다!" image={require('../../assets/img/ktwiz/sub_tit_ktwiz.png')} forwardTabs={KTWIZ_INTRO_TAB} currentPath={match.path} />
      <ContentTemplate>
        <PageTitleTemplate>
          <BreadcrumbContainer page={KTWIZ_INTRO} />
        </PageTitleTemplate>
        <SubContentTemplate className="ktwiz team about">
          <Intro />
        </SubContentTemplate>
      </ContentTemplate>
      <div className='ktwiz-bottom-img'>
        <img className='pc' src={require('../../assets/img/ktwiz/team_about_bottom_2024.png')} alt='' />
        <img className='m' src={require('../../assets/img/ktwiz/team_about_bottom_2024.png')} alt='' />
      </div>
    </PageTemplate>
  )
}

export default AboutPage
