import React from 'react'
import Link from '../../common/Link'
import { download } from '../../../modules/common/download'

const Mascot = () => {
  return (
    <>
      <article className='title'>
        <div className='txt'>
          <h6>
            마스코트
            </h6>
        </div>
        <div className='txt-bar'></div>
        <div className="desc">
          <p>
            마스코트는 하나가 아닌 둘이며, ‘빅(vic)’과 ‘또리(ddory)’로 불립니다.<br />
            함께 있으면 ‘빅또리’로 kt wiz의 승리를 의미하기도 합니다.
          </p>
        </div>
      </article>
      <div className='media'>
        <div className='item'>
          <div className="video-wrap">
            <div className='video'>
              <iframe title="kt wiz 마스코트 탄생스토리" src="https://www.youtube.com/embed/HfT0aOh-T3U" frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>
            </div>
          </div>
          <div className='name'>kt wiz 마스코트 탄생스토리</div>
        </div>
        <div className='item'>
          <div className="video-wrap">
            <div className='video'>
              <iframe title="kt wiz 마스코트 빅 & 또리" src="https://www.youtube.com/embed/oGK9pOqxNRY" frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>
            </div>
          </div>
          <div className='name'>kt wiz 마스코트 빅 & 또리</div>
        </div>
      </div>
      <div className='articles'>
        <article className='arti'>
          <div className='tit'>
            <h6>
              빅(vic)
                                </h6>
          </div>
          <div className='desc'>
            <p>
              빅(vic)은 이니셜 심볼을 부착하였으며, 공격형 파워를 상징합니다.
                                </p>
          </div>
          <div className='img-wrap'>
            <img className='pc' src={require('../../../assets/img/ktwiz/bi_mascot_vic.png')} alt='' />
            <img className='m' src={require('../../../assets/img/ktwiz/bi_mascot_vic_m.png')} alt='' />
          </div>
          <div className='buttons pc'>
            <Link className='btn-download' to={require('../../../assets/download/Mascot_vic_ai.zip')} target='_blank' download>AI 다운로드</Link>
            <Link className='btn-download' onClick={() => download(require('../../../assets/download/Mascot_vic_jpg.jpg'), 'Mascot_vic.jpg')}>JPG 다운로드</Link>
          </div>
        </article>
        <article className='arti'>
          <div className='tit'>
            <h6>
              또리(ddory)
                                </h6>
          </div>
          <div className='desc'>
            <p className='pc'>
              또리(ddory)는 이미지 심볼을 부착하고, 기동력과 민첩성을 지닌 수비를 상징합니다.
                                </p>
            <p className='m'>
              또리(ddory)는 이미지 심볼을 부착하고,<br />
              기동력과 민첩성을 지닌 수비를 상징합니다.
                                </p>
          </div>
          <div className='img-wrap'>
            <img className='pc' src={require('../../../assets/img/ktwiz/bi_mascot_ddory.png')} alt='' />
            <img className='m' src={require('../../../assets/img/ktwiz/bi_mascot_ddory_m.png')} alt='' />
          </div>
          <div className='buttons pc'>
            <Link className='btn-download' to={require('../../../assets/download/Mascot_ddory_ai.zip')} target='_blank' download>AI 다운로드</Link>
            <Link className='btn-download' onClick={() => download(require('../../../assets/download/Mascot_ddory_jpg.jpg'), 'Mascot_ddory.jpg')}>JPG 다운로드</Link>
          </div>
        </article>
      </div>
      <article className='arti'>
        <div className='tit'>
          <h6>
            빅(vic) & 또리(ddory)
                            </h6>
        </div>
        <div className='img-wrap'>
          <img className='pc' src={require('../../../assets/img/ktwiz/bi_mascot_vic_and_ddory.png')} alt='' />
          <img className='m' src={require('../../../assets/img/ktwiz/bi_mascot_vic_and_ddory_m.png')} alt='' />
        </div>
        <div className='buttons pc'>
          <Link className='btn-download' to={require('../../../assets/download/Mascot_vic_ddory_ai.zip')} target='_blank' download>AI 다운로드</Link>
          <Link className='btn-download' to={require('../../../assets/download/Mascot_vic_ddory_jpg.zip')} target='_blank' download>JPG 다운로드</Link>
        </div>
      </article>
    </>
  )
}

export default Mascot
