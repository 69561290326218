import React from 'react'
import HomeContainerOld from '../containers/HomeContainerOld'

const HomePage = ({ history }) => {

  return (
    <HomeContainerOld />
  )
}

export default HomePage
