import React from 'react'
import Link from '../common/Link'
import Swiper from 'react-id-swiper'

const GameSchedule = ({ recentGames }) => {
  const params = {
    containerClass: 'swiper-container2',
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    initialSlide: 1
  };

  const games = recentGames && [recentGames.prev, recentGames.current, recentGames.next]

  return (
    <>
      {games &&
        <Swiper {...params}>
          {games.filter(game => game).map(game => (
            <div key={game.gmkey} className="game_wrap position_maker">
              <div className="game_info">
                <div className="time_place">
                  <strong className="date">{game.gyear + '.' + game.gmonth + '.' + game.gday}</strong>
                  <strong className="time">{game.stadium} {game.gtime}</strong>
                </div>{/* //.time_place */}
                <div className="score_wrap position_maker clearfix">
                  <strong className="score float_l">{game.visitScore ? game.visitScore : 0}</strong>
                  <span className="vs">VS</span>
                  <strong className="score float_r">{game.homeScore ? game.homeScore : 0}</strong>
                </div>{/* //.score_wrap */}
                <div className="state">
                  <span>&nbsp;{game.outcome}&nbsp;</span>
                </div>{/* //.state */}
                <div className="point"><Link to={'/game/regular/boxscore/' + game.gameDate + '/' + game.gmkey}>경기 정보</Link></div>
              </div>{/* //.game_info */}
              <div className="team_wrap clearfix">
                <div className={'team float_l ' + game.visitKey.toLowerCase()}>
                  <div className="logo"></div>
                  <div className="team_name">
                    <span>{game.visit}</span>
                  </div>
                </div>
                <div className={'team float_r ' + game.homeKey.toLowerCase()}>
                  <div className="logo"></div>
                  <div className="team_name">
                    <span>{game.home}</span>
                  </div>
                </div>
              </div>{/* //.team_wrap */}
            </div>
          ))}
        </Swiper >
      }
    </>
  )
}

export default GameSchedule
