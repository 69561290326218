import React, { useState, useEffect } from 'react'
import * as yup from 'yup'

const initUpdateUserInfo = {
  simpleId: '',
  newPw: '',
  confirmNewPw: ''
}

const updateUserInfoSchema = yup.object().shape({
  simpleId: yup.string().max(20).required(),
  newPw: yup.string().min(8).required(),
  confirmNewPw: yup.string().min(6).required()
})

const ResetPw = ({ updateUserId, onResetPwRequest }) => {

  useEffect(() => {
    setUpdateUserInfo({
      ...updateUserInfo,
      simpleId: updateUserId
    })
  }, [])

  const [updateUserInfo, setUpdateUserInfo] = useState(initUpdateUserInfo)
  const [isEnableButton, setIsEnableButton] = useState(false)

  const onChangeNewPw = e => {
    setUpdateUserInfo({
      ...updateUserInfo,
      newPw: e.target.value
    })
  }
  const onChangeConfirmNewPw = e => {
    setUpdateUserInfo({
      ...updateUserInfo,
      confirmNewPw: e.target.value
    })
  }

  updateUserInfoSchema.isValid({
    simpleId: updateUserInfo.simpleId,
    newPw: updateUserInfo.newPw,
    confirmNewPw: updateUserInfo.confirmNewPw
  }).then(response => {
    setIsEnableButton(response)
  }).catch(error => {
    console.log(error);
  })

  return (
    <>
      <div className="content_box find_by pw_re pw_reset">
        {/*상단 설명*/}
        <div className="group group1">
          <div className="content_desc">
            <h4>비밀번호 재설정</h4>
            <span>새로운 비밀번호를 설정해주세요.</span>
          </div>{/*//.content_desc*/}
        </div>{/*//.group1*/}

        {/*입력 양식*/}
        <div className="group group2">
          <form action="" method="" name="pw_reset">
            <div className="input_field">
              <div className="input_row new_pw clearfix">
                <div className="input_tit">
                  <label htmlFor="new_pw">새 비밀번호 입력</label>
                </div>{/*//.input_tit*/}
                <div className="input_content">
                  <input type="password" id="new_pw" name="new_pw" className="cell" placeholder="8자 이상(영소문자, 숫자, 특수문자)" onChange={onChangeNewPw} />
                </div>{/*//.input_content*/}
              </div>{/*//.input_row.new_pw*/}

              <div className="input_row new_pw_confirm clearfix">
                <div className="input_tit">
                  <label htmlFor="new_pw_confirm">새 비밀번호 확인</label>
                </div>{/*//.input_tit*/}
                <div className="input_content">
                  <input type="password" id="new_pw_confirm" name="new_pw_confirm" className="cell" placeholder="새 비밀번호 재입력" onChange={onChangeConfirmNewPw} />
                </div>{/*//.input_content*/}
              </div>{/*//.input_row.new_pw_confirm*/}
            </div>{/*//.input_field*/}
            <div className="btn">
              <button type="button" className={isEnableButton ? "active" : ""} disabled={!isEnableButton} onClick={() => onResetPwRequest(updateUserInfo)}>확인</button> {/*클래스 active 붙었을 때 버튼 색 바뀝니다*/} {/*확인 선택 시 login.html로 이동*/}
            </div>
          </form>
        </div>{/*//.group2*/}
      </div>{/*//.content_box*/}
    </>
  )
}

export default ResetPw
