import React from 'react';
import { useLocation, useRouteMatch, useHistory } from 'react-router-dom';
import ContentTemplate from '../../components/common/ContentTemplate';
import SubContentTemplate from '../../components/common/SubContentTemplate';
import PageHeaderContainer from '../../containers/common/PageHeaderContainer';
import PageTitleTemplate from '../../components/common/PageTitleTemplate';
import Breadcrumb from '../../components/common/Breadcrumb';
import ArticleSearch from '../../components/media/board/ArticleSearch';
import ArticleDetail from '../../components/media/board/ArticleDetail';
import PageTemplate from '../../components/common/PageTemplate';
import { WIZ_NEWS_PAGE, WIZ_PRESS_PAGE } from '../../constants/page';
import WizBoard from '../../components/media/board/WizBoard';
import qs from 'query-string';

const WizNewsPage = () => {
  const matchBoard = useRouteMatch('/media/:board');
  const matchSeq = useRouteMatch('/media/:board/:seq');
  const query = qs.parse(useLocation().search);
  const history = useHistory();

  const tabs = [
    {
      path: '/media/wiznews',
      title: 'wiz소식',
      type: 'news',
      page: WIZ_NEWS_PAGE
    },
    {
      path: '/media/wizpress',
      title: 'wiz보도자료',
      type: 'press',
      page: WIZ_PRESS_PAGE
    }
  ];

  const goTab = i => {
    history.push(tabs[i].path);
  };

  const tab = tabs.find(t => t.path === matchBoard.url);
  tab.isActive = true;

  console.log('WizNewsPage', { query });

  return (
    <PageTemplate className='media'>
      <PageHeaderContainer
        title='wiz 뉴스'
        subtitle='kt wiz의 새소식을 발빠르게 전해 드립니다.'
        image={require('../../assets/img/media/sub_tit_media.png')}
        tabs={tabs}
        onClickTab={goTab}
      />
      <ContentTemplate className={matchSeq ? 'media_wiz_news_detail' : 'media_wiz_news'}>
        <PageTitleTemplate>
          <Breadcrumb page={tab.page} />
          {
            !matchSeq && <ArticleSearch option={query['search.sort']} text={query['search.searchWord']} />
          }
        </PageTitleTemplate>
        <SubContentTemplate>
          {
            matchSeq
              ? <ArticleDetail seq={matchSeq.params.seq} query={query} />
              : <WizBoard board={tab.type} query={query} />
          }
        </SubContentTemplate>
      </ContentTemplate>
    </PageTemplate>
  );
};

export default WizNewsPage;
