import React from 'react'

const FindIdEmail = ({ emailServerNames, email2, onChangeEmail1, onChangeEmail2, onChangeEmailServerName, onChangeEmailCertNo, isDisableEmailCertButton, isDisableEmailFindButton, onCertRequest, emailCert, onFindIdRequest }) => {

  return (
    <>
      {/*입력 양식(이메일)*/}
      <div className="group2 id_email_form">
        <div className="group group2_1 email_form">
          <form action="" method="" name="find_id_email">
            <div className="input_field">
              {/*이메일 입력*/}
              <div className="input_row email">
                <div className="input_tit">
                  <label htmlFor="email">이메일</label>
                </div>{/*//.input_tit*/}
                <div className="input_content clearfix">
                  <div className="email_wrap float_l clearfix"> {/*modify 20191015 태그 추가*/}
                    <div className="email1_cell float_l"> {/*modify 20191015 태그 추가*/}
                      <input type="text" id="email1" name="email1" className="cell" onChange={onChangeEmail1} />
                    </div>
                    <div className="email2_cell float_l"> {/*modify 20191015 태그 추가*/}
                      <input type="text" id="email2" name="email2" className="cell" onChange={onChangeEmail2} value={email2} />
                    </div>
                  </div>{/*//.email_wrap*/}
                  <div className="email3_cell float_l"> {/*modify 20191015 태그 추가*/}
                    <select id="email3" name="email3" className="cell" onChange={onChangeEmailServerName}>
                      <option value="0">직접입력</option>
                      {
                        emailServerNames.map((item, index) => (
                          <option key={index} value={item}>{item}</option>
                        ))
                      }

                    </select>
                  </div>
                  <div className="float_l"> {/*modify 20191015 태그 추가*/}
                    <button type="button" className={isDisableEmailCertButton ? '' : 'active'} disabled={isDisableEmailCertButton} onClick={() => onCertRequest(emailCert)}>인증요청</button>
                  </div> {/*클래스 active 붙었을 때 버튼 색 바뀝니다*/}
                </div>{/*//.input_content*/}
              </div>{/*//.input_row.email*/}

              {/*인증번호 확인*/}
              <div className="input_row num_confirm clearfix">
                <div className="input_tit">
                  <label htmlFor="num_confirm">인증번호</label>
                </div>
                <div className="input_content">
                  <input type="text" id="num_confirm" name="num_confirm" className="cell" placeholder="인증번호 6자 입력" maxLength="6" onChange={onChangeEmailCertNo} />
                </div>
              </div>{/*//.input_row.num_confirm*/}
              <div className="warning">
                <span>* 이메일 주소 입력 후 ‘인증요청’ 버튼을 선택해주세요.</span>
              </div>{/*//.warning*/}
            </div>{/*//.input_field*/}
            <div className="btn">
              <button type="button" className={isDisableEmailFindButton ? '' : 'active'} disabled={isDisableEmailFindButton} onClick={() => onFindIdRequest(emailCert)}>아이디 찾기</button> {/*클래스 active 붙었을 때 버튼 색 바뀝니다*/}
            </div>
          </form>
        </div>{/*//.group2_1*/}

        {/*하단 설명*/}
        <div className="group group2_2 id_email_desc email_desc">
          <div className="txt_wrap">
            <strong>[이메일로 아이디 찾기 안내]</strong>
            <p>kt wiz 회원가입 시 선택사항으로 이메일 정보를 추가로 입력한 고객만이메일로 아이디 찾기가 가능합니다.</p>
          </div>
        </div>{/*//.group2_2*/}
      </div>{/*//.group2.id_email_form*/}
    </>
  )
}

export default FindIdEmail
