import React from 'react'
import PageTab from '../../components/common/PageTab'
import PageForwardTab from '../../components/common/PageForwardTab'

const TitleBanner = ({ title, subtitle, image, tabs, forwardTabs, onClickTab, currentPath }) => {

  return (
    <div className="sub_bg position_maker">
      <div className="container">
        <div className={"page_tit_wrap clearfix" + (subtitle == null ? ' only' : '')}>
          <div className="img_wrap float_l">
            <img src={image} alt={title} />
          </div>
          <div className="page_tit float_l">
            <h3>{title}</h3>
            {subtitle != null && (<span>{subtitle}</span>)}
          </div>
        </div>
      </div>

      {tabs && <PageTab tabs={tabs} onClickTab={onClickTab} />}
      {forwardTabs && <PageForwardTab forwardTabs={forwardTabs} currentPath={currentPath} />}
    </div>
  )
}

export default TitleBanner
