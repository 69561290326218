import React, { useState } from 'react'
import GraphWrap from './GraphWrap'
import numeral from 'numeral'

const CrowdRanking = ({ match, crowdList, initYear, onChangeYear, yearList }) => {

  const [year, setYear] = useState(null)

  const [showYearComboBox, setShowYearComboBox] = useState(false)

  if (yearList && year == null) {
    setYear(yearList[0].gyear)
  }

  const graphList = crowdList && [...crowdList].sort((a, b) => {
    if (a.teamCode === 'KT') {
      return -1
    } else {
      if (a.crowd > b.crowd) {
        return -1
      } else if (a.crowd < b.crowd) {
        return 1
      } else {
        return 0
      }
    }
  })

  const CrowdRow = ({ team, index }) => {
    return (
      <tr className={team.teamCode === 'KT' ? 'kt' : ''}>
        <td>{index + 1}</td>
        <td>{team.teamName}</td>
        <td>{team.game}</td>
        <td>{numeral(team.crowd).format('0,0')}</td>
        <td>{numeral(Math.round(team.crowd / team.game)).format('0,0')}</td>
      </tr>
    )
  }

  return (
    <div class="rank_record_wrap crowd_state">
      <div class="season_cumulative_crowd">
        <article>
          <h4 class="mobile_mode">관중현황</h4>
          <h4 class="pc_mode">{year} 시즌 누적관중</h4>
          <div class="season_slt_block">
            <select name="season" onChange={(e) => setYear(e.target.value)} value={year}>
              {yearList && yearList.map((year, index) => <option value={year.gyear}>{year.gyear} 시즌</option>)}
            </select>
            <button type="submit" class="btn_search" onClick={() => onChangeYear(year)}>검색</button>
          </div>
          <div class="season_select_box">
            <button type="button" class="btn_season" onClick={() => setShowYearComboBox(true)}>{initYear} 시즌</button>
            {showYearComboBox && (
              <div className="select_box" style={{ display: 'block' }}>
                <form name="season_select_frm" action="" method="">
                  {yearList && yearList.map((y, index) => <label><input type="radio" name="season" value={y.gyear} checked={year === y.gyear} onClick={() => setYear(y.gyear)} />{y.gyear} 시즌</label>)}
                </form>
                <button type="submit" className="btn_submit" onClick={() => { onChangeYear(year); setShowYearComboBox(false) }}>완료</button>
              </div>
            )}
          </div>
          <GraphWrap match={match} xAxisList={graphList && graphList.map(team => team.teamName)} valueList={graphList && graphList.map(team => team.crowd)} />
        </article>
      </div>

      <div class="season_crowd_record">
        <article>
          <h4 class="pc_mode">{year} 시즌 관중기록</h4>
          <table>
            <caption>{year} 시즌 관중기록</caption>
            <colgroup>
              <col width="20%" />
              <col width="20%" />
              <col width="20%" />
              <col width="20%" />
              <col width="20%" />
            </colgroup>
            <thead>
              <tr>
                <th>순위</th>
                <th>팀명</th>
                <th>경기 수</th>
                <th>누적관중</th>
                <th>평균관중</th>
              </tr>
            </thead>
            <tbody>
              {crowdList && crowdList.map((team, index) => <CrowdRow key={team.teamCode} team={team} index={index} />)}
            </tbody>
          </table>
        </article>
      </div>
    </div>
  )
}

export default CrowdRanking
