import { put, take, takeEvery, all, fork } from "redux-saga/effects"
import { push } from "connected-react-router"
// api
// import * as findPwApi from "../../api/auth/findPw"
// actions
import * as findPwActions from "../../modules/auth/findPw"
import * as loadingActions from "../../modules/common/loading"
import * as certActions from "../../modules/auth/cert"
// import * as dialogActions from "../../modules/common/dialog"

function* findPw({ userCert }) {

  // 1. loading OPEN
  yield put(loadingActions.setLoading(true))

  // 2. 인증 확인 요청
  yield put(certActions.certConfirmRequest(userCert, false, true))
  const certConfirmResult = yield take([certActions.CERT_CONFIRM_SUCCESS, certActions.CERT_CONFIRM_FAIL])
  if (certConfirmResult.type === certActions.CERT_CONFIRM_SUCCESS) {

    yield put(push("/resetpw", userCert.simpleId))

  } else if (certConfirmResult.type === certActions.CERT_CONFIRM_FAIL) {
    yield put(findPwActions.findPwFail())
  }

  // 4. loading CLOSE
  yield put(loadingActions.setLoading(false))


}

function* findPwWatch() {
  yield takeEvery(findPwActions.FIND_PW_REQUEST, findPw)
}

export default function* findPwSaga() {
  yield all([
    fork(findPwWatch),
  ])
}
