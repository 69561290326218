import React from 'react'
import ContentTemplate from '../../components/common/ContentTemplate'
import { useLocation, useRouteMatch } from 'react-router-dom';
import SubContentTemplate from '../../components/common/SubContentTemplate'
import PageHeaderContainer from '../../containers/common/PageHeaderContainer'
import PageTitleTemplate from '../../components/common/PageTitleTemplate'
import BreadcrumbContainer from '../../containers/common/BreadcrumbContainer'
import ArticleSearch from '../../components/media/board/ArticleSearch'
import PageTemplate from '../../components/common/PageTemplate'
import { HIGHLIGHT_PAGE } from '../../constants/page'
import WizBoard from '../../components/media/board/WizBoard';
import qs from 'query-string';
import ArticleDetail from '../../components/media/board/ArticleDetail';

const HighlightPage = () => {

  const matchSeq = useRouteMatch('/media/highlight/:seq');
  const query = qs.parse(useLocation().search);

  return (
    <PageTemplate className="media">
      <PageHeaderContainer title="하이라이트" subtitle="생생한 경기 하이라이트를 담았습니다." image={require('../../assets/img/media/sub_tit_media.png')} />
      <ContentTemplate className={matchSeq ? 'media_highlight_detail' : "media_highlight"}>
        <PageTitleTemplate>
          <BreadcrumbContainer page={HIGHLIGHT_PAGE} />
          {
            !matchSeq &&
            <ArticleSearch />
          }
        </PageTitleTemplate>
        <SubContentTemplate>
          {
            matchSeq
              ? <ArticleDetail seq={matchSeq.params.seq} query={query} />
              : <WizBoard board={'highlight'} query={query} count={12} listType='grid' />
          }
        </SubContentTemplate>
      </ContentTemplate>
    </PageTemplate >
  )
}

export default HighlightPage
