import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import FindPw from '../../components/auth/findPw'

// actions
import * as CertActions from '../../modules/auth/cert'
import * as FindPwACtions from '../../modules/auth/findPw'

const FindPwContainer = () => {

  // 페이지 빠져나갈 때, 인증 정보 리셋
  useEffect(() => {
    return () => {
      onCertReset()
    }
  }, [])

  const cert = useSelector(state => state.cert)
  const dispatch = useDispatch()

  const onCertRequest = (userCert) => { dispatch(CertActions.certRequest(userCert)) }
  const onCertReset = () => { dispatch(CertActions.certReset()) }
  const onFindPwRequest = (userCert) => { dispatch(FindPwACtions.findPwRequest(userCert)) }

  return <FindPw cert={cert} onCertReset={onCertReset} onCertRequest={onCertRequest} onFindPwRequest={onFindPwRequest} />
}

export default FindPwContainer
