import wiz from '../../api/wiz';
import { dataRequest } from '../common/wizData';

// listByCategory 관련 actions
const boards = {
  news: '001',
  press: '002',
  firstPitcher: '003',
  fanReporting: '004',
  sns: '005',
  gallery: '006',
  sponsor: '007',
  history: '008',
  wizstory: '009',
  highlight: '010'
};

export const createListRequest = (params, dataType) => {
  return dataRequest(
    wiz.API.LIST_BY_CATEGORY,
    {
      'article.boardCode': boards[dataType] || '001',
      ...params
    },
    dataType
  );
};

export const wizNewsRequest = params => createListRequest(params, 'news');
export const wizPressRequest = params => createListRequest(params, 'press');
export const wizFirstPitcherRequest = params => createListRequest(params, 'firstPitcher');
export const wizFanReportingRequest = params => createListRequest(params, 'fanReporting');
export const wizSnsRequest = params => createListRequest(params, 'sns');
export const wizGalleryRequest = params => createListRequest(params, 'gallery');
export const wizSponsorRequest = params => createListRequest(params, 'sponsor');
export const wizHistoryRequest = params => createListRequest(params, 'history');
export const highlightRequest = params => createListRequest(params, 'highlight');
export const hotIssueRequest = params => {
  return dataRequest(
    wiz.API.GET_HOTISSUE_LIST,
    {
      ...params
    },
    'hotissue'
  );
}

export const gameVideoRequest = params => {
  return dataRequest(
    wiz.API.GET_GAME_VIDEO,
    {
      ...params
    },
    'gameVideo'
  );
}

export const wizArticleRequest = (seq, params) =>
  dataRequest(wiz.API.GET_ARTICLE_DETAIL, { 'article.artcSeq': seq.toString(), ...params }, 'articleDetail');
