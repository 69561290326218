import React from 'react'

const BatterDetailSecondPcTabLastest = ({ dataTable, recentgamerecordlist }) => {
  return (
    <>
      {/* 시즌 정규리그 최근 5경기 기록 pc */}
      <article className="player_record_content latest_game pc_mode">
        <div className="player_record mb_hide">
          <div className="container">
            <h5>{dataTable.title}</h5>
          </div>
          <div className="container table">
            <table className="btD7161b td13">
              <caption>{dataTable.title}</caption>
              <colgroup>
                {
                  dataTable.data.map((v, i) => (
                    <col key={i} width={v.cols} />
                  ))
                }
              </colgroup>
              <thead>
                <tr>
                  {
                    dataTable.data.map((v, i) => (
                      <th key={i}>{v.head}</th>
                    ))
                  }
                </tr>
              </thead>
              <tbody>
                {
                  recentgamerecordlist && recentgamerecordlist.length > 0
                    ? recentgamerecordlist.map((v, i) => (
                      <tr key={i}>
                        {
                          dataTable.data.map((sv, si) => (
                            <td key={si}>{v[sv.bodyParam]}</td>
                          ))
                        }
                      </tr>
                    ))
                    : <tr><td colSpan={dataTable.data.length}>데이터가 없습니다.</td></tr>
                }
              </tbody>
            </table>
          </div>
        </div>
      </article>
      {/* // 시즌 정규리그 최근 5경기 기록 pc */}
    </>
  )
}

export default BatterDetailSecondPcTabLastest
