import React from "react";

const Uniform = () => {
  return (
    <>
      <article className="title">
        <div className="txt">
          <h6>유니폼</h6>
        </div>
        <div className="txt-bar"></div>
        <div className="desc">
          <p>
            KT의 브랜드 컬러인 레드와 블랙을 기본으로 하여,
            <br />
            강력하고 신비로운 마법사 구단의 모습을 담은 kt wiz의 유니폼 입니다.
          </p>
        </div>
        <div className="sub-desc">
          <p>
          kt wiz의 상징인 '마법'의 의미를 강조하여 이를 연상시킬 수 있는 서체와
            <br />
            버스트마크를 가미한 디자인으로, 강인하고 날렵한 마법사 구단을 표현하였습니다.
            <br />
            워드마크 상/하단에 추가된 버스트마크는 팬과 선수의 연결이라는 의미를 가지고 있습니다.
            {/* <br />
            마찬가지로 상승하는 곡선의 형태인 언더라인 그래픽은 kt 모기업이 서비스하는 속도감을 표현하였다. */}
          </p>
        </div>

        <div className="images">
          {/* <img className='pc' src={require('../../../assets/img/ktwiz/bi_uniform_img.png')} alt="" /> */}
          {/* <img className='m' src={require('../../../assets/img/ktwiz/bi_uniform_img_m.png')} alt="" />*/}

          {/* 2020시즌 이미지 */}
          {/* <img className="pc" src={require("../../../assets/img/ktwiz/bi_uniform_img_21.jpg")} alt="" /> */}
          {/* <img className="m" src={require("../../../assets/img/ktwiz/bi_uniform_img_21.jpg")} alt="" /> */}

          {/* 2024시즌 이미지 */}
          <img className="pc" src={require("../../../assets/img/ktwiz/bi_uniform_img_2024.png")} alt="" />
          <img className="m" src={require("../../../assets/img/ktwiz/bi_uniform_img_2024.png")} alt="" />
        </div>
      </article>
    </>
  );
};

export default Uniform;
