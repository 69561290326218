import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import PlayerPage from './PlayerPage'

function makeRoutes(pages) {
  return (
    <Switch>
      <Route path='/player/:type/detail' component={PlayerPage} />
      {pages.map(({ path, component }) => {
        return <Route key={path} path={path} component={component} />
      })}
      <Redirect to={pages[0].path} />
    </Switch>
  )
}

export default function PlayerRoute() {
  const pages = [
    { path: '/player/coach', title: '코칭스텝', component: PlayerPage, },
    { path: '/player/pitcher', title: '투수', component: PlayerPage, },
    { path: '/player/catcher', title: '타자', component: PlayerPage, },
    { path: '/player/infielder', title: '타자', component: PlayerPage, },
    { path: '/player/outfielder', title: '타자', component: PlayerPage, },
    { path: '/player/cheer', title: '응원단', component: PlayerPage, },
    { path: '/player/song', title: '응원가', component: PlayerPage, },
    { path: '/player/song-copyright', title: '응원가 저작권', component: PlayerPage, },
  ]

  return makeRoutes(pages)
}
