import React from 'react'
import BatterDetailSecondMobileTabRegular from './BatterDetailSecondMobileTabRegular'
import BatterDetailSecondMobileTabLastest from './BatterDetailSecondMobileTabLastest'
import BatterDetailSecondMobileTabTotal from './BatterDetailSecondMobileTabTotal'

const dataTables = {
  BATTER_DETAIL_REGULAR_SEASON_M: {
    title: '시즌 정규리그 기록',
    data: [
      { cols: '16.66%', head: ['경기', '2루타', 'OPS', 'OPS+'], bodyParam: ['gamenum', 'h2', 'ops', 'opsPlus'] },
      { cols: '16.66%', head: ['타율', '3루타', '볼넷', 'BABIP'], bodyParam: ['hra', 'h3', 'bb', 'babip'] },
      { cols: '16.66%', head: ['타석', '홈런', '삼진', '결승타'], bodyParam: ['pa', 'hr', 'kk', 'wrHit'] },
      { cols: '16.66%', head: ['타수', '타점', '병살', '득점권타율'], bodyParam: ['ab', 'rbi', 'gd', 'spHra'] },
      { cols: '16.66%', head: ['득점', '출루율', '도루'], bodyParam: ['run', 'bra', 'sba'] },
      { cols: '*', head: ['안타', '장타율', '도루실패'], bodyParam: ['hit', 'slg', 'cs'] },
    ]
  },
  BATTER_DETAIL_LASTEST_GAME_M: {
    title: '최근 5경기 기록',
    data: [
      { cols: '', head: '일자', bodyParam: 'displayDate' },
      { cols: '', head: '상대팀', bodyParam: 'matchTeamName' },
      { cols: '', head: '타수', bodyParam: 'ab' },
      { cols: '', head: '득점', bodyParam: 'run' },
      { cols: '', head: '안타', bodyParam: 'hit' },
      { cols: '', head: '홈런', bodyParam: 'hr' },
      { cols: '', head: '타점', bodyParam: 'rbi' },
      { cols: '', head: '타율', bodyParam: 'hra' },
      { cols: '', head: '출루율', bodyParam: 'bra' },

    ]
  },
  BATTER_DETAIL_TOTAL_M: {
    title: '통산 기록',
    data: [
      { cols: '12.5%', head: '연도', bodyParam: 'gyear' },
      { cols: '12.5%', head: '팀', bodyParam: 'teamName' },
      { cols: '12.5%', head: '경기', bodyParam: 'gamenum' },
      { cols: '*', head: '타율', bodyParam: 'hra' },
      { cols: '12.5%', head: '득점', bodyParam: 'run' },
      { cols: '12.5%', head: '안타', bodyParam: 'hit' },
      { cols: '12.5%', head: '홈런', bodyParam: 'hr' },
      { cols: '12.5%', head: '타점', bodyParam: 'rbi' },

    ]
  },
}

const BatterDetailSecondMobile = ({ data, isFuturesLeague, selectedGyear }) => {

  const seasonsummary = data && data.seasonsummary
  const recentgamerecordlist = data && data.recentgamerecordlist
  const yearrecordlist = data && data.yearrecordlist

  return (
    <>
      {/* 퓨처스리그가 아닐 때에만 노출 */}
      {
        !isFuturesLeague &&
        <>
          {/* 정규리그 기록 */}
          <BatterDetailSecondMobileTabRegular dataTable={dataTables.BATTER_DETAIL_REGULAR_SEASON_M} seasonsummary={seasonsummary} selectedGyear={selectedGyear} />

          {/* 최근 5경기 */}
          <BatterDetailSecondMobileTabLastest dataTable={dataTables.BATTER_DETAIL_LASTEST_GAME_M} recentgamerecordlist={recentgamerecordlist} />

          {/* 통산 기록 */}
          <BatterDetailSecondMobileTabTotal dataTable={dataTables.BATTER_DETAIL_TOTAL_M} yearrecordlist={yearrecordlist} />
        </>
      }
    </>
  )
}
export default BatterDetailSecondMobile
