import { useSelector } from 'react-redux'
import wiz from '../../api/wiz'
import { dataRequest } from '../common/wizData'

export const createResourceRequest = (params, dataType) => {
  return dataRequest(
    wiz.API.GET_RESOURCE,
    params,
    dataType
  )
}

export const resourceRequest = id => createResourceRequest({ id: id }, id)

export const useResource = (type) => {
  const data = useSelector(state => state.wizData.data && state.wizData.data[type])
  const error = useSelector(state => state.wizData.error && state.wizData.error[type])
  return [data, error]
}
