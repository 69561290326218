import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import Link from '../common/Link'

const CLASS_NAMES = {
  lnbDep2Wrap: 'lnb_dep2_wrap',
  orderByTabWrap: 'orderby_tab_wrap',
  detailLnbWrap: 'detail_lnb_wrap'
}

const ContentSwitchingTab = ({ className, tabs = [], currentContentType, onClickContentTab, showPlayerSearch, onSearchYear, onSearchPlayerName, yearList }) => {

  const [searchYear, setSearchYser] = useState(null)
  const [searchPlayerName, setSearchPlayerName] = useState('')

  const currentPath = useLocation().pathname

  return (
    <>
      {
        CLASS_NAMES.lnbDep2Wrap === className &&
        <>
          <div className="lnb_dep2_wrap">
            <ul>
              {
                tabs &&
                tabs.map((v, i) => (
                  v.path
                    ? <li key={i} className={`dep4 ${i === 0 ? 'first' : ''} ${currentPath === v.path ? 'active' : ''}`}>
                      <Link to={v.path} className="tab_team_rank">{v.title}</Link>
                    </li>
                    : <li key={i} className={`dep4 ${i === 0 ? 'first' : ''} ${currentContentType === v.type ? 'active' : ''}`}>
                      <Link onClick={() => onClickContentTab(v.type)} className="tab_team_rank">{v.title}</Link>
                    </li>
                ))
              }
            </ul>
          </div>
        </>
      }

      {
        CLASS_NAMES.orderByTabWrap === className &&
        <>
          {/* 목록 정렬 탭 */}
          <div className="orderby_tab_wrap">
            <div className={`orderby_tab ${tabs.length > 2 ? 'tab_three' : ''}`}>
              <ul>
                {
                  tabs &&
                  tabs.map((v, i) => (
                    <li key={i}>
                      {
                        v.path
                          ? <Link to={v.path} className={currentPath === v.path ? 'active' : ''}>{v.title}</Link>
                          : <Link onClick={() => onClickContentTab(v.type)} className={currentContentType === v.type ? 'active' : ''}>{v.title}</Link>
                      }
                    </li>
                  ))
                }
              </ul>
            </div>
          </div>
        </>
      }

      {
        CLASS_NAMES.detailLnbWrap === className &&
        <>
          {/* detail lnb */}
          <div className="detail_lnb_wrap">
            <div className="wide">
              <div className="container">
                <ul>
                  {
                    tabs &&
                    tabs.map((v, i) => (
                      v.path
                        ? <li key={i} className={`dep4 ${currentPath === v.path ? 'active' : ''}`}>
                          <Link className={`tab_${v.type}`} to={v.path}>{v.title}<span className="underline"></span></Link>
                        </li>
                        : <li key={i} className={`dep4 ${currentContentType === v.type ? 'active' : ''}`}>
                          <Link className={`tab_${v.type}`} onClick={() => onClickContentTab(v.type)}>{v.title}<span className="underline"></span></Link>
                        </li>
                    ))
                  }
                </ul>
                {
                  showPlayerSearch &&
                  <div className="search_input_block">
                    <span>선수 검색</span>
                    <input type="text" name="player_name" placeholder="선수 이름을 입력하세요." value={searchPlayerName} onChange={(e) => setSearchPlayerName(e.target.value)} onKeyPress={(e) => { e.key === 'Enter' && onSearchPlayerName(searchPlayerName) }} />
                    <button type="submit" className="btn_search" onClick={() => onSearchPlayerName(searchPlayerName)}>검색</button>
                  </div>
                }

                <div className="season_slt_block">
                  <select name="season" onChange={(e) => setSearchYser(e.target.value)}>
                    {yearList && yearList.map((year, index) => <option key={year.gyear} value={year.gyear}>{year.gyear} 시즌</option>)}
                  </select>
                  <button type="submit" className="btn_search" onClick={() => onSearchYear(searchYear)}>검색</button>
                </div>
              </div>
            </div>
          </div>
        </>
      }
    </>
  )
}

export default ContentSwitchingTab
