import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import * as yup from "yup";

import ReCAPTCHA from "react-google-recaptcha";
import CertChange from "./cert/CertChange";

// yup schema exam
let schema = yup.object().shape({
  id: yup.string().required(),
  pw: yup.string().required(),
});

const Login = ({ login, onLoginRequest, returnObj }) => {
  const [isSaveId, setIsSaveId] = useState(
    localStorage.getItem("isSaveId") === "0"
  );
  const [id, setId] = useState(localStorage.getItem("saveId") || "");
  const [pw, setPw] = useState("");
  const [isInputValid, setIsInputValid] = useState(false);

  const [isShowCaptcha, setIsShowCaptcha] = useState(false);
  const [isNotRobot, setIsNotRobot] = useState(false);

  useEffect(() => {
    return () => {
      localStorage.setItem("saveId", isSaveId ? id : "");
      localStorage.setItem("isSaveId", isSaveId ? "0" : "1");
    };
  });

  const onChangeId = (e) => {
    setId(e.target.value);
  };
  const onChangePw = (e) => {
    setPw(e.target.value);
  };
  const onChangeSaveId = (e) => {
    setIsSaveId(e.target.checked);
  };

  const onChangeRecaptcha = (value) => {
    setIsShowCaptcha(false);
    setIsNotRobot(true);
  };

  const onExpiredRecaptcha = () => {
    setIsNotRobot(false);
  };

  // validate exam
  schema
    .validate({
      id: id,
      pw: pw,
    })
    .catch((error) => {})
    .then((valid) => {
      valid && !login.isLoggingIn
        ? setIsInputValid(true)
        : setIsInputValid(false);
    });

  useEffect(() => {
    if (!(isInputValid && isNotRobot)) setIsShowCaptcha(isInputValid);
  }, [isInputValid, isNotRobot]);

  return (
    <div className="content_box login clearfix">
      {!login.certStatus.active ? (
        // 왼쪽영역
        <div className="area area1 float_l">
          <img
            className="img_wrap"
            src={require("../../assets/img/login/kt_logo.png")}
            alt="kt wiz 로고"
          />

          <form action="" method="" name="login_form">
            <input
              type="text"
              id="id"
              className="cell"
              placeholder="아이디"
              value={id}
              onChange={onChangeId}
            />
            <input
              type="password"
              id="pw"
              className="cell"
              placeholder="비밀번호"
              onChange={onChangePw}
            />

            <div style={{ display: isShowCaptcha ? "" : "none" }}>
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_V2_SITE_KEY}
                onChange={onChangeRecaptcha}
                size={"normal"}
                onExpired={onExpiredRecaptcha}
              />
            </div>

            <button
              type="button"
              id="loginBtn"
              onClick={() => onLoginRequest({ id: id, pw: pw }, returnObj)}
              className={
                !(isInputValid && isNotRobot) ? "login_btn" : "login_btn active"
              }
              disabled={!(isInputValid && isNotRobot)}
            >
              로그인
            </button>

            <label htmlFor="id_save">
              <input
                type="checkbox"
                id="id_save"
                checked={isSaveId}
                onChange={onChangeSaveId}
              />
              아이디 저장
            </label>
          </form>
        </div>
      ) : (
        <CertChange
          certStatus={{ ...login.certStatus }}
          userInfo={{ id, pw }}
          onLoginRequest={onLoginRequest}
        />
      )}

      {/* 오른쪽 영역 */}
      {!login.certStatus.active && (
        <div className="area area2 float_l position_maker">
          <div className="m_invisible txt">
            <span>아이디나 비밀번호가 기억나지 않으세요?</span>
          </div>
          <div className="row clearfix">
            <div className="link_btn float_l">
              <Link to="/findid">아이디 찾기</Link>
            </div>
            <div className="link_btn float_l">
              <Link to="/findpw">비밀번호 찾기</Link>
            </div>
          </div>
          <div className="m_invisible txt">
            <span>아직 kt wiz의 회원이 아니신가요?</span>
          </div>
          <div className="row">
            <div className="link_btn font_c">
              <Link to="/join">회원가입하기</Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
