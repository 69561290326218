import React from 'react'

const Prepairing = () => {
  return (
    <>
      <div>
        <img src={require('../../assets/img/common/pre-page-2021.png')} alt='준비중' className='img_wrap' />
      </div>
    </>
  )
}

export default Prepairing
