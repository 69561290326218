import client from "../client";
import qs from "query-string"

/**
 * 인증요청 API
 * @param {*} userCert
 */
export const findId = (userCert) => {

  const data = qs.stringify({

    'findInfo.findType': userCert.certType,
    'findInfo.mobpNo': userCert.mobpNo,
    'findInfo.memberEmail': userCert.memberEmail

  })

  return client.post("/api/v2/user/id/find", data)
    .then(response => {

      // 성공 처리
      if ((response.status === 200 || response.status === "200") && response.data.status === 0) {
        console.log(`###### API :: FINDID REQUEST => ${JSON.stringify(response.data.data)}`)
        return response.data.data
      }
      // 실패 처리
      else {
        return {
          error: {
            status: response.data.status,
            message: response.data.message
          }
        }
      }

    })
    // TODO : 에러 어떻게 처리할 것인지?
    .catch(error => {

      console.log(error);

      return {
        error
      }
    })

}
