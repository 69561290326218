import React from 'react'
import PasswordConfirmContainer from '../../containers/mypage/PasswordConfirmContainer'
import PageTemplate from '../../components/common/PageTemplate'
import PageHeaderContainer from '../../containers/common/PageHeaderContainer'
import ContentTemplate from '../../components/common/ContentTemplate'
import PageTitleTemplate from '../../components/common/PageTitleTemplate'
import Breadcrumb from '../../components/common/Breadcrumb'
import { PASSWORD_CONFIRM_PAGE } from '../../constants/page'

const PasswordConfirmPage = () => {
  return (
    <PageTemplate className='member'>
      <PageHeaderContainer title='비밀번호 확인' image={require('../../assets/img/login/sub_tit_mypage.png')} />
      <ContentTemplate>
        <PageTitleTemplate>
          <Breadcrumb page={PASSWORD_CONFIRM_PAGE} />
        </PageTitleTemplate>
        <PasswordConfirmContainer />
      </ContentTemplate>
    </PageTemplate>
  )
}

export default PasswordConfirmPage
