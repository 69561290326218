import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { createDetailRequest } from '../../../modules/player/player'
import Link from '../../common/Link'
import qs from 'query-string'

const BatterDetail = ({ type, selectedGyear }) => {

  const params = qs.parse(useLocation().search)

  const data = useSelector(state => state.wizData.data && state.wizData.data[type])
  // const error = useSelector(state => state.wizData.error && state.wizData.error[type])
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(createDetailRequest({ 'gameSearch.pcode': params.pcode, 'gameSearch.targetYear': selectedGyear }, type))
  }, [params.pcode, selectedGyear, type])

  const gameplayer = data && data.gameplayer
  const seasonsummary = data && data.seasonsummary

  return (
    <div className="player_detail_content">
      <article className="player_info_content">
        <div>
          <div className="player_img">
            <img src={(gameplayer && gameplayer.playerPrvwImg2) ? gameplayer.playerPrvwImg2 : require('../../../assets/img/player/no_img_player_detail.png')} alt="" className="mb_hide" onError={(e) => {
              e.target.onError = null
              e.target.src = require('../../../assets/img/player/no_img_player_detail.png')
            }} />
            <img src={(gameplayer && gameplayer.playerPrvwImg3) ? gameplayer.playerPrvwImg3 : require('../../../assets/img/player/no_img_player_detail_m.png')} alt="" className="pc_hide" onError={(e) => {
              e.target.onError = null
              e.target.src = require('../../../assets/img/player/no_img_player_detail_m.png')
            }} />
          </div>
          <dl className="player_info">
            <dt>
              <span className="back_num">{`No. ${gameplayer && gameplayer.backnum}`}</span>
              {gameplayer && gameplayer.playerName}
              <span className="eng_name mb_hide">{gameplayer && gameplayer.engName}</span>
              <span className="position pc_hide">{`${gameplayer && gameplayer.position}(${gameplayer && gameplayer.hittype})`}</span>
            </dt>
            <dd className="info_list_wrap">
              <ul>
                <li className="tuota mb_hide">
                  <dl>
                    <dt>투타</dt>
                    <dd>{gameplayer && gameplayer.hittype}</dd>
                  </dl>
                </li>
                <li className="birthday">
                  <dl>
                    <dt>생년월일</dt>
                    <dd>{`${gameplayer && gameplayer.birth.substring(0, 4)}.${gameplayer && gameplayer.birth.substring(4, 6)}.${gameplayer && gameplayer.birth.substring(6, 8)}`}</dd>
                  </dl>
                </li>
                <li className="birthplace pc_hide">
                  <dl>
                    <dt>태어난 곳</dt>
                    <dd>{gameplayer && gameplayer.bornPlace}</dd>
                  </dl>
                </li>
                <li className="build">
                  <dl>
                    <dt>체격</dt>
                    <dd>{`${gameplayer && gameplayer.height}cm, ${gameplayer && gameplayer.weight}kg`}</dd>
                  </dl>
                </li>
                <li className="bloodtype pc_hide">
                  <dl>
                    <dt>혈액형</dt>
                    <dd>{gameplayer && gameplayer.bloodGroups}</dd>
                  </dl>
                </li>
                <li className="native">
                  <dl>
                    <dt>출신교</dt>
                    <dd>
                      {gameplayer && gameplayer.career.split('-').filter((v) => {
                        return (v.substring(v.length - 1) === '초'
                          || v.substring(v.length - 1) === '중'
                          || v.substring(v.length - 1) === '고')
                      }).map((v, i, arr) => (`${v}${(arr.length - 1 === i) ? '' : '-'}`))}

                      <span className="pc_hide">

                        {gameplayer && gameplayer.career.split('-').filter((v) => {
                          return (v.substring(v.length - 1) !== '초'
                            && v.substring(v.length - 1) !== '중'
                            && v.substring(v.length - 1) !== '고')
                        }).map((v, i, arr) => (`${(i === 0) ? '-' : ''}${v}${(arr.length - 1 === i) ? '' : '-'}`))}
                      </span>

                    </dd>
                  </dl>
                </li>
                <li className="debutyear pc_hide">
                  <dl>
                    <dt>프로데뷔 연도</dt>
                    <dd>{gameplayer && gameplayer.debutYear}</dd>
                  </dl>
                </li>
                {/* <li className="join mb_hide">
                  <dl>
                    <dt>입단</dt>
                    <dd>
                      {
                        gameplayer && gameplayer.career2.split('\n').map((v, i) => (
                          <React.Fragment key={i}>
                            {v}<br />
                          </React.Fragment>
                        ))
                      }
                    </dd>
                  </dl>
                </li> */}
              </ul>
              <Link to={`/media/photos/1?search.searchWord=${gameplayer && gameplayer.playerName}&search.sort=400`} className="btn_player_img mb_hide">선수 사진 보기</Link>
            </dd>
          </dl>
          <div className="player_record_summary mb_hide">
            <dl>
              <dt>{selectedGyear} 정규리그 성적 : </dt>
              <dd>타율 {seasonsummary ? seasonsummary.hra : '-'} / 안타 {seasonsummary ? seasonsummary.hit : '-'} / 타점 {seasonsummary ? seasonsummary.rbi : '-'} / 홈런 {seasonsummary ? seasonsummary.hr : '-'}</dd>
            </dl>
          </div>
        </div>
      </article>
    </div>
  )
}

export default BatterDetail
