import React from 'react'

const evalParams = (value, subValue) => {
  switch (subValue.bodyParam) {
    // 승패 출력
    case 'wls':
      return value[subValue.bodyParam] === "W" ? "승" : value[subValue.bodyParam] === "L" ? "패" : value[subValue.bodyParam] === "S" ? "세" : " "
    // 평균자책점 계산
    case 'era':
      return (value.er && value.er > 0 && value.innDisplay && value.innDisplay)
        ? (parseFloat(value.er * 9) / (parseFloat(value.innDisplay.split(' ')[0]) + parseFloat(value.innDisplay.split(' ')[1] ? value.innDisplay.split(' ')[1].split('/').reduce((p, c) => p / c) : 0))).toFixed(2)
        : 0
    default:
      return value[subValue.bodyParam]
  }
}

const PitcherDetailSecondPcTabLastest = ({ dataTable, recentgamerecordlist }) => {
  return (
    <>
      {/* 2019 시즌 정규리그 최근 5경기 기록 pc */}
      <article className="player_record_content latest_game pc_mode">
        <div className="player_record mb_hide">
          <div className="container">
            <h5>{dataTable.title}</h5>
          </div>
          <div className="container table">
            <table className="btD7161b td13">
              <caption>{dataTable.title}</caption>
              <colgroup>
                {
                  dataTable.data.map((v, i) => (
                    <col key={i} width={v.cols} />
                  ))
                }
              </colgroup>
              <thead>
                <tr>
                  {
                    dataTable.data.map((v, i) => (
                      <th key={i}>{v.head}</th>
                    ))
                  }
                </tr>
              </thead>
              <tbody>
                {
                  recentgamerecordlist && recentgamerecordlist.length > 0
                    ? recentgamerecordlist.map((v, i) => (
                      <tr key={i}>
                        {
                          dataTable.data.map((sv, si) => (
                            <td key={si}>
                              {evalParams(v, sv)}
                            </td>
                          ))
                        }
                      </tr>
                    ))
                    : <tr><td colSpan={dataTable.data.length}>데이터가 없습니다.</td></tr>
                }
              </tbody>
            </table>
          </div>
        </div>
      </article>
      {/* // 2019 시즌 정규리그 최근 5경기 기록 pc */}
    </>
  )
}

export default PitcherDetailSecondPcTabLastest
