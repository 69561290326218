import React from 'react'

const SecondContentTemplate = ({ children, className }) => {
  return (
    <>
      <div className={`content_second ${className}_detail`}>
        {children}
      </div>
    </>
  )
}

export default SecondContentTemplate
