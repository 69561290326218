import React from 'react'
import Link from '../../common/Link'
import { download } from '../../../modules/common/download'

const Emblem = () => {
  return (
    <>
      {/* club emblem */}
      <article className='title'>
        <div className='txt'>
          <h6>
            엠블럼
          </h6>
        </div>
        <div className='txt-bar'></div>
        <div className="desc">
          <p>
            엠블럼은 이니셜 심볼의 외곽 라인에 이미지 심볼, 워드마크, 수원을 조화롭게 표현하였습니다.<br />
            특히 연고지인 수원시를 강조하여 지역밀착형 구단임을 보여줍니다.
          </p>
        </div>
        <div className="images">
          <div className='img-wrap'>
            <img className='pc' src={require('../../../assets/img/ktwiz/bi_emblem_white.png')} alt="" />
            <img className='m' src={require('../../../assets/img/ktwiz/bi_emblem_white_m.png')} alt="" />
          </div>
          <div className='img-wrap'>
            <img className='pc' src={require('../../../assets/img/ktwiz/bi_emblem_black.png')} alt="" />
            <img className='m' src={require('../../../assets/img/ktwiz/bi_emblem_black_m.png')} alt="" />
          </div>
        </div>
        <div className="buttons" style={{marginBottom:"50px"}}>
          <Link className='btn-download l' to={require('../../../assets/download/Emblem_ai.zip')} target='_blank' download>
            AI 다운로드
          </Link>
          <Link className='btn-download r' onClick={() => download(require('../../../assets/download/Emblem_jpg.jpg'), 'Emblem.jpg')}>
            JPG 다운로드
          </Link>
        </div>
        <br />
        <br />

        {/* post season area */}
        <div className='txt'>
          <h6>
            정규시즌 우승 엠블럼
          </h6>
        </div>
        <div className='txt-bar' style={{marginLeft: "310px"}}></div>
        <div className="desc" style={{paddingLeft: "310px"}}>
          <p>
          정규시즌 우승 엠블럼은 창단 첫 정규시즌 우승을 거둔 2021년도의 캐치프레이즈를 
            <br /> 활용하여 V1의 감동과 챔피언 구단의 강인함을 표현하였습니다.
          </p>
        </div>
        <div className="images">
          <div className='img-wrap'>
            <img className='pc' src={require('../../../assets/img/ktwiz/bi_regular_season_emblem_white.png')} alt="" />
            <img className='m' src={require('../../../assets/img/ktwiz/bi_regular_season_emblem_white.png')} alt="" />
          </div>
          <div className='img-wrap'>
            <img className='pc' src={require('../../../assets/img/ktwiz/bi_regular_season_emblem_black.png')} alt="" />
            <img className='m' src={require('../../../assets/img/ktwiz/bi_regular_season_emblem_black.png')} alt="" />
          </div>
        </div>
        <div className="buttons">
          <Link className='btn-download l' onClick={() => download(require('../../../assets/img/ktwiz/bi_regular_season_emblem_white.png'), 'Emblem_regular_white.png')}>
            다운로드
          </Link>
          <Link className='btn-download r' onClick={() => download(require('../../../assets/img/ktwiz/bi_regular_season_emblem_black.png'), 'Emblem_regular_black.png')}>
            다운로드
          </Link>
        </div>
        <br />
        <br />

        {/* winner emblem */}
        <div className='txt'>
          <h6>
            통합우승 엠블럼
          </h6>
        </div>
        <div className='txt-bar' style={{marginLeft: "250px"}}></div>
        <div className="desc" style={{paddingLeft: "250px"}}>
          <p>
            통합우승 엠블럼은 창단 첫우승을 기념하여 10개 구단의 모습을 트로피로 표현하였고
            <br />가장 높은 1위의 위치에 별 모양으로 우리를 표현하였습니다.
          </p>
        </div>
        <div className="images">
          <div className='img-wrap'>
            <img className='pc' src={require('../../../assets/img/ktwiz/bi_winner_emblem_white.png')} alt="" />
            <img className='m' src={require('../../../assets/img/ktwiz/bi_winner_emblem_white.png')} alt="" />
          </div>
          <div className='img-wrap'>
            <img className='pc' src={require('../../../assets/img/ktwiz/bi_winner_emblem_black.png')} alt="" />
            <img className='m' src={require('../../../assets/img/ktwiz/bi_winner_emblem_black.png')} alt="" />
          </div>
        </div>
        <div className="buttons">
          <Link className='btn-download l' onClick={() => download(require('../../../assets/img/ktwiz/bi_winner_emblem_white.png'), 'Emblem_winner_white.png')}>
            다운로드
          </Link>
          <Link className='btn-download r' onClick={() => download(require('../../../assets/img/ktwiz/bi_winner_emblem_black.png'), 'Emblem_winner_black.png')}>
            다운로드
          </Link>
        </div>
      </article>
    </>
  )
}

export default Emblem
