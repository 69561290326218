import React from 'react'

const Email = ({ onClose }) => {
  return (
    <div class="util_popup email">
      <div class="util_popup_top">
        <h4>이메일 무단 수집거부</h4>
        <span>kt wiz는 팬들의 개인정보를 소중히 다루고 있습니다.</span>
      </div>
      <div class="util_popup_content">
        <p>
          kt wiz는 이메일 무단 수집되는 것을 거부합니다.<br />
          본 웹사이트에 게시된 이메일 주소가 전자우편 수집 프로그램이나 그 밖의 기술적 장치를 이용하여<br />
          무단으로 수집되는 것을 거부하며, 이를 위반 시 정보통신법에 의해 형사처벌됨을 유념하시기 바랍니다.<br /><br />
          &lt;게시일 2014년 4월 7일&gt;
      </p>
      </div>
      <button class="btn_popup_close" onClick={() => { onClose(false) }}>닫기</button>
      {/* <span class="back_arrow"></span> */}
    </div>

  )
}

export default Email
