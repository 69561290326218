import React, { useEffect } from 'react'
// import Home from '../components/Home'
import Home from '../components/Home'
import { useSelector, useDispatch } from 'react-redux'
import { highlightRequest, wizGalleryRequest, hotIssueRequest, gameVideoRequest } from '../modules/media/wizBoard'
import { teamRankRequest } from '../modules/game/ranking'
import { autoScreenStatusRequest, recentRegularGamesRequest } from '../modules/game/gameSchedule'

const HomeContainer = () => {

  const dispatch = useDispatch()

  const autoscreenStatus = useSelector(state => state.wizData.data && state.wizData.data['AUTOSCREENSTATUS'])
  // const autoscreenStatusError = useSelector(state => state.wizData.error && state.wizData.error['AUTOSCREENSTATUS'])

  const recentGames = useSelector(state => state.wizData.data && state.wizData.data['RECENT_REGULAR_GAMES'])
  // const recentGamesError = useSelector(state => state.wizData.error && state.wizData.error['RECENT_REGULAR_GAMES'])

  const highlightList = useSelector(state => state.wizData.data && state.wizData.data['highlight'])
  // const highlightListError = useSelector(state => state.wizData.error && state.wizData.error['highlight'])

  const teamRanking = useSelector(state => state.wizData.data && state.wizData.data['TEAM_RANKING'])
  // const teamRankingError = useSelector(state => state.wizData.error && state.wizData.error['TEAM_RANKING'])

  const galleryList = useSelector(state => state.wizData.data && state.wizData.data['gallery'])
  // const galleryListError = useSelector(state => state.wizData.error && state.wizData.error['gallery'])

  const hotIssueList = useSelector(state => state.wizData.data && state.wizData.data['hotissue'])
  // const hotIssueListError = useSelector(state => state.wizData.error && state.wizData.error['hotissue'])

  const gameVideo = useSelector(state => state.wizData.data && state.wizData.data['gameVideo'])
  // const gameVideoError = useSelector(state => state.wizData.error && state.wizData.error['gameVideo'])

  useEffect(() => {
    dispatch(autoScreenStatusRequest())
    dispatch(recentRegularGamesRequest())
    dispatch(highlightRequest({ 'search.pos': 0, 'search.max': 5 }))
    dispatch(wizGalleryRequest({ 'search.pos': 0, 'search.max': 12 }))
    dispatch(hotIssueRequest({ 'search.pos': 0, 'search.max': 5 }))
    dispatch(gameVideoRequest({}))
    dispatch(teamRankRequest({}, 'TEAM_RANKING'))
  }, [])

  return <Home autoscreenStatus={autoscreenStatus} recentGames={recentGames} highlightList={highlightList} teamRanking={teamRanking && teamRanking.ktWizTeamRank} galleryList={galleryList} hotIssueList={hotIssueList} gameVideo={gameVideo} />
}

export default HomeContainer
