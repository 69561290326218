import React from 'react'
import FindPwContainer from '../../containers/auth/FindPwContainer'
import PageTemplate from '../../components/common/PageTemplate'
import PageHeaderContainer from '../../containers/common/PageHeaderContainer'
import ContentTemplate from '../../components/common/ContentTemplate'
import PageTitleTemplate from '../../components/common/PageTitleTemplate'
import Breadcrumb from '../../components/common/Breadcrumb'
import { FINDPW_PAGE } from '../../constants/page'

const FindPwPage = () => {
  return (
    <PageTemplate className='member'>
      <PageHeaderContainer title={FINDPW_PAGE.title} image={require('../../assets/img/login/sub_tit_membership.png')} />
      <ContentTemplate>
        <PageTitleTemplate>
          <Breadcrumb page={FINDPW_PAGE} />
        </PageTitleTemplate>
        <FindPwContainer />
      </ContentTemplate>
    </PageTemplate>
  )
}

export default FindPwPage
