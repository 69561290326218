import React from 'react'
import ContentTemplate from '../../components/common/ContentTemplate'
import SubContentTemplate from '../../components/common/SubContentTemplate'
import PageHeaderContainer from '../../containers/common/PageHeaderContainer'
import PageTitleTemplate from '../../components/common/PageTitleTemplate'
import BreadcrumbContainer from '../../containers/common/BreadcrumbContainer'
import PageTemplate from '../../components/common/PageTemplate'
import { REGULAR_LEAGUE_RANKING_CROWD, REGULAR_LEAGUE_TAB, REGULAR_LEAGUE_RANKING_TAB } from '../../constants/page'
import RegularLeagueCrowdRankingContainer from '../../containers/game/RegularLeagueCrowdRankingContainer'

const RegularLeagueCrowdRankingPage = ({ match }) => {

  const currentPage = REGULAR_LEAGUE_RANKING_CROWD

  REGULAR_LEAGUE_RANKING_TAB.forEach(tab => {
    tab.isActive = false
    if (match.path === tab.path) {
      tab.isActive = true
    }
  })

  return (
    <PageTemplate className="game">
      <PageHeaderContainer title="정규 리그" subtitle=" kt wiz 정규리그 기록을 알려 드립니다." image={require('../../assets/img/game/sub_tit_game.png')} forwardTabs={REGULAR_LEAGUE_TAB} currentPath={currentPage.parent.path} />
      <ContentTemplate className='game_rank_record'>
        <PageTitleTemplate>
          <BreadcrumbContainer page={REGULAR_LEAGUE_RANKING_CROWD} />
        </PageTitleTemplate>
        <SubContentTemplate tabs={REGULAR_LEAGUE_RANKING_TAB}>
          <RegularLeagueCrowdRankingContainer match={match} />
        </SubContentTemplate>
      </ContentTemplate>
    </PageTemplate>
  )
}

export default RegularLeagueCrowdRankingPage
