import React from 'react'
import Link from '../common/Link'
import NoSearchResult from '../../components/common/NoSearchResult'
// import * as Enum from '../../constants/enum'

const PlayListItem = ({ type, search, list }) => {

  const filterList = list && list.filter(item => item.playerName.indexOf(search) !== -1)

  return (
    <>
      {
        (filterList && filterList.length > 0)
          ? filterList.map((n, index) => (
            <li key={index} className={(index + 1) % 4 === 0 ? 'fourth' : ''}>
              <article className="article">
                <Link to={`/player/${type}/detail?pcode=${n.pcode}`}>
                  <div className="tit_img">
                    <img src={`${n.playerPrvwImg}`.replace('http://localhost:8080', 'http://test.ktwiz.co.kr:17400')} alt={`No.${n.backnum} ${n.playerName}`} />
                    <p><span className="num">{n.backnum && `No. ${n.backnum}`}</span><br /><span className="name">{n.playerName}</span></p>
                  </div>
                  <div className="tit_detail">
                    {
                      (type === 'coach') &&
                      <dl>
                        <dd><span className="position">{n.position}</span></dd>
                        <dt>{n.playerName}<span className="no_txt">{n.backnum && `No. ${n.backnum}`}</span></dt>
                      </dl>
                    }
                    {
                      (type === 'pitcher' || type === 'catcher' || type === 'infielder' || type === 'outfielder') &&
                      <dl>
                        <dt><span className="no_txt">{n.backnum && `No. ${n.backnum}`}</span>{n.playerName}</dt>
                        <dd><span className="position">{n.position}({n.hittype})</span></dd>
                      </dl>
                    }
                  </div>
                </Link>
              </article>
            </li>
          ))
          : <NoSearchResult />

      }
    </>
  )
}

export default PlayListItem
