import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { resourceRequest, useResource } from '../../modules/resource/resource'
import * as dialogActions from "../../modules/common/dialog"
import Prepairing from '../common/Prepairing'

const SeatMap = () => {
  const SEAT_MAP_M = 'SEAT_MAP_M'
  const SEAT_MAP_PC = 'SEAT_MAP_PC'
  const [imagePc, errorPc] = useResource(SEAT_MAP_PC)
  const [imageM, errorM] = useResource(SEAT_MAP_M)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(resourceRequest(SEAT_MAP_PC))
    dispatch(resourceRequest(SEAT_MAP_M))
  }, [])

  useEffect(() => {
    if (errorPc && errorPc.message) {
      dispatch(dialogActions.alertOpen(errorPc.message))
    }
    if (errorM && errorM.message) {
      dispatch(dialogActions.alertOpen(errorM.message))
    }
  }, [errorPc, errorM])

  const isPrePage = process.env.REACT_APP_IS_PREPAIRING_PAGE

  return (
    <>
      {isPrePage === "Y" && <Prepairing />}
      {
        isPrePage === "N" &&
        <>
          <article className='arti'>
            <div className='img-title pc'>
              <img src={require('../../assets/img/ticket/seat_arrangement_title.jpg')} alt='' />
            </div>
            <div className='img-title m'>
              <img src={require('../../assets/img/ticket/seat_arrangement_title_m.png')} alt='' />
            </div>
            <div className='desc'>
              <div className='img-whole'>
                <img className='pc' src={`${imagePc && imagePc.resource && imagePc.resource.value}`.replace('localhost:8080', 'test.ktwiz.co.kr:17400')} alt="" />
                <img className='m' src={`${imageM && imageM.resource && imageM.resource.value}`.replace('localhost:8080', 'test.ktwiz.co.kr:17400')} alt="" />
              </div>
            </div>
          </article>
        </>
      }
    </>
  )
}

export default SeatMap
