import React from 'react'
import { Redirect } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { init } from '../../modules/join/join'
import { useEffect } from 'react'

const JoinInitPage = () => {

  const dispatch = useDispatch()

  useEffect(()=>{
    dispatch(init())
  },[dispatch])

  return (
    <Redirect to='/join/step' />
  )
}

export default JoinInitPage
