// 액션 타입 (action type)
const SET_GALLERY = 'photos/SET_GALLERY'
const SET_ARROW = 'photos/SET_ARROW'

// 액션 생성자 (action creator)
export const setGallery = isGallery => ({ type: SET_GALLERY, isGallery })
export const setArrow = arrow => ({ type: SET_ARROW, arrow })

const initialState = {
  isGallery: false,
  arrow: null
}

// reducer
const gallery = (state = initialState, action) => {
  switch (action.type) {
    case SET_GALLERY:
      return {
        ...state,
        isGallery: action.isGallery
      }
    case SET_ARROW:
      return {
        ...state,
        arrow: action.arrow
      }
    default:
      return state
  }
}

export default gallery


