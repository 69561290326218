import { put, takeEvery, all, call, fork } from 'redux-saga/effects'
// actions
import * as certActions from '../../modules/auth/cert'
import * as loadingActions from '../../modules/common/loading'
import * as dialogActions from '../../modules/common/dialog'
// api
import * as certApi from '../../api/auth/cert'
// const
import * as Enum from '../../constants/enum'

/**
 * 인증 요청
 * @param {*} param0
 */
function* cert({ userCert }) {

  // 1. loading OPEN
  yield put(loadingActions.setLoading(true))

  // 2. 인증 요청 API 호출
  const callCertResp = yield call(certApi.callCert, userCert)

  // 3. 결과
  if (callCertResp.certSeq === null || callCertResp.certSeq === undefined) {
    // fail
    yield put(certActions.certFail())
    yield put(dialogActions.alertOpen(callCertResp.error.message))
  } else {
    // success (certSeq 저장)
    yield put(certActions.certSuccess(callCertResp.certSeq))
    yield put(dialogActions.alertOpen(`회원님의 ${userCert.certType === Enum.CERT_TYPES.MOBILE ? '핸드폰으로' : '이메일로'} 인증번호를 발송하였습니다.`))
  }

  // 4. loading CLOSE
  yield put(loadingActions.setLoading(false))

}

function* certWatch() { yield takeEvery(certActions.CERT_REQUEST, cert) }

/**
 * 인증확인요청
 * @param {*} param0
 */
function* certConfirm({ userCert, isSuccessAlert, isFailAlert }) {

  // 2. 인증 확인 요청 API 호출
  const callCertConfirmResp = yield call(certApi.callCertConfirm, userCert)

  // 3. 결과
  if (callCertConfirmResp.error !== undefined) {
    // fail
    yield put(certActions.certConfirmFail())
    if (isFailAlert) {
      yield put(dialogActions.alertOpen(callCertConfirmResp.error.message))
    }
  } else {
    // success
    yield put(certActions.certConfirmSuccess())
    if (isSuccessAlert) {
      yield put(dialogActions.alertOpen('인증이 완료되었습니다.'))
    }
  }

}

function* certConfirmWatch() { yield takeEvery(certActions.CERT_CONFIRM_REQUEST, certConfirm) }

export default function* certSaga() {
  yield all([
    fork(certWatch),
    fork(certConfirmWatch),
  ])
}
