import React from 'react'
import Link from '../../../components/common/Link'

const SongMobileListItem = ({ list, onClickIsLyricsPopup, onClickIsSoundPopup }) => {

  return (
    <>
      {
        list &&
        list.map((v, i) => (
          <li key={i}>
            <span className="song_tit">{v.cheerSongTitle}</span>
            <Link onClick={() => onClickIsSoundPopup(true, {
              cheerSongTitle: v.cheerSongTitle,
              cheerSongLyrics: v.cheerSongLyrics,
              cheerSongUrl: v.cheerSongUrl
            })} className="btn_song_sound"></Link>
            <Link onClick={() => onClickIsLyricsPopup(true, {
              cheerSongTitle: v.cheerSongTitle,
              cheerSongLyrics: v.cheerSongLyrics,
            })} className="btn_song_lyrics"></Link>
          </li>
        ))
      }
    </>
  )
}

export default SongMobileListItem
