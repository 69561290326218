import React from 'react'

const JoinStep = ({ join }) => {

  if (join.joinUser.isAdult != null && join.approvedTerms == null) {
    if (join.joinUser.isAdult) {
      return (
        <div className="sign_step_wrap">
          <img src={require('../../../assets/img/login/step1.png')} alt="약관동의" className="m_invisible" />
          <img src={require('../../../assets/img/login/step1_m.png')} alt="약관동의" className="pc_invisible" />
        </div>
      )
    } else {
      return (
        <div className="sign_step_wrap">
          <img src={require('../../../assets/img/login/step1_14.png')} alt="약관동의" className="m_invisible" />
          <img src={require('../../../assets/img/login/step1_14_m.png')} alt="약관동의" className="pc_invisible" />
        </div>
      )
    }
  } else if (join.approvedTerms != null && join.joinUser.simpleId == null) {
    if (join.joinUser.isAdult) {
      return (
        <div className="sign_step_wrap">
          <img src={require('../../../assets/img/login/step2.png')} alt="정보입력" className="m_invisible" />
          <img src={require('../../../assets/img/login/step2_m.png')} alt="정보입력" className="pc_invisible" />
        </div>
      )
    } else {
      return (
        <div className="sign_step_wrap">
          <img src={require('../../../assets/img/login/step2_14.png')} alt="정보입력" className="m_invisible" />
          <img src={require('../../../assets/img/login/step2_14_m.png')} alt="정보입력" className="pc_invisible" />
        </div>
      )
    }
  } else if (join.memberId == null) {
    if (join.joinUser.isAdult) {
      return (
        <div className="sign_step_wrap">
          <img src={require('../../../assets/img/login/step3.png')} alt="본인인증" className="m_invisible" />
          <img src={require('../../../assets/img/login/step3_m.png')} alt="본인인증" className="pc_invisible" />
        </div>
      )
    } else {
      return (
        <div className="sign_step_wrap">
          <img src={require('../../../assets/img/login/step3_14.png')} alt="보호자 인증" className="m_invisible" />
          <img src={require('../../../assets/img/login/step3_14_m.png')} alt="보호자 인증" className="pc_invisible" />
        </div>
      )
    }
  } else if (join.memberId != null) {
    if (join.joinUser.isAdult) {
      return (
        <div className="sign_step_wrap">
          <img src={require('../../../assets/img/login/step4.png')} alt="가입완료" className="m_invisible" />
          <img src={require('../../../assets/img/login/step4_m.png')} alt="가입완료" className="pc_invisible" />
        </div>
      )
    } else {
      return (
        <div className="sign_step_wrap">
          <img src={require('../../../assets/img/login/step4_14.png')} alt="가입완료" className="m_invisible" />
          <img src={require('../../../assets/img/login/step4_14_m.png')} alt="가입완료" className="pc_invisible" />
        </div>
      )
    }
  }
}

export default JoinStep
