import React from 'react'
import ContentTemplate from '../../components/common/ContentTemplate'
import SubContentTemplate from '../../components/common/SubContentTemplate'
import PageHeaderContainer from '../../containers/common/PageHeaderContainer'
import PageTitleTemplate from '../../components/common/PageTitleTemplate'
import BreadcrumbContainer from '../../containers/common/BreadcrumbContainer'
import PageTemplate from '../../components/common/PageTemplate'
import { KTWIZ_WALLPAPER } from '../../constants/page'
import WallpaperContainer from '../../containers/ktwiz/WallpaperContainer'

const WallpaperPage = () => {
  return (
    <PageTemplate className="ktwiz">
      <PageHeaderContainer title="월페이퍼" subtitle=" kt wiz 팬들을 위한 월페이퍼 다운로드 서비스" image={require('../../assets/img/ktwiz/sub_tit_ktwiz.png')} />
      <ContentTemplate>
        <PageTitleTemplate>
          <BreadcrumbContainer page={KTWIZ_WALLPAPER} />
        </PageTitleTemplate>
        <SubContentTemplate className="ktwiz wallpaper">
          <WallpaperContainer />
        </SubContentTemplate>
      </ContentTemplate>
    </PageTemplate>
  )
}

export default WallpaperPage
