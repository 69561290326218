import wiz from '../../api/wiz'
import { dataRequest, dataClear } from '../common/wizData'

// action types
export const SELECT_USER_TYPE = 'join/SELECT_USER_TYPE'
export const JOIN_TERM_REQUEST = 'join/JOIN_TERM_REQUEST'
export const JOIN_TERM_SUCCESS = 'join/JOIN_TERM_SUCCESS'
export const JOIN_TERM_FAIL = 'join/JOIN_TERM_FAIL'
export const APPROVE_TERMS = 'join/APPROVE_TERMS'
export const SET_JOIN_USER = 'join/SET_JOIN_USER'
export const JOIN_REQUEST = 'join/JOIN_REQUEST'
export const JOIN_SUCCESS = 'join/JOIN_SUCCESS'
export const JOIN_FAILURE = 'join/JOIN_FAILURE'
export const JOIN_INIT = 'join/INIT'

// action creators
export const selectUserType = (userType) => ({ type: SELECT_USER_TYPE, isAdult: userType === 'ADULT' })
export const joinTermRequest = () => ({ type: JOIN_TERM_REQUEST })
export const joinTermSuccess = () => ({ type: JOIN_TERM_SUCCESS })
export const joinTermFail = () => ({ type: JOIN_TERM_FAIL })
export const setApprovedTerms = (approvedTerms) => ({ type: APPROVE_TERMS, approvedTerms: approvedTerms })
export const setJoinUser = (joinUser) => ({ type: SET_JOIN_USER, joinUser: joinUser })
export const joinRequest = (joinUser) => {
  return { type: JOIN_REQUEST, joinUser: joinUser }
}
export const init = () => {
  return { type: JOIN_INIT, terms: null, approvedTerms: null, joinUser: {}, memberId: null, error: null }
}

// reducers
const joinInitialState = {
  terms: null,
  approvedTerms: null,
  joinUser: {},
  memberId: null,
  error: null
}

export const join = (state = joinInitialState, action) => {
  switch (action.type) {
    case JOIN_INIT:
      return {
        ...action
      }
    case SELECT_USER_TYPE:
      state.joinUser.isAdult = action.isAdult
      return {
        ...state,
        joinUser: state.joinUser
      }
    case JOIN_TERM_REQUEST:
      return {
        ...state,
        terms: null
      }
    case JOIN_TERM_SUCCESS:
      return {
        ...state,
        terms: action.terms
      }
    case JOIN_TERM_FAIL:
      return {
        ...state,
        terms: null
      }
    case APPROVE_TERMS:
      return {
        ...state,
        approvedTerms: action.approvedTerms
      }
    case SET_JOIN_USER:
      return {
        ...state,
        joinUser: action.joinUser
      }
    case JOIN_REQUEST:
      return {
        ...state,
        joinUser: action.joinUser
      }
    case JOIN_SUCCESS:
      return {
        ...state,
        memberId: action.memberId
      }
    case JOIN_FAILURE:
      return {
        ...state,
        error: action.error
      }
    default:
      return state
  }
}

export const CHECK_USER_ID_REQUEST = 'join/CHECK_USER_ID_REQUEST'
export const CHECK_USER_ID_SUCCESS = 'join/CHECK_USER_ID_SUCCESS'
export const CHECK_USER_ID_FAILURE = 'join/CHECK_USER_ID_FAILURE'

//export const checkUserIdRequest = (userId, reqType) => ({ type: CHECK_USER_ID_REQUEST, userId: userId, reqType: reqType })
export const checkUserIdSuccess = (isDuplicate) => ({ type: CHECK_USER_ID_SUCCESS, isDuplicate: isDuplicate })
export const checkUserIdFailure = () => ({ type: CHECK_USER_ID_FAILURE })

export const AUTH_NO_REQUEST = 'join/AUTH_NO_REQUEST'
export const AUTH_NO_SUCCESS = 'join/AUTH_NO_SUCCESS'
export const AUTH_NO_FAILURE = 'join/AUTH_NO_FAILURE'

export const authNoRequest = (mobpNo) => ({ type: AUTH_NO_REQUEST, mobpNo: mobpNo })
export const authNoSuccess = () => ({ type: AUTH_NO_SUCCESS })
export const authNoFailure = () => ({ type: AUTH_NO_FAILURE })

export const IDENTIFY_REQUEST = 'join/IDENTIFY_REQUEST'
export const IDENTIFY_SUCCESS = 'join/IDENTIFY_SUCCESS'
export const IDENTIFY_FAILURE = 'join/IDENTIFY_FAILURE'

export const identify = (type, data) => ({ type: IDENTIFY_REQUEST, identifyType: type, identifyData: data })

// reducers
const joinFormCheckInitialState = {
  isDuplicate: null,
  reqType: null,
  realNameRequestSeq: null,
  mobpNo: null,
  authNo: null,
  identifyType: null,
  identifyData: null,
  identifyResult: null,
}

export const joinFormCheck = (state = joinFormCheckInitialState, action) => {
  switch (action.type) {
    case CHECK_USER_ID_REQUEST:
      return {
        ...state,
        userId: action.userId,
        reqType: action.reqType
      }
    case CHECK_USER_ID_SUCCESS:
      return {
        ...state,
        isDuplicate: action.isDuplicate,
        reqType: action.reqType,
        realNameRequestSeq: action.realNameRequestSeq
      }
    case AUTH_NO_REQUEST:
      return {
        ...state,
        mobpNo: action.mobpNo
      }
    case AUTH_NO_SUCCESS:
      return {
        ...state,
        authNo: action.authNo
      }
    case IDENTIFY_REQUEST:
      return {
        ...state,
        identifyType: action.identifyType,
        identifyData: action.identifyData
      }
    case IDENTIFY_SUCCESS:
      return {
        ...state,
        identifyResult: action.identifyResult
      }
    default:
      return state
  }
}




export const createWizRequest = (api, params, dataType) => {
  return dataRequest(
    api,
    params,
    dataType
  )
}

//checkUserIdRequest = (userId, reqType) => ({ type: CHECK_USER_ID_REQUEST, userId: userId, reqType: reqType })
export const checkUserIdRequest = (userId, dataType) => createWizRequest(wiz.API.USER_ID_CHECK, { 'simpleId': userId }, dataType)
export const clearRequestData = (dataType) => dataClear(dataType)
