import React from 'react'
import Link from '../../common/Link'
import JoinStep from './JoinStep'

const JoinComplete = ({ join }) => {
  return (
    <>
      <JoinStep join={join} />

      <div class="content_box sign_up_step4">
        <div class="content_desc">
          <img src={require('../../../assets/img/login/victory.png')} alt="회원가입 축하 이미지" className="img1" />
          <img src={require('../../../assets/img/common/logo_b.png')} alt="kt wiz 로고" className="img2" />
          <span>회원가입이 완료되었습니다.</span>
          <p>kt wiz 서비스를 가입해 주셔서 감사합니다.<br />경기 정보 및 티켓예매, 주차예약 등 다양한<br />서비스를 편리하게 이용하실 수 있습니다.</p>
        </div>

        <div class="link_btn_wrap">
          <div class="row btn_row clearfix">
            <div class="link_btn link_btn1 float_l">
              <a href="/">홈으로 가기</a>
            </div>
            <div class="link_btn link_btn2 float_l">
              <Link to="/login">로그인 하기</Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default JoinComplete
