import React from 'react'
import Link from '../../../components/common/Link'

const JoinStep1 = ({ join, onSelectUserType }) => {
  return (
    <>
      <div className='content_box sign_up'>
        <div className='content_desc'>
          <img src={require('../../../assets/img/login/kt_logo.png')} alt='kt wiz 로고' className='img_wrap' />
          <p>
            kt wiz에 가입하여 티켓예매, 경기상세기록 등<br />
            다양한 서비스를 편리하게 이용해보세요.
          </p>
        </div>

        <div className='link_btn_wrap'>
          <div className='row btn_row clearfix'>
            <div className='link_btn link_btn1 float_l'>
              <Link onClick={() => onSelectUserType('ADULT')}>
                <p>
                  일반
                  <br />
                  회원가입
                </p>
              </Link>
            </div>
            <div className='link_btn link_btn2 float_l'>
              <Link onClick={() => onSelectUserType('CHILDREN')} className='link_btn link_btn2 float_r'>
                <p>
                  14세 미만
                  <br />
                  회원가입
                </p>
              </Link>
            </div>
          </div>
        </div>

        <div className='desc_wrap'>
          <span className='desc_tit'>[ kt wiz 회원안내 ]</span>
          <p className='desc desc1'>회원 가입 후, 실명인증을 완료한 고객은 무료 회원으로 분류되어 티켓예매 등과 같은 예매가 필요한 서비스 또한 이용 가능합니다.</p>
          <span className='desc_tit'>[ 만 14세 미만 회원가입 안내 ]</span>
          <p className='desc desc2'>만 14세 미만은 법률에 의거하여 보호자(법적대리인)의 동의가 필요합니다.</p>
        </div>
      </div>
    </>
  )
}

export default JoinStep1
