import React, { useState, useEffect, useRef } from 'react'
import SongPcTeamList from './SongPcTeamList'

const SongPcTeam = ({ teamSongList, isPlaying, onIsPlaying }) => {

  const audioRef = useRef()
  const [selectItem, setSelectItem] = useState(0)
  const onClickSelectItem = key => { setSelectItem(key) }
  const onClickPauseAudio = () => { audioRef.current.pause() }
  const onClickPlayAudio = () => {
    audioRef.current.play()
    onIsPlaying({
      team: true,
      player: false,
    })
  }

  const onLoad = () => { audioRef.current.load() }
  const onCanPlay = () => { /* audioRef.current.play() */ } // 사운드 파일 로드시 자동 플레이

  useEffect(() => {
    onLoad() // 응원가 선택시 재로드
    onIsPlaying({
      team: true,
      player: false,
    })
  }, [selectItem])

  useEffect(() => {
    (isPlaying.player === true) &&
      onClickPauseAudio() // 선수 응원가 재생시 정지
  }, [isPlaying])

  const selectedTeamSong = teamSongList && teamSongList.length > 0 && teamSongList[selectItem]

  return (
    <>
      {/* song player container */}
      <div className="song_player_container">
        {/* song_player_wrap */}
        <div className="song_player_wrap">
          <div className="song_contents_view">
            <dl>
              <dt>{selectedTeamSong && selectedTeamSong.cheerSongTitle}</dt>
              <dd>
                {
                  selectedTeamSong &&
                  selectedTeamSong.cheerSongLyrics.split('\n').map((v, i) => (
                    <p key={i}>
                      {v}
                      <br />
                    </p>
                  ))
                }
              </dd>
            </dl>
          </div>

          <SongPcTeamList
            teamSongList={teamSongList}
            selectItem={selectItem}
            onClickSelectItem={onClickSelectItem}
            onClickPlayAudio={onClickPlayAudio}
            onClickPauseAudio={onClickPauseAudio} />

          <div className="song_player">
            <audio
              ref={audioRef}
              controls
              loop
              preload="true"
              style={{ width: "100%", backgroundColor: "#f1f3f4" }}
              controlsList="nodownload"
              src={selectedTeamSong && selectedTeamSong.cheerSongUrl.replace('http://localhost:8080', 'http://test.ktwiz.co.kr:17400')}
              onPlay={onClickPlayAudio}
              onPause={onClickPauseAudio}
              onCanPlay={(e) => onCanPlay(e)}
            >
              <source src={selectedTeamSong && selectedTeamSong.cheerSongUrl.replace('http://localhost:8080', 'http://test.ktwiz.co.kr:17400')} loop="loop" type="audio/mpeg" />
              <source src={selectedTeamSong && selectedTeamSong.cheerSongUrl.replace('http://localhost:8080', 'http://test.ktwiz.co.kr:17400')} loop="loop" type="audio/ogg" />
              HTML5 Audio를 지원하지 않는 브라우저입니다.
            </audio>
          </div>

        </div>
      </div>
    </>
  )
}

export default SongPcTeam
