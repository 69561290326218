import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import LiveVideoList from '../../components/media/board/LiveVideoList';
import { positionViewRequest, matrixViewRequest, ptsRequest, ftsRequest } from '../../modules/media/liveVideo';
import qs from 'query-string'

const LiveVideoContainer = ({ mediaType }) => {

  const dispatch = useDispatch()

  const location = useLocation()

  const history = useHistory()

  const query = qs.parse(location.search)

  const page = query['page'] ? Number(query['page']) : 1

  const videoList = useSelector(state => state.wizData.data && state.wizData.data['PC_LIVE_VIDEO_LIST'])
  // const videoListError = useSelector(state => state.wizData.error && state.wizData.error['PC_LIVE_VIDEO_LIST'])

  const mobileVideoList = useSelector(state => state.wizData.data && state.wizData.data['M_LIVE_VIDEO_LIST'])
  // const mobileVideoListError = useSelector(state => state.wizData.error && state.wizData.error['M_LIVE_VIDEO_LIST'])

  const request = {
    position: positionViewRequest,
    matrix: matrixViewRequest,
    pts: ptsRequest,
    fts: ftsRequest
  }

  const onClickPage = page => {
    query.page = page

    history.push('/media/live/' + mediaType + '?' + qs.stringify(query))
  }

  const search = { 'interactiveMedia.mediaName': query['mediaName'], 'trackingMedia.mediaName': query['mediaName'], 'search.startDate': query['search.startDate'], 'search.endDate': query['search.endDate'] }


  useEffect(() => {
    dispatch(request[mediaType]({ 'search.pos': (page - 1) * 4, 'search.max': 4, ...search }, 'PC_LIVE_VIDEO_LIST'))
    dispatch(request[mediaType]({ 'search.pos': (page - 1) * 10, 'search.max': 10, ...search }, 'M_LIVE_VIDEO_LIST'))
  }, [location.search])

  return (
    <LiveVideoList videoList={videoList} mobileVideoList={mobileVideoList} page={page} onClickPage={onClickPage} />
  );
};

export default LiveVideoContainer;
