import React, { useEffect, useState } from "react";
import { callDormantUser, callNormalUser } from "../../../api/auth/dormant";
import { IDENTIFY_TYPE, LOGIN_FAIL_TYPE } from "../../../constants/enum";

const CertChange = ({ certStatus, userInfo, onLoginRequest }) => {
  const { kind } = certStatus;
  const [memorizedKind] = useState(kind);

  useEffect(() => {
    console.log("addEventListener");
    window.addEventListener("message", onMessage, false);
    return () => {
      console.log("removeEventListener");
      window.removeEventListener("message", onMessage);
    };
  });

  const onMessage = async (e) => {
    if (
      e.data.type === IDENTIFY_TYPE.MOBILE ||
      e.data.type === IDENTIFY_TYPE.IPIN
    ) {
      console.log(e);

      const { id, pw } = userInfo;

      const copiedParams = { ...e.data };
      copiedParams["simpleId"] = id;

      let result = null;

      if (kind === LOGIN_FAIL_TYPE.DORMANT) {
        result = await callDormantUser(copiedParams);
      } else if (kind === LOGIN_FAIL_TYPE.NORMAL) {
        result = await callNormalUser(copiedParams);
      }

      const returnObj = { returnUrl: "", isOutLink: "self" };

      console.log(result);

      if (result === true) {
        onLoginRequest({ id: id, pw: pw }, returnObj);
      }
    }
  };

  const openPhonePopup = () => {
    window.open(
      "/api/v2/user/identify/phone/request",
      "mobilem",
      "top=10, left=10, width=500, height=600, status=no, menubar=no, toolbar=no, resizable=no"
    );
  };

  const openIpinPopup = () => {
    window.open(
      "/api/v2/user/identify/ipin/request",
      "ipin2",
      "top=10, left=10, width=500, height=600, status=no, menubar=no, toolbar=no, resizable=no"
    );
  };

  return (
    <>
      {kind === LOGIN_FAIL_TYPE.DORMANT ? (
        <div className="dormant">
          <h1>
            회원님 아이디는 <br className="mb" />
            휴면 상태로 전환되었습니다.
          </h1>
          <p>
            회원님은 로그인 한 지 1년 이상이 경과되어
            <br className="mb" /> 아이디가 휴면 상태로 전환되었습니다. <br />
            본인 확인 후 다시 kt wiz 서비스 이용이 가능합니다.
          </p>
          <div className="btn-area">
            <button
              type="button"
              onClick={() => openPhonePopup()}
              className="login_btn"
            >
              휴대폰 인증
            </button>
            <button
              type="button"
              onClick={() => openIpinPopup()}
              className="login_btn light"
            >
              아이핀 인증
            </button>
          </div>
        </div>
      ) : kind === LOGIN_FAIL_TYPE.NORMAL ? (
        <div className="dormant">
          <h1>
            일반 회원 전환이 <br className="mb" />
            필요한 아이디 입니다.
          </h1>
          <p>
            회원님 아이디는 일반회원 전환이 필요합니다.
            <br />
            (올해 1월 1일 기준 14세 이상 회원)
            <br />
            본인인증 및 일반회원 전환 후<br className="mb" />
            kt wiz 서비스 이용이 가능합니다.
          </p>
          <div className="btn-area">
            <button
              type="button"
              onClick={() => openPhonePopup()}
              className="login_btn"
            >
              휴대폰 인증
            </button>
            <button
              type="button"
              onClick={() => openIpinPopup()}
              className="login_btn light"
            >
              아이핀 인증
            </button>
          </div>
        </div>
      ) : memorizedKind === LOGIN_FAIL_TYPE.DORMANT ? (
        <div className="dormant">
          <img
            src={require("../../../assets/img/login/welcome_back_logo.png")}
            alt="welcome 로고"
          />
          <h1>kt wiz 휴면 상태가 해제 되었습니다.</h1>
          <p>
            kt wiz를 다시 찾아주셔서 진심으로 감사합니다.
            <br />
            앞으로 더 좋은 서비스 제공을 위해 노력하겠습니다.
            <br />
            아래 버튼을 누르시면 정상적으로 서비스 이용이 가능합니다.
          </p>
          <div className="btn-area">
            <button
              type="button"
              onClick={() => (window.location.href = "/")}
              className="login_btn"
            >
              홈으로 가기
            </button>
          </div>
        </div>
      ) : (
        <div className="dormant">
          <img
            src={require("../../../assets/img/login/welcome_back_logo.png")}
            alt="welcome 로고"
          />
          <h1>
            일반회원 전환이 <br className="mb" />
            완료되었습니다.
          </h1>
          <p>
            kt wiz를 사랑해주시는 회원님
            <br />
            앞으로 더 좋은 서비스 제공을 위해 노력하겠습니다.
            <br />
            아래 버튼을 누르시면 정상적으로 서비스 이용이 가능합니다.
          </p>
          <div className="btn-area">
            <button
              type="button"
              onClick={() => (window.location.href = "/")}
              className="login_btn"
            >
              홈으로 가기
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default CertChange;
