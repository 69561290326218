import React from 'react';
import { useLocation } from 'react-router-dom';
import Link from '../../common/Link';
import { format } from 'date-fns';
import { LightgalleryItem } from "react-lightgallery"
import { useHistory } from 'react-router-dom'

const ArticlePhotoItem = ({ article, index, /*className,*/ isGallery, goArrow }) => {

  const history = useHistory()

  const { /*contents,*/ title, subTitle, seq, imgFilePath, registerAt /*, updateAt, viewCnt*/ } = article;
  const location = useLocation();

  const detailLink = `${location.pathname}/${seq}`;
  const liClassName = `${index % 2 ? 'block_right' : 'block_left'} ${(index + 1) % 3 ? '' : 'third'}`;

  console.log('ArticlePhotoItem: ', { location, article, index, liClassName, detailLink });


  const itemRef = React.useRef(null)

  const isPhone = () => {
    const userAgent = navigator.userAgent.toLowerCase(); //userAgent 값 얻기

    return userAgent.match('android') != null || /ipad|iphone|ipod/.test(userAgent)
  }

  const goDetail = (url) => {
    isPhone() && history.push(url)
  }

  const open = () => {
    itemRef && itemRef.current && itemRef.current.open()
  }

  React.useEffect(() => {
    console.log('isGallery', isGallery, 'goArrow', goArrow)
    if (isGallery && goArrow === 'next' && index === 0) {
      console.log('isGallery', isGallery, 'goArrow', goArrow, 'index', index)
      setTimeout(open, 1000)
    }
    if (isGallery && goArrow === 'prev' && index === 11) {
      console.log('isGallery', isGallery, 'goArrow', goArrow, 'index', index)
      setTimeout(open, 1000)
    }
  }, [])

  return (
    <li className={liClassName}>
      <article className='article'>
        <LightgalleryItem ref={itemRef} group='group'
          src={imgFilePath ? `${imgFilePath}`.replace('localhost:8080', 'test.ktwiz.co.kr:17400') : require(`../../../assets/img/media/gallery/gallery_0${index + 1}.png`)}
          subHtml={'.tit_detail' + (index + 1)}
        >
          <Link onClick={() => goDetail(detailLink)}>
            {/* <!-- 피씨일 경우 lightgallery로 연결, 모바일일 경우 상세페이지로 연결 --> */}
            <div className='tit_img'>
              {/* {imgFilePath && ( */}
              <img src={imgFilePath ? `${imgFilePath}`.replace('localhost:8080', 'test.ktwiz.co.kr:17400') : require('../../../assets/img/media/gallery/gallery_01.png')} alt={title} />
              {/* )} */}
            </div>
            <div className={'tit_detail tit_detail' + (index + 1)}>
              <div>
                <dl>
                  <dt>
                    <span className='category_txt'>{title}</span>
                    {subTitle}
                  </dt>
                </dl>
                <div className='tit_info'>
                  <ul>
                    <li>
                      <span className='date'>{format(registerAt, 'yyyy.MM.dd')}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </Link>
        </LightgalleryItem>
      </article>
    </li >
  );
};

export default ArticlePhotoItem;
