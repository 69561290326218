import React from "react";
import TitleBanner from "../common/TitleBanner";
import Link from "../common/Link";

const Shop = () => {
  return (
    <div className="container shop_page">
      <div className="shop_link">
        <Link to="https://www.ktwizstore.co.kr/" target=" _blank" isExt={true}>
          <img src={require("../../assets/img/common/shop_img.jpg")} />
        </Link>
      </div>
    </div>
  );
};

export default Shop;
