import React from 'react'
import { useLocation, useRouteMatch } from 'react-router-dom';
import ContentTemplate from '../../components/common/ContentTemplate'
import SubContentTemplate from '../../components/common/SubContentTemplate'
import PageHeaderContainer from '../../containers/common/PageHeaderContainer'
import PageTitleTemplate from '../../components/common/PageTitleTemplate'
import Breadcrumb from '../../components/common/Breadcrumb'
import ArticleSearch from '../../components/media/board/ArticleSearch'
import PageTemplate from '../../components/common/PageTemplate'
import { WIZSTORY_PAGE } from '../../constants/page'
import qs from 'query-string';
import WizBoard from '../../components/media/board/WizBoard';
import ArticleDetail from '../../components/media/board/ArticleDetail';

const WizStoryPage = () => {
  const matchSeq = useRouteMatch('/media/wizstory/:seq');
  const query = qs.parse(useLocation().search);

  return (
    <PageTemplate className='media'>
      <PageHeaderContainer
        title='wiz 스토리'
        subtitle='kt wiz 팬이 전하는 생생한 스토리를 만나보세요.'
        image={require('../../assets/img/media/sub_tit_media.png')}
      />
      <ContentTemplate className={matchSeq ? 'media_wiz_story_detail' : 'media_wiz_story'}>
        <PageTitleTemplate>
          <Breadcrumb page={WIZSTORY_PAGE} />
          {
            !matchSeq &&
            <ArticleSearch />
          }
        </PageTitleTemplate>
        <SubContentTemplate>
          {
            matchSeq
              ? <ArticleDetail seq={matchSeq.params.seq} query={query} />
              : <WizBoard board={'wizstory'} query={query} count={12} listType='grid' />
          }
        </SubContentTemplate>
      </ContentTemplate>
    </PageTemplate>
  )
}

export default WizStoryPage
