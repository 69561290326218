export const thirdPresentLogEn = [
  `A. 'Third parties' refer to all natural persons and corporations other than the service provider, personal information task entrant, business transferee, and the information subject using the service, which, in the case of the KT baseball team, are the business operators with whom a partnership contract has been established.`,
  `　　`,
  `\n`,
  `B. To provide personal information to a third party, it is necessary to sufficiently inform and obtain consent regarding the recipient, the purpose of the recipient's use of personal information, the items of personal information provided, and the period of retention and use of personal information by the recipient.`,
  `　　`,
  `\n`,
  `C. A recipient of personal information from a business operator shall not use the personal information for purposes other than the provided purpose or provide it to a third party without the separate consent of the information subject, except in cases where there is special provision in other laws.`,
  `　　`,
  `\n`,
  `1. Ticketlink`,
  `   - Items: ID, name, mobile phone number, address, membership card number`,
  `   - Purpose of provision: Ticket purchase`,
  `　　`,
  `\n`,
  `2. Smartro`,
  `   - Items: ID, name, mobile phone number, address, membership card number`,
  `   - Purpose of provision: Product purchase`,
  `　　`,
  `\n`,
  `3. Mobile Text Messaging Agency`,
  `   - Items: Mobile phone number`,
  `   - Purpose of provision: Sending mobile text messages`,
  `　　`,
  `\n`,
  `4. Email Agency`,
  `   - Items: Email address`,
  `   - Purpose of provision: Sending emails`,
  `　　`,
  `\n`,
  `5. KT`,
  `   - Items: Linked information value (I-PIN CI)`,
  `   - Purpose of provision: Point inquiry`,
  `　　`,
  `\n`,
  `6. NHN Entertainment`,
  `   - Items: ID, name, mobile phone number, address, membership card number`,
  `   - Purpose of provision: Ticket purchase`,
];

export const presentLogEn = [
  `This Privacy Policy applies to the use of the KT Sports website and all related services (including KT Sports, KT Wiz, KT Sonicboom web/app) provided by the company and contains the following information:`,
  `　　`,
  `\n`,
  `1. Types of personal information collected and method of collection`,
  `2. Purpose of collection and use of personal information`,
  `3. Sharing and provision of personal information`,
  `4. Entrustment of personal information processing`,
  `5. Provision of personal information to third parties`,
  `6. Retention and use period of personal information`,
  `7. Procedure and method for destruction of personal information`,
  `8. Rights of users and legal guardians and how to exercise them`,
  `9. Installation/operation of and refusal to automatic personal information collection devices`,
  `10. Technical/administrative measures for personal information protection`,
  `11. Contact information for the person in charge of managing personal information`,
  `12. Separate storage or destruction of personal information for users who have not used the website and wizzap services for a year`,
  `13. Duty to notify`,
  `　　`,
  `\n`,
  `1. Types of personal information collected and method of collection`,
  `　　`,
  `\n`,
  `The company collects the following personal information for membership registration, customer consultation, wizzap (App) services, paid services, and various other services:`,
  `　　`,
  `\n`,
  `a. Information collected during membership registration`,
  `- For members over 14 years old: ID, name, date of birth, gender, password, phone number, email address, authentication information, linked information value (I-PIN CI), duplicate check value (I-PIN DI), IP address, authentication date and time`,
  `- For members under 14 years old: ID, guardian's name, password, phone number, email address`,
  `- Postal code, address, membership consent, consent to receive text messages, consent to use for marketing, consent to provide to third parties, stadium entry information, stadium entrance date and time`,
  `　　`,
  `\n`,
  `b. Information automatically collected during service use or business processing`,
  `- Service use time/record, access log, password, content used, cookies, access IP information, billing and payment records/information, suspension records, address, phone number automatically collected and used for mobile login authentication, platform-specific OS, OS version, device model, browser information, email address sent through service inquiries`,
  `　　`,
  `\n`,
  `c. Personal information required for event participation`,
  `- Address and phone number other than prize collection location`,
  `　　`,
  `\n`,
  `d. Information collected for identity verification`,
  `- Name, date of birth, gender, duplicate membership check information, phone number`,
  `　　`,
  `\n`,
  `e. Payment information collected during paid service use`,
  `- For credit card payment: card issuer name, card number, etc.`,
  `- For mobile phone payment: mobile phone number, carrier, model name, payment authorization number, etc.`,
  `- For bank transfer: bank name, account number, account holder's name`,
  `- For smart OS-equipped mobile devices (Tablet, PC): phone number, model name, device sharing information`,
  `- For payment of discounted products affiliated with company and KT group companies/cooperative companies/partners: membership card number, point inquiry, etc. for authorization of usage rights and payment capability`,
  `- Information related to the use of additional services such as number information guide, address change service`,
  `　　`,
  `\n`,
  `2. Purpose of Collecting and Using Personal Information`,
  `　　`,
  `\n`,
  `The company collects personal information for the purpose of providing seamless services based on the collected information and to improve service quality. However, if a member explicitly refuses the provision of service information, the company will not provide the information, and the company is not responsible for any disadvantages caused by the lack of information provision.`,
  `　　`,
  `\n`,
  `a. Fulfillment of contracts related to service provision and billing`,
  `　　`,
  `\n`,
  `Content provision, customized service provision, delivery of goods or sending invoices, identity verification, purchase and payment, collection of fees`,
  `　　`,
  `\n`,
  `b. Member management`,
  `　　`,
  `\n`,
  `Provision of membership services, personal identification, restrictions on members violating the kt sports terms of use, sanctions against acts that interfere with the smooth operation of services and fraudulent use of services, confirmation of intent to join, restrictions on registration and the number of registrations, confirmation of consent from legal guardians when collecting personal information from children under 14, subsequent verification of legal guardians, record preservation for dispute resolution, handling complaints and grievances, communication of notices, confirmation of intent to withdraw membership`,
  `　　`,
  `\n`,
  `c. Development of new services and utilization for marketing and advertising`,
  `　　`,
  `\n`,
  `Development of new services and provision of customized services, advertising and service provision based on statistical characteristics, verification of service effectiveness, provision of event information and participation opportunities, provision of advertising information, determination of access frequency, statistics on service use by members`,
  `　　`,
  `\n`,
  `3. Sharing and Providing Personal Information`,
  `　　`,
  `\n`,
  `The company uses users' personal information within the scope notified in "2. Purpose of Collecting and Using Personal Information" and does not disclose or use it without the users' prior consent, except in the following cases:`,
  `　　`,
  `\n`,
  `a. When the user has given prior consent`,
  `　　`,
  `\n`,
  `b. When required by law or requested by investigative agencies according to the procedures and methods defined by law`,
  `　　`,
  `\n`,
  `4. Entrustment of Personal Information`,
  `　　`,
  `\n`,
  `For the improvement of services, the company entrusts personal information as follows, and ensures that necessary measures are taken for the safe management of personal information in accordance with related laws during the entrustment contract.`,
  `　　`,
  `\n`,
  `The company's personal information entrustment agencies and their tasks are as follows:`,
  `　　`,
  `\n`,
  `① Ticketlink`,
  `　　`,
  `\n`,
  `- Entrusted tasks: Ticket booking, payment for paid membership`,
  `　　`,
  `\n`,
  `- Period of retention and use of personal information: Until the member withdraws or the entrustment contract ends`,
  `　　`,
  `\n`,
  `② Smartro`,
  `　　`,
  `\n`,
  `- Entrusted tasks: Purchasing goods`,
  `　　`,
  `\n`,
  `- Period of retention and use of personal information: Until the member withdraws or the entrustment contract ends`,
  `　　`,
  `\n`,
  `③ KT`,
  `　　`,
  `\n`,
  `- Entrusted tasks: Customer point inquiry`,
  `　　`,
  `\n`,
  `- Period of retention and use of personal information: Until the member withdraws or the entrustment contract ends`,
  `　　`,
  `\n`,
  `④ Helixtech`,
  `　　`,
  `\n`,
  `- Entrusted tasks: Operation and management of the kt sports, kt wiz website`,
  `　　`,
  `\n`,
  `- Period of retention and use of personal information: Until the member withdraws or the entrustment contract ends`,
  `　　`,
  `\n`,
  `⑤ NICE Evaluation Information Co., Ltd.`,
  `　　`,
  `\n`,
  `- Entrusted tasks: Identity verification`,
  `　　`,
  `\n`,
  `- Period of retention and use of personal information: Not stored separately as the company already possesses the personal information`,
  `　　`,
  `\n`,
  `※ The company ensures that the entrusted companies take all necessary measures related to the protection of personal information.`,
  `　　`,
  `\n`,
  `5. Disclosure of Personal Information to Third Parties`,
  `　　`,
  `\n`,
  `The company may provide or share customers' personal information with third parties only with the customer's prior consent. In such cases, the company will inform who the recipient is, their main business, which personal information items are provided or shared, and the purpose of providing or sharing the personal information, and then seek consent. Even if a member does not consent to the provision of information to third parties at the time of membership registration, this does not restrict membership registration. However, exceptions are made in the following cases:`,
  `　　`,
  `\n`,
  `1) Under the provisions of laws or, if required by investigative agencies for investigation purposes following the procedures and methods defined by laws:`,
  `　　`,
  `\n`,
  `- Recipient: KT`,
  `- Purpose of Provision: Sending mobile text messages`,
  `- Items Provided: Mobile phone number`,
  `- Retention and Usage Period: Until the text message is sent`,
  `　　`,
  `\n`,
  `2) - Recipient: I-Names`,
  `- Purpose of Provision: Sending emails`,
  `- Items Provided: Email address`,
  `- Retention and Usage Period: Until the email is sent`,
  `　　`,
  `\n`,
  `6. Retention and Use Period of Personal Information`,
  `　　`,
  `\n`,
  `The company will destroy personal information without delay once the purpose of collecting or receiving the personal information is achieved, in principle. However, the information will be preserved for a certain period for the following reasons:`,
  `　　`,
  `\n`,
  `a. Reasons for information retention based on company policies:`,
  `　　`,
  `\n`,
  `- Record of misuse (abnormal service use such as fraudulent sign-up, disciplinary records): 1 year`,
  `- Reason for retention: Prevention of misuse`,
  `　　`,
  `\n`,
  `b. Reasons for information retention required by related laws:`,
  `　　`,
  `\n`,
  `In cases where it is necessary to retain information as required by the Commercial Act, the "Act on Consumer Protection in Electronic Commerce, etc.," and other related laws, the company will keep member information for a certain period as determined by related laws. The information will be used only for the purpose of retention, and the retention period is as follows:`,
  `　　`,
  `\n`,
  `1) Records related to contracts or withdrawal of subscriptions: 5 years`,
  `- Reason for retention: Act on Consumer Protection in Electronic Commerce, etc.`,
  `　　`,
  `\n`,
  `2) Records on payment and supply of goods, etc.: 5 years`,
  `- Reason for retention: Act on Consumer Protection in Electronic Commerce, etc.`,
  `　　`,
  `\n`,
  `3) Records on electronic financial transactions: 5 years`,
  `- Reason for retention: Electronic Financial Transactions Act`,
  `　　`,
  `\n`,
  `4) Records on consumer complaints or dispute resolutions: 3 years`,
  `- Reason for retention: Act on Consumer Protection in Electronic Commerce, etc.`,
  `　　`,
  `\n`,
  `5) Website visit records: 3 months`,
  `- Reason for retention: Protection of Communications Secrets Act`,
  `　　`,
  `\n`,
  `7. Procedures and Methods of Destroying Personal Information`,
  `　　`,
  `\n`,
  `The company will destroy personal information without delay once the purpose of use has been achieved.`,
  `　　`,
  `\n`,
  `The procedures and methods for the destruction of personal information are as follows:`,
  `　　`,
  `\n`,
  `a. Destruction procedure`,
  `　　`,
  `\n`,
  `- Information entered by users for membership registration or other purposes is transferred to a separate DB (in the case of paper, to a separate document box) after the purpose is achieved and stored for a certain period according to internal policies and other related legal reasons (refer to the retention and use period) before being destroyed.`,
  `- This personal information will not be used for any purpose other than retention unless required by law.`,
  `　　`,
  `\n`,
  `b. Destruction method`,
  `　　`,
  `\n`,
  `- Personal information printed on paper: Shredded or incinerated.`,
  `- Personal information stored in electronic file format: Deleted using technical methods that prevent the records from being reproduced.`,
  `　　`,
  `\n`,
  `8. Rights of Users and Their Legal Guardians and How to Exercise Them`,
  `　　`,
  `\n`,
  `Users and their legal guardians can always check or modify their own or their under-14 child's registered personal information, and can refuse to agree to the processing of personal information or request a withdrawal of consent (membership withdrawal). However, this may limit the use of all or part of the services.`,
  `　　`,
  `\n`,
  `For checking or modifying personal information of a user or a child under 14, click on "Change Personal Information" (or "Edit Member Information"), and follow the identity verification process to directly view, correct, or withdraw. You can also contact the personal information manager by mail, phone, or email for immediate action.`,
  `　　`,
  `\n`,
  `If a member requests correction of errors in their personal information, the information will not be used or provided until correction is completed. If incorrect information has been provided to a third party, we will notify the third party immediately of the correction to ensure it is corrected.`,
  `　　`,
  `\n`,
  `9. Installation/Operation of Automatic Personal Information Collection Devices and Rejection Thereof`,
  `　　`,
  `\n`,
  `The company uses cookies, which are small text files sent by the server used to operate the website to the user's browser and stored on the hard disk of the member's device (PC, smartphone, tablet, etc.), to store and frequently retrieve user information to provide personalized and customized services.`,
  `　　`,
  `\n`,
  `Cookies are used to maintain and customize user settings by reading the contents of cookies stored on the hard disk when users visit the website.`,
  `　　`,
  `\n`,
  `Cookies identify the member's device but do not personally identify the member. Members have the choice to manage cookie installation. They can accept all cookies, be notified when a cookie is set, or refuse all cookies by setting their web browser accordingly. `,
  `　　`,
  `\n`,
  `Example settings method (for Internet Explorer): Tools at the top of the web browser > Internet Options > Privacy > Setting the level of privacy handling. `,
  `　　`,
  `\n`,
  `However, refusing cookie installation may result in difficulties in providing some services that require login.`,
  `　　`,
  `\n`,
  `10. Technical/Administrative Measures for Personal Information Protection`,
  `　　`,
  `\n`,
  `The company strives to prevent personal information from being lost, stolen, leaked, altered, or damaged and has taken the following technical/administrative measures to ensure security. However, the company is not responsible for any force majeure incidents like hacking attacks or new virus infiltrations that are not preventable or solvable with current technology.`,
  `　　`,
  `\n`,
  `Password Encryption: Passwords for member IDs are encrypted for storage and management, known only to the individual, and personal information verification and changes are also possible only by the individual.`,
  `　　`,
  `\n`,
  `Measures Against Hacking: The company does its best to prevent leakage or damage of members' personal information due to hacking or computer viruses. Antivirus software is regularly updated, and as soon as a vaccine for a newly emerged virus is available, it is applied to prevent information breach. Additionally, to control unauthorized access from the outside, an intrusion prevention system is installed/operated, and server access is limited to the minimum necessary personnel according to their job roles.`,
  `　　`,
  `\n`,
  `Minimization and Training of Handling Staff: Regular training is provided to the company's personal information handling staff to always emphasize compliance with the personal information handling policy.`,
  `　　`,
  `\n`,
  `11. Personal Information Manager and Contact Information`,
  `　　`,
  `\n`,
  `The company has designated and is managing a personal information management officer to address all personal information protection-related grievances that members may have while using the service, and responds promptly and sincerely to inquiries related to personal information.`,
  `　　`,
  `\n`,
  `Personal Information Management Officer: Lee Inwon (e-mail: inwon.lees@kt.com)`,
  `　　`,
  `\n`,
  `Personal Information Management Staff: Han Wooje (e-mail: wj.han@kt.com)`,
  `　　`,
  `\n`,
  `Contact: www.kt-sports.co.kr`,
  `　　`,
  `\n`,
  `For reports or consultations on personal information infringement, please contact the following institutions:`,
  `　　`,
  `\n`,
  `Personal Information Infringement Report Center (www.118.or.kr / dial 118 without a code)`,
  `　　`,
  `\n`,
  `Supreme Prosecutors' Office Cybercrime Investigation Department (www.spo.go.kr / 02-3480-3571)`,
  `　　`,
  `\n`,
  `National Police Agency Cyber Terror Response Center (www.ctrc.go.kr / dial 182 without a code)`,
  `　　`,
  `\n`,
  `12. Separate Storage or Disposal of Personal Information of Members Who Have Not Used the Website or Wizzap Services for Over a Year`,
  `　　`,
  `\n`,
  `To protect customer personal information, the company notifies members who have not logged in to the website or Wizzap for 11 months via text message about service termination, and if there is no login within 30 days from the notice, the service is terminated and the member's personal information is destroyed.`,
  `　　`,
  `\n`,
  `13. Notification Obligation`,
  `　　`,
  `\n`,
  `Any addition, deletion, or modification to the contents of this personal information handling policy will be notified through the website's "Notices" at least 7 days before the changes take effect. For significant changes affecting user rights, such as collection and use of personal information or provision to third parties, notification will be made at least 30 days in advance.`,
  `　　`,
  `\n`,
  `Personal Information Handling Policy Revision History`,
  `　　`,
  `\n`,
  `- Notice Date of Personal Information Policy Change: January 12, 2023`,
  `　　`,
  `\n`,
  `- Effective Date: January 19, 2023`,
  `　　`,
  `\n`,
  `- Previous Personal Information Handling Policy (November 30, 2021)`,
];
