import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import FuturesTeamRanking from '../../components/game/FuturesTeamRanking'
import { futuresTeamRankingRequest, periodMinorTeamRankRequest } from '../../modules/game/ranking'

const FuturesLeagueTeamRankingContainer = ({ match }) => {

  const teamRankingList = useSelector(state => state.wizData.data && state.wizData.data['TEAM_RANKING_LIST'])
  // const teamRankingListError = useSelector(state => state.wizData.error && state.wizData.error['TEAM_RANKING_LIST'])

  const periodTeamRankList = useSelector(state => state.wizData.data && state.wizData.data['PERIOD_TEAM_RANK'])
  // const periodTeamRankListError = useSelector(state => state.wizData.error && state.wizData.error['PERIOD_TEAM_RANK'])

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(futuresTeamRankingRequest({}, 'TEAM_RANKING_LIST'))
    dispatch(periodMinorTeamRankRequest({}, 'PERIOD_TEAM_RANK'))
  }, [])

  return (
    <FuturesTeamRanking match={match} teamRankingList={teamRankingList} periodTeamRankList={periodTeamRankList} />
  )
}

export default FuturesLeagueTeamRankingContainer
