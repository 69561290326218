import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { yearListRequest, teamCrowdRankingRequest } from '../../modules/game/ranking'
import CrowdRanking from '../../components/game/CrowdRanking'

const RegularLeagueCrowdRankingContainer = ({ match }) => {

  const teamCrowdList = useSelector(state => state.wizData.data && state.wizData.data['TEAM_CROWD_RANKING_LIST'])
  // const teamCrowdListError = useSelector(state => state.wizData.error && state.wizData.error['TEAM_CROWD_RANKING_LIST'])

  const yearList = useSelector(state => state.wizData.data && state.wizData.data['YEAR_LIST'])

  console.log(match)

  const [year, setYear] = useState(null)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(yearListRequest({}, 'YEAR_LIST'))
  }, [dispatch])

  useEffect(() => {
    dispatch(teamCrowdRankingRequest({ 'gameSearch.gyear': year }, 'TEAM_CROWD_RANKING_LIST'))
  }, [year])

  if (yearList && year == null) {
    setYear(yearList.gameYearlist[0].gyear)
  }

  return (
    <CrowdRanking match={match} crowdList={teamCrowdList} initYear={year} onChangeYear={setYear} yearList={yearList && yearList.gameYearlist} />
  )
}

export default RegularLeagueCrowdRankingContainer
