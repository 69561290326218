import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import BatterRanking from '../../components/game/BatterRanking'
import { futuresBatterRankingRequest } from '../../modules/game/ranking'

const FuturesLeagueBatterRankingContainer = ({ match, year }) => {

  const hraTop3InKt = useSelector(state => state.wizData.data && state.wizData.data['HRA_TOP3_IN_KT'])
  // const hraTop3InKtError = useSelector(state => state.wizData.error && state.wizData.error['HRA_TOP3_IN_KT'])

  const hrTop3InKt = useSelector(state => state.wizData.data && state.wizData.data['HR_TOP3_IN_KT'])
  // const hrTop3InKtError = useSelector(state => state.wizData.error && state.wizData.error['HR_TOP3_IN_KT'])

  const hraTop5 = useSelector(state => state.wizData.data && state.wizData.data['HRA_TOP5'])
  // const hraTop5Error = useSelector(state => state.wizData.error && state.wizData.error['HRA_TOP5'])

  console.log(hraTop3InKt)

  const dispatch = useDispatch()

  useEffect(() => {
    if (year) {
      dispatch(futuresBatterRankingRequest({ 'gameSearch.gyear': year, 'gameSearch.paFlag': 'Y', 'gameSearch.teamCode': 'KT', 'gameSearch.top': 3, 'gameSearch.sortKey': 'HRA' }, 'HRA_TOP3_IN_KT'))
      dispatch(futuresBatterRankingRequest({ 'gameSearch.gyear': year, 'gameSearch.teamCode': 'KT', 'gameSearch.top': 3, 'gameSearch.sortKey': 'HR' }, 'HR_TOP3_IN_KT'))
      dispatch(futuresBatterRankingRequest({ 'gameSearch.gyear': year, 'gameSearch.paFlag': 'Y', 'gameSearch.top': 5, 'gameSearch.sortKey': 'HRA' }, 'HRA_TOP5'))
    }
  }, [year])


  return (
    <BatterRanking hraTop3InKt={hraTop3InKt} hrTop3InKt={hrTop3InKt} hraTop5={hraTop5} />
  )
}

export default FuturesLeagueBatterRankingContainer
