import React from 'react';
// import GamePhoto from '../../components/media/photo/GamePhoto';
import { useLocation, useRouteMatch, useHistory } from 'react-router-dom';
import ContentTemplate from '../../components/common/ContentTemplate';
import SubContentTemplate from '../../components/common/SubContentTemplate';
import PageHeaderContainer from '../../containers/common/PageHeaderContainer';
import PageTitleTemplate from '../../components/common/PageTitleTemplate';
import Breadcrumb from '../../components/common/Breadcrumb';
import ArticleSearch from '../../components/media/board/ArticleSearch';
import ArticlePhotoDetail from '../../components/media/board/ArticlePhotoDetail';
import PageTemplate from '../../components/common/PageTemplate';
import { WIZ_GALLERY_GAME_PAGE, WIZ_GALLERY_TRAIN_PAGE, WIZ_GALLERY_EVENT_PAGE } from '../../constants/page';
import WizBoard from '../../components/media/board/WizBoard';
import qs from 'query-string';

const WizPhotoPage = () => {
  const matchCat = useRouteMatch('/media/:photos/:cat');
  const matchCatSeq = useRouteMatch('/media/:photos/:cat/:seq');
  const query = qs.parse(useLocation().search);
  const history = useHistory();

  const tabs = [
    {
      path: '/media/photos/1',
      title: '경기',
      type: 'gallery',
      page: WIZ_GALLERY_GAME_PAGE
    },
    {
      path: '/media/photos/2',
      title: '훈련',
      type: 'gallery',
      page: WIZ_GALLERY_TRAIN_PAGE
    },
    {
      path: '/media/photos/3',
      title: '행사',
      type: 'gallery',
      page: WIZ_GALLERY_EVENT_PAGE
    }
  ];

  const goTab = i => {
    history.push(tabs[i].path);
  };


  const tab = tabs[parseInt(matchCat.params.cat || 1) - 1];
  tab.isActive = true;

  console.log('WizPhotoPage', { tab, matchCat, matchCatSeq });

  return (
    <PageTemplate className='media'>
      <PageHeaderContainer
        title='wiz 포토'
        subtitle='kt wiz의 생생한 역사적 순간을 담았습니다.'
        image={require('../../assets/img/media/sub_tit_media.png')}
        tabs={tabs}
        onClickTab={goTab}
      />
      <ContentTemplate className={matchCatSeq ? 'media_wiz_photo_detail' : 'media_wiz_photo'}>
        <PageTitleTemplate>
          <Breadcrumb page={tab.page} />
          <ArticleSearch
            options={{ title: '제목', content: '내용', player: '선수' }}
            option={query['search.sort']}
            text={query['search.searchWord']}
            showPeriodSearch={true}
            startDate={query['search.startDate']}
            endDate={query['search.endDate']}
          />
        </PageTitleTemplate>
        <SubContentTemplate>
          {matchCatSeq ? (
            <ArticlePhotoDetail seq={matchCatSeq.params.seq} query={query} />
          ) : (
              <WizBoard board={tab.type} query={query} count={12} listType='grid' cat={matchCat.params.cat} classForList='two_block' />
            )}
        </SubContentTemplate>
      </ContentTemplate>
    </PageTemplate>
  );
};

export default WizPhotoPage;
