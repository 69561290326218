import React from 'react'
import SongMobileListItem from './SongMobileListItem'

const SongMobileList = ({ list, onClickIsLyricsPopup, onClickIsSoundPopup }) => {
  return (
    <>
      <div className="song_list_by">
        <ul>
          <SongMobileListItem list={list} onClickIsLyricsPopup={onClickIsLyricsPopup} onClickIsSoundPopup={onClickIsSoundPopup} />
        </ul>
      </div>
    </>
  )
}

export default SongMobileList
