import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Link from "../../components/common/Link";
import Familysite from "./Familysite";
import SiteMap from "./SiteMap";
import Privacy from "./Privacy";
import Use from "./Use";
import Email from "./Email";
import Ethics from "./Ethics";

const Footer = () => {
  const [visible, setVisible] = useState(false);
  const [active, setActive] = useState("familysite_bg clearfix");
  const [footerBgActive, setFooterBgActive] = useState("footer_overlay");

  const location = useLocation();
  const [isOpenSelectBox, setIsOpenSelectBox] = useState(false);

  const [showContent, setShowContent] = useState({
    privacy: false,
    use: false,
    email: false,
    siteMap: false,
    ethics: false,
  });

  const onOpenContent = (e) => {
    setShowContent(false);
    setShowContent({
      [e]: true,
    });
  };

  useEffect(() => {
    setShowContent(false);
  }, [location]);

  const handleFamilySite = () => {
    setVisible(!visible);
    handleFooterBg();

    if (visible) {
      setActive("familysite_bg clearfix");
    } else {
      setActive("familysite_bg clearfix active");
    }
  };

  // {/* 검정 백그라운드 토글 */}
  const handleFooterBg = () => {
    if (visible) {
      setFooterBgActive("footer_overlay");
    } else {
      setFooterBgActive("footer_overlay active");
    }
  };

  const handleScrollTop = (e) => {
    // window.scrollTo(0, 0);
    window.scroll({
      behavior: "smooth",
      left: 0,
      top: 0,
    });
  };

  return (
    <footer>
      <div className="mb">
        <div className="con">
          <div className="ft-logo">
            <div className="img">
              <img src="/v2/imgs/img-logo-kr.svg" alt="케이티 위즈" />
            </div>
          </div>
          <div className="terms">
            <ul>
              <li>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    onOpenContent("privacy");
                  }}
                >
                  개인정보 처리방침
                </a>
              </li>
              <li>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    onOpenContent("use");
                  }}
                >
                  이용약관
                </a>
              </li>
            </ul>
          </div>
          <div className="ft-sns">
            <ul>
              <li>
                <a href="//www.instagram.com/ktwiz.pr/" target="_blank" rel="noopener noreferrer">
                  <img src="/v2/imgs/ico-instagram@2x.png" alt="인스타그램" />
                </a>
              </li>
              <li>
                <a href="//www.facebook.com/ktwiz/" target="_blank" rel="noopener noreferrer">
                  <img src="/v2/imgs/ico-facebook@2x.png" alt="페이스북" />
                </a>
              </li>
              <li>
                <a href="//www.youtube.com/c/ktwiztv" target="_blank" rel="noopener noreferrer">
                  <img src="/v2/imgs/ico-youtube@2x.png" alt="유투브" />
                </a>
              </li>
              <li>
                <a href="//tv.naver.com/ktwiz" target="_blank" rel="noopener noreferrer">
                  <img src="/v2/imgs/ico-naver@2x.png" alt="네이버" />
                </a>
              </li>
            </ul>
          </div>
          <div className="ft-copyright">Copyright 2022 kt sports. All rights reserved.</div>
        </div>
      </div>
      <div className="pc">
        <div className="con">
          <div className="col ft-logo">
            <div className="img">
              <img src="/v2/imgs/img-logo-kr.svg" alt="케이티 위즈" />
            </div>
          </div>
          <div className="col ft-info">
            <div className="row">
              <div className="ft-terms-list">
                <ul>
                  <li>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        onOpenContent("privacy");
                      }}
                    >
                      개인정보 처리방침
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        onOpenContent("use");
                      }}
                    >
                      이용약관
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        onOpenContent("email");
                      }}
                    >
                      이메일무단수집거부
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        onOpenContent("siteMap");
                      }}
                    >
                      Sitemap
                    </a>
                  </li>
                  {/* <li>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        onOpenContent("ethics");
                      }}
                    >
                      윤리경영
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="ft-company-info tel">
                <dl>
                  <dt>대표번호</dt>
                  <dd>
                    <strong>1899-5916</strong>
                    (운영시간 : 평일 10:00 ~ 18:00, 주말 10:00 ~ 경기시작 전까지, 월요일 및 주말 미경기 시 미운영)
                  </dd>
                </dl>
              </div>
            </div>
            <div className="row">
              <div className="ft-company-info">
                <dl>
                  <dt>주소</dt>
                  <dd>
                    <address>경기도 수원시 장안구 경수대로 (조원동) 수원 케이티 위즈파크</address>
                  </dd>
                </dl>
              </div>
            </div>
            <div className="row">
              <div className="ft-copyright">Copyright 2022 kt sports. All rights reserved.</div>
            </div>
          </div>
          <div className="col ft-etc">
            <div className="row">
              <label className={`fm-select ${isOpenSelectBox ? "on" : ""}`}>
                <div className="name" onClick={(_) => setIsOpenSelectBox(!isOpenSelectBox)}>
                  <div className="text ellipsis">kt 그룹사 및 관련사이트</div>
                  <img className="fm-arrow fm-arrow-down" src="/v2/imgs/ico-24-open-n@2x.png" alt="open" />
                  <img className="fm-arrow fm-arrow-up" src="/v2/imgs/ico-24-open-n@2x.png" alt="close" />
                </div>
                <div className="options">
                  <ul>
                    <li>
                      <Link to="//www.ktestate.com/" target="_blank">
                        KT estate
                      </Link>
                    </li>
                    <li>
                      <Link to="//www.kttelecop.co.kr/" target="_blank">
                        KT telecop
                      </Link>
                    </li>
                    <li>
                      <Link to="//www.ktsat.net/mainPage.do" target="_blank">
                        KT sat
                      </Link>
                    </li>
                    <li>
                      <Link to="//www.ktengineering.co.kr/" target="_blank">
                        KT engineering
                      </Link>
                    </li>
                    <li>
                      <Link to="//www.ktis.co.kr/v2/" target="_blank">
                        KT is
                      </Link>
                    </li>
                    <li>
                      <Link to="//www.ktcs.co.kr/" target="_blank">
                        KT cs
                      </Link>
                    </li>
                    <li>
                      <Link to="//www.ktmns.com/" target="_blank">
                        KT m&amp;s
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="//www.ktp.co.kr/" target="_blank">
                        KT powertel
                      </Link>
                    </li> */}
                    <li>
                      <Link to="//www.ktlinkus.com/" target="_blank">
                        KT linkus
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="//www.ktsubmarine.co.kr/kor/main.asp" target="_blank">
                        KT submarine
                      </Link>
                    </li> */}
                    <li>
                      <Link to="//www.ktds.com/index.jsp" target="_blank">
                        KT ds
                      </Link>
                    </li>
                    <li>
                      <Link to="//www.ktnexr.com/" target="_blank">
                        KT NexR
                      </Link>
                    </li>
                    <li>
                      <Link to="//www.initech.com/html/index.html" target="_blank">
                        INITECH
                      </Link>
                    </li>
                    <li>
                      <Link to="//www.ktmmobile.com/main.do" target="_blank">
                        kt M mobile
                      </Link>
                    </li>
                    <li>
                      <Link to="//www.kthcorp.com/index" target="_blank">
                        kt alpha
                      </Link>
                    </li>
                    <li>
                      <Link to="//www.geniemusic.co.kr/" target="_blank">
                        지니뮤직
                      </Link>
                    </li>
                    <li>
                      <Link to="//www.nasmedia.co.kr/" target="_blank">
                        nasmedia
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="//www.mhows.com/" target="_blank">
                        KT mhows
                      </Link>
                    </li> */}
                    <li>
                      <Link to="//www.kt.com/" target="_blank">
                        KT 닷컴
                      </Link>
                    </li>
                    <li>
                      <Link to="//shop.kt.com/main.do" target="_blank">
                        KT Shop
                      </Link>
                    </li>
                    <li>
                      <Link to="//www.ktcommerce.co.kr/login.jsp" target="_blank">
                        kt commerce
                      </Link>
                    </li>
                    <li>
                      <Link to="//www.ktdw.co.kr/" target="_blank">
                        KT 동우회
                      </Link>
                    </li>
                    <li>
                      <Link to="//recruit.kt.com/" target="_blank">
                        인재채용
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="//www.digieco.co.kr/KTFront/index.action" target="_blank">
                        경제경영연구소
                      </Link>
                    </li> */}
                    <li>
                      <Link to="//partner.kt.com/" target="_blank">
                        KT 파트너포탈
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="//www.ktlifeplan.com:1900/main.do" target="_blank">
                        KT Life Plan
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </label>
            </div>
            <div className="row">
              <div className="ft-sns">
                <ul>
                  <li>
                    <a href="https://www.instagram.com/ktwiz.pr" target="_blank" rel="noopener noreferrer">
                      <img src="/v2/imgs/ico-instagram@2x.png" alt="인스타그램" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.facebook.com/ktwiz" target="_blank" rel="noopener noreferrer">
                      <img src="/v2/imgs/ico-facebook@2x.png" alt="페이스북" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.youtube.com/c/ktwiztv" target="_blank" rel="noopener noreferrer">
                      <img src="/v2/imgs/ico-youtube@2x.png" alt="유투브" />
                    </a>
                  </li>
                  <li>
                    <a href="https://tv.naver.com/ktwiz" target="_blank" rel="noopener noreferrer">
                      <img src="/v2/imgs/ico-naver@2x.png" alt="네이버" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showContent.privacy && <Privacy onClose={setShowContent} />}
      {showContent.use && <Use onClose={setShowContent} />}
      {showContent.siteMap && <SiteMap onClose={setShowContent} />}
      {showContent.email && <Email onClose={setShowContent} />}
      {showContent.ethics && <Ethics onClose={setShowContent} />}
    </footer>
    // <footer className='footer'>
    //   <div className='container position_maker'>
    //     <div className='util_btn_wrap pc_invisible clearfix'>
    //       <div className='util_btn'>
    //         <Link to='/login'>로그인</Link>
    //       </div>
    //       <div className='util_btn'>
    //         <Link to='#' onClick={handleScrollTop}>TOP</Link>
    //       </div>
    //     </div>

    //     <div className='sns_wrap clearfix'>
    //       <ul>
    //         <li className='sns wizzap pc_invisible'>
    //           <Link to='위잽앱 연결'>
    //             <img src={require('../../assets/img/common/wizzap.png')} alt='wizzap 앱' />
    //           </Link>
    //         </li>
    //         <li className='sns instagram'>
    //           <Link to='//www.instagram.com/ktwiz.pr/' target='_blank'>
    //             <img src={require('../../assets/img/common/instagram.png')} alt='instagram' />
    //           </Link>
    //         </li>
    //         <li className='sns youtube'>
    //           <Link to='//www.youtube.com/c/ktwiztv' target='_blank'>
    //             <img src={require('../../assets/img/common/youtube.png')} alt='youtube' />
    //           </Link>
    //         </li>
    //         <li className='sns facebook'>
    //           <Link to='//www.facebook.com/ktwiz/' target='_blank'>
    //             <img src={require('../../assets/img/common/facebook.png')} alt='facebook' />
    //           </Link>
    //         </li>
    //         <li className='sns navertv'>
    //           <Link to='//tv.naver.com/ktwiz' target='_blank'>
    //             <img src={require('../../assets/img/common/navertv.png')} alt='naver tv' />
    //           </Link>
    //         </li>
    //       </ul>
    //     </div>

    //     <div className='util_bt_wrap clearfix'>
    //       <div className='util_bt float_l'>
    //         <ul>
    //           <li>
    //             <Link onClick={() => { onOpenContent('privacy') }}>개인정보처리방침</Link>
    //           </li>
    //           <li>
    //             <Link onClick={() => { onOpenContent('use') }}>이용약관</Link>
    //           </li>
    //           <li className='m_invisible'>
    //             <Link onClick={() => { onOpenContent('email') }}>이메일무단수집거부</Link>
    //           </li>
    //           <li className='m_invisible' >
    //             <Link onClick={() => { onOpenContent('siteMap') }}>site map</Link>
    //           </li>
    //           <li className='m_invisible'>
    //             <Link onClick={() => { onOpenContent('ethics') }}>윤리경영</Link>
    //           </li>
    //         </ul>
    //       </div>
    //       {showContent.privacy && <Privacy onClose={setShowContent} />}
    //       {showContent.use && <Use onClose={setShowContent} />}
    //       {showContent.siteMap && <SiteMap onClose={setShowContent} />}
    //       {showContent.email && <Email onClose={setShowContent} />}
    //       {showContent.ethics && <Ethics onClose={setShowContent} />}
    //       <Familysite active={active} handleFamilySite={handleFamilySite} />
    //     </div>

    //     <div className='address'>
    //       <address className='m_invisible'>주소 : 경기도 수원시 장안구 경수대로 893(조원동) 수원 케이티 위즈 파크</address>
    //       <address className='m_invisible'>대표번호 : 1899-5916(운영시간 : 평일 - 10:00 ~ 18:00, 주말 - 10:00 ~ 경기 시작 전까지, 월요일 및 주말 미 경기 시 미운영)</address>
    //       <p className='copyright position_maker'>
    //         <span>Copyright 2020 kt sports.</span>
    //         <span>All rights reserved.</span>
    //       </p>
    //     </div>
    //   </div>
    //   <div className={footerBgActive}></div>
    // </footer>
  );
};

export default Footer;
