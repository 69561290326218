import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Login from "../../components/auth/Login";
import { loginRequest, initCertStatus } from "../../modules/auth/login";
import qs from "query-string";

const LoginContainer = () => {
  const history = useHistory();
  const location = useLocation();
  const { returnUrl, isOutLink } = qs.parse(location.search);

  const login = useSelector((state) => state.login);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(initCertStatus());
    };
  }, []);

  if (login.isLoggedIn && !login.certStatus.active) {
    history.push("/");
  }

  const onLoginRequest = (user, returnObj = { returnUrl, isOutLink }) =>
    dispatch(loginRequest(user, returnObj));

  return (
    <Login
      login={login}
      onLoginRequest={onLoginRequest}
      returnObj={{ returnUrl, isOutLink }}
    />
  );
};

export default LoginContainer;
