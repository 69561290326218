import React from 'react'
import * as Enum from '../../../constants/enum'

const FindPwMobile = ({ onCertRequest, onFindPwRequest, userCert, onChangeUserCert, isMobileDisableCertButton, isMobileDisableFindButton }) => {

  return (
    <>
      {/*입력 양식(휴대폰)*/}
      <div className="group2 pw_mobile_form">
        <div className="group group2_1">
          <form action="" method="" name="find_pw_mobile">
            <div className="input_field">
              {/*아이디 입력*/}
              <div className="input_row id">
                <div className="input_tit">
                  <label htmlFor="id">아이디</label>
                </div>{/*//.input_tit*/}
                <div className="input_content">
                  <input type="text" id="id" name="id" className="cell" placeholder="아이디 입력" onChange={e => onChangeUserCert('simpleId', e)} />
                </div>{/*//.input_content*/}
              </div>{/*//.input_row.id*/}

              {/*휴대폰 번호*/}
              <div className="input_row mobile_num clearfix">
                <div className="input_tit">
                  <label htmlFor="mobile_num">휴대폰 번호</label>
                </div>{/*//.input_tit*/}
                <div className="input_content clearfix">
                  <div className="mobile_num1_cell float_l"> {/*modify 201915 태그 추가*/}
                    <select id="mobile_num1" name="mobile_num1" className="cell" onChange={e => onChangeUserCert('mobpNo1', e)}>
                      {
                        Enum.MOBILE_FRONT_NUMS.map((item, index) => (
                          <option key={index} value={item}>{item}</option>
                        ))
                      }
                    </select>
                  </div>
                  {/* <input type="text" id="mobile_num1" name="mobile_num1" className="cell cell1 pc_invisible float_l" maxLength="3" /> */}
                  {/* <span>-</span> */}
                  <div className="mobile_num2_cell float_l"> {/*modify 201915 태그 추가*/}
                    <input type="text" id="mobile_num2" name="mobile_num2" className="cell" maxLength="4" onChange={e => onChangeUserCert('mobpNo2', e)} />
                  </div>
                  {/* <span>-</span> */}
                  <div className="mobile_num3_cell float_l"> {/*modify 201915 태그 추가*/}
                    <input type="text" id="mobile_num3" name="mobile_num3" className="cell" maxLength="4" onChange={e => onChangeUserCert('mobpNo3', e)} />
                  </div>
                  <div className="float_l">
                    {/*modify 201915 태그 추가*/}
                    <button type="button" className={isMobileDisableCertButton ? "active" : ""} disabled={!isMobileDisableCertButton} onClick={() => onCertRequest(userCert)}>인증요청</button>
                    {/*클래스 active 붙었을 때 버튼 색 바뀝니다*/}
                  </div>
                </div>{/*//.input_content*/}
              </div>{/*//.input_row.mobile_num*/}

              {/*인증번호 확인*/}
              <div className="input_row num_confirm clearfix">
                <div className="input_tit">
                  <label htmlFor="num_confirm">인증번호</label>
                </div>
                <div className="input_content">
                  <input type="text" id="num_confirm" name="num_confirm" className="cell" placeholder="인증번호 6자 입력" maxLength="6" onChange={e => onChangeUserCert('certNo', e)} />
                </div>
              </div>{/*//.input_row.num_confirm*/}
              <div className="warning">
                <span>* 휴대폰 번호 입력 후 ‘인증요청’ 버튼을 선택해주세요.</span>
              </div>{/*//.warning*/}
            </div>{/*//.input_field*/}
            <div className="btn">
              <button type="button" className={isMobileDisableFindButton ? "active" : ""} disabled={!isMobileDisableFindButton} onClick={() => onFindPwRequest(userCert)}>비밀번호 찾기</button> {/*클래스 active 붙었을 때 버튼 색 바뀝니다*/}
            </div>
          </form>
        </div>{/*//.group2_1*/}

        {/*하단 설명*/}
        <div className="group group2_2 pw_mobile_desc">
          <div className="txt_wrap">
            <strong>[휴대폰 번호로 비밀번호 찾기 안내]</strong>
            <p>kt wiz 회원가입 시 입력한 휴대폰 번호로 비밀번호 찾기가 가능합니다.</p>
          </div>
        </div>{/*//.group2_2*/}
      </div>{/*//.group2.pw_mobile_form*/}
    </>
  )
}

export default FindPwMobile
