import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { createDetailRequest } from '../../../modules/player/player'
import { useLocation } from 'react-router-dom'
import qs from 'query-string'
import PitcherDetailSecondPc from './PitcherDetailSecondPc'
import PitcherDetailSecondMobile from './PitcherDetailSecondMobile'

const PitcherDetailSecond = ({ type, contentType, selectedGyear }) => {

  const params = qs.parse(useLocation().search)
  const data = useSelector(state => state.wizData.data && state.wizData.data[type])
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(createDetailRequest({ 'gameSearch.pcode': params.pcode, 'gameSearch.targetYear': selectedGyear }, type))
  }, [params.pcode, selectedGyear, type])

  useEffect(() => {
    setIsFuturesLeague(false) // 다른 탭 이동시 정규리그 기록 보기로 전환
  }, [contentType])

  const [isFuturesLeague, setIsFuturesLeague] = useState(false)
  const onClickIsFuturesLeague = () => {
    setIsFuturesLeague(!isFuturesLeague)
  }

  return (
    <>
      <div className="player_record_detail">
        <section className="player_record_list">

          {/* PC 버전 */}
          <PitcherDetailSecondPc contentType={contentType} data={data} isFuturesLeague={isFuturesLeague} onClickIsFuturesLeague={onClickIsFuturesLeague} selectedGyear={selectedGyear} />

          {/* MOBILE 버전 */}
          <PitcherDetailSecondMobile data={data} isFuturesLeague={isFuturesLeague} selectedGyear={selectedGyear} />

        </section>
      </div>
    </>
  )

}

export default PitcherDetailSecond
