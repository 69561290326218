export const FIND_ID_REQUEST = 'findId/FIND_ID_REQUEST'
export const FIND_ID_SUCCESS = 'findId/FIND_ID_SUCCESS'
export const FIND_ID_FAIL = 'findId/FIND_ID_FAIL'

export const findIdRequest = (userCert) => ({ type: FIND_ID_REQUEST, userCert })
export const findIdSuccess = () => ({ type: FIND_ID_SUCCESS })
export const findIdFail = () => ({ type: FIND_ID_FAIL })

const initialState = {
  isRequesting: false,
  isReqeusted: false
}

const findId = (state = initialState, action) => {

  switch (action.type) {

    case FIND_ID_REQUEST:
      return {
        isRequesting: true,
        isReqeusted: false
      }
    case FIND_ID_SUCCESS:
      return {
        isRequesting: false,
        isReqeusted: true
      }
    case FIND_ID_FAIL:
      return {
        isRequesting: false,
        isReqeusted: false
      }
    default:
      return state

  }
}

export default findId
