import React from 'react'
import Link from './Link'

const PageForwardTab = ({ forwardTabs, currentPath }) => {
  console.log(currentPath)
  forwardTabs.map((v, i) => {
    if (v.path === currentPath) { forwardTabs[i].isActive = true }
    else { forwardTabs[i].isActive = false }
  })

  return (
    <div className={forwardTabs.length < 5 ? 'lnb_wrap' : 'lnb_wrap per20'}>
      <div className='container'>
        <ul className='clearfix'>
          {forwardTabs.map((tab, i) => (
            <li key={`${i}`} className={'dep3' + (tab.isActive ? ' active' : '')}>
              <Link to={tab.path}>
                {tab.title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default PageForwardTab
