
export const checkPassword = passwd => {
  const num = passwd.search(/[0-9]/g);
  const eng = passwd.search(/[a-z]/ig);
  const spe = passwd.search(/[`~!@@#$%^&*|\\\'\";:\/?]/gi);

  if (passwd.length < 8 || passwd.length > 30) {
    alert("비밀번호는 8자리 ~ 30자리 이내로 입력해주세요.");
    return false;
  }

  if (passwd.search(/\s/) != -1) {
    alert("비밀번호는 공백업이 입력해주세요.");
    return false;
  }
  if (num < 0 || eng < 0 || spe < 0) {
    alert("비밀번호는 영문,숫자, 특수문자를 혼합하여 입력해주세요.");
    return false;
  }
  return true
}
