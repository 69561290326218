import React, { useState } from 'react'
import Link from '../common/Link'

const PitcherRankingTable = ({ pitcherList, allPitcherList, northPitcherList, southPitcherList, tab, onChangeSort, onChangeYear, onChangePlayerName, year, sortKey, yearList }) => {

  const [selectYear, setSelectYear] = useState(null)

  const [showYearComboBox, setShowYearComboBox] = useState(false)

  if (yearList && selectYear == null) {
    setSelectYear(yearList[0].gyear)
  }

  const PcKtPitcher = ({ pitcher }) => {
    return (
      <tr className={pitcher.inFlag === '1' ? 'selected' : ''}>
        <td><Link>{pitcher.playerName}</Link></td>
        <td>{pitcher.teamName}</td>
        <td>{pitcher.era}</td>
        <td>{pitcher.gamenum}</td>
        <td>{pitcher.w}</td>
        <td>{pitcher.l}</td>
        <td>{pitcher.sv}</td>
        <td>{pitcher.hold}</td>
        <td>{pitcher.wra}</td>
        <td>{pitcher.inn}</td>
        <td>{pitcher.hit}</td>
        <td>{pitcher.hr}</td>
        <td>{pitcher.bb}</td>
        <td>{pitcher.hp}</td>
        <td>{pitcher.kk}</td>
        <td>{pitcher.r}</td>
        <td>{pitcher.er}</td>
      </tr>
    )
  }

  const PcAllPitcher = ({ pitcher, index }) => {
    return (
      <tr className={pitcher.teamName === 'KT' ? 'selected' : ''}>
        <td>{index + 1}</td>
        <td>{pitcher.playerName}</td>
        <td>{pitcher.teamName}</td>
        <td>{pitcher.era}</td>
        <td>{pitcher.gamenum}</td>
        <td>{pitcher.w}</td>
        <td>{pitcher.l}</td>
        <td>{pitcher.sv}</td>
        <td>{pitcher.hold}</td>
        <td>{pitcher.wra}</td>
        <td>{pitcher.inn}</td>
        <td>{pitcher.hit}</td>
        <td>{pitcher.hr}</td>
        <td>{pitcher.bb}</td>
        <td>{pitcher.hp}</td>
        <td>{pitcher.kk}</td>
        <td>{pitcher.r}</td>
        <td>{pitcher.er}</td>
      </tr>
    )
  }

  const MobileKtPitcher = ({ pitcher, index }) => {
    return (
      <tr className={pitcher.inFlag === '1' ? 'selected' : ''}>
        <td className="hide">{pitcher.playerName}</td>
        <td>{pitcher.era}</td>
        <td>{pitcher.gamenum}</td>
        <td>{pitcher.w}</td>
        <td>{pitcher.l}</td>
        <td>{pitcher.sv}</td>
        <td>{pitcher.hold}</td>
        <td>{pitcher.wra}</td>
        <td>{pitcher.inn}</td>
        <td>{pitcher.hit}</td>
        <td>{pitcher.hr}</td>
        <td>{pitcher.bb}</td>
        <td>{pitcher.hp}</td>
        <td>{pitcher.kk}</td>
        <td>{pitcher.r}</td>
        <td>{pitcher.er}</td>
      </tr>
    )
  }

  return (
    <div className="game_record_detail">
      <div className="container">
        <div className="notice"><p>※각 항목을 클릭하시면 순위를 보실 수 있습니다.</p></div>
        <section className="rank_record_list pitcher_record">

          <article className="rank_record_content ktwiz_pitcher pc_mode" style={{ display: tab === 'regular_league' ? 'block' : 'none' }}>
            <div className="rank_record">
              <table>
                <caption>kt wiz 투수</caption>
                <colgroup>
                  <col width="90px" />
                  <col width="61px" />
                  <col width="*" />
                  <col width="61px" />
                  <col width="61px" />
                  <col width="61px" />
                  <col width="61px" />
                  <col width="61px" />
                  <col width="63px" />
                  <col width="63px" />
                  <col width="61px" />
                  <col width="61px" />
                  <col width="61px" />
                  <col width="61px" />
                  <col width="61px" />
                  <col width="61px" />
                  <col width="61px" />
                </colgroup>
                <thead>
                  <tr>
                    <th>선수명</th>
                    <th>팀명</th>
                    <th className={sortKey === 'ERA' ? 'selected' : ''}><Link onClick={() => onChangeSort('ERA')}>평균자책점<span className="down"></span></Link></th>
                    <th className={sortKey === 'GAMENUM' ? 'selected' : ''}><Link onClick={() => onChangeSort('GAMENUM')}>경기<span className="down"></span></Link></th>
                    <th className={sortKey === 'W' ? 'selected' : ''}> <Link onClick={() => onChangeSort('W')}>승<span className="down"></span></Link></th>
                    <th className={sortKey === 'L' ? 'selected' : ''}><Link onClick={() => onChangeSort('L')}>패<span className="down"></span></Link></th>
                    <th className={sortKey === 'SV' ? 'selected' : ''}><Link onClick={() => onChangeSort('SV')}>세<span className="down"></span></Link></th>
                    <th className={sortKey === 'HOLD' ? 'selected' : ''}><Link onClick={() => onChangeSort('HOLD')}>홀<span className="down"></span></Link></th>
                    <th className={sortKey === 'WRA' ? 'selected' : ''}><Link onClick={() => onChangeSort('WRA')}>승률<span className="down"></span></Link></th>
                    <th className={sortKey === 'INN2' ? 'selected' : ''}><Link onClick={() => onChangeSort('INN2')}>이닝<span className="down"></span></Link></th>
                    <th className={sortKey === 'HIT' ? 'selected' : ''}><Link onClick={() => onChangeSort('HIT')}>피안타<span className="down"></span></Link></th>
                    <th className={sortKey === 'HR' ? 'selected' : ''}><Link onClick={() => onChangeSort('HR')}>피홈런<span className="down"></span></Link></th>
                    <th className={sortKey === 'BB' ? 'selected' : ''}><Link onClick={() => onChangeSort('BB')}>볼넷<span className="down"></span></Link></th>
                    <th className={sortKey === 'HP' ? 'selected' : ''}><Link onClick={() => onChangeSort('HP')}>사구<span className="down"></span></Link></th>
                    <th className={sortKey === 'KK' ? 'selected' : ''}><Link onClick={() => onChangeSort('KK')}>탈삼진<span className="down"></span></Link></th>
                    <th className={sortKey === 'R' ? 'selected' : ''}><Link onClick={() => onChangeSort('R')}>실점<span className="down"></span></Link></th>
                    <th className={sortKey === 'ER' ? 'selected' : ''}><Link onClick={() => onChangeSort('ER')}>자책점<span className="down"></span></Link></th>
                  </tr>
                </thead>
                <tbody>
                  {pitcherList && pitcherList.map((pitcher, index) => <PcKtPitcher key={pitcher.pcode} pitcher={pitcher} index={index} />)}
                </tbody>
              </table>
            </div>
          </article>

          <article className="rank_record_content total_pitcher_rank pc_mode" style={{ display: tab === 'latest_game' ? 'block' : 'none' }}>
            <div className="rank_record">
              <table>
                <caption>전체 투수 순위</caption>
                <colgroup>
                  <col width="41px" />
                  <col width="71px" />
                  <col width="60px" />
                  <col width="*" />
                  <col width="60px" />
                  <col width="60px" />
                  <col width="60px" />
                  <col width="60px" />
                  <col width="60px" />
                  <col width="62px" />
                  <col width="62px" />
                  <col width="60px" />
                  <col width="60px" />
                  <col width="60px" />
                  <col width="60px" />
                  <col width="60px" />
                  <col width="60px" />
                  <col width="60px" />
                </colgroup>
                <thead>
                  <tr>
                    <th>순위</th>
                    <th>선수명</th>
                    <th>팀명</th>
                    <th className={sortKey === 'ERA' ? 'selected' : ''}><Link onClick={() => onChangeSort('ERA')}>평균자책점<span className="down"></span></Link></th>
                    <th className={sortKey === 'GAMENUM' ? 'selected' : ''}><Link onClick={() => onChangeSort('GAMENUM')}>경기<span className="down"></span></Link></th>
                    <th className={sortKey === 'W' ? 'selected' : ''}> <Link onClick={() => onChangeSort('W')}>승<span className="down"></span></Link></th>
                    <th className={sortKey === 'L' ? 'selected' : ''}><Link onClick={() => onChangeSort('L')}>패<span className="down"></span></Link></th>
                    <th className={sortKey === 'SV' ? 'selected' : ''}><Link onClick={() => onChangeSort('SV')}>세<span className="down"></span></Link></th>
                    <th className={sortKey === 'HOLD' ? 'selected' : ''}><Link onClick={() => onChangeSort('HOLD')}>홀<span className="down"></span></Link></th>
                    <th className={sortKey === 'WRA' ? 'selected' : ''}><Link onClick={() => onChangeSort('WRA')}>승률<span className="down"></span></Link></th>
                    <th className={sortKey === 'INN2' ? 'selected' : ''}><Link onClick={() => onChangeSort('INN2')}>이닝<span className="down"></span></Link></th>
                    <th className={sortKey === 'HIT' ? 'selected' : ''}><Link onClick={() => onChangeSort('HIT')}>피안타<span className="down"></span></Link></th>
                    <th className={sortKey === 'HR' ? 'selected' : ''}><Link onClick={() => onChangeSort('HR')}>피홈런<span className="down"></span></Link></th>
                    <th className={sortKey === 'BB' ? 'selected' : ''}><Link onClick={() => onChangeSort('BB')}>볼넷<span className="down"></span></Link></th>
                    <th className={sortKey === 'HP' ? 'selected' : ''}><Link onClick={() => onChangeSort('HP')}>사구<span className="down"></span></Link></th>
                    <th className={sortKey === 'KK' ? 'selected' : ''}><Link onClick={() => onChangeSort('KK')}>탈삼진<span className="down"></span></Link></th>
                    <th className={sortKey === 'R' ? 'selected' : ''}><Link onClick={() => onChangeSort('R')}>실점<span className="down"></span></Link></th>
                    <th className={sortKey === 'ER' ? 'selected' : ''}><Link onClick={() => onChangeSort('ER')}>자책점<span className="down"></span></Link></th>
                  </tr>
                </thead>
                <tbody>
                  {allPitcherList && allPitcherList.map((pitcher, index) => <PcAllPitcher key={pitcher.pcode} pitcher={pitcher} index={index} />)}
                </tbody>
              </table>
            </div>
          </article>

          <article className="rank_record_content total_pitcher_rank pc_mode" style={{ display: tab === 'north_league' ? 'block' : 'none' }}>
            <div className="rank_record">
              <table>
                <caption>전체 투수 순위</caption>
                <colgroup>
                  <col width="41px" />
                  <col width="71px" />
                  <col width="60px" />
                  <col width="*" />
                  <col width="60px" />
                  <col width="60px" />
                  <col width="60px" />
                  <col width="60px" />
                  <col width="60px" />
                  <col width="62px" />
                  <col width="62px" />
                  <col width="60px" />
                  <col width="60px" />
                  <col width="60px" />
                  <col width="60px" />
                  <col width="60px" />
                  <col width="60px" />
                  <col width="60px" />
                </colgroup>
                <thead>
                  <tr>
                    <th>순위</th>
                    <th>선수명</th>
                    <th>팀명</th>
                    <th className={sortKey === 'ERA' ? 'selected' : ''}><Link onClick={() => onChangeSort('ERA')}>평균자책점<span className="down"></span></Link></th>
                    <th className={sortKey === 'GAMENUM' ? 'selected' : ''}><Link onClick={() => onChangeSort('GAMENUM')}>경기<span className="down"></span></Link></th>
                    <th className={sortKey === 'W' ? 'selected' : ''}> <Link onClick={() => onChangeSort('W')}>승<span className="down"></span></Link></th>
                    <th className={sortKey === 'L' ? 'selected' : ''}><Link onClick={() => onChangeSort('L')}>패<span className="down"></span></Link></th>
                    <th className={sortKey === 'SV' ? 'selected' : ''}><Link onClick={() => onChangeSort('SV')}>세<span className="down"></span></Link></th>
                    <th className={sortKey === 'HOLD' ? 'selected' : ''}><Link onClick={() => onChangeSort('HOLD')}>홀<span className="down"></span></Link></th>
                    <th className={sortKey === 'WRA' ? 'selected' : ''}><Link onClick={() => onChangeSort('WRA')}>승률<span className="down"></span></Link></th>
                    <th className={sortKey === 'INN2' ? 'selected' : ''}><Link onClick={() => onChangeSort('INN2')}>이닝<span className="down"></span></Link></th>
                    <th className={sortKey === 'HIT' ? 'selected' : ''}><Link onClick={() => onChangeSort('HIT')}>피안타<span className="down"></span></Link></th>
                    <th className={sortKey === 'HR' ? 'selected' : ''}><Link onClick={() => onChangeSort('HR')}>피홈런<span className="down"></span></Link></th>
                    <th className={sortKey === 'BB' ? 'selected' : ''}><Link onClick={() => onChangeSort('BB')}>볼넷<span className="down"></span></Link></th>
                    <th className={sortKey === 'HP' ? 'selected' : ''}><Link onClick={() => onChangeSort('HP')}>사구<span className="down"></span></Link></th>
                    <th className={sortKey === 'KK' ? 'selected' : ''}><Link onClick={() => onChangeSort('KK')}>탈삼진<span className="down"></span></Link></th>
                    <th className={sortKey === 'R' ? 'selected' : ''}><Link onClick={() => onChangeSort('R')}>실점<span className="down"></span></Link></th>
                    <th className={sortKey === 'ER' ? 'selected' : ''}><Link onClick={() => onChangeSort('ER')}>자책점<span className="down"></span></Link></th>
                  </tr>
                </thead>
                <tbody>
                  {northPitcherList && northPitcherList.map((pitcher, index) => <PcAllPitcher key={pitcher.pcode} pitcher={pitcher} index={index} />)}
                </tbody>
              </table>
            </div>
          </article>

          <article className="rank_record_content total_pitcher_rank pc_mode" style={{ display: tab === 'south_league' ? 'block' : 'none' }}>
            <div className="rank_record">
              <table>
                <caption>전체 투수 순위</caption>
                <colgroup>
                  <col width="41px" />
                  <col width="71px" />
                  <col width="60px" />
                  <col width="*" />
                  <col width="60px" />
                  <col width="60px" />
                  <col width="60px" />
                  <col width="60px" />
                  <col width="60px" />
                  <col width="62px" />
                  <col width="62px" />
                  <col width="60px" />
                  <col width="60px" />
                  <col width="60px" />
                  <col width="60px" />
                  <col width="60px" />
                  <col width="60px" />
                  <col width="60px" />
                </colgroup>
                <thead>
                  <tr>
                    <th>순위</th>
                    <th>선수명</th>
                    <th>팀명</th>
                    <th className={sortKey === 'ERA' ? 'selected' : ''}><Link onClick={() => onChangeSort('ERA')}>평균자책점<span className="down"></span></Link></th>
                    <th className={sortKey === 'GAMENUM' ? 'selected' : ''}><Link onClick={() => onChangeSort('GAMENUM')}>경기<span className="down"></span></Link></th>
                    <th className={sortKey === 'W' ? 'selected' : ''}> <Link onClick={() => onChangeSort('W')}>승<span className="down"></span></Link></th>
                    <th className={sortKey === 'L' ? 'selected' : ''}><Link onClick={() => onChangeSort('L')}>패<span className="down"></span></Link></th>
                    <th className={sortKey === 'SV' ? 'selected' : ''}><Link onClick={() => onChangeSort('SV')}>세<span className="down"></span></Link></th>
                    <th className={sortKey === 'HOLD' ? 'selected' : ''}><Link onClick={() => onChangeSort('HOLD')}>홀<span className="down"></span></Link></th>
                    <th className={sortKey === 'WRA' ? 'selected' : ''}><Link onClick={() => onChangeSort('WRA')}>승률<span className="down"></span></Link></th>
                    <th className={sortKey === 'INN2' ? 'selected' : ''}><Link onClick={() => onChangeSort('INN2')}>이닝<span className="down"></span></Link></th>
                    <th className={sortKey === 'HIT' ? 'selected' : ''}><Link onClick={() => onChangeSort('HIT')}>피안타<span className="down"></span></Link></th>
                    <th className={sortKey === 'HR' ? 'selected' : ''}><Link onClick={() => onChangeSort('HR')}>피홈런<span className="down"></span></Link></th>
                    <th className={sortKey === 'BB' ? 'selected' : ''}><Link onClick={() => onChangeSort('BB')}>볼넷<span className="down"></span></Link></th>
                    <th className={sortKey === 'HP' ? 'selected' : ''}><Link onClick={() => onChangeSort('HP')}>사구<span className="down"></span></Link></th>
                    <th className={sortKey === 'KK' ? 'selected' : ''}><Link onClick={() => onChangeSort('KK')}>탈삼진<span className="down"></span></Link></th>
                    <th className={sortKey === 'R' ? 'selected' : ''}><Link onClick={() => onChangeSort('R')}>실점<span className="down"></span></Link></th>
                    <th className={sortKey === 'ER' ? 'selected' : ''}><Link onClick={() => onChangeSort('ER')}>자책점<span className="down"></span></Link></th>
                  </tr>
                </thead>
                <tbody>
                  {southPitcherList && southPitcherList.map((pitcher, index) => <PcAllPitcher key={pitcher.pcode} pitcher={pitcher} index={index} />)}
                </tbody>
              </table>
            </div>
          </article>

          <article className="rank_record_content pitcher_rank mb_mode">
            <div className="rank_record">
              <h5>투수기록</h5>
              <div className="season_select_box">
                <button type="button" className="btn_season" onClick={() => setShowYearComboBox(true)}>{year} 시즌</button>
                {showYearComboBox && (
                  <div className="select_box" style={{ display: 'block' }}>
                    <form name="season_select_frm" action="" method="">
                      {yearList && yearList.map((y, index) => <label><input type="radio" name="season" value={y.gyear} checked={selectYear === y.gyear} onClick={() => setSelectYear(y.gyear)} />{y.gyear} 시즌</label>)}
                    </form>
                    <button type="submit" className="btn_submit" onClick={() => { onChangeYear(selectYear); setShowYearComboBox(false) }}>완료</button>
                  </div>
                )}
              </div>
              <div className="fixed_table_content">
                <div className="table_wrap fixed">
                  <table>
                    <caption>투수기록</caption>
                    <colgroup>
                      <col className="hide" width="90px" />
                      <col width="*" />
                      <col width="65px" />
                      <col width="65px" />
                      <col width="65px" />
                      <col width="65px" />
                      <col width="65px" />
                      <col width="67px" />
                      <col width="67px" />
                      <col width="65px" />
                      <col width="65px" />
                      <col width="65px" />
                      <col width="65px" />
                      <col width="65px" />
                      <col width="65px" />
                      <col width="65px" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th className="hide">선수명</th>
                        <th className={sortKey === 'ERA' ? 'selected' : ''}><Link onClick={() => onChangeSort('ERA')}>평균자책점<span className="down"></span></Link></th>
                        <th className={sortKey === 'GAMENUM' ? 'selected' : ''}><Link onClick={() => onChangeSort('GAMENUM')}>경기<span className="down"></span></Link></th>
                        <th className={sortKey === 'W' ? 'selected' : ''}> <Link onClick={() => onChangeSort('W')}>승<span className="down"></span></Link></th>
                        <th className={sortKey === 'L' ? 'selected' : ''}><Link onClick={() => onChangeSort('L')}>패<span className="down"></span></Link></th>
                        <th className={sortKey === 'SV' ? 'selected' : ''}><Link onClick={() => onChangeSort('SV')}>세<span className="down"></span></Link></th>
                        <th className={sortKey === 'HOLD' ? 'selected' : ''}><Link onClick={() => onChangeSort('HOLD')}>홀<span className="down"></span></Link></th>
                        <th className={sortKey === 'WRA' ? 'selected' : ''}><Link onClick={() => onChangeSort('WRA')}>승률<span className="down"></span></Link></th>
                        <th className={sortKey === 'INN2' ? 'selected' : ''}><Link onClick={() => onChangeSort('INN2')}>이닝<span className="down"></span></Link></th>
                        <th className={sortKey === 'HIT' ? 'selected' : ''}><Link onClick={() => onChangeSort('HIT')}>피안타<span className="down"></span></Link></th>
                        <th className={sortKey === 'HR' ? 'selected' : ''}><Link onClick={() => onChangeSort('HR')}>피홈런<span className="down"></span></Link></th>
                        <th className={sortKey === 'BB' ? 'selected' : ''}><Link onClick={() => onChangeSort('BB')}>볼넷<span className="down"></span></Link></th>
                        <th className={sortKey === 'HP' ? 'selected' : ''}><Link onClick={() => onChangeSort('HP')}>사구<span className="down"></span></Link></th>
                        <th className={sortKey === 'KK' ? 'selected' : ''}><Link onClick={() => onChangeSort('KK')}>탈삼진<span className="down"></span></Link></th>
                        <th className={sortKey === 'R' ? 'selected' : ''}><Link onClick={() => onChangeSort('R')}>실점<span className="down"></span></Link></th>
                        <th className={sortKey === 'ER' ? 'selected' : ''}><Link onClick={() => onChangeSort('ER')}>자책점<span className="down"></span></Link></th>
                      </tr>
                    </thead>
                    <tbody>
                      {pitcherList && pitcherList.map((pitcher, index) => <MobileKtPitcher key={pitcher.pcode} pitcher={pitcher} index={index} />)}
                    </tbody>
                  </table>
                  <table className="fixed_table">
                    <thead>
                      <tr>
                        <th>선수명</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pitcherList && pitcherList.map((pitcher, index) => <tr key={pitcher.pcode} className={pitcher.inFlag === '1' ? 'selected' : ''}><td><a href="">{pitcher.playerName}</a></td></tr>)}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </article>
        </section>
      </div>
    </div >
  )
}

export default PitcherRankingTable
