import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { resourceRequest, useResource } from '../../modules/resource/resource'
import * as dialogActions from "../../modules/common/dialog"

const Wizpark5GStadiumStadium = () => {
  const WIZPARK_5G_STADIUM_IMAGE_PC = 'WIZPARK_5G_STADIUM_IMAGE_PC'
  const WIZPARK_5G_STADIUM_IMAGE_M = 'WIZPARK_5G_STADIUM_IMAGE_M'
  const [imagePc, errorPc] = useResource(WIZPARK_5G_STADIUM_IMAGE_PC)
  const [imageM, errorM] = useResource(WIZPARK_5G_STADIUM_IMAGE_M)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(resourceRequest(WIZPARK_5G_STADIUM_IMAGE_PC))
    dispatch(resourceRequest(WIZPARK_5G_STADIUM_IMAGE_M))
  }, [])

  useEffect(() => {
    if (errorPc && errorPc.message) {
      dispatch(dialogActions.alertOpen(errorPc.message))
    }
  }, [errorPc, errorM])


  return (
    <>
      <img className='img-whole pc' src={imagePc && imagePc.resource && imagePc.resource.value} alt="" />
      <img className='img-whole mobile' src={imageM && imageM.resource && imageM.resource.value} alt="" />
    </>
  )
}

export default Wizpark5GStadiumStadium
