import React, { useState } from 'react'
// import DaumPostcode from 'react-daum-postcode';
// import * as yup from 'yup'
// import { confirmPassword } from '../../modules/mypage/myinfo';

const PasswordConfirm = ({ login, onConfirmPassword }) => {

  const [simpleId, setSimpleId] = useState(login.user.id)
  const [memberPw, setMemberPw] = useState('')

  const handleKeyPress = (e) => {
    if (e.charCode === 13) {
      onConfirmPassword({ simpleId: simpleId, memberPw: memberPw })
    }
  }

  return (
    <div className="content_box find_by pw_re pw_confirm">
      {/* 상단 설명 */}
      <div className="group group1">
        <div className="content_desc">
          <h4>비밀번호 확인</h4>
          <span>정확한 본인 확인을 위해 다시 한번 비밀번호를 입력해주세요.</span>
        </div>{/* //.content_desc */}
      </div>{/* //.group1 */}

      {/* 입력 양식(휴대폰) */}
      <div className="group group2">
        <form action="" method="" name="pw_confirm">
          <div className="input_field">
            {/* midify 20191018 태그 변경 >> */}
            <dl className="user_row member_id clearfix">
              <dt className="user_tit">아이디</dt>
              <dd className="user_content">{login.user.id}</dd>
            </dl>
            {/*  <div className="input_row member_id clearfix">
                <div className="input_tit">
                  <label for="member_id">아이디</label>
                </div>
                <div className="input_content">
                  <input type="text" id="member_id" name="member_id" value="kt wiz" className="cell input_value" />
                </div>
              </div>  */}
            {/* << modify 20191018 */}
            <div className="input_row pw_confirm clearfix">
              <div className="input_tit">
                <label htmlFor="pw_confirm">비밀번호</label>
              </div>{/* //.input_tit */}
              <div className="input_content clearfix">
                <input type="password" id="pw_confirm" name="pw_confirm" className="cell" placeholder="8자 이상(영소문자, 숫자, 특수문자)" value={memberPw} onChange={(e) => setMemberPw(e.target.value)} onKeyPress={handleKeyPress} onSubmit={false} />
              </div>{/* //.input_content */}
            </div>{/* //.input_row.new_pw_confirm */}
          </div>{/* //.input_field */}
          <div className="btn">
            <button type="button" className={memberPw !== '' ? 'active' : ''} disabled={memberPw === ''} onClick={() => onConfirmPassword({ simpleId: simpleId, memberPw: memberPw })}>확인</button> {/* 클래스 active 붙었을 때 버튼 색 바뀝니다 */} {/* 확인 시 member_info.html로 이동 */}
          </div>
        </form>
      </div>{/* //.group2 */}
    </div>
  )
}

export default PasswordConfirm
