import React from 'react'
import Link from '../../components/common/Link'

const PageTab = ({ tabs, onClickTab }) => {
  return (
    <div className={tabs.length < 5 ? 'lnb_wrap' : 'lnb_wrap per20'}>
      <div className='container'>
        <ul className='clearfix'>
          {tabs.map((tab, i) => (
            <li key={`${i}`} className={'dep3' + (tab.isActive ? ' active' : '')}>
              <Link
                to={tab.link}
                onClick={e => {
                  e.preventDefault()
                  onClickTab && onClickTab(i)
                }}
              >
                {tab.title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default PageTab
