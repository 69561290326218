import React from 'react'
import ContentTemplate from '../../components/common/ContentTemplate'
import SubContentTemplate from '../../components/common/SubContentTemplate'
import PageHeaderContainer from '../../containers/common/PageHeaderContainer'
import PageTitleTemplate from '../../components/common/PageTitleTemplate'
import BreadcrumbContainer from '../../containers/common/BreadcrumbContainer'
import PageTemplate from '../../components/common/PageTemplate'
import { KTWIZ_BI_MASCOT, KTWIZ_BI_TAB } from '../../constants/page'
import Mascot from '../../components/ktwiz/bi/Mascot'

const BiWordmarkPage = ({ match }) => {
  return (
    <PageTemplate className="ktwiz">
      <PageHeaderContainer title="구단 BI" subtitle=" kt wiz를 대표하는 상징들을 소개 합니다." image={require('../../assets/img/ktwiz/sub_tit_ktwiz.png')} forwardTabs={KTWIZ_BI_TAB} currentPath={match.path} />
      <ContentTemplate>
        <PageTitleTemplate>
          <BreadcrumbContainer page={KTWIZ_BI_MASCOT} />
        </PageTitleTemplate>
        <SubContentTemplate className="ktwiz bi mascot">
          <Mascot />
        </SubContentTemplate>
      </ContentTemplate>
    </PageTemplate>
  )
}

export default BiWordmarkPage
