import React from 'react'
import ResetPwContainer from '../../containers/auth/ResetPwContainer'
import PageTemplate from '../../components/common/PageTemplate'
import PageHeaderContainer from '../../containers/common/PageHeaderContainer'
import ContentTemplate from '../../components/common/ContentTemplate'
import PageTitleTemplate from '../../components/common/PageTitleTemplate'
import Breadcrumb from '../../components/common/Breadcrumb'
import { RESETPW_PAGE } from '../../constants/page'

const ResetPwPage = ({ location }) => {

  return (
    <PageTemplate className='member'>
      <PageHeaderContainer title={RESETPW_PAGE.title} image={require('../../assets/img/login/sub_tit_membership.png')} />
      <ContentTemplate>
        <PageTitleTemplate>
          <Breadcrumb page={RESETPW_PAGE} />
        </PageTitleTemplate>
        <ResetPwContainer updateUserId={location.state} />
      </ContentTemplate>
    </PageTemplate>
  )
}

export default ResetPwPage
