import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import { wizArticleRequest } from '../../../modules/media/wizBoard';
import Link from '../../common/Link';

const ArticlePhotoDetail = ({ seq, query }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const detail = useSelector(state => state.wizData.data && state.wizData.data['articleDetail']);
  const match = useRouteMatch();
  const url = window.location.href

  console.log('ArticlePhotoDetail', { match });

  React.useEffect(() => {
    dispatch(wizArticleRequest(seq, query));
  }, [dispatch, seq]);

  const goList = () => {
    history.push(match.url);
  };

  const article = detail && {
    ...detail.article,
    title: detail.article.artcTitle,
    imgFilePath: detail.article.imgFilePath,
    content: detail.article.artcContents,
    nextSeq: detail.article.artcNextSeq,
    prevSeq: detail.article.artcPrevSeq,
    registerAt: new Date(detail.article.regDttm)
  };

  const goNext = () => {
    history.push(`${match.url}/${article.nextSeq}`);
  };

  const goPrev = () => {
    history.push(`${match.url}/${article.prevSeq}`);
  };

  const kakaoShare = () => {
    window.Kakao.init('05fc58a5fa465162b2e0fc5350ce9ed9')
    window.Kakao.Link.sendDefault({
      objectType: 'feed',
      content: {
        title: `${article.title}`,
        imageUrl: 'https://ktwiz.dev/static/media/bi_emblem_black.ee614e48.png',
        link: {
          mobileWebUrl: window.location.href,
          webUrl: window.location.href
        }
      },
      buttons: [{
        title: 'pc로 열기',
        link: {
          mobileWebUrl: window.location.href,
          webUrl: window.location.href
        }
      },
      {
        title: '모바일로 열기',
        link: {
          mobileWebUrl: window.location.href,
          webUrl: window.location.href
        }
      }
      ]
    });
    window.Kakao.cleanup()
  }

  return (
    <>
      <div className='detail_wrap'>
        <article className='article'>
          <div className='article_tit_wrap'>
            <dl className='article_tit'>
              <dt>
                <h4>
                  {article && article.title}
                </h4>
              </dt>
              <dd>
                <ul className='tit_info'>
                  <li>
                    <span className='date'>{article && format(article.registerAt, 'yyyy.MM.dd')}</span>
                    <span className='icon_calendar'></span>
                  </li>
                  {article && article.viewCnt ? (
                    <li className='last'>
                      <span className='count'>{article.viewCnt}</span>
                      <span className='icon_time'></span>
                    </li>
                  ) : (
                      ''
                    )}
                </ul>
              </dd>
            </dl>
          </div>
          <div className='article_content_wrap'>
            <div className='article_content'>
              {article && article.imgFilePath && <img src={article.imgFilePath} />}
              {article && article.streamMediaPath ? (
                <>
                  <iframe
                    src={article.streamMediaPath}
                    width='640'
                    height='360'
                    frameBorder='0'
                    allow='autoplay; fullscreen'
                    allowFullScreen
                  ></iframe>
                  <br />
                </>
              ) : (
                  ''
                )}
              <div dangerouslySetInnerHTML={{ __html: article && article.content }}></div>
            </div>
          </div>
        </article>
        <div className='share_btn_wrap'>
          <ul className='clearfix'>
            <li>
              <Link to='' className='btn_share kakaotalk' onClick={kakaoShare} />
            </li>
            <li>
              <Link to='' onClick={() => {
                window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}`, 'newwindow', 'width=700,height=700')
              }} className='btn_share facebook' target='_blank' />
            </li>
            {/* <li>
              <a href='' className='btn_share normal'></a>
            </li> */}
          </ul>
        </div>
      </div>

      <div className='board_btn_wrap'>
        <div className='board_btn_row'>
          <button className='btn_prev' onClick={goPrev}>
            이전
          </button>
          <button className='btn_next' onClick={goNext}>
            다음
          </button>
          <button className='btn_list' onClick={() => goList()}>
            목록보기
          </button>
        </div>
      </div>
    </>
  );
};

export default ArticlePhotoDetail;
