import React from "react";
import { fullText20210506, fullText20211207 } from "./privacyPolicyText";

const FullTextLog = ({ fullDateString }) => {
  return (
    <div className="fulllog_container">
      <h1 className="indi_title">개인정보 수집·이용 동의</h1>
      {fullDateString === `20211207` && fullText20211207.map((item, idx) => <p key={idx}>{item}</p>)}
      {fullDateString === `20210506` && fullText20210506.map((item, idx) => <p key={idx}>{item}</p>)}
    </div>
  );
};

export default FullTextLog;
