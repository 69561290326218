import React, { useState } from "react";
import DaumPostcode from "react-daum-postcode";
import * as yup from "yup";
import { checkPassword } from "../../modules/utils/passwd";
import { presentLog, thirdPresentLog } from "../auth/join/privacyPolicyText";

const MyInfo = ({ info, terms, onMyInfoUpdateRequest, onWithdrawRequest, onUpdateTermsRequest }) => {
  const [smsAgree, setSmsAgree] = useState(info.user.smsYn === "Y");
  const [emailAgree, setEmailAgree] = useState(info.user.emailYn === "Y");
  const [emailId, setEmailId] = useState(info.user.memberEmail.substring(0, info.user.memberEmail.indexOf("@")));
  const [emailDomain, setEmailDomain] = useState(info.user.memberEmail.substring(info.user.memberEmail.indexOf("@") + 1));
  const [addr1, setAddr1] = useState(info.user.addr1);
  const [addr2, setAddr2] = useState(info.user.addr2);
  const [postNo, setPostNo] = useState(info.user.postNo);
  const [mobpNo1, setMobpNo1] = useState(info.user.mobpNo1);
  const [mobpNo2, setMobpNo2] = useState(info.user.mobpNo2);
  const [mobpNo3, setMobpNo3] = useState(info.user.mobpNo3);

  const [memberPw, setMemberPw] = useState("");
  const [memberNewPw, setMemberNewPw] = useState("");
  const [memberNewPwConfirm, setMemberNewPwConfirm] = useState("");

  const [showDonationPopup, setShowDonationPopup] = useState(false);
  const [showDonationProgramPopup, setShowDonationProgramPopup] = useState(false);
  const [showTermsPopup, setShowTermsPopup] = useState(false);
  const [showLeavePopup, setShowLeavePopup] = useState(false);
  const [openPostCode, setOpenPostCode] = useState(false);

  const [withdrawType, setWithdrawType] = useState(null);
  const [withdrawReason, setWithdrawReason] = useState("");

  const [checkAgreeProvide, setCheckAgreeProvide] = useState(terms[4]);
  const [checkAgreeMarket, setCheckAgreeMarket] = useState(terms[8]);

  const [personerInform, setPersonerInform] = useState("none");

  const onChangeInformValue = (checkValue) =>{
    setPersonerInform(checkValue)
  }

  React.useEffect(() => {
    console.log(terms);
  }, [terms]);

  const handleAddress = (data) => {
    let fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress += extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }

    setPostNo(data.zonecode);
    setAddr1(fullAddress);
    setOpenPostCode(false);
  };

  const postCodeSearchStyle = {
    width: "300px",
    height: "400px",
    left: ((window.innerWidth || document.documentElement.clientWidth) - 300) / 2 - 5 + "px",
    top: ((window.innerHeight || document.documentElement.clientHeight) - 400) / 2 - 5 + "px",
    border: "5px solid",
    position: "fixed",
    overflow: "hidden",
    zIndex: 1,
    display: openPostCode ? "block" : "none",
  };

  const postCodeCloseImageStyle = {
    width: "20px",
    cursor: "pointer",
    position: "absolute",
    right: "-3px",
    top: "-3px",
    zIndex: 1,
  };

  const donationPopupStyle = {
    display: showDonationPopup ? "block" : "none",
  };

  const schema = yup.object().shape({
    simpleId: yup.string().required(),
    memberPw: yup.string(),
    memberNewPw: yup.string(),
    memberNewPwConfirm: yup.string(),
    mobpNo1: yup.string().required("휴대폰 번호를 입력해주세요"),
    mobpNo2: yup.string().required("휴대폰 번호를 입력해주세요"),
    mobpNo3: yup.string().required("휴대폰 번호를 입력해주세요"),
    emailYn: yup.string(),
    smsYn: yup.string(),
  });

  const saveMyInfo = () => {
    const user = {
      simpleId: info.user.simpleId,
      memberId: info.user.memberId,
      memberEmailId: emailId,
      memberEmailDomain: emailDomain,
      addr1: addr1,
      addr2: addr2,
      postNo: postNo,
      mobpNo1: mobpNo1,
      mobpNo2: mobpNo2,
      mobpNo3: mobpNo3,
      emailYn: emailAgree ? "Y" : "N",
      smsYn: smsAgree ? "Y" : "N",
      memberEmail: emailId + "@" + emailDomain,
      memberPw: memberPw,
      memberNewPw: memberNewPw,
      memberNewPwConfirm: memberNewPwConfirm,
    };

    schema
      .validate(user)
      .then(function (value) {
        if (memberNewPw !== "") {
          if (memberPw === "") {
            window.alert("기존 비밀번호를 입력해주세요");
            return;
          }

          if (memberNewPw !== memberNewPwConfirm) {
            window.alert("변경 비밀번호가 일치하지 않습니다.");
            return;
          }
          if (!checkPassword(memberNewPw)) {
            return;
          }
        }
        onMyInfoUpdateRequest(value);
      })
      .catch(function (err) {
        console.log(err);
        console.log(err.errors);

        const message = err.errors.reduce(function (acc, cur, i) {
          acc += cur + "\n";
          return acc;
        });
        window.alert(message);
      });
  };

  const withdrawRequest = () => {
    onWithdrawRequest({ type: withdrawType, reason: withdrawReason });
  };

  const openDonationPopup = () => {
    document.getElementsByTagName("body")[0].style.overflowY = "hidden";
    setShowDonationPopup(true);
    // setShowDonationProgramPopup(true)
  };

  const closeDonationPopup = () => {
    document.getElementsByTagName("body")[0].style.overflowY = "auto";
    setShowDonationPopup(false);
  };

  const openDonationProgramPopup = () => {
    document.getElementsByTagName("body")[0].style.overflowY = "hidden";
    setShowDonationProgramPopup(true);
  };

  const closeDonationProgramPopup = () => {
    document.getElementsByTagName("body")[0].style.overflowY = "auto";
    setShowDonationProgramPopup(false);
  };

  return (
    <>
      <div className="info_form_wrap member_info">
        <form action="" method="" name="member_info">
          <div className="input_field">
            <dl className="user_row member_id clearfix">
              <dt className="user_tit">아이디</dt>
              <dd className="user_content">{info.user.simpleId}</dd>
            </dl>
            <dl className="user_row member_name clearfix">
              <dt className="user_tit">이름</dt>
              <dd className="user_content">{info.user.memberName}</dd>
            </dl>
            <dl className="user_row member_class clearfix">
              <dt className="user_tit">회원등급</dt>
              <dd className="user_content">{info.user.memberDivName}</dd>
            </dl>
            <dl className="user_row member_memebership clearfix">
              <dt className="user_tit">멤버십 번호</dt>
              <dd className="user_content">{info.user.mobileCardNo}</dd>
            </dl>
            <div className="input_row mobile_num">
              <div className="input_tit necessary">
                <label htmlFor="mobile_num">휴대폰 번호</label>
              </div>
              {/* //.input_tit */}
              <div className="input_content clearfix">
                <div className="mobile_num1_cell float_l">
                  {/* modify 201915 태그 추가 */}
                  <select id="mobile_num1" name="mobile_num1" className="cell" value={mobpNo1} onChange={(e) => setMobpNo1(e.target.value)}>
                    <option value="010">010</option>
                    <option value="011">011</option>
                    <option value="016">016</option>
                    <option value="017">017</option>
                    <option value="018">018</option>
                    <option value="019">019</option>
                  </select>
                </div>
                <div className="mobile_num2_cell float_l">
                  <input type="text" id="mobile_num2" name="mobile_num2" className="cell" maxLength="4" value={mobpNo2} onChange={(e) => setMobpNo2(e.target.value)} />
                </div>
                {/*  <span>-</span>  */}
                <div className="mobile_num3_cell float_l">
                  {/* modify 201915 태그 추가 */}
                  <input type="text" id="mobile_num3" name="mobile_num3" className="cell" maxLength="4" value={mobpNo3} onChange={(e) => setMobpNo3(e.target.value)} />
                </div>
                <div className="agree_checkbox float_l">
                  {/* modify 201915 태그 추가 */}
                  <label htmlFor="sms_agree" className="sms_agree m_invisible">
                    <input type="checkbox" id="sms_agree" name="sms_agree" value="y" className="mr5" checked={smsAgree} onChange={(e) => setSmsAgree(!smsAgree)} />
                    SMS 수신 동의
                  </label>
                </div>
              </div>
              {/* //.input_content */}
            </div>
            {/* //.input_row.mobile_num */}

            {/* SMS 수신 동의(모바일) */}
            <div className="input_row agree_radio sms_agree pc_invisible">
              <div className="input_tit">
                <label htmlFor="sms_check">SMS 수신 동의</label>
              </div>
              {/* //.input-tit */}
              <div className="input_content clearfix">
                <label htmlFor="sms_agree">
                  <input type="radio" id="sms_agree" name="sms_agree" value="y" className="mr5" checked={smsAgree} onChange={(e) => setSmsAgree(true)} />
                  동의
                </label>
                <label htmlFor="sms_disagree">
                  <input type="radio" id="sms_disagree" name="sms_agree" value="n" className="mr5" checked={!smsAgree} onChange={(e) => setSmsAgree(false)} />
                  동의안함
                </label>
              </div>
              {/* //.input_content */}
            </div>
            {/* //.input_row.sms_agree */}

            {/* 주소 */}
            <div className="input_row address">
              <div className="input_tit">
                <label htmlFor="address">주소</label>
              </div>
              {/* //.input_tit */}
              <div className="input_content">
                <div className="address_num">
                  <div className="row clearfix">
                    {/* modify 201915 태그 추가 */}
                    <div className="address1_cell float_l">
                      {/* modify 201915 태그 추가 */}
                      <input type="text" id="address1" name="address1" className="cell" value={postNo} onChange={(e) => setPostNo(e.target.value)} />
                    </div>
                    <div className="address_btn float_l">
                      {/* modify 201915 태그 추가 */}
                      <button type="button" onClick={() => setOpenPostCode(true)}>
                        주소찾기
                      </button>
                    </div>
                  </div>
                </div>
                {/* //.address_num */}
                <div className="address_txt clearfix">
                  <input type="text" id="address2" name="address2" className="cell cell2 float_l" maxLength="256" value={addr1} onChange={(e) => setAddr1(e.target.value)} />
                  <input type="text" id="address3" name="address3" className="cell cell3 float_l" maxLength="256" value={addr2} onChange={(e) => setAddr2(e.target.value)} /> {/* modify 201915 클래스 m_invisible 제거 */}
                </div>
                {/* //.address_txt */}
              </div>
              {/* //.input_content */}
            </div>
            {/* //.input_row.address */}

            <div style={postCodeSearchStyle}>
              <DaumPostcode onComplete={handleAddress} width="100%" height="100%" />
              <img src="//t1.daumcdn.net/postcode/resource/images/close.png" id="btnCloseLayer" style={postCodeCloseImageStyle} alt="닫기 버튼" onClick={() => setOpenPostCode(false)}></img>
            </div>

            {/* 이메일 */}
            <div className="input_row email">
              <div className="input_tit">
                <label htmlFor="email">이메일</label>
              </div>
              {/* //.input_tit */}
              <div className="input_content clearfix">
                <div className="email_wrap float_l clearfix">
                  {/* modify 20191015 태그 추가 */}
                  <div className="email1_cell float_l">
                    {/* modify 20191015 태그 추가 */}
                    <input type="text" id="email1" name="email1" className="cell" value={emailId} onChange={(e) => setEmailId(e.target.value)} />
                    {/* modify 20191015 클래스 float_l 제거 */}
                  </div>
                  <div className="email2_cell float_l">
                    {/* modify 20191015 태그 추가 */}
                    <input type="text" id="email2" name="email2" className="cell float_l" value={emailDomain} onChange={(e) => setEmailDomain(e.target.value)} />
                    {/* modify 20191015 클래스 float_l 제거 */}
                  </div>
                </div>
                {/* //.email_wrap */}
                <div className="email3_cell float_l">
                  {/* modify 20191015 태그 추가 */}
                  <select
                    id="email3"
                    name="email3"
                    className="cell"
                    onChange={(e) => {
                      setEmailDomain(e.target.value);
                    }}
                  >
                    <option value="">직접입력</option>
                    <option value="naver.com">naver.com</option>
                    <option value="gmail.com">gmail.com</option>
                    <option value="hanmail.net">hanmail.net</option>
                    <option value="hotmail.com">hotmail.com</option>
                    <option value="nate.com">nate.com</option>
                    <option value="yahoo.co.kr">yahoo.co.kr</option>
                    <option value="korea.com">korea.com</option>
                    <option value="hanmir.com">hanmir.com</option>
                    <option value="paran.com">paran.com</option>
                  </select>
                </div>
                <div className="agree_checkbox float_l">
                  {/* modify 20191015 태그 추가 */}
                  <label htmlFor="email_agree" className="email_agree m_invisible">
                    <input type="checkbox" id="email_agree" name="email_agree" value="y" className="mr5" checked={emailAgree} onChange={(e) => setEmailAgree(!emailAgree)} />
                    이메일 수신 동의
                  </label>
                </div>
              </div>
              {/* //.input_content */}
            </div>
            {/* //.input_row.email */}
            <div className="warning">
              <span>* 이메일은 추후 아이디/비밀번호를 찾기 시 사용되니, 실제로 사용하시는 '이메일'을 기입해주세요.</span>
            </div>
            {/* //.warning */}

            {/* 이메일 수신 동의(모바일) */}
            <div className="input_row agree_radio email_agree pc_invisible">
              <div className="input_tit">
                <label htmlFor="email_check">이메일 수신 동의</label>
              </div>
              {/* //.input-tit */}
              <div className="input_content clearfix">
                <label htmlFor="email_agree">
                  <input type="radio" id="email_agree" name="email_agree" value="y" className="mr5" checked={emailAgree} onChange={(e) => setEmailAgree(true)} />
                  동의
                </label>
                <label htmlFor="email_disagree">
                  <input type="radio" id="email_disagree" name="email_agree" value="n" className="mr5" checked={emailAgree} onChange={(e) => setEmailAgree(false)} />
                  동의안함
                </label>
              </div>
              {/* //.input_content */}
            </div>
            {/* //.input_row.email_agree */}

            {/* 기존 비밀번호 */}
            <div className="input_row pw_origin">
              <div className="input_tit">
                <label htmlFor="pw_origin">기존 비밀번호</label>
              </div>
              {/* //.input_tit */}
              <div className="input_content clearfix">
                <input type="password" id="pw_origin" name="pw_origin" className="cell float_l" placeholder="8자 이상(영소문자, 숫자, 특수문자)" value={memberPw} onChange={(e) => setMemberPw(e.target.value)} />
                <span className="float_l">* 비밀번호 변경시에만 입력하세요.</span>
              </div>
              {/* //.input_content */}
            </div>
            {/* //.input_row.pw_origin */}

            {/* 변경 비밀번호 */}
            <div className="input_row pw_reset">
              <div className="input_tit">
                <label htmlFor="pw_reset">변경 비밀번호</label>
              </div>
              {/* //.input_tit */}
              <div className="input_content clearfix">
                <input type="password" id="pw_reset" name="pw_reset" className="cell float_l" placeholder="8자 이상(영소문자, 숫자, 특수문자)" value={memberNewPw} onChange={(e) => setMemberNewPw(e.target.value)} />
                <span className="float_l">* 비밀번호 변경시에만 입력하세요.</span>
              </div>
              {/* //.input_content */}
            </div>
            {/* //.input_row.pw_reset */}

            {/* 변경 비밀번호 확인 */}
            <div className="input_row pw_reset_confirm">
              <div className="input_tit">
                <label htmlFor="pw_reset_confirm">
                  <span>변경 비밀번호</span>
                  <span>확인</span>
                </label>
              </div>
              {/* //.input_tit */}
              <div className="input_content clearfix">
                <input type="password" id="pw_reset_confirm" name="pw_reset_confirm" className="cell float_l" placeholder="8자 이상(영소문자, 숫자, 특수문자)" value={memberNewPwConfirm} onChange={(e) => setMemberNewPwConfirm(e.target.value)} />
                <span className="float_l">* 비밀번호 변경시에만 입력하세요.</span>
              </div>
              {/* //.input_content */}
            </div>
            {/* //.input_row.pw_reset_confirm */}
          </div>
          {/* //.input_field */}
        </form>
        <h5 onClick={() => setShowTermsPopup(true)} style={{ margin: "10px", width: "160px", color: "#ec0a0b", borderBottom: "1px solid", cursor: "pointer", fontSize: "15px" }}>
          개인정보 이용내역 보기 &gt;&gt;
        </h5>
      </div>
      {/* 나의 기부 현황 */}
      <div className="donation_wrap">
        <h4>나의 기부 현황</h4>
        <div className="donation_row">
          <div className="clearfix">
            <div className="box_wrap sum_wrap float_l clearfix">
              {/* modify 20191018 >> */}
              <dl className="donation_money clearfix">
                <dt>기부금액</dt>
                <dd className="txt_box">{info.donation.earnAmt}원</dd>
              </dl>
              {/*  <label for="sum" className="txt font_c">기부금액</label>
                    <input type="text" id="sum" name="sum" value="0원" className="cell txt_box" />   */}
            </div>
            <div className="box_wrap rank_wrap float_l clearfix">
              <dl className="donation_rank clearfix">
                <dt>순위</dt>
                <dd className="txt_box">
                  {info.donation.ranking} / {info.donation.totalMember}
                </dd>
              </dl>
              {/*  <label for="rank" className="txt">순위</label>
                    <input type="text" id=rank" name="rank" value="25,425 / 256,391" className="cell txt_box" />  */}
            </div>
            {/* << modify 20191018 */}
            <div className="donation_btn float_l clearfix">
              <button type="button" className="donation_state_btn" onClick={openDonationPopup}>
                기부금 내역보기
              </button>
              <button type="button" className="donation_program_btn" onClick={openDonationProgramPopup}>
                기부금 안내보기
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* 수정하기 버튼 */}
      {/* modify 20191014 >> */}
      <div className="btn_wrap clearfix">
        <button type="submit" className="confirm float_l active" disabled={false} onClick={saveMyInfo}>
          수정하기
        </button>
        <button type="button" className="leave_btn float_r" onClick={() => setShowLeavePopup(true)}>
          회원탈퇴
        </button>
        {/* modify 20191014 */}
      </div>
      {/* //.btn_wrap */}
      <div className="content_pop_wrap leave_pop" style={{ display: showLeavePopup ? "block" : "none" }}>
        <div className="content_pop_container">
          <div className="pop_overlay"></div>
          <div className="content_pop">
            <h5>회원탈퇴하기</h5>
            <div className="group1">
              <strong>회원탈퇴를 하시기 전에 아래 사항을 꼭 확인하세요!</strong>
              <ul className="leave_notice">
                <li>회원님의 개인정보, SNS 계정 정보 등 모든 정보가 삭제됩니다.</li>
                <li>
                  회원탈퇴를 하시면 작성하신 게시물은 삭제되지 않습니다.
                  <br />
                  필요 시 탈퇴 전 모두 수정, 삭제 해주세요.
                </li>
              </ul>
            </div>
            {/* //.group1 */}
            <div className="group2">
              <strong>탈퇴사유</strong>
              <label htmlFor="reason_etc">
                <input type="radio" id="reason_etc" name="reason" value="1" className="mr5" onClick={(e) => setWithdrawType(e.target.value)} />
                기타
              </label>
              <label htmlFor="reason_service">
                <input type="radio" id="reason_service" name="reason" value="2" className="mr5" onClick={(e) => setWithdrawType(e.target.value)} />
                서비스 불만족
              </label>
              <label htmlFor="reason_site">
                <input type="radio" id="reason_site" name="reason" value="3" className="mr5" onClick={(e) => setWithdrawType(e.target.value)} />
                사이트 이용 불편
              </label>
              <textarea id="opinion" name="opinion" rows="4" placeholder="탈퇴 사유 혹은 kt wiz에 바라는 점을 남겨주세요." value={withdrawReason} onChange={(e) => setWithdrawReason(e.target.value)}></textarea>
            </div>
            {/* //.group2 */}
            <div className="btn_wrap clearfix">
              <button type="sumbit" className={"confirm float_l " + (withdrawType == null || withdrawReason === "" ? "" : "active")} disabled={withdrawType == null || withdrawReason === ""} onClick={withdrawRequest}>
                탈퇴하기
              </button>
              <button className="leave_cancel_btn float_r" onClick={() => setShowLeavePopup(false)}>
                취소
              </button>
            </div>
            {/* //.btn_wrap */}

            <div className="pop_close">
              <button type="button" onClick={() => setShowLeavePopup(false)}>
                <img src={require("../../assets/img/common/close_b.png")} alt="팝업 닫기 버튼" />
              </button>
            </div>
            {/* pop_close */}
          </div>
          {/* //.leave_pop */}
        </div>
        {/* //.content_pop_container */}
      </div>
      {/* //.content_pop_wrap */}
      <div className="content_pop_wrap donation_state_pop" style={donationPopupStyle}>
        <div className="content_pop_container">
          <div className="pop_overlay"></div>
          <div className="content_pop">
            <h5>kt wiz 구단 회원 기부금 현황</h5>
            <div className="group1">
              <img src={require("../../assets/img/login/donation_state.png")} alt="kt wiz 구단 회원 기부금 현황 배경 이미지" />
              <strong className="first">kt wiz 구단 회원님들의</strong>
              <strong>기부현황을 알려드립니다.</strong>
              <span>kt wiz 회원 전체 기부현황 {info.donation.seasonEarnAmt}원</span>
            </div>
            {/* //.group1 */}

            <div className="group2">
              <h6>
                {info.user.simpleId} ({info.user.memberName})님의
              </h6>
              <dl>
                <dt>기부현황</dt>
                <dd>{info.donation.earnAmt}원</dd>
              </dl>
              <dl>
                <dt>순위</dt>
                <dd>
                  {info.donation.ranking} / {info.donation.totalMember}명
                </dd>
              </dl>
              <dl>
                <dt>체크인 횟수</dt>
                <dd>{info.donation.checkinCnt}회</dd>
              </dl>
            </div>
            {/* //.group2 */}

            <div className="group3">
              <p>kt wiz 구단 회원님들은 정규시즌 동안 “wizzap”과 “구단 홈페이지”를 통해 티켓을 구매할 시 티켓구매 금액의 3%를 구단에서 기부금으로 적립하여 시즌 종료 후 팬과 함께 연고지역 야구발전을 위해 사용할 예정입니다.</p>
            </div>
            {/* //.group3 */}

            <div className="pop_close">
              <button type="button" onClick={closeDonationPopup}>
                <img src={require("../../assets/img/common/close_b.png")} alt="팝업 닫기 버튼" />
              </button>
            </div>
            {/* pop_close */}
          </div>
          {/* //.donation_state_pop */}
        </div>
        {/* //.content_pop_container */}
      </div>
      {/* //.content_pop_wrap */}
      <div className="content_pop_wrap donation_program_pop" style={{ display: showDonationProgramPopup ? "block" : "none" }}>
        <div className="content_pop_container">
          <div className="pop_overlay"></div>
          <div className="content_pop">
            <h5>기부 프로그램 안내</h5>
            <div className="group1">
              <p>
                “티켓 구매의 3%는 자동으로 적립되며,
                <br />
                도움이 필요한 곳에 기부금으로 사용됩니다.”
              </p>
              <img src={require("../../assets/img/login/donation_program.png")} alt="기부 프로그램 안내 배경 이미지" className="m_invisible" />
              <img src={require("../../assets/img/login/donation_program_m.png")} alt="기부 프로그램 안내 배경 이미지" className="pc_invisible" />
            </div>
            {/* //.group1 */}

            <div className="group2">
              <h6>기부 프로그램</h6>
              <div className="row clearfix">
                <div className="area area1 float_l">
                  <strong className="tit tit1">기부금 적립 방법</strong>
                  <p className="pd_bt">
                    kt wiz 회원 가입 후 2018년 정규시즌동안
                    <br />
                    wizzap과 홈페이지를 통해 티켓 구매 시,
                    <br />
                    구매 금액의 3% 자동적립
                  </p>
                  <strong className="tit tit2">기부적립금 운영</strong>
                  <p>
                    2018년 시즌 종료 후 팬 투표를 통해 기부활동 선정
                    <br />▶ 기부 활동은 시즌 종료 후 진행 예정
                    <br />
                    (wizzap, 홈페이지 참고)
                    <br />
                    (2017년 : 의료지원, 복지시설, 유소년 야구용품 지원 등 지역사회 공헌)
                  </p>
                </div>
                {/* //.area1 */}
                <div className="area area2 float_l">
                  <strong className="tit tit3">기부마법사</strong>
                  <p className="pd_bt">
                    kt wiz 회원 가입 후 2018년 정규시즌동안
                    <br />
                    wizzap과 홈페이지를 통해 티켓 구매 시,
                    <br />
                    구매 금액의 3% 자동적립
                  </p>
                  <strong className="tit tit4">기부왕</strong>
                  <ul>
                    <li>2018 정규 시즌 중 기부 적립금이 가장 많은 회원 1명</li>
                    <li>2018 마지막 홈경기 기부왕 시상 및 시구</li>
                    <li>2019 정규 시즌 주중 경기 스카이박스(12인실) 초청</li>
                    <li>2019 정규 시즌 응원지정석 뮤료티켓 10매 </li>
                  </ul>
                </div>
              </div>
              {/* //.row */}
            </div>
            {/* //.group2 */}

            <div className="pop_close">
              <button type="button" onClick={closeDonationProgramPopup}>
                <img src={require("../../assets/img/common/close_b.png")} alt="팝업 닫기 버튼" />
              </button>
            </div>
            {/* pop_close */}
          </div>
          {/* //.program_pop */}
        </div>
        {/* //.content_pop_container */}
      </div>

      <div className="content_pop_wrap donation_program_pop" style={{ display: showTermsPopup ? "block" : "none" }}>
        <div className="content_pop_container">
          <div className="pop_overlay"></div>
          <div className="content_pop" style={{ overflowY: "scroll", maxHeight: "77%" }}>
            {personerInform === 'individualLog' ? 
            <div className="log_box">
              {presentLog.map((item,idx)=><p key={idx}>{item}</p>)}
              <button onClick={()=> onChangeInformValue('none')} className="btn_backjoin">돌아가기</button>
            </div>
            : personerInform === 'thirdTest' ? 
            <div className="log_box">
              {thirdPresentLog.map((item,idx)=><p key={idx}>{item}</p>)}
              <button onClick={()=> onChangeInformValue('none')} className="btn_backjoin">돌아가기</button>
            </div> : 
            // null}
            <>
            <h5>개인정보 이용내역</h5>
            {/* //.group1 */}

            <div className="sign_up_step1">
              <div className="group position_maker">
                <h5 style={{ textAlign: "left" }}>서비스 이용약관</h5>
                <div className="agree_data" dangerouslySetInnerHTML={{ __html: terms[1].termsContents.replace(/\n/g, "<br/>").replace(/ /g, "&nbsp;") }}></div>
              </div>
              {/* //.group */}

              <div className="group position_maker">
              <div className="indis_wrap">
                <h5 style={{ textAlign: "left" }}>개인정보 수집·이용 동의</h5>
                <button onClick={()=> onChangeInformValue('individualLog')} className="btn_movetext">전문보기</button>
              </div>
                <div className="agree_data" dangerouslySetInnerHTML={{ __html: terms[2].termsContents.replace(/\n/g, "<br/>").replace(/ /g, "&nbsp;") }}></div>
              </div>
              {/* //.group */}

              <div className="group position_maker">
                <h5 style={{ textAlign: "left" }}>위치정보 이용약관</h5>
                <div className="agree_data" dangerouslySetInnerHTML={{ __html: terms[3].termsContents.replace(/\n/g, "<br/>").replace(/ /g, "&nbsp;") }}></div>
              </div>

              <div className="group position_maker">
              <div className="indis_wrap">
                <h5 style={{ textAlign: "left" }}>
                  개인정보 제 3자 제공에 대한 안내<span className="font_c">[선택]</span>
                </h5>
                <button onClick={()=> onChangeInformValue('thirdTest')} className="btn_movetext">전문보기</button>
              </div>
                <div className="agree_data" dangerouslySetInnerHTML={{ __html: checkAgreeProvide.termsContents.replace(/\n/g, "<br/>").replace(/ /g, "&nbsp;") }}></div>
                <div className="warning">
                  <p>제3자 정보 제공 동의를 거부할 권리가 있으며, 동의를 거부하더라도 회원가입 및 서비스 이용이 가능합니다.</p>
                </div>
                {/* //.warning */}
                <div className="agree_check position_maker m_invisible">
                  <input type="radio" name="agree_provide" value="Y" checked={checkAgreeProvide.isAgree.toUpperCase() === "Y" ? true : false} onChange={() => setCheckAgreeProvide({ ...checkAgreeProvide, isAgree: "Y" })} />
                  <label htmlFor="agree_provide" className="ml5">
                    개인정보 제 3자 제공에 동의합니다.
                  </label>
                  <input type="radio" name="agree_provide" value="N" className="ml20" checked={checkAgreeProvide.isAgree.toUpperCase() === "N" ? true : false} onChange={() => setCheckAgreeProvide({ ...checkAgreeProvide, isAgree: "N" })} />
                  <label htmlFor="disagree_provide" className="ml5">
                    동의하지 않습니다.
                  </label>
                </div>
                {/* //.agree_check */}
                <div className="agree_check pc_invisible">
                  <br />
                  <br />
                  <label htmlFor="agree_provide">동의</label>
                  <input type="checkbox" name="agree_provide" value="Y" checked={checkAgreeProvide.isAgree.toUpperCase() === "Y" ? true : false} onChange={() => setCheckAgreeProvide({ ...checkAgreeProvide, isAgree: checkAgreeProvide.isAgree.toUpperCase() === "Y" ? "N" : "Y" })} />
                </div>
                {/* //.agree_check */}
              </div>
              {/* //.group */}

              <div className="group position_maker marketing">
                <h5 style={{ textAlign: "left" }}>
                  마케팅 및 광고 활용 동의에 대한 안내<span className="font_c">[선택]</span>
                </h5>
                <div className="agree_data" dangerouslySetInnerHTML={{ __html: checkAgreeMarket.termsContents.replace(/\n/g, "<br/>").replace(/ /g, "&nbsp;") }}></div>
                {/* //.agree_data */}
                <div className="warning">
                  <p>
                    마케팅 활용을 위한 개인정보 수집 이용 안내에 대한 동의를 거부할 권리가 있으며, 동의를 거부하더라도 회원가입 및 서비스 이용이 가능합니다. <br /> 미 동의시 kt sports 주식회사에서 주요 행사 안내 및 혜택 제공에 제한이 있을 수 있습니다.
                  </p>
                </div>
                {/* //.warning */}

                <div className="agree_check position_maker m_invisible">
                  <input type="radio" id="agree_market" name="agree_market" value="y" checked={checkAgreeMarket.isAgree.toUpperCase() === "Y" ? true : false} onChange={() => setCheckAgreeMarket({ ...checkAgreeMarket, isAgree: "Y" })} />
                  <label htmlFor="agree_market" className="ml5">
                    개인정보 마케팅 및 광고 활용에 동의 합니다.
                  </label>
                  <input type="radio" id="disagree_market" name="agree_market" value="n" className="ml20" checked={checkAgreeMarket.isAgree.toUpperCase() === "N" ? true : false} onChange={() => setCheckAgreeMarket({ ...checkAgreeMarket, isAgree: "N" })} />
                  <label htmlFor="disagree_market" className="ml5">
                    동의하지 않습니다.
                  </label>
                </div>
                <div className="agree_check pc_invisible">
                  <br />
                  <br />
                  <label htmlFor="agree_market">동의</label>
                  <input type="checkbox" name="agree_market" value="Y" checked={checkAgreeMarket.isAgree.toUpperCase() === "Y" ? true : false} onChange={() => setCheckAgreeMarket({ ...checkAgreeMarket, isAgree: checkAgreeMarket.isAgree.toUpperCase() === "Y" ? "N" : "Y" })} />
                </div>
              </div>
            </div>

            <div className="btn_wrap clearfix">
              <button type="button" className="confirm" style={{ margin: "0 auto" }} onClick={() => onUpdateTermsRequest({ checkAgreeMarket, checkAgreeProvide })}>
                선택약관 수정
              </button>
              {/* 클래스 active 붙었을 때 버튼 색 바뀝니다 */} {/* 동의 시 sign_up_step2.html로 이동 */}
              {/* 미동의 시<p>kt sports 주식회사에서 제공하는 서비스 안내 등 광고성 정보를 받으시려면 마케팅 목적 이용에 동의하여 주시기 바랍니다.</p> 이전 서비스화면으로 이동 */}
            </div>
            </>}
            <div className="pop_close">
              <button type="button" onClick={() => {setShowTermsPopup(false); onChangeInformValue('none');}}>
                <img src={require("../../assets/img/common/close_b.png")} alt="팝업 닫기 버튼" />
              </button>
            </div>
            {/* pop_close */}
          </div>
          {/* //.program_pop */}
        </div>
        {/* //.content_pop_container */}
      </div>
    </>
  );
};

export default MyInfo;
