import { put, all, call, fork, takeEvery, take } from 'redux-saga/effects'
import { registSponsorReq } from '../../api/ktwiz/sponsorReq'
import * as actions from '../../modules/ktwiz/sponsorReq'
import * as dialogActions from '../../modules/common/dialog'
import * as loadingActions from '../../modules/common/loading'

function* sponsorRegister({ sponsorReq, onClickShowRegister }) {

  yield put(loadingActions.setLoading(true))

  const callResp = yield call(registSponsorReq, sponsorReq);

  if (callResp.status === 1803 && callResp.message !== null) {
    yield put(dialogActions.alertOpen(".zip파일과 .alz파일 업로드만 가능합니다."))
  } else if (callResp.status !== 0 && callResp.message !== null) {
    yield put(actions.sponsorReqSuccess())
    yield put(dialogActions.alertOpen('처리중 문제가 발생했습니다.\n다시 한번 시도해 주세요.\n' + callResp.message))
  } else if (callResp.status === 0 && callResp.data !== null) {
    yield put(actions.sponsorReqSuccess())
    yield put(dialogActions.alertOpen('스폰서 신청이 완료 되었습니다.\n확인 후 담당자가 연락 드리도록 하겠습니다.'))

    // 처리 완료시 스폰서 신청 및 문의 창 닫기
    const alertResult = yield take([dialogActions.ALERT_POSITIVE])
    if (alertResult.type === dialogActions.ALERT_POSITIVE) {
      onClickShowRegister(false)
    }

  } else {
    yield put(actions.sponsorReqFailure())
    yield put(dialogActions.alertOpen('처리중 문제가 발생했습니다.\n다시 한번 시도해 주세요.'))
  }

  yield put(loadingActions.setLoading(false))

}

function* watchSponsorRegister() {
  yield takeEvery(actions.SPONSOR_REQ_REQUEST, sponsorRegister)
}

export default function* sponsorReqSaga() {
  yield all([
    fork(watchSponsorRegister),
  ])
}
