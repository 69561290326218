import React from 'react'
import ContentTemplate from '../../components/common/ContentTemplate'
import SubContentTemplate from '../../components/common/SubContentTemplate'
import PageHeaderContainer from '../../containers/common/PageHeaderContainer'
import PageTitleTemplate from '../../components/common/PageTitleTemplate'
import BreadcrumbContainer from '../../containers/common/BreadcrumbContainer'
import PageTemplate from '../../components/common/PageTemplate'
import PolicyChild from '../../components/ktwiz/policy/PolicyChild'
import { KTWIZ_POLICY_TAB, KTWIZ_POLICY_CHILD } from '../../constants/page'

const PolicyChildPage = ({ match }) => {
  return (
    <PageTemplate className="ktwiz">
      <PageHeaderContainer title="회원 정책" subtitle="kt wiz 회원만의 특별한 할인 혜택을 만나 보세요." image={require('../../assets/img/ktwiz/sub_tit_ktwiz.png')} forwardTabs={KTWIZ_POLICY_TAB} currentPath={match.path} />
      <ContentTemplate>
        <PageTitleTemplate>
          <BreadcrumbContainer page={KTWIZ_POLICY_CHILD} />
        </PageTitleTemplate>
        <SubContentTemplate className="ticket discount child">
          <PolicyChild />
        </SubContentTemplate>
      </ContentTemplate>
    </PageTemplate>
  )
}

export default PolicyChildPage
