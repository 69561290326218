import { all } from 'redux-saga/effects'
import loginSaga from './auth/login'
import joinSaga from './join/join'
import certSaga from './auth/cert'
import findIdSaga from './auth/findId'
import findPwSaga from './auth/findPw'
import resetPwSaga from './auth/resetPw'
import myinfoSaga from './mypage/myinfo'
import wizDataSaga from './common/wizData'
import sponsorSaga from './ktwiz/sponsor'
import wallpaperSaga from './ktwiz/wallpaper'
import ticketSaga from './ticket/ticket'
import sponsorReqSaga from './ktwiz/sponsorReq'

export function* rootSaga() {
  yield all([
    loginSaga(),
    joinSaga(),
    myinfoSaga(),
    wizDataSaga(),
    certSaga(),
    findIdSaga(),
    sponsorSaga(),
    wallpaperSaga(),
    findPwSaga(),
    resetPwSaga(),
    ticketSaga(),
    sponsorReqSaga(),
  ])
}
