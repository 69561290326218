import React from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import ContentTemplate from '../../components/common/ContentTemplate';
import SubContentTemplate from '../../components/common/SubContentTemplate';
import PageHeaderContainer from '../../containers/common/PageHeaderContainer';
import PageTitleTemplate from '../../components/common/PageTitleTemplate';
import Breadcrumb from '../../components/common/Breadcrumb';
import ArticleSearch from '../../components/media/board/ArticleSearch';
import ArticleDetail from '../../components/media/board/ArticleDetail';
import PageTemplate from '../../components/common/PageTemplate';
import { WIZ_FIRST_PITCHER_PAGE } from '../../constants/page';
import WizBoard from '../../components/media/board/WizBoard';
import qs from 'query-string';

const FirstFitcherInfoPage = () => {
  const matchSeq = useRouteMatch('/media/:board/:seq');
  const query = qs.parse(useLocation().search);

  const page = {
    path: '/media/firstpitch',
    title: '시구자 정보',
    type: 'firstPitcher',
    page: WIZ_FIRST_PITCHER_PAGE
  };

  return (
    <PageTemplate className='media'>
      <PageHeaderContainer
        title='시구자 정보'
        subtitle='경기의 또다른 하이라이트 시구자 정보를 안내해 드립니다.'
        image={require('../../assets/img/media/sub_tit_media.png')}
      />
      <ContentTemplate className={matchSeq ? 'media_event_pitcher_info_detail' : 'media_event_pitcher_info'}>
        <PageTitleTemplate>
          <Breadcrumb page={page.page} />
          <ArticleSearch option={query['search.sort']} text={query['search.searchWord']} />
        </PageTitleTemplate>
        <SubContentTemplate>
          {matchSeq ? (
            <ArticleDetail seq={matchSeq.params.seq} query={query} />
          ) : (
              <WizBoard board={page.type} query={query} />
            )}
        </SubContentTemplate>
      </ContentTemplate>
    </PageTemplate>
  );
};

export default FirstFitcherInfoPage;
