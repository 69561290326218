import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { createDetailRequest } from '../../../modules/player/player'
import Link from '../../common/Link'
import qs from 'query-string'

const CoachDetail = ({ type }) => {

  const params = qs.parse(useLocation().search)

  const data = useSelector(state => state.wizData.data && state.wizData.data[type])
  // const error = useSelector(state => state.wizData.error && state.wizData.error[type])
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(createDetailRequest({ 'gameSearch.pcode': params.pcode }, type))
  }, [params.pcode, type])

  const coachstep = data && data.coachstep

  return (
    <div className="player_detail_content staff">
      <article className="player_info_content">
        <div>
          <div className="player_img">
            <img src={(coachstep && coachstep.playerPrvwImg2) ? coachstep.playerPrvwImg2 : require('../../../assets/img/player/no_img_player_detail.png')} alt="" className="mb_hide" onError={(e) => {
              e.target.onError = null
              e.target.src = require('../../../assets/img/player/no_img_player_detail.png')
            }} />
            <img src={(coachstep && coachstep.playerPrvwImg3) ? coachstep.playerPrvwImg3 : require('../../../assets/img/player/no_img_player_detail_coach_m.png')} alt="" className="pc_hide" onError={(e) => {
              e.target.onError = null
              e.target.src = require('../../../assets/img/player/no_img_player_detail_coach_m.png')
            }} />
          </div>
          <dl className="player_info">
            <dt>
              <span className="back_num">{`No. ${coachstep && coachstep.backnum}`}</span>
              {coachstep && coachstep.playerName}
              <span className="eng_name mb_hide">{coachstep && coachstep.engName}</span>
            </dt>
            <dd className="info_list_wrap">
              <ul>
                <li className="position">
                  <dl>
                    <dt>포지션</dt>
                    <dd>{coachstep && coachstep.position}</dd>
                  </dl>
                </li>
                <li className="birthday">
                  <dl>
                    <dt>생년월일</dt>
                    <dd>{coachstep && coachstep.birth}</dd>
                  </dl>
                </li>
                <li className="build">
                  <dl>
                    <dt>체격</dt>
                    <dd>{coachstep && coachstep.height}, {coachstep && coachstep.weight}</dd>
                  </dl>
                </li>
                <li className="native">
                  <dl>
                    <dt>출신교</dt>
                    <dd>
                      <span className="mb_hide">

                        {coachstep && coachstep.career.split('-').filter((v) => {
                          return (v.substring(v.length - 1) === '초'
                            || v.substring(v.length - 1) === '중')
                        }).map((v, i, arr) => (`${v}-`))}

                      </span>

                      {coachstep && coachstep.career.split('-').filter((v) => {
                        return (v.substring(v.length - 1) === '고'
                          || v.substring(v.length - 1) === '대')
                      }).map((v, i, arr) => (`${v}${(arr.length - 1 === i) ? '' : '-'}`))}

                    </dd>
                  </dl>
                </li>
                {/* <li className="career mb_hide">
                  <dl>
                    <dt>경력사항</dt>
                    <dd>
                      {coachstep && coachstep.career2.split('\n').map((v, i) => (
                        <React.Fragment>
                          {v}<br />
                        </React.Fragment>
                      ))}
                    </dd>
                  </dl>
                </li> */}
              </ul>
              <Link to={`/media/photos/1?search.searchWord=${coachstep && coachstep.playerName}&search.sort=400`} className="btn_player_img mb_hide">사진 보기</Link>
            </dd>
          </dl>
        </div>
      </article>
      <article className="player_career_content">
        <div>
          <h5>주요경력</h5>
          <ul>
            {coachstep && coachstep.career2.split('\n').map((v, i) => (
              <li key={i}>{v}</li>
            ))}
          </ul>
        </div>
      </article>
    </div>
  )
}

export default CoachDetail
