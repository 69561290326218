import wiz from '../../api/wiz'
import { dataRequest } from '../common/wizData'

export const createRequest = (api, params, dataType) => {
  return dataRequest(
    api,
    params,
    dataType
  )
}

export const pitcherRankingRequest = (param, type) => createRequest(wiz.API.GET_PITCHER_RANKING_LIST, param, type)
export const futuresPitcherRankingRequest = (param, type) => createRequest(wiz.API.GET_FUTURES_PITCHER_RANKING_LIST, param, type)

export const createBatterRankingRequest = (api, params, dataType) => {
  return dataRequest(
    api,
    params,
    dataType
  )
}

export const batterRankingRequest = (param, dataType) => createRequest(wiz.API.GET_BATTER_RANKING_LIST, param, dataType)
export const futuresBatterRankingRequest = (param, dataType) => createRequest(wiz.API.GET_FUTURES_BATTER_RANKING_LIST, param, dataType)


export const teamCrowdRankingRequest = (param, type) => createRequest(wiz.API.GET_TEAM_CROWD_RANKING_LIST, param, type)

export const teamRankingRequest = (param, type) => createRequest(wiz.API.GET_TEAM_RANKING_LIST, param, type)

export const teamPitchingRankingRequest = (param, type) => createRequest(wiz.API.GET_TEAM_PITCHING_RANKING_LIST, param, type)

export const teamBattingRankingRequest = (param, type) => createRequest(wiz.API.GET_TEAM_BATTING_RANKING_LIST, param, type)

export const teamWinLoseListRequest = (param, type) => createRequest(wiz.API.GET_TEAM_WINLOSE_LIST, param, type)

export const watchPointRequest = (param, type) => createRequest(wiz.API.GET_WATCH_POINT, param, type)

export const futuresTeamRankingRequest = (param, type) => createRequest(wiz.API.GET_FUTURES_TEAM_RANKING_LIST, param, type)

export const teamRankRequest = (param, type) => createRequest(wiz.API.GET_TEAM_RANK, param, type)

export const periodTeamRankRequest = (param, type) => createRequest(wiz.API.GET_PERIOD_TEAM_RANK, param, type)

export const periodMinorTeamRankRequest = (param, type) => createRequest(wiz.API.GET_PERIOD_MINOR_TEAM_RANK, param, type)

export const yearListRequest = (param, type) => createRequest(wiz.API.GET_YEARLIST, param, type)
