import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { resourceRequest, useResource } from '../../modules/resource/resource'
import * as dialogActions from "../../modules/common/dialog"

const WizparkGuide = () => {
  const PARK_GUIDE_IMAGE_PC = 'PARK_GUIDE_IMAGE_PC'
  const PARK_GUIDE_IMAGE_M = 'PARK_GUIDE_IMAGE_M'
  const [imagePc, errorPc] = useResource(PARK_GUIDE_IMAGE_PC)
  const [imageM, errorM] = useResource(PARK_GUIDE_IMAGE_M)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(resourceRequest(PARK_GUIDE_IMAGE_PC))
    dispatch(resourceRequest(PARK_GUIDE_IMAGE_M))
  }, [])

  useEffect(() => {
    if (errorPc && errorPc.message) {
      dispatch(dialogActions.alertOpen(errorPc.message))
    }
    if (errorM && errorM.message) {
      dispatch(dialogActions.alertOpen(errorM.message))
    }
  }, [errorPc, errorM])

  return (
    <>
      <article className='arti'>
        <div className='tit-img pc'>
          <img src={require('../../assets/img/park/wizpark_guide_title_img.png')} />
        </div>
        <div className='tit-img m'>
          <img src={require('../../assets/img/park/wizpark_guide_title_img_m.png')} />
        </div>
        <div className='desc-img'>
          <img className='img-whole pc' src={imagePc && imagePc.resource && imagePc.resource.value} alt="" />
          <img className='img-whole mobile' src={imageM && imageM.resource && imageM.resource.value} alt="" />
        </div>
      </article>
      {/* <img className='img-whole pc' src={imagePc && imagePc.resource && imagePc.resource.value} alt="" />
      <img className='img-whole mobile' src={imageM && imageM.resource && imageM.resource.value} alt="" /> */}
    </>
  )
}

export default WizparkGuide
