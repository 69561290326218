import React, { useState, useEffect } from "react";
import JoinStep from "./JoinStep";
import { useHistory } from "react-router-dom";

const JoinStep2 = ({ join, onApproveTerms }) => {
  const history = useHistory();

  const [agreeService, setAgreeService] = useState(false);
  const [agreeInfo, setAgreeInfo] = useState(false);
  const [agreeLocation, setAgreeLocation] = useState(false);
  const [agreeProvide, setAgreeProvide] = useState(false);
  const [agreeMarket, setAgreeMarket] = useState(false);
  const [agreeAll, setAgreeAll] = useState(false);

  const checkAll = (e) => {
    const checked = e.target.checked;

    setAgreeAll(checked);
    setAgreeService(checked);
    setAgreeInfo(checked);
    setAgreeLocation(checked);
    setAgreeProvide(checked);
    setAgreeMarket(checked);
  };

  useEffect(() => {
    if ((!agreeService || !agreeInfo || !agreeLocation || !agreeProvide || !agreeMarket) && agreeAll) {
      setAgreeAll(false);
    } else if (agreeService && agreeInfo && agreeLocation && agreeProvide && agreeMarket && !agreeAll) {
      setAgreeAll(true);
    }
  }, [agreeService, agreeInfo, agreeLocation, agreeProvide, agreeMarket]);

  const goStep1 = () => {
    history.push("/join");
  };

  const goStep2 = () => {
    const approveTerms = [];
    if (agreeService) {
      approveTerms.push({ termsKind: join.terms[1].termsKind, termsId: join.terms[1].termsId, isAgree: "Y" });
    }
    if (agreeInfo) {
      approveTerms.push({ termsKind: join.terms[2].termsKind, termsId: join.terms[2].termsId, isAgree: "Y" });
    }
    if (agreeLocation) {
      approveTerms.push({ termsKind: join.terms[3].termsKind, termsId: join.terms[3].termsId, isAgree: "Y" });
    }

    if (agreeProvide) {
      approveTerms.push({ termsKind: join.terms[4].termsKind, termsId: join.terms[4].termsId, isAgree: "Y" });
    } else if (!agreeProvide) {
      approveTerms.push({ termsKind: join.terms[4].termsKind, termsId: join.terms[4].termsId, isAgree: "N" });
    }

    if (agreeMarket) {
      approveTerms.push({ termsKind: join.terms[8].termsKind, termsId: join.terms[8].termsId, isAgree: "Y" });
    }
    if (!agreeMarket) {
      approveTerms.push({ termsKind: join.terms[8].termsKind, termsId: join.terms[8].termsId, isAgree: "N" });
    }

    console.log(approveTerms);
    onApproveTerms(approveTerms);
  };

  

  

  const gotoText=()=>{
    history.push({pathname:"/indilog",state:'personerLog'})
  }
  const goToThirdLog=()=>{
    history.push({pathname:'/indilog',state:'3rdPersonerLog'})
  }

  return (
    <>
      <JoinStep join={join} />
      {/* 이용약관 박스 */}
      <div className="group position_maker">
        <h4>서비스 이용약관</h4>
        <div className="agree_data" dangerouslySetInnerHTML={{ __html: join.terms[1].termsContents.replace(/\n/g, "<br/>").replace(/ /g, "&nbsp;") }}></div>
        {/* //.agree_data */}
        <div className="warning m_invisible">
          <p>이용약관에 대한 동의를 거부할 수 있으며, 미 동의 시 회원가입을 하실 수 없습니다.</p>
        </div>
        {/* //.warning */}
        <div className="agree_check position_maker m_invisible">
          <input type="checkbox" name="agree_service" value="Y" checked={agreeService} onChange={(e) => setAgreeService(e.target.checked)} />
          <label htmlFor="agree_service" className="ml5">
            kt wiz 이용약관에 동의합니다.
          </label>
        </div>
        {/* //.agree_check */}
        <div className="agree_check pc_invisible">
          <label htmlFor="agree_service">동의</label>
          <input type="checkbox" name="agree_service" value="Y" checked={agreeService} onChange={(e) => setAgreeService(e.target.checked)} />
        </div>
        {/* //.agree_check */}
      </div>
      {/* //.group */}

      <div className="group position_maker">
        <div className="indis_wrap">
          <h4>개인정보 수집·이용 동의</h4>
          <button onClick={gotoText} className="btn_movetext">전문보기</button>

        </div>
        <div className="agree_data" dangerouslySetInnerHTML={{ __html: join.terms[2].termsContents.replace(/\n/g, "<br/>").replace(/ /g, "&nbsp;") }}></div>
        {/* //.agree_data */}
        <div className="warning m_invisible">
          <p>이용약관에 대한 동의를 거부할 수 있으며, 미 동의 시 회원가입을 하실 수 없습니다.</p>
        </div>
        {/* //.warning */}
        <div className="agree_check position_maker m_invisible">
          <input type="checkbox" name="agree_info" value="Y" checked={agreeInfo} onChange={(e) => setAgreeInfo(e.target.checked)} />
          <label htmlFor="agree_info" className="ml5">
            개인정보 수집 및 이용에 동의합니다.
          </label>
        </div>
        {/* //.agree_check */}
        <div className="agree_check pc_invisible">
          <label htmlFor="agree_info">동의</label>
          <input type="checkbox" name="agree_info" value="Y" checked={agreeInfo} onChange={(e) => setAgreeInfo(e.target.checked)} />
        </div>
        {/* //.agree_check */}
      </div>
      {/* //.group */}

      <div className="group position_maker">
        <h4>위치정보 이용약관</h4>
        <div className="agree_data" dangerouslySetInnerHTML={{ __html: join.terms[3].termsContents.replace(/\n/g, "<br/>").replace(/ /g, "&nbsp;") }}></div>
        <div className="warning m_invisible">
          <p>이용약관에 대한 동의를 거부할 수 있으며, 미 동의 시 회원가입을 하실 수 없습니다.</p>
        </div>
        <div className="agree_check position_maker m_invisible">
          <input type="checkbox" id="agree_loca" name="agree_loca" value="Y" checked={agreeLocation} onChange={(e) => setAgreeLocation(e.target.checked)} />
          <label htmlFor="agree_loca" className="ml5">
            위치정보 이용에 동의합니다.
          </label>
        </div>
        <div className="agree_check pc_invisible">
          <input type="checkbox" id="agree_loca" name="agree_loca" value="Y" checked={agreeLocation} onChange={(e) => setAgreeLocation(e.target.checked)} />
          <label htmlFor="agree_loca" className="ml5">
            동의
          </label>
        </div>
      </div>

      <div className="group position_maker">
      <div className="indis_wrap">
        <h4>
          개인정보 제 3자 제공에 대한 안내<span className="font_c">[선택]</span>
        </h4>
        <button className="btn_movetext"  onClick={goToThirdLog}>전문보기</button>
      </div>
        <div className="agree_data" dangerouslySetInnerHTML={{ __html: join.terms[4].termsContents.replace(/\n/g, "<br/>").replace(/ /g, "&nbsp;") }}></div>
        <div className="warning">
          <p>제3자 정보 제공 동의를 거부할 권리가 있으며, 동의를 거부하더라도 회원가입 및 서비스 이용이 가능합니다.</p>
        </div>
        {/* //.warning */}
        <div className="agree_check position_maker m_invisible">
          <input type="radio" name="agree_provide" value="Y" checked={agreeProvide} onChange={(e) => setAgreeProvide(e.target.checked === true)} />
          <label htmlFor="agree_provide" className="ml5">
            개인정보 제 3자 제공에 동의합니다.
          </label>
          <input type="radio" name="agree_provide" value="N" className="ml20" checked={!agreeProvide} onChange={(e) => setAgreeProvide(e.target.checked === false)} />
          <label htmlFor="disagree_provide" className="ml5">
            동의하지 않습니다.
          </label>
        </div>
        {/* //.agree_check */}
        <div className="agree_check pc_invisible">
          <label htmlFor="agree_provide">동의</label>
          <input type="checkbox" name="agree_provide" value="Y" checked={agreeProvide} onChange={(e) => setAgreeProvide(e.target.checked)} />
        </div>
        {/* //.agree_check */}
      </div>
      {/* //.group */}

      <div className="group position_maker marketing">
        <h4>
          마케팅 및 광고 활용 동의에 대한 안내<span className="font_c">[선택]</span>
        </h4>
        <div className="agree_data" dangerouslySetInnerHTML={{ __html: join.terms[8] && join.terms[8].termsContents.replace(/\n/g, "<br/>").replace(/ /g, "&nbsp;") }}></div>
        {/* //.agree_data */}
        <div className="warning">
          <p>
            마케팅 활용을 위한 개인정보 수집 이용 안내에 대한 동의를 거부할 권리가 있으며, 동의를 거부하더라도 회원가입 및 서비스 이용이 가능합니다. <br /> 미 동의시 kt sports 주식회사에서 주요 행사 안내 및 혜택 제공에 제한이 있을 수 있습니다.
          </p>
        </div>
        {/* //.warning */}

        <div className="agree_check position_maker m_invisible">
          <input type="radio" id="agree_market" name="agree_market" value="y" checked={agreeMarket} onChange={(e) => setAgreeMarket(e.target.checked === true)} />
          <label htmlFor="agree_market" className="ml5">
            개인정보 마케팅 및 광고 활용에 동의 합니다.
          </label>
          <input type="radio" id="disagree_market" name="agree_market" value="n" className="ml20" checked={!agreeMarket} onChange={(e) => setAgreeMarket(e.target.checked === false)} />
          <label htmlFor="disagree_market" className="ml5">
            동의하지 않습니다.
          </label>
        </div>
        <div className="agree_check pc_invisible">
          <label htmlFor="agree_market">동의</label>
          <input type="checkbox" name="agree_market" value="Y" checked={agreeMarket} onChange={(e) => setAgreeMarket(e.target.checked)} />
        </div>
        {/* //.agree_check */}
      </div>
      {/* //.group */}

      {/* 전체동의 체크박스 */}
      <div className="agree_all_check">
        <input type="checkbox" name="agree_all" value="Y" checked={agreeAll} onChange={checkAll} />
        <label htmlFor="agree_all" className="ml5">
          이용약관, 개인정보 수집 및 이용에 모두 동의합니다.
        </label>
      </div>
      {/* //.agree_all_check */}

      {/* 동의 버튼 */}
      <div className="btn_wrap clearfix">
        <button type="button" className={"confirm float_l " + (agreeService && agreeInfo && agreeLocation ? "active" : "")} disabled={!(agreeService && agreeInfo && agreeLocation)} onClick={goStep2}>
          동의합니다.
        </button>
        {/* 클래스 active 붙었을 때 버튼 색 바뀝니다 */} {/* 동의 시 sign_up_step2.html로 이동 */}
        <button className="float_r" onClick={() => goStep1()}>
          동의하지 않습니다.
        </button>
        {/* 미동의 시<p>kt sports 주식회사에서 제공하는 서비스 안내 등 광고성 정보를 받으시려면 마케팅 목적 이용에 동의하여 주시기 바랍니다.</p> 이전 서비스화면으로 이동 */}
      </div>
      {/* btn_wrap */}
      {/* //.btn_container */}
    </>
  );
};

export default JoinStep2;
