import React, { useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import ContentTemplate from '../../components/common/ContentTemplate'
import SubContentTemplate from '../../components/common/SubContentTemplate'
import PageHeaderContainer from '../../containers/common/PageHeaderContainer'
import PageTitleTemplate from '../../components/common/PageTitleTemplate'
import BreadcrumbContainer from '../../containers/common/BreadcrumbContainer'
import PageTemplate from '../../components/common/PageTemplate'
import SecondContentTemplate from '../../components/common/SecondContentTemplate'
import PlayerSearch from '../../components/player/PlayerSearch'
import ContentSwitchingTab from '../../components/common/ContentSwitchingTab'
import {
  PLATER_HITTER, PLAYER_CATCHER, PLAYER_PITCHER, PLAYER_COACHING_STEP, PLAYER_CHEER_SQUAD, PLAYER_CHEER_SONG, PLAYER_INFIELDER, PLAYER_OUTFIELDER,
  PLAYER_CATCHER_DETAIL, PLAYER_PITCHER_DETAIL, PLAYER_COACHING_STEP_DETAIL, PLAYER_CHEER_SQUAD_DETAIL, PLAYER_INFIELDER_DETAIL, PLAYER_OUTFIELDER_DETAIL, PLAYER_CHEER_SONG_COPYRIGHT
} from '../../constants/page'
import PlayerList from '../../components/player/PlayerList'
import Cheer from '../../components/player/cheer'
import Song from '../../components/player/song'
import CoachDetail from '../../components/player/coach/CoachDetail'
import PitcherDetail from '../../components/player/pitcher/PitcherDetail'
import PitcherDetailSecond from '../../components/player/pitcher/PitcherDetailSecond'
import BatterDetail from '../../components/player/batter/BatterDetail'
import BatterDetailSecond from '../../components/player/batter/BatterDetailSecond'
import CheerDetail from '../../components/player/cheer/CheerDetail'
import Prepairing from '../../components/common/Prepairing'
import SongCopyright from './../../components/player/song/SongCopyright';

// 중메뉴
const tabs = [
  { path: PLAYER_COACHING_STEP.path, title: PLAYER_COACHING_STEP.title, type: 'coach', page: PLAYER_COACHING_STEP, subtitle: '최고의 kt wiz 코칭스탭을 소개합니다.', contentsClass: 'player_staff', contentsDetailClass: 'player_staff_detail' },
  { path: PLAYER_PITCHER.path, title: PLAYER_PITCHER.title, type: 'pitcher', page: PLAYER_PITCHER, subtitle: 'kt wiz의 자랑스런 ‘첫 번째 선수단’을 소개합니다.', contentsClass: 'player_pitcher', contentsDetailClass: 'player_pitcher_detail' },
  { path: PLAYER_CATCHER.path, title: PLATER_HITTER.title, type: 'catcher', page: PLAYER_CATCHER, subtitle: 'kt wiz의 자랑스런 ‘첫 번째 선수단’을 소개합니다.', contentsClass: 'player_catcher', contentsDetailClass: 'player_catcher_detail' },
  { path: PLAYER_CHEER_SQUAD.path, title: PLAYER_CHEER_SQUAD.title, type: 'cheer', page: PLAYER_CHEER_SQUAD, subtitle: 'kt wiz꽃! kt wiz의 응원단', contentsClass: 'player_cheer', contentsDetailClass: 'player_cheer_detail' },
  { path: PLAYER_CHEER_SONG.path, title: PLAYER_CHEER_SONG.title, type: 'song', page: PLAYER_CHEER_SONG, subtitle: '팬이라면 꼭 알아두어야 할 kt wiz 응원가를 소개합니다.', contentsClass: 'player_song', contentsDetailClass: '' },
  { path: PLAYER_CHEER_SONG_COPYRIGHT.path, title: PLAYER_CHEER_SONG_COPYRIGHT.title, type: 'song-copyright', page: PLAYER_CHEER_SONG_COPYRIGHT, subtitle: '', contentsClass: 'player_song_copyright', contentsDetailClass: '' },
]

// 소메뉴
const subTabs = [
  { path: PLAYER_CATCHER.path, title: PLAYER_CATCHER.title, type: 'catcher', page: PLAYER_CATCHER },
  { path: PLAYER_INFIELDER.path, title: PLAYER_INFIELDER.title, type: 'infielder', page: PLAYER_INFIELDER },
  { path: PLAYER_OUTFIELDER.path, title: PLAYER_OUTFIELDER.title, type: 'outfielder', page: PLAYER_OUTFIELDER },
]

// 콘텐츠메뉴
const songContentTabs = [
  { type: 'team', title: '팀 응원가' },
  { type: 'player', title: '선수별 응원가' }
]

const batterContentTabs = [
  { type: 'catcher', title: PLAYER_CATCHER.title, path: PLAYER_CATCHER.path },
  { type: 'infielder', title: PLAYER_INFIELDER.title, path: PLAYER_INFIELDER.path },
  { type: 'outfielder', title: PLAYER_OUTFIELDER.title, path: PLAYER_OUTFIELDER.path },
]

const secondContentTabs = [
  { type: 'regular_league', title: '정규리그 기록' },
  { type: 'latest_game', title: '최근 5경기' },
  { type: 'total', title: '통산 기록' }
]

const albumTypes = [PLAYER_COACHING_STEP.path, PLAYER_PITCHER.path, PLAYER_CATCHER.path, PLAYER_INFIELDER.path, PLAYER_OUTFIELDER.path]
const slideTypes = [PLAYER_CHEER_SQUAD.path]
const audioListTypes = [PLAYER_CHEER_SONG.path]
const copyrightTypes = [PLAYER_CHEER_SONG_COPYRIGHT.path]

const PlayerPage = ({ location }) => {

  const detailMatch = useRouteMatch('/player/:type/detail')

  // const currentPath = (location.pathname === PLAYER_INFIELDER.path || location.pathname === PLAYER_OUTFIELDER.path) ? PLAYER_CATCHER.path : location.pathname
  let currentPath = location.pathname

  if (detailMatch) { currentPath = location.pathname.replace('/detail', '') }
  if (currentPath === PLAYER_INFIELDER.path || currentPath === PLAYER_OUTFIELDER.path) { currentPath = PLAYER_CATCHER.path }
  const currentSubPath = subTabs.some((v) => v.path === location.pathname.replace('/detail', '')) && location.pathname.replace('/detail', '')
  const currentDetailPath = (detailMatch) && location.pathname

  const [currentContentType, setCurrentContentType] = useState('team')
  const [currentSecondContentType, setCurrentSecondContentType] = useState(secondContentTabs[0].type)

  const currentTabIdx = tabs.indexOf(tabs.filter(x => x.path === currentPath)[0])
  const currentSubTabIdx = subTabs.indexOf(subTabs.filter(x => x.path === currentSubPath)[0])

  const [playerName, setPlayerName] = useState('')
  const [searchPlayerName, setSearchPlayerName] = useState('')

  const [selectedGyear, setSelectedGyear] = useState(new Date().getFullYear())

  const onChangePlayerName = e => { setPlayerName(e.target.value) }
  const onClickSearchPlayerName = () => { setSearchPlayerName(playerName) }
  const onClickContentTab = (type) => { setCurrentContentType(type) }
  const onClickSecondContentTab = (type) => { setCurrentSecondContentType(type) }
  const onSearchYear = searchYear => { setSelectedGyear(searchYear) }

  const isPrePage = process.env.REACT_APP_IS_PREPAIRING_PAGE

  return (
    <PageTemplate className='player' isTop={true}>
      <PageHeaderContainer
        title={tabs[currentTabIdx].title}
        subtitle={tabs[currentTabIdx].subtitle}
        image={require('../../assets/img/player/sub_tit_player.png')}
        forwardTabs={currentPath && tabs}
        forwardSubtabs={currentSubPath && subTabs}
        currentPath={currentPath && currentPath}
        currentSubPath={currentSubPath && currentSubPath}
      />

      {
        !currentDetailPath &&
        <>
          <ContentTemplate className={tabs[currentTabIdx].contentsClass}>
            <PageTitleTemplate>
              <BreadcrumbContainer page={currentSubPath ? subTabs[currentSubTabIdx].page : tabs[currentTabIdx].page} />
              {
                //isPrePage === "Y" &&
                <>
                  {
                    (currentPath === PLAYER_CHEER_SQUAD.path || currentPath === PLAYER_CHEER_SONG.path || currentPath === PLAYER_CHEER_SONG_COPYRIGHT.path) ||
                    <PlayerSearch onChangeName={onChangePlayerName} onClickSearchName={onClickSearchPlayerName} />
                  }

                  {
                    (currentPath === PLAYER_CHEER_SONG.path) &&
                    <ContentSwitchingTab className='orderby_tab_wrap' tabs={songContentTabs} currentContentType={currentContentType} onClickContentTab={onClickContentTab} />
                  }
                  {
                    (currentPath === PLAYER_CATCHER.path
                      || currentPath === PLAYER_INFIELDER.path
                      || currentPath === PLAYER_OUTFIELDER.path) &&
                    <ContentSwitchingTab className='orderby_tab_wrap' tabs={batterContentTabs} currentContentType={currentContentType} onClickContentTab={onClickContentTab} />
                  }

                </>
              }

            </PageTitleTemplate>
            {
              isPrePage === "Y" ?
                (< Prepairing />) :
                (
                  <SubContentTemplate>
                    {
                      albumTypes.some(v => v === (currentSubPath ? currentSubPath : currentPath)) &&
                      <PlayerList searchPlayerName={searchPlayerName} type={currentSubPath ? subTabs[currentSubTabIdx].type : tabs[currentTabIdx].type} />
                    }
                    {
                      slideTypes.some(v => v === (currentSubPath ? currentSubPath : currentPath)) &&
                      //< Prepairing />
                      <Cheer type={tabs[currentTabIdx].type} />
                    }
                    {
                      audioListTypes.some(v => v === (currentSubPath ? currentSubPath : currentPath)) &&
                      <Song type={tabs[currentTabIdx].type} currentContentType={currentContentType} />
                    }
                    {copyrightTypes.some(v => v === (currentSubPath ? currentSubPath : currentPath)) && <SongCopyright />}
                  </SubContentTemplate>
                )

            }
          </ContentTemplate>
        </>
      }

      {
        currentDetailPath &&
        <>
          <ContentTemplate className={tabs[currentTabIdx].contentsDetailClass}>
            <PageTitleTemplate>
              <BreadcrumbContainer page={currentSubPath ? subTabs[currentSubTabIdx].page : tabs[currentTabIdx].page} />
            </PageTitleTemplate>
            <SubContentTemplate>
              {
                currentDetailPath === PLAYER_COACHING_STEP_DETAIL.path &&
                <CoachDetail searchPlayerName={searchPlayerName}
                  type={currentSubPath ? `${subTabs[currentSubTabIdx].type}Detail` : `${tabs[currentTabIdx].type}Detail`} />
              }
              {
                currentDetailPath === PLAYER_PITCHER_DETAIL.path &&
                <PitcherDetail searchPlayerName={searchPlayerName}
                  type={currentSubPath ? `${subTabs[currentSubTabIdx].type}Detail` : `${tabs[currentTabIdx].type}Detail`}
                  selectedGyear={selectedGyear} />
              }
              {
                (currentDetailPath === PLAYER_CATCHER_DETAIL.path
                  || currentDetailPath === PLAYER_INFIELDER_DETAIL.path
                  || currentDetailPath === PLAYER_OUTFIELDER_DETAIL.path) &&
                <BatterDetail searchPlayerName={searchPlayerName}
                  type={currentSubPath ? `${subTabs[currentSubTabIdx].type}Detail` : `${tabs[currentTabIdx].type}Detail`}
                  selectedGyear={selectedGyear} />
              }
              {
                currentDetailPath === PLAYER_CHEER_SQUAD_DETAIL.path &&
                <CheerDetail serahcPlayerName={searchPlayerName}
                  type={currentSubPath ? `${subTabs[currentSubTabIdx].type}Detail` : `${tabs[currentTabIdx].type}Detail`} />
              }
              {

              }
            </SubContentTemplate>
          </ContentTemplate>

          {
            (currentDetailPath === PLAYER_PITCHER_DETAIL.path
              || currentDetailPath === PLAYER_CATCHER_DETAIL.path
              || currentDetailPath === PLAYER_INFIELDER_DETAIL.path
              || currentDetailPath === PLAYER_OUTFIELDER_DETAIL.path) &&
            <SecondContentTemplate className='player'>
              <ContentSwitchingTab className='detail_lnb_wrap' tabs={secondContentTabs} currentContentType={currentSecondContentType} onClickContentTab={onClickSecondContentTab} onSearchYear={onSearchYear} />

              {
                (currentDetailPath === PLAYER_PITCHER_DETAIL.path) &&
                <PitcherDetailSecond type={currentSubPath ? `${subTabs[currentSubTabIdx].type}Detail` : `${tabs[currentTabIdx].type}Detail`}
                  contentType={currentSecondContentType} selectedGyear={selectedGyear} />
              }

              {
                (currentDetailPath === PLAYER_CATCHER_DETAIL.path
                  || currentDetailPath === PLAYER_INFIELDER_DETAIL.path
                  || currentDetailPath === PLAYER_OUTFIELDER_DETAIL.path) &&
                <BatterDetailSecond type={currentSubPath ? `${subTabs[currentSubTabIdx].type}Detail` : `${tabs[currentTabIdx].type}Detail`}
                  contentType={currentSecondContentType} selectedGyear={selectedGyear} />
              }

            </SecondContentTemplate>
          }

        </>
      }

    </PageTemplate>
  )
}

export default PlayerPage