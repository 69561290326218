import ContentTemplate from 'components/common/ContentTemplate';
import PageTemplate from 'components/common/PageTemplate';
import React from 'react'
import { useState } from 'react';
import FullTextLog from '../FullTextLog';

const MobileTermsMenu = () => {
    const [mobileTerms, setMobileTerms] = useState("mobilePrivacyPolicyMenu");
  
    const onClickBtn = (mobileTermsStringValue) => {
      setMobileTerms(mobileTermsStringValue);
    };
  
    return (
      <PageTemplate>
        <ContentTemplate className="sign_up_step1">
          {mobileTerms === "mobilePrivacyPolicyMenu" && (
            <div>
              <h1 className="indi_title">개인정보 수집·이용 동의</h1>
              <button className="btn_textlog" onClick={() => onClickBtn("20210506")}>
                <span className="btn_title">1차 개인정보 수집·이용 동의</span>{" "}
                <span className="btn_subtitle">(2021.05.06~2021.12.07)</span>
              </button>
              <button className="btn_textlog" onClick={() => onClickBtn("20211207")}>
                <span className="btn_title">2차 개인정보 수집·이용 동의</span>{" "}
                <span className="btn_subtitle">(2021.12.07~2023.01.19)</span>
              </button>
            </div>
          )}
          {mobileTerms === "20210506" && (
            <>
              <FullTextLog fullDateString={"20210506"} />
              <button className="btn_textlog" onClick={() => onClickBtn("mobilePrivacyPolicyMenu")}>
                개인정보 수집·이용 동의 확인
              </button>
            </>
          )}
          {mobileTerms === "20211207" && (
            <>
              <FullTextLog fullDateString={"20211207"} />
              <button className="btn_textlog" onClick={() => onClickBtn("mobilePrivacyPolicyMenu")}>
                개인정보 수집·이용 동의 확인
              </button>
            </>
          )}
        </ContentTemplate>
      </PageTemplate>
    )
  }

export default MobileTermsMenu