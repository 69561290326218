import client from "../client";

export const getMyInfo = () => {
  return client.get(`/api/v2/user/info`);
};

export const getDonMileInfo = (memberId) => {
  return client.get(`/api/v2/user/donMileInfo?memberId=` + memberId);
};

export const getProfile = (memberId) => {
  return client.get(`/api/v2/wizland/profile?memberId=` + memberId);
};

export const getMyInfoTerms = () => {
  return client.get(`/api/v2/user/terms/detail`);
};

export const updateSelectedTerms = (terms) => {
  const params = new URLSearchParams();

  terms.forEach((item, idx) => {
    params.append("terms[" + idx + "].termsId", item.termsId);
    params.append("terms[" + idx + "].termsKind", item.termsKind);
    params.append("terms[" + idx + "].isAgree", item.isAgree);
  });

  return client.post(`/api/v2/user/terms/detail/update`, params);
};

export const updateUserInfo = (user) => {
  const params = new URLSearchParams();
  params.append("user.simpleId", user.simpleId);
  params.append("user.memberId", user.memberId);
  params.append("user.mobpNo", user.mobpNo1 + "-" + user.mobpNo2 + "-" + user.mobpNo3);
  params.append("user.mobpNo1", user.mobpNo1);
  params.append("user.mobpNo2", user.mobpNo2);
  params.append("user.mobpNo3", user.mobpNo3);
  params.append("user.memberEmail", user.memberEmail);
  params.append("user.isAdult", user.isAdult ? "Y" : "N");
  params.append("user.postNo", user.postNo);
  params.append("user.addr1", user.addr1);
  params.append("user.addr2", user.addr2);
  params.append("user.smsYn", user.smsYn);
  params.append("user.emailYn", user.emailYn);
  if (user.memberPw && user.memberNewPw) {
    params.append("user.memberPw", user.memberPw);
    params.append("user.memberNewPw", user.memberNewPw);
  }

  return client.post(`/api/v2/user/info/update`, params);
};

export const updatePassword = (user) => {
  const params = new URLSearchParams();
  params.append("user.simpleId", user.simpleId);
  params.append("user.memberNewPw", user.memberNewPw);

  return client.post(`/api/v2/wizland/pw/change`, params);
};

export const withdraw = (withdraw) => {
  const params = new URLSearchParams();
  params.append("withdraw.type", withdraw.type);
  params.append("withdraw.reason", withdraw.reason);

  return client.post(`/api/v2/user/delete`);
};

export const checkPassword = (user) => {
  const params = new URLSearchParams();
  params.append("user.simpleId", user.simpleId);
  params.append("user.memberPw", user.memberPw);

  return client.post(`/api/v2/auth/password/check`, params);
};
