import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

const GraphWrap = ({ match, xAxisList, valueList }) => {

  Highcharts.setOptions({
    lang: {
      thousandsSep: ','
    }
  })

  // 팀순위 그래프 피씨버전
  const options1 = {
    colors: ["#ec0a0b"],
    subtitle: { style: { color: '#222' } },
    credits: { enabled: false },
    title: { text: '', style: { color: '#222', fontSize: '24px', fontWeight: '' } },
    legend: { enabled: false, itemStyle: { fontWeight: 'bold', fontSize: '14px' } },
    chart: { marginLeft: 70, marginRight: 40, marginTop: 80, marginBottom: 60, backgroundColor: "#fff" },
    xAxis: { categories: xAxisList, labels: { style: { color: '#5b5a5a' }, rotation: -45, align: 'right' } },
    yAxis: { title: { text: '', rotation: 0 }, labels: { style: { color: '#5b5a5a' }, formatter: function () { return this.value + '위' } }, reversed: true, allowDecimals: false, min: 1, max: 10, tickInterval: 1, plotLines: [{ value: 0, width: 1, color: '#808080' }], showFirstLabel: true, showLastLabel: true },
    tooltip: {
      useHTML: true,
      backgroundColor: null,
      borderWidth: 0,
      shadow: false,
      headerFormat: '{point.x}<br />',
      pointFormat: 'KT WIZ : {point.y}위',
      style: { fontSize: '14px', color: '#fff' }
    },
    series: [
      { name: 'KT WIZ ', data: valueList }
    ]
  }

  // 팀순위 그래프 모바일버전
  const options2 = {
    colors: ["#ec0a0b"],
    subtitle: { style: { color: '#222' } },
    credits: { enabled: false },
    title: { text: '', style: { color: '#222', fontSize: '24px', fontWeight: '' } },
    legend: { enabled: false, itemStyle: { fontWeight: 'bold', fontSize: '14px' } },
    chart: { marginLeft: 60, marginRight: 40, marginTop: 65, marginBottom: 50, height: 200, backgroundColor: "#fff" },
    xAxis: { categories: xAxisList, labels: { style: { color: '#5b5a5a' }, align: 'center' } },
    yAxis: { title: { text: '', rotation: 0 }, labels: { style: { color: '#5b5a5a' }, formatter: function () { return this.value + '위' } }, reversed: true, allowDecimals: false, min: 1, max: 10, tickInterval: 1, plotLines: [{ value: 0, width: 1, color: '#808080' }], showFirstLabel: true, showLastLabel: true },
    tooltip: {
      useHTML: true,
      backgroundColor: null,
      borderWidth: 0,
      shadow: false,
      headerFormat: '{point.x}<br />',
      pointFormat: 'KT WIZ : {point.y}위',
      style: { fontSize: '14px', color: '#fff' }
    },
    series: [
      { name: 'KT WIZ ', data: valueList }
    ]
  }

  // 관중현황 그래프 피씨버전
  const options3 = {
    colors: ["#e60013", "#3f4046", "#3f4046", "#3f4046", "#3f4046", "#3f4046", "#3f4046", "#3f4046", "#3f4046", "#3f4046"],
    chart: { type: 'column', marginLeft: 95, marginRight: 30, marginTop: 80, marginBottom: 50, height: 350, backgroundColor: "#ffffff" },
    credits: { enabled: false },
    title: { text: '', style: { color: 'black', fontSize: '24px', fontWeight: '' } },
    subtitle: { style: { color: 'black' } },
    legend: { enabled: false, itemStyle: { fontWeight: 'bold', fontSize: '14px' } },
    xAxis: { categories: xAxisList, labels: { style: { color: '#6e6e70' } } },
    yAxis: { title: { text: '', rotation: 0 }, labels: { style: { color: '#6e6e70' }, format: '{value:,.0f}' }, reversed: false, allowDecimals: false, min: 0, minTickInterval: 1, tickInterval: 200000 },
    tooltip: {
      useHTML: true,
      backgroundColor: null,
      borderWidth: 0,
      shadow: false,
      headerFormat: '<b>{point.x}</b><br />',
      pointFormat: '누적관중 : {point.y}명',
      style: { fontSize: '10px', color: '#fff' }
    },
    plotOptions: {
      series: {
        pointWidth: 16
      }
    },
    series: [
      { name: '누적관중 ', colorByPoint: true, data: valueList }
    ]
  }

  // 관중현황 그래프 모바일버전
  const options4 = {
    colors: ["#e60013", "#3f4046", "#3f4046", "#3f4046", "#3f4046", "#3f4046", "#3f4046", "#3f4046", "#3f4046", "#3f4046"],
    chart: { type: 'column', marginLeft: 85, marginRight: 30, marginTop: 50, marginBottom: 40, height: 250, backgroundColor: "#ffffff" },
    credits: { enabled: false },
    title: { text: '', style: { color: 'black', fontSize: '24px', fontWeight: '' } },
    subtitle: { style: { color: 'black' } },
    legend: { enabled: false, itemStyle: { fontWeight: 'bold', fontSize: '14px' } },
    xAxis: { categories: xAxisList, labels: { style: { color: '#6e6e70' } } },
    yAxis: { title: { text: '', rotation: 0 }, labels: { style: { color: '#6e6e70' }, format: '{value:,.0f}' }, reversed: false, allowDecimals: false, min: 0, minTickInterval: 1, tickInterval: 500000 },
    tooltip: {
      useHTML: true,
      backgroundColor: null,
      borderWidth: 0,
      shadow: false,
      headerFormat: '<b>{point.x}</b><br />',
      pointFormat: '누적관중 :<br />{point.y}명',
      style: { fontSize: '10px', color: '#fff' }
    },
    plotOptions: {
      series: {
        pointWidth: 10
      }
    },
    series: [
      { name: '누적관중 ', colorByPoint: true, data: valueList }
    ]
  }

  const matchArray = match.url.split('/');
  const length = matchArray.length;
  const pageName = matchArray[length - 1];

  // console.log(`Highchars`, Highcharts)

  return (
    <div className="graph_wrap">
      <div className="graph pc"><HighchartsReact highcharts={Highcharts} options={pageName === "team" ? options1 : options3}></HighchartsReact></div>
      <div className="graph mobile"><HighchartsReact highcharts={Highcharts} options={pageName === "team" ? options2 : options4}></HighchartsReact></div>
    </div>
  )
}

export default GraphWrap
