import React from 'react'
import HomeContainer from '../containers/HomeContainer'

const HomePage = ({ history }) => {

  return (
    <HomeContainer />
  )
}

export default HomePage
