import React, { useState, useEffect } from 'react'
import FindIdMobile from './FindIdMobile'
import FindIdEmail from './FindIdEmail'
import * as yup from 'yup'
import * as Enum from '../../../constants/enum'

let mobilCertSchema = yup.object().shape({
  mobileNum1: yup.string().min(3).max(3).required(),
  mobileNum2: yup.string().min(3).max(4).required(),
  mobileNum3: yup.string().min(4).max(4).required()
})

let mobilFindSchema = yup.object().shape({
  mobileNum1: yup.string().min(3).max(3).required(),
  mobileNum2: yup.string().min(3).max(4).required(),
  mobileNum3: yup.string().min(4).max(4).required(),
  isMobileCert: yup.boolean().oneOf([true]),
  mobileCertNo: yup.string().required()
})

let emailCertSchema = yup.object().shape({
  email1: yup.string().required(),
  email2: yup.string().required(),
})

let emailFindSchema = yup.object().shape({
  email1: yup.string().required(),
  email2: yup.string().required(),
  isEmailCert: yup.boolean().oneOf([true]),
  emailCertNo: yup.string().required()
})

const FindId = ({ cert, onCertReset, onCertRequest, onCertConfirmRequest, onFindIdRequest }) => {

  const [findType, setFindType] = useState(Enum.FIND_TYPES.FIND_ID_MOBILE)
  const [mobileNum1, setMobileNum1] = useState(Enum.MOBILE_FRONT_NUMS[0])
  const [mobileNum2, setMobileNum2] = useState('')
  const [mobileNum3, setMobileNum3] = useState('')
  const [email1, setEmail1] = useState('')
  const [email2, setEmail2] = useState('')
  const [mobileCertNo, setMobileCertNo] = useState('')
  const [emailCertNo, setEmailCertNo] = useState('')
  const [isMobileCert, setIsMobileCert] = useState(false)
  const [isEmailCert, setIsEmailCert] = useState(false)
  const [isDisableMobileCertButton, setIsDisableMobileCertButton] = useState(false)
  const [isDisableEmailCertButton, setIsDisableEmailCertButton] = useState(false)
  const [isDisableMobileFindButton, setIsDisableMobileFindButton] = useState(false)
  const [isDisableEmailFindButton, setIsDisableEmailFindButton] = useState(false)

  useEffect(() => {
    setIsMobileCert(cert.certSeq !== null)
    setIsEmailCert(cert.certSeq !== null)
  }, [cert])

  let mobileCert = {
    reqType: Enum.CERT_REQ_TYPES.FIND_ID,
    certType: Enum.CERT_TYPES.MOBILE,
    mobpNo: `${mobileNum1}-${mobileNum2}-${mobileNum3}`,
    certSeq: cert.certSeq,
    certNo: mobileCertNo
  }

  let emailCert = {
    reqType: Enum.CERT_REQ_TYPES.FIND_ID,
    certType: Enum.CERT_TYPES.EMAIL,
    memberEmail: `${email1}@${email2}`,
    certSeq: cert.certSeq,
    certNo: emailCertNo
  }

  const onChangeFindType = e => {
    onCertReset()
    setFindType(e.target.value)
  }

  const onChangeMobileNum1 = e => { setMobileNum1(e.target.value) }
  const onChangeMobileNum2 = e => { setMobileNum2(e.target.value) }
  const onChangeMobileNum3 = e => { setMobileNum3(e.target.value) }
  const onChangeEmail1 = e => { setEmail1(e.target.value) }
  const onChangeEmail2 = e => { setEmail2(e.target.value) }
  const onChangeEmailServerName = e => { setEmail2(e.target.value === '0' ? '' : e.target.value) }
  const onChangeMobileCertNo = e => { setMobileCertNo(e.target.value) }
  const onChangeEmailCertNo = e => { setEmailCertNo(e.target.value) }

  const findTypeState = (findType) => {

    switch (findType) {
      case Enum.FIND_TYPES.FIND_ID_MOBILE:
        return <FindIdMobile
          mobileFrontNums={Enum.MOBILE_FRONT_NUMS}
          onChangeMobileNum1={onChangeMobileNum1}
          onChangeMobileNum2={onChangeMobileNum2}
          onChangeMobileNum3={onChangeMobileNum3}
          onChangeMobileCertNo={onChangeMobileCertNo}
          isDisableMobileCertButton={isDisableMobileCertButton}
          isDisableMobileFindButton={isDisableMobileFindButton}
          onCertRequest={onCertRequest}
          mobileCert={mobileCert}
          onFindIdRequest={onFindIdRequest} />
      case Enum.FIND_TYPES.FIND_ID_EMAIL:
        return <FindIdEmail
          emailServerNames={Enum.EMAIL_SERVER_NAMES}
          email2={email2}
          onChangeEmail1={onChangeEmail1}
          onChangeEmail2={onChangeEmail2}
          onChangeEmailServerName={onChangeEmailServerName}
          onChangeEmailCertNo={onChangeEmailCertNo}
          isDisableEmailCertButton={isDisableEmailCertButton}
          isDisableEmailFindButton={isDisableEmailFindButton}
          onCertRequest={onCertRequest}
          emailCert={emailCert}
          onCertConfirmRequest={onCertConfirmRequest}
          onFindIdRequest={onFindIdRequest} />
      default:
        return <></>
    }

  }

  /* 휴대폰 인증요청 버튼 유효성 검사 */
  mobilCertSchema.isValid({
    mobileNum1: mobileNum1,
    mobileNum2: mobileNum2,
    mobileNum3: mobileNum3
  }).catch(error => {

  }).then(response => {
    response ? setIsDisableMobileCertButton(false) : setIsDisableMobileCertButton(true)
  })

  /* 휴대폰 아이디 찾기 버튼 유효성 검사 */
  mobilFindSchema.isValid({
    mobileNum1: mobileNum1,
    mobileNum2: mobileNum2,
    mobileNum3: mobileNum3,
    isMobileCert: isMobileCert,
    mobileCertNo: mobileCertNo
  }).catch(error => {

  }).then(reponse => {
    reponse ? setIsDisableMobileFindButton(false) : setIsDisableMobileFindButton(true)
  })

  /* 이메일 인증요청 버튼 유효성 검사 */
  emailCertSchema.isValid({
    email1: email1,
    email2: email2
  }).catch(error => {

  }).then(response => {
    response ? setIsDisableEmailCertButton(false) : setIsDisableEmailCertButton(true)
  })

  /* 이메일 아이디 찾기 버튼 유효성 검사 */
  emailFindSchema.isValid({
    email1: email1,
    email2: email2,
    isEmailCert: isEmailCert,
    emailCertNo: emailCertNo
  }).catch(error => {

  }).then(response => {
    response ? setIsDisableEmailFindButton(false) : setIsDisableEmailFindButton(true)
  })

  return (
    <>

      <div className="content_box find_by find_id">
        {/*상단 설명*/}
        <div className="group group1">
          <div className="content_desc">
            <p>가입 당시 등록한 정보를 통해 아이디를<br />찾을 수 있습니다. 휴대폰 또는 이메일정보 중<br />한가지를 선택해주세요.</p>
          </div>
          <div className="radio_wrap clearfix" onChange={onChangeFindType}>
            <label htmlFor="find_id_mobile"><input type="radio" id="find_id_mobile" value={Enum.FIND_TYPES.FIND_ID_MOBILE} name="find_id_method" className="id_mobile_radio" defaultChecked />휴대폰</label> {/*휴대폰으로 찾기가 디폴트 화면입니다. 클릭 시 group2.id_mobile_form 나타납니다*/}
            <label htmlFor="find_id_email"><input type="radio" id="find_id_email" value={Enum.FIND_TYPES.FIND_ID_EMAIL} name="find_id_method" className="id_email_radio" />이메일</label>
            {/*클릭 시 group2.id_email_form 나타납니다*/}
          </div>{/*//.radio_wrap*/}
        </div>{/*//.group1*/}

        {/* 라디오 버튼에 따른 뷰 렌더링 */}
        {findTypeState(findType)}

      </div>{/*//.content_box*/}
    </>
  )
}

export default FindId
