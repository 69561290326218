import React from "react";
import Swiper from "react-id-swiper";

const MainTopSlide = () => {
  const params = {
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev"
    }
  };

  return (
    <Swiper {...params}>
      <div className="slide">
        <div className="container position_maker">
          <div className="slide_img slide_img00">
            {/* <img src={require("../../assets/img/main/main_slide_00.png")} alt="2020 kt wiz" className="mb_hide" /> */}
            {/* <img src={require("../../assets/img/main/main_slide_00_m.png")} alt="2020 kt wiz" className="pc_hide" /> */}

            {/* 2020시즌 이미지  */}
            {/* <img src={require("../../assets/img/main/main_slide_01.png")} alt="2020 kt wiz" className="mb_hide" />
                <img src={require("../../assets/img/main/main_slide_01_m.png")} alt="2020 kt wiz" className="pc_hide" /> */}
            {/*<img src={require("../../assets/img/main/main_slide_02.png")} alt="2020 kt wiz" className="mb_hide" />
            <img src={require("../../assets/img/main/main_slide_02_m.png")} alt="2020 kt wiz" className="pc_hide" /> */}
            {/* <img src={require("../../assets/img/main/main_slide_03.jpg")} alt="2021 kt wiz" className="mb_hide" />
            <img src={require("../../assets/img/main/main_slide_03_m.jpg")} alt="2021 kt wiz" className="pc_hide" /> */}
            <img src={require("../../assets/img/main/main_slide_220215.jpg")} alt="2021 kt wiz" className="mb_hide" />
            <img src={require("../../assets/img/main/main_slide_220215_m.jpg")} alt="2021 kt wiz" className="pc_hide" />
          </div>
        </div>
      </div>
      {/* <div className="slide">
        <div className="container position_maker">
          <div className="slide_txt_wrap">
            <div className="slide_txt1">
              <img src={require('../../assets/img/main/main_text1.png')} alt="飛上 2019 숭리의 kt wiz!" />
            </div>
            <div className="slide_txt2">
              <img src={require('../../assets/img/main/main_text2.png')} alt="kt wiz head line 2019 kt wiz 5G 워터페스티벌 일정" />
            </div>
          </div>
          <div className="slide_img">
            <img src={require('../../assets/img/main/main_player.png')} alt="슬라이드 배경 선수 이미지" />
          </div>
        </div>
      </div> */}
    </Swiper>
  );
};

export default MainTopSlide;
