import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Link, Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";

// route
import HomePage from "../pages/HomePage";
import HomePageOld from "../pages/HomePageOld";
import { LoginPage, JoinPage, JoinInitPage, FindIdPage, FindPwPage, ResetPwPage } from "../pages/auth";
import { AboutPage, HistoryPage, BiSymbolPage, BiWordmarkPage, BiEmblemPage, BiMascotPage, BiUniformPage, SponsorPage, WallpaperPage, PolicyRegularPage, PolicyChildPage, PolicyDonationPage } from "../pages/ktwiz";
import { WizparkLocationPage, WizparkFineDustPage, WizparkUltrafineDustPage, IksanStadiumPage, WizparkParkingPage, WizparkShuttleBusPage, Wizpark5GStadiumStadiumPage, WizparkStorePage, WizparkIntroPage, WizparkGuidePage } from "../pages/wizpark";
import { RegularLeagueSchedulePage, RegularLeagueBoxScorePage, FuturesLeagueSchedulePage, FuturesLeagueBoxScorePage, RegularLeagueTeamRankingPage, RegularLeaguePitcherRankingPage, RegularLeagueBatterRankingPage, RegularLeagueCrowdRankingPage, FuturesLeagueTeamRankingPage, FuturesLeaguePitcherRankingPage, FuturesLeagueBatterRankingPage, RegularLeagueWatchPointPage } from "../pages/game";
import { PlayerRoute } from "../pages/player";
import WizMediaPage from "../pages/media";
import { TicketPricePage, ReservationAndRefundPage, DiscountAndBenefitPage, SkyboxReservationPage, GroupReservationPage, SeatMapPage, EntrancePage, StorePage } from "../pages/ticket";
import { MyInfoPage } from "../pages/mypage";
import ShopPage from "../pages/shop/ShopPage";
import PasswordConfirmPage from "../pages/mypage/PasswordConfirmPage";
import MobileTermsRoutePage from "./auth/join/mobileTerms/MobileTermsRoutePage";
import IndiLogPage from "pages/auth/IndiLogPage";
import { CookiesProvider } from "react-cookie";

// components
import Footer from "./Footer";
import Header from "./Header";
import Dialog from "./common/Dialog";

// css
import "../styles/reset.css";
import "../styles/common.css";
import "../styles/grid.css";
import "../styles/swiper.css";
import "../styles/main.css";
import "../styles/contents.css";
import "../styles/contents3.css";
import "../styles/login.css";
// import '../styles/ktwiz.css'
// import '../styles/park.css'
// import '../styles/ticket.css'

import "v2/css/ktwiz.css";

// loading
import LoadingOverlay from "react-loading-overlay";
import LoadingLoader from "react-spinners/ScaleLoader";
import ScrollToTop from "./common/ScrollToTop";
function App({ history }) {
  const isLoading = useSelector((state) => state.loading.isLoading);
  const dialog = useSelector((state) => state.dialog);

  return (
    <>
      <CookiesProvider>
        <BrowserRouter>
          <ConnectedRouter history={history}>
            <ScrollToTop />
                <div className="wrap">
                  <Switch>
                  <Route path='/mobile' component={MobileTermsRoutePage} />
                  <Route>
                  <Header />
                  <Switch>
                    <Route path="/login" component={LoginPage} />
                    <Route path='/indilog' component={IndiLogPage} />
                    <Route path="/join/step" component={JoinPage} />
                    <Route path="/join" component={JoinInitPage} />
                    <Route path="/findid" component={FindIdPage} />
                    <Route path="/findpw" component={FindPwPage} />
                    <Route path="/resetpw" component={ResetPwPage} />
                    <Route path="/ktwiz/about" component={AboutPage} />
                    <Route path="/ktwiz/history" component={HistoryPage} />
                    <Route path="/ktwiz/bi/symbol" component={BiSymbolPage} />
                    <Route path="/ktwiz/bi/wordmark" component={BiWordmarkPage} />
                    <Route path="/ktwiz/bi/emblem" component={BiEmblemPage} />
                    <Route path="/ktwiz/bi/mascot" component={BiMascotPage} />
                    <Route path="/ktwiz/bi/uniform" component={BiUniformPage} />
                    <Route path="/ktwiz/bi" component={BiSymbolPage} />
                    <Route path="/ktwiz/sponsor" component={SponsorPage} />
                    <Route path="/ktwiz/wallpaper" component={WallpaperPage} />
                    <Route path="/ktwiz/policy/regular" component={PolicyRegularPage} />
                    <Route path="/ktwiz/policy/child" component={PolicyChildPage} />
                    <Route path="/ktwiz/policy/donation" component={PolicyDonationPage} />
                    <Route path="/ktwiz" component={AboutPage} />
                    <Route path="/wizpark/guide" component={WizparkGuidePage} />
                    <Route path="/wizpark/stadium" component={Wizpark5GStadiumStadiumPage} />
                    <Route path="/wizpark/parking" component={WizparkParkingPage} />
                    <Route path="/wizpark/shuttle" component={WizparkShuttleBusPage} />
                    <Route path="/wizpark/intro" component={WizparkIntroPage} />
                    <Route path="/wizpark/location" component={WizparkLocationPage} />
                    <Route path="/wizpark/iksan" component={IksanStadiumPage} />
                    <Route path="/wizpark/store" component={WizparkStorePage} />
                    <Route path="/wizpark/finedust" component={WizparkFineDustPage} />
                    <Route path="/wizpark/ultrafinedust" component={WizparkUltrafineDustPage} />
                    <Route path="/wizpark" component={WizparkIntroPage} />
                    <Route path="/game/regular/schedule/:year/:month" component={RegularLeagueSchedulePage} />
                    <Route path="/game/regular/schedule" component={RegularLeagueSchedulePage} />
                    <Route path="/game/regular/boxscore/:gameDate/:gameId" component={RegularLeagueBoxScorePage} />
                    <Route path="/game/regular/boxscore" component={RegularLeagueBoxScorePage} />
                    <Route path="/game/regular/ranking/team" component={RegularLeagueTeamRankingPage} />
                    <Route path="/game/regular/ranking/pitcher" component={RegularLeaguePitcherRankingPage} />
                    <Route path="/game/regular/ranking/batter" component={RegularLeagueBatterRankingPage} />
                    <Route path="/game/regular/ranking/crowd" component={RegularLeagueCrowdRankingPage} />
                    <Route path="/game/regular/watchPoint/:gameDate/:gameId" component={RegularLeagueWatchPointPage} />
                    <Route path="/game/regular/watchPoint" component={RegularLeagueWatchPointPage} />
                    <Route path="/game/futures/schedule/:year/:month" component={FuturesLeagueBoxScorePage} />
                    <Route path="/game/futures/schedule" component={FuturesLeagueSchedulePage} />
                    <Route path="/game/futures/boxscore/:gameDate/:gameId" component={FuturesLeagueBoxScorePage} />
                    <Route path="/game/futures/boxscore" component={FuturesLeagueBoxScorePage} />
                    <Route path="/game/futures/ranking/team" component={FuturesLeagueTeamRankingPage} />
                    <Route path="/game/futures/ranking/pitcher" component={FuturesLeaguePitcherRankingPage} />
                    <Route path="/game/futures/ranking/batter" component={FuturesLeagueBatterRankingPage} />
                    <Route path="/game" component={RegularLeagueSchedulePage} />
                    <Route path="/player" component={PlayerRoute} />
                    <Route path="/media" component={WizMediaPage} />
                    <Route path="/ticket/price" component={TicketPricePage} />
                    <Route path="/ticket/reservation" component={ReservationAndRefundPage} />
                    <Route path="/ticket/discount" component={DiscountAndBenefitPage} />
                    <Route path="/ticket/skybox" component={SkyboxReservationPage} />
                    <Route path="/ticket/group" component={GroupReservationPage} />
                    <Route path="/ticket/seatmap" component={SeatMapPage} />
                    <Route path="/ticket/entrance" component={EntrancePage} />
                    <Route path="/ticket/store" component={StorePage} />
                    <Route path="/ticket" component={TicketPricePage} />
                    <Route path="/mypage/check" component={PasswordConfirmPage} />
                    <Route path="/mypage" component={MyInfoPage} />
                    <Route path="/sports/site/baseball/main.do" component={HomePage} />
                    <Route path="/shop" component={ShopPage} />
                    <Route path="/main-old" component={HomePageOld} />
                    <Route exact path="/" component={HomePage} />
                  </Switch>
                  <Footer />
                  </Route>
                  </Switch>
                  <LoadingOverlay active={isLoading} spinner={<LoadingLoader color="#ec0a0b" size={30} />} fadeSpeed={200} text="" />
                </div>
            <Dialog dialog={dialog} />
          </ConnectedRouter>
        </BrowserRouter>
      </CookiesProvider>
    </>
  );
}

export default App;
