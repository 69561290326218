import { put, take, takeEvery, all, call, fork } from "redux-saga/effects"
import { push } from "connected-react-router"
// api
import * as resetPwApi from "../../api/auth/resetPw"
// actions
import * as loadingActions from "../../modules/common/loading"
import * as dialogActions from "../../modules/common/dialog"
import * as resetPwActions from "../../modules/auth/resetPw"

function* blockResetPw() {

  yield put(dialogActions.alertOpen("잘못된 접근입니다."))

  const alertResult = yield take([dialogActions.ALERT_POSITIVE])

  if (alertResult.type === dialogActions.ALERT_POSITIVE) {
    yield put(push("/login"))
  }

}

function* blockResetPwWatch() {
  yield takeEvery(resetPwActions.BLOCK_RESET_PW, blockResetPw)
}

function* resetPw({ updateUserInfo }) {

  // 1. 로딩바 열기
  yield put(loadingActions.setLoading(true))

  // 2-1. 비밀번호 양식 불일치 체크
  // if (!updateUserInfo.newPw.match(/(?=.*\d)((?=.*[a-z])|(?=.*[A-Z]))(?=.*[!@#$%^~*+=-]).{8,}/)) {
  if (!updateUserInfo.newPw.match(/(?=.*\d)((?=.*[a-z]))(?=.*[`~!@@#$%^&*|\\\'\";:\/?]).{8,}/)) { // 기존 홈페이지 기준으로 변경
    yield put(dialogActions.alertOpen('비밀번호는 영문자, 숫자, 특수문자를 포함한 8자 이상으로 구성되어야 합니다.'))
    yield take(dialogActions.ALERT_POSITIVE)
  }
  // 2-2. 비밀번호 확인 불일치 체크
  else if (updateUserInfo.newPw !== updateUserInfo.confirmNewPw) {
    yield put(dialogActions.alertOpen('비밀번호를 확인해주세요.'))
    yield take(dialogActions.ALERT_POSITIVE)
  }
  // 3. 비밀번호 변경 수행
  else {
    const resetPwApiResponse = yield call(resetPwApi.resetPw, updateUserInfo)

    // success
    if (resetPwApiResponse.status === 0) {

      yield put(dialogActions.alertOpen('비밀번호 변경이 완료되었습니다.'))

      const resetPwApiAlertResult = yield take(dialogActions.ALERT_POSITIVE)
      if (resetPwApiAlertResult.type === dialogActions.ALERT_POSITIVE) {
        yield put(push('/login'))
      }

    }
    // fail
    else {
      yield put(dialogActions.alertOpen(resetPwApiResponse.message))
    }

  }

  // 4. 로딩바 닫기
  yield put(loadingActions.setLoading(false))
}


function* resetPwWatch() {
  yield takeEvery(resetPwActions.RESET_PW_REQUEST, resetPw)
}

export default function* resetPwSaga() {
  yield all([
    fork(blockResetPwWatch),
    fork(resetPwWatch),
  ])
}
