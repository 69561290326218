import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { resourceRequest, useResource } from '../../../modules/resource/resource'
import * as dialogActions from "../../../modules/common/dialog"
import Prepairing from '../../common/Prepairing'

const PolicyRegular = () => {
  const DISCOUNT_REGULAR_IMAGE_M = 'DISCOUNT_REGULAR_IMAGE_M'
  const DISCOUNT_REGULAR_IMAGE_PC = 'DISCOUNT_REGULAR_IMAGE_PC'
  const [imagePc, errorPc] = useResource(DISCOUNT_REGULAR_IMAGE_PC)
  const [imageM, errorM] = useResource(DISCOUNT_REGULAR_IMAGE_M)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(resourceRequest(DISCOUNT_REGULAR_IMAGE_PC))
    dispatch(resourceRequest(DISCOUNT_REGULAR_IMAGE_M))
  }, [])

  useEffect(() => {
    if (errorPc && errorPc.message) {
      dispatch(dialogActions.alertOpen(errorPc.message))
    }
  }, [errorPc, errorM])

  const isPrePage = process.env.REACT_APP_IS_PREPAIRING_PAGE

  return (
    <>
      <article className='arti'>
        {/* {<div className='tit'>
          <h6>
            일반회원 혜택
              </h6>
        </div>} */}
        <div className='desc'>
          <div className='img-whole'>
            <img className='pc' src={imagePc && imagePc.resource && imagePc.resource.value} alt="" />
            <img className='m' src={imageM && imageM.resource && imageM.resource.value} alt="" />
          </div>
        </div>
      </article>
      {/*
          <article className='arti'>
            <div className='tit'>
              <h6>유의 사항</h6>
            </div>
            <div className='desc'>
              <div className='wrap'>
                <div className='r'>
                  <span className='marker'>·</span>
                  <p>
                    응원지정석 이하 좌석 : 응원지정석, 내야지정석, 외야잔디 자유석 <strong className='red'>(*스카이존 제외)</strong>
                  </p>
                </div>
                <div className='r'>
                  <span className='marker'>·</span>
                  <p>
                    회원혜택 제공 기간 : <strong className='red'>
                      2019.03.18(월) ~ 2019.12.31(화)까지 적용(2020.01.01(수) 등급초기화)</strong>
                  </p>
                </div>
                <div className='r'>
                  <span className='marker'>·</span>
                  <p>
                    본인 등급은 wizzap 또는 구단 홈페이지 내에서 확인 가능
              </p>
                </div>
                <div className='r'>
                  <span className='marker'>·</span>
                  <p>
                    방문횟수 적용
              </p>
                </div>
                <div className='r t'>
                  <span className='marker'>-</span>
                  <p>
                    본인이 티켓구매(티켓선물 포함) 후 wizzap 스마트 전용 / 일반게이트 입장 시, 티켓 리딩 입장기록을 통해 적용
                    (wizzap / 구단홈페이지)
              </p>
                </div>
                <div className='r t'>
                  <span className='marker'>-</span>
                  <p>
                    방문횟수 및 등급 변경은 경기 관람 후 익일 누적 적용
              </p>
                </div>
                <div className='r t'>
                  <span className='marker'>-</span>
                  <p>
                    게임권 / 무료 쿠폰으로 예매한 티켓은 방문 횟수 미적용
              </p>
                </div>
              </div>
            </div>
          </article>*/}
    </>
  )
}

export default PolicyRegular
