import React from 'react'

const PitcherDetailSecondMobileTabLastest = ({ dataTable, recentgamerecordlist }) => {
  return (
    <>
      {/* 시즌 정규리그 최근 5경기 기록 mobile */}
      <article className="player_record_content latest_game mb_mode">
        <div className="player_record">
          <div className="container">
            <h5>최근 5경기 기록</h5>
          </div>
          <div className="container table first_fixed">
            {/* fix_tb_block */}
            <div className="fix_tb_block btD7161b">
              <div className="fix_tb_content">
                <div className="fix_tb_wrap">
                  <table>
                    <caption>{dataTable.title}</caption>
                    <thead>
                      <tr>
                        {
                          dataTable.data.map((v, i) => (

                            (i > 0) &&
                            <th key={i}>{v.head}</th>
                          ))
                        }
                      </tr>
                    </thead>
                    <tbody>
                      {
                        recentgamerecordlist && recentgamerecordlist.length > 0
                          ? recentgamerecordlist.map((v, i) => (
                            <tr key={i}>
                              {
                                dataTable.data.map((sv, si) => (
                                  (si > 0) &&
                                  <td key={si}>{v[sv.bodyParam]}</td>
                                ))
                              }
                            </tr>
                          ))
                          : <tr><td colSpan={dataTable.data.length}>데이터가 없습니다.</td></tr>
                      }
                    </tbody>
                  </table>
                </div>
                <table className="fix_tb">
                  <thead>
                    <tr>
                      <th>{dataTable.data[0].head}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      recentgamerecordlist && recentgamerecordlist.length > 0
                        ? recentgamerecordlist.map((v, i) => (
                          <tr key={i}>
                            {
                              dataTable.data.map((sv, si) => (
                                (si === 0) &&
                                <td key={si}>{v[sv.bodyParam]}</td>
                              ))
                            }
                          </tr>
                        ))
                        : <tr><td>　</td></tr>
                    }
                  </tbody>
                </table>
              </div>
            </div>
            {/* // fix_tb_block */}
          </div>
        </div>
      </article>
      {/* // 시즌 정규리그 최근 5경기 기록 mobile */}
    </>
  )
}

export default PitcherDetailSecondMobileTabLastest
