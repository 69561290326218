import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { resourceRequest, useResource } from '../../../modules/resource/resource'
import * as dialogActions from "../../../modules/common/dialog"
import Prepairing from '../../common/Prepairing'

const PolicyDonation = () => {
  const DISCOUNT_DONATION_IMAGE_M = 'DISCOUNT_DONATION_IMAGE_M'
  const DISCOUNT_DONATION_IMAGE_PC = 'DISCOUNT_DONATION_IMAGE_PC'
  const [imagePc, errorPc] = useResource(DISCOUNT_DONATION_IMAGE_PC)
  const [imageM, errorM] = useResource(DISCOUNT_DONATION_IMAGE_M)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(resourceRequest(DISCOUNT_DONATION_IMAGE_PC))
    dispatch(resourceRequest(DISCOUNT_DONATION_IMAGE_M))
  }, [])

  useEffect(() => {
    if (errorPc && errorPc.message) {
      dispatch(dialogActions.alertOpen(errorPc.message))
    }
  }, [errorPc, errorM])

  const isPrePage = process.env.REACT_APP_IS_PREPAIRING_PAGE

  return (
    <>
      <article className='arti'>
        <div class='tit'>
          <h6>기부 프로그램</h6>
        </div>
        <div className='desc'>
          <div className='img-whole'>
            <img className='pc' src={imagePc && imagePc.resource && imagePc.resource.value} alt="" />
            <img className='m' src={imageM && imageM.resource && imageM.resource.value} alt="" />
          </div>
        </div>
      </article>
      {/*
    <article class='arti'>
      <div class='tit'>
        <h6>기부 프로그램</h6>
      </div>
      <div class='desc'>
        <div class='info'>
          <div class='s s1'>
            <div class='t'>
              <span class='icon'></span>
              <h6>기부금 적립 방법</h6>
            </div>
            <div class='c'>
              <div class='r'>
                <div class='marker'>·</div>
                <div class='txt'>
                  <p>kt wiz 회원 가입 후 2019년 정규시즌 동안 wizzap과 홈페이지를 통해 티켓 구매 시 구매 금액의 3% 자동적립</p>
                </div>
              </div>
              <div class='r'>
                <div class='marker'>·</div>
                <div class='txt'>
                  <p>wizzap에서 기부 적립금 및 순위 확인 가능</p>
                </div>
              </div>
            </div>
          </div>
          <div class='s s2'>
            <div class='t'>
              <span class='icon'></span>
              <h6>기부적립금 운영</h6>
            </div>
            <div class='c'>
              <div class='r'>
                <div class='marker'>·</div>
                <div class='txt'>
                  <p>2019년 시즌 종료 후 팬 의견 수렴하여 기부활동 선정 및 비시즌 사회 공헌 활동 진행</p>
                </div>
              </div>
              <div class='r'>
                <div class='marker'>·</div>
                <div class='txt'>
                  <p>의료지원, 복지시설, 유소년 야구용품 지원 등 지역사회 공헌</p>
                </div>
              </div>
            </div>
          </div>
          <div class='s s3'>
            <div class='t'>
              <span class='icon'></span>
              <h6>기부왕</h6>
            </div>
            <div class='c'>
              <div class='r'>
                <div class='marker'>·</div>
                <div class='txt'>
                  <p>2019 정규 시즌 중 기부 적립금이 가장 많은 회원 1명</p>
                </div>
              </div>
              <div class='r'>
                <div class='marker'>·</div>
                <div class='txt'>
                  <p>2019 마지막 홈경기 기부왕 시상 및 시구</p>
                </div>
              </div>
              <div class='r'>
                <div class='marker'>·</div>
                <div class='txt'>
                  <p>2020 정규 시즌 경기 스카이박스 초청</p>
                </div>
              </div>
              <div class='r'>
                <div class='marker'>·</div>
                <div class='txt'>
                  <p>2020 정규 시즌 1루 응원지정석 무료티켓 10매</p>
                </div>
              </div>
            </div>
          </div>
          <div class='s s4'>
            <div class='t'>
              <span class='icon'></span>
              <h6>기부마법사</h6>
            </div>
            <div class='c'>
              <div class='r'>
                <div class='marker'>·</div>
                <div class='txt'>
                  <p>2019 정규 시즌 기부 적립금 상위 2~50위 선정</p>
                </div>
              </div>
              <div class='r'>
                <div class='marker'>·</div>
                <div class='txt'>
                  <p>2020 정규 시즌 1루 응원지정석 무료티켓 5매 제공</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class='message'>
          <p>
            <span className='first'>수원 케이티 위즈 파크에서 경기를</span><span>관람하신 팬 여러분 모두가 기부천사입니다!</span>
          </p>
        </div>
      </div>
    </article>
  */}
    </>
  )
}

export default PolicyDonation
