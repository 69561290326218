import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { resourceRequest, useResource } from '../../../modules/resource/resource'
import * as dialogActions from "../../../modules/common/dialog"

const ShuttleBus = () => {
  const SHUTTLE_BUS_IMAGE_PC = 'SHUTTLE_BUS_IMAGE_PC'
  const SHUTTLE_BUS_IMAGE_M = 'SHUTTLE_BUS_IMAGE_M'
  const [imagePc, errorPc] = useResource(SHUTTLE_BUS_IMAGE_PC)
  const [imageM, errorM] = useResource(SHUTTLE_BUS_IMAGE_M)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(resourceRequest(SHUTTLE_BUS_IMAGE_PC))
    dispatch(resourceRequest(SHUTTLE_BUS_IMAGE_M))
  }, [])

  useEffect(() => {
    if (errorPc && errorPc.message) {
      dispatch(dialogActions.alertOpen(errorPc.message))
    }
  }, [errorPc, errorM])

  return (
    <>
      <article className='arti'>
        <div className='tit'>
          <h6>셔틀버스 안내</h6>
        </div>
        <div className='desc'>
          <img className='img-whole pc' src={imagePc && imagePc.resource && imagePc.resource.value} alt="" />
          <img className='img-whole mobile' src={imageM && imageM.resource && imageM.resource.value} alt="" />
        </div>
      </article>
    </>
  )
}

export default ShuttleBus
