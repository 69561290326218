import React, { useEffect, useState } from 'react'
import Link from '../../components/common/Link'
import { useSelector, useDispatch } from 'react-redux'
import { logoutRequest } from '../../modules/auth/login'
import PcGnb from './PcGnb'
import MypageNav from './MypageNav'
import HeaderOverlay from './HeaderOverlay'
import MobileHeader from './MobileHeader'
import MobileGnb from './MobileGnb'
import { useHistory } from "react-router-dom";



const Header = () => {
  const login = useSelector(state => state.login)

  const dispatch = useDispatch()

  const history = useHistory();

  const onLogout = () => {
    const rightGnb = document.querySelector(`.mb .gnb-m`);

    if (rightGnb.classList.contains('on')) {
      setMobileUserNavClass('off');
      rightGnb.scrollTo(0, 0)
    }

    dispatch(logoutRequest(login.user))
  }
  const [visible, setVisible] = useState(false)
  const [mobileNavActive, setMobileNavActive] = useState('gnb_m_wrap menu_m_wrap')
  const [mypageActive, setMypageActive] = useState('mypage_m_wrap menu_m_wrap')
  const [gnbCloseBtnActive, setGnbCloseBtnActive] = useState('close_btn')
  const [myPageCloseBtnActive, setMyPageCloseBtnActive] = useState('close_btn')
  const [bgActive, setBgActive] = useState('header_overlay')

  const [mobileGnbClass, setMobileGnbClass] = useState('');
  const [mobileUserNavClass, setMobileUserNavClass] = useState('');
  const [pathName, setPathName] = useState('');

  const setMoilbeSubNavClass = el => {
    const target = document.querySelector(`.${el}`);
    if (target) {
      target.classList.contains('on') ? target.classList.remove('on') : target.classList.add('on')
    }
  }

  // {/* 모바일 메뉴 토글 */}
  const handleMobileNav = () => {
    setVisible(!visible)
    // console.log(visible);
    handleBg()

    if (visible) {
      setMobileNavActive('gnb_m_wrap menu_m_wrap')
      setGnbCloseBtnActive('close_btn')

      // 하위메뉴 토글 초기화
      const moblieDep2Wrap = document.getElementsByClassName('moblie_dep2_wrap')
      for (let i = 1; i < moblieDep2Wrap.length; i++) {
        // console.log(dep2Wrap[i].className);
        if (moblieDep2Wrap[i].className === 'moblie_dep2_wrap active') {
          moblieDep2Wrap[i].className = 'moblie_dep2_wrap'
          moblieDep2Wrap[i].parentElement.classList.add('up')
        }
      }
      moblieDep2Wrap[0].className = 'moblie_dep2_wrap active'
    } else {
      setMobileNavActive('gnb_m_wrap menu_m_wrap active')
      setGnbCloseBtnActive('close_btn active')
    }
  }

  // {/* 마이페이지 토글 */}
  const handleMypageNav = () => {
    setVisible(!visible)
    handleBg()

    if (visible) {
      setMypageActive('mypage_m_wrap menu_m_wrap')
      setMyPageCloseBtnActive('close_btn')
    } else {
      setMypageActive('mypage_m_wrap menu_m_wrap active')
      setMyPageCloseBtnActive('close_btn active')
    }
  }

  // {/* 검정 백그라운드 토글 */}
  const handleBg = () => {
    if (visible) {
      setBgActive('header_overlay')
      // document.getElementsByTagName('body')[0].style.overflowY = 'auto'
      // console.log(document.getElementsByTagName('body')[0].style.overflowY);
    } else {
      setBgActive('header_overlay active')
      // document.getElementsByTagName('body')[0].style.overflowY = 'hidden'
    }
  }

  const go = (link) => {
    const leftGnb = document.querySelector(`.mb .gnb`);
    const rightGnb = document.querySelector(`.mb .gnb-m`);

    if (leftGnb.classList.contains('on')) {
      setMobileGnbClass('off');
    }

    if (rightGnb.classList.contains('on')) {
      setMobileUserNavClass('off');
    }

    history.push(link);
  };

  useEffect(_ => {
    setPathName(history.location.pathname)
    history.listen(location => {
      setPathName(location.pathname)
    });
  }, [history])

  return (
    <>
      <nav className={pathName !== '/' ? 'scrolled' : ''}>
        <div className="mb">
          <div className="con">
            <div className="btn-hamburger-menu" onClick={_ => setMobileGnbClass('on')}>
              <img src="/v2/imgs/ico-24-menu@2x.png" alt="menu" />
            </div>
            <div className="logo" style={{ cursor: 'pointer' }} onClick={e => { e.preventDefault(); go('/') }}>
              <img src="/v2/imgs/img-logo-mb@2x.png" alt="kt wiz" />
            </div>
            <div className="utility">
              <ul>
                <li>
                  <a className="btn-ticket" href="#"
                    onClick={e => {
                      e.preventDefault();
                      go('/ticket')
                    }}
                  >
                    <img src="/v2/imgs/ico-24-ticket@2x.png" alt="Ticket" />
                  </a>
                </li>
                <li>
                  <a className="btn-user" href="#" onClick={e => {
                    e.preventDefault();
                    setMobileUserNavClass('on')
                  }}
                  >
                    <img src="/v2/imgs/ico-24-user@2x.png" alt="User" />
                  </a>
                </li>
              </ul>
            </div>
            <div className={`gnb ${mobileGnbClass}`}>
              <div className="btn-close" onClick={_ => setMobileGnbClass('off')}>
                <img src="/v2/imgs/ico-30-close@2x.png" alt="close" />
              </div>
              <ul>
                <li className="gnb-ktwiz on">
                  <a href="#"
                    onClick={e => {
                      e.preventDefault();
                      setMoilbeSubNavClass('gnb-ktwiz')
                    }}
                  >
                    kt wiz
                    <div className="arrow">
                      <img className="off" src="/v2/imgs/ico-18-chevron-open-n@2x.png" />
                      <img className="on" src="/v2/imgs/ico-18-chevron-close-n@2x.png" />
                    </div>
                  </a>
                  <ul>
                    <li>
                      <Link to="/ktwiz/about" onClick={() => go("/ktwiz/about")}>
                        kt wiz는?
                      </Link>
                    </li>
                    <li>
                      <Link to="/ktwiz/bi/symbol" onClick={() => go("/ktwiz/bi/symbol")}>구단 BI</Link>
                    </li>
                    <li>
                      <Link to="/ktwiz/policy/regular" onClick={() => go("/ktwiz/policy/regular")}>회원정책</Link>
                    </li>
                  </ul>
                </li>
                <li className="gnb-wiz-park">
                  <a href="#"
                    onClick={e => {
                      e.preventDefault();
                      setMoilbeSubNavClass('gnb-wiz-park')
                    }}
                  >
                    wiz park
                    <div className="arrow">
                      <img className="off" src="/v2/imgs/ico-18-chevron-open-n@2x.png" />
                      <img className="on" src="/v2/imgs/ico-18-chevron-close-n@2x.png" />
                    </div>
                  </a>
                  <ul>
                    <li>
                      <Link to="/wizpark/intro" onClick={() => go("/wizpark/intro")}>
                        수원 kt wiz park
                      </Link>
                    </li>
                    <li>
                      <Link to="/wizpark/parking" onClick={() => go("/wizpark/parking")}>
                        주차 예약
                      </Link>
                    </li>
                    <li>
                      <Link to="/wizpark/location" onClick={() => go("/wizpark/location")}>찾아오기</Link>
                    </li>
                    <li>
                      <Link to="/wizpark/iksan" onClick={() => go("/wizpark/iksan")}>익산야구장</Link>
                    </li>
                  </ul>
                </li>
                <li className="gnb-game">
                  <a href="#"
                    onClick={e => {
                      e.preventDefault();
                      setMoilbeSubNavClass('gnb-game')
                    }}
                  >
                    Game
                    <div className="arrow">
                      <img className="off" src="/v2/imgs/ico-18-chevron-open-n@2x.png" />
                      <img className="on" src="/v2/imgs/ico-18-chevron-close-n@2x.png" />
                    </div>
                  </a>
                  <ul>
                    <li>
                      <Link to="/game/regular/schedule" onClick={() => go("/game/regular/schedule")}>정규리그</Link>
                    </li>
                    <li>
                      <Link to="/game/futures/schedule" onClick={() => go("/game/futures/schedule")}>퓨처스리그</Link>
                    </li>
                  </ul>
                </li>
                <li className="gnb-player">
                  <a href="#"
                    onClick={e => {
                      e.preventDefault();
                      setMoilbeSubNavClass('gnb-player')
                    }}
                  >
                    Player
                    <div className="arrow">
                      <img className="off" src="/v2/imgs/ico-18-chevron-open-n@2x.png" />
                      <img className="on" src="/v2/imgs/ico-18-chevron-close-n@2x.png" />
                    </div>
                  </a>
                  <ul>
                    <li>
                      <Link to="/player/coach" onClick={() => go("/player/coach")}>코칭스텝</Link>
                    </li>
                    <li>
                      <Link to="/player/pitcher" onClick={() => go("/player/pitcher")}>투수</Link>
                    </li>
                    <li>
                      <Link to="/player/catcher" onClick={() => go("/player/catcher")}>타자</Link>
                    </li>
                    <li>
                      <Link to="/player/cheer" onClick={() => go("/player/cheer")}>응원단</Link>
                    </li>
                    <li>
                      <Link to="/player/song" onClick={() => go("/player/song")}>응원가</Link>
                    </li>
                    <li>
                      <Link to="/player/song-copyright" onClick={() => go("/player/song-copyright")}>응원가 저작권</Link>
                    </li>
                  </ul>
                </li>
                <li className="gnb-media">
                  <a href="#"
                    onClick={e => {
                      e.preventDefault();
                      setMoilbeSubNavClass('gnb-media')
                    }}
                  >
                    Media
                    <div className="arrow">
                      <img className="off" src="/v2/imgs/ico-18-chevron-open-n@2x.png" />
                      <img className="on" src="/v2/imgs/ico-18-chevron-close-n@2x.png" />
                    </div>
                  </a>
                  <ul>
                    <li>
                      <Link to="/media/wiznews" onClick={() => go("/media/wiznews")}>wiz 뉴스</Link>
                    </li>
                    <li>
                      <Link to="/media/wizstory" onClick={() => go("/media/wizstory")}>wiz 스토리</Link>
                    </li>
                    <li>
                      <Link to="/media/firstpitch" onClick={() => go("/media/firstpitch")}>시구자 정보</Link>
                    </li>
                    <li>
                      <Link to="/media/photos" onClick={() => go("/media/photos")}>wiz 포토</Link>
                    </li>
                    <li>
                      <Link to="/media/highlight" onClick={() => go("/media/highlight")}>하이라이트</Link>
                    </li>
                    <li>
                      <Link to="/media/live/pts" onClick={() => go("/media/live/pts")}>Live 영상모음</Link>
                    </li>
                  </ul>
                </li>
                <li className="gnb-sponsor">
                  <a href="#" onClick={e => { e.preventDefault(); window.open(process.env.REACT_APP_B2B_DOMAIN) }} >
                    스폰서
                    <div className="new-window">
                      <img src="/v2/imgs/ico-18-export@2x.png" alt="open new" />
                    </div>
                  </a>
                </li>
              </ul>
            </div>

            {/* <!-- GNB M --> */}
            <div className={`gnb-m ${mobileUserNavClass}`}>
              <div className="btn-close" onClick={_ => setMobileUserNavClass('off')}>
                <img src="/v2/imgs/ico-30-close@2x.png" alt="close" />
              </div>
              <ul>
                <li className="user">
                  {
                    login.isLoggedIn ?
                      <div className="login">
                        <span className="emblem">
                          <i className="emblem emblem-kt"></i>
                        </span>
                        <span className="text">
                          안녕하세요!
                          <strong>{login.user.id}</strong>
                          님
                        </span>
                      </div>
                      :
                      <div className="logout">
                        <div className="wrap">
                          <div className="col">
                            <Link to='/login' onClick={_ => go('/login')}>
                              <img src="/v2/imgs/ico-18-log-in@2x.png" alt="login" />
                              로그인
                            </Link>
                          </div>
                          <div className="col">
                            <Link to='/join' onClick={_ => go('/join')}>
                              <img src="/v2/imgs/ico-18-user@2x.png" alt="signup" />
                              회원가입
                            </Link>
                          </div>
                        </div>
                      </div>
                  }

                </li>
                <li className="gnb2-ticket on">
                  <a href="#"
                    onClick={e => {
                      e.preventDefault();
                      setMoilbeSubNavClass('gnb2-ticket')
                    }}
                  >
                    Ticket
                    <div className="arrow">
                      <img className="off" src="/v2/imgs/ico-18-chevron-open-n@2x.png" />
                      <img className="on" src="/v2/imgs/ico-18-chevron-close-n@2x.png" />
                    </div>
                  </a>
                  <ul>
                    <li>
                      <Link to="/ticket/reservation" onClick={() => go("/ticket/reservation")}>일반티켓</Link>
                    </li>
                    <li>
                      <Link to="/ticket/skybox" onClick={() => go("/ticket/skybox")}>단체관람</Link>
                    </li>
                    <li>
                      <Link to="/ticket/seatmap" onClick={() => go("/ticket/seatmap")}>입장 및 좌석 정보</Link>
                    </li>
                  </ul>
                </li>
                <li className="gnb-parking">
                  <a href="#" onClick={e => { e.preventDefault(); window.open('https://suwonparkingbaseball.or.kr:410/resvIndex.htm') }}>
                    주차예약
                    <div className="new-window">
                      <img src="/v2/imgs/ico-18-export@2x.png" alt="open new" />
                    </div>
                  </a>
                </li>
                <li className="gnb-wiz-mall">
                  <a href="#" onClick={e => { e.preventDefault(); go('/shop') }}>
                    wiz mall
                    <div className="new-window">
                      <img src="/v2/imgs/ico-18-export@2x.png" alt="open new" />
                    </div>
                  </a>
                </li>
                <li className="gnb-mypage">
                  <a href="#" onClick={e => { e.preventDefault(); go('/mypage') }}>
                    마이페이지
                    <div className="new-window">
                      <img src="/v2/imgs/ico-18-export@2x.png" alt="open new" />
                    </div>
                  </a>
                </li>
                {/* <!-- 
                            로그인 후 화면 
                            시작 --> */}
                {
                  login.isLoggedIn ?
                    <li className="logout-wrap">
                      <div className="btn-logout"
                        onClick={e => {
                          e.preventDefault();
                          onLogout(login.user);
                        }}>
                        <span className="img">
                          <img src="/v2/imgs/ico-18-log-out@2x.png" alt="Logout" />
                        </span>
                        <span className="text">
                          로그아웃
                        </span>
                      </div>
                    </li>
                    : null
                }
                {/* <!-- 
                            로그인 후 화면 
                            끝 --> */}
              </ul>
            </div>
          </div>
        </div>
        <div className="pc">
          <div className="con">
            <div className="logo">
              <img src="/v2/imgs/img-logo.svg" alt="KT Wiz" style={{ cursor: "pointer" }} />
            </div>
            <div className="gnb">
              <ul>
                <li className="gnb-ktwiz">
                  <a href="#" onClick={e => e.preventDefault()}>kt wiz</a>
                </li>
                <li className="gnb-wiz-park">
                  <a href="#" onClick={e => e.preventDefault()}>wiz park</a>
                </li>
                <li className="gnb-game">
                  <a href="#" onClick={e => e.preventDefault()}>Game</a>
                </li>
                <li className="gnb-player">
                  <a href="#" onClick={e => e.preventDefault()}>Player</a>
                </li>
                <li className="gnb-media">
                  <a href="#" onClick={e => e.preventDefault()}>Media</a>
                </li>
                <li className="gnb-shop">
                  <a href="#" onClick={e => { e.preventDefault(); go('/shop') }}>Shop</a>
                </li>
                <li className="gnb-sponsor">
                  <a href="#"
                    onClick={e => {
                      e.preventDefault();
                      go('/ktwiz/sponsor')
                    }}>
                    스폰서
                  </a>
                </li>
                <li className="gnb-ticket">
                  <a href="#" onClick={e => e.preventDefault()}>티켓구매</a>
                </li>
              </ul>
            </div>
            <div className="utility">
              <ul>
                {
                  login.isLoggedIn ?
                    <>
                      <li>
                        <a className="btn-utility" href="#"
                          onClick={e => {
                            e.preventDefault();
                            go('/mypage')
                          }}
                        >{login.user.id}</a>
                      </li>
                      <li>
                        <a className="btn-logout" href="#"
                          onClick={e => {
                            e.preventDefault();
                            onLogout(login.user);
                          }}
                        >
                          <img src="/v2/imgs/ico-18-log-out-w@2x.png" alt="로그아웃" />
                        </a>
                      </li>
                    </>
                    :
                    <>
                      <li>
                        <a
                          className="btn-utility"
                          href="#"
                          onClick={e => {
                            e.preventDefault();
                            go('/login')
                          }}>
                          로그인
                        </a>
                      </li>
                      <li>
                        <a className="btn-utility"
                          href="#"
                          onClick={e => {
                            e.preventDefault();
                            go('/join')
                          }}
                        >
                          회원가입
                        </a>
                      </li>
                    </>
                }
                <li>
                  <a className="btn-kt-sports" href="#" onClick={e => { e.preventDefault(); window.open('http://kt-sports.co.kr/sports/site/main.do') }}>
                    <img src="/v2/imgs/img-logo-ktsports@2x.png" alt="kt sports" />
                  </a>
                </li>

              </ul>
            </div>
          </div>
          <div className="over">
            <div className="con">
              <div className="top">
                <div className="logo" onClick={_ => go('/')}>
                  <img src="/v2/imgs/img-logo-black.svg" alt="kt wiz" />
                </div>
                <div className="gnb">
                  <ul>
                    <li className="gnb-ktwiz">
                      <a href="#" onClick={e => e.preventDefault()} >kt wiz</a>
                    </li>
                    <li className="gnb-wiz-park">
                      <a href="#" onClick={e => e.preventDefault()}>wiz park</a>
                    </li>
                    <li className="gnb-game">
                      <a href="#" onClick={e => e.preventDefault()}>Game</a>
                    </li>
                    <li className="gnb-player">
                      <a href="#" onClick={e => e.preventDefault()}>Player</a>
                    </li>
                    <li className="gnb-media">
                      <a href="#" onClick={e => e.preventDefault()}>Media</a>
                    </li>
                    <li className="gnb-shop">
                      <a
                        href="#"
                        onClick={e => { e.preventDefault(); go('/shop') }
                        }
                      >
                        Shop
                      </a>
                    </li>
                    <li className="gnb-sponsor">
                      <a
                        href="#"
                        onClick={e => { e.preventDefault(); window.open(process.env.REACT_APP_B2B_DOMAIN) }
                        }
                      >
                        스폰서
                      </a>
                    </li>
                    <li className="gnb-ticket">
                      <a href="#" onClick={e => e.preventDefault()}>티켓구매</a>
                    </li>
                  </ul>
                </div>
                <div className="utility">
                  <ul>
                    {
                      login.isLoggedIn ?
                        <>
                          <li>
                            <a
                              className="btn-utility" href="#"
                              onClick={e => {
                                e.preventDefault();
                                go('/mypage')
                              }}

                            >{login.user.id}</a>
                          </li>
                          <li>
                            <a
                              classclassName="btn-logout"
                              href="#"
                              onClick={e => {
                                e.preventDefault();
                                onLogout(login.user);
                              }}
                            >
                              <img style={{width: '18px', height: '18px'}} className="black" src="/v2/imgs/ico-18-log-out@2x.png" alt="로그아웃" />
                            </a>
                          </li>
                        </>
                        :
                        <>
                          <li>
                            <a
                              className="btn-utility"
                              href="#"
                              onClick={e => {
                                e.preventDefault();
                                go('/login')
                              }}>
                              로그인
                            </a>
                          </li>
                          <li>
                            <a className="btn-utility"
                              href="#"
                              onClick={e => {
                                e.preventDefault();
                                go('/join')
                              }}
                            >
                              회원가입
                            </a>
                          </li>
                        </>
                    }
                    <li>
                      <a className="btn-kt-sports" href="#" onClick={e => { e.preventDefault(); window.open('http://kt-sports.co.kr/sports/site/main.do') }}>
                        <img src="/v2/imgs/img-logo-ktsports-bk@2x.png" alt="kt sports" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="bottom">
                <div className="lnb">
                  <ul>
                    <li className="gnb-ktwiz">
                      <ul>
                        <li>
                          <Link to="/ktwiz/about" onClick={() => go("/ktwiz/about")}>
                            kt wiz는?
                          </Link>
                        </li>
                        <li>
                          <Link to="/ktwiz/bi/symbol" onClick={() => go("/ktwiz/bi/symbol")}>
                            구단 BI
                          </Link>
                        </li>
                        <li>
                          <Link to="/ktwiz/policy/regular" onClick={() => go("/ktwiz/policy/regular")}>
                            회원 정책
                          </Link>
                        </li>
                        <li>
                          <Link to="/ktwiz/sponsor" onClick={() => go("/ktwiz/sponsor")}>
                            스폰서
                          </Link>
                        </li>
                        <li>
                          <Link to="/ktwiz/wallpaper" onClick={() => go("/ktwiz/wallpaper")}>
                            월페이퍼
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="gnb-wiz-park">
                      <ul>
                        <li>
                          <Link to="/wizpark/intro" onClick={() => go("/wizpark/intro")}>
                            <span>
                              수원 kt wiz
                            </span>
                            <span>
                              park
                            </span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/wizpark/parking" onClick={() => go("/wizpark/parking")}>
                            <span>
                              주차 예약
                            </span>
                            {/* <span>
                              셔틀버스
                            </span> */}
                          </Link>
                        </li>
                        <li>
                          <Link to="/wizpark/location" onClick={() => go("/wizpark/location")}>
                            찾아오기
                          </Link>
                        </li>
                        <li>
                          <Link to="/wizpark/iksan" onClick={() => go("/wizpark/iksan")}>
                            익산야구장
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="gnb-game">
                      <ul>
                        <li>
                          <Link to="/game/regular/schedule" onClick={() => go("/game/regular/schedule")}>
                            정규리그
                          </Link>
                        </li>
                        <li>
                          <Link to="/game/futures/schedule" onClick={() => go("/game/futures/schedule")}>
                            퓨처스리그
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="gnb-player">
                      <ul>
                        <li>
                          <Link to="/player/coach" onClick={() => go("/player/coach")}>
                            코칭스텝
                          </Link>
                        </li>
                        <li>
                          <Link to="/player/pitcher" onClick={() => go("/player/pitcher")}>
                            투수
                          </Link>
                        </li>
                        <li>
                          <Link to="/player/catcher" onClick={() => go("/player/catcher")}>
                            타자
                          </Link>
                        </li>
                        <li>
                          <Link to="/player/cheer" onClick={() => go("/player/cheer")}>
                            응원단
                          </Link>
                        </li>
                        <li>
                          <Link to="/player/song" onClick={() => go("/player/song")}>
                            응원가
                          </Link>
                        </li>
                        <li>
                          <Link to="/player/song-copyright" onClick={() => go("/player/song-copyright")}>
                            응원가 저작권
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="gnb-media">
                      <ul>
                        <li>
                          <Link to="/media/wiznews" onClick={() => go("/media/wiznews")}>
                            wiz 뉴스
                          </Link>
                        </li>
                        <li>
                          <Link to="/media/wizstory" onClick={() => go("/media/wizstory")}>
                            wiz 스토리
                          </Link>
                        </li>
                        <li>
                          <Link to="/media/firstpitch" onClick={() => go("/media/firstpitch")}>
                            시구자 정보
                          </Link>
                        </li>
                        <li>
                          <Link to="/media/photos" onClick={() => go("/media/photos")}>
                            wiz 포토
                          </Link>
                        </li>
                        <li>
                          <Link to="/media/highlight" onClick={() => go("/media/highlight")}>
                            하이라이트
                          </Link>
                        </li>
                        <li>
                          <Link to="/media/live/pts" onClick={() => go("/media/live/pts")}>
                            Live 영상모음
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="gnb-shop">&nbsp;</li>
                    <li className="gnb-sponsor">&nbsp;</li>
                    <li className="gnb-ticket">
                      <ul>
                        <li>
                          <Link to="/ticket/reservation" onClick={() => go("/ticket/reservation")}>
                            티켓예매
                          </Link>
                        </li>
                        <li>
                          <Link to="/ticket/group" onClick={() => go("/ticket/group")}>
                            {/* <span>
                              스카이 박스/
                            </span> */}
                            <span>
                              단체관람
                            </span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/ticket/seatmap" onClick={() => go("/ticket/seatmap")}>
                            입장 및 좌석 정보
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      {/* <header className='header_pc'>
        <div className='header_wrap'>
          <div className='util_t'>
            <div className='container'>
              <ul>
                <li className='login'>
                  {
                    login.isLoggedIn
                      ? <>
                        <Link to='/mypage' className='before_reset'>
                          {login.user.id}
                        </Link>
                        &nbsp;&nbsp;<Link onClick={() => onLogout(login.user)}>로그아웃</Link>
                      </>
                      : <>
                        <Link to='/login' className='before_reset'>
                          로그인
                      </Link>
                        <Link to='/join'>회원가입</Link>
                      </>
                  }
                </li>
                <li className='ktsports'>
                  <Link to="http://kt-sports.co.kr/sports/site/main.do" target=" _blank" isExt={true} title="kt sports">
                    kt sports
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <PcGnb />
        </div>
      </header>

      <MobileHeader handleMobileNav={handleMobileNav} handleMypageNav={handleMypageNav} />
      <MobileGnb mobileNavActive={mobileNavActive} handleMobileNav={handleMobileNav} gnbCloseBtnActive={gnbCloseBtnActive} />
      <MypageNav mypageActive={mypageActive} myPageCloseBtnActive={myPageCloseBtnActive} handleMypageNav={handleMypageNav} login={login} onLogout={onLogout} />
      <HeaderOverlay bgActive={bgActive} /> */}
    </>
  )
}

export default Header
