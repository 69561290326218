import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { yearListRequest, futuresPitcherRankingRequest } from '../../modules/game/ranking'
import PitcherRankingTable from '../../components/game/PitcherRankingTable'
import ContentSwitchingTab from '../../components/common/ContentSwitchingTab'

const FuturesLeaguePitcherRankingTableContainer = ({ match, tab, year, onChangeYear }) => {

  const pitcherRankingList = useSelector(state => state.wizData.data && state.wizData.data['PITCHER_RANKING_LIST'])
  // const pitcherRankingListError = useSelector(state => state.wizData.error && state.wizData.error['PITCHER_RANKING_LIST'])

  const northPitcherRankingList = useSelector(state => state.wizData.data && state.wizData.data['NORTH_PITCHER_RANKING_LIST'])
  // const northPitcherRankingListError = useSelector(state => state.wizData.error && state.wizData.error['NORTH_PITCHER_RANKING_LIST'])

  const southPitcherRankingList = useSelector(state => state.wizData.data && state.wizData.data['SOUTH_PITCHER_RANKING_LIST'])
  // const southPitcherRankingListError = useSelector(state => state.wizData.error && state.wizData.error['SOUTH_PITCHER_RANKING_LIST'])

  const yearList = useSelector(state => state.wizData.data && state.wizData.data['YEAR_LIST'])

  const dispatch = useDispatch()

  const [sortKey, setSortKey] = useState('ERA')
  const [playerName, setPlayerName] = useState(null)

  if (yearList && year == null) {
    onChangeYear(yearList.gameYearlist[0].gyear)
  }

  useEffect(() => {
    dispatch(yearListRequest({}, 'YEAR_LIST'))
  }, [dispatch])

  useEffect(() => {
    if (year != null) {
      dispatch(futuresPitcherRankingRequest({ 'gameSearch.gyear': year, 'gameSearch.teamCode': 'KT', 'gameSearch.sortKey': sortKey, 'gameSearch.pname': playerName }, 'PITCHER_RANKING_LIST'))
      dispatch(futuresPitcherRankingRequest({ 'gameSearch.gyear': year, 'gameSearch.sortKey': sortKey, 'gameSearch.minorLeague': 'NORTH', 'gameSearch.inFlag': 'Y', 'gameSearch.pname': playerName }, 'NORTH_PITCHER_RANKING_LIST'))
      dispatch(futuresPitcherRankingRequest({ 'gameSearch.gyear': year, 'gameSearch.sortKey': sortKey, 'gameSearch.minorLeague': 'SOUTH', 'gameSearch.inFlag': 'Y', 'gameSearch.pname': playerName }, 'SOUTH_PITCHER_RANKING_LIST'))
    }
  }, [sortKey, playerName, year])

  const secondContentTabs = [
    { type: 'regular_league', title: 'kt wiz 투수' },
    { type: 'north_league', title: '북부 투수 순위' },
    { type: 'south_league', title: '남부 투수 순위' }
  ]

  const [contentType, setContentType] = useState(secondContentTabs[0].type)

  const onCliekContentTab = type => {
    setContentType(type)
  }

  return (
    <>
      <ContentSwitchingTab className='detail_lnb_wrap' tabs={secondContentTabs} currentContentType={contentType} onClickContentTab={onCliekContentTab} showPlayerSearch={true} onSearchPlayerName={setPlayerName} onSearchYear={onChangeYear} yearList={yearList && yearList.gameYearlist} />
      <PitcherRankingTable pitcherList={pitcherRankingList} northPitcherList={northPitcherRankingList} southPitcherList={southPitcherRankingList} tab={contentType} onChangeSort={setSortKey} onChangePlayerName={setPlayerName} onChangeYear={onChangeYear} year={year} sortKey={sortKey} yearList={yearList && yearList.gameYearlist} />
    </>
  )
}

export default FuturesLeaguePitcherRankingTableContainer
