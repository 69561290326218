import React from "react";
import { Route, Switch } from "react-router-dom";
import MobileTermsMenu from "./MobileTermsMenu";
import MobileTermsPage from "./MobileTermsPage";
import MobileThirdTermsPage from "./MobileThirdTermsPage";
import MobileNoticeTerms from "./mobileNoticeTerms/MobileNoticeTerms";
import MobileTermsPageEn from "./mobileTermsEn/MobileTermsEnPage";
import MobileThirdTermsEnPage from "./mobileTermsEn/MobileThirdTermsEnPage";

const MobileTermsRoutePage = () => {
  return (
    <Switch>
      <Route path="/mobile/terms" component={MobileTermsPage} />
      <Route path="/mobile/menu" component={MobileTermsMenu} />
      <Route path="/mobile/thdterms" component={MobileThirdTermsPage} />
      <Route path="/mobile/notice/terms" component={MobileNoticeTerms} />
      <Route path="/mobile/en/terms" component={MobileTermsPageEn} />
      <Route path="/mobile/en/thdterms" component={MobileThirdTermsEnPage} />
    </Switch>
  );
};

export default MobileTermsRoutePage;
