import React from 'react'
import ContentTemplate from '../../components/common/ContentTemplate'
import SubContentTemplate from '../../components/common/SubContentTemplate'
import PageHeaderContainer from '../../containers/common/PageHeaderContainer'
import PageTitleTemplate from '../../components/common/PageTitleTemplate'
import BreadcrumbContainer from '../../containers/common/BreadcrumbContainer'
import PageTemplate from '../../components/common/PageTemplate'
import { TICKET_GROUP_RESERVATION, TICKET_SKYBOX_AND_GROUP_TAB } from '../../constants/page'
import GroupReservation from '../../components/ticket/GroupReservation'

const GroupReservationPage = ({ match }) => {
  return (
    <PageTemplate className="ticket">
      <PageHeaderContainer title="스카이박스/단체 관람" subtitle="스카이 박스 및 단체 관람에 대해 안내드립니다." image={require('../../assets/img/ticket/sub_tit_ticket.png')} forwardTabs={TICKET_SKYBOX_AND_GROUP_TAB} currentPath={match.path} />
      <ContentTemplate>
        <PageTitleTemplate>
          <BreadcrumbContainer page={TICKET_GROUP_RESERVATION} />
        </PageTitleTemplate>
        <SubContentTemplate className="sub_content_wrap ticket group guide">
          <GroupReservation />
        </SubContentTemplate>
      </ContentTemplate>
    </PageTemplate>
  )
}

export default GroupReservationPage
