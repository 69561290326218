import React from 'react'
import ContentTemplate from '../../components/common/ContentTemplate'
import SubContentTemplate from '../../components/common/SubContentTemplate'
import PageHeaderContainer from '../../containers/common/PageHeaderContainer'
import PageTitleTemplate from '../../components/common/PageTitleTemplate'
import BreadcrumbContainer from '../../containers/common/BreadcrumbContainer'
import PageTemplate from '../../components/common/PageTemplate'
import { TICKET_ENTRANCE, TICKET_ENTRANCE_AND_SEAT_TAB } from '../../constants/page'
import Entrance from '../../components/ticket/Entrance'

const EntrancePage = ({ match }) => {
  return (
    <PageTemplate className="ticket">
      <PageHeaderContainer title="입장 및 좌석 정보" subtitle="입장 및 좌석 정보를 알려드립니다." image={require('../../assets/img/ticket/sub_tit_ticket.png')} forwardTabs={TICKET_ENTRANCE_AND_SEAT_TAB} currentPath={match.path} />
      <ContentTemplate>
        <PageTitleTemplate>
          <BreadcrumbContainer page={TICKET_ENTRANCE} />
        </PageTitleTemplate>
        <SubContentTemplate className="ticket seat timetable">
          <Entrance />
        </SubContentTemplate>
      </ContentTemplate>
    </PageTemplate>
  )
}

export default EntrancePage
