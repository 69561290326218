import React from 'react'

const PageTitleTemplate = ({ children }) => {

  return (
    <div className="position_maker">
      {children}
    </div>
  )
}

export default PageTitleTemplate
