import FullTextLog from 'components/auth/join/FullTextLog'
import ViewtextPage from 'components/auth/join/ViewtextPage'
import React, { useState } from 'react'

const IndiLogContainer = () => {
    const [textLog,setTextLog] = useState('text') 

    const onClickFirstBtn = React.useCallback((dateString) =>{
        setTextLog(dateString)
    },[textLog])

    const setTextData = (logStringData) =>{
        setTextLog(logStringData)
    }

    if (textLog === 'text') {
        return < ViewtextPage setTextData={setTextData} />
    }

    if(textLog === 'menu'){
    return (
    <div>
        <h1 className='log_title'>개인정보 수집·이용 동의</h1>
        <button className='btn_textlog' onClick={()=>onClickFirstBtn('20210506')} ><span className='btn_title'>1차 개인정보 수집·이용 동의</span> <span className='btn_subtitle'>(2021.05.06~2021.12.07)</span></button>
        <button className='btn_textlog' onClick={()=>onClickFirstBtn('20211207')} ><span className='btn_title'>2차 개인정보 수집·이용 동의</span> <span className='btn_subtitle'>(2021.12.07~2023.01.19)</span></button>
    </div>
    )
    }else if(textLog === '20211207'){
     return (
     <>
     <FullTextLog fullDateString={'20211207'} />
     <button className='btn_textlog' onClick={()=>onClickFirstBtn('menu')} >개인정보 수집·이용 동의 확인</button>
     </>
     )
    }else if(textLog === '20210506'){
     return (
     <>
     <FullTextLog fullDateString={'20210506'} />
     <button className='btn_textlog' onClick={()=>onClickFirstBtn('menu')} >개인정보 수집·이용 동의 확인</button>
     </>
     )
    }else{
        return<></>
    }

}

export default IndiLogContainer