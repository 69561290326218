import ContentTemplate from "components/common/ContentTemplate";
import PageTemplate from "components/common/PageTemplate";
import React from "react";
import { thirdPresentLogEn } from "../../privacyPolicyTextEn";

const MobileThirdTermsEnPage = () => {
  return (
    <PageTemplate>
      <ContentTemplate className="sign_up_step1">
        <h1 className="service_compare_title">Guidance on Provision of Personal Information to Third Parties</h1>
        {thirdPresentLogEn.map((item, idx) => (
          <p key={idx}>{item}</p>
        ))}
      </ContentTemplate>
    </PageTemplate>
  );
};

export default MobileThirdTermsEnPage;
