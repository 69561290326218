import { put, all, call, fork, takeEvery, select } from 'redux-saga/effects'
import { getJoinTerms, isDeplicateUserId, requestAuthNo, requestJoin } from '../../api/join/join'
import * as actions from '../../modules/join/join'

function* joinTerms(action) {
  const response = yield call(getJoinTerms);

  const terms = response.data.data.list.reduce(function (acc, cur, i) {
    acc[cur.termsKind] = cur

    return acc
  }, {});


  yield put({
    type: actions.JOIN_TERM_SUCCESS,
    terms: terms
  });
}

function* watchJoinTerms() {
  yield takeEvery(actions.JOIN_TERM_REQUEST, joinTerms)
}

function* checkUserId(action) {
  const response = yield call(isDeplicateUserId, action.userId);
  const isDuplicate = response.data.status !== 0 || response.data.data.duplication === 'Y'
  const realNameRequestSeq = (action.reqType === 'USER' && !isDuplicate) ? response.data.data.realNameRequestSeq : null

  yield put({
    type: actions.CHECK_USER_ID_SUCCESS,
    isDuplicate: isDuplicate,
    reqType: action.reqType,
    realNameRequestSeq: realNameRequestSeq
  });
}

function* watchCheckUserId() {
  yield takeEvery(actions.CHECK_USER_ID_REQUEST, checkUserId)
}

function* callRequestAuthNo(action) {
  const response = yield call(requestAuthNo, action.mobpNo);

  yield put({
    type: actions.AUTH_NO_SUCCESS,
    authNo: response.data.data.authNo
  });
}

function* watchRequestAuthNo() {
  yield takeEvery(actions.AUTH_NO_REQUEST, callRequestAuthNo)
}

function* join(action) {

  const state = yield select()
  const response = yield call(requestJoin, action.joinUser, state.join.approvedTerms);

  if (response.data.status === 0) {
    yield put({
      type: actions.JOIN_SUCCESS,
      memberId: response.data.data.memberId
    });
  } else {
    yield put({
      type: actions.JOIN_FAILURE,
      error: { code: response.data.status, message: response.data.message }
    });
  }
}

function* watchJoin() {
  yield takeEvery(actions.JOIN_REQUEST, join)
}

export default function* joinSaga() {
  yield all([
    fork(watchJoinTerms),
    fork(watchJoin),
    fork(watchCheckUserId),
    fork(watchRequestAuthNo),
  ])
}
