import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import TeamRanking from '../../components/game/TeamRanking'
import { teamRankingRequest, teamPitchingRankingRequest, teamBattingRankingRequest, teamWinLoseListRequest, periodTeamRankRequest } from '../../modules/game/ranking'

const RegularLeagueBatterRankingContainer = ({ match }) => {


  const teamRankingList = useSelector(state => state.wizData.data && state.wizData.data['TEAM_RANKING_LIST'])
  // const teamRankingListError = useSelector(state => state.wizData.error && state.wizData.error['TEAM_RANKING_LIST'])

  const teamPitchingRankingList = useSelector(state => state.wizData.data && state.wizData.data['TEAM_PITCHING_RANKING_LIST'])
  // const teamPitchingRankingListError = useSelector(state => state.wizData.error && state.wizData.error['TEAM_PITCHING_RANKING_LIST'])

  const teamBattingRankingList = useSelector(state => state.wizData.data && state.wizData.data['TEAM_BATTING_RANKING_LIST'])
  // const teamBattingRankingListError = useSelector(state => state.wizData.error && state.wizData.error['TEAM_BATTING_RANKING_LIST'])

  const teamWinLoseList = useSelector(state => state.wizData.data && state.wizData.data['TEAM_WINLOSE_LIST'])
  // const teamWinLoseListError = useSelector(state => state.wizData.error && state.wizData.error['TEAM_WINLOSE_LIST'])

  const periodTeamRankList = useSelector(state => state.wizData.data && state.wizData.data['PERIOD_TEAM_RANK'])
  // const periodTeamRankListError = useSelector(state => state.wizData.error && state.wizData.error['PERIOD_TEAM_RANK'])

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(teamRankingRequest({}, 'TEAM_RANKING_LIST'))
    dispatch(teamPitchingRankingRequest({ 'gameSearch.teamType': '0' }, 'TEAM_PITCHING_RANKING_LIST'))
    dispatch(teamBattingRankingRequest({ 'gameSearch.teamType': '0' }, 'TEAM_BATTING_RANKING_LIST'))
    dispatch(teamWinLoseListRequest({}, 'TEAM_WINLOSE_LIST'))
    dispatch(periodTeamRankRequest({}, 'PERIOD_TEAM_RANK'))
  }, [])


  return (
    <TeamRanking match={match} teamRankingList={teamRankingList} teamPitchingList={teamPitchingRankingList} teamBattingList={teamBattingRankingList} teamWinLoseList={teamWinLoseList} periodTeamRankList={periodTeamRankList} />
  )
}

export default RegularLeagueBatterRankingContainer
