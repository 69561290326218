import React from 'react'
import Link from '../../common/Link'

const PitcherDetailSecondPcTabRegular = ({ dataTable, seasonsummary, onClickIsFuturesLeague, selectedGyear }) => {
  return (
    <>
      {/* 시즌 정규리그 기록 pc */}
      <article className="player_record_content regular_league pc_mode">
        <div className="player_record">
          <div className="container">
            <h5>{selectedGyear} {dataTable.title}</h5>
            <Link onClick={onClickIsFuturesLeague} className="btn_futures">퓨처스리그 기록 보기</Link>
          </div>
          <div className="container table">
            <table className="btD7161b">
              <caption>{selectedGyear} {dataTable.title}</caption>
              <colgroup>
                {
                  dataTable.data.map((v, i) => (
                    (v.lineNum === 1) &&
                    <col key={i} width={v.cols} />
                  ))
                }
              </colgroup>
              <thead>
                <tr>
                  {
                    dataTable.data.map((v, i) => (
                      (v.lineNum === 1) &&
                      <th key={i}>{v.head}</th>
                    ))
                  }
                </tr>
              </thead>
              <tbody>
                <tr>
                  {
                    seasonsummary
                      ? dataTable.data.map((v, i) => (
                        (v.lineNum === 1) &&
                        <td key={i}>{seasonsummary[v.bodyParam]}</td>
                      ))
                      : <td colSpan={dataTable.data.length}>데이터가 없습니다.</td>
                  }
                </tr>
              </tbody>
            </table>
            <table>
              <caption>{selectedGyear} {dataTable.title}</caption>
              <colgroup>
                {
                  dataTable.data.map((v, i) => (
                    (v.lineNum === 2) &&
                    <col key={i} width={v.cols} />
                  ))
                }
              </colgroup>
              <thead>
                <tr>
                  {
                    dataTable.data.map((v, i) => (
                      (v.lineNum === 2) &&
                      <th key={i}>{v.head}</th>
                    ))
                  }
                </tr>
              </thead>
              <tbody>
                <tr>
                  {
                    seasonsummary
                      ? dataTable.data.map((v, i) => (
                        (v.lineNum === 2) &&
                        <td key={i}>{seasonsummary[v.bodyParam]}</td>
                      ))
                      : <td colSpan={dataTable.data.length}>데이터가 없습니다.</td>
                  }
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </article>
      {/* // 시즌 정규리그 기록 pc */}
    </>
  )
}

export default PitcherDetailSecondPcTabRegular
