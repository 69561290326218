import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { createListRequest } from '../../modules/player/playerList'
import PlayerListItem from './PlayerListItem'
import Prepairing from '../common/Prepairing'

const PlayerList = ({ searchPlayerName, type }) => {

  const list = useSelector(state => state.wizData.data && state.wizData.data[type])
  // const error = useSelector(state => state.wizData.error && state.wizData.error[type])
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(createListRequest({ 'player.playerName': searchPlayerName }, type))
  }, [type])

  const isPrePage = process.env.REACT_APP_IS_PREPAIRING_PAGE

  return (
    <>
      {/* {
        isPrePage === "Y" &&
        <Prepairing />
      } */}

      {
        // isPrePage === "N" &&
        <>
          <div className="player_list grid_view">
            <ul>
              <PlayerListItem type={type} search={searchPlayerName} list={list} />
            </ul>
          </div>
          <div className="page_btn_wrap"></div>
        </>
      }
    </>
  )
}

export default PlayerList
