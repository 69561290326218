import React, { useState } from 'react'
import Link from '../common/Link'

const BatterRankingTable = ({ batterList, allBatterList, northBatterList, southBatterList, tab, year, sortKey, onChangeSort, onChangeYear, yearList }) => {

  const [selectYear, setSelectYear] = useState(null)

  const [showYearComboBox, setShowYearComboBox] = useState(false)

  if (yearList && selectYear == null) {
    setSelectYear(yearList[0].gyear)
  }

  const PcKtBatter = ({ batter }) => {
    return (
      <tr class={batter.paFlag === '1' ? 'selected' : ''}>
        <td>{batter.playerName}</td>
        <td>{batter.teamName}</td>
        <td>{batter.hra}</td>
        <td>{batter.gamenum}</td>
        <td>{batter.ab}</td>
        <td>{batter.run}</td>
        <td>{batter.hit}</td>
        <td>{batter.h2}</td>
        <td>{batter.h3}</td>
        <td>{batter.hr}</td>
        <td>{batter.rbi}</td>
        <td>{batter.sb}</td>
        <td>{batter.bb}</td>
        <td>{batter.hp}</td>
        <td>{batter.kk}</td>
        <td>{batter.slg}</td>
        <td>{batter.bra}</td>
      </tr>
    )
  }

  const PcAllBatter = ({ batter, index }) => {
    return (
      <tr className={batter.teamName === 'KT' ? 'selected' : ''}>
        <td>{index + 1}</td>
        <td>{batter.playerName}</td>
        <td>{batter.teamName}</td>
        <td>{batter.hra}</td>
        <td>{batter.gamenum}</td>
        <td>{batter.ab}</td>
        <td>{batter.run}</td>
        <td>{batter.hit}</td>
        <td>{batter.h2}</td>
        <td>{batter.h3}</td>
        <td>{batter.hr}</td>
        <td>{batter.rbi}</td>
        <td>{batter.sb}</td>
        <td>{batter.bb}</td>
        <td>{batter.hp}</td>
        <td>{batter.kk}</td>
        <td>{batter.slg}</td>
        <td>{batter.bra}</td>
      </tr>
    )
  }

  const MobileKtBatter = ({ batter, index }) => {
    return (
      <tr className={batter.paFlag === '1' ? 'selected' : ''}>
        <td className="hide">{batter.playerName}</td>
        <td>{batter.hra}</td>
        <td>{batter.gamenum}</td>
        <td>{batter.ab}</td>
        <td>{batter.run}</td>
        <td>{batter.hit}</td>
        <td>{batter.h2}</td>
        <td>{batter.h3}</td>
        <td>{batter.hr}</td>
        <td>{batter.rbi}</td>
        <td>{batter.sb}</td>
        <td>{batter.bb}</td>
        <td>{batter.hp}</td>
        <td>{batter.kk}</td>
        <td>{batter.slg}</td>
        <td>{batter.bra}</td>
      </tr>
    )
  }

  return (
    <div class="game_record_detail">
      <div class="container">
        <div class="notice"><p>※각 항목을 클릭하시면 순위를 보실 수 있습니다.</p></div>
        <section class="rank_record_list catcher_record">
          <article class="rank_record_content ktwiz_catcher pc_mode" style={{ display: tab === 'regular_league' ? 'block' : 'none' }}>
            <div class="rank_record">
              <table>
                <caption>kt wiz 타자</caption>
                <colgroup>
                  <col width="90px" />
                  <col width="61px" />
                  <col width="*" />
                  <col width="61px" />
                  <col width="61px" />
                  <col width="61px" />
                  <col width="61px" />
                  <col width="61px" />
                  <col width="61px" />
                  <col width="61px" />
                  <col width="61px" />
                  <col width="61px" />
                  <col width="61px" />
                  <col width="61px" />
                  <col width="61px" />
                  <col width="63px" />
                  <col width="63px" />
                </colgroup>
                <thead>
                  <tr>
                    <th>선수명</th>
                    <th>팀명</th>
                    <th className={sortKey === 'HRA' ? 'selected' : ''}><Link onClick={() => onChangeSort('HRA')}>타율<span class="down"></span></Link></th>
                    <th className={sortKey === 'GAMENUM' ? 'selected' : ''}><Link onClick={() => onChangeSort('GAMENUM')}>경기<span class="down"></span></Link></th>
                    <th className={sortKey === 'AB' ? 'selected' : ''}><Link onClick={() => onChangeSort('AB')}>타수<span class="down"></span></Link></th>
                    <th className={sortKey === 'RUN' ? 'selected' : ''}><Link onClick={() => onChangeSort('RUN')}>득점<span class="down"></span></Link></th>
                    <th className={sortKey === 'HIT' ? 'selected' : ''}><Link onClick={() => onChangeSort('HIT')}>안타<span class="down"></span></Link></th>
                    <th className={sortKey === 'H2' ? 'selected' : ''}><Link onClick={() => onChangeSort('H2')}>2루타<span class="down"></span></Link></th>
                    <th className={sortKey === 'H3' ? 'selected' : ''}><Link onClick={() => onChangeSort('H3')}>3루타<span class="down"></span></Link></th>
                    <th className={sortKey === 'HR' ? 'selected' : ''}><Link onClick={() => onChangeSort('HR')}>홈런<span class="down"></span></Link></th>
                    <th className={sortKey === 'RBI' ? 'selected' : ''}><Link onClick={() => onChangeSort('RBI')}>타점<span class="down"></span></Link></th>
                    <th className={sortKey === 'SB' ? 'selected' : ''}><Link onClick={() => onChangeSort('SB')}>도루<span class="down"></span></Link></th>
                    <th className={sortKey === 'BB' ? 'selected' : ''}><Link onClick={() => onChangeSort('BB')}>볼넷<span class="down"></span></Link></th>
                    <th className={sortKey === 'HP' ? 'selected' : ''}><Link onClick={() => onChangeSort('HP')}>사구<span class="down"></span></Link></th>
                    <th className={sortKey === 'KK' ? 'selected' : ''}><Link onClick={() => onChangeSort('KK')}>삼진<span class="down"></span></Link></th>
                    <th className={sortKey === 'SLG' ? 'selected' : ''}><Link onClick={() => onChangeSort('SLG')}>장타율<span class="down"></span></Link></th>
                    <th className={sortKey === 'BRA' ? 'selected' : ''}><Link onClick={() => onChangeSort('BRA')}>출루율<span class="down"></span></Link></th>
                  </tr>
                </thead>
                <tbody>
                  {batterList && batterList.map((batter, index) => <PcKtBatter batter={batter} index={index} />)}
                </tbody>
              </table>
            </div>
          </article>

          <article class="rank_record_content total_catcher_rank pc_mode" style={{ display: tab === 'latest_game' ? 'block' : 'none' }}>
            <div class="rank_record">
              <table>
                <caption>전체 타자 순위</caption>
                <colgroup>
                  <col width="41px" />
                  <col width="71px" />
                  <col width="60px" />
                  <col width="*" />
                  <col width="60px" />
                  <col width="60px" />
                  <col width="60px" />
                  <col width="60px" />
                  <col width="60px" />
                  <col width="62px" />
                  <col width="62px" />
                  <col width="60px" />
                  <col width="60px" />
                  <col width="60px" />
                  <col width="60px" />
                  <col width="60px" />
                  <col width="60px" />
                  <col width="60px" />
                </colgroup>
                <thead>
                  <tr>
                    <th>순위</th>
                    <th>선수명</th>
                    <th>팀명</th>
                    <th className={sortKey === 'HRA' ? 'selected' : ''}><Link onClick={() => onChangeSort('HRA')}>타율<span class="down"></span></Link></th>
                    <th className={sortKey === 'GAMENUM' ? 'selected' : ''}><Link onClick={() => onChangeSort('GAMENUM')}>경기<span class="down"></span></Link></th>
                    <th className={sortKey === 'AB' ? 'selected' : ''}><Link onClick={() => onChangeSort('AB')}>타수<span class="down"></span></Link></th>
                    <th className={sortKey === 'RUN' ? 'selected' : ''}><Link onClick={() => onChangeSort('RUN')}>득점<span class="down"></span></Link></th>
                    <th className={sortKey === 'HIT' ? 'selected' : ''}><Link onClick={() => onChangeSort('HIT')}>안타<span class="down"></span></Link></th>
                    <th className={sortKey === 'H2' ? 'selected' : ''}><Link onClick={() => onChangeSort('H2')}>2루타<span class="down"></span></Link></th>
                    <th className={sortKey === 'H3' ? 'selected' : ''}><Link onClick={() => onChangeSort('H3')}>3루타<span class="down"></span></Link></th>
                    <th className={sortKey === 'HR' ? 'selected' : ''}><Link onClick={() => onChangeSort('HR')}>홈런<span class="down"></span></Link></th>
                    <th className={sortKey === 'RBI' ? 'selected' : ''}><Link onClick={() => onChangeSort('RBI')}>타점<span class="down"></span></Link></th>
                    <th className={sortKey === 'SB' ? 'selected' : ''}><Link onClick={() => onChangeSort('SB')}>도루<span class="down"></span></Link></th>
                    <th className={sortKey === 'BB' ? 'selected' : ''}><Link onClick={() => onChangeSort('BB')}>볼넷<span class="down"></span></Link></th>
                    <th className={sortKey === 'HP' ? 'selected' : ''}><Link onClick={() => onChangeSort('HP')}>사구<span class="down"></span></Link></th>
                    <th className={sortKey === 'KK' ? 'selected' : ''}><Link onClick={() => onChangeSort('KK')}>삼진<span class="down"></span></Link></th>
                    <th className={sortKey === 'SLG' ? 'selected' : ''}><Link onClick={() => onChangeSort('SLG')}>장타율<span class="down"></span></Link></th>
                    <th className={sortKey === 'BRA' ? 'selected' : ''}><Link onClick={() => onChangeSort('BRA')}>출루율<span class="down"></span></Link></th>
                  </tr>
                </thead>
                <tbody>
                  {allBatterList && allBatterList.map((batter, index) => <PcAllBatter batter={batter} index={index} />)}
                </tbody>
              </table>
            </div>
          </article>

          <article class="rank_record_content total_catcher_rank pc_mode" style={{ display: tab === 'north_game' ? 'block' : 'none' }}>
            <div class="rank_record">
              <table>
                <caption>전체 타자 순위</caption>
                <colgroup>
                  <col width="41px" />
                  <col width="71px" />
                  <col width="60px" />
                  <col width="*" />
                  <col width="60px" />
                  <col width="60px" />
                  <col width="60px" />
                  <col width="60px" />
                  <col width="60px" />
                  <col width="62px" />
                  <col width="62px" />
                  <col width="60px" />
                  <col width="60px" />
                  <col width="60px" />
                  <col width="60px" />
                  <col width="60px" />
                  <col width="60px" />
                  <col width="60px" />
                </colgroup>
                <thead>
                  <tr>
                    <th>순위</th>
                    <th>선수명</th>
                    <th>팀명</th>
                    <th className={sortKey === 'HRA' ? 'selected' : ''}><Link onClick={() => onChangeSort('HRA')}>타율<span class="down"></span></Link></th>
                    <th className={sortKey === 'GAMENUM' ? 'selected' : ''}><Link onClick={() => onChangeSort('GAMENUM')}>경기<span class="down"></span></Link></th>
                    <th className={sortKey === 'AB' ? 'selected' : ''}><Link onClick={() => onChangeSort('AB')}>타수<span class="down"></span></Link></th>
                    <th className={sortKey === 'RUN' ? 'selected' : ''}><Link onClick={() => onChangeSort('RUN')}>득점<span class="down"></span></Link></th>
                    <th className={sortKey === 'HIT' ? 'selected' : ''}><Link onClick={() => onChangeSort('HIT')}>안타<span class="down"></span></Link></th>
                    <th className={sortKey === 'H2' ? 'selected' : ''}><Link onClick={() => onChangeSort('H2')}>2루타<span class="down"></span></Link></th>
                    <th className={sortKey === 'H3' ? 'selected' : ''}><Link onClick={() => onChangeSort('H3')}>3루타<span class="down"></span></Link></th>
                    <th className={sortKey === 'HR' ? 'selected' : ''}><Link onClick={() => onChangeSort('HR')}>홈런<span class="down"></span></Link></th>
                    <th className={sortKey === 'RBI' ? 'selected' : ''}><Link onClick={() => onChangeSort('RBI')}>타점<span class="down"></span></Link></th>
                    <th className={sortKey === 'SB' ? 'selected' : ''}><Link onClick={() => onChangeSort('SB')}>도루<span class="down"></span></Link></th>
                    <th className={sortKey === 'BB' ? 'selected' : ''}><Link onClick={() => onChangeSort('BB')}>볼넷<span class="down"></span></Link></th>
                    <th className={sortKey === 'HP' ? 'selected' : ''}><Link onClick={() => onChangeSort('HP')}>사구<span class="down"></span></Link></th>
                    <th className={sortKey === 'KK' ? 'selected' : ''}><Link onClick={() => onChangeSort('KK')}>삼진<span class="down"></span></Link></th>
                    <th className={sortKey === 'SLG' ? 'selected' : ''}><Link onClick={() => onChangeSort('SLG')}>장타율<span class="down"></span></Link></th>
                    <th className={sortKey === 'BRA' ? 'selected' : ''}><Link onClick={() => onChangeSort('BRA')}>출루율<span class="down"></span></Link></th>
                  </tr>
                </thead>
                <tbody>
                  {northBatterList && northBatterList.map((batter, index) => <PcAllBatter batter={batter} index={index} />)}
                </tbody>
              </table>
            </div>
          </article>

          <article class="rank_record_content total_catcher_rank pc_mode" style={{ display: tab === 'south_game' ? 'block' : 'none' }}>
            <div class="rank_record">
              <table>
                <caption>전체 타자 순위</caption>
                <colgroup>
                  <col width="41px" />
                  <col width="71px" />
                  <col width="60px" />
                  <col width="*" />
                  <col width="60px" />
                  <col width="60px" />
                  <col width="60px" />
                  <col width="60px" />
                  <col width="60px" />
                  <col width="62px" />
                  <col width="62px" />
                  <col width="60px" />
                  <col width="60px" />
                  <col width="60px" />
                  <col width="60px" />
                  <col width="60px" />
                  <col width="60px" />
                  <col width="60px" />
                </colgroup>
                <thead>
                  <tr>
                    <th>순위</th>
                    <th>선수명</th>
                    <th>팀명</th>
                    <th className={sortKey === 'HRA' ? 'selected' : ''}><Link onClick={() => onChangeSort('HRA')}>타율<span class="down"></span></Link></th>
                    <th className={sortKey === 'GAMENUM' ? 'selected' : ''}><Link onClick={() => onChangeSort('GAMENUM')}>경기<span class="down"></span></Link></th>
                    <th className={sortKey === 'AB' ? 'selected' : ''}><Link onClick={() => onChangeSort('AB')}>타수<span class="down"></span></Link></th>
                    <th className={sortKey === 'RUN' ? 'selected' : ''}><Link onClick={() => onChangeSort('RUN')}>득점<span class="down"></span></Link></th>
                    <th className={sortKey === 'HIT' ? 'selected' : ''}><Link onClick={() => onChangeSort('HIT')}>안타<span class="down"></span></Link></th>
                    <th className={sortKey === 'H2' ? 'selected' : ''}><Link onClick={() => onChangeSort('H2')}>2루타<span class="down"></span></Link></th>
                    <th className={sortKey === 'H3' ? 'selected' : ''}><Link onClick={() => onChangeSort('H3')}>3루타<span class="down"></span></Link></th>
                    <th className={sortKey === 'HR' ? 'selected' : ''}><Link onClick={() => onChangeSort('HR')}>홈런<span class="down"></span></Link></th>
                    <th className={sortKey === 'RBI' ? 'selected' : ''}><Link onClick={() => onChangeSort('RBI')}>타점<span class="down"></span></Link></th>
                    <th className={sortKey === 'SB' ? 'selected' : ''}><Link onClick={() => onChangeSort('SB')}>도루<span class="down"></span></Link></th>
                    <th className={sortKey === 'BB' ? 'selected' : ''}><Link onClick={() => onChangeSort('BB')}>볼넷<span class="down"></span></Link></th>
                    <th className={sortKey === 'HP' ? 'selected' : ''}><Link onClick={() => onChangeSort('HP')}>사구<span class="down"></span></Link></th>
                    <th className={sortKey === 'KK' ? 'selected' : ''}><Link onClick={() => onChangeSort('KK')}>삼진<span class="down"></span></Link></th>
                    <th className={sortKey === 'SLG' ? 'selected' : ''}><Link onClick={() => onChangeSort('SLG')}>장타율<span class="down"></span></Link></th>
                    <th className={sortKey === 'BRA' ? 'selected' : ''}><Link onClick={() => onChangeSort('BRA')}>출루율<span class="down"></span></Link></th>
                  </tr>
                </thead>
                <tbody>
                  {southBatterList && southBatterList.map((batter, index) => <PcAllBatter batter={batter} index={index} />)}
                </tbody>
              </table>
            </div>
          </article>

          <article class="rank_record_content catcher_rank mb_mode">
            <div class="rank_record">
              <h5>타자기록</h5>
              <div className="season_select_box">
                <button type="button" className="btn_season" onClick={() => setShowYearComboBox(true)}>{year} 시즌</button>
                {showYearComboBox && (
                  <div className="select_box" style={{ display: 'block' }}>
                    <form name="season_select_frm" action="" method="">
                      {yearList && yearList.map((y, index) => <label><input type="radio" name="season" value={y.gyear} checked={selectYear === y.gyear} onClick={() => setSelectYear(y.gyear)} />{y.gyear} 시즌</label>)}
                    </form>
                    <button type="submit" className="btn_submit" onClick={() => { onChangeYear(selectYear); setShowYearComboBox(false) }}>완료</button>
                  </div>
                )}
              </div>
              <div class="fixed_table_content">
                <div class="table_wrap fixed">
                  <table>
                    <caption>타자기록</caption>
                    <colgroup>
                      <col className="hide" width="90px" />
                      <col width="*" />
                      <col width="65px" />
                      <col width="65px" />
                      <col width="65px" />
                      <col width="65px" />
                      <col width="65px" />
                      <col width="65px" />
                      <col width="65px" />
                      <col width="65px" />
                      <col width="65px" />
                      <col width="65px" />
                      <col width="65px" />
                      <col width="65px" />
                      <col width="67px" />
                      <col width="67px" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th className="hide">선수명</th>
                        <th className={sortKey === 'HRA' ? 'selected' : ''}><Link onClick={() => onChangeSort('HRA')}>타율<span class="down"></span></Link></th>
                        <th className={sortKey === 'GAMENUM' ? 'selected' : ''}><Link onClick={() => onChangeSort('GAMENUM')}>경기<span class="down"></span></Link></th>
                        <th className={sortKey === 'AB' ? 'selected' : ''}><Link onClick={() => onChangeSort('AB')}>타수<span class="down"></span></Link></th>
                        <th className={sortKey === 'RUN' ? 'selected' : ''}><Link onClick={() => onChangeSort('RUN')}>득점<span class="down"></span></Link></th>
                        <th className={sortKey === 'HIT' ? 'selected' : ''}><Link onClick={() => onChangeSort('HIT')}>안타<span class="down"></span></Link></th>
                        <th className={sortKey === 'H2' ? 'selected' : ''}><Link onClick={() => onChangeSort('H2')}>2루타<span class="down"></span></Link></th>
                        <th className={sortKey === 'H3' ? 'selected' : ''}><Link onClick={() => onChangeSort('H3')}>3루타<span class="down"></span></Link></th>
                        <th className={sortKey === 'HR' ? 'selected' : ''}><Link onClick={() => onChangeSort('HR')}>홈런<span class="down"></span></Link></th>
                        <th className={sortKey === 'RBI' ? 'selected' : ''}><Link onClick={() => onChangeSort('RBI')}>타점<span class="down"></span></Link></th>
                        <th className={sortKey === 'SB' ? 'selected' : ''}><Link onClick={() => onChangeSort('SB')}>도루<span class="down"></span></Link></th>
                        <th className={sortKey === 'BB' ? 'selected' : ''}><Link onClick={() => onChangeSort('BB')}>볼넷<span class="down"></span></Link></th>
                        <th className={sortKey === 'HP' ? 'selected' : ''}><Link onClick={() => onChangeSort('HP')}>사구<span class="down"></span></Link></th>
                        <th className={sortKey === 'KK' ? 'selected' : ''}><Link onClick={() => onChangeSort('KK')}>삼진<span class="down"></span></Link></th>
                        <th className={sortKey === 'SLG' ? 'selected' : ''}><Link onClick={() => onChangeSort('SLG')}>장타율<span class="down"></span></Link></th>
                        <th className={sortKey === 'BRA' ? 'selected' : ''}><Link onClick={() => onChangeSort('BRA')}>출루율<span class="down"></span></Link></th>
                      </tr>
                    </thead>
                    <tbody>
                      {batterList && batterList.map((batter, index) => <MobileKtBatter batter={batter} index={index} />)}
                    </tbody>
                  </table>
                  <table class="fixed_table">
                    <thead>
                      <tr>
                        <th>선수명</th>
                      </tr>
                    </thead>
                    <tbody>
                      {batterList && batterList.map((batter, index) => <tr className={batter.paFlag === '1' ? 'selected' : ''}><td><a href="">{batter.playerName}</a></td></tr>)}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </article>

        </section>
      </div>
    </div >
  )
}

export default BatterRankingTable
