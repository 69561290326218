import ContentTemplate from "components/common/ContentTemplate";
import PageTemplate from "components/common/PageTemplate";
import React from "react";
import { thirdPresentLog } from "../privacyPolicyText";

const MobileThirdTermsPage = () => {
  return (
    <PageTemplate>
      <ContentTemplate className="sign_up_step1">
        <h1 className="indi_title">개인정보 제 3자 제공에 대한 안내</h1>
        {thirdPresentLog.map((item, idx) => (
          <p key={idx}>{item}</p>
        ))}
      </ContentTemplate>
    </PageTemplate>
  );
};

export default MobileThirdTermsPage;
