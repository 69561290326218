import React from 'react'
import GraphWrap from './GraphWrap'
import numeral from 'numeral'

const FuturesTeamRanking = ({ match, year, teamRankingList, periodTeamRankList }) => {

  const TeamRankingRow = ({ team, index }) => {
    console.log(team)
    return (
      <tr className={team.teamName === 'KT' ? 'kt' : ''}>
        <td className="mb_hide">{team.rank}</td>
        <td className="mb_hide">{team.teamName}</td>
        <td>{team.game}</td>
        <td>{team.win}</td>
        <td>{team.lose}</td>
        <td>{team.drawn}</td>
        <td>{team.wra}</td>
        <td>{numeral(team.ab).format('0,0')}</td>
        <td>{team.cont}</td>
        <td>{team.lra}</td>
        <td>{team.bra}</td>
        <td>{team.hra}</td>
        <td>{team.er}</td>
        <td>{team.run}</td>
        <td>{team.r}</td>
        <td>{team.hr}</td>
      </tr>
    )
  }

  return (
    <div className="rank_record_wrap team_rank">

      <div className="season_team_rank">
        <article>
          <h4>{teamRankingList && teamRankingList[0].gyear} 시즌 퓨처스 남부리그 팀 순위</h4>
          <GraphWrap match={match} xAxisList={periodTeamRankList && periodTeamRankList.map(team => team.date.substring(4, 6) + '.' + team.date.substring(6, 8))} valueList={periodTeamRankList && periodTeamRankList.map(team => team.rank)} />
        </article>
      </div>

      <div className="season_team_record">
        <article>
          <h4>{teamRankingList && teamRankingList[0].gyear} 시즌 퓨처스 북부리그 팀 기록</h4>
          <div className="fixed_table_content">
            <div className="table_wrap fixed">
              <table>
                <caption>{teamRankingList && teamRankingList[0].gyear} 시즌 퓨처스 북부리그 팀 기록</caption>
                <colgroup>
                  <col width="65px" />
                  <col width="*" />
                  <col width="68px" />
                  <col width="68px" />
                  <col width="68px" />
                  <col width="68px" />
                  <col width="70px" />
                  <col width="70px" />
                  <col width="68px" />
                  <col width="70px" />
                  <col width="70px" />
                  <col width="70px" />
                  <col width="70px" />
                  <col width="68px" />
                  <col width="68px" />
                  <col width="68px" />
                </colgroup>
                <thead>
                  <tr>
                    <th className="mb_hide">순위</th>
                    <th className="mb_hide">팀명</th>
                    <th>경기 수</th>
                    <th>승</th>
                    <th>패</th>
                    <th>무</th>
                    <th>승률</th>
                    <th>타수</th>
                    <th>연속</th>
                    <th>출루율</th>
                    <th>장타율</th>
                    <th>타율</th>
                    <th>자책점</th>
                    <th>득점</th>
                    <th>실점</th>
                    <th>홈런</th>
                  </tr>
                </thead>
                <tbody>
                  {teamRankingList && teamRankingList.filter(team => team.league === 'NORTH').map((team, index) => <TeamRankingRow key={index} team={team} index={index} />)}
                </tbody>
              </table>
              <table className="fixed_table">
                <colgroup>
                  <col width="42px" />
                  <col width="*" />
                </colgroup>
                <thead>
                  <tr>
                    <th>순위</th>
                    <th>팀명</th>
                  </tr>
                </thead>
                <tbody>
                  {teamRankingList && teamRankingList.filter(team => team.league === 'NORTH').map((team, index) => (
                    <tr className={team.teamCode === 'KT' ? 'kt' : ''}>
                      <td>{team.rank}</td>
                      <td>{team.teamName}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </article>
      </div>

      <div className="season_team_record">
        <article>
          <h4>{teamRankingList && teamRankingList[0].gyear} 시즌 퓨처스 남부리그 팀 기록</h4>
          <div className="fixed_table_content">
            <div className="table_wrap fixed">
              <table>
                <caption>{teamRankingList && teamRankingList[0].gyear} 시즌 퓨처스 남부리그 팀 기록</caption>
                <colgroup>
                  <col width="65px" />
                  <col width="*" />
                  <col width="68px" />
                  <col width="68px" />
                  <col width="68px" />
                  <col width="68px" />
                  <col width="70px" />
                  <col width="70px" />
                  <col width="68px" />
                  <col width="70px" />
                  <col width="70px" />
                  <col width="70px" />
                  <col width="70px" />
                  <col width="68px" />
                  <col width="68px" />
                  <col width="68px" />
                </colgroup>
                <thead>
                  <tr>
                    <th className="mb_hide">순위</th>
                    <th className="mb_hide">팀명</th>
                    <th>경기 수</th>
                    <th>승</th>
                    <th>패</th>
                    <th>무</th>
                    <th>승률</th>
                    <th>타수</th>
                    <th>연속</th>
                    <th>출루율</th>
                    <th>장타율</th>
                    <th>타율</th>
                    <th>자책점</th>
                    <th>득점</th>
                    <th>실점</th>
                    <th>홈런</th>
                  </tr>
                </thead>
                <tbody>
                  {teamRankingList && teamRankingList.filter(team => team.league === 'SOUTH').map((team, index) => <TeamRankingRow key={index} team={team} index={index} />)}
                </tbody>
              </table>
              <table className="fixed_table">
                <colgroup>
                  <col width="42px" />
                  <col width="*" />
                </colgroup>
                <thead>
                  <tr>
                    <th>순위</th>
                    <th>팀명</th>
                  </tr>
                </thead>
                <tbody>
                  {teamRankingList && teamRankingList.filter(team => team.league === 'SOUTH').map((team, index) => (
                    <tr className={team.teamName === 'KT' ? 'kt' : ''}>
                      <td>{team.rank}</td>
                      <td>{team.teamName}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </article>
      </div>
    </div>
  )
}

export default FuturesTeamRanking
