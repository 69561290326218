import React from 'react'
import Link from '../../common/Link'


const Parking = () => {
  return (
    <>
      <div className='title'>
        <h6>주차 예약 안내</h6>
      </div>
      <div className='body'>
        <ul className='guide'>
          <li>
            <span className='hyphen'>-</span>
            <p>
              수원종합운동장 (수원 kt wiz park 포함) 주차장은 프로야구 <strong className='red'>경기일에만 주차예약제</strong>가
              시행됩니다.
            </p>
          </li>
          <li>
            <span className='hyphen'>-</span>
            <p>
              <strong className='red'>미예약 차량 주차 불가</strong> : 사전 예약차량만 주차 가능
            </p>
          </li>
          <li>
            <span className='hyphen'>-</span>
            <p>
              <strong>
                통제시간 : 경기시간 2시간 전부터
              </strong>
            </p>
          </li>
        </ul>
        <div className='howto'>
          <div className='tit'>
            <div className='icon'></div>
            <h6>
              예약방법
            </h6>
          </div>
          <div className='item'>
            <h6>
              ① 홈페이지
            </h6>
            <p className='pc'>
              kt wiz,수원종합운동장 홈페이지를 통한 예약 / 수정 및 취소 가능
            </p>
            <p className='m'>
              kt wiz,수원종합운동장 홈페이지를 통한<br />
              예약 / 수정 및 취소 가능
            </p>
          </div>
          <div className='item'>
            <h6>
              ② 예약 및 문의
            </h6>
            <p>
              수원종합운동장 031-240-2720~1
            </p>
          </div>
          <div className='item'>
            <h6>
              ③ 예약 기간
            </h6>
            <p className='pc'>
              수원 홈경기 프로야구 입장권 예매기간(kt wiz 홈경기 7일전 14시 00분부터 만차시)
            </p>
            <p className='m'>
              수원 홈경기 프로야구 입장권 예매기간<br />
              (kt wiz 홈경기 7일전 14시 00분부터 만차시)
            </p>
          </div>
          <div className='item'>
            <h6>
              ④ 예약 제외 차량
            </h6>
            <p>
              25인승 소형버스 이상 및 장애인 차량<br />
              : 입차 시 요금만 납부
            </p>
          </div>

          <div className='buttons'>
            <Link to='https://suwonparkingbaseball.or.kr/suwonwps/EgovPageLink.do?link=main/menu/prs/ParkingReservationPrivacy' className='btn-reserve' isExt={true}>예약하기</Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default Parking
