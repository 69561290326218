import React from 'react'
import ContentTemplate from '../../components/common/ContentTemplate'
import SubContentTemplate from '../../components/common/SubContentTemplate'
import PageHeaderContainer from '../../containers/common/PageHeaderContainer'
import PageTitleTemplate from '../../components/common/PageTitleTemplate'
import BreadcrumbContainer from '../../containers/common/BreadcrumbContainer'
import PageTemplate from '../../components/common/PageTemplate'
import { WIZPARK_PARKING, WIZPARK_PARKING_AND_SHUTTLE_TAB } from '../../constants/page'
import Parking from '../../components/wizpark/parking/Parking'

const WizparkParkingPage = ({ match }) => {
  return (
    <PageTemplate className="park">
      <PageHeaderContainer title="주차예약" subtitle=" 사전 주차 예약을 안내드립니다." image={require('../../assets/img/park/sub_tit_park.png')} forwardTabs={WIZPARK_PARKING_AND_SHUTTLE_TAB} currentPath={match.path} />
      <ContentTemplate>
        <PageTitleTemplate>
          <BreadcrumbContainer page={WIZPARK_PARKING} />
        </PageTitleTemplate>
        <SubContentTemplate className="park parking reservation">
          <Parking />
        </SubContentTemplate>
      </ContentTemplate>
    </PageTemplate>
  )
}

export default WizparkParkingPage
