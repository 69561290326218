import wiz from '../../api/wiz';
import { dataRequest } from '../common/wizData';

export const createListRequest = (api, params, dataType) => {
  return dataRequest(
    api,
    params,
    dataType
  );
};

export const matrixViewRequest = (params, dataType) => createListRequest(wiz.API.GET_INTERACTIVE_LIST, { 'interactiveMedia.mediaType': 'FREE', ...params }, dataType);
export const positionViewRequest = (params, dataType) => createListRequest(wiz.API.GET_INTERACTIVE_LIST, { 'interactiveMedia.mediaType': 'OMNI', ...params }, dataType);
export const ptsRequest = (params, dataType) => createListRequest(wiz.API.GET_TRACKING_LIST, { 'trackingMedia.mediaType': 'PTS', ...params }, dataType);
export const ftsRequest = (params, dataType) => createListRequest(wiz.API.GET_TRACKING_LIST, { 'trackingMedia.mediaType': 'FTS', ...params }, dataType);