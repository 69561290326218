import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { /*selectUserType, joinTermRequest, setApprovedTerms,*/ checkUserIdRequest, clearRequestData, /*authNoRequest, setJoinUser*/ } from '../../../modules/join/join'
import DaumPostcode from 'react-daum-postcode';
import JoinStep from './JoinStep'
import * as yup from 'yup'
import { checkPassword } from '../../../modules/utils/passwd';
import * as dialogActions from "../../../modules/common/dialog"

const JoinStep3 = ({ join, joinFormCheck, cert, onCertNoRequest, onConfirmCertNo, onJoinSubmit }) => {

  console.log(join)

  const dispatch = useDispatch()

  const onCheckUserId = (userId) => dispatch(checkUserIdRequest(userId, 'USER_ID_CHECK'))

  const onCheckRecommendId = (userId) => dispatch(checkUserIdRequest(userId, 'RECOMMEND_ID_CHECK'))

  const userIdCheck = useSelector(state => state.wizData.data && state.wizData.data['USER_ID_CHECK'])
  const userIdCheckError = useSelector(state => state.wizData.error && state.wizData.error['USER_ID_CHECK'])

  const recommendIdCheck = useSelector(state => state.wizData.data && state.wizData.data['RECOMMEND_ID_CHECK'])
  const recommendIdCheckError = useSelector(state => state.wizData.error && state.wizData.error['RECOMMEND_ID_CHECK'])

  useEffect(() => {
    console.log(userIdCheck)
    if (userIdCheck) {
      if (userIdCheck.duplication === 'N') {
        setRealNameRequestSeq(userIdCheck.realNameRequestSeq)
        dispatch(dialogActions.alertOpen('사용할수 있는 아이디입니다.'))
      }
    }
    dispatch(clearRequestData('USER_ID_CHECK'))
  }, [userIdCheck])

  useEffect(() => {
    if (userIdCheckError) {
      if (userIdCheckError.status === 8000) {
        dispatch(dialogActions.alertOpen('이미 존재하는 아이디입니다.'))
      } else {
        dispatch(dialogActions.alertOpen(userIdCheckError.message))
      }
    }
    dispatch(clearRequestData('USER_ID_CHECK'))
  }, [userIdCheckError])

  useEffect(() => {
    if (recommendIdCheck) {
      if (recommendIdCheck.duplication === 'N') {
        dispatch(dialogActions.alertOpen('입력하신 추천인 아이디가 존재하지 않습니다.'))
      }
    }
    dispatch(clearRequestData('RECOMMEND_ID_CHECK'))
  }, [recommendIdCheck])

  useEffect(() => {
    if (recommendIdCheckError) {
      if (recommendIdCheckError.status === 8000) {
        dispatch(dialogActions.alertOpen('추천인 아이디가 확인되었습니다.'))
      } else {
        dispatch(dialogActions.alertOpen(recommendIdCheckError.message))
      }
    }
    dispatch(clearRequestData('RECOMMEND_ID_CHECK'))
  }, [recommendIdCheckError])

  const [simpleId, setSimpleId] = useState('')
  const [memberPw, setMemberPw] = useState('')
  const [memberPwConfirm, setMemberPwConfirm] = useState('')
  const [recommenderId, setRecommenderId] = useState('');
  const [smsAgree, setSmsAgree] = useState(true);
  const [emailAgree, setEmailAgree] = useState(true);

  const [mobpNo1, setMobpNo1] = useState('010');
  const [mobpNo2, setMobpNo2] = useState('');
  const [mobpNo3, setMobpNo3] = useState('');

  const [openPostCode, setOpenPostCode] = useState(false);

  const [postNo, setPostNo] = useState('');
  const [addr1, setAddr1] = useState('');
  const [addr2, setAddr2] = useState('');

  const [memberEmailId, setMemberEmailId] = useState('')
  const [memberEmailDomain, setMemberEmailDomain] = useState('')
  const [presetEmailDomain, setPresetEmailDomain] = useState('')

  const [childUserName, setChildUserName] = useState('')
  const [childBirthYear, setChildBirthYear] = useState('')
  const [childBirthMonth, setChildBirthMonth] = useState('')
  const [childBirthDate, setChildBirthDate] = useState('')
  const [childSex, setChildSex] = useState('')

  const [certConfirmNo, setCertConfirmNo] = useState('')

  const [realNameRequestSeq, setRealNameRequestSeq] = useState('')

  const requestAuthNo = () => {
    onCertNoRequest(`${mobpNo1}-${mobpNo2}-${mobpNo3}`)
  }

  const confirmCertNo = () => {
    onConfirmCertNo(`${mobpNo1}-${mobpNo2}-${mobpNo3}`, cert.certSeq, certConfirmNo)
  }

  const isPhoneNumber = (mobp1, mobp2, mobp3) => {
    if (!mobp1 || !mobp2 || !mobp3 || mobp1.length !== 3 || mobp2.length !== 4 || mobp3.length !== 4 || isNaN(mobp1) || isNaN(mobp2) || isNaN(mobp3)) {
      return false
    }
    return true
  }

  const handleAddress = (data) => {
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
      }
      fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
    }

    setPostNo(data.zonecode)
    setAddr1(fullAddress)
    setOpenPostCode(false)
  }

  const postCodeSearchStyle = {
    width: '300px',
    height: '400px',
    left: (((window.innerWidth || document.documentElement.clientWidth) - 300) / 2 - 5) + 'px',
    top: (((window.innerHeight || document.documentElement.clientHeight) - 400) / 2 - 5) + 'px',
    border: '5px solid',
    position: 'fixed',
    overflow: 'hidden',
    zIndex: 1,
    display: openPostCode ? 'block' : 'none'
  }

  const postCodeCloseImageStyle = {
    width: '20px',
    cursor: 'pointer',
    position: 'absolute',
    right: '-3px',
    top: '-3px',
    zIndex: 1
  }

  const schema = yup.object().shape({
    simpleId: yup.string().required('아이디를 입력해주세요'),
    memberPw: yup.string().min(8, "암호는 8자 이상이어야 합니다").required('비밀번호를 입력해주세요'),
    memberPwConfirm: yup.string().required('비밀번호 확인을 입력해주세요'),
    recommenderId: yup.string(),
    mobpNo1: yup.string().required('전화번호를 입력해주세요'),
    mobpNo2: yup.string().required('전화번호를 입력해주세요'),
    mobpNo3: yup.string().required('전화번호를 입력해주세요'),
    realNameRequestSeq: yup.string().required('아이디 중복체크를 해주세요'),
    certSeq: yup.string().required('핸드폰 인증을 해주세요'),
    certConfirmNo: yup.string().required('핸드폰 인증을 해주세요'),
    isChild: yup.boolean(),
    childUserName: yup.string().when('isChild', {
      is: true,
      then: yup.string().required('어린이 이름을 입력해주세요')
    }),
    childBirthDate: yup.string().when('isChild', {
      is: true,
      then: yup.string().required('생년월일을 입력해주세요')
    }),
    childSex: yup.string().when('isChild', {
      is: true,
      then: yup.string().required('어린이 성별을 선택해주세요')
    })
  });

  const validate = () => {
    // schema.validate({ name: 'jimmy', age: 11 }).catch(function(err) {
    //   console.log(err.name)
    //   console.log(err.errors)
    // });
    return true;
  }

  const assembleJoinUser = () => {
    const user = {
      simpleId: simpleId,
      memberEmailId: memberEmailId,
      memberEmailDomain: memberEmailDomain,
      addr1: addr1,
      addr2: addr2,
      postNo: postNo,
      mobpNo1: mobpNo1,
      mobpNo2: mobpNo2,
      mobpNo3: mobpNo3,
      emailYn: emailAgree ? 'Y' : 'N',
      smsYn: smsAgree ? 'Y' : 'N',
      memberEmail: memberEmailId + '@' + memberEmailDomain,
      memberPw: memberPw,
      memberPwConfirm: memberPwConfirm,
      certSeq: cert.certSeq ? cert.certSeq : '',
      certConfirmNo: certConfirmNo,
      isChild: !join.joinUser.isAdult,
      realNameRequestSeq: realNameRequestSeq,
      isAdult: join.joinUser.isAdult,
      approvedTerms: join.approvedTerms
    }

    if (user.realNameRequestSeq == null) {
      alert('아이디 중복체크를 해주세요')
      return
    }

    if (!join.joinUser.isAdult) {
      user.childUserName = childUserName
      user.childBirthDate = childBirthYear + '-' + childBirthMonth + '-' + childBirthDate
      console.log(user.childBirthDate)
      if (user.childBirthDate && user.childBirthDate.length !== 10) {
        alert('어린이 생년월일을 입력해주세요')
        return
      }
      user.childSex = childSex
    }

    schema.validate(user)
      .then(function (value) {
        console.log(value)
        if (user.memberPw !== memberPwConfirm) {
          alert("비밀번호가 일치하지 않습니다.")
          return
        }
        if (!checkPassword(user.memberPw)) {
          return
        }

        onJoinSubmit(value)
      })
      .catch(function (err) {
        console.log(err)
        console.log(err.errors)

        const message = err.errors.reduce(function (acc, cur, i) {
          acc += cur + "\n"
          return acc
        })
        window.alert(message);
      });
  }

  const agePicker = () => {
    const date  = new Date();
    const childBirthArr = [];

    
    for(let i = 0; i < 14; i++) {
      childBirthArr.push(date.getFullYear() - i)
    }

    return childBirthArr
  }


  const childForm = (<>
    {/* 어린이 입력항목 */}
    <fieldset className="necessary under14">
      <legend>어린이 입력항목</legend>
      <div className="form_tit clearfix">
        <h4 className="float_l">어린이 입력항목</h4>
        <p className="float_r m_invisible">
          <span className="font_c">*</span><span>는 필수 입력사항입니다.</span>
        </p>
      </div>
      {/* //.form_tit */}

      <div className="input_field">
        {/* 이름 */}
        <div className="input_row name">
          <div className="input_tit">
            <label htmlFor="child_name">이름</label>
          </div>{/* //.input_tit */}
          <div className="input_content">
            <input type="text" id="child_name" name="child_name" className="cell" placeholder="실명 입력" value={childUserName} onChange={(e) => setChildUserName(e.target.value)} />
          </div>{/* //.input_content */}
        </div>{/* //.input_row.name */}

        {/* 생년월일 */}
        <div className="input_row birth">
          <div className="input_tit">
            <label htmlFor="child_birth">생년월일</label>
          </div>{/* //.input_tit */}
          <div className="input_content">
            <div className="birth_wrap clearfix">
              <select id="child_year" name="child_year" className="cell cell1 float_l" value={childBirthYear} onChange={(e) => setChildBirthYear(e.target.value)}>
                <option value="0">년도</option>
                {agePicker().length > 0 && agePicker().map(age => <option key={age} value={`${age}`}>{age}년</option>)}
              </select>
              <select id="child_month" name="child_month" className="cell cell2 float_l" value={childBirthMonth} onChange={(e) => setChildBirthMonth(e.target.value)}>
                <option value="0">월</option>
                <option value="01">1월</option>
                <option value="02">2월</option>
                <option value="03">3월</option>
                <option value="04">4월</option>
                <option value="05">5월</option>
                <option value="06">6월</option>
                <option value="07">7월</option>
                <option value="08">8월</option>
                <option value="09">9월</option>
                <option value="10">10월</option>
                <option value="11">11월</option>
                <option value="12">12월</option>
              </select>
              <select id="child_day" name="child_day" className="cell cell3 float_l" value={childBirthDate} onChange={(e) => setChildBirthDate(e.target.value)}>
                <option value="0">일</option>
                <option value="01">1일</option>
                <option value="02">2일</option>
                <option value="03">3일</option>
                <option value="04">4일</option>
                <option value="05">5일</option>
                <option value="06">6일</option>
                <option value="07">7일</option>
                <option value="08">8일</option>
                <option value="09">9일</option>
                <option value="10">10일</option>
                <option value="11">11일</option>
                <option value="12">12일</option>
                <option value="13">13일</option>
                <option value="14">14일</option>
                <option value="15">15일</option>
                <option value="16">16일</option>
                <option value="17">17일</option>
                <option value="18">18일</option>
                <option value="19">19일</option>
                <option value="20">20일</option>
                <option value="21">21일</option>
                <option value="22">22일</option>
                <option value="23">23일</option>
                <option value="24">24일</option>
                <option value="25">25일</option>
                <option value="26">26일</option>
                <option value="27">27일</option>
                <option value="28">28일</option>
                <option value="29">29일</option>
                <option value="30">30일</option>
                <option value="31">31일</option>
              </select>
            </div>{/* //.birth_wrap */}
          </div>{/* //.input_content */}
        </div>{/* //.input_row.birth */}

        {/* 성별 */}
        <div className="input_row gender">
          <div className="input_tit">
            <label htmlFor="child_gender">성별</label>
          </div>{/* //.input_tit */}
          <div className="input_content">
            <label htmlFor="child_male">
              <input type="radio" id="child_male" name="child_gender" value="child_male" checked={childSex === '1'} onChange={(e) => setChildSex('1')} />남</label>
            <label htmlFor="child_female">
              <input type="radio" id="child_female" name="child_gender" value="child_female" checked={childSex === '2'} onChange={(e) => setChildSex('2')} />여</label>
          </div>{/* //.input_content */}
        </div>{/* //.input_row.gender */}
      </div>{/* //.input_field */}
    </fieldset>
  </>
  )

  return (
    <>
      <JoinStep join={join} />
      {/* 정보입력 */}
      <div className="info_form_wrap">
        {/* 필수 입력항목 */}
        <fieldset className="necessary">
          <legend>필수 입력항목</legend>
          <div className="form_tit clearfix">
            <h4 className="float_l m_invisible">기본정보</h4>
            <h4 className="pc_invisible">*필수 입력항목</h4>
            <p className="float_r m_invisible">
              <span className="font_c">*</span><span>는 필수 입력사항입니다.</span>
            </p>
          </div>{/* //.form_tit */}

          <div className="input_field">
            {/* 아이디 */}
            <div className="input_row id">
              <div className="input_tit">
                <label htmlFor="id">아이디</label>
              </div>{/* //.input_tit */}
              <div className="input_content clearfix">
                <input type="text" id="id" name="id" className="cell float_l" readOnly={joinFormCheck.realNameRequestSeq != null} placeholder="8자 이상(영소문자, 숫자)" onChange={(e) => { console.log(e.target.value); setSimpleId(e.target.value) }} />
                <button disabled={simpleId === ''} className={"float_l " + (simpleId === '' ? '' : 'active')} onClick={(e) => { onCheckUserId(simpleId) }}>중복확인</button>
                {/* 클래스 active 붙었을 때 버튼 색 바뀝니다 */}
              </div>{/* //.input_content */}
            </div>{/* //.input_row.id */}

            {/* 비밀번호 */}
            <div className="input_row pw">
              <div className="input_tit">
                <label htmlFor="pw">비밀번호</label>
              </div>{/* //.input_tit */}
              <div className="input_content">
                <input type="password" id="pw" name="pw" className="cell" placeholder="8자 이상(영소문자, 숫자, 특수문자)" value={memberPw} onChange={(e) => setMemberPw(e.target.value)} />
              </div>{/* //.input_content */}
            </div>{/* //.input_row.pw */}

            {/* 비밀번호 확인 */}
            <div className="input_row pw_re">
              <div className="input_tit">
                <label htmlFor="pw_re">비밀번호 확인</label>
              </div>{/* //.input_tit */}
              <div className="input_content">
                <input type="password" id="pw_re" name="pw_re" className="cell" placeholder="8자 이상(영소문자, 숫자, 특수문자)" value={memberPwConfirm} onChange={(e) => setMemberPwConfirm(e.target.value)} />
              </div>{/* //.input_content */}
            </div>{/* //.input_row.pw_re */}

            {/* 휴대폰 번호 */}
            <div className="input_row mobile_num">
              <div className="input_tit">
                <label htmlFor="mobile_num">휴대폰 번호</label>
              </div>{/* //.input_tit */}
              <div className="input_content clearfix">
                <div className="mobile_num1_cell float_l"> {/* modify 201915 태그 추가 */}
                  <select id="mobile_num_1" name="mobile_num_1" className="cell" readOnly={cert.isConfirmReqeusted} onChange={(e) => setMobpNo1(e.target.value)}>
                    <option value="010">010</option>
                    <option value="011">011</option>
                    <option value="016">016</option>
                    <option value="017">017</option>
                    <option value="018">018</option>
                    <option value="019">019</option>
                  </select>
                </div>
                {/*  <input type="text" id="mobile_num1" name="mobile_num1" className="cell cell1 pc_invisible float_l" maxlength="3" />  */}
                {/*  <span>-</span>  */}
                <div className="mobile_num2_cell float_l"> {/* modify 201915 태그 추가 */}
                  <input type="text" id="mobile_num2" name="mobile_num2" className="cell" readOnly={cert.isConfirmReqeusted} maxLength="4" onChange={(e) => setMobpNo2(e.target.value)} />
                </div>
                {/*  <span>-</span>  */}
                <div className="mobile_num3_cell float_l"> {/* modify 201915 태그 추가 */}
                  <input type="text" id="mobile_num3" name="mobile_num3" className="cell" readOnly={cert.isConfirmReqeusted} maxLength="4" onChange={(e) => setMobpNo3(e.target.value)} />
                </div>
                <div className="float_l"> {/* modify 201915 태그 추가 */}
                  <button disabled={!isPhoneNumber(mobpNo1, mobpNo2, mobpNo3)} className={isPhoneNumber(mobpNo1, mobpNo2, mobpNo3) ? 'active' : ''} onClick={requestAuthNo}>인증요청</button> {/* 클래스 active 붙었을 때 버튼 색 바뀝니다 */}
                </div>
                <div className="agree_checkbox float_l"> {/* modify 201915 태그 추가 */}
                  <label htmlFor="sms_agree" className="sms_agree m_invisible">
                    <input type="checkbox" id="sms_agree" name="sms_agree" value="y" checked={smsAgree} onChange={(e) => setSmsAgree(e.target.checked)} />SMS 수신 동의</label>
                </div>
              </div>{/* //.input_content */}
            </div>{/* //.input_row.mobile_num */}

            {/* 휴대폰 인증번호 */}
            <div className="input_row num_confirm">
              <div className="input_tit">
                <label htmlFor="num_confirm">인증번호</label>
              </div>{/* //.input_tit */}
              <div className="input_content clearfix">
                <input type="text" id="num_confirm" name="num_confirm" className="cell float_l" placeholder="인증번호 6자 입력" disabled={cert == null || cert.certSeq == null} value={certConfirmNo} onChange={(e) => setCertConfirmNo(e.target.value)} />
                <button disabled={cert == null || cert.certSeq == null} className={"btn_wrap float_l" + (cert.certSeq != null ? ' active' : '')} onClick={() => confirmCertNo()}>인증확인</button> {/* 클래스 active 붙었을 때 버튼 색 바뀝니다 */}
              </div>{/* //.input_content */}
            </div>{/* //.input_row.num_confirm */}
            <div className="warning">
              <span>* 인증번호 입력 후 ‘인증확인’ 버튼을 선택해주세요.</span>
            </div>{/* //.warning */}

            {/* SMS 수신 동의(모바일) */}
            <div className="input_row agree_radio sms_agree pc_invisible">
              <div className="input_tit">
                <label htmlFor="sms_check">SMS 수신 동의</label>
              </div>{/* //.input-tit */}
              <div className="input_content clearfix">
                <label htmlFor="sms_agree">
                  <input type="radio" id="sms_agree" name="sms_agree" value="y" checked={smsAgree} onChange={(e) => setSmsAgree(e.target.checked)} />동의</label>
                <label htmlFor="sms_disagree">
                  <input type="radio" id="sms_disagree" name="sms_agree" value="n" checked={!smsAgree} onChange={(e) => setSmsAgree(!e.target.checked)} />동의안함</label>
              </div>{/* //.input_content */}
            </div>{/* //.input_row.sms_agree */}
          </div>{/* //.input_field */}
        </fieldset>

        {/* 선택 입력항목 */}
        <fieldset className="option">
          <legend>선택 입력항목</legend>
          <div className="form_tit pc_invisible">
            <h4>선택 입력항목</h4>
          </div>{/* //.form_tit */}

          <div className="input_field">
            {/* 주소 */}
            <div className="input_row address">
              <div className="input_tit">
                <label htmlFor="address">주소</label>
              </div>
              {/* //.input_tit */}
              <div className="input_content">
                <div className="address_num clearfix">
                  <div className="row clearfix"> {/* modify 201915 태그 추가 */}
                    <div className="address1_cell float_l"> {/* modify 201915 태그 추가 */}
                      <input type="text" id="address1" name="address1" className="cell" readOnly={true} value={postNo} />
                    </div>
                    <div className="address_btn float_l"> {/* modify 201915 태그 추가 */}
                      <button trype="submit" onClick={() => setOpenPostCode(!openPostCode)}>주소찾기</button>
                    </div>
                  </div>
                </div>{/* //.address_num */}
                <div className="address_txt clearfix">
                  <input type="text" id="address2" name="address2" className="cell cell2 float_l" maxLength="256" value={addr1} readOnly={true} />
                  <input type="text" id="address3" name="address3" className="cell cell3 float_l" maxLength="256" value={addr2} onChange={(e) => setAddr2(e.target.value)} /> {/* modify 201915 클래스 m_invisible 제거 */}
                </div>{/* //.address_txt */}
              </div>{/* //.input_content */}
            </div>{/* //.input_row.address */}

            <div style={postCodeSearchStyle} > {/* 다음 우편번호 컴포넌트와 닫기 버튼을 감싸는 박스 */}
              <DaumPostcode onComplete={handleAddress} width="100%" height="100%" />
              <img src="//t1.daumcdn.net/postcode/resource/images/close.png" id="btnCloseLayer" style={postCodeCloseImageStyle} alt="닫기 버튼" onClick={() => setOpenPostCode(false)}></img>
            </div>

            {/* 이메일 */}
            <div className="input_row email">
              <div className="input_tit">
                <label htmlFor="email">이메일</label>
              </div>{/* //.input_tit */}
              <div className="input_content clearfix">
                <div className="email_wrap float_l clearfix"> {/* modify 20191015 태그 추가 */}
                  <div className="email1_cell float_l"> {/* modify 20191015 태그 추가 */}
                    <input type="text" id="email1" name="email1" className="cell" value={memberEmailId} onChange={(e) => setMemberEmailId(e.target.value)} />
                  </div>
                  <div className="email2_cell float_l"> {/* modify 20191015 태그 추가 */}
                    <input type="text" id="email2" name="email2" className="cell" value={memberEmailDomain} readOnly={presetEmailDomain !== ''} onChange={(e) => setMemberEmailDomain(e.target.value)} />
                  </div>
                </div>{/* //.email_wrap */}
                <div className="email3_cell float_l"> {/* modify 20191015 태그 추가 */}
                  <select id="email3" name="email3" className="cell" onChange={(e) => { setPresetEmailDomain(e.target.value); setMemberEmailDomain(e.target.value) }}>
                    <option value="">직접입력</option>
                    <option value="naver.com">naver.com</option>
                    <option value="gmail.com">gmail.com</option>
                    <option value="hanmail.net">hanmail.net</option>
                    <option value="hotmail.com">hotmail.com</option>
                    <option value="nate.com">nate.com</option>
                    <option value="yahoo.co.kr">yahoo.co.kr</option>
                    <option value="korea.com">korea.com</option>
                    <option value="hanmir.com">hanmir.com</option>
                    <option value="paran.com">paran.com</option>
                  </select>
                </div>
                <div className="agree_checkbox float_l"> {/* modify 20191015 태그 추가 */}
                  <label htmlFor="email_agree" className="email_agree m_invisible">
                    <input type="checkbox" id="email_agree" name="email_agree" value="y" checked={emailAgree} onChange={(e) => setEmailAgree(e.target.checked)} />이메일 수신 동의</label>
                </div>
              </div>{/* //.input_content */}
            </div>{/* //.input_row.email */}
            <div className="warning">
              <span>* 이메일은 추후 아이디/비밀번호를 찾기 시 사용되니, 실제로 사용하시는 '이메일'을 기입해주세요.</span>
            </div>{/* //.warning */}

            {/* 이메일 수신 동의(모바일) */}
            <div className="input_row agree_radio email_agree pc_invisible">
              <div className="input_tit">
                <label htmlFor="email_check">이메일 수신 동의</label>
              </div>{/* //.input-tit */}
              <div className="input_content clearfix">
                <label htmlFor="email_agree">
                  <input type="radio" id="email_agree" name="email_agree" value="y" checked={emailAgree} onChange={(e) => setEmailAgree(e.target.checked)} />동의</label>
                <label htmlFor="email_disagree">
                  <input type="radio" id="email_disagree" name="email_agree" value="n" checked={!emailAgree} onChange={(e) => setEmailAgree(!e.target.checked)} />동의안함</label>
              </div>{/* //.input_content */}
            </div>{/* //.input_row.email_agree */}

            {/* 추천인 아이디 */}
            <div className="input_row re_id">
              <div className="input_tit">
                <label htmlFor="re_id">추천인 아이디</label>
              </div>
              {/* //.input_tit */}
              <div className="input_content clearfix">
                <input type="text" id="re_id" name="re_id" className="cell float_l" placeholder="8자 이상(영소문자, 숫자)" value={recommenderId} onChange={(e) => setRecommenderId(e.target.value)} />
                <button disabled={recommenderId === ''} className={"btn_wrap float_l " + (recommenderId !== '' ? 'active' : '')} onClick={() => onCheckRecommendId(recommenderId)}>아이디 확인</button>
              </div>{/* //.input_content */}
            </div>{/* //.input_row.re_id */}
          </div>{/* //.input_field */}
        </fieldset>

        {join.joinUser.isAdult ? '' : childForm}

        {/* 동의 버튼 */}
        <div className="btn_wrap btn_only"> {/* modify 20191015 클래스 btn_only 추가 */}
          <button type="submit" className={"confirm" + (!validate() ? 'active' : '')} disabled={!validate()} onClick={() => (assembleJoinUser())}>확인</button>
          {/* 클래스 active 붙었을 때 enabled 되면서 버튼 색 바뀝니다 */}
          {/* 확인 시 sign_up_step3.html로 이동 */}
          {/*  <button type="reset" className="m_invisible float_r">취소</button>  */} {/* modify 20191015 */}
          {/* 클래스 active 붙었을 때 버튼 색 바뀝니다 */}
          {/* 취소 시 현 페이지에서 입력내용 reset */}
          {/* <button type="submit" className="btn_mobile pc_invisible confirm" disabled={true}>보호자 인증</button> */}
          {/* 클래스 active 붙었을 때 버튼 색 바뀝니다 */}
          {/* 확인 시 sign_up_step3.html로 이동 */}
        </div>{/* //.btn_wrap */}
      </div>{/* //.info_form_wrap */}
    </>
  );
};

export default JoinStep3
