import React from 'react'
import { useHistory } from 'react-router-dom'
import numeral from 'numeral'

const BoxScore = ({ league, boxscore }) => {

  console.log(boxscore)

  const history = useHistory()

  const goBoxScore = (gmkey, gameDate) => {
    history.push(`/game/${league}/boxscore/${gameDate}/${gmkey}`)
  }

  const wlsCodeToName = (code) => {
    if (code === 'W') {
      return '승'
    } else if (code === 'L') {
      return '패'
    } else if (code === 'H') {
      return '홀드'
    } else {
      return code
    }
  }

  const PcBatter = ({ batter, score }) => {
    return (<tr>
      <td>{batter.oneturn}</td>
      <td>{batter.position}</td>
      <td>{batter.name}</td>
      <td>{batter.inn1}{batter.inn1 ? batter.il1 && ` / ${batter.il1}` : batter.il1}</td>
      <td>{batter.inn2}{batter.inn2 ? batter.il2 && ` / ${batter.il2}` : batter.il2}</td>
      <td>{batter.inn3}{batter.inn3 ? batter.il3 && ` / ${batter.il3}` : batter.il3}</td>
      <td>{batter.inn4}{batter.inn4 ? batter.il4 && ` / ${batter.il4}` : batter.il4}</td>
      <td>{batter.inn5}{batter.inn5 ? batter.il5 && ` / ${batter.il5}` : batter.il5}</td>
      <td>{batter.inn6}{batter.inn6 ? batter.il6 && ` / ${batter.il6}` : batter.il6}</td>
      <td>{batter.inn7}{batter.inn7 ? batter.il7 && ` / ${batter.il7}` : batter.il7}</td>
      <td>{batter.inn8}{batter.inn8 ? batter.il8 && ` / ${batter.il8}` : batter.il8}</td>
      <td>{batter.inn9}{batter.inn9 ? batter.il9 && ` / ${batter.il9}` : batter.il9}</td>
      <td>{batter.inn10}{batter.inn10 ? batter.il10 && ` / ${batter.il10}` : batter.il10}</td>
      <td>{batter.inn11}{batter.inn11 ? batter.il11 && ` / ${batter.il11}` : batter.il11}</td>
      <td>{batter.inn12}{batter.inn12 ? batter.il12 && ` / ${batter.il12}` : batter.il12}</td>
      {score[0].score13 === undefined || score[0].score13 ==='-1'
      ?
      <></>
      :
      <>
        <td>{batter.inn13}{batter.inn13 ? batter.il13 && ` / ${batter.il13}` : batter.il13}</td>
        <td>{batter.inn14}{batter.inn14 ? batter.il14 && ` / ${batter.il14}` : batter.il14}</td>
        <td>{batter.inn15}{batter.inn15 ? batter.il15 && ` / ${batter.il15}` : batter.il15}</td>
      </>
      }
      {/* {score && score.length > 0 && score[0].score13 !== '-1' && <td>{batter.inn13}</td>}
      {score && score.length > 0 && score[0].score14 !== '-1' && <td>{batter.inn14}</td>}
      {score && score.length > 0 && score[0].score15 !== '-1' && <td>{batter.inn15}</td>} */}
      {/* <td className={boxscore && (boxscore.gameFlag == '4' || boxscore.gameFlag == '3' || boxscore.gameFlag == '5' || boxscore.gameFlag == '7') ? '' : 'notpost'}>{batter.inn13}</td>
      <td className={boxscore && (boxscore.gameFlag == '4' || boxscore.gameFlag == '3' || boxscore.gameFlag == '5' || boxscore.gameFlag == '7') ? '' : 'notpost'}>{batter.inn14}</td>
      <td className={boxscore && (boxscore.gameFlag == '4' || boxscore.gameFlag == '3' || boxscore.gameFlag == '5' || boxscore.gameFlag == '7') ? '' : 'notpost'}>{batter.inn15}</td> */}
      <td>{batter.ab}</td>
      <td>{batter.run}</td>
      <td>{batter.hit}</td>
      <td>{batter.rbi}</td>
      <td>{batter.accmAb !== 0 && (Math.round(batter.accmHit / batter.accmAb * 1000) / 1000).toFixed(3)}</td>
    </tr>)
  }

  const PcPitcher = ({ pitcher }) => {
    return (<tr>
      <td>{pitcher.name}</td>
      <td>{pitcher.changeinn}</td>
      <td>{wlsCodeToName(pitcher.wls)}</td>
      <td>{pitcher.w}</td>
      <td>{pitcher.l}</td>
      <td>{pitcher.s}</td>
      <td>{pitcher.inn}</td>
      <td>{pitcher.pa}</td>
      <td>{pitcher.bf}</td>
      <td>{pitcher.ab}</td>
      <td>{pitcher.hit}</td>
      <td>{pitcher.hr}</td>
      <td>{pitcher.bbhp}</td>
      <td>{pitcher.kk}</td>
      <td>{pitcher.r}</td>
      <td>{pitcher.er}</td>
      <td>{(Math.round(pitcher.accmEr / pitcher.accmInn2 * 27 * 100) / 100).toFixed(2)}</td>
    </tr>)
  }

  const PcEtc = ({ etc }) => {
    return (<tr>
      <th>{etc.how}</th>
      <td>{etc.result}</td>
    </tr>)
  }

  const MobileBatter = ({ batter }) => {
    return (<tr>
      <td>{batter.oneturn}</td>
      <td>{batter.position}</td>
      <td>{batter.name}</td>
      <td>{batter.ab}</td>
      <td>{batter.run}</td>
      <td>{batter.hit}</td>
      <td>{batter.rbi}</td>
      <td>{batter.accmAb !== 0 && Math.round(batter.accmHit / batter.accmAb * 1000) / 1000}</td>
    </tr>)
  }

  const MobilePitcher = ({ pitcher }) => {
    return (<tr>
      <td>{pitcher.name}</td>
      <td>{wlsCodeToName(pitcher.wls)}</td>
      <td>{pitcher.inn}</td>
      <td>{pitcher.r}</td>
      <td>{pitcher.er}</td>
      <td>{pitcher.hit}</td>
      <td>{pitcher.hr}</td>
      <td>{pitcher.bbhp}</td>
      <td>{pitcher.kk}</td>
    </tr>)
  }

  const ScoreBoard = ({ score }) => {
    return (<tr>
      <th>{score.bhomeName}</th>
      <td>{(score.score1 === '-' || score.score1 === '-1') ? '-' : score.score1}</td>
      <td>{(score.score2 === '-' || score.score2 === '-1') ? '-' : score.score2}</td>
      <td>{(score.score3 === '-' || score.score3 === '-1') ? '-' : score.score3}</td>
      <td>{(score.score4 === '-' || score.score4 === '-1') ? '-' : score.score4}</td>
      <td>{(score.score5 === '-' || score.score5 === '-1') ? '-' : score.score5}</td>
      <td>{(score.score6 === '-' || score.score6 === '-1') ? '-' : score.score6}</td>
      <td>{(score.score7 === '-' || score.score7 === '-1') ? '-' : score.score7}</td>
      <td>{(score.score8 === '-' || score.score8 === '-1') ? '-' : score.score8}</td>
      <td>{(score.score9 === '-' || score.score9 === '-1') ? '-' : score.score9}</td>
      <td>{(score.score10 === '-' || score.score10 === '-1') ? '-' : score.score10}</td>
      <td>{(score.score11 === '-' || score.score11 === '-1') ? '-' : score.score11}</td>
      <td>{(score.score12 === '-' || score.score12 === '-1') ? '-' : score.score12}</td>
      {
      score.score13 === undefined || score.score13 ==='-1'
      ? 
        <></> 
      : 
        <>
          <td>{(score.score13 === '-' || score.score13 === '-1') ? '-' : score.score13}</td>
          <td>{(score.score14 === '-' || score.score14 === '-1') ? '-' : score.score14}</td>
          <td>{(score.score15 === '-' || score.score15 === '-1') ? '-' : score.score15}</td>
        </>
      }
      {/* <td className={boxscore && (boxscore.gameFlag == '4' || boxscore.gameFlag == '3' || boxscore.gameFlag == '5' || boxscore.gameFlag == '7') ? '' : 'notpost'}>{score.score13 === '-1' ? '-' : score.score13}</td>
      <td className={boxscore && (boxscore.gameFlag == '4' || boxscore.gameFlag == '3' || boxscore.gameFlag == '5' || boxscore.gameFlag == '7') ? '' : 'notpost'}>{score.score14 === '-1' ? '-' : score.score14}</td>
      <td className={boxscore && (boxscore.gameFlag == '4' || boxscore.gameFlag == '3' || boxscore.gameFlag == '5' || boxscore.gameFlag == '7') ? '' : 'notpost'}>{score.score15 === '-1' ? '-' : score.score15}</td> */}
      <td className="red">{score.run}</td>
      <td>{score.hit}</td>
      <td>{score.error}</td>
      <td>{score.ballfour}</td>
    </tr>)
  }

  return (
    <>
      <div className="boxscore_info_top_wrap" >
        <div className="pc_mode" >
          <article>
            <div className="date_select">
              <button className={'btn_date_prev' + (boxscore && boxscore.schedule.prev ? ' active' : '')}
                onClick={() => goBoxScore(boxscore.schedule.prev.gmkey, boxscore.schedule.prev.gameDate)}
                disabled={boxscore && boxscore.schedule.prev ? '' : 'true'}></button>
              <span className="selected_date">{boxscore && boxscore.schedule.current.gyear}년 {boxscore && boxscore.schedule.current.gmonth}월 {boxscore && boxscore.schedule.current.gday}일</span>
              <button className={'btn_date_next' + (boxscore && boxscore.schedule.next ? ' active' : '')}
                onClick={() => goBoxScore(boxscore.schedule.next.gmkey, boxscore.schedule.next.gameDate)}
                disabled={boxscore && boxscore.schedule.next ? '' : 'true'}></button>
            </div>
            <div className="date_boxscore_info">
              <ul>
                <li className="first">{boxscore && boxscore.schedule.current.gtime} {boxscore && boxscore.schedule.current.stadium}</li>
                {boxscore && boxscore.schedule.current.crowdCn != null && <li>관중 : {boxscore && numeral(boxscore.schedule.current.crowdCn).format('0,0')}명</li>}
              </ul>
              <table className={boxscore && (boxscore.gameFlag == '4' || boxscore.gameFlag == '3' || boxscore.gameFlag == '5' || boxscore.gameFlag == '7') ? 'post' : ''}>
                <caption>해당 날짜 박스스코어</caption>
                <colgroup>
                  <col width="*" />
                  <col width="5.6%" />
                  <col width="5.6%" />
                  <col width="5.6%" />
                  <col width="5.6%" />
                  <col width="5.6%" />
                  <col width="5.6%" />
                  <col width="5.6%" />
                  <col width="5.6%" />
                  <col width="5.6%" />
                  <col width="5.6%" />
                  <col width="5.6%" />
                  <col width="5.6%" />
                  <col width="5.6%" />
                  <col width="5.6%" />
                  <col width="5.6%" />
                  <col width="5.6%" />
                  {boxscore && boxscore.scoreboard.length > 0 && (boxscore.scoreboard[0].score13 === undefined || boxscore.scoreboard[0].score13 === '-1')
                  ?
                  <></>
                  :
                  <>
                    <col width="5.6%" />
                    <col width="5.6%" />
                    <col width="5.6%" />
                  </>
                  }
                </colgroup>
                <thead>
                  <tr>
                    <th>팀</th>
                    <th>1</th>
                    <th>2</th>
                    <th>3</th>
                    <th>4</th>
                    <th>5</th>
                    <th>6</th>
                    <th>7</th>
                    <th>8</th>
                    <th>9</th>
                    <th>10</th>
                    <th>11</th>
                    <th>12</th>
                    {boxscore && boxscore.scoreboard.length > 0 && (boxscore.scoreboard[0].score13 === undefined || boxscore.scoreboard[0].score13 === '-1')
                    ?
                    <></>
                    :
                    <>
                      <th>13</th>
                      <th>14</th>
                      <th>15</th>
                    </>
                    }
                    {/* {boxscore && boxscore.scoreboard.length > 0 && boxscore.scoreboard[0].score13 !== '-1' && <th>13</th>}
                    {boxscore && boxscore.scoreboard.length > 0 && boxscore.scoreboard[0].score14 !== '-1' && <th>14</th>}
                    {boxscore && boxscore.scoreboard.length > 0 && boxscore.scoreboard[0].score15 !== '-1' && <th>15</th>} */}
                    {/* <th className={boxscore && (boxscore.gameFlag == '4' || boxscore.gameFlag == '3' || boxscore.gameFlag == '5' || boxscore.gameFlag == '7') ? '' : 'notpost'}>13</th>
                    <th className={boxscore && (boxscore.gameFlag == '4' || boxscore.gameFlag == '3' || boxscore.gameFlag == '5' || boxscore.gameFlag == '7') ? '' : 'notpost'}>14</th>
                    <th className={boxscore && (boxscore.gameFlag == '4' || boxscore.gameFlag == '3' || boxscore.gameFlag == '5' || boxscore.gameFlag == '7') ? '' : 'notpost'}>15</th> */}
                    <th>R</th>
                    <th>H</th>
                    <th>E</th>
                    <th>B</th>
                  </tr>
                </thead>
                <tbody>
                  {boxscore && boxscore.scoreboard.map((score, index) => <ScoreBoard key={index} score={score}/>)}
                </tbody>
              </table>
            </div>
            <div className={'team team1 ' + (boxscore && boxscore.schedule.current.visitKey.toLowerCase())}>
              <span className="logo">{boxscore && boxscore.schedule.current.visit}</span>
              <span className="score">{(boxscore && boxscore.scoreboard[0] && boxscore.scoreboard[0].run) ? boxscore.scoreboard[0].run : 0}</span>
              <span className="team_place">{boxscore && boxscore.schedule.current.visit} (원정)</span>
            </div>
            <div className={'team team2 ' + (boxscore && boxscore.schedule.current.homeKey.toLowerCase())}>
              <span className="logo">{boxscore && boxscore.schedule.current.home}</span>
              <span className="score">{(boxscore && boxscore.scoreboard[1] && boxscore.scoreboard[1].run) ? boxscore.scoreboard[1].run : 0}</span>
              <span className="team_place">{boxscore && boxscore.schedule.current.home} (홈)</span>
            </div>
          </article>
        </div>

        <div className="mobile_mode">
          <article>
            <div className="date_select">
              <button className="btn_date_prev" onClick={() => goBoxScore(boxscore.schedule.prev.gmkey, boxscore.schedule.prev.gameDate)}></button>
              <span className="selected_date">{boxscore && boxscore.schedule.current.gyear}년 {boxscore && boxscore.schedule.current.gmonth}월 {boxscore && boxscore.schedule.current.gday}일</span>
              <button className="btn_date_next" onClick={() => goBoxScore(boxscore.schedule.next.gmkey, boxscore.schedule.next.gameDate)}></button>
            </div>
            <div className="date_boxscore">
              <span className="score">{(boxscore && boxscore.scoreboard[0] && boxscore.scoreboard[0].run) ? boxscore.scoreboard[0].run : 0}</span>
              <span className="vs">VS</span>
              <span className="score">{(boxscore && boxscore.scoreboard[1] && boxscore.scoreboard[1].run) ? boxscore.scoreboard[1].run : 0}</span>
            </div>
            <div className="date_boxscore_info">
              <span className="time_place">{boxscore && boxscore.schedule.current.gtime} {boxscore && boxscore.schedule.current.stadium}</span>
              <ul>
                <li className="first">{boxscore && boxscore.schedule.current.stadium}</li>
                {boxscore && boxscore.schedule.current.crowdCn != null && <li>관중 : {boxscore && numeral(boxscore.schedule.current.crowdCn).format('0,0')}명</li>}
              </ul>
            </div>
            <div className={'team team1 ' + (boxscore && boxscore.schedule.current.visitKey.toLowerCase())}>
              <span className="logo">{boxscore && boxscore.schedule.current.visit}</span>
            </div>
            <div className={'team team2 ' + (boxscore && boxscore.schedule.current.homeKey.toLowerCase())}>
              <span className="logo">{boxscore && boxscore.schedule.current.home}</span>
            </div>
          </article>
        </div>
      </div>

      <div className="boxscore_record_wrap">
        <div className="pc_mode">
          {boxscore && boxscore.schedule.current.cancelFlag === '0' && boxscore.schedule.current.endFlag === '0' &&
            <div className="no_record">
              <p><span className="warning"></span>상세 기록 정보는 경기 종료 후 제공됩니다.</p>
            </div>
          }
          {boxscore && boxscore.schedule.current.cancelFlag === '1' &&
            <div className="no_record">
              <p><span className="warning"></span>경기가 취소되었습니다.</p>
            </div>
          }

          {boxscore && boxscore.schedule.current.endFlag === '1' &&
            <>
              <div className="main_record_wrap">
                <article>
                  <h4>주요 기록</h4>
                  <table>
                    <caption>주요 기록</caption>
                    <colgroup>
                      <col width="135px" />
                      <col width="*" />
                    </colgroup>
                    <tbody>
                      {boxscore && boxscore.etcgames.map((etc, index) => <PcEtc key={index} etc={etc} />)}
                    </tbody>
                  </table>
                </article>
              </div>

              <div className="catcher_record_wrap">
                <article>
                  <h4>{boxscore && boxscore.schedule.current.visit} 타자 기록</h4>
                  <div className="table_wrap">
                    <table className={boxscore && (boxscore.gameFlag == '4' || boxscore.gameFlag == '3' || boxscore.gameFlag == '5' || boxscore.gameFlag == '7') ? 'post' : ''}>
                      <caption>{boxscore && boxscore.schedule.current.visit} 타자 기록</caption>
                      <thead>
                        <tr>
                          <th>타순</th>
                          <th>포지션</th>
                          <th>이름</th>
                          <th>1</th>
                          <th>2</th>
                          <th>3</th>
                          <th>4</th>
                          <th>5</th>
                          <th>6</th>
                          <th>7</th>
                          <th>8</th>
                          <th>9</th>
                          <th>10</th>
                          <th>11</th>
                          <th>12</th>
                          {boxscore && boxscore.scoreboard.length > 0 && (boxscore.scoreboard[0].score13 === undefined || boxscore.scoreboard[0].score13 === '-1')
                          ?
                          <></>
                          :
                          <>
                            <th>13</th>
                            <th>14</th>
                            <th>15</th>
                          </>
                          }
                          {/* {boxscore && boxscore.scoreboard.length > 0 && boxscore.scoreboard[0].score13 !== '-1' && <th >13</th>}
                          {boxscore && boxscore.scoreboard.length > 0 && boxscore.scoreboard[0].score14 !== '-1' && <th >14</th>}
                          {boxscore && boxscore.scoreboard.length > 0 && boxscore.scoreboard[0].score15 !== '-1' && <th >15</th>} */}
                          {/* {boxscore && boxscore.scoreboard[0].score13 === '-1' ? <></> : <th>13</th>}
                          {boxscore && boxscore.scoreboard[0].score13 === '-1' ? <></> : <th>14</th>}
                          {boxscore && boxscore.scoreboard[0].score13 === '-1' ? <></> : <th>15</th>} */}
                          {/* <th className={boxscore && (boxscore.gameFlag == '4' || boxscore.gameFlag == '3' || boxscore.gameFlag == '5' || boxscore.gameFlag == '7') ? '' : 'notpost'}>13</th>
                          <th className={boxscore && (boxscore.gameFlag == '4' || boxscore.gameFlag == '3' || boxscore.gameFlag == '5' || boxscore.gameFlag == '7') ? '' : 'notpost'}>14</th>
                          <th className={boxscore && (boxscore.gameFlag == '4' || boxscore.gameFlag == '3' || boxscore.gameFlag == '5' || boxscore.gameFlag == '7') ? '' : 'notpost'}>15</th> */}
                          <th>타수</th>
                          <th>득점</th>
                          <th>안타</th>
                          <th>타점</th>
                          <th>타율</th>
                        </tr>
                      </thead>
                      <tbody>
                        {boxscore && boxscore.vbatters.map((batter, index) => <PcBatter key={index} batter={batter} score={boxscore.scoreboard} />)}
                      </tbody>
                    </table>
                  </div>
                </article>
              </div>

              <div className="catcher_record_wrap">
                <article>
                  <h4>{boxscore && boxscore.schedule.current.home} 타자 기록</h4>
                  <div className="table_wrap">
                    <table className={boxscore && (boxscore.gameFlag == '4' || boxscore.gameFlag == '3' || boxscore.gameFlag == '5' || boxscore.gameFlag == '7') ? 'post' : ''}>
                      <caption>{boxscore && boxscore.schedule.current.home} 타자 기록</caption>
                      <thead>
                        <tr>
                          <th>타순</th>
                          <th>포지션</th>
                          <th>이름</th>
                          <th>1</th>
                          <th>2</th>
                          <th>3</th>
                          <th>4</th>
                          <th>5</th>
                          <th>6</th>
                          <th>7</th>
                          <th>8</th>
                          <th>9</th>
                          <th>10</th>
                          <th>11</th>
                          <th>12</th>
                          {boxscore && boxscore.scoreboard.length > 0 && (boxscore.scoreboard[0].score13 === undefined || boxscore.scoreboard[0].score13 === '-1')
                          ?
                          <></>
                          :
                          <>
                            <th>13</th>
                            <th>14</th>
                            <th>15</th>
                          </>
                          }
                          {/* {boxscore && boxscore.scoreboard.length > 0 && boxscore.scoreboard[0].score13 !== '-1' &&  <th >13</th>}
                          {boxscore && boxscore.scoreboard.length > 0 && boxscore.scoreboard[0].score14 !== '-1' && <th >14</th>}
                          {boxscore && boxscore.scoreboard.length > 0 && boxscore.scoreboard[0].score15 !== '-1' && <th >15</th>} */}
                          {/* <th className={boxscore && (boxscore.gameFlag == '4' || boxscore.gameFlag == '3' || boxscore.gameFlag == '5' || boxscore.gameFlag == '7') ? '' : 'notpost'}>13</th>
                          <th className={boxscore && (boxscore.gameFlag == '4' || boxscore.gameFlag == '3' || boxscore.gameFlag == '5' || boxscore.gameFlag == '7') ? '' : 'notpost'}>14</th>
                          <th className={boxscore && (boxscore.gameFlag == '4' || boxscore.gameFlag == '3' || boxscore.gameFlag == '5' || boxscore.gameFlag == '7') ? '' : 'notpost'}>15</th> */}
                          <th>타수</th>
                          <th>득점</th>
                          <th>안타</th>
                          <th>타점</th>
                          <th>타율</th>
                        </tr>
                      </thead>
                      <tbody>
                      {boxscore && boxscore.hbatters.map((batter, index) => <PcBatter key={index} batter={batter} score={boxscore.scoreboard} />)} 
                      </tbody>
                    </table>
                  </div>
                </article>
              </div>

              <div className="pitcher_record_wrap">
                <article>
                  <h4>{boxscore && boxscore.schedule.current.visit} 투수 기록</h4>
                  <div className="table_wrap">
                    <table>
                      <caption>{boxscore && boxscore.schedule.current.visit} 투수 기록</caption>
                      <colgroup>
                        <col width="63px" />
                        <col width="63px" />
                        <col width="63px" />
                        <col width="63px" />
                        <col width="63px" />
                        <col width="63px" />
                        <col width="63px" />
                        <col width="63px" />
                        <col width="63px" />
                        <col width="63px" />
                        <col width="63px" />
                        <col width="63px" />
                        <col width="63px" />
                        <col width="63px" />
                        <col width="63px" />
                        <col width="63px" />
                        <col width="*" />
                      </colgroup>
                      <thead>
                        <tr>
                          <th>선수명</th>
                          <th>등판</th>
                          <th>결과</th>
                          <th>승</th>
                          <th>패</th>
                          <th>세</th>
                          <th>이닝</th>
                          <th>타자</th>
                          <th>투구수</th>
                          <th>타수</th>
                          <th>피안타</th>
                          <th>피홈런</th>
                          <th>사구</th>
                          <th>삼진</th>
                          <th>실점</th>
                          <th>자책</th>
                          <th>평균 자책점</th>
                        </tr>
                      </thead>
                      <tbody>
                        {boxscore && boxscore.vpitchers.map((pitcher, index) => <PcPitcher key={index} pitcher={pitcher} />)}
                      </tbody>
                    </table>
                  </div>
                </article>
              </div>

              <div className="pitcher_record_wrap">
                <article>
                  <h4>{boxscore && boxscore.schedule.current.home} 투수 기록</h4>
                  <div className="table_wrap">
                    <table>
                      <caption>{boxscore && boxscore.schedule.current.home} 투수 기록</caption>
                      <colgroup>
                        <col width="63px" />
                        <col width="63px" />
                        <col width="63px" />
                        <col width="63px" />
                        <col width="63px" />
                        <col width="63px" />
                        <col width="63px" />
                        <col width="63px" />
                        <col width="63px" />
                        <col width="63px" />
                        <col width="63px" />
                        <col width="63px" />
                        <col width="63px" />
                        <col width="63px" />
                        <col width="63px" />
                        <col width="63px" />
                        <col width="*" />
                      </colgroup>
                      <thead>
                        <tr>
                          <th>선수명</th>
                          <th>등판</th>
                          <th>결과</th>
                          <th>승</th>
                          <th>패</th>
                          <th>세</th>
                          <th>이닝</th>
                          <th>타자</th> 
                          <th>투구수</th>
                          <th>타수</th>
                          <th>피안타</th>
                          <th>피홈런</th>
                          <th>사구</th>
                          <th>삼진</th>
                          <th>실점</th>
                          <th>자책</th>
                          <th>평균 자책점</th>
                        </tr>
                      </thead>
                      <tbody>
                        {boxscore && boxscore.hpitchers.map((pitcher, index) => <PcPitcher key={index} pitcher={pitcher} />)}
                      </tbody>
                    </table>
                  </div>
                </article>
              </div>
            </>
          }
        </div>

        {/* mobile_mode */}
        <div className="mobile_mode">


          <div className="boxscore_wrap">
            <article>
              <h4>박스스코어</h4>
              <table className={boxscore && (boxscore.gameFlag == '4' || boxscore.gameFlag == '3' || boxscore.gameFlag == '5' || boxscore.gameFlag == '7') ? 'post' : ''}>
                <caption>박스스코어</caption>
                <thead>
                  <tr>
                    <th>팀</th>
                    <th>1</th>
                    <th>2</th>
                    <th>3</th>
                    <th>4</th>
                    <th>5</th>
                    <th>6</th>
                    <th>7</th>
                    <th>8</th>
                    <th>9</th>
                    <th>10</th>
                    <th>11</th>
                    <th>12</th>
                    {boxscore && boxscore.scoreboard.length > 0 && (boxscore.scoreboard[0].score13 === undefined || boxscore.scoreboard[0].score13 === '-1')
                    ?
                    <></>
                    :
                    <>
                      <th>13</th>
                      <th>14</th>
                      <th>15</th>
                    </>
                    }
                    {/* {boxscore && boxscore.scoreboard.length > 0 && boxscore.scoreboard[0].score13 !== '-1' && <th>13</th>}
                    {boxscore && boxscore.scoreboard.length > 0 && boxscore.scoreboard[0].score14 !== '-1' && <th>14</th>}
                    {boxscore && boxscore.scoreboard.length > 0 && boxscore.scoreboard[0].score15 !== '-1' && <th>15</th>} */}
                    {/* <th className={boxscore && (boxscore.gameFlag == '4' || boxscore.gameFlag == '3' || boxscore.gameFlag == '5' || boxscore.gameFlag == '7') ? '' : 'notpost'}>13</th>
                    <th className={boxscore && (boxscore.gameFlag == '4' || boxscore.gameFlag == '3' || boxscore.gameFlag == '5' || boxscore.gameFlag == '7') ? '' : 'notpost'}>14</th>
                    <th className={boxscore && (boxscore.gameFlag == '4' || boxscore.gameFlag == '3' || boxscore.gameFlag == '5' || boxscore.gameFlag == '7') ? '' : 'notpost'}>15</th> */}
                    <th>R</th>
                    <th>H</th>
                    <th>E</th>
                    <th>B</th>
                  </tr>
                </thead>
                <tbody>
                  {boxscore && boxscore.scoreboard.map((score, index) => <ScoreBoard key={index} score={score} endFlag={boxscore.schedule.current.endFlag} />)}
                </tbody>
              </table>
            </article>
          </div>

          {boxscore && boxscore.schedule.current.cancelFlag === '0' && boxscore.schedule.current.endFlag === '0' &&
            <div className="no_record">
              <p><span className="warning"></span>상세 기록 정보는 경기 종료 후 제공됩니다.</p>
            </div>
          }
          {boxscore && boxscore.schedule.current.cancelFlag === '1' &&
            <div className="game_cancel">
              <p><span className="warning"></span>경기가 취소되었습니다.</p>
            </div>
          }

          {boxscore && boxscore.schedule.current.endFlag === '1' &&
            <div className="main_record_wrap">
              <article>
                <h4>주요 기록</h4>
                <table>
                  <caption>주요 기록</caption>
                  <colgroup>
                    <col width="80px" />
                    <col width="*" />
                  </colgroup>
                  <tbody>
                    {boxscore && boxscore.etcgames.map((etc, index) => <PcEtc key={index} etc={etc} />)}
                  </tbody>
                </table>
              </article>
            </div>
          }

          {boxscore && boxscore.schedule.current.endFlag === '1' &&
            <div className="catcher_record_wrap">
              <article>
                <h4>{boxscore && boxscore.schedule.current.visit} 타자기록</h4>
                <table>
                  <caption>{boxscore && boxscore.schedule.current.visit} 타자기록</caption>
                  <colgroup>
                    <col width="12%" />
                    <col width="12%" />
                    <col width="*" />
                    <col width="12%" />
                    <col width="12%" />
                    <col width="12%" />
                    <col width="12%" />
                    <col width="12%" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>타순</th>
                      <th>포지션</th>
                      <th>이름</th>
                      <th>타수</th>
                      <th>득점</th>
                      <th>안타</th>
                      <th>타점</th>
                      <th>타율</th>
                    </tr>
                  </thead>
                  <tbody>
                    {boxscore && boxscore.vbatters.map((batter, index) => <MobileBatter key={index} batter={batter} />)}
                  </tbody>
                </table>
              </article>
            </div>
          }

          {boxscore && boxscore.schedule.current.endFlag === '1' &&
            <div className="catcher_record_wrap">
              <article>
                <h4>{boxscore && boxscore.schedule.current.home} 타자기록</h4>
                <table>
                  <caption>{boxscore && boxscore.schedule.current.home} 타자기록</caption>
                  <colgroup>
                    <col width="12%" />
                    <col width="12%" />
                    <col width="*" />
                    <col width="12%" />
                    <col width="12%" />
                    <col width="12%" />
                    <col width="12%" />
                    <col width="12%" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>타순</th>
                      <th>포지션</th>
                      <th>이름</th>
                      <th>타수</th>
                      <th>득점</th>
                      <th>안타</th>
                      <th>타점</th>
                      <th>타율</th>
                    </tr>
                  </thead>
                  <tbody>
                    {boxscore && boxscore.hbatters.map((batter, index) => <MobileBatter key={index} batter={batter} />)}
                  </tbody>
                </table>
              </article>
            </div>
          }

          {boxscore && boxscore.schedule.current.endFlag === '1' &&
            <div className="pitcher_record_wrap">
              <article>
                <h4>{boxscore && boxscore.schedule.current.visit} 투수기록</h4>
                <table>
                  <caption>{boxscore && boxscore.schedule.current.visit} 투수기록</caption>
                  <colgroup>
                    <col width="*" />
                    <col width="10.5%" />
                    <col width="10.5%" />
                    <col width="10.5%" />
                    <col width="10.5%" />
                    <col width="10.5%" />
                    <col width="10.5%" />
                    <col width="10.5%" />
                    <col width="10.5%" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>이름</th>
                      <th>결과</th>
                      <th>이닝</th>
                      <th>투구</th>
                      <th>자책</th>
                      <th>안타</th>
                      <th>홈런</th>
                      <th>사구</th>
                      <th>삼진</th>
                    </tr>
                  </thead>
                  <tbody>
                    {boxscore && boxscore.vpitchers.map((batter, index) => <MobilePitcher key={index} pitcher={batter} />)}
                  </tbody>
                </table>
              </article>
            </div>
          }

          {boxscore && boxscore.schedule.current.endFlag === '1' &&
            <div className="pitcher_record_wrap">
              <article>
                <h4>{boxscore && boxscore.schedule.current.home} 투수기록</h4>
                <table>
                  <caption>{boxscore && boxscore.schedule.current.home} 투수기록</caption>
                  <colgroup>
                    <col width="*" />
                    <col width="10.5%" />
                    <col width="10.5%" />
                    <col width="10.5%" />
                    <col width="10.5%" />
                    <col width="10.5%" />
                    <col width="10.5%" />
                    <col width="10.5%" />
                    <col width="10.5%" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>이름</th>
                      <th>결과</th>
                      <th>이닝</th>
                      <th>실점</th>
                      <th>자책</th>
                      <th>안타</th>
                      <th>홈런</th>
                      <th>사구</th>
                      <th>삼진</th>
                    </tr>
                  </thead>
                  <tbody>
                    {boxscore && boxscore.hpitchers.map((pitcher, index) => <MobilePitcher key={index} pitcher={pitcher} />)}
                  </tbody>
                </table>
              </article>
            </div>
          }
        </div>
      </div>
    </>
  )
}

export default BoxScore
