import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Link from '../common/Link'
import * as Enum from '../../constants/enum'
import { UserAgent } from 'react-useragent'
import { reserveGeneralTicketCheck, reserveGeneralTicketRequest, seasonTicketRequest } from '../../modules/ticket/ticket'
import Prepairing from '../common/Prepairing'
import { myInfoRequest } from 'modules/mypage/myinfo'
import { isFuture, isPast } from 'date-fns'

const ReservationAndRefund = () => {

  const login = useSelector(state => state.login)
  const myInfo = useSelector((state) => state.myInfo);
  const ticketInfo = useSelector((state) => state.ticket);
  const dispatch = useDispatch()
  const location = useLocation()

  const [isTerms, setIsTerms] = useState(false)

  useEffect(()=>{
      dispatch(seasonTicketRequest());
      if(login.isLoggedIn){
        dispatch(myInfoRequest());
      }
  },[dispatch,login.isLoggedIn])

  const onTicket = () => {
    if(isPast(Number(ticketInfo.seasonInfo.seasonInfo.childStartDate)) && isFuture(Number(ticketInfo.seasonInfo.seasonInfo.childEndDate))){
      if(login.isLoggedIn && myInfo.info.user.isAdult==='Y') {
        window.alert('어린이회원권구매기간에 성인회원은 구매불가합니다')
      }else{
        dispatch(reserveGeneralTicketRequest(login.isLoggedIn, isTerms, location.pathname))
      }
    }else{
      dispatch(reserveGeneralTicketRequest(login.isLoggedIn, isTerms, location.pathname))
    }
  }
  const onTicketCheck = () => {
    dispatch(reserveGeneralTicketCheck(login.isLoggedIn, location.pathname))
  }
  const onToggleIsTerms = e => { setIsTerms(e.target.checked) }

  return (
    <>
      {/* 예매 / 구매 방법 */}
      <article>
        <div className='title'>
          <h6>예매 / 구매 방법</h6>
        </div>
        <div className='con b'>
          <div className='c c1'>
            <div className='r r1'>
              <img className='icon' src={require('../../assets/img/ticket/icon_phone.png')} alt="" />
              <p className='txt'>
                kt wiz 전용 wizzap앱
                <UserAgent>
                  {
                    ({ ua }) => {
                      switch (ua.os) {
                        case Enum.USER_AGENT.OS.ANDROID:
                          return <Link to='//play.google.com/store/apps/details?id=kr.co.ktwiz.wizzap' target='_blank' className='btn btn-install'>앱 설치</Link>
                        case Enum.USER_AGENT.OS.IOS:
                          // return <Link to='//apps.apple.com/kr/app/wizzap/id975125575' target='_blank' className='btn btn-install'>앱 설치</Link> // apple store url
                          return <Link to='//itunes.apple.com/app/id975125575?mt=8' target='_blank' className='btn btn-install'>앱 설치</Link> // itunes store direct url
                        default:
                          return <Link to='//play.google.com/store/apps/details?id=kr.co.ktwiz.wizzap' target='_blank' className='btn btn-install'>앱 설치</Link>
                      }
                    }
                  }
                </UserAgent>
              </p>
            </div>
            <div className='r r2'>
              <img className='icon' src={require('../../assets/img/ticket/icon_call.png')} alt="" />
              <p className='txt'>
                티켓링크 콜센터<br />
                1588-7890
              </p>
            </div>
          </div>
          <div className='c c2'>
            <div className='r r1'>
              <img className='icon' src={require('../../assets/img/ticket/icon_pc.png')} alt="" />
              <p className='txt'>
                kt wiz 홈페이지 티켓 예매<br />
                티켓링크에서 예매(<Link onClick={onTicket}>http://www.ticketlink.co.kr</Link>)</p>

            </div>
            <div className='r r2'>
              <img className='icon' src={require('../../assets/img/ticket/icon_ticket-box.png')} alt="" />
              <p className='txt'>
                kt wiz park 매표소<br />
                현장 구매</p>
            </div>
          </div>
        </div>
      </article>

      {/* 티켓 예매 활성화 시 주석 제거 */}
      {/* <article>
        <div className='title'>
          <h6>티켓 구매 약관</h6>
        </div>
        <form className='con'>
          <div className='notice_txt_block'>
            <p>
              <strong>kt wiz 시즌티켓 구매약관</strong><br /><br />
              제1조 약관의 목적<br />
              kt wiz (이하’사업자’) 시즌티켓 구매약관은 이용자가 구매자 자격으로 사업자가 주관하는 당해연도 정규시즌 홈경기를 관람함에 있어서 사업자와 구매자 양자간 권리와 의무에 관한 사항을 규정하는 것을 목적으로 합니다.<br /><br />

              제2조 시즌티켓의 정의<br />
              1. kt wiz 시즌티켓은 당해연도 KBO 정규시즌 홈경기의 시즌권, 게임권을 말합니다.<br />
              2. 시즌권이라함은 kt wiz 홈경기에 당해연도 시즌 전 경기 특정 좌석을 선점하는 경기 티켓을 말합니다.<br />
              3. 게임권이라함은 kt wiz 홈경기에 당해연도 자신이 원하는 경기를 예매할 수 있는 예매 쿠폰을 말합니다.<br /><br />

              제3조 약관적용 대상 및 시즌티켓 구매자의 정의<br />
              1. 이 약관은 사업자가 주관하는 당해연도 KBO 정규시즌 홈경기를 관람하는 모든 구매자에게 적용됩니다.<br />
              2. kt wiz 시즌티켓 구매자(이하‘구매자’)라 함은 본 약관에 따라 kt wiz 홈경기 시즌티켓을 구매하여 시즌티켓을 소지하고 구매내용에 의거해 사업자가 주관하는 당해연도 홈경기를 관람할 수 있는 자격을 갖춘 이용자를 말합니다.<br /><br />

              제4조 시즌티켓 구매방법<br />
              1. 시즌티켓을 구매하고자 하는 자는 사업자가 제공하는 (혹은 사업자를 대행하는 티켓업체 웹사이트 내) 구매절차에 회원정보를 기입하고 시즌티켓 가격을 납부한 후 시즌티켓을 교부 받으면 구매자가 됩니다.<br />
              2. 사업자가 시즌티켓을 구매하고자 하는 자의 구매신청을 승낙할 경우, 사업자는 가격납부 확인 후 시즌티켓을 이용자에게 교부합니다.<br />
              3. 시즌티켓을 구매하고자 하는 이용자는 구매신청서의 기재사항을 성실히 기재하여야 합니다.<br /><br />

              제5조 사업자의 권리와 의무<br />
              1. 사업자는 이 약관이 정하는 바에 따라, 구매자에게 지속적이고 안정적으로 재화, 용역을 제공하는데 최선을 다하여야 합니다.<br />
              2. 사업자는 수집한 개인정보를 다음의 목적을 위하여 활용합니다.<br />
              (1) 시즌티켓 혜택 제공에 관한 이행<br />
              (2) 시즌티켓 혜택 이용에 따른 본인확인, 개인식별, 불량회원의 부정 이용방지, 불만처리 등의 민원처리, 고지사항 전달<br />
              (3) 시즌티켓 구매자의 구매 및 이용에 대한 통계 등<br />
              3. 사업자는 서비스 향상 및 구매자의 혜택 강화를 위하여 필요하다고 인정되는 다양한 정보를 전자우편, 문자, 전화 등의 방법으로 이용자에게 제공할 수 있습니다.<br />
              4. 수집된 개인정보는 구매자의 동의 없이는 목적 외의 이용이나 제3자에게 제공할 수 없습니다. 다만, 다음의 경우에는 예외로 합니다.<br />
              (1) 시즌티켓의 제작 및 배송업무상 필요한 최소한의 정보(이름, 주소, 연락처)를 알려주는 경우<br />
              (2) 법률의 규정 또는 법률에 의하여 필요가 불가피한 사유가 있는 경우<br />
              (3) 구매자들이 사전에 동의한 경우<br /><br />

              제6조 구매자의 권리와 의무<br />
              1. 구매자는 구매일로부터 구매한 시즌티켓의 유효기간 내 시즌티켓의 종류 및 자격 별 혜택을 이용하실 수 있습니다. 시즌티켓 종류 및 자격 별 혜택에 관한 구체적인 내용은 시즌티켓 구매 시에 제공된 내용에 의합니다.<br />
              2. 구매자는 사업자가 제공하는 부가혜택을 이용할 수 있습니다. 단, 구매자에게 제공되는 부가혜택은사업자의 사정에 따라 예고 없이 변경될 수 있습니다.<br />
              3. 구매자는 사업자가 주관하는 홈경기 관람 시 반드시 시즌티켓을 지참하여야 하며, 사업자의 요구 시 시즌티켓을 제시하여야만 구매자로서의 혜택을 이용할 수 있습니다.<br />
              4. 시즌티켓을 촬영한 사진 또는 스캔자료로는 시즌티켓 구매자로서의 혜택을 이용할 수 없습니다.<br />
              5. 구매자는 혜택의 정상적인 이용과 관련하여 사업자가 요구가 있을 경우, 본인확인절차에 응해야 합니다.<br />
              6. 구매자는 시즌티켓을 분실했을 경우 즉시 사업자에게 통지하여야 하며, 사업자가 정한 절차에 따라 이를 재발급 받아야 합니다. 이때 소정의 재발급비용은 구매자본인이 부담하여야 합니다.<br />
              7. 시즌티켓 분실신고 이전에 구매자 본인의 귀책사유(고의 또는 중대한과실)에 의한 부정사용으로 인하여 발생하는 불이익에 대해서는 구매자 본인에게 책임이 있습니다.<br />
              8. 구매자는 구매 시 기재한 회원정보에 변경이 발생하면 즉시 변경내용을 사업자에게 통보하여야 합니다. 기재사항 누락이나 변경내용의 미 통보로 인해 발생하는 불이익은 사업자의 중대한 과실이 없는 한, 사업자의 책임을 물을 수 없습니다.<br /><br />

              제7조 시즌티켓의 유효기간<br />
              1. 시즌티켓의 유효기간은 당해연도 KBO 정규시즌 종료일까지로 한다.<br />
              2. 시즌티켓 구매자의 자격은 KBO가 주관하는 모든 경기(KBO시범경기, 올스타전, 포스트시즌등)에는 제공되지 않습니다.<br /><br />

              제8조 시즌티켓의 재발급<br />
              1. 개인 시즌티켓을 분실, 멸실, 훼손한 경우 재발급을 신청할 수 있습니다.<br />
              2. 개인 시즌티켓을 재발급 받고자 할 때에는 본인임을 증명할 수 있는 신분증을 제출하고 수수료를 부담하여야 합니다.<br />
              3. 시즌티켓의 부정사용으로 인해 발생한 재발급 요청에 대해서 사업자는 시즌티켓의 재발급을 거부할 수 있습니다.<br />
              4. 사업자의 과실로 인해 시즌티켓을 분실, 멸실, 훼손한 경우 사업자는 구매자의 별도 비용 부담 없이 시즌티켓을 재발급 해드립니다.<br /><br />

              제9조 시즌티켓 구매자의 자격상실<br />
              다음 사항이 발생되면 시즌티켓 구매자의 자격이 상실됩니다.<br />
              1. 시즌티켓의 유효약정 기간이 만료된 경우<br />
              2. 시즌티켓의 부정, 편법사용으로 적발된 경우<br />
              3. 시즌티켓 구매자의 귀책사유로 인해 사업자에게 재산상의 피해를 입힌 경우<br />
              4. 다른 구매자 및 일반이용자에게 심각한 피해를 유발하거나, 이용 중 행위 등으로 인해 다른 구매자 및 일반 이용자의 이용에 지장을 주어 구매자의 야구장 방문이 부적절하다고 판단되는 경우<br /><br />

              제10조 시즌티켓 환불<br />
              시즌티켓 구매 후 시즌티켓의 환불은 다음의 금액을 제하고 환불됩니다.<br />
              1. 시즌티켓 판매 금액의 10%<br />
              2-1. 시즌권의 경우 시즌 경기 시작 경기로부터 환불 요청 도달일까지의 경기 수에 좌석 등급 별 1경기 정상가격을 곱한 금액<br />
              2-2. 게임권의 경우 환불 요청 도달일까지 사용된 게임권에 좌석 등급 별 1경기 정상가격을 곱한 금액<br />
              3. 부여 받은 혜택(상품/티켓 할인, 무료쿠폰 등)의 환불 요청 도달일까지의 사용된 금액<br /><br />

              제11조 손해배상<br />
              1. 일방 당사자가 본 약관에 따른 의무를 불이행, 위반하거나 또는 이로 인하여 본 계약이 해지되는 경우 이로 인하여 상대방에게 발생하는 모든 손해를 배상하여야 한다.<br />
              2. 사업자의 시설물(파울타구 등 경기진행에 따른 것은 제외)에 의해 시즌티켓 구매자에게 신체상, 재산상의 피해가 발생할 경우, 사업자는 그 손해를 배상합니다. 다만 그 손해가 불가항력으로 인하여 발생한 경우, 또는 그 손해의 발생이 구매자의 귀책사유로 인한 경우에는 그 배상의 책임이 경감된다.<br />
              3. 구매자의 귀책사유로 사업자의 시설물을 파손 등 기타손해가 발생할 경우 구매자는 이를 배상하여야 합니다.<br /><br />

              제12조 면책조항<br />
              1. 사업자는 천재지변, 행정관청의 규제, 긴급한 안전조치, 기타 불가항력의 사유로 시즌티켓 구매자 혜택의 이용이 곤란할 때에는 그 사유가 종료될 때까지 이용을 중단시킬 수 있습니다.<br />
              2. 제1항의 경우에는 구매자는 탈퇴할 수 있습니다. 단, 이 경우 구매자는 사업자에 대하여 시즌티켓 가격에 따른 환불금액 이외에 별도의 손해배상을 청구할 수 없습니다.<br />
              3. 구매자가 제1항의 이유로 탈퇴하는 경우 사업자는 본 약관에 따라 시즌티켓 가격을 환불해 할 수 있습니다.<br />
              4. 환불금액은 제 9조의 시즌티켓 환불 규정에 따른다.<br /><br />

              제13조 약관의 변경 및 분쟁조정<br />
              1. 본 약관의 내용은 사업자의 영업 환경변화 등 기타 다른 사정에 따라 변경 될 수 있습니다. 다만, 약관의 변경 후 그 내용이 기존의 약관내용보다 시즌티켓 구매자에게 유리할 경우, 기존회원은 변경된 약관의 적용을 주장할 수 있습니다.<br />
              2. 본 약관과 관련된 법적인 분쟁의 발생 시에는 민사소송법상의 관할규정에 따릅니다.<br />
              3. 본 약관에 관한 소송관할을 수원지방법원으로 한다.
            </p>
          </div>
          <div className='notice'>
            <p>
              ※ 이용약관에 대한 동의를 거부할 수 있으며, 미 동의 시 티켓 예매가 진행되지 않습니다.<br />
            </p>
            <label className='cbox'>
              <input type='checkbox' id='is_agree' name='is_agree' onChange={onToggleIsTerms} />
              &nbsp;<span className='txt'>kt 이용 약관에 동의 합니다.</span>
            </label>
          </div>
        </form>
      </article> */}

      {/* 티켓 예매하기 */}
      <article>
        <div className='title'>
          <h6>티켓 예매하기</h6>
        </div>
        <div className='con'>
          <div className='reservation'>
            <p>예매를 통해서 kt wiz 경기를 경기장에서 직접 만나 보세요.</p>
            <div className='buttons'>
              <UserAgent>
                {
                  ({ ua }) => {
                    switch (ua.os) {
                      case Enum.USER_AGENT.OS.ANDROID:
                        return (
                          <>
                            <Link className='btn-ticket ticketing' to='//play.google.com/store/apps/details?id=kr.co.ktwiz.wizzap' target='_blank'>예매하기</Link>
                            <Link className='btn-ticket checking' to='//play.google.com/store/apps/details?id=kr.co.ktwiz.wizzap' target='_blank'>예매 확인 / 취소</Link>
                          </>
                        )
                      case Enum.USER_AGENT.OS.IOS:
                        return (
                          <>
                            <Link className='btn-ticket ticketing' to='//itunes.apple.com/app/id975125575?mt=8' target='_blank'>예매하기</Link>
                            <Link className='btn-ticket checking' to='//itunes.apple.com/app/id975125575?mt=8' target='_blank'>예매 확인 / 취소</Link>
                          </>
                        )
                      default:
                        return (
                          <>
                            <Link className='btn-ticket ticketing' onClick={onTicket}>예매하기</Link>
                            <Link className='btn-ticket checking' onClick={onTicketCheck}>예매 확인 / 취소</Link>
                          </>
                        )
                    }
                  }
                }
              </UserAgent>


            </div>
            <img className='img-ticket' src={require('../../assets/img/ticket/ticket.png')} alt='' />
          </div>
        </div>
      </article>
    </>
  )
}

export default ReservationAndRefund
