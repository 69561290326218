import React, { useState, useEffect } from 'react'
import format from 'date-fns/format'
import Link from '../common/Link'

const GameSchedule = ({ league, onChangeMonth, onScoreBoardRequest, year, month, recentGames, scheduleData, allTeamSchedules, daySchedule, scoreBoard }) => {

  const firstDay = new Date(year, month - 1, 1)
  const lastDay = new Date(year, month, 0)
  const dayCount = lastDay.getDate()
  const skipCount = firstDay.getDay()
  const remainCount = 7 - lastDay.getDay() - 1

  const gameState = {
    '1': '예정',
    '2': '경기중',
    '3': '종료',
    '4': '취소',
    '5': '서스팬디드'
  }

  const [tab, setTab] = useState('ktwiz')
  const [mobileTab, setMobileTab] = useState('today')
  const [showMonthOptions, setShowMonthOptions] = useState(false)
  const [selectedMonth, setSelectedMonth] = useState(month)
  const [showScoreBoard, setShowScoreBoard] = useState(false)

  useEffect(() => {
    setSelectedMonth(month)
  }, [month])

  const createWeeks = () => {
    const calendar = new Array(skipCount + dayCount + remainCount)
    for (let i = 0; i < calendar.length; i++) {
      calendar[i] = {}
      if (i >= skipCount && i < skipCount + dayCount) {
        calendar[i].day = i - skipCount + 1
      }
    }

    if (scheduleData) {
      scheduleData.forEach(s => {
        const index = s.gameDate % 100 + skipCount - 1
        if (index < calendar.length) {
          if (calendar[index].first) {
            calendar[index].second = s
          } else {
            calendar[index].first = s
          }
        }
      })
    }

    const weeks = []
    for (let i = 0; i < calendar.length / 7; i++) {
      weeks.push(calendar.slice(i * 7, i * 7 + 7))
    }
    return weeks
  }

  const createAllTeamWeeks = () => {
    const calendar = new Array(skipCount + dayCount + remainCount)
    for (let i = 0; i < calendar.length; i++) {
      calendar[i] = {}
      calendar[i].games = []
      if (i >= skipCount && i < skipCount + dayCount) {
        calendar[i].day = i - skipCount + 1
      }
    }

    if (allTeamSchedules) {
      allTeamSchedules.forEach(s => {
        const index = s.gameDate % 100 + skipCount - 1
        if (index < calendar.length) {
          calendar[index].games.push(s)
        }
      })
    }

    const weeks = []
    for (let i = 0; i < calendar.length / 7; i++) {
      weeks.push(calendar.slice(i * 7, i * 7 + 7))
    }
    return weeks
  }

  const weeks = createWeeks()

  const allTeamWeeks = createAllTeamWeeks()

  const onPrevMonth = () => {
    onChangeMonth(format(new Date(year, Number(month) - 2, 1), 'yyyyMM'))
  }

  const onNextMonth = () => {
    onChangeMonth(format(new Date(year, Number(month), 1), 'yyyyMM'))
  }

  const selectMonth = month => {
    setSelectedMonth(month)
    setShowMonthOptions(false)
  }

  const openScoreBoard = (gmkey, gameDate) => {
    onScoreBoardRequest(gmkey, gameDate)
    setShowScoreBoard(true)
  }

  const iconCss = game => {
    if (game.outcome === '승') {
      return 'win'
    } else if (game.outcome === '패') {
      return 'lose'
    } else if (game.outcome === '무') {
      return 'draw'
    } else if (game.outcome === '취') {
      return 'cancel'
    } else if (game.status === '2') {
      return 'onair'
    } else {
      return ''
    }
  }

  const Day = ({ day }) => {

    return (
      <td className={day.first && day.first.homeKey === 'KT' ? 'home_play' : ''}>
        <span className="date">{day.day}</span>
        <ul className="icon_list">
          {day.first && day.second && <li className='dh'></li>}
          {day.first && <Link to={'/game/' + league + '/boxscore/' + day.first.gameDate + '/' + day.first.gmkey}><li className={day.first && iconCss(day.first)}></li></Link>}
          {day.second && <Link to={'/game/' + league + '/boxscore/' + day.second.gameDate + '/' + day.second.gmkey}><li className={day.second && iconCss(day.second)}></li></Link>}
        </ul>
        {
          day.first &&
          <Link to={'/game/' + league + '/boxscore/' + day.first.gameDate + '/' + day.first.gmkey}>
            <ul className="detail_list">
              <li className={"team " + (day.first && day.first.matchTeamCode.toLowerCase())}><span>{day.first && day.first.matchTeamName}</span></li>
              <li className="time_place">
                <ul>
                  <li className="first">{day.first && day.first.gtime} {day.second && '/' + day.second.gtime} {day.first && day.first.stadium}</li>
                </ul>
              </li>
              <li className="movieList">
                <ul>
                  <li className="first">{day.first && day.first.broadcast}</li>
                </ul>
              </li>
            </ul>
          </Link>
        }
      </td >
    )
  }

  const Week = ({ week }) => {
    return (<tr>
      {week.map((day, index) => <Day key={index} day={day} />)}
    </tr>)
  }

  const AllTeamDay = ({ day }) => {
    return (
      <td>
        <span className="date">{day.day}</span>
        <ul className="result_list">
          {day.games && day.games.map(game => (<li className={(game.visitKey === 'KT' || game.homeKey === 'KT') && 'kt_result'}>{game.visit}{game.visitScore}:{game.home}{game.homeScore}[{game.stadium}]</li>))}
        </ul>
      </td>
    )
  }

  const AllTeamWeek = ({ week }) => {
    return (<tr>
      {week.map((day, index) => <AllTeamDay key={index} day={day} />)}
    </tr>)
  }


  const MobileDay = ({ day }) => {
    if (day.first) {
      console.log(day.first.matchTeamCode)
      return (
        <td className={day.first.homeKey === 'KT' ? 'h_play' : 'a_play'}>
          <span className="date">{day.day}</span>
          <ul className="result_list">
            <li className={iconCss(day.first)}><Link onClick={() => openScoreBoard(day.first.gmkey, day.first.gameDate)}>{day.first.outcome}</Link></li>
            {day.second && <li className={iconCss(day.second)}><Link onClick={() => openScoreBoard(day.first.gmkey, day.second.gameDate)}>{day.second.outcome}</Link></li>}
          </ul>
          <Link to={'/game/' + league + '/boxscore/' + day.first.gameDate + '/' + day.first.gmkey}>
            <span className={'team ' + day.first.matchTeamCode.toLowerCase()} > {day.first.matchTeamName}</span>
          </Link>
        </td>
      )
    } else {
      return (<td></td>)
    }
  }

  const MobileWeek = ({ week }) => {
    return (<tr>
      {week.map((day, index) => <MobileDay key={index} day={day} />)}
    </tr>)
  }

  const TodayKtWizGame = ({ games }) => {
    if (games && games.length > 0) {
      return (<article>
        <h4>kt wiz 경기 일정</h4>
        <div className="table">
          <div className={'team team1 ' + games[0].visitKey.toLowerCase()}>
            <span className="logo">{games[0].visitKey}</span>
            <strong>{games[0].visit}</strong>
          </div>
          <div className="info">
            <strong>VS</strong>
            <span className="date">{games[0].displayDate}</span>
            <span className="place">{games[0].stadium}</span>
          </div>
          <div className={'team team2 ' + games[0].homeKey.toLowerCase()}>
            <span className="logo">{games[0].homeKey}</span>
            <strong>{games[0].home}</strong>
          </div>
        </div>
      </article >)
    } else {
      return (<article><div className="today_no_game">
        <img src={require('../../assets/img/game/logo_kt.png')} alt="kt wiz logo" />
        <p>오늘은 경기가 없습니다.</p>
      </div></article>)
    }
  }

  const TodayOtherGame = ({ games }) => {
    if (games && games.length > 0) {
      return (<article>
        <h4>전체 경기 일정</h4>
        <table>
          <caption>전체 경기 일정</caption>
          <thead>
            <tr>
              <th>날짜</th>
              <th>경기</th>
              <th>구장</th>
            </tr>
          </thead>
          <tbody>
            {games.map(game => (
              <tr key={game.gmkey}>
                <td><span className="date_time">{game.displayDate}</span></td>
                <td>
                  <span className="team team1">{game.visit}</span>
                  <span className={'icon ' + game.visitKey.toLowerCase()}></span>
                  <span className="box">vs</span>
                  <span className={'icon ' + game.homeKey.toLowerCase()}></span>
                  <span className="team team2">{game.home}</span>
                </td>
                <td><span className="place">{game.stadium}</span></td>
              </tr>
            ))}
          </tbody>
        </table >
      </article >)
    } else {
      return (<article>
        <h4>전체 경기 일정</h4>
        <table>
          <caption>전체 경기 일정</caption>
          <thead>
            <tr>
              <th>날짜</th>
              <th>경기</th>
              <th>구장</th>
            </tr>
          </thead>
          <tbody>
            <tr className="today_no_game">
              <td colSpan="3"><p>오늘은 경기가 없습니다.</p></td>
            </tr>
          </tbody>
        </table>
      </article>)
    }
  }

  const ScoreBoard = ({ scoreBoard }) => {
    if (scoreBoard) {
      return (<article className="article">
        <div className="game_result">
          <ul>
            <li>{scoreBoard.gamescore.displayDate}</li>
            <li>{scoreBoard.gamescore.visit}&nbsp;{scoreBoard.gamescore.vscore}<span>{gameState[scoreBoard.gamescore.endFlag]}</span>{scoreBoard.gamescore.hscore}&nbsp;{scoreBoard.gamescore.home}</li>
            <li>{scoreBoard.gamescore.stadium}</li>
          </ul>
          <table className="thead">
            <caption>경기 결과</caption>
            <colgroup>
              <col width="*" />
              <col width="6.1%" />
              <col width="6.1%" />
              <col width="6.1%" />
              <col width="6.1%" />
              <col width="6.1%" />
              <col width="6.1%" />
              <col width="6.1%" />
              <col width="6.1%" />
              <col width="6.1%" />
              <col width="6.1%" />
              <col width="6.1%" />
              <col width="6.1%" />
              <col width="6.1%" />
              <col width="6.1%" />
              <col width="6.1%" />
            </colgroup>
            <thead>
              <tr>
                <th></th>
                <th>1</th>
                <th>2</th>
                <th>3</th>
                <th>4</th>
                <th>5</th>
                <th>6</th>
                <th>7</th>
                <th>8</th>
                <th>9</th>
                <th>10</th>
                <th>11</th>
                <th>12</th>
                <th>R</th>
                <th>H</th>
                <th>E</th>
              </tr>
            </thead>
          </table>
          <table>
            <caption>경기 결과</caption>
            <colgroup>
              <col width="*" />
              <col width="6.1%" />
              <col width="6.1%" />
              <col width="6.1%" />
              <col width="6.1%" />
              <col width="6.1%" />
              <col width="6.1%" />
              <col width="6.1%" />
              <col width="6.1%" />
              <col width="6.1%" />
              <col width="6.1%" />
              <col width="6.1%" />
              <col width="6.1%" />
              <col width="6.1%" />
              <col width="6.1%" />
              <col width="6.1%" />
            </colgroup>
            <tbody>
              <tr>
                <th>{scoreBoard.inningscorelist[0].bhomeName}</th>
                <td className="bg_gray">{scoreBoard.inningscorelist[0].score1}</td>
                <td className="bg_gray">{scoreBoard.inningscorelist[0].score2}</td>
                <td className="bg_gray">{scoreBoard.inningscorelist[0].score3}</td>
                <td className="bg_gray">{scoreBoard.inningscorelist[0].score4}</td>
                <td className="bg_gray">{scoreBoard.inningscorelist[0].score5}</td>
                <td className="bg_gray">{scoreBoard.inningscorelist[0].score6}</td>
                <td className="bg_gray">{scoreBoard.inningscorelist[0].score7}</td>
                <td className="bg_gray">{scoreBoard.inningscorelist[0].score8}</td>
                <td className="bg_gray">{scoreBoard.inningscorelist[0].score9}</td>
                <td className="bg_gray">{scoreBoard.inningscorelist[0].score10}</td>
                <td className="bg_gray">{scoreBoard.inningscorelist[0].score11}</td>
                <td className="bg_gray">{scoreBoard.inningscorelist[0].score12}</td>
                <td>{scoreBoard.inningscorelist[0].run}</td>
                <td>{scoreBoard.inningscorelist[0].hit}</td>
                <td>{scoreBoard.inningscorelist[0].error}</td>
              </tr>
              <tr>
                <th>{scoreBoard.inningscorelist[1].bhomeName}</th>
                <td className="bg_gray">{scoreBoard.inningscorelist[1].score1}</td>
                <td className="bg_gray">{scoreBoard.inningscorelist[1].score2}</td>
                <td className="bg_gray">{scoreBoard.inningscorelist[1].score3}</td>
                <td className="bg_gray">{scoreBoard.inningscorelist[1].score4}</td>
                <td className="bg_gray">{scoreBoard.inningscorelist[1].score5}</td>
                <td className="bg_gray">{scoreBoard.inningscorelist[1].score6}</td>
                <td className="bg_gray">{scoreBoard.inningscorelist[1].score7}</td>
                <td className="bg_gray">{scoreBoard.inningscorelist[1].score8}</td>
                <td className="bg_gray">{scoreBoard.inningscorelist[1].score9}</td>
                <td className="bg_gray">{scoreBoard.inningscorelist[1].score10}</td>
                <td className="bg_gray">{scoreBoard.inningscorelist[1].score11}</td>
                <td className="bg_gray">{scoreBoard.inningscorelist[1].score12}</td>
                <td>{scoreBoard.inningscorelist[1].run}</td>
                <td>{scoreBoard.inningscorelist[1].hit}</td>
                <td>{scoreBoard.inningscorelist[1].error}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </article>)
    } else {
      return (<article></article>)
    }
  }

  return (
    <>
      <div className="game_info_top_wrap">
        {recentGames &&
          <div className="around_today_game_list">
            <ul>
              <li className="previous_date">
                <article>
                  <h5><span>{recentGames.prev.gyear}.{recentGames.prev.gmonth}.{recentGames.prev.gday}</span></h5>
                  <div className="table">
                    <div className={"team team1 " + (recentGames.prev.visitKey.toLowerCase())}>
                      <div className="logo">{recentGames.prev.visitFullname}</div>
                      <div>
                        <dl>
                          <dt>{recentGames.prev.visitFullname}</dt>
                          <dd>{recentGames.prev.visitDecision} : {recentGames.prev.visitDecisionPitcher}</dd>
                        </dl>
                      </div>
                    </div>
                    <div className="info">
                      <div className="score">
                        <strong>{recentGames.prev.visitScore}&nbsp;:&nbsp;{recentGames.prev.homeScore}</strong>
                        <span className="result">{recentGames.prev.outcome}</span>
                        <span className="info_mark"><Link to={'/game/' + league + '/boxscore/' + recentGames.prev.gameDate + '/' + recentGames.prev.gmkey}>경기 정보</Link></span>
                      </div>
                    </div>
                    <div className={"team team2 " + (recentGames.prev.homeKey.toLowerCase())}>
                      <div className="logo">{recentGames.prev.homeFullname}</div>
                      <div>
                        <dl>
                          <dt>{recentGames.prev.homeFullname}</dt>
                          <dd>{recentGames.prev.homeDecision} : {recentGames.prev.homeDecisionPitcher}</dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                </article>
              </li>
              <li className="current_date">
                <article>
                  <h5><span>{recentGames.current.gyear}.{recentGames.current.gmonth}.{recentGames.current.gday}</span></h5>
                  <div className="table">
                    <div className={"team team1 " + (recentGames.current.visitKey.toLowerCase())}>
                      <div className="logo">{recentGames.current.visitFullname}</div>
                      <div>
                        <dl>
                          <dt>{recentGames.current.visitFullname}</dt>
                          <dd>{recentGames.current.visitDecision ? recentGames.current.visitDecision + ' : ' + recentGames.current.visitDecisionPitcher : '선발 : ' + (recentGames.current.visitStarter ? recentGames.current.visitStarter : '미정')}</dd>
                        </dl>
                      </div>
                    </div>
                    <div className="info">
                      <div className="detail">
                        <strong>{recentGames.current.visitScore ? recentGames.current.visitScore : '0'}&nbsp;:&nbsp;{recentGames.current.homeScore ? recentGames.current.homeScore : '0'}</strong>
                        {recentGames.current.status === '2' ? <span className="result">경기중</span> : (recentGames.current.outcome ? <span className="result">{recentGames.current.outcome}</span> : <span className="date_place">{recentGames.current.gtime} {recentGames.current.stadium}</span>)}
                        <span className="info_mark"><Link to={'/game/' + league + '/boxscore/' + recentGames.current.gameDate + '/' + recentGames.current.gmkey}>경기 정보</Link></span>
                      </div>
                    </div>
                    <div className={"team team2 " + (recentGames.current.homeKey.toLowerCase())}>
                      <div className="logo">{recentGames.current.homeFullname}</div>
                      <div>
                        <dl>
                          <dt>{recentGames.current.homeFullname}</dt>
                          <dd>{recentGames.current.homeDecision ? recentGames.current.homeDecision + ' : ' + recentGames.current.homeDecisionPitcher : '선발 : ' + (recentGames.current.homeStarter ? recentGames.current.homeStarter : '미정')}</dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                </article>
              </li>
              <li className="next_date">
                {recentGames.next ? (
                  <article>
                    <h5><span>{recentGames.next.gyear}.{recentGames.next.gmonth}.{recentGames.next.gday}</span></h5>
                    <div className="table">
                      <div className={"team team1 " + (recentGames.next ? recentGames.next.visitKey.toLowerCase() : '')}>
                        <div className="logo">{recentGames.next && recentGames.next.visitFullname}</div>
                        <div>
                          <dl>
                            <dt>{recentGames.next && recentGames.next.visitFullname}</dt>
                            <dd>선발 : {recentGames.next && recentGames.next.visitStarter ? recentGames.next.visitStarter : '미정'}</dd>
                          </dl>
                        </div>
                      </div>
                      <div className="info">
                        <div className="detail">
                          <strong>VS</strong>
                          <span className="date_place">{recentGames.next && recentGames.next.gtime} {recentGames.next && recentGames.next.stadium}</span>
                          <span className="info_mark"><Link to="">경기 정보</Link></span>
                        </div>
                      </div>
                      <div className={"team team2 " + (recentGames.next ? recentGames.next.homeKey.toLowerCase() : '')}>
                        <div className="logo">{recentGames.next && recentGames.next.homeFullname}</div>
                        <div>
                          <dl>
                            <dt>{recentGames.next && recentGames.next.homeFullname}</dt>
                            <dd>선발 : {recentGames.next && recentGames.next.homeStarter ? recentGames.next.homeStarter : '미정'}</dd>
                          </dl>
                        </div>
                      </div>
                    </div>
                  </article>
                ) : (
                    <article>
                      <div className="no_game">
                        <h5><span></span></h5>
                        <p>예정된 경기가 없습니다.</p>
                      </div>
                    </article>
                  )}
              </li>
            </ul>
          </div>
        }
      </div>
      {/*  // game_info_top_wrap */}

      {/*  game_info_contents_wrap */}
      <div className="game_info_contents_wrap">
        {/*  contents_top_wrap */}
        <div className="contents_top_wrap">
          {/*  contents_tab */}
          <div className="contents_tab">
            <ul>
              <li className={tab === 'ktwiz' ? 'active' : ''}><Link onClick={() => setTab('ktwiz')} className="tab_ktwiz">kt wiz 경기</Link></li>
              <li className={tab === 'total' ? 'active' : ''}><Link onClick={() => setTab('total')} className="tab_total">전체 리그</Link></li>
            </ul>
          </div>
          {/*  // contents_tab */}

          {/*  month_select_wrap */}
          <div className="month_select_wrap">
            <button className="btn_prev_month" onClick={() => onPrevMonth()}></button>
            <span className="year">{year}년</span>
            <div className="month">
              <Link onClick={() => setShowMonthOptions(!showMonthOptions)} className="seleted_month">
                {selectedMonth}월
              </Link>
              {/*  option_month */}
              <ul className={'option_month ' + (showMonthOptions ? 'active' : '')}>
                <li><Link onClick={() => selectMonth('10')}>10</Link></li>
                <li><Link onClick={() => selectMonth('09')}>09</Link></li>
                <li><Link onClick={() => selectMonth('08')}>08</Link></li>
                <li><Link onClick={() => selectMonth('07')}>07</Link></li>
                <li><Link onClick={() => selectMonth('06')}>06</Link></li>
                <li><Link onClick={() => selectMonth('05')}>05</Link></li>
                <li><Link onClick={() => selectMonth('04')}>04</Link></li>
                <li><Link onClick={() => selectMonth('03')}>03</Link></li>
              </ul>
            </div>
            <Link onClick={() => onChangeMonth(year + selectedMonth)} className="btn_select">이동</Link>
            <button className="btn_next_month" onClick={() => onNextMonth()}></button>
            {/*  // option_month */}
          </div>
          {/*  // month_select_wrap */}

          {/*  승, 패, 무 분류 아이콘 */}
          <div className="result_icon_list">
            <ul>
              <li><img src={require('../../assets/img/game/icon_win.png')} alt="승 아이콘" /></li>
              <li><img src={require('../../assets/img/game/icon_lose.png')} alt="패 아이콘" /></li>
              <li><img src={require('../../assets/img/game/icon_draw.png')} alt="무 아이콘" /></li>
            </ul>
          </div>
          {/*  // 승, 패, 무 분류 아이콘 */}
        </div>
        {/*  // contents_top_wrap */}

        {/*  calendar_content_wrap */}
        <div className="calendar_content_wrap">
          {/*  ktwiz table */}
          <table className="ktwiz" style={{ display: tab === 'ktwiz' ? 'table' : 'none' }}>
            <caption>한달간 경기일정</caption>
            <thead>
              <tr>
                <th style={{ color: '#ec0a0b' }}>일</th>
                <th>월</th>
                <th>화</th>
                <th>수</th>
                <th>목</th>
                <th>금</th>
                <th>토</th>
              </tr>
            </thead>
            <tbody>
              {weeks && weeks.map((week, index) => <Week key={index} week={week} />)}
            </tbody>
          </table>
          {/*  // ktwiz table */}

          {/*  total table */}
          <table className="total" style={{ display: tab === 'total' ? 'table' : 'none' }}>
            <caption>한달간 경기일정</caption>
            <thead>
              <tr>
                <th style={{ color: '#ec0a0b' }}>일</th>
                <th>월</th>
                <th>화</th>
                <th>수</th>
                <th>목</th>
                <th>금</th>
                <th>토</th>
              </tr>
            </thead>
            <tbody>
              {allTeamWeeks && allTeamWeeks.map((week, index) => <AllTeamWeek key={index} week={week} />)}
            </tbody>
          </table>
          {/*  // total table */}
          <p className="notice_schedule">* 경기 일정은 사정에 따라 변동될 수있습니다.</p>
        </div>
        {/*  // calendar_content_wrap */}

        <div className="broadcast_list">
          <ul>
            <li><p><strong className="type">TV</strong>K-2T<span>(KBS 2TV)</span>, M-T<span>(MBC TV)</span>, S-T<span>(SBS TV)</span>, KN-T<span>(KBS N SPORTS)</span>, MS-T<span>(MBC SPORTS PLUS)</span>, SS-T<span>(SBS SPORTS)</span>, SPOTV+<span>(SPOTV PLUS)</span>, SKY-T<span>(SKY SPORTS)</span></p></li>
            <li><p><strong className="type">CMB</strong>D-CMB<span>(대전 CMB)</span>, K-CMB<span>(광주 CMB)</span></p></li>
            <li><p><strong className="type">IPTV</strong>SPO-T<span>(SPOTV)</span>, SPO-2T<span>(SPOTV2)</span>, IB-T<span>(IB SPORTS)</span></p></li>
            <li><p><strong className="type">RADIO</strong>K-2R<span>(KBS 2라디오)</span>, M-R<span>(MBC 라디오)</span>, S-R<span>(SBS 라디오)</span>, DK-R<span>(대전 KBS 라디오)</span>, TM-R<span>(대구 MBC 라디오)</span>, PM-R<span>(부산 MBC 라디오)</span>, DM-R<span>(대전 MBC 라디오)</span>, KM-R<span>(광주 MBC 라디오)</span>, GM-R<span>(MBC 경남 라디오)</span>, T-R<span>(TBC 라디오)</span>, TJ-R<span>(TJB 라디오)</span>, KNN-R<span>(KNN 라디오)</span>, KBC-R<span>(KBC 라디오)</span></p></li>
          </ul>
        </div>
      </div>
      {/*  // game_info_contents_wrap */}

      {/*  mobile mode */}
      {/*  game_schedule_info_wrap */}
      <div className="orderby_tab_wrap">
        <div className="orderby_tab">
          <ul>
            <li><Link onClick={() => setMobileTab('today')} className={'tab_today_game ' + (mobileTab === 'today' && 'active')}>오늘 경기 일정</Link></li>
            <li><Link onClick={() => setMobileTab('season')} className={'tab_season_game ' + (mobileTab === 'season' && 'active')}>시즌 경기 일정</Link></li>
          </ul>
        </div>
      </div>
      <div className="game_schedule_info_wrap">
        <div className="today_game_schedule_info" style={{ display: mobileTab === 'today' ? 'block' : 'none' }}>
          <TodayKtWizGame games={daySchedule && daySchedule.ktwiztodaygame} />
          <TodayOtherGame games={daySchedule && daySchedule.otherteamlist} />
        </div>
        <div className="season_game_schedule_info" style={{ display: mobileTab === 'season' ? 'block' : 'none' }}>
          <div className="top_month_select">
            <button className="btn_prev_month" onClick={() => onPrevMonth()}></button>
            <span className="selected_month">{month}월</span>
            <button className="btn_next_month" onClick={() => onNextMonth()}></button>
          </div>
          <div className="season_calendar_wrap">
            <div className="top">
              <ul>
                <li><span className="icon_a">원정경기</span></li>
                <li><span className="icon_h">홈경기</span></li>
              </ul>
            </div>

            <div className="thead_wrap">
              <table className="thead">
                <caption>시즌 경기 일정</caption>
                <thead>
                  <tr>
                    <th className="first">일</th>
                    <th>월</th>
                    <th>화</th>
                    <th>수</th>
                    <th>목</th>
                    <th>금</th>
                    <th className="last">토</th>
                  </tr>
                </thead>
              </table>
            </div>
            <div>
              <table>
                <tbody>
                  {weeks && weeks.map((week, index) => <MobileWeek key={index} week={week} />)}
                </tbody>
              </table>
            </div>
            <div className="popup_game_result" style={{ display: showScoreBoard ? 'block' : 'none' }}>
              <h6>경기 결과</h6>
              <div className="popup_content_box">
                <ScoreBoard scoreBoard={scoreBoard} />
              </div>
              <button className="btn_close" onClick={() => setShowScoreBoard(false)}>팝업 닫기 버튼</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default GameSchedule
