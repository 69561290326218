import client from '../client';
import qs from "query-string"

export const callLogin = (user) => {

  const data = qs.stringify({
    "user.simpleId": user.id,
    "user.memberPw": user.pw,
    pnsToken: "dummyPns"
  })

  return client.post("/api/v2/auth/login", data)
    .then(response => {
      if (response.status === 200 || response.status === "200") {

        console.log(`###### API :: LOGIN => ${JSON.stringify(response.data.data)}`)

        // 성공 처리
        if (response.data.status === 0) {
          return {
            user: {
              id: response.data.data.user.simpleId,
              tid: response.data.data.accessToken
            }
          }
        }
        // 실패 처리
        else {
          return {
            error: {
              status: response.data.status,
              message: response.data.message
            }
          }
        }
      }

    })
    // TODO : 에러 어떻게 처리할 것인지?
    .catch(error => {
      console.log(error);

      return {
        error
      }
    })

}


export const callLogout = (user) => {
  // TODO: logout API
  return true
}
