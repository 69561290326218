import React from 'react';
import { useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import qs from 'query-string';
import format from 'date-fns/format'

import TextSearchForm from './searchForm/TextSearchForm';
import PeriodSearchForm from './searchForm/PeriodSearchForm';

const LiveArticleSearch = ({
  options = { title: '제목' },
  option = 'title',
  text,
  startDate,
  endDate
}) => {
  const [search, setSearch] = React.useState({ 'mediaName': text });
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  React.useEffect(() => {
    // 기존 query 읽어오기
    const query = qs.parse(location.search);
    const newQuery = {
      ...query,
      ...search
    };
    history.push(`${match.url}?${qs.stringify(newQuery)}`);
  }, [search]);

  const handleTextSubmit = (option, text) => {
    setSearch({
      ...search,
      'mediaName': text
    });
  };

  const handlePeriodSubmit = (startDate, endDate) => {
    console.log('handlePeriodSubmit', { startDate, endDate });
    setSearch({
      ...search,
      'search.startDate': format(startDate, 'yyyy-MM-dd'),
      'search.endDate': format(endDate, 'yyyy-MM-dd')
    });
  };

  return (
    <div className='searchbox_wrap'>
      <div className='searchbox_form active add_period_search'>
        <TextSearchForm
          options={options}
          option={option}
          text={search['mediaName']}
          onSubmit={handleTextSubmit}
        />
        <PeriodSearchForm startDate={startDate} endDate={endDate} onSubmit={handlePeriodSubmit} />
      </div>
    </div>
  );
};

LiveArticleSearch.propTypes = {
  sort: PropTypes.oneOf(['title', 'content', 'player']),
  text: PropTypes.string,
  onSearch: PropTypes.func
};

export default LiveArticleSearch;
