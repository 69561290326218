import client from '../client';

export const getJoinTerms = () => {
  return client.get('/api/v2/user/terms?isHpJoin=Y');
}

export const isDeplicateUserId = (userId) => {

  const params = new URLSearchParams()
  params.append('simpleId', userId)

  return client.post(`/api/v2/user/id/check`, params)
}

export const requestAuthNo = (userId) => {

  const params = new URLSearchParams()
  params.append('type', 'PHONE')
  params.append('mobpNo', '01064370382')

  return client.post(`/api/v2/user/authNo/request`, params)
}

export const requestJoin = (joinUser, approvedTerms) => {

  const params = new URLSearchParams()
  params.append('user.simpleId', joinUser.simpleId)
  params.append('user.memberPw', joinUser.memberPw)
  params.append('user.mobpNo', joinUser.mobpNo1 + '-' + joinUser.mobpNo2 + '-' + joinUser.mobpNo3)
  params.append('user.mobpNo1', joinUser.mobpNo1)
  params.append('user.mobpNo2', joinUser.mobpNo2)
  params.append('user.mobpNo3', joinUser.mobpNo3)
  params.append('user.memberEmail', joinUser.memberEmail)
  params.append('user.isAdult', joinUser.isAdult ? 'Y' : 'N')
  params.append('user.postNo', joinUser.postNo)
  params.append('user.addr1', joinUser.addr1)
  params.append('user.addr2', joinUser.addr2)
  if (joinUser.recommenderId != null) {
    params.append('user.recommenderId', joinUser.recommenderId)
  }
  params.append('user.smsYn', joinUser.smsYn)
  params.append('user.emailYn', joinUser.emailYn)
  params.append('isOver14y', joinUser.isAdult ? 'Y' : 'N')
  params.append('user.childYn', joinUser.isAdult ? 'N' : 'Y')
  if (!joinUser.isAdult) {
    params.append('user.childUserName', joinUser.childUserName)
    params.append('user.childBirthDate', joinUser.childBirthDate)
    params.append('user.childSex', joinUser.childSex)
  }
  params.append('realNameRequestSeq', joinUser.realNameRequestSeq)
  params.append('realNameAuthType', joinUser.realNameAuthType)
  params.append('certData', joinUser.certData)

  let i = 0
  for (const idx in approvedTerms) {
    params.append('approvedTerms[' + i + '].termsId', approvedTerms[idx].termsId)
    params.append('approvedTerms[' + i + '].termsKind', approvedTerms[idx].termsKind)
    i = i + 1
  }

  console.log(params)

  return client.post(`/api/v2/user/web/join`, params)
}
