import React from 'react'

const PitcherDetailSecondMobileTabTotal = ({ dataTable, yearrecordlist }) => {

  const evalTotal = (operationType, param) => {

    switch (operationType) {

      // 집계
      case 'sum':
        let sumValue = 0

        yearrecordlist &&
          yearrecordlist.map((v) => {
            sumValue += Number(v[param])
          })
        return sumValue

      // 평균
      case 'avg':
        let avgValue = 0
        let listCnt = yearrecordlist ? yearrecordlist.length : 0

        yearrecordlist &&
          yearrecordlist.map((v) => {
            avgValue += Number(v[param])
          })

        return (avgValue / listCnt).toFixed(2)

      // 이닝
      case 'inn':
        let innValue = 0
        let outCnt = 0

        yearrecordlist &&
          yearrecordlist.map((v) => {

            let innArr = v[param].split(' ')

            if (innArr.length > 1) {
              innValue += Number(innArr[0])
              outCnt += Number(innArr[1].split('/')[0])
            } else {
              innValue += Number(v[param])
            }

          })

        if (outCnt >= 3) {
          let evalOutCnt = Math.floor(outCnt / 3)
          innValue += evalOutCnt
          outCnt -= evalOutCnt * 3
        }

        return `${innValue}${outCnt > 0 ? ` ${outCnt}/3` : ''}`

      default:
        return '-'
    }
  }

  return (
    <>
      {/* 통산 기록 mobile */}
      <article className="player_record_content total mb_mode">
        <div className="player_record">
          <div className="container">
            <h5>{dataTable.title}</h5>
          </div>
          <div className="container table first_fixed">
            {/* fix_tb_block */}
            <div className="fix_tb_block btD7161b">
              <div className="fix_tb_content">
                <div className="fix_tb_wrap">
                  <table className="wid430">
                    <caption>{dataTable.title}</caption>
                    <thead>
                      <tr>
                        {
                          dataTable.data.map((v, i) => (
                            (i > 0) &&
                            <th key={i}>{v.head}</th>
                          ))
                        }
                      </tr>
                    </thead>
                    <tbody>
                      {
                        yearrecordlist && yearrecordlist.length > 0
                          ? yearrecordlist.map((v, i) => (
                            <tr key={i}>
                              {
                                dataTable.data.map((sv, si) => (
                                  (si > 0) &&
                                  <td key={si}>{v[sv.bodyParam]}</td>
                                ))
                              }
                            </tr>
                          ))
                          : <tr><td colSpan={dataTable.data.length}>데이터가 없습니다.</td></tr>
                      }
                    </tbody>
                    {
                      (yearrecordlist && yearrecordlist.length > 0) &&
                      <tfoot>
                        <tr>
                          <td></td>
                          {
                            dataTable.data.map((v, i) => (
                              v.foot &&
                              <td key={i}>{evalTotal(v.foot, v.bodyParam)}</td>
                            ))
                          }
                        </tr>
                      </tfoot>
                    }
                  </table>
                </div>
                <table className="fix_tb">
                  <thead>
                    <tr><th>{dataTable.data[0].head}</th></tr>
                  </thead>
                  <tbody>
                    {
                      yearrecordlist && yearrecordlist.length > 0
                        ? yearrecordlist.map((v, i) => (
                          <tr key={i}>
                            {
                              dataTable.data.map((sv, si) => (
                                (si === 0) &&
                                <td key={si}>{v[sv.bodyParam]}</td>
                              ))
                            }
                          </tr>
                        ))
                        : <tr><td>　</td></tr>
                    }
                  </tbody>
                  {
                    (yearrecordlist && yearrecordlist.length > 0) &&
                    <tfoot>
                      <tr><td className="foot">통산</td></tr>
                    </tfoot>
                  }
                </table>
              </div>
            </div>
            {/* // fix_tb_block */}
          </div>
        </div>
      </article>
      {/* // 통산 기록 mobile */}
    </>
  )
}

export default PitcherDetailSecondMobileTabTotal
