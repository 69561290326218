import client from '../client';

export const registSponsorReq = (sponsorReq) => {

  return client.post("/api/v2/sponsor/sponsorReq", sponsorReq, {
    headers: { 'Content-Type': 'multipart/form-data' }
  }).then(response => {
    // console.log('### CMS API :: /api/v2/sponsor/sponsorReq ###', response.data)
    return response.data
  }).catch(error => {
    // console.error(error);
    return error
  })
}
