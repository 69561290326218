import React from 'react'

const Breadcrumb = ({ page }) => {
  let path = []

  const makeBreadcrumb = page => {
    path.push(page)
    if (page.parent == null) {
      return
    }
    makeBreadcrumb(page.parent)
  }

  makeBreadcrumb(page)

  return (
    <div className='page_location'>
      {path.reverse().map(cur => (
        <span key={cur.title} className={cur.parent == null ? 'home' : cur === page ? 'here' : 'menu'}>
          {cur.title}
        </span>
      ))}
    </div>
  )
}

export default Breadcrumb
