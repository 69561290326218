import ContentTemplate from "components/common/ContentTemplate";
import PageTemplate from "components/common/PageTemplate";
import React from "react";
import { presentLogEn } from "../../privacyPolicyTextEn";

const MobileTermsPageEn = () => {
  return (
    <PageTemplate>
      <ContentTemplate className="sign_up_step1">
        <h1 className="service_compare_title">Personal Information Collection and Use Consent</h1>
        {presentLogEn.map((item, idx) => (
          <p key={idx}>{item}</p>
        ))}
      </ContentTemplate>
    </PageTemplate>
  );
};

export default MobileTermsPageEn;
