export const getLoginUser = () => {
  const sessionUser = sessionStorage.getItem('sessionUser')
  if(sessionUser == null) {
    return null
  }
  return JSON.parse(sessionUser)
}

export const setLoginUser = (user) => {
  sessionStorage.setItem('sessionUser', JSON.stringify(user))
}
