import axios from 'axios';

const client = axios.create();

client.defaults.headers.common['Akey'] = 'f68cNbKYSKJYan41zIcjOmbRUxQ='
client.defaults.headers.common['App-Agent'] = 'platformCode=70;platformVer=5.0.1;deviceId=ASD12345KFOKFIKL;appName=wizzapWeb;appVer=10.0.0;deviceModel=Browser;'
client.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

client.interceptors.request.use(function (config) {
  const user = sessionStorage.getItem('sessionUser')
  if (user != null && user !== 'null') {
    config.headers.common['Access-Token'] = JSON.parse(user).tid
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});

export default client;
