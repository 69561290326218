import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { yearListRequest, batterRankingRequest } from '../../modules/game/ranking'
import BatterRankingTable from '../../components/game/BatterRankingTable'
import ContentSwitchingTab from '../../components/common/ContentSwitchingTab'

const RegularLeagueBatterRankingTableContainer = ({ match, year, onChangeYear }) => {

  const batterRankingList = useSelector(state => state.wizData.data && state.wizData.data['BATTER_RANKING_LIST'])
  // // const batterRankingListError = useSelector(state => state.wizData.error && state.wizData.error['BATTER_RANKING_LIST'])

  const allBatterRankingList = useSelector(state => state.wizData.data && state.wizData.data['ALL_BATTER_RANKING_LIST'])
  // // const allBatterRankingListError = useSelector(state => state.wizData.error && state.wizData.error['ALL_BATTER_RANKING_LIST'])

  const yearList = useSelector(state => state.wizData.data && state.wizData.data['YEAR_LIST'])

  const dispatch = useDispatch()

  const [sortKey, setSortKey] = useState('HRA')
  const [playerName, setPlayerName] = useState('')

  useEffect(() => {
    dispatch(yearListRequest({}, 'YEAR_LIST'))
  }, [dispatch])

  useEffect(() => {
    dispatch(batterRankingRequest({ 'gameSearch.gyear': year, 'gameSearch.teamCode': 'KT', 'gameSearch.sortKey': sortKey, 'gameSearch.pname': playerName }, 'BATTER_RANKING_LIST'))
    dispatch(batterRankingRequest({ 'gameSearch.gyear': year, 'gameSearch.sortKey': sortKey, 'gameSearch.paFlag': 'Y', 'gameSearch.pname': playerName }, 'ALL_BATTER_RANKING_LIST'))
  }, [year, sortKey, playerName])

  if (yearList && year == null) {
    onChangeYear(yearList.gameYearlist[0].gyear)
  }

  const secondContentTabs = [
    { type: 'regular_league', title: 'kt wiz 타자' },
    { type: 'latest_game', title: '전체 타자 순위' }
  ]

  const [contentType, setContentType] = useState(secondContentTabs[0].type)

  const onCliekContentTab = type => {
    setContentType(type)
  }

  return (
    <>
      <ContentSwitchingTab className='detail_lnb_wrap' onClickContentTab={onCliekContentTab} currentContentType={contentType} tabs={secondContentTabs} showPlayerSearch={true} onSearchPlayerName={setPlayerName} onSearchYear={onChangeYear} yearList={yearList && yearList.gameYearlist} />
      <BatterRankingTable batterList={batterRankingList} allBatterList={allBatterRankingList} tab={contentType} onChangeSort={setSortKey} onChangePlayerName={setPlayerName} onChangeYear={onChangeYear} year={year} sortKey={sortKey} yearList={yearList && yearList.gameYearlist} />
    </>
  )
}

export default RegularLeagueBatterRankingTableContainer
