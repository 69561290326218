import React, { useState, useEffect } from 'react'
import FindPwMobile from './FindPwMobile'
import FindPwEmail from './FindPwEmail'
import * as Enum from '../../../constants/enum'
import * as yup from 'yup'

const initUserCert = {
  simpleId: '',
  mobpNo1: Enum.MOBILE_FRONT_NUMS[0],
  mobpNo2: '',
  mobpNo3: '',
  email1: '',
  email2: '',
  certNo: '',
  certSeq: '',
  reqType: Enum.CERT_REQ_TYPES.FIND_PW,
  certType: Enum.CERT_TYPES.MOBILE
}

const mobileCertSchema = yup.object().shape({
  simpleId: yup.string().max(20).required(),
  mobpNo1: yup.string().min(3).max(3).required(),
  mobpNo2: yup.string().min(3).max(4).required(),
  mobpNo3: yup.string().min(4).max(4).required()
})

const mobileFindSchema = yup.object().shape({
  simpleId: yup.string().max(20).required(),
  mobpNo1: yup.string().min(3).max(3).required(),
  mobpNo2: yup.string().min(3).max(4).required(),
  mobpNo3: yup.string().min(4).max(4).required(),
  isCert: yup.boolean().oneOf([true]),
  certNo: yup.string().required()
})


const emailCertSchema = yup.object().shape({
  simpleId: yup.string().max(20).required(),
  email1: yup.string().required(),
  email2: yup.string().required(),
})

const emailFindSchema = yup.object().shape({
  simpleId: yup.string().max(20).required(),
  email1: yup.string().required(),
  email2: yup.string().required(),
  isCert: yup.boolean().oneOf([true]),
  certNo: yup.string().required()
})

const FindPw = ({ cert, onCertRequest, onFindPwRequest }) => {

  const [findType, setFindType] = useState(Enum.FIND_TYPES.FIND_PW_MOBILE)
  const [userCert, setUserCert] = useState(initUserCert)
  const [isCert, setIsCert] = useState(false)
  const [isMobileDisableCertButton, setIsMobileDisableCertButton] = useState(false)
  const [isMobileDisableFindButton, setIsMobileDisableFindButton] = useState(false)
  const [isEmailDisableCertButton, setIsEmailDisableCertButton] = useState(false)
  const [isEmailDisableFindButton, setIsEmailDisableFindButton] = useState(false)

  useEffect(() => {
    setUserCert({ ...userCert, mobpNo: `${userCert.mobpNo1}-${userCert.mobpNo2}-${userCert.mobpNo3}` })
  }, [userCert.mobpNo1, userCert.mobpNo2, userCert.mobpNo3])

  useEffect(() => {
    setUserCert({ ...userCert, memberEmail: `${userCert.email1}@${userCert.email2}` })
  }, [userCert.email1, userCert.email2])

  useEffect(() => {
    setUserCert({ ...userCert, certSeq: cert.certSeq })
    setIsCert(cert.certSeq !== null)
  }, [cert])

  const onChangeUserCert = (k, e) => { setUserCert({ ...userCert, [k]: e.target.value }) }
  const onChangeFindType = e => {
    setFindType(e.target.value)
    if (Enum.FIND_TYPES.FIND_PW_MOBILE === e.target.value) {
      setUserCert({ ...initUserCert, certType: Enum.CERT_TYPES.MOBILE })
    } else if (Enum.FIND_TYPES.FIND_PW_EMAIL === e.target.value) {
      setUserCert({ ...initUserCert, certType: Enum.CERT_TYPES.EMAIL })
    }
    setIsCert(false)
    setIsMobileDisableCertButton(false)
    setIsMobileDisableFindButton(false)
    setIsEmailDisableCertButton(false)
    setIsEmailDisableFindButton(false)
  }
  const onChangeIsCert = () => { setIsCert() }
  const findTypeState = (findType) => {
    switch (findType) {
      case Enum.FIND_TYPES.FIND_PW_MOBILE:
        return (
          <FindPwMobile
            onCertRequest={onCertRequest}
            onFindPwRequest={onFindPwRequest}
            userCert={userCert}
            onChangeUserCert={onChangeUserCert}
            isMobileDisableCertButton={isMobileDisableCertButton}
            isMobileDisableFindButton={isMobileDisableFindButton}
            onChangeIsCert={onChangeIsCert}
            isCert={isCert} />
        )
      case Enum.FIND_TYPES.FIND_PW_EMAIL:
        return (
          <FindPwEmail
            onCertRequest={onCertRequest}
            onFindPwRequest={onFindPwRequest}
            userCert={userCert}
            onChangeUserCert={onChangeUserCert}
            isEmailDisableCertButton={isEmailDisableCertButton}
            isEmailDisableFindButton={isEmailDisableFindButton}
            onChangeIsCert={onChangeIsCert}
            isCert={isCert} />
        )
      default:
        return <></>
    }
  }

  mobileCertSchema.isValid({
    simpleId: userCert.simpleId,
    mobpNo1: userCert.mobpNo1,
    mobpNo2: userCert.mobpNo2,
    mobpNo3: userCert.mobpNo3
  }).catch(error => {
    console.log(error)
  }).then(response => {
    setIsMobileDisableCertButton(response)
  })

  mobileFindSchema.isValid({
    simpleId: userCert.simpleId,
    mobpNo1: userCert.mobpNo1,
    mobpNo2: userCert.mobpNo2,
    mobpNo3: userCert.mobpNo3,
    isCert: isCert,
    certNo: userCert.certNo
  }).catch(error => {
    console.log(error);
  }).then(response => {
    setIsMobileDisableFindButton(response)
  })

  emailCertSchema.isValid({
    simpleId: userCert.simpleId,
    email1: userCert.email1,
    email2: userCert.email2,
  }).catch(error => {
    console.log(error);
  }).then(response => {
    setIsEmailDisableCertButton(response)
  })

  emailFindSchema.isValid({
    simpleId: userCert.simpleId,
    email1: userCert.email1,
    email2: userCert.email2,
    isCert: isCert,
    certNo: userCert.certNo
  }).catch(error => {
    console.log(error);
  }).then(response => {
    setIsEmailDisableFindButton(response)
  })

  return (
    <>
      <div className="content_box find_by find_pw">
        {/*상단 설명*/}
        <div className="group group1">
          <div className="content_desc">
            <p>가입 당시 등록한 정보를 통해 비밀번호를<br />찾을 수 있습니다. 휴대폰 또는 이메일정보 중<br />한가지를 선택해주세요.</p>
          </div>
          <div className="radio_wrap clearfix" onChange={onChangeFindType}>
            <label htmlFor="find_pw_mobile"><input type="radio" id="find_pw_mobile" value="find_pw_mobile" name="find_pw_method" className="pw_mobile_radio mr5" defaultChecked />휴대폰</label> {/*휴대폰으로 찾기가 디폴트 화면입니다. 클릭 시 group2.pw_mobile_form 나타납니다*/}
            <label htmlFor="find_pw_email"><input type="radio" id="find_pw_email" value="find_pw_email" name="find_pw_method" className="pw_email_radio mr5" />이메일</label>
            {/*클릭 시 group2.pw_email_form 나타납니다*/}
          </div>{/*//.radio_wrap*/}
        </div>{/*//.group1*/}

        {/* 라디오 버튼에 따른 뷰 렌더링 */}
        {findTypeState(findType)}

      </div>{/*//.content_box*/}
    </>
  )
}

export default FindPw
