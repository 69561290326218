import React from 'react'
// import { useHistory } from 'react-router-dom'

const Pagination = ({ page, totalPage, onClickPage }) => {

  console.log(page, totalPage)

  const PC_PAGINATION_SIZE = 10

  const MOBILE_PAGINATION_SIZE = 5

  const firstTag = (
    <button className='btn_first' onClick={() => onClickPage(1)}>
      처음
    </button>
  )

  const pcPrevTag = (
    <button className='btn_prev' onClick={() => onClickPage((page - PC_PAGINATION_SIZE < 1) ? 1 : page - PC_PAGINATION_SIZE)}>
      이전
    </button>
  )

  const mPrevTag = (
    <button className='btn_prev' onClick={() => onClickPage((page - MOBILE_PAGINATION_SIZE < 1) ? 1 : page - MOBILE_PAGINATION_SIZE)}>
      이전
    </button>
  )

  // const pageTag = (p, currentPage) => <button className={'btn_page' + p === currentPage ? 'active' : ''}>{p}</button>

  const pcNextTag = (
    <button className='btn_next' onClick={() => onClickPage((page + PC_PAGINATION_SIZE > totalPage) ? totalPage : page + PC_PAGINATION_SIZE)}>
      다음
    </button>
  )

  const mNextTag = (
    <button className='btn_next' onClick={() => onClickPage((page + MOBILE_PAGINATION_SIZE > totalPage) ? totalPage : page + MOBILE_PAGINATION_SIZE)}>
      다음
    </button >
  )

  const lastTag = (
    <button className='btn_last' onClick={() => onClickPage(totalPage)}>
      마지막
    </button>
  )

  // const history = useHistory()

  const pcStartPage = Math.ceil((page / PC_PAGINATION_SIZE) - 1) * PC_PAGINATION_SIZE + 1

  const pcLastPage = totalPage < page + PC_PAGINATION_SIZE ? totalPage : pcStartPage + PC_PAGINATION_SIZE - 1

  const mStartPage = Math.ceil((page / MOBILE_PAGINATION_SIZE) - 1) * MOBILE_PAGINATION_SIZE + 1

  const mLastPage = totalPage < page + MOBILE_PAGINATION_SIZE ? totalPage : mStartPage + MOBILE_PAGINATION_SIZE - 1

  return (
    <div className='page_btn_wrap'>
      <div className='page_btn_row pc_row'>
        {page > 1 ? firstTag : null}
        {page > PC_PAGINATION_SIZE ? pcPrevTag : null}
        {[...Array(pcLastPage - pcStartPage + 1).keys()].map(p => (
          <button
            key={`${p}`}
            className={'btn_page' + (p + pcStartPage === page ? ' active' : '')}
            onClick={() => onClickPage(p + pcStartPage)}
          >
            {p + pcStartPage}
          </button>
        ))}
        {Math.ceil(totalPage / 10) * 10 - page > 10 ? pcNextTag : null}
        {page < totalPage ? lastTag : null}
      </div>
      <div className='page_btn_row mobile_row'>
        {page > 1 ? firstTag : null}
        {page > MOBILE_PAGINATION_SIZE ? mPrevTag : null}
        {[...Array(mLastPage - mStartPage + 1).keys()].map(p => (
          <button
            key={`${p}`}
            className={'btn_page' + (p + mStartPage === page ? ' active' : '')}
            onClick={() => onClickPage(p + mStartPage)}
          >
            {p + mStartPage}
          </button>
        ))}
        {Math.ceil(totalPage / 10) * 10 - page > 10 ? mNextTag : null}
        {page < totalPage ? lastTag : null}
      </div>
    </div>
  )
}

export default Pagination
