export const FIND_PW_REQUEST = 'findPw/FIND_PW_REQUEST'
export const FIND_PW_SUCCESS = 'findPw/FIND_PW_SUCCESS'
export const FIND_PW_FAIL = 'findPw/FIND_PW_FAIL'

export const findPwRequest = (userCert) => ({ type: FIND_PW_REQUEST, userCert })
export const findPwSuccess = () => ({ type: FIND_PW_SUCCESS })
export const findPwFail = () => ({ type: FIND_PW_FAIL })

const initialState = {
  isRequesting: false,
  isReqeusted: false
}

const findPw = (state = initialState, action) => {

  switch (action.type) {

    case FIND_PW_REQUEST:
      return {
        isRequesting: true,
        isReqeusted: false
      }
    case FIND_PW_SUCCESS:
      return {
        isRequesting: false,
        isReqeusted: true
      }
    case FIND_PW_FAIL:
      return {
        isRequesting: false,
        isReqeusted: false
      }
    default:
      return state

  }
}

export default findPw
