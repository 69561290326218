import React from 'react'
import ContentTemplate from '../../components/common/ContentTemplate'
import SubContentTemplate from '../../components/common/SubContentTemplate'
import PageHeaderContainer from '../../containers/common/PageHeaderContainer'
import PageTitleTemplate from '../../components/common/PageTitleTemplate'
import BreadcrumbContainer from '../../containers/common/BreadcrumbContainer'
import PageTemplate from '../../components/common/PageTemplate'
import { WIZPARK_IKSAN } from '../../constants/page'
import IksanStadium from '../../components/wizpark/IksanStadium'

const IksanStadiumPage = () => {
  return (
    <PageTemplate className="park">
      <PageHeaderContainer title="익산야구장" subtitle=" kt wiz의 둥지 '익산야구장'을 소개합니다." image={require('../../assets/img/park/sub_tit_park.png')} />
      <ContentTemplate>
        <PageTitleTemplate>
          <BreadcrumbContainer page={WIZPARK_IKSAN} />
        </PageTitleTemplate>
        <SubContentTemplate className="park iksan">
          <IksanStadium />
        </SubContentTemplate>
      </ContentTemplate>
    </PageTemplate>
  )
}

export default IksanStadiumPage
