import React, { useState } from "react";
import Link from "./common/Link";
import format from "date-fns/format";
import MainTopSlide from "./main/MainTopSlide";
import GameScheduleSlide from "./main/GameScheduleSlide";
import HotIssueSlide from "./main/HotIssueSlide";
import GallerySlide from "./main/GallerySlide";
import { UserAgent } from "react-useragent";
import { useCookies } from "react-cookie";

const Home = ({ autoscreenStatus, recentGames, highlightList, teamRanking, galleryList, hotIssueList, gameVideo }) => {
  const [cookies, setCookie] = useCookies(["popup_today"]);

  // console.log(autoscreenStatus)

  //const isPrePage = process.env.REACT_APP_IS_PREPAIRING_PAGE
  const isPrePage = "N";

  const closePopup = () => {
    //setCookie("popup", "show", { path: "/", maxAge: 24 * 60 * 60 * 1000 });
    setCookie("popup_today", "show", { path: "/", maxAge: 24 * 60 * 60 });
  };

  const thisDate = new Date();
  const thisYear = thisDate.getFullYear();

  const videoLink = autoscreenStatus && autoscreenStatus.autoScreenStatus[3] === "1" ? "https://sports.news.naver.com/externalTv/index.nhn?gameId=" + (recentGames && recentGames.current.gmkey) + thisYear + "&wmode=window&autoPlay=Y&useP2p=N&showCv=N" : gameVideo && gameVideo.video.videoLink;

  const SeasonArea = () => {
    return (
      <section className="section section2">
        <div className="container">
          {/* game_schedule */}
          <div className="game_schedule">
            <div className="schedule_wrap clearfix">
              <div className="content_wrap float_l position_maker">
                <h3 className="section_tit m_invisible">GAME SCHEDULE</h3>
                <GameScheduleSlide recentGames={recentGames} />
              </div>
              {/* //.content_wrap */}

              <UserAgent>
                {({ ua }) => {
                  return !ua.mobile ? (
                    <div className="content_wrap float_r spotv m_invisible">
                      <div className="video_container">
                        <div className="video_wrapper">
                          <div className="video_wrap">
                            <iframe title="main_video" src={videoLink} frameBorder="no" scrolling="no" marginWidth="0" marginHeight="0" width="100%" height="100%" allow="autoplay" allowFullScreen>
                              <p>iframe을 지원하지 않는 브라우저입니다.</p>
                            </iframe>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  );
                }}
              </UserAgent>
            </div>
          </div>
          {/* //.game_schedule */}

          {/* banner_top */}
          <div className="banner_t">
            <div className="row clearfix">
              <div className="banner_wrap col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div className="banner banner1">
                  <div className="img_wrap teamrank">
                    <Link to="/game/regular/ranking/team">
                      <strong className="rank">{teamRanking && teamRanking.rankName}</strong>
                      <dl>
                        <dt>팀 순위</dt>
                        <dd>
                          <strong>{teamRanking && teamRanking.wldName}</strong>
                          <span>
                            총 {teamRanking && teamRanking.game}경기 승률 {teamRanking && teamRanking.wra}
                          </span>
                        </dd>
                      </dl>
                    </Link>
                  </div>
                </div>
              </div>
              {/* /.banner_wrap */}
              <div className="banner_wrap col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div className="banner banner2">
                  <div className="img_wrap">
                    <Link to="/wizpark/parking" title="사전주차예약 페이지 이동">
                      <img src={require("../assets/img/main/sec2_banner_parking.png")} alt="사전주차예약 배너" />
                    </Link>
                  </div>
                </div>
              </div>
              {/* //.banner_wrap */}
            </div>
            {/* //.row */}
          </div>
          {/* //.banner_t */}
        </div>
        {/* //.container */}
      </section>
    );
  };

  return (
    <div className="content">
      {/* main_slide */}
      <section className="section main_slide">
        <MainTopSlide />
      </section>
      {/* //.main_slide */}

      {autoscreenStatus && autoscreenStatus.autoScreenStatus[1] === "1" && <SeasonArea />}

      {/* hot_issue */}
      <section className="section hot_issue">
        <div className="container">
          <div className="section_tit_wrap clearfix">
            <h3 className="section_tit float_l">HOT ISSUE</h3>
            <Link to="/media/wiznews" className="more float_r">
              더보기
            </Link>
          </div>
          {/* //.section_tit_wrap */}
          <div className="position_maker">
            <div className="card_wrap clearfix">
              <HotIssueSlide hotIssueList={hotIssueList} />
            </div>
            {/* //.card_wrap */}
          </div>
          {/* //.row */}
        </div>
        {/* //.container */}
      </section>
      {/* //.hot_issue */}

      {/* video */}
      <section className="section video">
        <div className="container">
          <div className="section_tit_wrap clearfix">
            <h3 className="section_tit float_l">VIDEO</h3>
            <Link className="more float_r" to="/media/highlight">
              더보기
            </Link>
          </div>
          {/* //.section_tit_wrap */}
          <div className="clearfix">
            {/* 왼쪽 영역(게임 최신 영상) */}
            <div className="recent_video float_l">
              <div className="video_container swiper-slide">
                <div className="video_wrapper">
                  <div className="video_wrap">
                    <iframe title="highlight" src={highlightList && highlightList[0].videoLink} frameBorder="no" scrolling="no" marginWidth="0" marginHeight="0" width="100%" height="100%" allow="autoplay" allowFullScreen>
                      <p>iframe을 지원하지 않는 브라우저입니다.</p>
                    </iframe>
                  </div>
                  {/* //.video_wrap */}
                </div>
                {/* //.video_wrapper */}
              </div>
              {/* //.video_container */}
              <div className="view_icon_wrap clearfix">
                <ul className="clearfix">
                  {/* <li className="view_icon position">
                    <Link to="/media/live/position"><span>포지션뷰</span></Link>
                  </li>
                  <li className="view_icon matrix">
                    <Link to="/media/live/matrix"><span>매트릭스뷰</span></Link>
                  </li> */}
                  <li className="view_icon pitching">
                    <Link to="/media/live/pts">
                      <span>피칭분석</span>
                    </Link>
                  </li>
                  <li className="view_icon motion">
                    <Link to="/media/live/fts">
                      <span>모션트래킹</span>
                    </Link>
                  </li>
                </ul>
              </div>
              {/* //.icon_wrap */}
            </div>
            {/* //.recent_video */}

            {/* 오른쪽 영역(영상 목록) */}
            <div className="video_list_wrap float_r m_invisible">
              <ul>
                {/* 영상 목록1 */}
                <li>
                  <Link to={"/media/highlight/" + (highlightList && highlightList[0].artcSeq)} className="clearfix">
                    <div className="thumb_wrap float_l">
                      <div className="thumb">
                        <div className="img_wrap">
                          <img src={highlightList && highlightList[0].imgFilePath} alt={highlightList && highlightList[0].artcTitle} />
                        </div>
                      </div>
                    </div>
                    <div className="txt_container">
                      <div className="txt_wrap float_l">
                        <span className="category">하이라이트</span>
                        <h4 className="content_tit">{highlightList && highlightList[0].artcTitle}</h4>
                        <span className="date">등록 일자 : {highlightList && format(new Date(highlightList[0].regDttm), "yyyy년 MM월 dd일")}</span>
                      </div>
                    </div>
                  </Link>
                </li>

                {/* 영상 목록2 */}
                <li>
                  <Link to={"/media/highlight/" + (highlightList && highlightList[1].artcSeq)} className="clearfix">
                    <div className="thumb_wrap float_l">
                      <div className="thumb">
                        <div className="img_wrap">
                          <img src={highlightList && highlightList[1].imgFilePath} alt={highlightList && highlightList[1].artcTitle} />
                        </div>
                      </div>
                    </div>
                    <div className="txt_container">
                      <div className="txt_wrap float_l">
                        <span className="category">하이라이트</span>
                        <h4 className="content_tit">{highlightList && highlightList[1].artcTitle}</h4>
                        <span className="date">등록 일자 : {highlightList && format(new Date(highlightList[1].regDttm), "yyyy년 MM월 dd일")}</span>
                      </div>
                    </div>
                  </Link>
                </li>

                {/* 영상 목록3 */}
                <li>
                  <Link to={"/media/highlight/" + (highlightList && highlightList[2].artcSeq)} className="clearfix">
                    <div className="thumb_wrap float_l">
                      <div className="thumb">
                        <div className="img_wrap">
                          <img src={highlightList && highlightList[2].imgFilePath} alt={highlightList && highlightList[2].artcTitle} />
                        </div>
                      </div>
                    </div>
                    <div className="txt_container">
                      <div className="txt_wrap float_l">
                        <span className="category">하이라이트</span>
                        <h4 className="content_tit">{highlightList && highlightList[2].artcTitle}</h4>
                        <span className="date">등록 일자 : {highlightList && format(new Date(highlightList[2].regDttm), "yyyy년 MM월 dd일")}</span>
                      </div>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            {/* //.video_list_wrap */}
          </div>
        </div>
        {/* //.container */}
      </section>
      {/* //.video */}

      {/* gallery */}
      <section className="section gallery">
        <div className="container">
          <div className="section_tit_wrap clearfix">
            <h3 className="section_tit float_l">GALLERY</h3>
            <Link className="more float_r" to="/media/photo">
              더보기
            </Link>
          </div>
          {/* //.section_tit_wrap */}
          <div className="position_maker">
            <div className="card_wrap clearfix">
              <GallerySlide galleryList={galleryList} />
            </div>
            {/* //.card_wrap */}
          </div>
          {/* //.row */}
        </div>
        {/* //.container */}
      </section>
      {/* //.gallery */}

      {/* banner_bt_sns */}
      <section className="section banner_bt_sns">
        <div className="container">
          {/* section6_1(배너) */}
          <div className="banner_bt">
            <div className="row clearfix">
              <div className="banner_wrap col-lg-6 col-md-6 col-sm-6 col-xs-12 position_maker">
                <div className="banner banner1">
                  <div className="img_wrap">
                    {isPrePage === "N" && (
                      <Link to="/player/pitcher/detail?pcode=50030" title="이달의 선수 보기">
                        <img src={require("../assets/img/main/sec6_banner_player_2008.png")} alt="이달의 선수 배너" />
                      </Link>
                    )}
                    {isPrePage === "Y" && (
                      <Link onClick={() => alert("준비중입니다.")} title="이달의 선수 보기">
                        <img src={require("../assets/img/main/sec6_banner_player_2008.png")} alt="이달의 선수 배너" />
                      </Link>
                    )}
                  </div>
                  {/* //.img_wrap */}
                </div>
                {/* //.banner */}
              </div>
              {/* //.banner_wrap */}
              <div className="banner_wrap col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div className="banner banner2">
                  <div className="img_wrap">
                    <Link to="https://www.ktwizstore.co.kr/" target=" _blank" isExt={true} title="위즈몰 연결">
                      <img src={require("../assets/img/main/sec6_banner_wizmall.png")} alt="위즈몰 배너" />
                    </Link>
                  </div>
                  {/* //.img_wrap */}
                </div>
                {/* //.banner */}
              </div>
              {/* //.banner_wrap */}
            </div>
            {/* //.row */}
          </div>
          {/* //.banner_bt */}

          {/* modify 20191012 >> */}
          {/* section6_2(kt wiz sns) */}
          {/*  <div className="ktwizwith">
            <h3 className="section_tit">kt wiz WITH</h3>
            <div className="sns_wrap">
              <ul className="clearfix">
                <li className="sns ktwiz pc_invisible">
                  <a href="">
                    <span className="m_invisible">kt wiz</span>
                  </a>
                </li>
                <li className="sns facebook">
                  <a href="https://www.facebook.com/ktwiz/" target="_blank">
                    <span className="m_invisible">Facebook</span>
                  </a>
                </li>
                <li className="sns instagram">
                  <a href="https://www.instagram.com/ktwiz.pr/" target="_blank">
                    <span className="m_invisible">Instagram</span>
                  </a>
                </li>
                <li className="sns youtube">
                  <a href="https://www.youtube.com/c/ktwiztv" target="_blank">
                    <span className="m_invisible">Youtube</span>
                  </a>
                </li>
                <li className="sns navertv">
                  <a href="https://tv.naver.com/ktwiz" target="_blank">
                    <span className="m_invisible">Naver tv</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>  */}
        </div>
      </section>
      <div className="content_pop_wrap leave_pop" style={{ display: cookies.popup_today == null ? "none" : "none" }}>
        <div className="content_pop_container">
          <div className="pop_overlay"></div>
          <div className="content_pop popup" style={{ padding: 0 }}>
            <img src={require("../assets/img/popup/popup_200814.png")} alt="" />
            <div className="close_wrap">
              <Link onClick={() => closePopup()}>오늘 하루 열지 않기</Link> &nbsp;
              <input type="checkbox" onClick={() => closePopup()} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
