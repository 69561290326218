import { put, all, call, fork, takeEvery } from "redux-saga/effects";
import { getMyInfo, getDonMileInfo, getProfile, updateUserInfo, /*updatePassword,*/ withdraw, checkPassword, getMyInfoTerms, updateSelectedTerms } from "../../api/mypage/myinfo";
import * as actions from "../../modules/mypage/myinfo";
import * as dialogActions from "../../modules/common/dialog";
import { logoutRequest } from "../../modules/auth/login";
import { getLoginUser } from "../../modules/utils/auth";
// import { push } from 'connected-react-router'

function* myInfo(action) {
  const infoResponse = yield call(getMyInfo);

  const donMileInfoResponse = yield call(getDonMileInfo, infoResponse.data.data.user.memberId);

  const profileResponse = yield call(getProfile, infoResponse.data.data.user.memberId);

  const termsResponse = yield call(getMyInfoTerms);

  infoResponse.data.data.user.memberDivName = profileResponse.data.data.user.memberDiv;

  yield put({
    type: actions.MY_INFO_SUCCESS,
    info: { user: infoResponse.data.data.user, donation: donMileInfoResponse.data.data.donMileInfo, terms: termsResponse.data.data.list },
  });
}

function* watchMyInfoRequest() {
  yield takeEvery(actions.MY_INFO_REQUEST, myInfo);
}

function* updateMyInfo(action) {
  const response = yield call(updateUserInfo, action.user);

  if (response.data.status !== 0) {
    yield put({ type: actions.MY_INFO_UPDATE_SUCCESS });
    yield put(dialogActions.alertOpen(response.data.message));
  } else {
    yield put({ type: actions.MY_INFO_UPDATE_SUCCESS });
    yield put(dialogActions.alertOpen("회원정보가 수정되었습니다."));
    yield put({ type: actions.MY_INFO_REQUEST });
  }
}

function* watchMyInfoUpdateRequest() {
  yield takeEvery(actions.MY_INFO_UPDATE_REQUEST, updateMyInfo);
}

function* requestWithdraw(action) {
  const response = yield call(withdraw, action.withdraw);
  if (response.data.status !== 0) {
    yield put(dialogActions.alertOpen(response.data.message));
  } else {
    yield put({ type: actions.WITHDRAW_SUCCESS });
    yield put(dialogActions.alertOpen("탈퇴처리가 완료되었습니다."));
    yield put(logoutRequest(getLoginUser()));
  }
}

function* watchWithdrawRequest() {
  yield takeEvery(actions.WITHDRAW_REQUEST, requestWithdraw);
}

function* confirmPassword(action) {
  const response = yield call(checkPassword, action.user);

  if (response.data.status !== 0) {
    yield call(alert, response.data.message);
    yield put({ type: actions.PASSWORD_CONFIRM_FAILURE });
  } else {
    yield put({ type: actions.PASSWORD_CONFIRM_SUCCESS, memberPwConfirm: true });
  }
}

function* watchConfirmPassword() {
  yield takeEvery(actions.PASSWORD_CONFIRM_REQUEST, confirmPassword);
}

function* updateTerms(action) {
  if (Object.keys(action.terms).length > 0) {
    const {
      terms: { checkAgreeMarket, checkAgreeProvide },
    } = action;

    const tempTermsList = [checkAgreeProvide, checkAgreeMarket];

    const response = yield call(updateSelectedTerms, tempTermsList);

    if (response.data.status !== 0) {
      yield call(alert, response.data.message);
      yield put({ type: actions.UPDATE_TERMS_FALURE });
    } else {
      yield put({ type: actions.UPDATE_TERMS_SUCCESS });
      window.location.reload();
    }
  }
}

function* watchUpdateTerms() {
  yield takeEvery(actions.UPDATE_TERMS_REQUEST, updateTerms);
}

export default function* myinfoSaga() {
  yield all([fork(watchMyInfoRequest), fork(watchMyInfoUpdateRequest), fork(watchWithdrawRequest), fork(watchConfirmPassword), fork(watchUpdateTerms)]);
}
