import React from 'react'
import { useDispatch } from 'react-redux'
import * as dialogActions from '../../modules/common/dialog'

const Dialog = ({ dialog }) => {

  const dispatch = useDispatch()

  const onAlertPositiveButton = () => { dispatch(dialogActions.alertPositive()) }
  const onConfirmPositiveButton = () => { dispatch(dialogActions.confirmPositive()) }
  const onConfirmNegativeButton = () => { dispatch(dialogActions.confirmNegative()) }

  return (
    <div className={dialog.isAlert || dialog.isConfirm ? 'content_pop_wrap active' : 'content_pop_wrap'}>
      <div className="content_pop_container">
        <div className="pop_overlay"></div>
        <div className="content_pop notice" style={dialog.style && dialog.style}>
          <div className="notice_block" style={{ height: '90%' }}>
            {
              !dialog.isIframe &&
              <p>
                {
                  dialog.message.split('\n').map((v, i) => (
                    <React.Fragment key={i}>
                      {v}<br />
                    </React.Fragment>
                  ))
                }
              </p>
            }

            {
              dialog.isIframe &&
              <>
                <div dangerouslySetInnerHTML={{ __html: dialog.message }} style={{ height: '100%' }}></div>
              </>
            }

          </div>
          <div className="btn_wrap clearfix">
            {
              dialog.isAlert &&
              <button className="btn_confirm" onClick={onAlertPositiveButton}>{dialog.positiveButton}</button>
            }
            {
              dialog.isConfirm &&
              <>
                <button className="btn_confirm" onClick={onConfirmPositiveButton}>{dialog.positiveButton}</button>
                <button className="btn_cancel" onClick={onConfirmNegativeButton}>{dialog.negativeButton}</button>
              </>
            }

          </div>
        </div>
      </div>
    </div>
  )
}

export default Dialog
