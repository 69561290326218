import React, { useState } from 'react'
import ContentTemplate from '../../components/common/ContentTemplate'
import SubContentTemplate from '../../components/common/SubContentTemplate'
import PageHeaderContainer from '../../containers/common/PageHeaderContainer'
import PageTitleTemplate from '../../components/common/PageTitleTemplate'
import BreadcrumbContainer from '../../containers/common/BreadcrumbContainer'
import PageTemplate from '../../components/common/PageTemplate'
import { REGULAR_LEAGUE_RANKING_PITCHER, REGULAR_LEAGUE_TAB, REGULAR_LEAGUE_RANKING_TAB } from '../../constants/page'
import RegularLeaguePitcherRankingContainer from '../../containers/game/RegularLeaguePitcherRankingContainer'
import SecondContentTemplate from '../../components/common/SecondContentTemplate'
import RegularLeaguePitcherRankingTableContainer from '../../containers/game/RegularLeaguePitcherRankingTableContainer'


const RegularLeaguePitcherRankingPage = ({ match }) => {

  const currentPage = REGULAR_LEAGUE_RANKING_PITCHER

  REGULAR_LEAGUE_RANKING_TAB.forEach(tab => {
    tab.isActive = false
    if (match.path === tab.path) {
      tab.isActive = true
    }
  })

  const [year, setYear] = useState(null)

  return (
    <PageTemplate className="game">
      <PageHeaderContainer title="정규 리그" subtitle="kt wiz의 투수 기록을 알려드립니다." image={require('../../assets/img/game/sub_tit_game.png')} forwardTabs={REGULAR_LEAGUE_TAB} currentPath={currentPage.parent.path} />
      <ContentTemplate className="game_rank_record">
        <PageTitleTemplate>
          <BreadcrumbContainer page={currentPage} />
        </PageTitleTemplate>
        <SubContentTemplate tabs={REGULAR_LEAGUE_RANKING_TAB}>
          <RegularLeaguePitcherRankingContainer match={match} year={year} />
        </SubContentTemplate>
      </ContentTemplate>
      <SecondContentTemplate className='game'>
        <RegularLeaguePitcherRankingTableContainer year={year} onChangeYear={setYear} />
      </SecondContentTemplate>
    </PageTemplate>
  )
}

export default RegularLeaguePitcherRankingPage
