import { getYear } from 'date-fns';
import React from 'react'

const BatterRanking = ({ hraTop3InKt, hrTop3InKt, hraTop5 }) => {
  const date = new Date();
  const recentYear = getYear(date);

  return (
    <div className="rank_record_wrap catcher_record" >
      <div className="pc_mode" >
        <div className="top_rank_wrap">
          <article>
            <div className="top3_rank_list">
              <ul>
                <li className="first">
                  <div className="img_box"><img src={hraTop3InKt && hraTop3InKt[0].playerPrvwImg} alt={hraTop3InKt && hraTop3InKt[0].playerName} /></div>
                  <div className="ribbon">타율<br />TOP3</div>
                  <div className="rank">
                    <ul>
                      {hraTop3InKt && hraTop3InKt.map((player, index) => <li class={index === 0 ? 'top' : ''}>{index + 1}. {player.playerName} ({player.hra})</li>)}
                    </ul>
                  </div>
                </li>
                <li>
                  <div className="img_box"><img src={hrTop3InKt && hrTop3InKt[0].playerPrvwImg} alt={hrTop3InKt && hrTop3InKt[0].playerName} /></div>
                  <div className="ribbon">홈런<br />TOP3</div>
                  <div className="rank">
                    <ul>
                      {hrTop3InKt && hrTop3InKt.map((player, index) => <li class={index === 0 ? 'top' : ''}>{index + 1}. {player.playerName} ({player.hr})</li>)}
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
            <div className="top5_rank_list">
              <h5>전체 타자 타율 <span>TOP5</span></h5>
              <ul className="rank">
                {hraTop5 && hraTop5.map((player, index) => <li class={player.teamName === 'KT' ? 'kt' : ''}><span class="name">{index + 1}&nbsp;&nbsp;{player.playerName} ({player.teamName})</span><span class="record">{player.hra}</span></li>)}
              </ul>
              <span className="notice">※ {recentYear} 정규리그 시즌</span>
            </div>
          </article>
        </div>
      </div>
    </div>
  )
}

export default BatterRanking
