import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { sponsorListRequest } from '../../modules/ktwiz/sponsor';
import Sponsor from '../../components/ktwiz/sponsor/Sponsor';

const SponsorContainer = () => {

  const dispatch = useDispatch()

  const sponsor = useSelector(state => state.sponsor)

  useEffect(() => {
    dispatch(sponsorListRequest())
  }, [])

  return (
    sponsor.sponsors != null ? <Sponsor sponsors={sponsor.sponsors} /> : ''
  )
}

export default SponsorContainer
