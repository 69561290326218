import React from 'react';
import { useLocation } from 'react-router-dom';
import Link from '../../common/Link';
import { format } from 'date-fns';
// import qs from 'query-string';
import * as Page from '../../../constants/page'

const ArticleListItem = ({ article, index }) => {
  const { contents, title, seq, imgFilePath, registerAt, /*updateAt,*/ viewCnt } = article;
  const location = useLocation();
  const search = location.search;

  console.log('ArticleListItem: ', { article, search });

  const detailLink = `${location.pathname}/${seq}${search}`;

  const imgNoneClass = ((location.pathname === Page.WIZ_NEWS_PAGE.path || location.pathname === Page.WIZ_PRESS_PAGE.path) && !imgFilePath);

  return (
    <li className={imgNoneClass ? 'img_none' : ''}>
      <article className='article'>
        <Link to={`${detailLink}`}>
          {imgFilePath && (
            <div className='tit_img'>
              <img src={imgFilePath} alt={title} onError={(e) => {
                e.target.onError = null
                e.target.src = require('../../../assets/img/media/no-img-media.png')
              }} />
            </div>
          )}
          <div className='tit_detail'>
            <div>
              <dl>
                <dt>{title}</dt>
                <dd dangerouslySetInnerHTML={{ __html: contents && '<p>' + contents + '</p>' }}></dd>
              </dl>
              <div className='tit_info'>
                <ul>
                  <li>
                    <span className='date'>{format(registerAt, 'yyyy.MM.dd')}</span>
                    <span className='icon_calendar'></span>
                  </li>
                  <li className='last'>
                    <span className='count'>{viewCnt}</span>
                    <span className='icon_time'></span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Link>
      </article>
    </li>
  );
};

export default ArticleListItem;
