import { put, take, takeEvery, all, call, fork } from 'redux-saga/effects'
import { push } from 'connected-react-router'
// api
import * as findIdApi from '../../api/auth/findId'
// actions
import * as findIdActions from '../../modules/auth/findId'
import * as loadingActions from '../../modules/common/loading'
import * as certActions from '../../modules/auth/cert'
import * as dialogActions from '../../modules/common/dialog'

function* findId({ userCert }) {

  // 1. loading OPEN
  yield put(loadingActions.setLoading(true))

  // 2. 인증 확인 요청
  yield put(certActions.certConfirmRequest(userCert, false, true))
  const certConfirmResult = yield take([certActions.CERT_CONFIRM_SUCCESS, certActions.CERT_CONFIRM_FAIL])
  if (certConfirmResult.type === certActions.CERT_CONFIRM_SUCCESS) {

    // 3. 아이디 조회
    const findIdApiResponse = yield call(findIdApi.findId, userCert)

    if (findIdApiResponse.listCnt > 0) {

      let findIds = ""
      const maxFindIdCnt = 5

      findIdApiResponse.list.map((v, i, arr) => {
        const simpleIdArr = [...v.simpleId]
        findIds = `${findIds}${simpleIdArr.map((x, index) => index > 3 ? x.replace(x, "*") : x).join('')}${(i === arr.length - 1 || i === maxFindIdCnt - 1) ? "" : ", "}`
      })

      yield put(dialogActions.confirmOpen(`회원님의 아이디는 ${findIds} 입니다.`, '로그인하기', '취소'))

      // const simpleIdArr = [...findIdApiResponse.list[0].simpleId]
      // yield put(dialogActions.confirmOpen(`회원님의 아이디는 ${simpleIdArr.map((x, index) => index > 3 ? x.replace(x, "*") : x).join('')} 입니다.`, '로그인하기', '취소'))

    } else {
      yield put(dialogActions.alertOpen(`아이디가 존재하지 않습니다.`))
    }

    const dialogResult = yield take([dialogActions.CONFIRM_POSITIVE, dialogActions.CONFIRM_NEGATIVE, dialogActions.ALERT_POSITIVE])

    if (dialogResult.type === dialogActions.CONFIRM_POSITIVE) {
      yield put(findIdActions.findIdSuccess())
      yield put(push('/login'))
    }

    if (dialogResult.type === dialogActions.ALERT_POSITIVE) {
      yield put(findIdActions.findIdFail())
    }

  } else if (certConfirmResult.type === certActions.CERT_CONFIRM_FAIL) {
    yield put(findIdActions.findIdFail())
  }

  // 4. loading CLOSE
  yield put(loadingActions.setLoading(false))


}

function* findIdWatch() {
  yield takeEvery(findIdActions.FIND_ID_REQUEST, findId)
}

export default function* findIdSaga() {
  yield all([
    fork(findIdWatch),
  ])
}
