import React from "react";
import Link from "../../common/Link";
// import SponsorRegister from "./SponsorRegister";

const Sponsor = ({ sponsors }) => {
  // const [showRegister, setShowRegister] = useState(false);
  const onClickShowRegister = (v) => {
    window.open("https://b2b.ktwiz.co.kr/about/intro", "_blank");

    // if (v)
    //   document.getElementsByTagName('body')[0].style.overflowY = 'hidden'
    // if (!v)
    //   document.getElementsByTagName('body')[0].style.overflowY = 'auto'
    // setShowRegister(v)
  };

  return (
    <>
      <div className="buttons pc">
        <Link className="btn-sponsor" onClick={() => onClickShowRegister(true)}>
          kt wiz 스폰서 신청 및 문의
        </Link>
      </div>
      <div className="sponsor-list">
        {sponsors.map((sponsor) => (
          <div className="item" key={sponsor.artcSeq}>
            <div className="inner-wrap">
              <div className="img-wrap">
                <Link to={sponsor.link} isExt={true}>
                  <img
                    src={sponsor.imgFilePath}
                    alt={sponsor.artcTitle}
                    onError={(e) => {
                      e.target.src = null;
                      e.target.src = require("../../../assets/img/ktwiz/no_img_ktwiz_sponsor.png");
                    }}
                  />
                </Link>
              </div>
              <div className="txt">{sponsor.artcTitle}</div>
            </div>
          </div>
        ))}
      </div>

      {/* 신청 및 문의 팝업 */}
      {/* {showRegister && (
        <SponsorRegister onClickShowRegister={onClickShowRegister} />
      )} */}
    </>
  );
};

export default Sponsor;
