import React, { useState } from 'react'
import SongPcPlayerList from './SongPcPlayerList'
import SongPcPlayerPopup from './SongPcPlayerPopup'

const SongPcPlayer = ({ playerSongList, isPlaying, onIsPlaying }) => {

  const [playerPopupData, setPlayerPopupData] = useState(null)
  const [isPlayerPopup, setIsPlayerPopup] = useState(false)

  const onClickIsPlayerPopup = (isPopup, playerData) => {
    setIsPlayerPopup(isPopup)
    setPlayerPopupData(playerData)
  }

  return (
    <>
      {/* song_lists pc_mode */}
      <div className="song_lists pc_mode">

        <SongPcPlayerList playerSongList={playerSongList} onClickIsPlayerPopup={onClickIsPlayerPopup} />

        {
          isPlayerPopup &&
          <SongPcPlayerPopup playerPopupData={playerPopupData} onClickIsPlayerPopup={onClickIsPlayerPopup} isPlaying={isPlaying} onIsPlaying={onIsPlaying} />
        }

      </div>
      {/* // song_lists pc_mode */}
    </>
  )
}

export default SongPcPlayer
