import React from 'react'
import Link from '../../../components/common/Link'

const CheerMobileListItem = ({ cheerleaderList }) => {
  const items = (
    cheerleaderList &&
    cheerleaderList.map((v, i) => (
      <li key={i}>
        <article className="article">
          <Link to={`/player/cheer/detail?leaderSeq=${v.leaderSeq}`}>
            <div className="tit_img">
              <img src={`${v.listImgPath}`.replace('http://localhost:8080', 'http://test.ktwiz.co.kr:17400')} alt="응원단장 김주일" />
            </div>
            <div className="tit_detail">
              <dl>
                <dt>{v.leaderPosition}</dt>
                <dd>{v.leaderName}</dd>
              </dl>
            </div>
          </Link>
        </article>
      </li>
    )))

  return (
    <>

      {items}

    </>
  )
}

export default CheerMobileListItem
