import PageTemplate from "components/common/PageTemplate";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { presentLog, thirdPresentLog } from "./privacyPolicyText";

const ViewtextPage = ({ setTextData }) => {
  const history = useHistory();
  const location = useLocation();

  // const backToJoin = () => {
  //   history.push(`/join`);
  // };

  const goToIndiLog = () => {
    setTextData("menu");
  };

  return (
    <PageTemplate>
      {location.state === 'personerLog' && (
        <h1 className="indi_title">개인정보 수집·이용 동의</h1>
      )}
      {location.state === "personerLog" && presentLog.map((item, idx) => <p key={idx}>{item}</p>)}
      {location.state === "personerLog" && (
        <button className="btn_textlog" onClick={goToIndiLog}>
          변경전 개인정보 수집·이용 동의 보기 &gt;
        </button>
      )}
      {location.state === '3rdPersonerLog' && (
        <h1 className="indi_title">개인정보 제 3자 제공에 대한 안내</h1>
      )}
      {location.state === "3rdPersonerLog" && thirdPresentLog.map((item, idx) => <p key={idx}>{item}</p>)}
      {/* <button className='btn_backjoin' onClick={backToJoin}>돌아가기</button> */}
    </PageTemplate>
  );
};

export default ViewtextPage;
