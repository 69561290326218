import React from "react";
// import { Swiper, Navigation } from "swiper/js/swiper.esm";
// import CheerPcGalleryTop from "./CheerPcGalleryTop";
// import CheerPcGalleryThumbnail from "./CheerPcGalleryThumbnail";
import Swiper from 'react-id-swiper';

const CheerPc = ({ cheerleaderList }) => {
  // const natureImageUrls = [
  //   'http://lorempixel.com/600/600/nature/1',
  //   'http://lorempixel.com/600/600/nature/2',
  //   'http://lorempixel.com/600/600/nature/3',
  //   'http://lorempixel.com/600/600/nature/4',
  //   'http://lorempixel.com/600/600/nature/1',
  //   'http://lorempixel.com/600/600/nature/2',
  //   'http://lorempixel.com/600/600/nature/3',
  //   'http://lorempixel.com/600/600/nature/4',
  //   'http://lorempixel.com/600/600/nature/1',
  //   'http://lorempixel.com/600/600/nature/2'
  // ];

  const [topSwiper, updateSwiper] = React.useState(null);
  const [thumbSwiper, updateThumbSwiper] = React.useState(null);
  const [activeIndex, setActiveIndex] = React.useState(0);

  React.useEffect(() => {
    if (topSwiper && thumbSwiper) {
      console.log('Update Thumb');
      topSwiper.thumbs && (topSwiper.thumbs.swiper = thumbSwiper);
      thumbSwiper.on('click', function () {
        console.log('Clicked', { clickedIndex: thumbSwiper.clickedIndex });
        topSwiper.slideTo(thumbSwiper.clickedIndex);
        setActiveIndex(thumbSwiper.clickedIndex);
      });
      topSwiper.on('activeIndexChange', function () {
        setActiveIndex(topSwiper.activeIndex);
      });
    }
  }, [topSwiper, thumbSwiper]);

  const topParams = {
    getSwiper: updateSwiper,
    containerClass: 'swiper-container gallery-top',
    spaceBetween: 10,
    // loop: true,
    // loopedSlides: 5, //looped slides should be the same
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    effect: 'fade',
    speed: 300,
    allowTouchMove: false
    //   getSwiper: swiper => {
    //     swiper.on("activeIndexChange", function () {
    //       setActiveIndex(swiper.realIndex);
    //     });
    //   }
  };

  const thumbParams = {
    getSwiper: updateThumbSwiper,
    containerClass: 'swiper-container gallery-thumbs',
    spaceBetween: 0,
    slidesPerView: 6,
    touchRatio: 0,
    // loop: true,
    freeMode: true,
    // loopedSlides: 5, //looped slides should be the same
    watchSlidesVisibility: true,
    watchSlidesProgress: true
  };

  return (
    <>
      {/* slide list */}
      <div className="slide_list">
        <article className="article">
          <div className="slide_wrap">
            {cheerleaderList &&
              <Swiper {...topParams}>
                {cheerleaderList.map((v, i) => (
                  <div key={i} className="swiper-slide" style={{
                    backgroundImage: `url(${`${v.titleImgPath}`.replace('http://localhost:8080', 'http://test.ktwiz.co.kr:17400')})`
                  }}>

                    <div className="cheer_info_wrap">
                      <dl>
                        <dt>
                          <span className="position">{v.leaderPosition}</span>
                          <span className="kor_name">{v.leaderName}</span>
                          <span className="eng_name">{v.leaderEngName}</span>
                        </dt>
                        <dd>
                          <ul>
                            <li>
                              <dl>
                                <dt>별명</dt>
                                <dd>{v.leaderNickName}</dd>
                              </dl>
                            </li>
                            <li>
                              <dl>
                                <dt>키/형액형</dt>
                                <dd>{v.leaderHeight}cm / {v.leaderBloodGroups}형</dd>
                              </dl>
                            </li>
                            <li>
                              <dl>
                                <dt>취미</dt>
                                <dd>{v.leaderHobby}</dd>
                              </dl>
                            </li>
                            <li>
                              <dl>
                                <dt>좋아하는 선수</dt>
                                <dd>{v.leaderLikePlayer}</dd>
                              </dl>
                            </li>
                            <li>
                              <dl>
                                <dt>좌우명</dt>
                                <dd>{v.leaderMotto}</dd>
                              </dl>
                            </li>
                          </ul>
                        </dd>
                      </dl>
                    </div>

                  </div>
                ))}
              </Swiper>
            }
            {cheerleaderList &&
              <Swiper {...thumbParams}>
                {cheerleaderList.map((v, i) => {
                  return (
                    <div
                      key={i}
                      className="swiper-slide"
                      style={{
                        backgroundImage: `url(${`${i === activeIndex ? v.thumbOnImgPath : v.thumbOffImgPath}`.replace(
                          "http://localhost:8080",
                          "http://test.ktwiz.co.kr:17400"
                        )})`,
                        border: `${i === activeIndex ? '3px solid #e40013' : ''}`
                      }}
                    ></div>
                  );
                })}
              </Swiper>
            }
          </div>
        </article>
      </div>
      {/* slide list */}
    </>
  );




  // const [activeIndex, setActiveIndex] = React.useState(0);

  // const galleryThumbs = {
  //   Swiper,
  //   modules: [Navigation],
  //   containerClass: "gallery-thumbs",
  //   spaceBetween: 0,
  //   centeredSlides: true,
  //   slidesPerView: 6,
  //   // loop: true,
  //   freeMode: true,
  //   // loopedSlides: 6,
  //   navigation: {
  //     nextEl: ".swiper-button-next",
  //     prevEl: ".swiper-button-prev"
  //     // hideOnClick: true
  //   },
  //   // navigationHide: true,
  //   renderPrevButton: () => <></>,
  //   renderNextButton: () => <></>,
  //   watchSlidesVisibility: true,
  //   watchSlidesProgress: true,
  //   allowTouchMove: false,
  //   getSwiper: swiper => {
  //     swiper.on("activeIndexChange", function () {
  //       setActiveIndex(swiper.realIndex);
  //     });
  //   }
  // };

  // const galleryTop = {
  //   Swiper,
  //   modules: [Navigation],
  //   spaceBetween: 10,
  //   // loop: true,
  //   // loopedSlides: 6,
  //   containerClass: "gallery-top",
  //   navigation: {
  //     nextEl: ".swiper-button-next",
  //     prevEl: ".swiper-button-prev"
  //   },
  //   renderPrevButton: () => <></>,
  //   renderNextButton: () => <></>,
  //   // thumbs: {
  //   //   swiper: galleryThumbs
  //   // },
  //   // effect: "fade",
  //   speed: 300,
  //   allowTouchMove: false
  // };

  // const buttonStyle = [
  //   { backgroundImage: "url(" + require("../../../assets/img/player/slide_prev_active.png") + ")" },
  //   { backgroundImage: "url(" + require("../../../assets/img/player/slide_next_active.png") + ")" }
  // ];

  // return (
  //   <>
  //     {/* slide list */}
  //     <div className="slide_list">
  //       <article className="article">
  //         <div className="slide_wrap">
  //           <CheerPcGalleryTop galleryTop={galleryTop} cheerleaderList={cheerleaderList} />
  //           <CheerPcGalleryThumbnail galleryThumbs={galleryThumbs} cheerleaderList={cheerleaderList} activeIndex={activeIndex} />

  //           {/* <div className="swiper-button-prev swiper-button-white" style={buttonStyle[0]}></div>
  //           <div className="swiper-button-next swiper-button-white" style={buttonStyle[1]}></div> */}
  //         </div>
  //       </article>
  //     </div>
  //     {/* slide list */}
  //   </>
  // );
};

export default CheerPc;
