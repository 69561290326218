import React, { useState, useEffect } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import ContentTemplate from '../../components/common/ContentTemplate';
import SubContentTemplate from '../../components/common/SubContentTemplate';
import PageHeaderContainer from '../../containers/common/PageHeaderContainer';
import PageTitleTemplate from '../../components/common/PageTitleTemplate';
import Breadcrumb from '../../components/common/Breadcrumb';
// import ArticleSearch from '../../components/media/board/ArticleSearch';
import PageTemplate from '../../components/common/PageTemplate';
import { WIZ_LIVE_TAB } from '../../constants/page';
import qs from 'query-string';
import LiveVideoContainer from '../../containers/media/LiveVideoContainer';
import LiveArticleSearch from '../../components/media/board/LiveArticleSearch';

const LivePage = ({ match }) => {

  const mediaType = useRouteMatch('/media/live/:mediaType');

  const query = qs.parse(useLocation().search);

  const [currentPage, setCurrentPage] = useState(WIZ_LIVE_TAB[0])

  useEffect(() => {
    setCurrentPage(WIZ_LIVE_TAB.find(page => match.path.startsWith(page.path)))
  }, [mediaType])

  return (
    <PageTemplate className='media'>
      <PageHeaderContainer
        title='live 영상 모음'
        subtitle=' kt wiz의 생생한 영상을 만나보세요. '
        image={require('../../assets/img/media/sub_tit_media.png')}
        forwardTabs={WIZ_LIVE_TAB}
        currentPath={match.path}
      />
      <ContentTemplate className='media_live_video'>
        <PageTitleTemplate>
          <Breadcrumb page={currentPage} />
          <LiveArticleSearch option={query['search.sort']} text={query['mediaName']} startDate={query['search.startDate']} endDate={query['search.endDate']} />
        </PageTitleTemplate>
        <SubContentTemplate>
          <LiveVideoContainer mediaType={mediaType.params.mediaType} />
        </SubContentTemplate>
      </ContentTemplate>
    </PageTemplate>
  );
};

export default LivePage;
