import React, { useState, useEffect } from 'react'
import Link from '../../../components/common/Link'
import JoinStep from './JoinStep'
// import { join } from 'redux-saga/effects'

const JoinStep4 = ({ join, onIdentifyCallback }) => {
  useEffect(() => {
    console.log('addEventListener')
    window.addEventListener('message', onMessage, false)
    return () => {
      console.log('removeEventListener')
      window.removeEventListener('message', onMessage)
    }
  })

  const onMessage = e => {
    console.log(e)
    if (e.data.type === 'IPIN2' || e.data.type === 'MOBILEM') {
      onIdentifyCallback(e.data.type, e.data.data)
    }
  }

  const [identifyType, setIdentifyType] = useState('MOBILEM')

  const openPhonePopup = () => {
    window.open('/api/v2/user/identify/phone/request', 'mobilem', 'top=10, left=10, width=500, height=600, status=no, menubar=no, toolbar=no, resizable=no')
  }

  const openIpinPopup = () => {
    window.open('/api/v2/user/identify/ipin/request', 'ipin2', 'top=10, left=10, width=500, height=600, status=no, menubar=no, toolbar=no, resizable=no')
  }

  const openIdentifyPopup = () => {
    if (identifyType === 'MOBILEM') {
      openPhonePopup()
    } else {
      openIpinPopup()
    }
  }

  return (
    <>
      <JoinStep join={join} />
      <div className='content_box sign_up_step3'>
        {/* 상단 설명 */}
        <div className='m_invisible'>
          <div className='content_desc'>
            <p>
              다음의 인증 방법 중 하나를 선택해
              <br />
              본인 인증을 해주시기 바랍니다.
            </p>
          </div>
          {/* //.content_desc */}

          {/* 인증 버튼 */}
          <div className='link_btn_wrap clearfix'>
            <div className='area area1 float_l'>
              <div className='link_btn link_btn1'>
                {' '}
                {/* modify 20191015 태그 추가 */}
                <Link onClick={() => openPhonePopup()}>
                  <p>
                    휴대폰
                    <br />
                    인증
                  </p>
                </Link>
              </div>
              <div className='link_btn_desc'>
                {' '}
                {/* modify 20191015 태그 추가 */}
              </div>
            </div>
            {/* //.area1 */}
            <div className='area area float_r'>
              <div className='link_btn link_btn2'>
                {' '}
                {/* modify 20191015 태그 추가 */}
                <Link onClick={() => openIpinPopup()}>
                  <p>
                    아이핀
                    <br />
                    인증
                  </p>
                </Link>
              </div>
              <div className='link_btn_desc'>
                {' '}
                {/* modify 20191015 태그 추가 */}
              </div>
            </div>
            {/* //.area2 */}
          </div>
          {/* //.btn_wrap */}
        </div>
        {/* //.m_invisible */}

        {/* 모바일 화면용 */}
        <div className='pc_invisible'>
          {/* 상단 설명 */}
          <div className='content_desc'>
            <span>실명인증 방법을 선택해주세요.</span>
          </div>
          {/* //.content_desc */}

          {/* 인증 라디오 버튼 */}
          <div className='confirm_wrap'>
            <label htmlFor='mobile_confirm' className='mobile_confirm'>
              <input type='radio' id='mobile_confirm' name='name_confirm' value='mobile' checked={identifyType === 'MOBILEM'} onChange={() => setIdentifyType('MOBILEM')} />
              휴대폰(본인 명의)
            </label>
            <label htmlFor='ipin_confirm'>
              <input type='radio' id='ipin_confirm' name='name_confirm' value='ipin' checked={identifyType === 'IPIN2'} onChange={() => setIdentifyType('IPIN2')} />
              아이핀
            </label>
          </div>
          {/* //.confirm_wrap */}

          {/* 하단 설명 */}
          <div className='desc_wrap'>
            <ul>
              <li className='desc1'>본인 명의로 이동 통신사에 가입한 휴대폰으로만 이용 가능합니다.</li>
              <li className='desc1'>소지하고 계신 휴대폰이 본인 명의가 아닌 경우(법인/MVNO 가입자 포함), 아이핀을 선택해 주세요.</li>
            </ul>
            <p className='desc2'>
              [인증 이용문의처]
              <br />
              -NICE 평가정보 : 02-2122-4000
            </p>
          </div>
          {/* //.desc_wrap */}

          {/* 확인 버튼 */}
          <div className='btn_wrap'>
            <button type='submit' className='confirm active' onClick={() => openIdentifyPopup()}>
              확인
            </button>{' '}
            {/* 라디오버튼 디폴트 값이 있어서 버튼 색 있습니다 */} {/* 확인 시 sign_up_step4.html로 이동 */}
          </div>
          {/* //.btn_container */}
        </div>
        {/* //.pc_invisible */}
      </div>
      {/* //.content_box */}
    </>
  )
}

export default JoinStep4
