import React from 'react'

const FindIdMobile = ({ mobileFrontNums, onChangeMobileNum1, onChangeMobileNum2, onChangeMobileNum3, onChangeMobileCertNo, isDisableMobileCertButton, isDisableMobileFindButton, onCertRequest, mobileCert, onFindIdRequest }) => {

  return (
    <>
      {/*입력 양식(휴대폰)*/}
      <div className="group2 id_mobile_form">
        <div className="group group2_1">
          <form action="" method="" name="find_id_mobile">
            <div className="input_field">
              {/*휴대폰 번호*/}
              <div className="input_row mobile_num clearfix">
                <div className="input_tit">
                  <label htmlFor="mobile_num">휴대폰 번호</label>
                </div>{/*//.input_tit*/}
                <div className="input_content clearfix">
                  <div className="mobile_num1_cell float_l"> {/*modify 201915 태그 추가*/}
                    <select id="mobile_num1" name="mobile_num1" className="cell" onChange={onChangeMobileNum1}>
                      {
                        mobileFrontNums.map((item, index) => (
                          <option key={index} value={item}>{item}</option>
                        ))
                      }
                    </select>
                  </div>
                  {/* <input type="text" id="mobile_num1" name="mobile_num1" className="cell cell1 pc_invisible float_l" maxLength="3" /> */}
                  {/* <span>-</span> */}
                  <div className="mobile_num2_cell float_l"> {/*modify 201915 태그 추가*/}
                    <input type="text" id="mobile_num2" name="mobile_num2" className="cell" maxLength="4" onChange={onChangeMobileNum2} />
                  </div>
                  {/* <span>-</span> */}
                  <div className="mobile_num3_cell float_l"> {/*modify 201915 태그 추가*/}
                    <input type="text" id="mobile_num3" name="mobile_num3" className="cell" maxLength="4" onChange={onChangeMobileNum3} />
                  </div>
                  <div className="float_l"> {/*modify 201915 태그 추가*/}
                    <button type="button" className={isDisableMobileCertButton ? '' : 'active'} disabled={isDisableMobileCertButton} onClick={() => onCertRequest(mobileCert)}>인증요청</button>
                    {/*클래스 active 붙었을 때 버튼 색 바뀝니다*/}
                  </div>
                </div>{/*//.input_content*/}
              </div>{/*//.input_row.mobile_num*/}

              {/*인증번호 확인*/}
              <div className="input_row num_confirm clearfix">
                <div className="input_tit">
                  <label htmlFor="num_confirm">인증번호</label>
                </div>
                <div className="input_content">
                  <input type="text" id="num_confirm" name="num_confirm" className="cell" placeholder="인증번호 6자 입력" maxLength="6" onChange={onChangeMobileCertNo} />
                </div>
              </div>{/*//.input_row.num_confirm*/}
              <div className="warning">
                <span>* 휴대폰 번호 입력 후 ‘인증요청’ 버튼을 선택해주세요.</span>
              </div>{/*//.warning*/}
            </div>{/*//.input_field*/}
            <div className="btn">
              <button type="button" className={isDisableMobileFindButton ? '' : 'active'} disabled={isDisableMobileFindButton} onClick={() => onFindIdRequest(mobileCert)}>아이디 찾기</button>
              {/*클래스 active 붙었을 때 버튼 색 바뀝니다*/}
            </div>
          </form>
        </div>{/*//.group2_1*/}

        {/*하단 설명*/}
        <div className="group group2_2 id_mobile_desc">
          <div className="txt_wrap">
            <strong>[휴대폰 번호로 아이디 찾기 안내]</strong>
            <p>kt wiz 회원가입 시 입력한 휴대폰 번호로 아이디 찾기가 가능합니다.</p>
          </div>
        </div>{/*//.group2_2*/}
      </div>{/*//.group2.id_mobile_form*/}
    </>
  )
}

export default FindIdMobile
