import React from 'react'
import Link from '../common/Link'
import Swiper from 'react-id-swiper'

const HotIssueSlide = ({ hotIssueList }) => {
  const params = {
    containerClass: 'swiper-container3',
    WrapperEl: 'ul',
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    slidesPerGroup: 1,
    slidesPerView: 1,
    spaceBetween: 0,
    breakpoints: {
      993: {
        slidesPerView: 3,
        spaceBetween: 30
      },
      769: {
        slidesPerView: 2,
        spaceBetween: 30
      }
    }
  };

  console.log(hotIssueList);
  return (
    <>
      {hotIssueList &&
        <Swiper {...params}>
          {hotIssueList.map(article => (
            <li key={article.artcSeq} className="card">
              <div className="card_bg">
                <div className="img_wrap">
                  <Link to={'/media/' + (article.boardCode === '001' ? 'wiznews' : 'wizpress') + '/' + article.artcSeq}>
                    <img src={article.imgFilePath} alt={article.artcTitle} onError={e => {
                      e.target.src = null
                      e.target.src = require('../../assets/img/main/no_img_main_hotissue.png')
                    }} />
                  </Link>
                </div>
                <div className="txt_wrap">
                  <span className="category">{article.boardCode === '001' ? '위즈소식' : '보도자료'}</span>
                  <h4 className="card_tit"><Link to={'/media/wizpress/' + article.artcSeq}>{article.artcTitle}</Link></h4>
                  <p className="card_txt">{article.artcContents.replace(/<[^>]+>/g, "")}</p>
                  <div className="more">
                    <Link to={'/media/wizpress/' + article.artcSeq}>더보기</Link>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </Swiper>
      }
    </>
  )
}

export default HotIssueSlide
