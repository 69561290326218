import React from 'react'
import SongPcPlayerListItem from './SongPcPlayerListItem'

const SongPcPlayerList = ({ playerSongList, onClickIsPlayerPopup }) => {
  return (
    <>
      <div className="song_list_by">
        <h5>선수 응원가</h5>
        <ul>
          <SongPcPlayerListItem playerSongList={playerSongList} onClickIsPlayerPopup={onClickIsPlayerPopup} />
        </ul>
      </div>
    </>
  )
}

export default SongPcPlayerList
