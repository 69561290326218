import React, { useState } from 'react'
import SongPcTeam from './SongPcTeam'
import SongPcPlayer from './SongPcPlayer'

const SongPc = ({ teamSongList, playerSongList }) => {

  const [isPlaying, setIsPlaying] = useState({
    team: false,
    player: false,
  })

  const onIsPlaying = (isPlaying) => { setIsPlaying(isPlaying) }

  return (
    <>
      <SongPcTeam teamSongList={teamSongList} isPlaying={isPlaying} onIsPlaying={onIsPlaying} />
      <SongPcPlayer playerSongList={playerSongList} isPlaying={isPlaying} onIsPlaying={onIsPlaying} />
    </>
  )
}

export default SongPc
