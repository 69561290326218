import React from 'react'
import PitcherDetailSecondMobileTabRegular from './PitcherDetailSecondMobileTabRegular'
import PitcherDetailSecondMobileTabLastest from './PitcherDetailSecondMobileTabLastest'
import PitcherDetailSecondMobileTabTotal from './PitcherDetailSecondMobileTabTotal'

const dataTables = {
  PITCHER_DETAIL_REGULAR_SEASON_M: {
    title: '시즌 정규리그 기록',
    data: [
      { cols: '14%', head: ['경기', '세', '볼넷', '땅볼'], bodyParam: ['gamenum', 'sv', 'bb', 'go'] },
      { cols: '14.28%', head: ['ERA', 'SVO', '사구', 'K/BB'], bodyParam: ['era', 'svo', 'hp', 'kbb'] },
      { cols: '14.28%', head: ['W-L', '이닝', '실점', 'QS'], bodyParam: ['wl', 'innDisplay', 'r', 'qs'] },
      { cols: '14.28%', head: ['선발', '홀', '실책', 'QS+'], bodyParam: ['start', 'hold', 'err', 'qsPlus'] },
      { cols: '14.28%', head: ['완투', '승률', 'IRS', '터프세이브'], bodyParam: ['wCg', 'wra', 'ravg', 'turfSave'] },
      { cols: '14.28%', head: ['완봉', 'WHIP', '뜬공'], bodyParam: ['sho', 'whip', 'fo'] },
      { cols: '*', head: ['삼진', '피안타율', '피홈런율'], bodyParam: ['kk', 'oavg', 'havg'] },
    ]
  },
  PITCHER_DETAIL_LASTEST_GAME_M: {
    title: '최근 5경기 기록',
    data: [
      { cols: '', head: '일자', bodyParam: 'displayDate' },
      { cols: '', head: '상대팀', bodyParam: 'matchTeamName' },
      { cols: '', head: 'W-L', bodyParam: 'wl' },
      { cols: '', head: '이닝', bodyParam: 'innDisplay' },
      { cols: '', head: '세', bodyParam: 'sv' },
      { cols: '', head: '자책점', bodyParam: 'er' },
      { cols: '', head: '피안타', bodyParam: 'hit' },
      { cols: '', head: '볼넷', bodyParam: 'bb' },
      { cols: '', head: '삼진', bodyParam: 'kk' },
    ]
  },
  PITCHER_DETAIL_TOTAL_M: {
    title: '통산 기록',
    data: [
      { cols: '', head: '연도', bodyParam: 'gyear' },
      { cols: '', head: '팀', bodyParam: 'teamName' },
      { cols: '', head: '경기', bodyParam: 'gamenum', foot: 'sum' },
      { cols: '', head: '방어율', bodyParam: 'era', foot: 'avg' },
      { cols: '', head: '승', bodyParam: 'w', foot: 'sum' },
      { cols: '', head: '패', bodyParam: 'l', foot: 'sum' },
      { cols: '', head: '이닝', bodyParam: 'innDisplay', foot: 'inn' },
      { cols: '', head: '탈삼진', bodyParam: 'kk', foot: 'sum' },
    ]
  },
}

const PitcherDetailSecondMobile = ({ data, isFuturesLeague, selectedGyear }) => {

  const seasonsummary = data && data.seasonsummary
  const recentgamerecordlist = data && data.recentgamerecordlist
  const yearrecordlist = data && data.yearrecordlist

  return (
    <>
      {/* 퓨처스리그가 아닐 때에만 노출 */}
      {
        !isFuturesLeague &&
        <>
          {/* 정규리그 기록 */}
          <PitcherDetailSecondMobileTabRegular dataTable={dataTables.PITCHER_DETAIL_REGULAR_SEASON_M} seasonsummary={seasonsummary} selectedGyear={selectedGyear} />

          {/* 최근 5경기 */}
          <PitcherDetailSecondMobileTabLastest dataTable={dataTables.PITCHER_DETAIL_LASTEST_GAME_M} recentgamerecordlist={recentgamerecordlist} />

          {/* 통산 기록 */}
          <PitcherDetailSecondMobileTabTotal dataTable={dataTables.PITCHER_DETAIL_TOTAL_M} yearrecordlist={yearrecordlist} />
        </>
      }
    </>
  )
}

export default PitcherDetailSecondMobile
