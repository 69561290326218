// action types
export const SPONSOR_LIST_REQUEST = 'sponsor/SPONSOR_LIST_REQUEST'
export const SPONSOR_LIST_SUCCESS = 'sponsor/SPONSOR_LIST_SUCCESS'
export const SPONSOR_LIST_FAILURE = 'sponsor/SPONSOR_LIST_FAILURE'

// action creators
export const sponsorListRequest = () => ({ type: SPONSOR_LIST_REQUEST })
export const sponsorListSuccess = () => ({ type: SPONSOR_LIST_SUCCESS })
export const sponsorListFailure = () => ({ type: SPONSOR_LIST_FAILURE })

// reducers
const initialState = {
  sponsors: null,
  error: null,
}

export const sponsor = (state = initialState, action) => {
  switch (action.type) {
    case SPONSOR_LIST_REQUEST:
      return {
        ...state
      }
    case SPONSOR_LIST_SUCCESS:
      return {
        ...state,
        sponsors: action.sponsors
      }
    case SPONSOR_LIST_FAILURE:
      return {
        ...state,
        error: action.error
      }
    default:
      return state
  }
}

export default sponsor
