import React from 'react'
import ContentTemplate from '../../components/common/ContentTemplate'
import SubContentTemplate from '../../components/common/SubContentTemplate'
import PageHeaderContainer from '../../containers/common/PageHeaderContainer'
import PageTitleTemplate from '../../components/common/PageTitleTemplate'
import BreadcrumbContainer from '../../containers/common/BreadcrumbContainer'
import PageTemplate from '../../components/common/PageTemplate'
import { REGULAR_LEAGUE_WATCH_POINT, REGULAR_LEAGUE_TAB } from '../../constants/page'
import RegularLeagueWatchPointContainer from '../../containers/game/RegularLeagueWatchPointContainer'

const RegularLeagueTeamRankingPage = ({ match }) => {
  return (
    <PageTemplate className="game">
      <PageHeaderContainer title="정규 리그" subtitle="오늘의 관전 포인트를 알려 드립니다." image={require('../../assets/img/game/sub_tit_game.png')} forwardTabs={REGULAR_LEAGUE_TAB} currentPath={REGULAR_LEAGUE_WATCH_POINT.path} />
      <ContentTemplate className="game_watchpoint">
        <PageTitleTemplate>
          <BreadcrumbContainer page={REGULAR_LEAGUE_WATCH_POINT} />
        </PageTitleTemplate>
        <SubContentTemplate>
          <RegularLeagueWatchPointContainer match={match} />
        </SubContentTemplate>
      </ContentTemplate>
    </PageTemplate>
  )
}

export default RegularLeagueTeamRankingPage
