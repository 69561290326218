import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PitcherRanking from '../../components/game/PitcherRanking'
import { pitcherRankingRequest } from '../../modules/game/ranking'

const RegularLeaguePitcherRankingContainer = ({ year }) => {

  const eraTop3InKt = useSelector(state => state.wizData.data && state.wizData.data['ERA_TOP3_IN_KT'])
  // const eraTop3InKtError = useSelector(state => state.wizData.error && state.wizData.error['ERA_TOP3_IN_KT'])

  const winTop3InKt = useSelector(state => state.wizData.data && state.wizData.data['WIN_TOP3_IN_KT'])
  // const winTop3InKtError = useSelector(state => state.wizData.error && state.wizData.error['WIN_TOP3_IN_KT'])

  const eraTop5 = useSelector(state => state.wizData.data && state.wizData.data['ERA_TOP5'])
  // const eraTop5Error = useSelector(state => state.wizData.error && state.wizData.error['ERA_TOP5'])

  console.log(eraTop3InKt)

  const dispatch = useDispatch()

  useEffect(() => {
    if (year) {
      dispatch(pitcherRankingRequest({ 'gameSearch.gyear': year, 'gameSearch.inFlag': 'Y', 'gameSearch.teamCode': 'KT', 'gameSearch.top': 3, 'gameSearch.sortKey': 'ERA' }, 'ERA_TOP3_IN_KT'))
      dispatch(pitcherRankingRequest({ 'gameSearch.gyear': year, 'gameSearch.teamCode': 'KT', 'gameSearch.top': 3, 'gameSearch.sortKey': 'W' }, 'WIN_TOP3_IN_KT'))
      dispatch(pitcherRankingRequest({ 'gameSearch.gyear': year, 'gameSearch.inFlag': 'Y', 'gameSearch.top': 5, 'gameSearch.sortKey': 'ERA' }, 'ERA_TOP5'))
    }
  }, [year])


  return (
    <PitcherRanking eraTop3InKt={eraTop3InKt} winTop3InKt={winTop3InKt} eraTop5={eraTop5} />
  )
}

export default RegularLeaguePitcherRankingContainer
