import wiz from '../../api/wiz'
import { dataRequest } from '../common/wizData'

export const createDetailRequest = (params, dataType) => {

  // 코칭스텝 상세
  if (dataType === 'coachDetail') {
    return dataRequest(
      wiz.API.GET_COACH_DETAIL,
      {
        ...params
      },
      dataType
    )
  }
  // 투수 상세
  else if (dataType === 'pitcherDetail') {
    return dataRequest(
      wiz.API.GET_PITCHER_DETAIL,
      {
        ...params
      },
      dataType
    )
  }
  // 타자(포수,내야수,외야수) 상세
  else if (dataType === 'catcherDetail' || dataType === 'infielderDetail' || dataType === 'outfielderDetail') {
    return dataRequest(
      wiz.API.GET_BATTER_DETAIL,
      {
        ...params
      },
      dataType
    )
  }
  // 응원단 상세
  else if (dataType === 'cheerDetail') {
    return dataRequest(
      wiz.API.GET_CHEERLEADER_DETAIL,
      {
        ...params
      },
      dataType
    )
  }
  // 응원단 이미지
  else if (dataType === 'cheerDetailImg') {

    return dataRequest(
      wiz.API.GET_CHEERLEADER_DETAIL_IMG,
      {
        ...params
      },
      dataType

    )

  }
}

export const coachDetailRequest = params => createDetailRequest(params, 'coachDetail')
export const pitcherDetailRequest = params => createDetailRequest(params, 'pitcherDetail')
export const catcherDetailRequest = params => createDetailRequest(params, 'catcherDetail')
export const infielderDetailRequest = params => createDetailRequest(params, 'infielderDetail')
export const outfielderDetailRequest = params => createDetailRequest(params, 'outfielderDetail')
export const cheerDetailRequest = params => createDetailRequest(params, 'cheerDetail')
