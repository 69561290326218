import React, { useState } from 'react'
import ContentTemplate from '../../components/common/ContentTemplate'
import SubContentTemplate from '../../components/common/SubContentTemplate'
import PageHeaderContainer from '../../containers/common/PageHeaderContainer'
import PageTitleTemplate from '../../components/common/PageTitleTemplate'
import BreadcrumbContainer from '../../containers/common/BreadcrumbContainer'
import PageTemplate from '../../components/common/PageTemplate'
import { FUTURES_LEAGUE_RANKING_BATTER, FUTURES_LEAGUE_TAB, FUTURES_LEAGUE_RANKING_TAB } from '../../constants/page'
import FuturesLeagueBatterRankingContainer from '../../containers/game/FuturesLeagueBatterRankingContainer'
import SecondContentTemplate from '../../components/common/SecondContentTemplate'
import FuturesLeagueBatterRankingTableContainer from '../../containers/game/FuturesLeagueBatterRankingTableContainer'

const FuturesLeagueBatterRankingPage = ({ match }) => {


  FUTURES_LEAGUE_RANKING_TAB.forEach(tab => {
    tab.isActive = false
    if (match.path === tab.path) {
      tab.isActive = true
    }
  })

  const [year, setYear] = useState(null)

  return (
    <PageTemplate className="game">
      <PageHeaderContainer title="퓨처스 리그" subtitle="kt wiz의 타자 기록을 알려드립니다." image={require('../../assets/img/game/sub_tit_game.png')} forwardTabs={FUTURES_LEAGUE_TAB} currentPath={FUTURES_LEAGUE_RANKING_BATTER.parent.path} />
      <ContentTemplate className="game_rank_record">
        <PageTitleTemplate>
          <BreadcrumbContainer page={FUTURES_LEAGUE_RANKING_BATTER} />
        </PageTitleTemplate>
        <SubContentTemplate tabs={FUTURES_LEAGUE_RANKING_TAB}>
          <FuturesLeagueBatterRankingContainer match={match} year={year} />
        </SubContentTemplate>
      </ContentTemplate>
      <SecondContentTemplate className='game'>
        <FuturesLeagueBatterRankingTableContainer year={year} onChangeYear={setYear} />
      </SecondContentTemplate>
    </PageTemplate>
  )
}

export default FuturesLeagueBatterRankingPage
