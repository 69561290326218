import wiz from '../../api/wiz'
import { dataRequest } from '../common/wizData'

// listByCategory 관련 actions
const positions = {
  coach: '',
  pitcher: '투',
  catcher: '포',
  infielder: '내',
  outfielder: '외',
}

export const createListRequest = (params, dataType) => {

  // 코칭스텝
  if (dataType === 'coach') {

    return dataRequest(
      wiz.API.GET_COACH_LIST,
      {
        ...params
      },
      dataType

    )

  }
  //
  else if (dataType === 'pitcher' || dataType === 'catcher' || dataType === 'infielder' || dataType === 'outfielder') {

    return dataRequest(
      wiz.API.GET_PLAYER_LIST,
      {
        'gameSearch.position': positions[dataType] || positions.pitcher,
        ...params,
      },
      dataType
    )
  }
  // 응원단
  else if (dataType === 'cheer') {

    return dataRequest(
      wiz.API.GET_CHEERLEADER_LIST,
      {
        ...params
      },
      dataType

    )

  }
  // 응원가
  else if (dataType === 'song') {

    return dataRequest(
      wiz.API.GET_CHEERSONG_LIST,
      {
        ...params
      },
      dataType

    )

  }

}

export const coachRequest = params => createListRequest(params, 'coach')
export const pitcherRequest = params => createListRequest(params, 'pitcher')
export const catcherRequest = params => createListRequest(params, 'catcher')
export const infielderRequest = params => createListRequest(params, 'infielder')
export const outfielderRequest = params => createListRequest(params, 'outfielder')
export const cheerRequest = params => createListRequest(params, 'cheer')
export const songRequest = params => createListRequest(params, 'song')
