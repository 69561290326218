import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { login, cert, findId, findPw, resetPw } from './auth'
import { join, joinFormCheck } from './join/join'
import { wallpaper, sponsor, sponsorReq } from './ktwiz'
import wizData from './common/wizData'
import loading from './common/loading'
import dialog from './common/dialog'
import myInfo from './mypage/myinfo'
import pagination from './common/pagination'
import ticket from './ticket/ticket'
import gallery from './media/gallery'

// Add a new reducers
const rootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    login,
    cert,
    findId,
    findPw,
    resetPw,
    join,
    joinFormCheck,
    myInfo,
    wizData,
    pagination,
    loading,
    dialog,
    sponsor,
    wallpaper,
    ticket,
    sponsorReq,
    gallery
  })

export default rootReducer
