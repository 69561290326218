import React from 'react'
import Link from '../common/Link'

const PageTemplate = ({ children, className, isTop = false }) => {

  const handleScrollTop = e => {
    // window.scrollTo(0, 0);
    window.scroll({
      behavior: 'smooth',
      left: 0,
      top: 0
    });
  }

  return (
    <div className={"content " + className}>
      {children}

      {
        isTop &&
        <Link className='btn_top' onClick={handleScrollTop}>TOP</Link>
      }
    </div>
  )
}

export default PageTemplate
