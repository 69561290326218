import { put, takeEvery, all, call, take, fork } from 'redux-saga/effects'
import { push } from 'connected-react-router'
// actions
import * as ticketActions from '../../modules/ticket/ticket'
import * as dialogActions from '../../modules/common/dialog'
// api
import * as ticketApi from '../../api/ticket/ticket'
// const
// import * as Enum from '../../constants/enum'

/**
 * 티켓 예매하기 요청
 * @param {*} param0
 */
function* reserveTicket({ isLogin, isTerms, returnUrl }) {
  let date = new Date()
  let year = date.getFullYear()
  let month = date.getMonth() + 1
  let day = date.getDay()

  //console.log('y,m,d = ' + year + ' ' + month + ' ' + day)
  if (year <= 2021 && month <= 2 && day <= 15) {
    yield put(dialogActions.alertOpen('2021 시즌을 준비중입니다.'))
    return;
  }

  // 미로그인
  if (!isLogin) {
    yield put(dialogActions.confirmOpen('로그인 후 이용 가능합니다.', '로그인', '취소'))
    const loginDialogResult = yield take([dialogActions.CONFIRM_POSITIVE, dialogActions.CONFIRM_NEGATIVE])

    if (loginDialogResult.type === dialogActions.CONFIRM_POSITIVE) {
      yield put(push(`/login?returnUrl=${returnUrl}`))
    } else if (loginDialogResult.type === dialogActions.CONFIRM_NEGATIVE) {

    }
  }
  // 약관에 미동의
  // else if (!isTerms) {
  //   yield put(dialogActions.alertOpen('약관에 동의 후 이용 가능합니다.'))
  // }
  // 로그인 & 약관동의
  else {
    const callResp = yield call(ticketApi.getEncryptTlReqParam)

    const ticketUrl = process.env.REACT_APP_TICKET_URL

    let urlEncodedMemberId = encodeURIComponent(callResp.data.encryptedStr)

    console.log('URL ENCODE TEST :', urlEncodedMemberId, callResp.data.encryptedStr)

    // ticket 예메 페이지의 url이 노출되면 안되어 레이어 팝업으로 변경
    window.open(`${ticketUrl}/facility/direct/member/reserve?memberIdEnc=${urlEncodedMemberId}&partnerNo=biD4s%2F1HV%2Btw3H%2Bbfb9NBw%3D%3D$`, 'ticket', 'toolbar=no,location=no,directories=no,status=0,menubar=no,scrollbars=yes,resizable=no,copyhistory=no,width=990,height=890')
    /*
        let reserveUrl = `https://facility.ticketlink.co.kr/product/list?memberIdEnc=${callResp.data.encryptedStr}&partnerNo=biD4s%2F1HV%2Btw3H%2Bbfb9NBw%3D%3D$`
        // let reserveUrl = `https://facility.ticketlink.co.kr/facility/sports/type/LOGIN/?memberIdEnc=${callResp.data.encryptedStr}&partnerNo=biD4s%2F1HV%2Btw3H%2Bbfb9NBw%3D%3D$`

        let message = `
          <iframe width="100%" style="height:100%" src="${reserveUrl}">
          </iframe>
        `
        let style = {
          top: '5%',
          width: '90%',
          maxWidth: '1150px',
          height: '90%'
        }

        let isIframe = true

        yield put(dialogActions.alertOpen(message, '닫기', style, isIframe))*/
  }

}

function* reserveTicketWatch() { yield takeEvery(ticketActions.RESERVE_GENERAL_TICKET_REQUEST, reserveTicket) }

function* reserveTicketCheck({ isLogin, returnUrl }){
  if(!isLogin){
    yield put(dialogActions.confirmOpen('로그인 후 이용 가능 합니다.','로그인','취소'))
    const loginDialogResult = yield take([dialogActions.CONFIRM_POSITIVE, dialogActions.CONFIRM_NEGATIVE])
    if (loginDialogResult.type === dialogActions.CONFIRM_POSITIVE) {
      yield put(push(`/login?returnUrl=${returnUrl}`))

    } else if (loginDialogResult.type === dialogActions.CONFIRM_NEGATIVE) {
      
    }
  }else{
    const callMemberId = yield call(ticketApi.getEncryptTlReqParam)
    const urlEncodedMemberId = encodeURIComponent(callMemberId.data.encryptedStr)
  
    const ticketUrl = process.env.REACT_APP_TICKET_URL
  
    window.open(`${ticketUrl}/facility/direct/member/mypage?partnerNo=biD4s%2F1HV%2Btw3H%2Bbfb9NBw%3D%3D&memberIdEnc=${urlEncodedMemberId}`,'ticket check','toolbar=no,location=no,directories=no,status=0,menubar=no,scrollbars=yes,resizable=no,copyhistory=no,width=990,height=890')
  }
}

function* reserveTicketCheckWatch() { yield takeEvery(ticketActions.RESERVE_GENERAL_TICKET_CHECK, reserveTicketCheck) }

function* seasonTicketInfo(action) {
  const seasonInfoRes = yield call(ticketApi.getSeasonTicketInfo);
  
  yield put({
    type: ticketActions.SEASON_TICKET_SUCCESS,
    seasonInfo: seasonInfoRes.data,
  });
}

function* reserveSeasonTicketWatch(){ yield takeEvery(ticketActions.SEASON_TICKET_REQUEST, seasonTicketInfo) }

export default function* ticketSaga() {
  yield all([
    fork(reserveTicketWatch),
    fork(reserveTicketCheckWatch),
    fork(reserveSeasonTicketWatch)
  ])
}
