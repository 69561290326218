import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { resourceRequest, useResource } from '../../modules/resource/resource'
import * as dialogActions from "../../modules/common/dialog"
import Prepairing from '../common/Prepairing'
// import Link from '../../components/common/Link'

const GroupReservation = () => {
  const GROUP_IMAGE_M = 'GROUP_IMAGE_M'
  const GROUP_IMAGE_PC = 'GROUP_IMAGE_PC'
  const [imagePc, errorPc] = useResource(GROUP_IMAGE_PC)
  const [imageM, errorM] = useResource(GROUP_IMAGE_M)

  console.log(imagePc, imageM)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(resourceRequest(GROUP_IMAGE_PC))
    dispatch(resourceRequest(GROUP_IMAGE_M))
  }, [])

  useEffect(() => {
    if (errorPc && errorPc.message) {
      dispatch(dialogActions.alertOpen(errorPc.message))
    }
    if (errorM && errorM.message) {
      dispatch(dialogActions.alertOpen(errorM.message))
    }
  }, [errorPc, errorM])

  const isPrePage = process.env.REACT_APP_IS_PREPAIRING_PAGE

  return (
    <>
      {
        isPrePage === "Y" &&
        <Prepairing />
      }

      {
        isPrePage === "N" &&
        <>
          {/* <div className='img-whole'>
            <img className='pc' src={`${imagePc && imagePc.resource && imagePc.resource.value}`.replace('localhost:8080', 'test.ktwiz.co.kr:17400')} alt="" />
            <img className='m' src={`${imageM && imageM.resource && imageM.resource.value}`.replace('localhost:8080', 'test.ktwiz.co.kr:17400')} alt="" />
          </div>
          <div className='buttons'>
            <Link className='btn btn-red'>단체 관람 신청</Link>
          </div> */}


          <article className='arti g'>
            <div className='tit'>
              <h6>단체 관람 안내</h6>
            </div>
            <div className='desc'>
              <div className='pc'>
                <div className='r'>
                  <div className='sub-tit'>
                    <span className='icon'></span>
                    <strong className='red'>신청 방법</strong>
                  </div>
                </div>
                <div className='r'>
                  <strong>20인 이상 일반단체 신청</strong>
                </div>
                <div className='r'>
                  <div className='mark'>1)</div>
                  <p className='txt'>
                    티켓링크 콜센터 (1588-7890)&nbsp;&nbsp;* 경기일 무인 발권기 또는 예매티켓 교환처에서 예매번호를 통한 티켓수령<br />
                    (콜센터 단체관람은 최소 경기 2주전부터 신청가능, 예매 수수료 장당 1,000원)
                                    </p>
                </div>
              </div>
              <div className='m'>
                <div className='r'>
                  <div className='sub-tit'>
                    <span className='icon'></span>
                    <strong className='red'>신청 방법</strong>
                  </div>
                </div>
                <div className='r'>
                  <strong>20인 이상 일반단체 신청</strong>
                </div>
                <div className='r'>
                  <p className='txt'>
                    1) 티켓링크 콜센터 (1588-7890)
                                    </p>
                </div>
                <div className='r in'>
                  <div className='mark'>*</div>
                  <p className='txt'>
                    경기일 무인 발권기 또는 예매티켓 교환처에서<br />
                    예매번호를 통한 티켓수령<br />
                    (콜센터 단체관람은 최소 경기 2주전부터 신청가능,<br />
                    예매 수수료 장당 1,000원)
                                    </p>
                </div>
              </div>
            </div>
            <div className='imgs-wrap'>
              <div className='item'>
                <img className='pc' src={require('../../assets/img/ticket/group_guide_img_1.png')} alt='' />
                <img className='m' src={require('../../assets/img/ticket/group_guide_img_1_m.png')} alt='' />
              </div>
              <div className='item'>
                <img className='pc' src={require('../../assets/img/ticket/group_guide_img_2.png')} alt='' />
                <img className='m' src={require('../../assets/img/ticket/group_guide_img_2_m.png')} alt='' />
              </div>
              <div className='item'>
                <img className='pc' src={require('../../assets/img/ticket/group_guide_img_3.png')} alt='' />
                <img className='m' src={require('../../assets/img/ticket/group_guide_img_3_m.png')} alt='' />
              </div>
            </div>
          </article>
          <article className='arti'>
            <div className='tit'>
              <h6>유의사항</h6>
            </div>
            <div className='desc'>
              <div className='notice'>
                <div className='r'>
                  <span className='mark'>-</span>
                  <span className='txt'>
                    단체관람 신청은 경기 1일 전까지 가능하며, 경기 14일 전까지 신청하셔야 원하시는 자리를 확보하실 수 있습니다.
                                    </span>
                </div>
                <div className='r'>
                  <span className='mark'>-</span>
                  <span className='txt'>
                    결제와 동시에 발권이 진행되며, 발권이 완료된 입장권의 취소 시에는 소정의 취소수수료가 발생합니다.
                                    </span>
                </div>
                <div className='r in'>
                  <span className='mark'>*</span>
                  <span className='txt'>
                    예매 당일 : 취소수수료 없음, 예매일 이후 경기일 1일전 : 결제금액의 10% 수수료 발생
                                    </span>
                </div>
                <div className='r'>
                  <span className='mark'>-</span>
                  <span className='txt'>
                    경기일 당일에는 환불 및 취소가 절대 불가능하오니 유의하시기 바랍니다.
                                    </span>
                </div>
                <div className='r'>
                  <span className='mark'>-</span>
                  <span className='txt'>
                    우천취소 시 신용카드는 자동 취소되며 무통장입금은 신청 시 기재해주신 환불계좌를 통해 7일내 입금이 됩니다.
                                    </span>
                </div>
                <div className='r'>
                  <span className='mark'>-</span>
                  <span className='txt'>
                    단체관람 할인은 20인이상 단체관람에 한해 2,000원 할인 적용됩니다.
                                    </span>
                </div>
                <div className='r'>
                  <span className='mark'>-</span>
                  <span className='txt'>
                    반입물품 안내 사항은 아래와 같습니다.
                                    </span>
                </div>
                <div className='r in'>
                  <span className='mark'>1)</span>
                  <span className='txt'>
                    반입금지물품 : 주류(5% 이상)및 병, 캔 음료, 가스버너, 아이스박스, 유모차
                                    </span>
                </div>
                <div className='r in'>
                  <span className='mark'>2)</span>
                  <span className='txt'>
                    현수막, 엠프, 대북, 꾕과리, 호루라기, 부부젤라 등 경기에 방해되는 안내물 및 응원도구
                                    </span>
                </div>
                <div className='r'>
                  <span className='mark'>-</span>
                  <span className='txt'>
                    월요일은 구단 휴무일로 전화상담이 불가능 합니다.
                                    </span>
                </div>
              </div>
            </div>
          </article>
          <article className='arti'>
            <div className='tit'>
              <h6>전화문의 시간 안내</h6>

            </div>
            <div className='desc'>
              <div className='guide-wrap'>
                <div className='item'>
                  <div className='icon icon-phone'></div>
                  <h6>전화번호</h6>
                  <div className='row'>
                    <div className='mark'>·</div>
                    <div className='con'>1899-5916</div>
                  </div>
                  {/* <div className='row'>
                <div className='mark'>·</div>
                <div className='con'>010-1234-1234</div>
              </div> */}
                </div>
                <div className='item'>
                  <div className='icon icon-clock'></div>
                  <h6>운영시간</h6>
                  <div className='row'>
                    <div className='mark'>·</div>
                    <div className='con'>9시~18시</div>
                  </div>
                  <div className='row'>
                    <div className='con'>(점심시간 : 12시~13시)</div>
                  </div>
                </div>
                <div className='item'>
                  <div className='icon icon-ball'></div>
                  <h6>휴일</h6>
                  <div className='row'>
                    <div className='mark'>·</div>
                    <div className='con'>주말 홈경기 있을 시 : 월요일</div>
                  </div>
                  <div className='row'>
                    <div className='mark'>·</div>
                    <div className='con'>주말 홈경기 없을 시 : 토, 일, 월</div>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </>
      }

    </>
  )
}

export default GroupReservation
