import React from 'react'
import Link from '../../common/Link'

const SongMobilePopupSound = ({ soundPopupData, onClickIsSoundPopup, windowHeight }) => {

  return (
    <>
      <div className="popup_song popup_lyrics popup_sound" id="popup_sound" style={{ top: windowHeight * 0.5 }}>
        <Link onClick={() => onClickIsSoundPopup(false)} className="btn_sound_back">이전페이지 이동</Link>
        <div className="popup_content_box">
          <article className="article">
            <div className="lyrics">
              <strong>{soundPopupData.cheerSongTitle}</strong>
              <p>
                {
                  (soundPopupData && soundPopupData.cheerSongLyrics) &&
                  soundPopupData.cheerSongLyrics.split('\n').map((v, i) => (
                    <React.Fragment key={i}>
                      {v}<br />
                    </React.Fragment>
                  ))
                }
              </p>
            </div>
            <div className="song_player">
              <audio
                controls
                loop
                preload="true"
                style={{ width: "100%" }}
                controlsList="nodownload"
                src={soundPopupData && soundPopupData.cheerSongUrl}>
                <source src={soundPopupData && soundPopupData.cheerSongUrl} loop="loop" type="audio/mpeg" />
                <source src={soundPopupData && soundPopupData.cheerSongUrl} loop="loop" type="audio/ogg" />
                HTML5 Audio를 지원하지 않는 브라우저입니다.
              </audio>
            </div>
          </article>
        </div>
      </div>
      <div className="pop_back_black"></div>
    </>
  )
}

export default SongMobilePopupSound
