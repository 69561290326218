import React from 'react'
import { useLocation } from 'react-router-dom';
import Link from '../../common/Link'
import { format } from 'date-fns'

const ArticleGridItem = ({ article, index }) => {
  console.log(article)
  const { seq /*, contents, title, imgFilePath, registerAt, updateAt, viewCnt */ } = article;
  const location = useLocation();
  const search = location.search;
  const detailLink = `${location.pathname}/${seq}${search}`;

  return (
    <li className={(index + 1) % 3 === 0 ? ' third' : ''}>
      <article className='article'>
        <Link to={detailLink}>
          <div className='tit_img'>
            <img src={article && article.imgFilePath} alt={article && article.title} onError={e => {
              e.target.src = null
              e.target.src = require('../../../assets/img/media/no-img-media.png')
            }} />
            <span className='icon_play'>play 아이콘</span>
          </div>
          <div className='tit_detail'>
            <div>
              <dl>
                <dt>{article && article.title}</dt>
                {/* <dd>
                  <p>{article && article.content}</p>
                </dd> */}
              </dl>
              <div className='tit_info'>
                <ul>
                  <li>
                    <span className='date'>
                      {
                        (article && article.registerAt instanceof Date && !isNaN(article.registerAt)) &&
                        format(article.registerAt, 'yyyy.MM.dd')
                      }
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Link>
      </article>
    </li>
  )
}

export default ArticleGridItem
