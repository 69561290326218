import React from 'react'
import Link from '../../common/Link'
import { download } from '../../../modules/common/download'

const Symbolmark = () => {
  return (
    <>
      <article className='title'>
        <div className='txt'>
          <h6>심볼마크</h6>
        </div>
        <div className='txt-bar'></div>
        <div className="desc">
          <p>
            kt wiz는 구단명의 이니셜을 상징화한 ‘이니셜 심볼’과 <br />
            그림을 형상화한 ‘이미지 심볼’ 두 개의 심볼마크를 동시에 사용합니다.
          </p>
        </div>
        <div className='body'>
          <div className="video-wrap">
            <div className='video'>
              <iframe title="movie" src="https://www.youtube.com/embed/LR0BPPUXzsc" frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen></iframe>
            </div>
          </div>
        </div>
      </article>

      <article className='arti'>
        <div className='tit'>
          <h6>Initial Symbol</h6>
        </div>
        <div className="desc">
          <p>
            이니셜 심볼은 k와 w 두 글자를 이용하여 강력한 마법 문장이 연상 되도록 디자인 하였고, 전통과 현대적 감각의 조화를 고려하였습니다.<br />
            강인함과 파워를 표현합니다.
          </p>
        </div>
        <div className='body'>
          <div className="images">
            <div className='img-wrap'>
              <img className='pc' src={require('../../../assets/img/ktwiz/bi_symbol_initial_white.png')} alt="" />
              <img className='m' src={require('../../../assets/img/ktwiz/bi_symbol_initial_white_m.png')} alt="" />
            </div>
            <div className='img-wrap'>
              <img className='pc' src={require('../../../assets/img/ktwiz/bi_symbol_initial_black.png')} alt="" />
              <img className='m' src={require('../../../assets/img/ktwiz/bi_symbol_initial_black_m.png')} alt="" />
            </div>
          </div>
          <div className='pc'>
            <div className="buttons">
              <Link className='btn-download ai' to={require('../../../assets/download/InitialSymbol_ai.zip')} target='_blank' download>
                AI 다운로드
              </Link>
              <Link className='btn-download' onClick={() => download(require('../../../assets/download/InitialSymbol_jpg.jpg'), 'InitialSymbol.jpg')}>
                JPG 다운로드
              </Link>
            </div>
          </div>
        </div>
      </article>

      <article className='arti'>
        <div className='tit'>
          <h6>Image Symbol</h6>
        </div>
        <div className="desc">
          <p>
            이미지 심볼은 전형적인 마법사 이미지에서 탈피하여 현대적인 마법사의 모습을 새롭게 창조했습니다.<br />
            신속한 기동력과 유연함을 표현합니다.
          </p>
        </div>
        <div className='body'>
          <div className="images">
            <div className='img-wrap'>
              <img className='pc' src={require('../../../assets/img/ktwiz/bi_symbol_image_white.png')} alt="" />
              <img className='m' src={require('../../../assets/img/ktwiz/bi_symbol_image_white_m.png')} alt="" />
            </div>
            <div className='img-wrap'>
              <img className='pc' src={require('../../../assets/img/ktwiz/bi_symbol_image_black.png')} alt="" />
              <img className='m' src={require('../../../assets/img/ktwiz/bi_symbol_image_black_m.png')} alt="" />
            </div>
          </div>
          <div className='pc'>
            <div className="buttons">
              <Link className='btn-download' to={require('../../../assets/download/ImageSymbol_ai.zip')} target='_blank' download>
                AI 다운로드
              </Link>
              <Link className='btn-download' onClick={() => download(require('../../../assets/download/ImageSymbol_jpg.jpg'), 'ImageSymbol.jpg')}>
                JPG 다운로드
              </Link>
            </div>
          </div>
        </div>
      </article>

    </>
  )
}

export default Symbolmark
