import React from 'react'
import Link from '../../components/common/Link'

const PageForwardSubTab = ({ forwardSubtabs, currentSubPath }) => {

  forwardSubtabs.map((v, i) => {
    if (v.path === currentSubPath) { forwardSubtabs[i].isActive = true }
    else { forwardSubtabs[i].isActive = false }
  })

  return (
    <div className="lnb_dep_wrap">
      <div className="container">
        <ul>
          {forwardSubtabs.map((tab, i) => (
            <li key={`${i}`} className={'dep4' + (tab.isActive ? ' active' : '')}>
              <Link to={tab.path}>
                {tab.title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default PageForwardSubTab
