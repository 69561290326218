import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import GameSchedule from '../../components/game/GameSchedule'
import { futuresGameScheduleRequest, futuresGameAllScheduleRequest, recentFuturesGamesRequest, futuresDayScheduleRequest, futuresScoreBoardRequest } from '../../modules/game/gameSchedule'
import format from 'date-fns/format'
import { useHistory, useRouteMatch } from 'react-router-dom'

const FuturesLeagueGameScheduleContainer = () => {

  const history = useHistory()

  const yearMonthParam = useRouteMatch('/game/futures/schedule/:yearMonth')

  const schedule = useSelector(state => state.wizData.data && state.wizData.data['GAME_SCHEDULE_FUTURES'])
  // const scheduleError = useSelector(state => state.wizData.error && state.wizData.error['GAME_SCHEDULE_FUTURES'])

  const scheduleAll = useSelector(state => state.wizData.data && state.wizData.data['GAME_ALL_SCHEDULE_FUTURES'])
  // const scheduleAllError = useSelector(state => state.wizData.error && state.wizData.error['GAME_ALL_SCHEDULE_FUTURES'])

  const recentGames = useSelector(state => state.wizData.data && state.wizData.data['RECENT_FUTURES_GAMES'])
  // const recentGamesError = useSelector(state => state.wizData.error && state.wizData.error['RECENT_FUTURES_GAMES'])

  const daySchedule = useSelector(state => state.wizData.data && state.wizData.data['DAY_SCHEDULE_FUTURES'])
  // const dayScheduleError = useSelector(state => state.wizData.error && state.wizData.error['DAY_SCHEDULE_FUTURES'])

  const scoreBoard = useSelector(state => state.wizData.data && state.wizData.data['SCORE_BOARD_FUTURES'])
  // const scoreBoardError = useSelector(state => state.wizData.error && state.wizData.error['SCORE_BOARD_FUTURES'])

  const onScoreBoardRequest = (gmkey, gameDate) => dispatch(futuresScoreBoardRequest(gmkey, gameDate))

  const onChangeMonth = (yearMonth) => { history.push('/game/futures/schedule/' + yearMonth) }

  const dispatch = useDispatch()

  const yearMonth = (yearMonthParam && yearMonthParam.params.yearMonth) ? yearMonthParam.params.yearMonth : format(new Date(), 'yyyyMM')

  useEffect(() => {
    dispatch(recentFuturesGamesRequest())
    dispatch(futuresDayScheduleRequest())
  }, [])

  useEffect(() => {
    dispatch(futuresGameScheduleRequest(yearMonth))
    dispatch(futuresGameAllScheduleRequest(yearMonth))
  }, [yearMonth])

  return (
    <GameSchedule league="futures" onChangeMonth={onChangeMonth} onScoreBoardRequest={onScoreBoardRequest} year={yearMonth.substring(0, 4)} month={yearMonth.substring(4, 6)} recentGames={recentGames} scheduleData={schedule} daySchedule={daySchedule} allTeamSchedules={scheduleAll} scoreBoard={scoreBoard} />
  )
}

export default FuturesLeagueGameScheduleContainer
