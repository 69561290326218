import React from 'react'
import LoginContainer from '../../containers/auth/LoginContainer'
import PageTemplate from '../../components/common/PageTemplate'
import PageHeaderContainer from '../../containers/common/PageHeaderContainer'
import ContentTemplate from '../../components/common/ContentTemplate'
import PageTitleTemplate from '../../components/common/PageTitleTemplate'
import Breadcrumb from '../../components/common/Breadcrumb'
import { LOGIN_PAGE } from '../../constants/page'

const LoginPage = () => {

  return (
    <PageTemplate className='member'>
      <PageHeaderContainer
        title={LOGIN_PAGE.title}
        subtitle="로그인 후 kt wiz 사이트를 더욱 다양하게 이용해 보세요. 더욱 다양하게 이용해 보세요"
        image={require('../../assets/img/login/sub_tit_login.png')} />
      <ContentTemplate>
        <PageTitleTemplate>
          <Breadcrumb page={LOGIN_PAGE} />
        </PageTitleTemplate>
        <LoginContainer />
      </ContentTemplate>
    </PageTemplate>
  )
}

export default LoginPage
