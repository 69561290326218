import React from 'react'

const WizparkStore = () => {
    return (
        <div className="content">
            매장정보(페이지 목록 누락?)
        </div>
    )
}

export default WizparkStore
