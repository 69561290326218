import { put, all, call, fork, takeEvery } from 'redux-saga/effects'
import { getSponsorList } from '../../api/ktwiz/sponsor'
import * as actions from '../../modules/ktwiz/sponsor'

function* requestSponsorList(action) {
  const response = yield call(getSponsorList);

  if (response.data.status !== 0) {
    yield call(alert, response.data.message)
    yield put({ type: actions.SPONSOR_LIST_FAILURE })
  } else {
    const sponsors = response.data.data.list

    yield put({ type: actions.SPONSOR_LIST_SUCCESS, sponsors: sponsors })
  }
}

function* watchSponsorListRequest() {
  yield takeEvery(actions.SPONSOR_LIST_REQUEST, requestSponsorList)
}

export default function* sponsorSaga() {
  yield all([
    fork(watchSponsorListRequest),
  ])
}
