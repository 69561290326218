import React, { useEffect } from 'react'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { createDetailRequest } from '../../../modules/player/player'

const CheerDetail = ({ type }) => {
  const leaderSeq = (queryString.parse(useLocation().search)).leaderSeq

  const data = useSelector(state => state.wizData.data && state.wizData.data[type])
  const imgData = useSelector(state => state.wizData.data && state.wizData.data[`${type}Img`])

  console.log(imgData);
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(createDetailRequest({ 'cheerLeader.leaderSeq': leaderSeq }, type))
    dispatch(createDetailRequest({ 'cheerLeader.leaderSeq': leaderSeq }, `${type}Img`))
  }, [leaderSeq, type])

  return (
    <div className="player_detail_content cheer">
      <article className="player_info_content">
        <div>
          <div className="player_img">
            <img src={`${data && data.cheerLeader.titleImgPath}`} alt='' onError={(e) => {
              e.target.onError = null
              e.target.src = require('../../../assets/img/player/no_img_player_detail_cheerleader_m.png')
            }} />
          </div>
          <dl className="player_info">
            <dt>
              <span className="position">{data && data.cheerLeader.leaderPosition}</span>
              {data && data.cheerLeader.leaderName}
            </dt>
            <dd className="info_list_wrap">
              <ul>
                <li>
                  <dl>
                    <dt>별명</dt>
                    <dd>{data && data.cheerLeader.leaderNickName}</dd>
                  </dl>
                </li>
                <li>
                  <dl>
                    <dt>생년월일</dt>
                    <dd>{data && data.cheerLeader.leaderBirthDay}</dd>
                  </dl>
                </li>
                <li>
                  <dl>
                    <dt>키</dt>
                    <dd>{data && `${data.cheerLeader.leaderHeight}cm`}</dd>
                  </dl>
                </li>
                <li>
                  <dl>
                    <dt>취미</dt>
                    <dd>{data && data.cheerLeader.leaderHobby}</dd>
                  </dl>
                </li>
                <li>
                  <dl>
                    <dt>좌우명</dt>
                    <dd>{data && data.cheerLeader.leaderMotto}</dd>
                  </dl>
                </li>
                <li>
                  <dl>
                    <dt>주요경력</dt>
                    <dd>
                      {
                        data && data.cheerLeader.leaderCareer.split('\n').map((v, i) => (
                          <span key={i}>{v}</span>
                        ))
                      }
                    </dd>
                  </dl>
                </li>
                <li>
                  <dl>
                    <dt>좋아하는 kt wiz 선수</dt>
                    <dd>{data && data.cheerLeader.leaderLikePlayer}</dd>
                  </dl>
                </li>
                <li>
                  <dl>
                    <dt>kt wiz 팬들에게 인사말</dt>
                    <dd>{data && data.cheerLeader.leaderGreeting}</dd>
                  </dl>
                </li>
              </ul>
            </dd>
          </dl>
        </div>
      </article>
      <article className="player_images_content">
        <div className="player_images">
          <div>
            <h5>이미지</h5>
            <ul>
              {
                (imgData && imgData.length > 0)
                  ? imgData.map((v, i) => (
                    <>
                      <li className={i % 2 === 0 ? 'left' : 'right'} key={i}>
                        <img src={v.mediaPrvwPath} alt="img" onError={(e) => {
                          e.target.src = null
                          e.target.src = require('../../../assets/img/player/no_img_player_detail_cheerleader_m2.png')
                        }} />
                      </li>
                    </>
                  ))
                  : <>
                    <p>이미지가 없습니다.</p>
                  </>
              }
            </ul>
          </div>
        </div>
      </article>
    </div>
  )
}

export default CheerDetail
