import React, { useRef, useEffect } from 'react'
import Link from '../../common/Link'

const SongPcPlayerPopup = ({ playerPopupData, onClickIsPlayerPopup, isPlaying, onIsPlaying }) => {

  const audioRef = useRef()

  const onChangeAudioPlayer = (isPlaying) => {

    isPlaying &&
      onIsPlaying({
        team: false,
        player: true
      })
  }

  useEffect(() => {
    (isPlaying.team === true) &&
      audioRef.current.pause()
  }, [isPlaying])

  return (
    <>
      {/* pop up */}
      < div className="popup_song" id="popup_song">
        <h6>선수 응원가</h6>
        <div className="popup_content_box">
          <article className="article">
            <div className="tit_img">
              <img src={playerPopupData && playerPopupData.playerPrvwImg} alt={`${playerPopupData && playerPopupData.playerName} 선수`} onError={(e) => {
                e.target.onError = null
                e.target.src = require('../../../assets/img/player/no_img_cheersong_player.png')
              }} />
            </div>
            <div className="tit_detail">
              <strong>{playerPopupData && playerPopupData.playerName} 선수</strong>
              <span>응원가</span>
              <Link to="javascript:;" className="btn_song_list_play"></Link>
            </div>
            <div className="song_player" onChange={onChangeAudioPlayer}>
              <audio
                ref={audioRef}
                controls
                loop
                preload="true"
                style={{ width: "100%" }}
                controlsList="nodownload"
                src={`${playerPopupData && playerPopupData.cheerSongUrl}`.replace('localhost:8080', 'test.ktwiz.co.kr:17400')}
                onPlay={() => onChangeAudioPlayer(true)}
                onPause={() => onChangeAudioPlayer(false)}>

                <source src="" loop="loop" type="audio/mpeg" />
                <source src="" loop="loop" type="audio/ogg" />
                HTML5 Audio를 지원하지 않는 브라우저입니다.
                    </audio>
            </div>
          </article>
        </div>
        <button className="btn_close" onClick={() => onClickIsPlayerPopup(false)}>팝업 닫기 버튼</button>
      </div>
    </>
  )
}

export default SongPcPlayerPopup
