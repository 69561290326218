import React from 'react'
import ContentTemplate from '../../components/common/ContentTemplate'
import SubContentTemplate from '../../components/common/SubContentTemplate'
import PageHeaderContainer from '../../containers/common/PageHeaderContainer'
import PageTitleTemplate from '../../components/common/PageTitleTemplate'
import BreadcrumbContainer from '../../containers/common/BreadcrumbContainer'
import PageTemplate from '../../components/common/PageTemplate'
import { FUTURES_LEAGUE_RANKING_TEAM, FUTURES_LEAGUE_TAB, FUTURES_LEAGUE_RANKING_TAB } from '../../constants/page'
import FuturesLeagueTeamRankingContainer from '../../containers/game/FuturesLeagueTeamRankingContainer'

const FuturesLeagueTeamRankingPage = ({ match }) => {

  FUTURES_LEAGUE_RANKING_TAB.forEach(tab => {
    tab.isActive = false
    if (match.path === tab.path) {
      tab.isActive = true
    }
  })

  return (
    <PageTemplate className="game">
      <PageHeaderContainer title="퓨처스 리그" subtitle="kt wiz 퓨처스리그 기록을 알려 드립니다." image={require('../../assets/img/game/sub_tit_game.png')} forwardTabs={FUTURES_LEAGUE_TAB} currentPath={FUTURES_LEAGUE_RANKING_TEAM.parent.path} />
      <ContentTemplate className="game_rank_record">
        <PageTitleTemplate>
          <BreadcrumbContainer page={FUTURES_LEAGUE_RANKING_TEAM} />
        </PageTitleTemplate>
        <SubContentTemplate tabs={FUTURES_LEAGUE_RANKING_TAB}>
          <FuturesLeagueTeamRankingContainer match={match} />
        </SubContentTemplate>
      </ContentTemplate>
    </PageTemplate>
  )
}

export default FuturesLeagueTeamRankingPage
