import React, { useState, useRef, useEffect } from "react";
// import Link from "../../components/common/Link";
import format from "date-fns/format";
// import MainTopSlide from "./main/MainTopSlide";
// import GameScheduleSlide from "./main/GameScheduleSlide";
// import HotIssueSlide from "./main/HotIssueSlide";
// import GallerySlide from "./main/GallerySlide";
// import { UserAgent } from "react-useragent";
import { useCookies } from "react-cookie";
import Swiper from "react-id-swiper";
import { useHistory } from "react-router-dom";

const Home = ({
  autoscreenStatus,
  recentGames,
  highlightList,
  teamRanking,
  galleryList,
  hotIssueList,
  gameVideo,
}) => {
  const [cookies, setCookie] = useCookies(["popup_today"]);
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    setLoaded(true);
  }, []);

  const history = useHistory();

  const go = (link) => history.push(link);
  const gallerySlideParams = {
    containerClass: "swiper-wrapper",
    slidesPerView: "auto",
    // autoHeight: true,
    centeredSlides: true,
    spaceBetweenSlides: 12,
    //  navigation: false,
    breakpoints: {
      768: {
        slidesPerView: "auto",
        centeredSlides: true,
        // spaceBetween: 20,
        navigation: {
          nextEl: ".hm-gallery-swiper-button-next",
          prevEl: ".hm-gallery-swiper-button-prev",
        },
      },
      1199: {
        slidesPerView: "auto",
        centeredSlides: true,
        // spaceBetween: 20,
        navigation: {
          nextEl: ".hm-gallery-swiper-button-next",
          prevEl: ".hm-gallery-swiper-button-prev",
        },
      },
    },
  };

  const topSlideParams = {
    containerClass: "swiper-wrapper",
    navigation: {
      nextEl: "#hm-swiper-button-next",
      prevEl: "#hm-swiper-button-prev",
    },
    initialSlide: 0,
    // renderNextButton: _ => <div className="hm-swiper-button hm-swiper-button-next" ><img src="/v2/imgs/ico-keyvisual-next@2x.png" alt="Next" /></div>,
    // renderPrevButton: _ => <div className="hm-swiper-button hm-swiper-button-prev" ><img src="/v2/imgs/ico-keyvisual-prev@2x.png" alt="Prev" /></div>
  };
  const [gameInfoStatus, setGameInfoStatus] = useState("current");
  const [gameInfo, setGameInfo] = useState(null);

  const getTeamNameByKey = (key) => {
    if (key === "hh") {
      return "hanwha";
    }
    if (key === "ht") {
      return "kia";
    }
    if (key === "kt") {
      return "kt";
    }
    if (key === "lg") {
      return "lg";
    }
    if (key === "lt") {
      return "lotte";
    }
    if (key === "nc") {
      return "nc";
    }
    if (key === "ob") {
      return "doosan";
    }
    if (key === "sk") {
      return "ssg";
    }
    if (key === "ss") {
      return "samsung";
    }
    if (key === "wo") {
      return "kiwoom";
    }
    return "";
  };

  const thisDate = new Date();
  const thisYear = thisDate.getFullYear();

  const videoLink =
    autoscreenStatus && autoscreenStatus.autoScreenStatus[3] === "1"
      ? "https://m.sports.naver.com/game/popupPlayer/" +
        (recentGames && recentGames.current.gmkey) +
        thisYear
      : gameVideo && gameVideo.video.videoLink;

  useEffect(() => {
    if (recentGames) {
      setGameInfo(recentGames[gameInfoStatus]);
    }
  }, [recentGames, gameInfoStatus]);

  const scrollCallBack = (_) => {
    let bannerHeight = 1920;
    bannerHeight = document.querySelector(`.big-banner`).clientHeight - 400;
    // bannerHeight = .big-banner").height();
    var scrollTop = window.scrollY;
    const navEl = document.querySelector(`nav`);
    if (scrollTop > bannerHeight) {
      if (!navEl.classList.contains("scrolled")) {
        navEl.classList.add("scrolled");
      }
    } else {
      if (navEl.classList.contains("scrolled")) {
        navEl.classList.remove("scrolled");
      }
    }
  };
  useEffect(() => {
    setTimeout((_) => {
      window.addEventListener("scroll", scrollCallBack);
    }, 300);

    return function cleanup() {
      window.removeEventListener("scroll", scrollCallBack);
    };
  }, []);

  return (
    <div id="container" className="home">
      <div className="contents home">
        <header className="hm-header">
          <div className="big-banner">
            {/* <!-- Swiper --> */}
            <div className="swiper hm-banner-swiper">
              <Swiper {...topSlideParams}>
                <div className="swiper-slide">
                  <div
                    className="key-pc"
                    style={{
                      backgroundImage:
                        'url("/v2/imgs/dummy/main/2024_season_bg_web.png")',
                    }}
                  />
                  <div
                    className="key-mb"
                    style={{
                      backgroundImage:
                        'url("/v2/imgs/dummy/main/2024_season_bg_mb.png")',
                    }}
                  />
                </div>
              </Swiper>

              <div className="hm-swiper-control">
                <div className="wrap">
                  <div
                    className="hm-swiper-button hm-swiper-button-next"
                    id="hm-swiper-button-next"
                  >
                    <img src="/v2/imgs/ico-keyvisual-next@2x.png" alt="Next" />
                  </div>
                  <div
                    className="hm-swiper-button hm-swiper-button-prev"
                    id="hm-swiper-button-prev"
                  >
                    <img src="/v2/imgs/ico-keyvisual-prev@2x.png" alt="Prev" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="hm-news-list">
            <div className="con">
              <ul>
                {hotIssueList
                  ? hotIssueList
                      .filter((item, index) => index <= 2)
                      .map((article) => (
                        <li key={article.artcSeq}>
                          <div className="item">
                            <div className="top">
                              <div className="highlight-chip">
                                {article.boardCode === "001"
                                  ? "위즈소식"
                                  : "보도자료"}
                              </div>
                            </div>
                            <div className="info">
                              <div className="title ellipsis">
                                {article.artcTitle}
                              </div>
                              <div className="desc">
                                {article.artcContents.replace(/<[^>]+>/g, "")}
                              </div>
                            </div>
                            <div className="control">
                              <div
                                className="link"
                                style={{ cursor: "pointer" }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  go(`/media/wizpress/${article.artcSeq}`);
                                }}
                              >
                                자세히보기
                                <img
                                  className="arrow"
                                  src="/v2/imgs/ico-12-more.svg"
                                  alt="바로가기"
                                />
                              </div>
                            </div>
                          </div>
                        </li>
                      ))
                  : null}
              </ul>
            </div>
          </div>
        </header>
        <main>
          {/* <!-- 게임 스케쥴 --> */}
          <section className="hm-schedule">
            <div className="con">
              <div className="title-img">
                <img
                  className="pc"
                  src="/v2/imgs/img-title-schedule@2x.png"
                  alt="game Schedule"
                />
                <img
                  className="mb"
                  src="/v2/imgs/img-title-schedule-mb@2x.png"
                  alt="game Schedule"
                />
              </div>
              <div className="info">
                {gameInfo ? (
                  <div className="result">
                    <div className="header">
                      <div
                        className="arrow"
                        onClick={(_) => {
                          if (gameInfoStatus === "current") {
                            setGameInfoStatus("prev");
                          } else if (gameInfoStatus === "next") {
                            setGameInfoStatus("current");
                          }
                        }}
                      >
                        <img src="/v2/imgs/ico-24-navi-prev.svg" alt="prev" />
                      </div>
                      <div className="center">
                        <div className="date">
                          {`${gameInfo.gyear}.${gameInfo.gmonth}.${gameInfo.gday}`}
                        </div>
                        <div className="location">
                          {gameInfo.stadium} {gameInfo.gtime}
                        </div>
                      </div>
                      <div
                        className="arrow"
                        onClick={(_) => {
                          if (gameInfoStatus === "current") {
                            setGameInfoStatus("next");
                          } else if (gameInfoStatus === "prev") {
                            setGameInfoStatus("current");
                          }
                        }}
                      >
                        <img src="/v2/imgs/ico-24-navi-next.svg" alt="next" />
                      </div>
                    </div>

                    <div className="body">
                      <div className="team">
                        <div className="emblem">
                          <i
                            className={`emblem emblem-120 emblem-${getTeamNameByKey(
                              gameInfo.visitKey.toLowerCase()
                            )}`}
                          ></i>
                        </div>
                        <div className="name">{gameInfo.visit}</div>
                      </div>
                      <div className="wrap">
                        <div className="period">{/* {gameInfo.outcome} */}</div>
                        <div className="score">
                          <span className="number">
                            {gameInfo.visitScore ? gameInfo.visitScore : 0}
                          </span>
                          <span className="divider">:</span>
                          <span className="number">
                            {gameInfo.homeScore ? gameInfo.homeScore : 0}
                          </span>
                        </div>
                        <div className="button">
                          <div
                            className="btn-game-info"
                            onClick={(e) => {
                              e.preventDefault();
                              go(
                                `/game/regular/boxscore/${gameInfo.gameDate}/${gameInfo.gmkey}`
                              );
                            }}
                          >
                            경기정보
                            <img
                              src="/v2/imgs/ico-18-navi-next.svg"
                              alt="경기정보"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="team">
                        <div className="emblem">
                          <i
                            className={`emblem emblem-120 emblem-${getTeamNameByKey(
                              gameInfo.homeKey.toLowerCase()
                            )}`}
                          ></i>
                        </div>
                        <div className="name">{gameInfo.home}</div>
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="video">
                  {loaded && (
                    <iframe
                      width="395"
                      height="221"
                      src={videoLink}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  )}
                </div>
              </div>
              <div className="etc">
                <div className="flex flex-2">
                  <div
                    className="col"
                    onClick={(e) => {
                      e.preventDefault();
                      go("/game/regular/ranking/team");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="kt-card kt-card-rank">
                      <div className="bg">
                        <img
                          src="/v2/imgs/img-banner-parking@2x.png"
                          alt="팀 순위"
                        />
                        <div className="mask"></div>
                      </div>
                      <div className="info">
                        <div className="rank">
                          <div className="title">팀 순위</div>
                          <div className="number">
                            <strong>{teamRanking && teamRanking.rank}</strong>
                            <span className="unit">위</span>
                          </div>
                        </div>
                        <div className="result">
                          <div className="record">
                            {teamRanking && teamRanking.wldName}
                          </div>
                          <div className="game">
                            총 {teamRanking && teamRanking.game}경기, 승률{" "}
                            {teamRanking && teamRanking.wra}
                          </div>
                        </div>
                        <div className="icon">
                          <img src="/v2/imgs/img-score@2x.png" alt="팀 순위" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col"
                    onClick={(e) => {
                      e.preventDefault();
                      go("/wizpark/parking");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="kt-card kt-card-parking">
                      <div className="bg">
                        <img
                          src="/v2/imgs/dummy/main/img-banner-parking@2x.png"
                          alt="KT wiz 공식 온라인 스토어"
                        />
                        <div className="mask"></div>
                      </div>
                      <div className="info">
                        <div className="title">수원 케이티 위즈 파크</div>
                        <div className="desc">사전 주차 예약제 안내</div>
                        <div className="link">
                          사전주차 예약하기
                          <img
                            className="arrow"
                            src="/v2/imgs/ico-12-more-bl@2x.png"
                            alt="바로가기"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* <!-- 위즈 비디오 --> */}
          <section className="hm-video">
            <div className="con">
              <div className="title-img">
                <img
                  className="pc"
                  src="/v2/imgs/img-title-video@2x.png"
                  alt="wiz Video"
                />
                <img
                  className="mb"
                  src="/v2/imgs/img-title-video-mb@2x.png"
                  alt="wiz Video"
                />
              </div>
              <div className="video">
                <iframe
                  title="highlight"
                  src={highlightList && highlightList[0].videoLink}
                  frameBorder="no"
                  scrolling="no"
                  marginWidth="0"
                  marginHeight="0"
                  width="100%"
                  height="100%"
                  allow="autoplay"
                  allowFullScreen
                >
                  <p>iframe을 지원하지 않는 브라우저입니다.</p>
                </iframe>
              </div>
              <div className="thumbnail-list">
                <ul>
                  {highlightList
                    ? highlightList
                        .filter((item, index) => index <= 3)
                        .map((item, index) => (
                          <li
                            key={`video-${index}`}
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              e.preventDefault();
                              go(`/media/highlight/${item.artcSeq}`);
                            }}
                          >
                            <div className="item">
                              <div className="img">
                                <img
                                  src={item.imgFilePath}
                                  alt={item.artcTitle}
                                />
                              </div>
                              <div className="info">
                                <div className="top flex flex-2">
                                  <div className="col">
                                    <div className="highlight-chip">
                                      하이라이트
                                    </div>
                                  </div>
                                  <div className="col">
                                    <div className="date">
                                      {format(
                                        new Date(item.regDttm),
                                        "yyyy년 MM월 dd일"
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="title">{item.artcTitle}</div>
                              </div>
                            </div>
                          </li>
                        ))
                    : null}
                </ul>
              </div>
              <div className="controller">
                <button
                  className="hm-btn-more"
                  onClick={(_) => go("/media/highlight")}
                >
                  더 많은 영상보기
                </button>
              </div>
            </div>
          </section>

          {/* <!-- 피칭 분석 & 모션 트래킹  -->
                <!-- deprecated -->
                <!--                  */}
          {/* <section className="hm-analytics">
                    <div className="flex flex-2">
                        <div className="col">
                            <div className="kt-card kt-card-analytics">
                                <div className="bg">
                                    <img src="/v2/imgs/img-banner-parking@2x.png" alt="KT wiz 공식 온라인 스토어" />
                                    <div className="mask"></div>
                                </div>
                                <div className="info">
                                    <div className="title">
                                        피칭분석
                                    </div>
                                    <div className="desc">
                                        전문가가 꼼꼼하게 분석해주는 선수들의 피칭분석
                                    </div>
                                    <div className="link">
                                        바로가기
                                        <img className="arrow" src="/v2/imgs/ico-12-more.svg" alt="바로가기" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="kt-card kt-card-analytics">
                                <div className="bg">
                                    <img src="/v2/imgs/img-banner-parking@2x.png" alt="KT wiz 공식 온라인 스토어" />
                                    <div className="mask"></div>
                                </div>
                                <div className="info">
                                    <div className="title">
                                        모션트래킹
                                    </div>
                                    <div className="desc">
                                        kt wiz만의 기술로 선보이는 선수들의 모션트래킹 영상
                                    </div>
                                    <div className="link">
                                        바로가기
                                        <img className="arrow" src="/v2/imgs/ico-12-more.svg" alt="바로가기" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>  */}
          {/* --> */}

          {/* <!-- 위즈 갤러리 --> */}
          {galleryList ? (
            <section className="hm-gallery">
              <div className="con">
                <div className="title-img">
                  <img
                    className="pc"
                    src="/v2/imgs/img-title-gallery@2x.png"
                    alt="wiz Gallery"
                  />
                  <img
                    className="mb"
                    src="/v2/imgs/img-title-gallery-mb@2x.png"
                    alt="wiz Gallery"
                  />
                </div>
                {/* <!-- Swiper --> */}
                <div className="swiper hm-gallery-swiper">
                  {/* <div className="swiper-wrapper"> */}
                  <Swiper {...gallerySlideParams}>
                    {galleryList.map((item, index) => (
                      <div className="swiper-slide" key={`gallery-${index}`}>
                        <div
                          className="img"
                          style={{
                            backgroundImage: `url(${item.imgFilePath})`,
                          }}
                        ></div>
                        <div className="masking"></div>
                      </div>
                    ))}
                  </Swiper>
                  {/* </div> */}
                  <div className="hm-gallery-control">
                    <div className="wrap">
                      <div
                        className="hm-gallery-swiper-button hm-gallery-swiper-button-next"
                        id="hm-gallery-swiper-button-next"
                      >
                        <img
                          src="/v2/imgs/ico-54-gallery-next@2x.png"
                          alt="Next"
                        />
                      </div>
                      <div
                        className="hm-gallery-swiper-button hm-gallery-swiper-button-prev"
                        id="hm-gallery-swiper-button-prev"
                      >
                        <img
                          src="/v2/imgs/ico-54-gallery-prev@2x.png"
                          alt="Prev"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="controller">
                  <button
                    className="hm-btn-more"
                    onClick={(_) => go("/media/photo")}
                  >
                    더 많은 사진보기
                  </button>
                </div>
              </div>
              {/* <!-- Initialize Swiper -->
                    <script>
                        var swiper = new Swiper(".hm-gallery-swiper", {
                            slidesPerView: 5,
                            spaceBetween: 20,
                            centeredSlides: true,
                            navigation: {
                                nextEl: ".hm-gallery-swiper-button-next",
                                prevEl: ".hm-gallery-swiper-button-prev",
                            },
                            cssWidthAndHeight: true,
                            slidesPerView: 'auto',
                            visibilityFullFit: true,
                            autoResize: false,
                            breakpoints: {
                                1199: {
                                    slidesPerView: 3,
                                    spaceBetweenSlides: 12
                                }
                            }
                        });
                    </script> */}
            </section>
          ) : null}

          {/* <!-- 온라인 스토어 & 이달의 선수 --> */}
          <section className="hm-etc">
            <div className="flex flex-2">
              <div
                className="col"
                style={{ cursor: "pointer" }}
                onClick={(_) => window.open("https://www.ktwizstore.co.kr")}
              >
                <div className="kt-card kt-card-store">
                  <div className="bg">
                    <img
                      src="/v2/imgs/dummy/main/img-banner-store@2x.png"
                      alt="KT wiz 공식 온라인 스토어"
                    />
                    <div className="mask"></div>
                  </div>
                  <div className="info">
                    <img
                      className="logo"
                      src="/v2/imgs/img-logo.svg"
                      alt="kt wiz"
                    />
                    <div className="title">공식 온라인 스토어</div>
                    <div className="desc">
                      kt wiz의 익스클루시브한 아이템을 만나보세요!
                    </div>
                    <div className="link">
                      바로가기
                      <img
                        className="arrow"
                        src="/v2/imgs/ico-12-more.svg"
                        alt="바로가기"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col"
                style={{ cursor: "pointer" }}
                onClick={(_) => go("/player/infielder/detail?pcode=50054")}
              >
                <div className="kt-card kt-card-monthly-player">
                  <div className="bg">
                    <img
                      src="/v2/imgs/dummy/main/img/banner/player@2x.png"
                      alt="KT wiz 이달의 선수"
                    />
                    <div className="mask"></div>
                  </div>
                  <div className="info">
                    <div className="title">
                      <img
                        className="logo"
                        src="/v2/imgs/img-logo.svg"
                        alt="kt wiz"
                      />
                      이달의 선수
                    </div>
                    <div className="number">14</div>
                    <div className="name">천성호</div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </div>
  );
};

export default Home;
