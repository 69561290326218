import React from 'react'
import ContentTemplate from '../../components/common/ContentTemplate'
import SubContentTemplate from '../../components/common/SubContentTemplate'
import PageHeaderContainer from '../../containers/common/PageHeaderContainer'
import PageTitleTemplate from '../../components/common/PageTitleTemplate'
import BreadcrumbContainer from '../../containers/common/BreadcrumbContainer'
import PageTemplate from '../../components/common/PageTemplate'
import { REGULAR_LEAGUE_SCHEDULE, REGULAR_LEAGUE_TAB } from '../../constants/page'
import RegularLeagueGameScheduleContainer from '../../containers/game/RegularLeagueGameScheduleContainer'

const RegularLeagueSchedulePage = ({ match }) => {
  console.log(match)

  return (
    <PageTemplate className="game">
      <PageHeaderContainer title="정규 리그" subtitle="kt wiz의 정규리그 경기 일정을 알려 드립니다." image={require('../../assets/img/game/sub_tit_game.png')} forwardTabs={REGULAR_LEAGUE_TAB} currentPath={match.path} />
      <ContentTemplate className='game_schedule'>
        <PageTitleTemplate>
          <BreadcrumbContainer page={REGULAR_LEAGUE_SCHEDULE} />
        </PageTitleTemplate>
        <SubContentTemplate>
          <RegularLeagueGameScheduleContainer />
        </SubContentTemplate>
      </ContentTemplate>
    </PageTemplate>
  )
}

export default RegularLeagueSchedulePage
