import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { resourceRequest, useResource } from '../../../modules/resource/resource'
import * as dialogActions from "../../../modules/common/dialog"
import Prepairing from '../../common/Prepairing'

const PolicyChild = () => {
  const DISCOUNT_CHILD_IMAGE_M = 'DISCOUNT_CHILD_IMAGE_M'
  const DISCOUNT_CHILD_IMAGE_PC = 'DISCOUNT_CHILD_IMAGE_PC'
  const [imagePc, errorPc] = useResource(DISCOUNT_CHILD_IMAGE_PC)
  const [imageM, errorM] = useResource(DISCOUNT_CHILD_IMAGE_M)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(resourceRequest(DISCOUNT_CHILD_IMAGE_PC))
    dispatch(resourceRequest(DISCOUNT_CHILD_IMAGE_M))
  }, [])

  useEffect(() => {
    if (errorPc && errorPc.message) {
      dispatch(dialogActions.alertOpen(errorPc.message))
    }
  }, [errorPc])

  useEffect(() => {
    if (errorM && errorM.message) {
      dispatch(dialogActions.alertOpen(errorM.message))
    }
  }, [errorM])

  const isPrePage = process.env.REACT_APP_IS_PREPAIRING_PAGE

  return (
    <div className='img-whole'>
      <img className='pc' src={imagePc && imagePc.resource && imagePc.resource.value} alt="" />
      <img className='m' src={imageM && imageM.resource && imageM.resource.value} alt="" />
    </div>
  )
}

export default PolicyChild
