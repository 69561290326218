import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { resourceRequest, useResource } from '../../modules/resource/resource'
import * as dialogActions from "../../modules/common/dialog"
import Prepairing from '../common/Prepairing'

const SkyboxReservation = () => {
  const SKYBOX_IMAGE_M = 'SKYBOX_IMAGE_M'
  const SKYBOX_IMAGE_PC = 'SKYBOX_IMAGE_PC'
  const [imagePc, errorPc] = useResource(SKYBOX_IMAGE_PC)
  const [imageM, errorM] = useResource(SKYBOX_IMAGE_M)

  console.log(imagePc, imageM)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(resourceRequest(SKYBOX_IMAGE_PC))
    dispatch(resourceRequest(SKYBOX_IMAGE_M))
  }, [])

  useEffect(() => {
    if (errorPc && errorPc.message) {
      dispatch(dialogActions.alertOpen(errorPc.message))
    }
  }, [errorPc, errorM])

  //const isPrePage = process.env.REACT_APP_IS_PREPAIRING_PAGE
  const isPrePage = "Y"

  return (
    <>
      {
        isPrePage === "Y" &&
        <Prepairing />
      }
      {
        isPrePage === "N" &&
        <>
          <article className='arti g'>
            <div className='tit'>
              <h6>스카이 박스 안내</h6>
            </div>
            <div className='imgs-wrap'>
              <div className='item'>
                <img className='pc' src={require('../../assets/img/ticket/skybox_internal_facility_pc.jpg')} alt="" />
                <img className='m' src={require('../../assets/img/ticket/skybox_internal_facility_m.jpg')} alt="" />
                <div className='txt pc'>
                  스카이 박스 내부 시설
            </div>
              </div>
              <div className='item'>
                <img className='pc' src={require('../../assets/img/ticket/skybox_internal_view_pc.jpg')} alt="" />
                <img className='m' src={require('../../assets/img/ticket/skybox_internal_view_m.jpg')} alt="" />
                <div className='txt pc'>
                  스카이 박스 내부 전경
            </div>
              </div>
              <div className='item'>
                <img className='pc' src={require('../../assets/img/ticket/skybox_external_view_pc.jpg')} alt="" />
                <img className='m' src={require('../../assets/img/ticket/skybox external_view_m.jpg')} alt="" />
                <div className='txt pc'>
                  스카이 박스 외부 전경
            </div>
              </div>
            </div>
          </article>
          <article className='arti'>
            <div className='tit'>
              <h6>스카이 박스 입장 방법</h6>
            </div>
            <div className='desc'>
              <div className='notice'>
                <div className='r'>
                  KT에서 받은 내용으로 작성
              </div>
              </div>
            </div>
          </article>
          <article className='arti'>
            <div className='tit'>
              <h6>유의사항</h6>
            </div>
            <div className='desc'>
              <div className='notice'>
                <div className='r'>
                  <span className='mark'>-</span>
                  <span className='txt'>
                    단체관람 신청은 경기 1일 전까지 가능하며, 경기 14일 전까지 신청하셔야 원하시는 자리를 확보하실 수 있습니다.
              </span>
                </div>
                <div className='r'>
                  <span className='mark'>-</span>
                  <span className='txt'>
                    결제와 동시에 발권이 진행되며, 발권이 완료된 입장권의 취소 시에는 소정의 취소수수료가 발생합니다.
              </span>
                </div>
                <div className='r in'>
                  <span className='mark'>*</span>
                  <span className='txt'>
                    예매 당일 : 취소수수료 없음, 예매일 이후 경기일 1일전 : 결제금액의 10% 수수료 발생
              </span>
                </div>
                <div className='r'>
                  <span className='mark'>-</span>
                  <span className='txt'>
                    경기일 당일에는 환불 및 취소가 절대 불가능하오니 유의하시기 바랍니다.
              </span>
                </div>
                <div className='r'>
                  <span className='mark'>-</span>
                  <span className='txt'>
                    단체관람 할인은 20인이상 단체관람에 한해 2,000원 할인 적용됩니다.
              </span>
                </div>
                <div className='r'>
                  <span className='mark'>-</span>
                  <span className='txt'>
                    반입물품 안내 사항은 아래와 같습니다.
              </span>
                </div>
                <div className='r in'>
                  <span className='mark'>1)</span>
                  <span className='txt'>
                    &nbsp;반입금지물품 : 주류(5% 이상)및 병, 캔 음료, 가스버너, 아이스박스, 유모차
              </span>
                </div>
                <div className='r in'>
                  <span className='mark'>2)</span>
                  <span className='txt'>
                    &nbsp;현수막, 엠프, 대북, 꾕과리, 호루라기, 부부젤라 등 경기에 방해되는 안내물 및 응원도구
              </span>
                </div>
                <div className='r'>
                  <span className='mark'>-</span>
                  <span className='txt'>
                    월요일은 구단 휴무일로 전화상담이 불가능 합니다.
              </span>
                </div>
              </div>
            </div>
          </article>
          <article className='arti'>
            <div className='tit'>
              <h6>전화문의 시간 안내</h6>
            </div>
            <div className='desc'>
              <div className='guide-wrap'>
                <div className='item'>
                  <div className='icon icon-phone'></div>
                  <h6>전화번호</h6>
                  <div className='row'>
                    <div className='mark'>·</div>
                    <div className='con'>1899-5916</div>
                  </div>
                  {/* <div className='row'>
                <div className='mark'>·</div>
                <div className='con'>010-1234-1234</div>
              </div> */}
                </div>
                <div className='item'>
                  <div className='icon icon-clock'></div>
                  <h6>운영시간</h6>
                  <div className='row'>
                    <div className='mark'>·</div>
                    <div className='con'>9시~18시</div>
                  </div>
                  <div className='row'>
                    <div className='con'>(점심시간 : 12시~13시)</div>
                  </div>
                </div>
                <div className='item'>
                  <div className='icon icon-ball'></div>
                  <h6>휴일</h6>
                  <div className='row'>
                    <div className='mark'>·</div>
                    <div className='con'>주말 홈경기 있을 시 : 월요일</div>
                  </div>
                  <div className='row'>
                    <div className='mark'>·</div>
                    <div className='con'>주말 홈경기 없을 시 : 토, 일, 월</div>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </>
      }
    </>
  )
}

export default SkyboxReservation
