import { serviceLog20240304 } from "components/auth/join/privacyPolicyText";
import React from "react";

const Use = ({ onClose }) => {
  return (
    <div className="util_popup use">
      <div className="util_popup_top">
        <h4>이용약관</h4>
        <span>kt wiz사이트 이용과 관련된 사항을 규정합니다.</span>
      </div>
      <div className="util_popup_content">
        {/* <p>
          제1장 총칙<br />
          <br />
          제 1조 [목적]<br />
          이 약관은 주식회사 케이티스포츠(이하 “회사”라 합니다)와 이용고객(이하 “회원”이라고 함)간에 “회사”가 제공하는 콘텐츠 서비스인 Wizzap 서비스(이하 “서비스”라 합니다) 및 제반 서비스를 유선 또는 무선 인터넷 등의 수단으로 이용함에 있어 “회사”와 “회원”간의 권리, 이용에 관한 제반 사항과 기타 필요한 사항을 규정하는 것을 목적으로 합니다.<br />
          <br />
          제2조 [용어의 정의]<br />
          이 약관에서 사용하는 용어의 정의는 다음 각호와 같습니다.<br />
          1. “이용자”라 함은 “회사”가 제공하는 서비스에 유선 또는 무선 인터넷 등의 수단으로 접속하여 이 약관에 따라 “회사”가 제공하는 “콘텐츠” 및 제반 서비스를 이용하는 “간편회원” 및 “실명회원”을 말합니다.<br />
          2. “회원”이라 함은 “회사”와 이용계약을 체결하고 “이용자”아이디(ID)를 부여 받은 “이용자”로서 “회사”의 정보를 지속적으로 제공받으며 “회사”가 제공하는 서비스를 지속적으로 이용할 수 있는 자를 말합니다.<br />
          3. “아이디(ID)”라 함은 “회원” 식별과 “회원”의 서비스 이용을 위하여 “회원”이 선정하고 “회사”가 승인하는 영문자와 숫자의 조합을 의미합니다.<br />
          4. “비밀번호(Password)”라 함은 “회원”이 부여 받은 “아이디(ID)”와 일치되는 “회원”임을 확인하고 비밀보호를 위해 “회원”자신이 정한 문자 또는 숫자의 조합을 말합니다.<br />
          5. “콘텐츠”라 함은 정보통신망이용촉진 및 정보보호 등에 관한 법률 제2조 제1항 제1호의 규정에 의한 정보통신망에서 사용되는 부호, 문자, 음성, 음향, 이미지 또는 영상 등으로 표현된 자료 또는 정보로서, 그 보존 및 이용에 있어서 효용을 높일 수 있도록 전자적 형태로 제작 또는 처리된 것을 말합니다.<br />
          6. “월정액 결제”라 함은 전2항의 “회원”이 일정 금액을 “회사”에 지불하고 이용할 수 있는 “콘텐츠” 서비스 또는 상품을 의미합니다.<br />
          7. "마일리지"라 함은 “회사”가 제공하는 티켓구매나 상품의 구매 등을 이용하기 위해 특정 결제수단 중 원하는 결제수단을 선택하여 현금처럼 지불할 수 있는 사이버머니를 말하며 티켓구매나 상품구매 시 구매 금액의 0.01%의 비율로 적립됩니다.<br />
          8. 본 조 제1항에서 정의된 용어 이외의 용어에 대해서는 관계 법령 및 서비스 별 이용기준에서 정의하는 바에 따릅니다.<br />
          <br />
          제3조 [신원정보 등의 제공]<br />
          “회사”는 이 약관의 내용, 상호, 대표자 성명, 영업소 소재지 주소(소비자의 불만을 처리할 수 있는 곳의 주소를 포함), 전화번호, 모사전송번호, 전자우편주소, 사업자등록번호, 통신판매업 신고번호 및 개인정보관리책임자 등을 이용자가 쉽게 알 수 있도록 온라인 서비스초기화면에 게시합니다. 다만, 약관은 이용자가 연결화면을 통하여 볼 수 있도록 할 수 있습니다.<br />
          <br />
          제4조 [약관의 게시 등]<br />
          1. “회사”는 이 약관을 “회원”이 그 전부를 인쇄할 수 있고 거래과정에서 해당 약관의 내용을 확인할 수 있도록 기술 조치를 취합니다.<br />
          2. “회사”는 “이용자”가 “회사”와 이 약관의 내용에 관하여 질의 및 응답할 수 있도록 기술적 장치를 설치합니다.<br />
          3. “회사”는 “이용자”가 약관에 동의하기에 앞서 약관에 정하여져 있는 내용 중 청약철회, 환불조건 등과 같은 중요한 내용을 이용자가 쉽게 이해할 수 있도록 별도의 연결화면 또는 팝업화면 등을 제공하여 “이용자”의 확인을 구합니다.<br />
          <br />
          제5조 [약관의 효력 및 개정]<br />
          1. 본 약관의 내용은 “회원”가입 시 게재되거나 공지된 내용에 “회원”이 동의함으로써 그 효력이 발생합니다.<br />
          2. “회사”는 『온라인 디지털 콘텐츠 산업 발전법』, 『약관의 규제 등에 관한 법률』, 『정보통신망 이용촉진 및 정보 보호 등에 관한 법률』, 『전자상거래 등에서의 소비자보호에 관한 법률』, 『소비자 보호법』, 『약관의 규제에 관한 법률』 등 관련법을 위배하지 않는 범위에서 필요 시 이 약관을 개정할 수 있으며, 약관을 변경할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 서비스 초기화면에 그 적용일자 7일(“회원”에게 불리한 변경 또는 중대한 사항의 변경은 30일) 이전부터 적용일자 이후 상당한 기간 동안 공지하고, 기존”회원”에게는 개정약관, 적용일자 및 변경사유(중요 내용에 대한 변경인 경우 이에 대한 설명을 포함)를 휴대폰 문자메시지 또는 전자우편주소로 발송합니다.<br />
          3. “회사”가 약관을 변경할 경우에는 변경 약관을 공지한 후 7일(“회원”에게 불리한 변경 또는 중대한 사항의 변경은 30일) 동안 변경약관의 적용에 대한 “회원”의 동의 여부를 확인합니다.<br />
          4. 회원은 개정된 약관에 대해 동의하지 않을 권리가 있으며, 개정된 약관에 동의하지 않을 경우 이용계약을 해지할 수 있습니다. 회원이 회사의 전항 단서에 따른 약관의 불리한 변경에 대하여 적용예정일까지 회사에게 부동의 의사를 표시하지 않거나 이용계약을 해지하지 않은 경우 변경된 약관을 승인한 것으로 봅니다.<br />
          <br />
          제6조 [약관의 해석 및 약관 외 사항에 대한 규정]<br />
          본 약관에 명시되지 않은 사항에 대해서는 『디지털 콘텐츠 산업 발전법』, 『전기통신기본법』, 『전기통신사업법 및 전자거래기본법』, 『전자상거래 등에서의 소비자보호에 관한 법률』, 『약관의 규제에 관한 법률』, 『디지털콘텐츠 이용자보호지침』 『공정거래법』등 관계법령 및 “회사”가 정한 서비스의 세부이용약관 및 정책 등에 의합니다.<br />
          <br />
          <br />
          제2장 서비스 이용 계약<br />
          <br />
          제7조 [이용신청, 승낙 및 거절, 계약의 성립]<br />
          1. “회원”이 되고자 희망하는 “이용자”는 약관의 내용에 대하여 동의를 하고 “회원”가입신청을 한 후 “회사”가 이러한 신청에 대하여 승낙함으로써 이용계약이 체결됩니다.<br />
          2. 이용신청자는 본 약관 동의와 함께 “회사”가 필요로 하는 정보를 정확히 작성하여 제출하고 “회사”는 이를 심사한 후 승낙함으로써 이용계약이 성립됩니다.<br />
          3. “회사”는 다음 각호의 어느 하나에 해당하는 사유가 있는 경우 “회원” 가입신청에 대하여 거절할 수 있습니다.<br />
          1. 이용신청에 필요한 사항을 정확하게 기재하지 않은 경우<br />
          2. 실명이 아니거나 타인의 명의를 이용한 경우(실명회원 가입의 경우)<br />
          3. 이용자 등록 사항을 누락하거나 오기하여 신청하는 경우<br />
          4. 사회의 안녕질서 또는 미풍양속을 저해하거나, 저해할 목적으로 신청한 경우<br />
          5. “회원” 자격을 상실한 자로서 “회사”가 정한 일정 가입보류 기간이 경과되지 아니한 자<br />
          6. 14세 미만의 자가 법정대리인의 동의를 얻지 않은 경우<br />
          7. 기술 및 설비의 미보유로 인하여 서비스 제공이 가능하지 않은 경우<br />
          8. 기타 이용신청자의 귀책에 의하여 승낙이 불가능하거나, “회사”가 별도로 정한 이용신청 기준에 부합되지 아니한 자<br />
          4. “회사”는 서비스 관련 설비의 여유가 없거나 기술상 또는 업무상 문제가 있는 경우에는 가입신청의 승낙을 유보하거나 거절할 수 있습니다.<br />
          5. 제3항 또는 제4항에 따라 회원가입 신청에 대하여 유보하거나 거절한 경우 “회사”는 이를 신청자에게 즉시 알려야 합니다. 다만, “회사”가 고의 또는 과실 없이 신청자에게 통지할 수 없는 경우에는 그러하지 않습니다.<br />
          <br />
          제8조 [미성년자의 “회원”가입에 관한 특칙]<br />
          1. 만 14세 미만의 “이용자”는 개인정보의 수집 및 이용목적에 대하여 충분히 숙지하고 부모 등 법정대리인의 동의를 얻은 후에 “회원”가입을 신청하고 본인의 개인정보를 제공하여야 합니다.<br />
          2. “회사”는 부모 등 법정대리인의 동의에 대한 확인절차를 거치지 않은 14세 미만 이용자에 대하여는 가입을 취소 또는 불허합니다.<br />
          3. 만 14세 미만 “이용자”의 부모 등 법정대리인은 아동에 대한 개인정보의 열람, 정정, 갱신을 요청하거나 “회원”가입에 대한 동의를 철회할 수 있으며, 이러한 경우에 “회사”는 지체 없이 필요한 조치를 취해야 합니다.<br />
          <br />
          제9조 [“회원” 정보의 수정 및 관리]<br />
          1. “회원”은 “회원”정보확인화면을 통해서 언제든지 개인정보를 열람하고 수정할 수 있으며, 이때 서비스 관리를 위해 필요한 실명 등은 수정이 불가합니다. 개명의 경우 실명 재인증을 통해 변경 가능합니다.<br />
          2. “회원”은 수시로 “회원”가입 신청 시 기재한 사항에 대하여 변경이 되었을 경우, 온라인에서 수정하거나 전자우편 등의 방법으로 “회사”에 그 사실을 알려야 합니다.<br />
          3. “회사”는 제2항에 따라 “회원”으로부터 변경사항을 통지 받은 경우 지체 없이 이에 따라 개인정보를 변경합니다.<br />
          4. “회사”가 제3항에 따라 변경하지 않음으로 인하여 발생한 “회원”의 손해에 대하여 배상합니다. 다만, “회사”가 고의 또는 과실이 없음을 증명한 경우에는 그러하지 않습니다.<br />
          5. “회사”는 “회원”이 제2항에 따라 수정하지 않거나 이의 변경사항을 “회사”에 알리자 않아 발생한 불이익에 대하여 책임을 지지 않습니다.<br />
          6. “회원”은 자신의 “아이디”와 “비밀번호”에 관하여 관리할 책임이 있으며, 제3자에게 이를 이용하도록 하여서는 안됩니다.<br />
          7. “회사”는 “회원”의 아이디가 개인정보유출 우려가 있거나, 반사회적 행위 및 미풍양속을 저해하거나 “회사” 및 “회사”의 운영자로 오인하게 할 수 있도록 사용되는 경우, 아이디의 이용을 제한 할 수 있습니다.<br />
          8. “회원”은 자신의 아이디가 도용되거나 제3자가 사용하고 있는 사실을 안 때에는 지체 없이 이를 “회사”에 통지하여야 하며, 통지하지 아니하거나 통지한 경우에도 “회사”의 정책에 따르지 않은 경우 발생한 결과에 대하여는 책임을 지지 않습니다.<br />
          <br />
          제10조 [“회원”에 대한 통지]<br />
          1. “회사”는 별도의 방법을 정하지 않는 한 “회원”에 대한 통지는 “회원”이 지정한 전자우편 또는 휴대폰 문자메세지의 방법에 의합니다.<br />
          <br />
          제11조 [“회원”탈퇴 및 자격 상실 등]<br />
          1. “회원”은 “회사”에 언제든지 탈퇴를 요청할 수 있으며 “회사”는 즉시 “회원”탈퇴를 처리합니다.<br />
          2. “회원”이 다음 각호의 사유에 해당하는 경우, “회사”는 “회원”자격을 제한 및 정지시킬 수 있습니다. 이 경우 “회사”는 “회원”에게 해당 사유를 통지합니다.<br />
          1. 가입신청 또는 “콘텐츠” 이용 신청 또는 변경 시 허위내용을 등록한 경우.<br />
          2. “회사”는 회원가입 후 3년 동안 서비스 사용이력이 없는 회원에 대해 사용의사를 묻는 고지를 하고, “회사”가 정한 기한 내에 답변 없는 경우.<br />
          3. 다른 사람이 “회사”의 서비스를 이용하는 것을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우.<br />
          4. “회사”가 금지한 정보(컴퓨터 프로그램 등)의 사용, 송신 또는 게시<br />
          5. “회사” 또는 제3자의 저작권 등 지식 재산권에 대한 침해.<br />
          6. “회사” 또는 제3자의 명예를 손상시키거나 업무를 방해하는 행위.<br />
          7. “회사”를 이용하여 법령 또는 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우<br />
          8. “회사”의 기술적 보호조치를 회피 혹은 무력화 하는 행위.<br />
          9. “회사”가 제공하는 서비스를 정상적인 용도 이외 또는 부당한 방법으로 서비스를 이용한 이력이 명백한 경우<br />
          10. “회사”의 영업상 방해를 목적으로 서비스를 비정상적으로 악용하는 행위가 명백한 경우<br />
          3. “회사”가 “회원”자격을 제한, 정지 시킨 후, 동일한 행위가 2회 이상 반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우 “회사”는 “회원”자격을 상실시킬 수 있습니다.<br />
          4. “회사”가 “회원”자격을 상실시키는 경우에는 “회원”등록을 말소합니다. 이 경우 “회원”에게 이를 통지하고, “회원”등록 말소 전에 최소한 30일 이상의 기간을 정하여 소명하고, 그 내용이 정당한 경우 “회사”는 즉시 “회원”의 자격을 복구합니다. 단, “회사”가 정한 소명기간 동안 소명치 않은 경우 회원 등록 말소 동의로 간주합니다.<br />
          5. “회사”는 “회원”이 탈퇴하거나 회원자격을 상실하는 경우 “회원”의 개인정보를 『정보통신망 이용촉진 및 정보보호 등에 관한 법률』 등 관계법령에서 정한 범위와 절차에 따라 파기합니다.<br />
          <br />
          제12조 [개인정보의 보호]<br />
          “회사”는 관련법령이 정하는 바에 따라서 “회원” 등록정보를 포함한 “회원”의 개인정보를 보호하기 위하여 노력합니다. “회원”의 개인정보보호에 관해서는 관련법령 및 “회사”가 정하는 “개인정보취급방침”에 정한 바에 따릅니다. 다만, “회사”의 사이트 이외에 단순 링크사이트, 제휴된 몰의 사이트 등의 경우에는 개인정보취급방침이 적용되지 아니합니다.<br />
          <br />
          <br />
          제3장 서비스 이용<br />
          <br />
          제13조 [서비스의 이용개시]<br />
          1. “회사”는 “회원”의 이용 신청을 승낙한 때부터 서비스를 개시합니다. 단, 월정액 결제의 경우 “회사”가 지정하는 수단으로 결제가 완료된 후 이용 가능합니다.<br />
          2. “회사”의 업무상 또는 기술상의 장애로 인하여 서비스를 개시하지 못하는 경우에는 사이트에 공지하거나 “회원”에게 이를 통지합니다.<br />
          <br />
          제14조 [월정액 결제의 이용]<br />
          1. “회원”이 본 약관에 동의한다는 의사표시로써 동의함을 선택함과 함께 “회사”에 대하여 월정액 결제 이용 신청을 하고 “회사”가 이를 승낙함으로써 월정액 결제에 대한 이용계약이 성립됩니다.<br />
          2. “회원”은“회사”가 지정하는 결제수단으로 월정액 결제를 이용할 수 있으며, 각종 프로모션이나 이벤트 등을 통하여 발행된 “쿠폰” 또는 “회사”가 발행한 상품권 등을 이용하여 월정액 결제를 이용할 수 있습니다.<br />
          3. 마일리지를 적립하는 것만으로는 월정액 결제”회원”이 되지 않으며, 정기결제에 가입하여야 월정액 결제가 됩니다.<br />
          4. 실명인증을 완료한 “회원”이 “회사”가 지정하는 결제수단으로 티켓이나 상품을 구매하여도 월정액 결제 회원의 자격이 부여되지는 않습니다.<br />
          5. “회사”는 마일리지 또는 “월정액 결제”를 이용하는 “회원”의 거래금액에 대하여 내부정책 및 외부 결제업체(은행사, 카드사 등), 기타 관련 법령의 변경에 따라 “회원” 당 월 누적 결제액, 결제한도 등의 거래한도를 정할 수 있으며, “회원”은 “회사”가 정한 거래한도를 초과하는 범위의 “월정액 결제”를 이용하고자 할 경우 거래한도의 초과로 인하여 “월정액 결제”의 추가 이용이 불가능할 수 있습니다.<br />
          6. 무상으로 제공하는 이벤트성 마일리지의 경우는 “회사”의 정책에 따라 일정 기간이 경과하면 자동으로 소멸될 수 있습니다.<br />
          7. 제공되는 상품 중 특정 기록이나 영상의 경우에는 각 판권소유자의 이용허락 여부에 따라 제공되는 시기가 각각 달라질 수 있습니다.<br />
          8. 무선 영상 스트리밍 또는 정보 이용시의 데이터량에 따른 요금은 “회사”가 별도로 청구하지 않으며, 가입하신 이동통신사가 청구합니다.<br />
          <br />
          제15조 [상품의 변경 및 추가]<br />
          “회사”는 서비스 정책 결정 또는 저작권신탁관리업자의 징수규정에 대한 문화체육관광부의 승인, 저작권리자의 이용허락의 거절 등의 이유로 제15조, 제16조, 제17조, 제18조, 제19조, 제20조의상품에 대하여 일정기간 공지를 통해서 상품이용 방법, 지원기기 범위, 이용가능 기기 수량 등의 월정액 결제 이용 방법을 변경하거나 해당 상품의 판매를 중단할 수 있으며, 이에 대해서는 “회사”는 “회원”에게 배상할 책임이 없습니다. 신규 상품을 추가할 수 있습니다.<br />
          <br />
          제16조 [결제수단 등]<br />
          1. “회원”이 결제를 위하여 이용할 수 있는 지불수단은 다음 각 호와 같습니다.<br />
          1. 선불카드, 직불카드, 신용카드 등의 각종 카드결제<br />
          2. 전화 또는 휴대전화 요금합산을 이용한 결제<br />
          3. 폰 뱅킹, 인터넷 뱅킹, 메일 뱅킹 등의 각종 계좌이체<br />
          4. “회사”와 계약을 맺었거나 “회사”가 인정한 상품권 또는 마일리지에 의한 결제<br />
          5. 기타 전자적 지급방법에 의한 대금지급 등<br />
          2. “회원”이 특정 결제 수단을 선택하여 결제하는 경우 개별 결제 수단을 제공하는 각각의 서비스를 통해 충전할 수 있는 한도도 각 서비스 제공사가 정하는 기준에 따르게 됩니다.<br />
          3. “회사”의 사정에 따라 특정 결제수단이 추가 되거나 서비스 제공이 중단될 수 있습니다.<br />
          4. “마일리지”는 “아이디(ID)”당 월 누적 적립액이 한정(“회사”의 정책에 따라 금액 단위가 달라질 수 있습니다)될 수 있으며, 이와는 별도로 “회원”이 선택한 결제수단에 따라 제한금액이 정해져 있습니다.<br />
          5. 결제수단과 함께 사용될 수 있는 마일리지 결제의 경우, 회사의 사정에 따라 제공기간과 방식이 변경될 수 있습니다.<br />
          <br />
          제17조 [마일리지 및 유효기간]<br />
          1. “회원”은 마일리지적립 시 “회사”에서 지정하는 상품의 결제에 의해 적립될 수 있으며 “회사”는 사정에 따라 적립되는 상품의 변경을 할 수 있습니다.<br />
          2. “회사”는 “회원”의 마일리지에 대한 금융이자 지급 의무가 없습니다.<br />
          3. “마일리지”는 “서비스”에서 지정한 구매 이용을 위한 지불수단으로 사용이 가능합니다.<br />
          4. “마일리지”의 적립은 당해년도 1월1일부터 가능하며, 당해년도 12월31일 자동 소멸됩니다.<br />
          5. “마일리지”는 회사의 사정에 따라 지정된 정액 할인권으로만 사용할 수 있습니다.<br />
          6. 무상으로 제공하는 이벤트성 마일리지의 경우는 회사의 정책에 따라 일정 기간이 경과하면 자동으로 소멸될 수 있습니다.<br />
          7. “회원”은 마일리지를 타인에게 양도하거나 타인으로부터 양도 받을 수 없습니다.<br />
          8. “회사”는 마일리지 또는 월정액 결제를 이용하는 “회원”의 거래금액에 대해서 “회사”는 거래한도를 정할 수 있습니다.<br />
          <br />
          제18조 [월정액결제 서비스 이용계약의 성립 및 승낙의 제한]<br />
          1. “회원”은 본 약관에 동의한다는 의사표시(동의함을 선택)와 함께 “회사”에 대하여 월정액 결제 이용 신청을 하고 “회사”가 이를 승낙함으로써 월정액결제에 대한 이용계약이 성립합니다.<br />
          2. “회사”는 계약 체결 전에 다음 각 호의 사항에 관하여 “회원”이 정확하게 이해하고 실수 또는 착오 없이 거래할 수 있도록 정보를 제공합니다.<br />
          1. 해당 월정액 결제의 내용, 가격, 이용기간, 이용방법, 청약철회 및 해지 조건 및 그 방법, 환불에 관한 사항 및 유료서비스의 선택과 관련한 사항<br />
          2. “콘텐츠”의 이용신청에 관한 확인 또는 “회사”의 확인에 대한 동의<br />
          3. 결제방법의 선택<br />
          3. “회사”는 “이용자”의 이용신청이 다음 각 호에 해당하는 경우에는 승낙하지 않거나 승낙을 유보할 수 있습니다.<br />
          1. 실명이 아니거나 타인의 명의를 이용한 경우<br />
          2. 허위의 정보를 기재하거나, “회사”가 제시하는 필수 내용을 기재하지 않은 경우<br />
          3. 미성년자가 청소년보호법에 의해서 이용이 금지되는 “콘텐츠”를 이용하고자 하는 경우<br />
          4. 서비스 관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우<br />
          4. “회사”의 승낙의 의사표시에는 “이용자”의 이용신청에 대한 확인 및 서비스제공 가능여부, 이용신청의 정정, 취소 등에 관한 정보 등을 포함합니다.<br />
          <br />
          제19조 [미성년자 이용계약에 관한 특칙]<br />
          “회사”는 만 19세 미만의 미성년이용자가 월정액 결제를 이용하고자 하는 경우에 부모 등 법정 대리인의 동의를 얻거나, 계약체결 후 추인을 얻지 않으면 미성년자 본인 또는 법정대리인이 그 계약을 취소할 수 있다는 내용을 계약체결 전에 고지하는 조치를 취합니다.<br />
          <br />
          제20조 [수신확인통지, 이용신청 변경 및 취소]<br />
          1. “회사”는 “회원”의 월정액 결제 이용신청이 있는 경우 “회원”에게 수신확인통지를 합니다.<br />
          2. 수신확인통지를 받은 “회원”은 의사표시의 불일치 등이 있는 경우에는 수신확인통지를 받은 후 즉시 이용신청 변경 및 취소를 요청할 수 있고, “회사”는 서비스제공 전에 “회원”의 요청이 있는 경우에는 지체 없이 그 요청에 따라 처리하여야 합니다. 다만, 이미 대금을 지불한 경우에는 청약철회 등에 관한 제19조의 규정에 따릅니다.<br />
          <br />
          제21조 [서비스의 이용시간]<br />
          1. 서비스의 이용은 연중무휴 1일 24시간을 원칙으로 합니다. 다만, “회사”의 업무상이나 기술상의 이유로 서비스가 일시 중지될 수 있고, 또한 운영상의 목적으로 “회사”가 정한 기간에는 서비스가 일시 중지될 수 있습니다. 이러한 경우 “회사”는 사전에 공지함이 원칙이나 불가피한 경우 사후에 공지할 수 있습니다.<br />
          2. 월정액 결제의 경우 서비스를 신청한 시점 또는 서비스별 별도 시작시점부터 이용이 가능합니다.<br />
          3. “회사”는 서비스를 일정범위로 분할하여 각 범위 별로 이용 가능한 시간을 별도로 정할 수 있거나 서비스별 이용 가능시간을 “회사”의 정책으로 정할 수 있습니다.<br />
          <br />
          제22조 [서비스의 변경 및 중지]<br />
          1. “회사”는 서비스에 대하여 보다 나은 서비스를 제공하기 위한 목적 또는 운영상, 기술상 불가피한 사유가 발생된 때에는 제공중인 서비스를 본 약관의 사항의 범주 내에서 전부 또는 일부 변경할 수 있으며, 변경될 서비스의 내용 및 시행일자를 일정기간 초기화면에 공개하도록 합니다.<br />
          2. “회사”는 다음 각 호에 해당하는 경우 서비스의 전부 또는 일부를 제한하거나 중지할 수 있으며, 그 내용을 사전에 “회원”에게 고지하거나, 사전 고지가 어려운 사항일 경우 사후에 고지할 수 있습니다.<br />
          1. 서비스용 설비의 보수 등 공사로 인한 부득이한 경우<br />
          2. 정기점검 등의 콘텐츠 서비스의 제공에 필요한 경우<br />
          3. 서비스 업그레이드 및 사이트 유지보수 등을 위해 필요한 경우<br />
          4. “회원”이 “회사”의 영업활동을 방해하는 경우<br />
          5. 정전, 제반 설비의 장애 또는 이용량의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 경우<br />
          6. 영상, 기록 등 각종 컨텐츠 등의 원 권자와의 계약 종료 등 서비스 제공을 위한 원천 데이터 확보가 어려운 경우<br />
          7. 기타 천재지변, 국가비상사태 등 불가항력적 사유가 있는 경우<br />
          <br />
          제23조 [서비스의 이용 및 저장]<br />
          1. “회사”는 서비스의 이용에 관하여 본 약관 외에 게시물 등의 관리정책을 별도로 둘 수 있으며, 이를 통해 게시물 또는 기타 게재된 내용이 서비스에 의해 보유되는 최대 일수 및 용량, “서비스” 서버에 할당되는 최대 디스크 공간 및 정해진 기간 동안 서비스에 접속할 수 있는 최대한의 수 (최대 기간) 등을 관리할 수 있습니다.<br />
          2. “회사”의 정책에도 불구하고 “회사”의 귀책사유 없이 전 제1항의 정책에 따라 서비스에 보관되는 게시물 또는 기타 게재된 내용 등을 삭제하거나 저장하지 못한데 대해 “회사”는 아무런 책임을 지지 않습니다.<br />
          3. “회사”는 본 이용약관에 따른 서비스의 제공지역을 대한민국으로 한정할 수 있으며, “회원” 또는 “이용자”가 유무선 인터넷을 이용하여 접속 가능한 지역은 대한민국 영토 내로 제한 받을 수 있습니다.<br />
          <br />
          제24조 [정보 및 광고의 제공]<br />
          1. “회사”는 “회원”의 서비스 이용과 관련하여 필요한 정보를 “서비스” 홈페이지에 게재하거나 우편물, e-mail 및 휴대폰 문자메시지 등을 통해 제공할 수 있습니다. 단, “회원”이 정보제공을 원치 않는다는 의사를 밝히는 경우 정보제공대상에서 해당 “회원”을 제외하며, 정보제공 대상에서 제외되어 정보를 제공받지 못해 불이익이 발생할 경우에는 “회사”는 이에 대한 책임을 지지 않습니다.<br />
          2. 이때 “회사”는 정보와 함께 특정 광고를 게재할 수 있으며, 광고 수신을 원하지 않을 경우 수신거부를 할 수 있습니다. 그러나 “회원”이 광고를 통한 제3의 서비스를 이용하는 것과 관련하여 발생하는 모든 문제에 대해서 “회사”는 어떠한 책임도 지지 않습니다.<br />
          <br />
          <br />
          제4장 계약당사자의 의무<br />
          <br />
          제25조 [회사의 의무]<br />
          1. “회사”는 “회원”의 개인정보를 보호하고 보안시스템을 구비하여 “회원”이 안전하게 서비스를 이용할 수 있도록 제반 환경을 제공합니다.<br />
          2. “회사”는 제 ①항의 범위 내에서 업무와 관련하여 “회원”의 사전 동의 없이 “회원”전체 또는 일부의 개인정보에 관한 통계자료를 작성하여 이를 사용할 수 있고, 이를 위하여 “회원”의 컴퓨터에 쿠키를 전송할 수 있습니다. 이 경우 “회원”은 쿠키의 수신을 거부하거나 쿠키의 수신에 대하여 경고하도록 사용하는 컴퓨터의 브라우저의 설정을 변경할 수 있습니다.<br />
          3. “회사”는 서비스와 관련한 “회원”의 불만사항이 접수되는 경우 이를 신속하게 처리하여야 하며, 신속한 처리가 곤란한 경우 그 사유와 처리 일정을 서비스 화면에 게재하거나 전자우편 등을 통하여 동 “회원”에게 통지합니다.<br />
          4. “회사”는 『정보통신망이용척진 및 정보보호에 관한 법률』, 『통신비밀보호법』, 『전기통신사업법』 등 서비스의 운영, 유지와 관련 있는 법규를 준수합니다.<br />
          <br />
          제26조 [회원의 의무]<br />
          1. ① “회원”은 다음 각 호의 행위를 하여서는 아니 됩니다.<br />
          1. 이용 신청 및 서비스를 제공받기 위해 등록 양식에서 요구되는 정보를 허위 또는 타인의 명의로 작성하거나, 다른 “회원”의 ID 및 비밀번호를 도용, 부정하게 사용하는 행위<br />
          2. “회사”의 서비스 정보를 이용하여 얻은 정보를 “회사”의 사전 승낙 없이 복제 또는 유통 시키거나 상업적으로 이용하는 행위<br />
          3. 타인의 명예를 손상시키거나 불이익을 주는 행위<br />
          4. 음란물을 게재, 공개 하거나 음란사이트를 연결(링크)하는 행위<br />
          5. “회사” 및 제3자의 지적재산권 등 기타 권리를 침해하는 행위<br />
          6. 공공질서 및 미풍양속에 위반되는 내용의 정보, 문장, 도형, 음성 등을 타인에게 유포하는 행위<br />
          7. 서비스와 관련된 설비의 오 동작이나 정보 등의 파괴 및 혼란을 유발시키는 컴퓨터 바이러스 감염자료를 등록 또는 유포하는 행위<br />
          8. 서비스 운영을 고의로 방해하거나 서비스의 안정적 운영을 방해할 수 있는 정보 및 수신자의 의사에 반하여 광고성 정보를 전송하는 행위<br />
          9. 타인으로 가장하는 행위 및 타인과의 관계를 허위로 명시하는 행위<br />
          10. 다른 회원의 개인정보를 수집, 저장, 공개하는 행위<br />
          11. 자기 또는 타인에게 재산상의 이익을 주거나 타인에게 손해를 가할 목적으로 허위의 정보를 유통시키는 행위<br />
          12. 재물을 걸고 도박하거나 사행행위를 하는 행위<br />
          13. 윤락행위를 알선하거나 음행을 매개하는 내용의 정보를 유통시키는 행위<br />
          14. 수치심이나 혐오감 또는 공포심을 일으키는 말이나 음향, 글이나 화상 또는 영상을 계속하여 상대방에게 도달하게 하여 상대방의 일상적 생활을 방해하는 행위<br />
          15. 서비스에 게시된 정보를 변경하는 행위<br />
          16. 관련 법령에 의하여 그 전송 또는 게시가 금지되는 정보(컴퓨터 프로그램 포함)의 전송 또는 게시 행위<br />
          17. 회사의 직원이나 운영자를 가장하거나 사칭하여 또는 타인의 명의를 도용하여 글을 게시하거나 메일을 발송하는 행위<br />
          18. 컴퓨터 소프트웨어, 하드웨어, 전기통신 장비의 정상적인 가동을 방해, 파괴할 목적으로 고안된 소프트웨어 바이러스, 기타 다른 컴퓨터 코드, 파일, 프로그램을 포함하고 있는 자료를 게시하거나 e-mail으로 발송하는 행위<br />
          19. 사이트를 이용하여 구입한 재화 용역 등의 대금, 기타 사이트 이용에 관련하여 회원이 부담하는 채무를 기일에 지급하지 않는 행위<br />
          20. 다른 사람의 사이트 이용을 방해하거나 그 정보를 도용하는 등 전자거래 질서를 위협하는 행위<br />
          21. “회사”에서 공식적으로 인정한 경우를 제외한 서비스를 이용하여 상품을 판매하는 영업 활동 및 해킹, 영리를 목적으로 하는 광고행위, 음란사이트를 통한 상업행위, 상용소프트웨어 불법배포 행위<br />
          22. 법률에 의하거나 계약상 또는 위임에 의하여 전송할 수 있는 권리가 없는 내용을 게시, 게재, 전자메일 또는 기타의 방법으로 전송하거나 공개하는 행위<br />
          23. 타인을 "스톡(stalk)"하거나 달리 괴롭히는 행위 또는 다른 “회원”에 대한 개인정보를 수집 또는 저장하는 행위<br />
          24. 기타 불법적이거나 부당한 행위<br />
          2. “회원”은 관계 법령, 본 약관의 규정, 이용안내 및 서비스상에 공지한 주의사항, “회사”가 통지하는 사항 등을 준수하여야 하며, 기타 “회사”의 업무에 방해되는 행위를 하여서는 아니 됩니다.<br />
          3. “회원”의 본 조 위반이 있을 경우 “회사”는 “회원”의 계정을 중지하거나 해지할 수 있으며, 현재 또는 장래의 모든 또는 일부의 서비스 이용을 거절 할 수 있습니다. 또한 본 조의 위반으로 인해 발생된 “회원”의 관계기관에 의한 구속 등 법적 조치 등에 관해서는 “회사”가 책임을 지지 않으며, “회원”은 이와 같은 행위와 관련하여 “회사”에 손해를 끼쳤을 경우 “회사”에 대하여 손해배상 의무를 집니다.<br />
          <br />
          제27조 [지적재산권]<br />
          1. “서비스”에 게재되거나 공개, 제출된 모든 자료(“회원”간 전달 포함)에 대한 소유권은 “회원”과 “회사”가 별도로 정하지 않는 한 공개자 또는 게시자에게 있습니다. 단, “회사”는 원활한 서비스를 위하여 게시물 관리정책 또는 『정보통신망이용촉진 및 정보보호에 관한 법률』, 『청소년 보호법』, 『저작권법』 등의 법률에 따라 일정한 기간 이후에는 공개자 또는 게시자의 동의 없이 게재된 내용을 삭제할 수 있습니다.<br />
          2. “회원”이 이용계약을 해지하거나 적법한 사유로 이용계약이 해지 된 경우 “회원” 게시물의 권리는 “회사”에 귀속됩니다.<br />
          3. “회원”은 관련법령의 규정에서 별도로 정하지 않는 한 “서비스”에 공개적으로 게재하거나 제출한 자료에 대해 “회사”에게 비독점적이고 재라이센스 할 수 있는 권한을 부여 하는 것으로 간주 합니다.<br />
          4. “회원”은 서비스를 이용하여 얻은 정보를 가공, 판매하는 행위 등 서비스에 게재된 자료를 영리목적으로 이용하거나 제3자에게 이용하게 할 수 없으며, 공개물 또는 게시물에 대한 저작권 침해는 관계 법령의 적용을 받습니다.<br />
          5. “회원”은 “회원”이 제출한 내용으로 인하여 지적재산권 분쟁이 발생한 경우 “회사” 및 관계자를 면책 하고 해를 입지 않도록 할 것을 동의합니다.<br />
          6. 본 서비스 또는 서비스와 관련된 소프트웨어는 관련 지적재산권 및 기타 법률에 의해 보호되며, 후원 광고에 포함되어 있는 내용이나 본 서비스 또는 광고주를 통하여 “회원”에게 제공 되는 정보는 저작권, 상표권, 특허 등 제반 지적재산권 관련 법규에 의해 보호됩니다.<br />
          7. “서비스”는 “회원”에게 한 대의 컴퓨터에서 소프트웨어의 오브젝트 코드를 사용할 수 있는 개인적이고, 양도불가능하며, 비독점적인 권리 및 사용권을 부여합니다. 단, “회원”은 소프트웨어를 복사, 수정, 창작물의 제작, 리버스 엔지니어, 리버스 어셈블 또는 소스코드를 발견하려고 시도하거나, 판매, 양도, 재라이센스, 담보권을 설정하거나 소프트웨어상의 어떠한 권리를 달리 양도하여서는 안되며, 제3자로 하여금 위와 같은 행위를 하도록 허락하여서도 안됩니다.<br />
          <br />
          제28조 [게시물의 관리]<br />
          1. “회사”는 제25조 제1항 각 호에 해당하는 게시물이나 자료를 사전통지 없이 삭제하거나 이동 또는 등록 거부를 할 수 있으며, 이에 대해 “회사”는 어떠한 책임도 지지 않습니다. 또한, 그 게시물의 양과 성격에 따라 서비스 사용 중지 또는 “회원”자격의 제한을 둘 수 있습니다.<br />
          2. “회사”는 게시판에 『정보통신망이용촉진 및 정보보호에 관한 법률』을 위반한 청소년 유해 매체물이 게시되어 있는 경우에는 이를 지체 없이 삭제 합니다. 다만, 만 19세 이상의 “이용자”만 이용할 수 있는 게시판은 예외로 합니다.<br />
          3. “회사”가 운영하는 게시판 등에 게시된 정보로 인하여 법률상 이익이 침해된 자는 “회사”에게 당해 정보의 삭제 또는 반박내용의 게재를 요청할 수 있습니다. 이 경우 “회사”는 지체 없이 필요한 조치를 취하고 이를 즉시 신청인에게 통지합니다.<br />
          4. “회사”는 게시물에 관련된 세부 이용지침을 별도로 정하여 시행할 수 있으며, “회원”은 그 지침에 따라 각 종 게시물(“회원”간 전달 포함)을 등록하거나 삭제하여야 합니다.<br />
          5. “회원”이 게시하였던 게시물은 자동 삭제 되지 않으므로 삭제를 원할 경우 탈퇴 이전에 삭제하여야 하며, “회사”는 “회원”이 이용계약을 해지하거나 적법한 사유로 이용계약이 해지 된 경우 “회원”의 게시물을 삭제 또는 별도 관리 할 수 있습니다.<br />
          6. “회원”의 게시물이 『정보통신망법 및 저작권법 등 관련 법령』에 위반 되는 내용을 포함할 경우, 해당 권리자는 관련 법령이 정한 절차에 의거하여 해당 게시물의 게시 중단 및 삭제 등을 “회사”에 요청 할 수 있으며, “회사”는 관련 법령에 따른 조치를 취하여야 합니다.<br />
          <br />
          <br />
          제5장 계약해지 및 기타<br />
          <br />
          제29조 [월정액 결제 이용에 관한 해제 및 해지]<br />
          1. “회사”와 “콘텐츠”의 이용에 관한 월정액 결제를 구매한 “이용자”는 구매한 날로부터 7일 이내에는 청약의 철회를 할 수 있습니다. 다만, “회사”가 다음 각 호중 하나의 조치를 취한 경우에는 “이용자”의 청약 철회권이 제한될 수 있습니다.<br />
          1. 청약의 철회가 불가능한 “콘텐츠”에 대한 사실을 표시사항에 포함한 경우<br />
          2. “이용자”가 “회사”가 판단하는 악의적인 목적으로 구매와 취소를 반복하는 경우<br />
          3. 한시적 또는 일부 이용 등의 방법을 제공한 경우<br />
          2. “이용자”는 다음 각 호의 사유가 있을 때에는 당해 “콘텐츠”를 공급받은 날로부터 7일 이내에 월정액 결제를 해제, 해지할 수 있습니다.<br />
          1. 이용계약에서 약정한 “콘텐츠”가 제공되지 않는 경우<br />
          2. 제공되는 “콘텐츠”가 표시,광고 등과 상이하거나 현저한 차이가 있는 경우<br />
          3. 기타 “콘텐츠”의 결함으로 정상적인 이용이 현저히 불가능한 경우<br />
          3. 전1항 내지 전2항의 경우에 “회원”은 언제든지 “고객센터”, “개인정보관리 페이지” 등을 통해서 이용계약을 해지 신청할 수 있으며 “회사”는 관계법령 등에서 정하는 절차에 따라 이를 신속하게 처리합니다.<br />
          4. “회사”는 제3항에 따라 “회원”이 표시한 계약해제, 해지의 의사표시를 수신한 후 지체 없이 이러한 사실을 “회원”에게 회신합니다.<br />
          5. “회원”은 제2항 2호의 사유로 계약해제, 해지의 의사표시를 하기 전에 상당한 기간을 정하여 서비스이용의 하자에 대한 치유를 요구할 수 있습니다. 다만, “회사”가 하자의 치유가 불가능하다고 판단하는 경우에는 즉시 그 사실을 “회원”에게 통지하고 이용계약을 해제, 해지할 수 있습니다.<br />
          6. “회사”는 “회원”이 제26조에 규정한 “회원”의 의무를 이행하지 않을 경우 사전 통지 없이 본 “서비스” 이용 계약을 해지하거나 또는 월정액 결제 이용을 중지 시킬 수 있습니다.<br />
          7. “회원” 또는 “회사”가 월정액 결제에 대한 이용계약을 해지할 경우 관련법령 및 개인정보취급방침에서 정하는 바에 따라 일정 기간 저장된 후 파기됩니다.<br />
          <br />
          제30조 [“이용자”의 청약철회와 계약해제,해지의 효과]<br />
          1. “회사”는 ”회원”이 청약철회의 의사표시를 한 날로부터 또는 ”회원”에게 계약해제,해지의 의사표시에 대하여 회신한 날로부터 3영업일 이내에 대금의 결제와 동일한 방법으로 이를 환불하여야 하며, 동일한 방법으로 환불이 불가능할 때에는 이를 사전에 고지하여야 합니다. 다만, 수납확인이 필요한 결제수단의 경우에는 수납확인일로부터 3영업일 이내에 이를 환불하도록 합니다.<br />
          2. “회사”가 제1항에 따라 ”회원”이 구매한 상품을 중도 해지하여 환불할 경우, “회사”는 “회원이 구매한 대금에서 “컨텐츠”를 이용한 기간 등 서비스 또는 상품을 통하여 얻은 이익에 해당하는 금액을 차감 후 환불 합니다.<br />
          3. “회사”는 위 대금을 환불함에 있어 ”회원”이 신용카드 또는 전자화폐 등으로 결제를 한 때에는 지체 없이 해당 결제수단을 제공한 사업자로 하여금 재화 등의 대금의 청구를 정지 또는 취소하도록 요청합니다. 다만, 제2항의 금액공제가 필요한 경우에는 그러하지 아니할 수 있습니다.<br />
          4. 계약해제, 해지 요청에 따라 각 결제수단별로 다음 각 호의 방법에 따라 환불하며, 단, 결제수단별 취소가능 기간, 취소 방법 또는 취소 후 조치방법에 차이가 있을 수 있습니다.<br />
          1. 무통장입금의 경우 은행송금수수료를 공제한 잔액을 현금으로 반환합니다.<br />
          2. 휴대폰결제의 경우는 결제한 해당 월내에만 결제 취소 가능합니다.<br />
          3. 각 결제 수단별로 취소 가능 기간이 지난 경우 현금 환불 시에는 환불수수료(10% 또는 1,000원 중 큰 금액)를 공제한 잔액을 현금으로 반환하며, 환불 금액이 1,000원 미만인 경우와 무상으로 지급된 건은 환불되지 않습니다.<br />
          4. 그 외의 결제수단은 각 결제수단별 결제취소 절차에 따릅니다.<br />
          5. “회원”은 회원 탈퇴시 잔여 마일리지는 탈퇴신청 전에 소진하여야 하며 회원탈퇴 후에는 자동으로 소멸되어 환불신청이 불가능합니다.<br />
          6. “회원”이 제11조 제2항에 해당하는 행위를 하였을 경우, “회사”가 계약을 해제 또는 해지하거나 서비스 이용을 제한 한 후 환불해야 할 금액이 있을 경우에는 본 조 제3항에서 정한 환불 기준을 적용하여 공제하고 환불합니다. 이 경우 “회원”은 해당 조치에 대하여 “회사”가 정한 절차에 따라 이의 신청을 할 수 있으며, “회사”는 정당하다고 인정하는 경우 즉시 서비스 이용 재개 등을 합니다.<br />
          7. 시스템상의 장애로 인해 결제 후 월정액 결제 이용이 안되는 경우, “회사”는 결제를 취소하거나 결제수단에 따라 결제취소가 불가능한 경우 “이용자”에게 공지하고 대금의 결제와 동일한 방법으로 환불을 하여야 합니다.<br />
          8. “회사”는 “콘텐츠” 등의 대금을 지급 받은 자” 또는 “이용자와 콘텐츠이용계약을 체결한 자“가 동일인이 아닌 경우에 각자는 청약철회 또는 계약해제,해지로 인한 대금환급과 관련한 의무의 이행에 있어서 연대하여 책임을 집니다.<br />
          9. “회사”는 ”회원”에게 청약철회를 이유로 위약금 또는 손해배상을 청구하지 않으며, ”회원”의 계약 해제,해지는 손해배상의 청구에 영향을 미치지 않습니다. 단, 이용기간을 약정한 상품에 대한 해지에 대해서는 위약금이 청구될 수 있습니다.<br />
          <br />
          제31조 [“회사”의 계약해제,해지 및 이용제한]<br />
          1. “회사”는 ”회원”이 제11조 제2항에서 정한 행위를 하였을 경우 사전통지 없이 계약을 해제,해지하거나 또는 기간을 정하여 서비스 이용을 제한할 수 있습니다.<br />
          2. 제1항의 해제,해지는 “회사”가 자신이 정한 통지방법에 따라 ”회원”에게 그 의사를 표시한 때에 효력이 발생합니다.<br />
          3. “회사”의 해제,해지 및 이용제한에 대하여 ”회원”은 “회사”가 정한 절차에 따라 이의 신청을 할 수 있습니다. 이 때 이의가 정당하다고 “회사”가 인정하는 경우, “회사”는 즉시 서비스의 이용을 재개합니다.<br />
          <br />
          제32조 [“회사”의 계약해제,해지의 효과]<br />
          ”회원”의 귀책사유에 따른 이용계약의 해제, 해지의 효과는 제29조를 준용합니다. 다만, “회사”는 “이용자”에 대하여 계약 해제,해지의 의사표시를 한 날로부터 7영업일 이내에 대금의 결제와 동일한 방법으로 이를 환급합니다.<br />
          <br />
          제33조 [과오금]<br />
          1. “회사”는 과오금이 발생한 경우 이용대금의 결제와 동일한 방법으로 과오금 전액을 환불하여야 합니다. 다만, 동일한 방법으로 환불이 불가능할 때는 이를 사전에 고지합니다.<br />
          2. “회사”의 책임 있는 사유로 과오금이 발생한 경우 “회사”는 계약비용, 수수료 등에 관계없이 과오금 전액을 환불합니다. 다만, “이용자”의 책임 있는 사유로 과오금이 발생한 경우, “회사”가 과오금을 환불하는 데 소요되는 비용은 합리적인 범위 내에서 “이용자”가 부담하여야 합니다.<br />
          3. “이용자”는 월정액 결제 이용 대금이 적게 과금 된 경우 “회사”는 “이용자”와의 상호 합의하에 차액을 요구할 수 있습니다.<br />
          4. “회사”는 “이용자”가 주장하는 과오금에 대해 환불을 거부할 경우에 정당하게 이용대금이 부과되었음을 입증할 책임을 집니다.<br />
          5. “회사”는 과오금의 환불절차를 『디지털콘텐츠 이용자보호지침』에 따라 처리합니다.<br />
          <br />
          제34조 [콘텐츠하자 등에 의한 이용자피해보상]<br />
          “회사”는 콘텐츠하자 등에 의한 이용자피해보상의 기준,범위,방법 및 절차에 관한 사항을 『디지털콘텐츠 이용자보호지침』에 따라 처리합니다.<br />
          <br />
          제35조 [보증의 부인]<br />
          1. 본 서비스를 이용하여 어떠한 자료를 다운로드 또는 스트리밍하는 행위는 “회원”의 판단 및 위험에 의해 행하여지고, 그러한 자료와 다운로드로 인해 초래되는 컴퓨터 시스템에 대한 손해 및 데이터 손실에 대해서는 “회원”이 전적으로 책임을 부담합니다.<br />
          2. “회원”이 본 서비스를 통하여 취득한 구두 또는 문서상의 모든 충고와 정보에 대하여서는 본 약관에 명시적으로 규정되어 있지 않은 어떠한 보증도 제공되지 않습니다.<br />
          <br />
          제36조 [양도 금지]<br />
          “회원”은 서비스의 이용권한, 기타 이용 계약상 지위를 타인에게 양도, 증여할 수 없으며 게시물 등 모든 형태의 공개자료에 대한 저작권을 포함한 모든 권리 및 책임은 이를 공개한 “회원”에게 있습니다.<br />
          <br />
          <br />
          제6장 손해배상 등<br />
          <br />
          제37조 [손해배상]<br />
          1. 본 서비스는 “회원”의 편의를 위하여 제공하는 서비스로, 본 서비스와 관련된 부과금액 등 이용요금이 무료인 동안에는 본 서비스 이용과 관련하여 발생한 어떠한 손해에 대하여도 “회사”가 책임을 지지 않습니다.<br />
          2. “회원”이 본 약관을 위반함으로 인하여 “회사”에 손해가 발생하게 되는 경우, 이 약관을 위반한 “회원”은 “회사”에 발생하는 모든 손해를 배상하여야 합니다.<br />
          3. “회원”이 서비스를 이용함에 있어 행한 불법행위나 본 약관 위반행위로 인하여 “회사”가 당해 “회원” 이외의 제3자로부터 손해배상 청구 또는 소송을 비롯한 각종 이의제기를 받는 경우 당해 “회원”은 자신의 책임과 비용으로 “회사”를 면책 시켜야 하며, “회사”가 면책되지 못한 경우 당해 “회원”은 그로 인하여 “회사”에 발생한 모든 손해를 배상하여야 합니다.<br />
          4. 회사의 마일리지 및 월정액 결제 제공과 관련하여 회사의 귀책사유로 인하여 회원이 서비스 이용에 손해를 입은 경우, 회원은 마일리지 재충전 등 합당하고 적절한 보상을 받을 수 있습니다.<br />
          <br />
          제38조 [면책사항]<br />
          1. “회사”는 다음 각 호의 1에 해당하는 사유로 인하여 “회원” 또는 제3자에게 발생한 손해에 대하여는 그 책임을 지지 아니합니다.<br />
          1. 천재지변 또는 이에 준하는 불가항력으로 인해 서비스를 제공할 수 없는 경우<br />
          2. “회원”이 자신의 ID 또는 비밀번호 등의 관리를 소홀히 하는 등 타인의 부정사용을 방치한 경우<br />
          3. “회원”이 제3자의 ID, 비밀번호, 휴대폰, 계좌, 신용카드 번호 등 개인정보를 도용하여 제3자에게 손해를 발생시킨 경우<br />
          4. “회사”의 관리 영역이 아닌 공중통신선로의 장애로 서비스 이용이 불가능한 경우<br />
          5. 기타 “회사”의 귀책 사유가 없는 통신서비스 등의 장애로 인한 경우<br />
          2. “회사”는 “회원”이 서비스를 이용하여 기대하는 수익을 상실한 것에 대하여 책임을 지지 않으며 그 밖에 서비스를 통하여 얻은 자료로 인한 손해 등에 대하여도 책임을 지지 않습니다.<br />
          3. “회사”는 “회원”이 서비스에 게재한 정보, 자료, 사실의 신뢰도, 정확성 등 내용에 관하여는 책임을 지지 않습니다.<br />
          4. “회사”는 서비스 이용과 관련하여 “회원”에게 발생한 손해 가운데 “회원”의 고의, 과실에 의한 손해에 대하여 책임을 지지 않습니다.<br />
          5. “회사”는 “회원” 상호간 또는 “회원”과 제3자 상호간에 서비스를 매개로 발생한 분쟁에 대해서는 개입할 의무가 없으며 이로 인한 손해를 배상할 책임도 없습니다.<br />
          <br />
          제47조 [관할법원]<br />
          1. 서비스 이용과 관련하여 “회사”와 “회원” 사이에 분쟁이 발생한 경우, 쌍방간에 분쟁의 해결을 위해 성실히 협의한 후가 아니면 제소할 수 없습니다.<br />
          2. 본 조 제1항의 협의에서도 분쟁이 해결되지 않을 경우 ”회사”와 “회원”간의 “콘텐츠” 이용계약에 관한 『민사소송법』상의 관할법원에 소를 제기할 수 있다.<br />
          <br />
          부칙 (시행일)<br />
          본 약관은 2014년 9월 1일부터 적용합니다.
      </p> */}
        {serviceLog20240304.map((item, idx) => (
          <p key={idx}>{item}</p>
        ))}
      </div>
      <button
        className="btn_popup_close"
        onClick={() => {
          onClose(false);
        }}
      >
        닫기
      </button>
      {/* <span className="back_arrow"></span> */}
    </div>
  );
};

export default Use;
