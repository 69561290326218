import React from 'react'
import Wizpark5GStadiumStadium from '../../components/wizpark/Wizpark5GStadiumStadium'
import ContentTemplate from '../../components/common/ContentTemplate'
import SubContentTemplate from '../../components/common/SubContentTemplate'
import PageHeaderContainer from '../../containers/common/PageHeaderContainer'
import PageTitleTemplate from '../../components/common/PageTitleTemplate'
import BreadcrumbContainer from '../../containers/common/BreadcrumbContainer'
import PageTemplate from '../../components/common/PageTemplate'
import { WIZPARK_STADIUM } from '../../constants/page'

const Wizpark5GStadiumStadiumPage = () => {
  return (
    <PageTemplate className="park">
      <PageHeaderContainer title="5G 스타디움 소개" subtitle="새롭게 변화된 5G 스타디움을 소개합니다." image={require('../../assets/img/park/sub_tit_park.png')} />
      <ContentTemplate>
        <PageTitleTemplate>
          <BreadcrumbContainer page={WIZPARK_STADIUM} />
        </PageTitleTemplate>
        <SubContentTemplate className="park stadium">
          <Wizpark5GStadiumStadium />
        </SubContentTemplate>
      </ContentTemplate>
    </PageTemplate>
  )
}

export default Wizpark5GStadiumStadiumPage
