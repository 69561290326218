import client from "../client";
import { IDENTIFY_TYPE } from "../../constants/enum";

/**
 * 인증요청 API
 * @param {*} simpleId
 * @param {*} niceData
 */

export const callDormantUser = async ({ type, data, simpleId }) => {
  if (type && data && simpleId) {
    const urlType = type === IDENTIFY_TYPE.MOBILE ? "phone" : "ipin";
    const encodedData = encodeURIComponent(data);

    const result = await client
      .get(
        `/api/v2/user/awake/${urlType}?simpleId=${simpleId}&niceData=${encodedData}`
      )
      .then((res) => {
        if (res.data) {
          return res.data.status;
        }
      })
      .catch((e) => console.error(e));

    if (result === 0) {
      return true;
    } else {
      return false;
    }
  }
};

export const callNormalUser = async ({ type, data, simpleId }) => {
  if (type && data && simpleId) {
    const urlType = type === IDENTIFY_TYPE.MOBILE ? "phone" : "ipin";
    const encodedData = encodeURIComponent(data);

    const result = await client
      .get(
        `/api/v2/user/children/re-cert/${urlType}?simpleId=${simpleId}&niceData=${encodedData}`
      )
      .then((res) => {
        if (res.data) {
          return res.data.status;
        }
      })
      .catch((e) => console.error(e));

    if (result === 0) {
      return true;
    } else {
      return false;
    }
  }
};
