import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { confirmPassword } from '../../modules/mypage/myinfo'
import PasswordConfirm from '../../components/mypage/PasswordConfirm'
import { useHistory } from 'react-router-dom'
import { getLoginUser } from '../../modules/utils/auth'

const MyInfoContainer = () => {

  const dispatch = useDispatch()
  const history = useHistory()
  const login = useSelector(state => state.login)
  const myInfo = useSelector(state => state.myInfo)

  const onConfirmPassword = (user) => dispatch(confirmPassword(user))

  useEffect(() => {
    if (login.user == null || login.user.tid == null) {
      if (window.confirm('로그인이 필요한 서비스 입니다.\n로그인 하시겠습니까?')) {
        window.location.assign('/login');
      } else {
        window.location.assign('/');
      }
    }

    const loginUser = getLoginUser()
    if (loginUser.confirm === true) {
      history.push('/mypage')
    }
  }, [dispatch, myInfo.memberPwConfirm])

  return (<PasswordConfirm login={login} onConfirmPassword={onConfirmPassword} />);
}

export default MyInfoContainer
