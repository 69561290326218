import React from 'react'
import ContentTemplate from '../../components/common/ContentTemplate'
import SubContentTemplate from '../../components/common/SubContentTemplate'
import PageHeaderContainer from '../../containers/common/PageHeaderContainer'
import PageTitleTemplate from '../../components/common/PageTitleTemplate'
import BreadcrumbContainer from '../../containers/common/BreadcrumbContainer'
import PageTemplate from '../../components/common/PageTemplate'
import { FUTURES_LEAGUE_BOXSCORE, FUTURES_LEAGUE_TAB } from '../../constants/page'
import FuturesLeagueBoxScoreContainer from '../../containers/game/FuturesLeagueBoxScoreContainer'

// const tabs = [
//   { title: '경기 일정', link: '/ktwiz/about', isActive: true },
//   { title: '박스스코어', link: '/ktwiz/history', isActive: false },
//   { title: '순위기록', link: '/ktwiz/history', isActive: false },
//   { title: '관전포인트', link: '/ktwiz/history', isActive: false },
// ]

const FuturesLeagueBoxScorePage = ({ match }) => {
  return (
    <PageTemplate className="game">
      <PageHeaderContainer title="퓨처스 리그" subtitle="박스스코어 정보를 알려 드립니다." image={require('../../assets/img/game/sub_tit_game.png')} forwardTabs={FUTURES_LEAGUE_TAB} currentPath={FUTURES_LEAGUE_BOXSCORE.path} />
      <ContentTemplate className="game_boxscore">
        <PageTitleTemplate>
          <BreadcrumbContainer page={FUTURES_LEAGUE_BOXSCORE} />
        </PageTitleTemplate>
        <SubContentTemplate>
          <FuturesLeagueBoxScoreContainer match={match} />
        </SubContentTemplate>
      </ContentTemplate>
    </PageTemplate>
  )
}

export default FuturesLeagueBoxScorePage
