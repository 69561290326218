import React from 'react'
import FindIdContainer from '../../containers/auth/FindIdContainer'
import PageTemplate from '../../components/common/PageTemplate'
import PageHeaderContainer from '../../containers/common/PageHeaderContainer'
import ContentTemplate from '../../components/common/ContentTemplate'
import PageTitleTemplate from '../../components/common/PageTitleTemplate'
import Breadcrumb from '../../components/common/Breadcrumb'
import { FINDID_PAGE } from '../../constants/page'

const FindIdPage = () => {
  return (
    <PageTemplate className='member'>
      <PageHeaderContainer title={FINDID_PAGE.title} image={require('../../assets/img/login/sub_tit_membership.png')} />
      <ContentTemplate>
        <PageTitleTemplate>
          <Breadcrumb page={FINDID_PAGE} />
        </PageTitleTemplate>
        <FindIdContainer />
      </ContentTemplate>
    </PageTemplate>
  )
}

export default FindIdPage
