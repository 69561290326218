import React from 'react';

//<TextSearchForm options={options} option={option} text={text} onSubmit={handleTextSubmit} />
export default function TextSearchForm({ options, option, text, onSubmit }) {
  const [search, setSearch] = React.useState({ option, text });

  const handleSubmit = e => {
    e.preventDefault();
    console.log('Submitted TextSearchForm: ', search);
    onSubmit(search.option, search.text);
  };

  const handleChange = e => {
    setSearch({
      ...search,
      [e.target.name]: e.target.value
    });
    console.log('TextSearchForm.handleChange', search);
  };

  return (
    <form action='' method='' name='search_frm' onSubmit={handleSubmit}>
      <fieldset>
        <legend className='blind'>게시물 검색</legend>
        <div className='searchbox clearfix'>
          <div>
            <select id='search_option' name='option' className='float_l' value={search.option} onChange={handleChange}>
              {Object.entries(options).map(([k, v]) => (
                <option key={k} value={k}>
                  {v}
                </option>
              ))}
            </select>
            <input
              type='text'
              name='text'
              className='float_l'
              placeholder='검색어를 입력해주세요'
              value={search.text}
              onChange={handleChange}
            />
          </div>
          <button type='submit'>검색</button>
        </div>
      </fieldset>
    </form>
  );
}
