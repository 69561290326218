import React, { useEffect } from 'react'
import ResetPw from '../../components/auth/resetPw'
import { useDispatch } from 'react-redux'
import * as resetPwActions from '../../modules/auth/resetPw'
const ResetPwContainer = ({ updateUserId }) => {

  useEffect(() => {
    handleBlockPage()
  }, [])

  const dispatch = useDispatch()

  const handleBlockPage = () => { updateUserId === undefined && dispatch(resetPwActions.blockResetPw()) }
  const onResetPwRequest = (newPass) => { dispatch(resetPwActions.resetPwRequest(newPass)) }

  return (
    <ResetPw updateUserId={updateUserId} onResetPwRequest={onResetPwRequest} />
  )
}

export default ResetPwContainer
