import React, { useState } from 'react'
import SongMobileList from './SongMobileList'
import SongMobilePopupLyrics from './SongMobilePopupLyrics'
import SongMobilePopupSound from './SongMobilePopupSound'

const SongMobile = ({ currentContentType, teamSongList, playerSongList }) => {

  const [lyricsPopupData, setLyricsPopupData] = useState(null)
  const [isLyricsPopup, setIsLyricsPopup] = useState(false)
  const [windowHeight, setWindowHeight] = useState(0)

  const onClickIsLyricsPopup = (isPopup, playerData) => {
    setIsLyricsPopup(isPopup)
    setLyricsPopupData(playerData)
    setWindowHeight(window.innerHeight)
  }

  const [soundPopupData, setSoundPopupData] = useState(null)
  const [isSoundPopup, setIsSoundPopup] = useState(false)

  const onClickIsSoundPopup = (isPopup, playerData) => {
    setIsSoundPopup(isPopup)
    setSoundPopupData(playerData)
    setWindowHeight(window.innerHeight)
  }

  return (
    <>
      {/* song_lists mb_mode */}
      <div className="song_lists mb_mode">

        <SongMobileList
          list={currentContentType === 'team' ? teamSongList : playerSongList}
          onClickIsLyricsPopup={onClickIsLyricsPopup}
          onClickIsSoundPopup={onClickIsSoundPopup} />

        {/* pop up */}
        {
          // 자막 팝업
          isLyricsPopup &&
          <SongMobilePopupLyrics lyricsPopupData={lyricsPopupData} onClickIsLyricsPopup={onClickIsLyricsPopup} windowHeight={windowHeight} />
        }
        {
          // 재생 플레이어 팝업
          isSoundPopup &&
          <SongMobilePopupSound soundPopupData={soundPopupData} onClickIsSoundPopup={onClickIsSoundPopup} windowHeight={windowHeight} />
        }
        {/* // pop up */}

      </div>
      {/* // song_lists mb_mode */}

      <div className="page_btn_wrap"></div> {/* 여백 확보하려고 적용 */}
    </>
  )
}

export default SongMobile
