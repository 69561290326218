import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { createListRequest } from '../../../modules/player/playerList'
import SongPc from './SongPc'
import SongMobile from './SongMobile'
import Prepairing from '../../common/Prepairing'

const Song = ({ type, currentContentType }) => {

  const list = useSelector(state => state.wizData.data && state.wizData.data[type])
  // const error = useSelector(state => state.wizData.error && state.wizData.error[type])
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(createListRequest({ 'cheerSong.cheerSongCate': '0' }, type))
  }, [type])

  const teamSongList = list &&
    list.filter(item => item.cheerSongCate === '100')

  const playerSongList = list &&
    list.filter(item => item.cheerSongCate === '200')

  //const isPrePage = process.env.REACT_APP_IS_PREPAIRING_PAGE
  const isPrePage = "N"

  return (
    <>
      {
        isPrePage === "Y" &&
        <Prepairing />
      }
      {
        isPrePage === "N" &&
        <>
          <SongPc teamSongList={teamSongList} playerSongList={playerSongList} />
          <SongMobile currentContentType={currentContentType} teamSongList={teamSongList} playerSongList={playerSongList} />
        </>
      }
    </>
  )
}

export default Song
