import React, { useEffect, useState } from 'react';
import Link from '../../common/Link';
import Pagination from './Pagination';
import ReactPlayer from 'react-player';
import NoSearchResult from '../../common/NoSearchResult';

const LiveVideoList = ({ page, videoList, mobileVideoList, onClickPage }) => {

  const [titleVideo, setTitleVideo] = useState(videoList && videoList[0])

  useEffect(() => {
    setTitleVideo(videoList && videoList[0])
  }, [videoList])


  const onChangeTitleVideo = (video) => {
    setTitleVideo(video)
  }

  const PcVideo = ({ video }) => {
    return (
      <li>
        <article className="article">
          <Link onClick={() => onChangeTitleVideo(video)}>
            <div className="tit_img">
              <div className="img_box"><img src={video.prvwImgUrl} alt={video.mediaName} /></div>
              <span className="icon_play">▶</span>
            </div>
            <div className="tit_detail">
              <div>
                <dl>
                  <dt>{video.mediaName}</dt>
                  <dd><span className="date">{video.gameDate}</span></dd>
                </dl>
              </div>
            </div>
          </Link>
        </article>
      </li>
    )
  }


  const MobileVideo = ({ video, isLast }) => {
    return (
      <li class={isLast ? 'last' : ''}>
        <article class="article">
          <Link href={video.mediaUrl}>
            <div class="tit_img">
              <div class="img_box"><img src={video.prvwImgUrl} alt={video.mediaName} /></div>
              <span class="icon_play">▶</span>
            </div>
            <div class="tit_detail">
              <div>
                <dl>
                  <dt>{video.mediaName}</dt>
                  <dd><span class="date">{video.gameDate}</span></dd>
                </dl>
              </div>
            </div>
          </Link>
        </article>
      </li>
    )
  }

  return (
    <>
      {/* PC */}
      <div className="video_clt_list pc_mode">
        <div className="video_block_wrap">
          <div className="video_list_block">
            <ul>
              <li className="seleted_video">
                <article className="article">
                  <div className="tit_img">
                    <div className="iframe_wrap">
                      {titleVideo && <ReactPlayer url={titleVideo.mediaUrl} width="696px" height="391px" playing controls light={titleVideo.prvwImgUrl} />}
                    </div>
                    <div className="img_box">
                      <img src="../../img/media/position_video_001.jpg" alt={titleVideo && titleVideo.mediaName} />
                    </div>
                    <span className="icon_play">▶</span>
                  </div>
                  <div className="tit_detail">
                    <div>
                      <dl>
                        <dt>{titleVideo && titleVideo.mediaName}</dt>
                        <dd><span className="date">{titleVideo && titleVideo.gameDate}</span></dd>
                      </dl>
                    </div>
                  </div>
                </article>
              </li>
              <li className="video_list">
                {
                  (videoList && videoList.length > 0)
                    ? <>
                      <ul>
                        {videoList && videoList.map((video, index) => <PcVideo key={index} video={video} />)}
                      </ul>
                      {videoList && videoList[0] && <Pagination page={page} totalPage={videoList[0].totalPage} onClickPage={onClickPage} />}
                    </>
                    : <>
                      <NoSearchResult />
                    </>
                }
              </li>
            </ul>
          </div>
        </div>
      </div >

      {/* MOBILE */}
      <div class="video_clt_list mobile_mode">
        <div class="video_block_wrap">
          <div class="video_list_block">
            {
              (mobileVideoList && mobileVideoList.length > 0)
                ? <>
                  <ul>
                    <li class="video_list">
                      <ul>
                        {mobileVideoList.map((video, index) => <MobileVideo key={index} video={video} isLast={index === mobileVideoList.length - 1} />)}
                      </ul>
                      {mobileVideoList[0] && <Pagination page={page} totalPage={mobileVideoList[0].totalPage} onClickPage={onClickPage} />}
                    </li>
                  </ul>
                </>
                : <>
                  <NoSearchResult />
                </>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default LiveVideoList;
