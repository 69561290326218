export const RESERVE_GENERAL_TICKET_REQUEST = 'ticket/RESERVE_GENERAL_TICKET_REQUEST'
export const RESERVE_GENERAL_TICKET_CHECK = 'ticket/RESERVE_GENERAL_TICKET_CHECK'
export const RESERVE_GENERAL_TICKET_SUCCESS = 'ticket/RESERVE_GENERAL_TICKET_SUCCESS'
export const RESERVE_GENERAL_TICKET_FAIL = 'ticket/RESERVE_GENERAL_TICKET_FAIL'

export const RESERVE_GROUP_TICKET_REQUEST = 'ticket/RESERVE_GROUP_TICKET_REQUEST'
export const RESERVE_GROUP_TICKET_SUCCESS = 'ticket/RESERVE_GROUP_TICKET_SUCCESS'
export const RESERVE_GROUP_TICKET_FAIL = 'ticket/RESERVE_GROUP_TICKET_FAIL'

export const SEASON_TICKET_REQUEST = 'ticket/SEASON_TICKET_REQUEST'
export const SEASON_TICKET_SUCCESS = 'ticket/SEASON_TICKET_SUCCESS'
export const SEASON_TICKET_FAIL = 'ticket/SEASON_TICKET_FAIL'

export const reserveGeneralTicketRequest = (isLogin, isTerms, returnUrl) => ({ type: RESERVE_GENERAL_TICKET_REQUEST, isLogin, isTerms, returnUrl })
export const reserveGeneralTicketCheck = (isLogin, returnUrl) =>({ type:RESERVE_GENERAL_TICKET_CHECK, isLogin, returnUrl })
export const reserveGeneralTicketSuccess = (encryptedParam) => ({ type: RESERVE_GENERAL_TICKET_SUCCESS, encryptedParam })
export const reserveGeneralTicketFail = () => ({ type: RESERVE_GENERAL_TICKET_FAIL })

export const reserveGroupTicketRequest = () => ({ type: RESERVE_GROUP_TICKET_REQUEST })
export const reserveGroupTicketSuccess = () => ({ type: RESERVE_GROUP_TICKET_SUCCESS })
export const reserveGroupTicketFail = () => ({ type: RESERVE_GROUP_TICKET_FAIL })

export const seasonTicketRequest = () =>({ type:SEASON_TICKET_REQUEST })
export const seasonTicketSuccess = () =>({ type:SEASON_TICKET_SUCCESS })
export const seasonTicketFail = () =>({ type:SEASON_TICKET_FAIL })

const initialState = {
  isRequesting: false,
  isRequested: false,
  encryptedParam: null,
  seasonInfo: null,
}

const ticket = (state = initialState, action) => {

  switch (action.type) {

    case RESERVE_GENERAL_TICKET_REQUEST:
      return {
        ...state,
        isRequesting: true,
        isRequested: false,
      }
    case RESERVE_GENERAL_TICKET_CHECK:
      return {
        ...state,
        isRequesting: true,
        isRequested: false,
      }
    case RESERVE_GENERAL_TICKET_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        isRequested: true,
        encryptedParam: state.encryptedParam
      }
    case RESERVE_GENERAL_TICKET_FAIL:
      return {
        ...state,
        isRequesting: false,
        isRequested: false,
      }
    case RESERVE_GROUP_TICKET_REQUEST:
      return {
        ...state,
        isRequesting: true,
        isRequested: false,
      }
    case RESERVE_GROUP_TICKET_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        isRequested: true,
        encryptedParam: state.encryptedParam
      }
    case RESERVE_GROUP_TICKET_FAIL:
      return {
        ...state,
        isRequesting: false,
        isRequested: false,
      }
    case SEASON_TICKET_REQUEST:
      return{
        ...state,
      }
    case SEASON_TICKET_SUCCESS:
      return{
        ...state,
        seasonInfo:action.seasonInfo
      }
    case SEASON_TICKET_FAIL:
      return{
        ...state,
      }
    default:
      return state
  }
}

export default ticket
