import { presentLog } from "components/auth/join/privacyPolicyText";
import React from "react";

const Privacy = ({ onClose }) => {
  return (
    <div className="util_popup privacy">
      <div className="util_popup_top">
        <h4>개인정보 수집·이용 동의</h4>
        <span>kt wiz는 팬들의 개인정보를 소중히 다루고 있습니다.</span>
      </div>
      <div className="util_popup_content">
        <p>
        개인정보 수집 및 이용에 대한 안내
        <br />
        <br />
        {presentLog.map((item, idx) => <p key={idx}>{item}</p>)}
        </p>
      </div>
      <button
        className="btn_popup_close"
        onClick={() => {
          onClose(false);
        }}
      >
        닫기
      </button>
      {/* <span className="back_arrow"></span> */}
    </div>
  );
};

export default Privacy;
