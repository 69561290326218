/* ie 지원 These must be the first lines in src/index.js */
// ie9
// import 'react-app-polyfill/ie9'
// import 'react-app-polyfill/stable'
// ie 11
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './components/App'
import * as serviceWorker from './serviceWorker'

// added
import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { Provider } from 'react-redux'
import rootReducer from './modules'
import createSagaMiddleware from 'redux-saga'
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import { rootSaga } from './sagas'
import * as Enum from './constants/enum'

import * as Sentry from '@sentry/browser';

const sagaMiddleware = createSagaMiddleware()

const history = createBrowserHistory('/')

const applyMW = applyMiddleware(
  routerMiddleware(history),
  sagaMiddleware
)

// store
const store = createStore(
  rootReducer(history),
  composeWithDevTools(applyMW)
)

sagaMiddleware.run(rootSaga)

/**
 * PRODUCTION 환경일 때, console.log 무력화
 */
if (process.env.REACT_APP_ENV === Enum.ENV.PRODUCTION) {
  console.log = () => { }
  console.warn = () => { }
  console.error = () => { }
}

Sentry.init({ dsn: "https://2e60c6bdfafc4c58a0befe4a1381bf5f@sentry.io/1875875" });

ReactDOM.render(
  <Provider store={store}>
    <App history={history} />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
