// 환경
export const ENV = {
  LOCALHOST: "LOCAL",
  DEVELOPMENT: "DEV",
  PRODUCTION: "STAG",
};

export const USER_AGENT = {
  OS: {
    ANDROID: "AndroidOS",
    IOS: "iOS",
  },
};

// 전화번호 앞자리
export const MOBILE_FRONT_NUMS = ["010", "011", "016", "017", "018", "019"];

// 이메일 도메인
export const EMAIL_SERVER_NAMES = [
  "gmail.com",
  "naver.com",
  "kakao.com",
  "korea.com",
];

// 아이디 찾기 타입
export const FIND_TYPES = {
  FIND_ID_MOBILE: "find_id_mobile",
  FIND_ID_EMAIL: "find_id_email",
  FIND_PW_MOBILE: "find_pw_mobile",
  FIND_PW_EMAIL: "find_pw_email",
};

// 인증 요청 타입
export const CERT_REQ_TYPES = {
  MYPAGE: "MYPAGE",
  FIND_ID: "FIND_ID",
  FIND_PW: "FIND_PW",
};

// 인증 타입
export const CERT_TYPES = {
  MOBILE: "100",
  EMAIL: "200",
};

export const IDENTIFY_TYPE = {
  MOBILE: "MOBILEM",
  IPIN: "IPIN2",
};

export const LOGIN_FAIL_TYPE = {
  DORMANT: "dormant",
  NORMAL: "normal",
};
