import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { yearListRequest, futuresBatterRankingRequest } from '../../modules/game/ranking'
import BatterRankingTable from '../../components/game/BatterRankingTable'
import ContentSwitchingTab from '../../components/common/ContentSwitchingTab'

const FuturesLeagueBatterRankingTableContainer = ({ match, year, onChangeYear }) => {

  const batterRankingList = useSelector(state => state.wizData.data && state.wizData.data['BATTER_RANKING_LIST'])
  // const batterRankingListError = useSelector(state => state.wizData.error && state.wizData.error['BATTER_RANKING_LIST'])

  const northBatterRankingList = useSelector(state => state.wizData.data && state.wizData.data['NORTH_BATTER_RANKING_LIST'])
  // const northBatterRankingListError = useSelector(state => state.wizData.error && state.wizData.error['NORTH_BATTER_RANKING_LIST'])

  const southBatterRankingList = useSelector(state => state.wizData.data && state.wizData.data['SOUTH_BATTER_RANKING_LIST'])
  // const southBatterRankingListError = useSelector(state => state.wizData.error && state.wizData.error['SOUTH_BATTER_RANKING_LIST'])

  const yearList = useSelector(state => state.wizData.data && state.wizData.data['YEAR_LIST'])

  const dispatch = useDispatch()

  const [sortKey, setSortKey] = useState('HRA')
  const [playerName, setPlayerName] = useState(null)

  const secondContentTabs = [
    { type: 'regular_league', title: 'kt wiz 타자' },
    { type: 'north_game', title: '북부 타자 순위' },
    { type: 'south_game', title: '남부 타자 순위' }
  ]

  const [contentType, setContentType] = useState(secondContentTabs[0].type)

  const onClickContentTab = type => {
    setContentType(type)
  }

  if (yearList && year == null) {
    onChangeYear(yearList.gameYearlist[0].gyear)
  }

  useEffect(() => {
    dispatch(yearListRequest({}, 'YEAR_LIST'))
  }, [dispatch])

  useEffect(() => {
    if (year) {
      dispatch(futuresBatterRankingRequest({ 'gameSearch.gyear': year, 'gameSearch.teamCode': 'KT', 'gameSearch.sortKey': sortKey, 'gameSearch.pname': playerName }, 'BATTER_RANKING_LIST'))
      dispatch(futuresBatterRankingRequest({ 'gameSearch.gyear': year, 'gameSearch.minorLeague': 'NORTH', 'gameSearch.sortKey': sortKey, 'gameSearch.paFlag': 'Y', 'gameSearch.pname': playerName }, 'NORTH_BATTER_RANKING_LIST'))
      dispatch(futuresBatterRankingRequest({ 'gameSearch.gyear': year, 'gameSearch.minorLeague': 'SOUTH', 'gameSearch.sortKey': sortKey, 'gameSearch.paFlag': 'Y', 'gameSearch.pname': playerName }, 'SOUTH_BATTER_RANKING_LIST'))
    }
  }, [year, sortKey, playerName])

  return (
    <>
      <ContentSwitchingTab className='detail_lnb_wrap' tabs={secondContentTabs} currentContentType={contentType} onClickContentTab={onClickContentTab} showPlayerSearch={true} onSearchPlayerName={setPlayerName} onSearchYear={onChangeYear} yearList={yearList && yearList.gameYearlist} />
      <BatterRankingTable batterList={batterRankingList} northBatterList={northBatterRankingList} southBatterList={southBatterRankingList} tab={contentType} onChangeSort={setSortKey} onChangePlayerName={setPlayerName} onChangeYear={onChangeYear} year={year} sortKey={sortKey} yearList={yearList && yearList.gameYearlist} />
    </>
  )
}

export default FuturesLeagueBatterRankingTableContainer
