import React from 'react'
import { useHistory } from 'react-router-dom'

const WatchPoint = ({ watchPoint }) => {

  const history = useHistory()

  const goWatchPoint = (schedule) => {
    if (schedule)
      history.push(`/game/regular/watchPoint/${schedule.gameDate}/${schedule.gmkey}`)
  }

  // const ptyText = code => {
  //   if (code === '0') {
  //     return ""
  //   } else if (code === '1') {
  //     return "(비)"
  //   } else if (code === '2') {
  //     return "(비/눈)"
  //   } else if (code === '3') {
  //     return "(눈/비)"
  //   } else if (code === '4') {
  //     return "(눈)"
  //   }
  //   return ""
  // }

  const windMark = wind => {
    if (wind === '동') {
      return "←"
    } else if (wind === '서') {
      return "→"
    } else if (wind === '남') {
      return "↑"
    } else if (wind === '북') {
      return "↓"
    } else if (wind === '북동') {
      return "↙"
    } else if (wind === '북서') {
      return "↘"
    } else if (wind === '남동') {
      return "↖"
    } else if (wind === '남서') {
      return "↗"
    }
    return ''
  }

  if (watchPoint) {
    return (
      <>
        <div className="boxscore_info_top_wrap">
          {/* pc_mode */}
          <div className="pc_mode">
            <article>
              <div className="date_select">
                <button className={'btn_date_prev' + (watchPoint.schedule.prev ? ' active' : '')}
                  onClick={() => goWatchPoint(watchPoint.schedule.prev)}
                  disabled={watchPoint && watchPoint.schedule.prev ? '' : 'true'}></button>
                <span className="selected_date">{watchPoint.gameScore.displayDate}</span>
                <button className={'btn_date_next' + (watchPoint.schedule.next ? ' active' : '')}
                  onClick={() => goWatchPoint(watchPoint.schedule.next)}
                  disabled={watchPoint && watchPoint.schedule.prev ? '' : 'true'}></button>
              </div>
              <div className="date_boxscore_info">
                <ul>
                  <li className="first">{watchPoint.gameScore.gtime} {watchPoint.gameScore.stadium}</li>
                </ul>
                <table>
                  <caption>해당 날짜 관전포인트</caption>
                  <colgroup>
                    <col width="10.5%" />
                    <col width="10.5%" />
                    <col width="10.5%" />
                    <col width="10.5%" />
                    <col width="*" />
                    <col width="10.5%" />
                    <col width="10.5%" />
                    <col width="10.5%" />
                    <col width="10.5%" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>승</th>
                      <th>패</th>
                      <th>무</th>
                      <th>승률</th>
                      <th>VS</th>
                      <th>승</th>
                      <th>패</th>
                      <th>무</th>
                      <th>승률</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{watchPoint.visitTeamRank ? watchPoint.visitTeamRank.win : 0}</td>
                      <td>{watchPoint.visitTeamRank ? watchPoint.visitTeamRank.lose : 0}</td>
                      <td>{watchPoint.visitTeamRank ? watchPoint.visitTeamRank.drawn : 0}</td>
                      <td>{watchPoint.visitTeamRank ? watchPoint.visitTeamRank.wra : 0}</td>
                      <th>시즌 성적</th>
                      <td>{watchPoint.homeTeamRank ? watchPoint.homeTeamRank.win : 0}</td>
                      <td>{watchPoint.homeTeamRank ? watchPoint.homeTeamRank.lose : 0}</td>
                      <td>{watchPoint.homeTeamRank ? watchPoint.homeTeamRank.drawn : 0}</td>
                      <td>{watchPoint.homeTeamRank ? watchPoint.homeTeamRank.wra : 0}</td>
                    </tr>
                    <tr>
                      <td>{watchPoint.visitTeamWinLose.win}</td>
                      <td>{watchPoint.visitTeamWinLose.lose}</td>
                      <td>{watchPoint.visitTeamWinLose.drawn}</td>
                      <td>{Math.round(watchPoint.visitTeamWinLose.win / (watchPoint.visitTeamWinLose.win + watchPoint.visitTeamWinLose.lose) * 1000) / 1000}</td>
                      <th>시즌 상대 전적</th>
                      <td>{watchPoint.homeTeamWinLose.win}</td>
                      <td>{watchPoint.homeTeamWinLose.lose}</td>
                      <td>{watchPoint.homeTeamWinLose.drawn}</td>
                      <td>{Math.round(watchPoint.homeTeamWinLose.win / (watchPoint.homeTeamWinLose.win + watchPoint.homeTeamWinLose.lose) * 1000) / 1000}</td>
                    </tr>
                    <tr>
                      <td colSpan="4">{watchPoint.visitTeamRank && watchPoint.visitTeamRank.rank + "위"}</td>
                      <th>시즌 순위</th>
                      <td colSpan="4">{watchPoint.homeTeamRank && watchPoint.homeTeamRank.rank + "위"}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className={'team team1 ' + watchPoint.gameScore.visitKey.toLowerCase()}>
                <span className="logo">{watchPoint.gameScore.visit}</span>
                <span className="team_place">{watchPoint.gameScore.visit} (원정)</span>
              </div>
              <div className={'team team2 ' + watchPoint.gameScore.homeKey.toLowerCase()}>
                <span className="logo">{watchPoint.gameScore.home}</span>
                <span className="team_place">{watchPoint.gameScore.home} (홈)</span>
              </div>
            </article>
          </div>
          {/* pc_mode */}

          {/*  mobile_mode */}
          <div className="mobile_mode">
            <article>
              <div className="date_select">
                <button className={'btn_date_prev' + (watchPoint.schedule.prev ? ' active' : '')} onClick={() => goWatchPoint(watchPoint.schedule.prev)}></button>
                <span className="selected_date">{watchPoint.gameScore.displayDate}</span>
                <button className={'btn_date_next' + (watchPoint.schedule.next ? ' active' : '')} onClick={() => goWatchPoint(watchPoint.schedule.next)}></button>
              </div>
              <div className="date_boxscore">
                <span className="score">{watchPoint.gameScore.vscore}</span>
                <span className="vs">VS</span>
                <span className="score">{watchPoint.gameScore.hscore}</span>
              </div>
              <div className="date_boxscore_info">
                <span className="time_place">{watchPoint.gameScore.gtime} {watchPoint.gameScore.stadium}</span>
              </div>
              <div className={'team team1 ' + watchPoint.gameScore.visitKey.toLowerCase()}>
                <span className="logo">{watchPoint.gameScore.visit}</span>
              </div>
              <div className={'team team2 ' + watchPoint.gameScore.homeKey.toLowerCase()}>
                <span className="logo">{watchPoint.gameScore.home}</span>
              </div>
            </article>
          </div>
          {/*  // mobile_mode */}
          {/*  // pc_mode */}
        </div>
        {/*  // boxscore_info_top_wrap */}

        {/*  boxscore_record_wrap */}
        <div className="boxscore_record_wrap">
          {/*  pc_mode */}
          <div className="pc_mode">
            <div className="pitcher_compare_wrap">
              <article>
                <h4>선발투수 비교</h4>
                <div className="table_wrap">
                  <table>
                    <caption>선발투수 비교</caption>
                    <colgroup>
                      <col width="64px" />
                      <col width="64px" />
                      <col width="*" />
                      <col width="64px" />
                      <col width="64px" />
                      <col width="64px" />
                      <col width="64px" />
                      <col width="64px" />
                      <col width="64px" />
                      <col width="64px" />
                      <col width="64px" />
                      <col width="64px" />
                      <col width="64px" />
                      <col width="64px" />
                      <col width="64px" />
                      <col width="64px" />
                      <col width="64px" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>팀</th>
                        <th>이름</th>
                        <th>평균자책점</th>
                        <th>경기</th>
                        <th>승</th>
                        <th>패</th>
                        <th>세</th>
                        <th>홀</th>
                        <th>승률</th>
                        <th>이닝</th>
                        <th>피안타</th>
                        <th>피홈런</th>
                        <th>볼넷</th>
                        <th>사구</th>
                        <th>삼진</th>
                        <th>실점</th>
                        <th>자책점</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className={watchPoint.gameScore.visitKey === 'KT' ? 'kt' : ''}>
                        <td>{watchPoint.gameScore.visit}</td>
                        <td>{watchPoint.visitPitcher && watchPoint.visitPitcher.playerName}</td>
                        <td>{watchPoint.visitPitcher && watchPoint.visitPitcher.era}</td>
                        <td>{watchPoint.visitPitcher && watchPoint.visitPitcher.gamenum}</td>
                        <td>{watchPoint.visitPitcher && watchPoint.visitPitcher.w}</td>
                        <td>{watchPoint.visitPitcher && watchPoint.visitPitcher.l}</td>
                        <td>{watchPoint.visitPitcher && watchPoint.visitPitcher.sv}</td>
                        <td>{watchPoint.visitPitcher && watchPoint.visitPitcher.hold}</td>
                        <td>{watchPoint.visitPitcher && watchPoint.visitPitcher.wra}</td>
                        <td>{watchPoint.visitPitcher && watchPoint.visitPitcher.innDisplay}</td>
                        <td>{watchPoint.visitPitcher && watchPoint.visitPitcher.hit}</td>
                        <td>{watchPoint.visitPitcher && watchPoint.visitPitcher.hr}</td>
                        <td>{watchPoint.visitPitcher && watchPoint.visitPitcher.bb}</td>
                        <td>{watchPoint.visitPitcher && watchPoint.visitPitcher.hp}</td>
                        <td>{watchPoint.visitPitcher && watchPoint.visitPitcher.kk}</td>
                        <td>{watchPoint.visitPitcher && watchPoint.visitPitcher.r}</td>
                        <td>{watchPoint.visitPitcher && watchPoint.visitPitcher.er}</td>
                      </tr>
                      <tr className={watchPoint.gameScore.homeKey === 'KT' ? 'kt' : ''}>
                        <td>{watchPoint.gameScore.home}</td>
                        <td>{watchPoint.homePitcher && watchPoint.homePitcher.playerName}</td>
                        <td>{watchPoint.homePitcher && watchPoint.homePitcher.era}</td>
                        <td>{watchPoint.homePitcher && watchPoint.homePitcher.gamenum}</td>
                        <td>{watchPoint.homePitcher && watchPoint.homePitcher.w}</td>
                        <td>{watchPoint.homePitcher && watchPoint.homePitcher.l}</td>
                        <td>{watchPoint.homePitcher && watchPoint.homePitcher.sv}</td>
                        <td>{watchPoint.homePitcher && watchPoint.homePitcher.hold}</td>
                        <td>{watchPoint.homePitcher && watchPoint.homePitcher.wra}</td>
                        <td>{watchPoint.homePitcher && watchPoint.homePitcher.innDisplay}</td>
                        <td>{watchPoint.homePitcher && watchPoint.homePitcher.hit}</td>
                        <td>{watchPoint.homePitcher && watchPoint.homePitcher.hr}</td>
                        <td>{watchPoint.homePitcher && watchPoint.homePitcher.bb}</td>
                        <td>{watchPoint.homePitcher && watchPoint.homePitcher.hp}</td>
                        <td>{watchPoint.homePitcher && watchPoint.homePitcher.kk}</td>
                        <td>{watchPoint.homePitcher && watchPoint.homePitcher.r}</td>
                        <td>{watchPoint.homePitcher && watchPoint.homePitcher.er}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </article>
            </div>

            <div className="lineup_wrap">
              <article>
                <h4>라인업</h4>
                <div className="lineup_list_wrap">
                  <div className="lineup_list">
                    <div className="lineup lineup1">
                      <div className="team"><img src={require('../../assets/img/game/logo_' + watchPoint.gameScore.visitKey.toLowerCase() + '.png')} alt={watchPoint.gameScore.visit} /></div>
                      <div className="player_lineup">
                        {watchPoint.visitLineup.length > 0 && (
                          <ul>
                            <li className="pst_p">P. {watchPoint.visitLineup.find(p => p.pos === '1').playerName}</li>
                            {watchPoint.visitLineup.find(p => p.pos === 'D') && <li className="pst_d">D. {watchPoint.visitLineup.find(p => p.pos === 'D').playerName}</li>}
                            <li className="pst_2">2. {watchPoint.visitLineup.find(p => p.pos === '2').playerName}</li>
                            <li className="pst_3">3. {watchPoint.visitLineup.find(p => p.pos === '3').playerName}</li>
                            <li className="pst_4">4. {watchPoint.visitLineup.find(p => p.pos === '4').playerName}</li>
                            <li className="pst_5">5. {watchPoint.visitLineup.find(p => p.pos === '5').playerName}</li>
                            <li className="pst_6">6. {watchPoint.visitLineup.find(p => p.pos === '6').playerName}</li>
                            <li className="pst_7">7. {watchPoint.visitLineup.find(p => p.pos === '7').playerName}</li>
                            <li className="pst_8">8. {watchPoint.visitLineup.find(p => p.pos === '8').playerName}</li>
                            <li className="pst_9">9. {watchPoint.visitLineup.find(p => p.pos === '9').playerName}</li>
                          </ul>
                        )}
                      </div>
                    </div>
                    <div className="lineup vs">
                      <img src={require('../../assets/img/game/lineup_vs.png')} alt="vs" />
                    </div>
                    <div className="lineup lineup2">
                      <div className="team"><img src={require('../../assets/img/game/logo_' + watchPoint.gameScore.homeKey.toLowerCase() + '.png')} alt={watchPoint.gameScore.home} /></div>
                      <div className="player_lineup">
                        {watchPoint.homeLineup.length > 0 && (
                          <ul>
                            <li className="pst_p">P. {watchPoint.homeLineup.find(p => p.pos === '1').playerName}</li>
                            {watchPoint.homeLineup.find(p => p.pos === 'D') && <li className="pst_d">D. {watchPoint.homeLineup.find(p => p.pos === 'D').playerName}</li>}
                            <li className="pst_2">2. {watchPoint.homeLineup.find(p => p.pos === '2').playerName}</li>
                            <li className="pst_3">3. {watchPoint.homeLineup.find(p => p.pos === '3').playerName}</li>
                            <li className="pst_4">4. {watchPoint.homeLineup.find(p => p.pos === '4').playerName}</li>
                            <li className="pst_5">5. {watchPoint.homeLineup.find(p => p.pos === '5').playerName}</li>
                            <li className="pst_6">6. {watchPoint.homeLineup.find(p => p.pos === '6').playerName}</li>
                            <li className="pst_7">7. {watchPoint.homeLineup.find(p => p.pos === '7').playerName}</li>
                            <li className="pst_8">8. {watchPoint.homeLineup.find(p => p.pos === '8').playerName}</li>
                            <li className="pst_9">9. {watchPoint.homeLineup.find(p => p.pos === '9').playerName}</li>
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </div>

            <div className="broadcast_channel_wrap">
              <article>
                <h4>중계 채널</h4>
                <ul>
                  <li className="first">{watchPoint && watchPoint.schedule.current.broadcast}</li>
                </ul>
              </article>
            </div>

            <div className="home_weather_wrap">
              <article>
                <h4>홈구장 날씨</h4>
                <ul>
                  {watchPoint && watchPoint.weather &&
                    <>
                      <li>{watchPoint.weather.addr}</li>
                      <li>온도 : 현재 {watchPoint.weather.temp}˚ (최저 {watchPoint.weather.tmn}˚, 최고 {watchPoint.weather.tmx}˚)</li>
                      <li>날씨 : {watchPoint.weather.wfkor}</li>
                      <li>강수확률 : {watchPoint.weather.pop}%</li>
                      <li>풍속 : {Math.round(watchPoint.weather.ws * 100) / 100}m/s</li>
                      <li>풍향 : {watchPoint.weather.wdkor}<span>{windMark(watchPoint.weather.wdkor)}</span></li>
                      <li>습도 : {watchPoint.weather.reh}%</li>
                    </>
                  }
                  {watchPoint && watchPoint.weather == null && <li className="no_weather">경기 당일 날씨만 제공됩니다.</li>}
                </ul>
              </article>
            </div>
          </div>
          {/*  // pc_mode */}

          {/*  mobile_mode */}
          <div className="mobile_mode">
            <div className="home_weather_wrap">
              <article>
                <h4>홈구장 날씨</h4>
                <div className="weather_box">
                  <div className="weather cloud"></div>
                  <div className="weather_detail">
                    <dl>
                      <dt><strong>kt wiz</strong>&nbsp;<span>(경기도 수원시 장안구 조원1동)</span></dt>
                      <dd>
                        <ul>
                          {watchPoint && watchPoint.weather &&
                            <>
                              <li>{watchPoint.weather.addr}</li>
                              <li>온도 : 현재 {watchPoint.weather.temp}˚ (최저 {watchPoint.weather.tmn}˚, 최고 {watchPoint.weather.tmx}˚)</li>
                              <li>날씨 : {watchPoint.weather.wfkor}</li>
                              <li>강수확률 : {watchPoint.weather.pop}%</li>
                              <li>풍속 : {Math.round(watchPoint.weather.ws * 100) / 100}m/s</li>
                              <li>풍향 : {watchPoint.weather.wdkor}<span>{windMark(watchPoint.weather.wdkor)}</span></li>
                              <li>습도 : {watchPoint.weather.reh}%</li>
                            </>
                          }
                          {watchPoint && watchPoint.weather == null && <li className="no_weather">경기 당일 날씨만 제공됩니다.</li>}
                        </ul>
                      </dd>
                    </dl>
                  </div>
                </div>
              </article>
            </div>
          </div>
          {/*  // mobile_mode */}
        </div>
        {/*  // main_boxscore_record_wrap */}
      </>
    )
  } else {
    return (<></>)
  }
}

export default WatchPoint
