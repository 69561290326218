import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { resourceRequest, useResource } from '../../modules/resource/resource'
import * as dialogActions from "../../modules/common/dialog"
import Link from '../../components/common/Link'
import Prepairing from '../common/Prepairing'

const DiscountAndBenefit = () => {
  const TICKET_PRICE_DISCOUNT_IMAGE_PC = 'TICKET_PRICE_DISCOUNT_IMAGE_PC'
  const TICKET_PRICE_DISCOUNT_IMAGE_M = 'TICKET_PRICE_DISCOUNT_IMAGE_M'
  const [imagePc, errorPc] = useResource(TICKET_PRICE_DISCOUNT_IMAGE_PC)
  const [imageM, errorM] = useResource(TICKET_PRICE_DISCOUNT_IMAGE_M)

  console.log(imagePc, imageM)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(resourceRequest(TICKET_PRICE_DISCOUNT_IMAGE_PC))
    dispatch(resourceRequest(TICKET_PRICE_DISCOUNT_IMAGE_M))
  }, [])

  useEffect(() => {
    if (errorPc && errorPc.message) {
      dispatch(dialogActions.alertOpen(errorPc.message))
    }
    if (errorM && errorM.message) {
      dispatch(dialogActions.alertOpen(errorM.message))
    }
  }, [errorPc, errorM])

  //const isPrePage = process.env.REACT_APP_IS_PREPAIRING_PAGE
  const isPrePage = "N"

  return (
    <>

      {
        isPrePage === "Y" &&
        <Prepairing />
      }

      {
        isPrePage === "N" &&
        <>
          {/* <div class='img-whole'>
        <img class='pc' src={`${imagePc && imagePc.resource && imagePc.resource.value}`.replace('localhost:8080', 'test.ktwiz.co.kr:17400')} alt="" />
        <img class='m' src={`${imageM && imageM.resource && imageM.resource.value}`.replace('localhost:8080', 'test.ktwiz.co.kr:17400')} alt="" />
      </div> */}

          <div class='title'>
            <h6>
              할인
          <strong class='red'>할인 및 우대된 가격으로 kt wiz 경기장에서 직접 만나 보세요!</strong>
            </h6>
          </div>
          <article class='arti'>
            <div class='tit'>
              <h6>카드 할인</h6>
            </div>
            <div class='desc'>
              <table class='guide'>
                <tbody>
                  <tr>
                    <th>
                      <span>
                        참! 좋은 kt wiz 카드
                  </span>
                    </th>
                    <td>
                      <p>
                        전 좌석&nbsp;<strong class='red'>3,000원 할인</strong>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <span>
                        BC 카드
                  </span>
                    </th>
                    <td>
                      <p>
                        전 좌석&nbsp;<strong class='red'>1,000원 할인</strong>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <span>
                        kt 멤버십
                  </span>
                    </th>
                    <td>
                      <p>
                        응원지정석 / 스카이존 / 외야잔디자유석<br />
                        <strong class='red'>VIP 3,000원, 일반 2,000원 할인 </strong>
                        (1일 1회 / 월 2회)
                  </p>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <span>
                        문화누리카드
                  </span>
                    </th>
                    <td>
                      <p>
                        스카이존 / 외야잔디자유석<br />
                        <strong class='red'>40% 할인 </strong>(본인확인 및 문화누리카드 제시 후 티켓 수령, 1인 최대 4매)
                  </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </article>
          <article class='arti'>
            <div class='tit'>
              <h6>우대 할인</h6>
            </div>
            <div class='desc'>
              <table class='guide'>
                <tbody>
                  <tr>
                    <th>
                      <span>
                        장애인석<br />
                        (휠체어 전용)
                  </span>
                    </th>
                    <td>
                      <p>
                        해당 좌석은 휠체어를 사용하는 장애인에 한하며,<br />
                        보호자(동반석)포함 최대 2인까지 동일 금액으로 현장 구매<br />
                        <strong class='red'>[단, 휠체어석 좌석 상황에 따라 보호자석(동반석) 1인 제공]</strong>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <span>
                        어린이 회원(유료)
                  </span>
                    </th>
                    <td>
                      <p>
                      응원지정석 / 중앙지정석 / 스카이존 <strong class='red'>2,000원 할인</strong><br />
                        <strong class='red'>외야잔디자유석 현장 무료(만석 시 스카이존 제공)</strong>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <span>
                        미취학 아동(36개월 미만)
                  </span>
                    </th>
                    <td>
                      <p>
                        응원지정석 / 외야잔디자유석 / 스카이존<br />
                        <strong class='red'>무료 입장 가능 </strong>
                        (단, 좌석은 미배정)
                  </p>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <span>
                        초등학생 이하
                  </span>
                    </th>
                    <td>
                      <p>
                        외야잔디자유석 <strong class='red'>50% 현장 할인</strong>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <span>
                        국가 유공자 / 복지 / 경로카드 소지자
                  </span>
                    </th>
                    <td>
                      <p>
                        응원지정석 본인 <strong class='red'>50% 할인</strong>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <span>
                        기회경기관람권(장애인/만70세 이상)
                  </span>
                    </th>
                    <td>
                      <p>
                        {/* 중앙지정석 <strong class='red'>75% 할인</strong> (본인과 동반 1인에 한함) */}
                        중앙지정석 관람료 1,000원 (본인과 동반 1인에 한함)
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </article>
          <div class='buttons'>
            <Link to='/ticket/reservation' class='btn-reserve'>티켓 예매하기</Link>
          </div>
        </>
      }
    </>
  )
}

export default DiscountAndBenefit
