import React from 'react'
import CheerMobileListItem from './CheerMobileListItem'
const CheerMobileList = ({ cheerleaderList }) => {
  return (
    <>
      {/* player_list */}
      <div className="player_list">
        <ul>
          <CheerMobileListItem cheerleaderList={cheerleaderList} />
        </ul>
      </div>
      {/* // player_list */}
    </>
  )
}

export default CheerMobileList
