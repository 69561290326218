import React from 'react'
import MyInfoContainer from '../../containers/mypage/MyInfoContainer'
import PageTemplate from '../../components/common/PageTemplate'
import PageHeaderContainer from '../../containers/common/PageHeaderContainer'
import ContentTemplate from '../../components/common/ContentTemplate'
import PageTitleTemplate from '../../components/common/PageTitleTemplate'
import Breadcrumb from '../../components/common/Breadcrumb'
import { MY_INFO_PAGE } from '../../constants/page'

const MyInfoPage = () => {
  return (
    <PageTemplate className='member'>
      <PageHeaderContainer title='내 정보 관리' image={require('../../assets/img/login/sub_tit_mypage.png')} />
      <ContentTemplate>
        <PageTitleTemplate>
          <Breadcrumb page={MY_INFO_PAGE} />
        </PageTitleTemplate>
        <MyInfoContainer />
      </ContentTemplate>
    </PageTemplate>
  )
}

export default MyInfoPage
