import wiz from '../../api/wiz'
import { dataRequest } from '../common/wizData'

export const createGameScheduleRequest = (api, params, dataType) => {

  return dataRequest(
    api,
    params,
    dataType
  )
}

export const regularGameScheduleRequest = yearMonth => createGameScheduleRequest(wiz.API.GET_REGULAR_GAMESCHEDULE, { 'gameSearch.yearMonth': yearMonth }, 'GAME_SCHEDULE_REGULAR')
export const regularGameAllScheduleRequest = yearMonth => createGameScheduleRequest(wiz.API.GET_REGULAR_GAMESCHEDULE_ALL, { 'gameSearch.yearMonth': yearMonth }, 'GAME_ALL_SCHEDULE_REGULAR')

export const recentRegularGamesRequest = () => createGameScheduleRequest(wiz.API.GET_REGULAR_RECENT_GAMES, {}, 'RECENT_REGULAR_GAMES')

export const regularDayScheduleRequest = () => createGameScheduleRequest(wiz.API.GET_REGULAR_DAY_SCHEDULE, {}, 'DAY_SCHEDULE_REGULAR')

export const regularScoreBoardRequest = (gmkey, gameDate) => createGameScheduleRequest(wiz.API.GET_REGULAR_SCORE_BOARD, { 'gameSearch.gmkey': gmkey, 'gameSearch.gameDate': gameDate }, 'SCORE_BOARD_REGULAR')

export const regularBoxscoreRequest = (gmkey, gameDate) => createGameScheduleRequest(wiz.API.GET_REGULAR_BOXSCORE, { 'gameSearch.gmkey': gmkey, 'gameSearch.gameDate': gameDate }, 'BOXSCORE_REGULAR')


export const futuresGameScheduleRequest = yearMonth => createGameScheduleRequest(wiz.API.GET_FUTURES_GAMESCHEDULE, { 'gameSearch.yearMonth': yearMonth }, 'GAME_SCHEDULE_FUTURES')
export const futuresGameAllScheduleRequest = yearMonth => createGameScheduleRequest(wiz.API.GET_FUTURES_GAMESCHEDULE_ALL, { 'gameSearch.yearMonth': yearMonth }, 'GAME_ALL_SCHEDULE_FUTURES')

export const recentFuturesGamesRequest = () => createGameScheduleRequest(wiz.API.GET_FUTURES_RECENT_GAMES, {}, 'RECENT_FUTURES_GAMES')

export const futuresDayScheduleRequest = () => createGameScheduleRequest(wiz.API.GET_FUTURES_DAY_SCHEDULE, {}, 'DAY_SCHEDULE_FUTURES')

export const futuresScoreBoardRequest = (gmkey, gameDate) => createGameScheduleRequest(wiz.API.GET_FUTURES_SCORE_BOARD, { 'gameSearch.gmkey': gmkey, 'gameSearch.gameDate': gameDate }, 'SCORE_BOARD_FUTURES')

export const futuresBoxscoreRequest = (gmkey, gameDate) => createGameScheduleRequest(wiz.API.GET_FUTURES_BOXSCORE, { 'gameSearch.gmkey': gmkey, 'gameSearch.gameDate': gameDate }, 'BOXSCORE_FUTURES')

export const autoScreenStatusRequest = () => createGameScheduleRequest(wiz.API.GET_AUTOSCREENSTATUS, {}, 'AUTOSCREENSTATUS')
