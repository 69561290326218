import { getYear } from 'date-fns';
import React from 'react'

const PitcherRanking = ({ eraTop3InKt, winTop3InKt, eraTop5 }) => {
  const date = new Date();
  const recentYear = getYear(date);

  return (
    <div class="rank_record_wrap pitcher_record">
      <div class="pc_mode">
        <div class="top_rank_wrap">
          <article>
            <div class="top3_rank_list">
              <ul>
                <li class="first">
                  <div class="img_box"><img src={eraTop3InKt && eraTop3InKt[0].playerPrvwImg} alt={eraTop3InKt && eraTop3InKt[0].playerName} /></div>
                  <div class="ribbon"><span>평균자책점</span><br />TOP3</div>
                  <div class="rank">
                    <ul>
                      {eraTop3InKt && eraTop3InKt.map((player, index) => <li key={player.pcode} className={index === 0 ? 'top' : ''}>{index + 1}. {player.playerName} ({player.era})</li>)}
                    </ul>
                  </div>
                </li>
                <li>
                  <div class="img_box"><img src={winTop3InKt && winTop3InKt[0].playerPrvwImg} alt={winTop3InKt && winTop3InKt[0].playerName} /></div>
                  <div class="ribbon">승리<br />TOP3</div>
                  <div class="rank">
                    <ul>
                      {winTop3InKt && winTop3InKt.map((player, index) => <li key={player.pcode} className={index === 0 ? 'top' : ''}>{index + 1}. {player.playerName} ({player.w})</li>)}
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
            <div class="top5_rank_list">
              <h5>전체 투수 평균자책점 <span>TOP5</span></h5>
              <ul class="rank">
                {eraTop5 && eraTop5.map((player, index) => <li key={player.pcode} className={player.teamName === 'KT' ? 'kt' : ''}><span class="name">{index + 1}&nbsp;&nbsp;{player.playerName} ({player.teamName})</span><span class="record">{player.era}</span></li>)}
              </ul>
              <span class="notice">※ {recentYear} 정규리그 시즌</span>
            </div>
          </article>
        </div>
      </div>
    </div>
  )
}

export default PitcherRanking
