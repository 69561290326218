import React from 'react'
import { parse } from 'date-fns'
import DatePickerWrap from './DatePicketWrap'

export default function PeriodSearchForm({ startDate, endDate, onSubmit }) {
  const [period, setPeriod] = React.useState({ startDate: startDate && parse(startDate, 'yyyy-MM-dd', new Date()), endDate: endDate && parse(endDate, 'yyyy-MM-dd', new Date()) })

  const handleSubmit = e => {
    e.preventDefault()

    console.log('Submitted PeriodSearchForm: ', period)
    onSubmit(period.startDate, period.endDate)
  }

  const handleChange = (name, date) => {
    console.log(name, date)
    setPeriod({
      ...period,
      [name]: date,
    })
    console.log('PeriodSearchForm.handleChange', period)
  }

  const focusDatePicker = e => {
    const target = e.target.parentElement.getElementsByTagName("input")[0];
    target.focus()
  }

  return (
    <form action='' method='' name='period_frm' className='period_frm' onSubmit={handleSubmit}>
      <fieldset>
        <legend className='blind'>게시물 검색</legend>
        <div className='searchbox clearfix period_search'>
          <div className='period_selected'>
            <div className='startDate_wrap'>
              <DatePickerWrap name='startDate' selected={period.startDate} handleChange={handleChange} />
              <button type='button' className='btn_calendar bar' onClick={focusDatePicker}>
                달력보기
              </button>
            </div>

            <span>-</span>
            <div className='endDate_wrap'>
              <DatePickerWrap name='endDate' selected={period.endDate} handleChange={handleChange} />
              <button type='button' className='btn_calendar' onClick={focusDatePicker}>
                달력보기
              </button>
            </div>
          </div>
          <button type='submit'>검색</button>
        </div>
      </fieldset>
    </form>
  )
}
