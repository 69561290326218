import React from 'react'
import Link from '../../../components/common/Link'

const SongPcTeamListItem = ({ teamSongList, selectItem, onClickSelectItem, onClickPlayAudio, onClickPauseAudio }) => {

  return (
    <>
      {
        teamSongList &&
        teamSongList.map((v, i) => (
          <li key={i} className={selectItem === i ? "seleted" : ""} onClick={() => onClickSelectItem(i)}>
            <span className="song_tit">{v.cheerSongTitle}</span>
            <Link onClick={onClickPauseAudio} className="btn_song_stop"></Link>
            <Link onClick={onClickPlayAudio} className="btn_song_play"></Link>
          </li>
        ))

      }
    </>
  )
}

export default SongPcTeamListItem
