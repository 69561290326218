import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import BoxScore from '../../components/game/BoxScore'
import { futuresBoxscoreRequest } from '../../modules/game/gameSchedule'

const FuturesLeagueBoxScoreContainer = ({ match }) => {

  const boxscore = useSelector(state => state.wizData.data && state.wizData.data['BOXSCORE_FUTURES'])
  // const boxscoreError = useSelector(state => state.wizData.error && state.wizData.error['BOXSCORE_FUTURES'])

  console.log(match)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(futuresBoxscoreRequest(match.params.gameId, match.params.gameDate))
  }, [match.params.gameId, match.params.gmkey])

  return (
    <BoxScore league="futures" boxscore={boxscore} />
  )
}

export default FuturesLeagueBoxScoreContainer
