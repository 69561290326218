import React from 'react'
import SongPcTeamListItem from './SongPcTeamListItem'

const SongPcTeamList = ({ teamSongList, selectItem, onClickSelectItem, onClickPlayAudio, onClickPauseAudio }) => {

  return (
    <>
      <div className="song_list_wrap">
        <div>
          <h5>kt wiz 구단 <strong>응원가!</strong></h5>
          <div className="song_list">
            <ul>
              <SongPcTeamListItem
                teamSongList={teamSongList}
                selectItem={selectItem}
                onClickSelectItem={onClickSelectItem}
                onClickPlayAudio={onClickPlayAudio}
                onClickPauseAudio={onClickPauseAudio} />
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default SongPcTeamList
