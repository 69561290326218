import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import qs from "query-string";

import { createListRequest } from "../../../modules/media/wizBoard";
import ArticleList from "../board/ArticleList";
import ArticleListItem from "../board/ArticleListItem";
import ArticlePhotoItem from "../board/ArticlePhotoItem";
import Pagination from "../board/Pagination";
import ArticleGridItem from "./ArticleGridItem";

const useWizData = board => {
  const list = useSelector(state => state.wizData.data && state.wizData.data[board]);
  const error = useSelector(state => state.wizData.error && state.wizData.error[board]);
  const loading = useSelector(state => state.wizData.loading[board] || false);

  return [list, error, loading];
};

const WizBoard = ({ board, query, cat, listType = "list", classForList = "" }) => {
  const isFirstLoad = React.useRef(true);
  const [list, error, loading] = useWizData(board);
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const count = listType === "grid" ? 12 : 5;

  React.useEffect(() => {
    isFirstLoad.current = false;

    const newQuery = {
      "search.sort": "100",
      "search.max": count,
      "article.boardCatSeq": cat,
      ...query
    };

    dispatch(createListRequest(newQuery, board));
  }, [dispatch, query, board]);

  // 확장성을 위해 향후 Board 객체를 만들어서 아래 내용과 boardCode를 넣는 것 검토
  const listItem = {
    news: ArticleListItem,
    press: ArticleListItem,
    firstPitcher: ArticleListItem,
    fanReporting: ArticleListItem,
    sns: ArticleListItem,
    gallery: ArticlePhotoItem,
    sponsor: ArticleListItem,
    history: ArticleListItem,
    wizstory: ArticleGridItem,
    highlight: ArticleGridItem
  };

  const goToPage = page => {
    const newQuery = {
      ...query,
      "search.page": page
    };
    const toPath = `${location.pathname}?${qs.stringify(newQuery)}`;
    history.push(toPath);
  };

  const page = parseInt(query["search.page"], 10) || 1;

  const goToNextPage = () => {
    console.log("goToNextPage");
    goToPage(page + 1);
  };
  const goToPrevPage = () => {
    if (page > 1) {
      console.log("goToPrevPage");
      goToPage(page - 1);
    }
  };

  const articles =
    list &&
    list.map(n => ({
      ...n,
      contents: n.artcContents && n.artcContents.replace(/<[^>]+>/g, ""),
      title: n.artcTitle,
      subTitle: n.artcSubTitle,
      seq: n.artcSeq,
      registerAt: new Date(n.regDttm),
      updateAt: new Date(n.updDttm)
    }));

  console.log("WizBoard." + board, { page, articles, classForList });

  return (
    <>
      {isFirstLoad.current || loading ? (
        "Loading ..."
      ) : articles ? (
        <ArticleList
          articles={articles}
          itemComponent={listItem[board]}
          type={listType}
          className={classForList}
          onNextPage={goToNextPage}
          onPrevPage={goToPrevPage}
        />
      ) : (
            `${error && error.message}`
          )}
      {articles && articles[0] && <Pagination page={page} totalPage={articles[0].totalPage} onClickPage={goToPage} />}
    </>
  );
};

WizBoard.propTypes = {
  board: PropTypes.oneOf([
    "news",
    "press",
    "firstPitcher",
    "fanReporting",
    "sns",
    "gallery",
    "sponsor",
    "history",
    "wizstory",
    "highlight"
  ])
};

export default WizBoard;
