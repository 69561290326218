import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import FindId from '../../components/auth/findId'

// actions
import * as CertActions from '../../modules/auth/cert'
import * as FindIdACtions from '../../modules/auth/findId'

const FindIdContainer = () => {

  // 페이지 빠져나갈 때, 인증 정보 리셋
  useEffect(() => {
    return () => {
      onCertReset()
    }
  }, [])

  const cert = useSelector(state => state.cert)
  const dispatch = useDispatch()

  const onCertRequest = (userCert) => { dispatch(CertActions.certRequest(userCert)) }
  const onCertReset = () => { dispatch(CertActions.certReset()) }
  const onFindIdRequest = (userCert) => { dispatch(FindIdACtions.findIdRequest(userCert)) }

  return <FindId cert={cert} onCertReset={onCertReset} onCertRequest={onCertRequest} onFindIdRequest={onFindIdRequest} />

}

export default FindIdContainer
