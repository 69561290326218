import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { resourceRequest, useResource } from '../../modules/resource/resource'
import * as dialogActions from "../../modules/common/dialog"
import Prepairing from '../common/Prepairing'

const Entrance = () => {
  const ENTRANCE_IMAGE_M = 'ENTRANCE_IMAGE_M'
  const ENTRANCE_IMAGE_PC = 'ENTRANCE_IMAGE_PC'
  const [imagePc, errorPc] = useResource(ENTRANCE_IMAGE_PC)
  const [imageM, errorM] = useResource(ENTRANCE_IMAGE_M)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(resourceRequest(ENTRANCE_IMAGE_PC))
    dispatch(resourceRequest(ENTRANCE_IMAGE_M))
  }, [])

  useEffect(() => {
    if (errorPc && errorPc.message) {
      dispatch(dialogActions.alertOpen(errorPc.message))
    }
    if (errorM && errorM.message) {
      dispatch(dialogActions.alertOpen(errorM.message))
    }
  }, [errorPc, errorM])

  const isPrePage = process.env.REACT_APP_IS_PREPAIRING_PAGE

  return (
    <>
      {
        isPrePage === "Y" &&
        <Prepairing />
      }
      {
        isPrePage === "N" &&
        <>
          <div class='img-whole'>
            <img class='pc' src={`${imagePc && imagePc.resource && imagePc.resource.value}`.replace('localhost:8080', 'test.ktwiz.co.kr:17400')} alt="" />
            <img class='m' src={`${imageM && imageM.resource && imageM.resource.value}`.replace('localhost:8080', 'test.ktwiz.co.kr:17400')} alt="" />
          </div>
        </>
      }
    </>
  )
}

export default Entrance
