import React from 'react';
import { useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import qs from 'query-string';
import format from 'date-fns/format'

import TextSearchForm from './searchForm/TextSearchForm';
import PeriodSearchForm from './searchForm/PeriodSearchForm';

// startDate = '20191111'
// endDate = '20191111'
const ArticleSearch = ({
  options = { title: '제목', content: '내용' },
  option = 'title',
  text,
  showPeriodSearch,
  startDate,
  endDate
}) => {
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  const submit = (search) => {
    // 기존 query 읽어오기
    const query = qs.parse(location.search);
    delete query['search.page']
    if (search['search.searchWord']) {
      delete query['search.startDate']
      delete query['search.endDate']
    }
    if (search['search.startDate']) {
      delete query['search.searchWord']
    }

    const newQuery = {
      ...query,
      ...search
    };
    history.push(`${match.url}?${qs.stringify(newQuery)}`);
  }

  const handleTextSubmit = (option, text) => {
    console.log('handleTextSubmit', { option, text });

    let sort = '100';
    switch (option) {
      case 'title':
        sort = '100';
        break;
      case 'content':
        sort = '200';
        break;
      case 'player':
        sort = '400';
        break;
      default:
        sort = '100';
    }

    const search = {}
    search['search.sort'] = sort
    search['search.searchWord'] = text
    submit(search)
  };

  const handlePeriodSubmit = (startDate, endDate) => {
    console.log('handlePeriodSubmit', { startDate, endDate });
    const search = {}
    search['search.startDate'] = format(startDate, 'yyyy-MM-dd')
    search['search.endDate'] = format(endDate, 'yyyy-MM-dd')
    submit(search)
  };

  return (
    <div className='searchbox_wrap'>
      <div className={`searchbox_form active ${showPeriodSearch ? 'add_period_search' : ''}`}>
        <TextSearchForm
          options={options}
          option={option}
          text={text}
          onSubmit={handleTextSubmit}
        />
        {showPeriodSearch && <PeriodSearchForm startDate={startDate} endDate={endDate} onSubmit={handlePeriodSubmit} />}
      </div>
    </div>
  );
};

ArticleSearch.propTypes = {
  sort: PropTypes.oneOf(['title', 'content', 'player']),
  text: PropTypes.string,
  onSearch: PropTypes.func
};

export default ArticleSearch;
