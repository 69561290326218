import React from "react";

const SONG_LIST = [
  {
    index: 1,
    title: "나의사랑 kt wiz",
    subTitle: "End of the world",
    singer: "Skeeter Davis",
    madeBy: "후지퍼시픽 100%",
  },
  {
    index: 2,
    title: "지금 이 순간",
    subTitle: "This is the moment",
    singer: "",
    madeBy: "후지퍼시픽 50% / EKKO 50%",
  },
  {
    index: 3,
    title: "마법의 성",
    subTitle: "마법의 성",
    singer: "더클래식",
    madeBy: "작사, 작곡 - 김광진",
  },
  {
    index: 4,
    title: "라인업송",
    subTitle: "숙명",
    singer: "김상민",
    madeBy: "작사 - 홍지유 / 작곡 - 정기석",
  },
  {
    index: 5,
    title: "안타송 2",
    subTitle: "내 사랑을 받아다오 Stargirl",
    singer: "불독맨션",
    madeBy: "작사, 작곡 - 이한철",
  },
  {
    index: 6,
    title: "let's go kt wiz",
    subTitle: "나 사나이다",
    singer: "이혁준",
    madeBy: "작사, 작곡 - 이한철",
  },
  {
    index: 7,
    title: "안타송 2",
    subTitle: "뿌요뿌요",
    singer: "유피",
    madeBy: "작사, 작곡 - 장용진",
  },
  {
    index: 8,
    title: "강백호 응원가",
    subTitle: "흔들리는 꽃들 속에서 네 샴푸향이 느껴진거야",
    singer: "장범준",
    madeBy: "작사, 작곡 - 장범준",
  },
  {
    index: 9,
    title: "김민혁 응원가",
    subTitle: "Run",
    singer: "육중완",
    madeBy: "작사, 작곡 - 문성남",
  },
  {
    index: 10,
    title: "박경수 응원가",
    subTitle: "나에게로 떠나는 여행",
    singer: "버즈",
    madeBy: "작사 - 한경혜 / 작곡 - 고석영",
  },
  {
    index: 11,
    title: "송민섭 응원가",
    subTitle: "롤리폴리",
    singer: "티아라",
    madeBy: "작사, 작곡 - 신사동호랭이, 최규성",
  },
  {
    index: 12,
    title: "신본기 응원가",
    subTitle: "처음",
    singer: "서문탁",
    madeBy: "작사 - 이영준 / 작곡 - 박세준",
  },
  {
    index: 13,
    title: "심우준 응원가",
    subTitle: "프로포즈 대작전 ost 오나의 여신님",
    singer: "타카피",
    madeBy: "대표자 김재국",
  },
  {
    index: 14,
    title: "조용호 응원가",
    subTitle: "way back home",
    singer: "숀",
    madeBy: "작사 - 이지혜, JQ / 작곡 - SHAUN",
  },
  {
    index: 15,
    title: "김준태 응원가",
    subTitle: "Nothing's Gonna Change My Love For You",
    singer: "STEFAN COLES",
    madeBy: "(주)유니버설뮤직퍼블리싱",
  },
  {
    index: 16,
    title: "황재균 응원가",
    subTitle: "50 WAYS TO SAY GOODBYE",
    singer: "TRAIN",
    madeBy: "LIND ESPEN",
  },
  {
    index: 17,
    title: "권동진 응원가",
    subTitle: "FORREST GUMP",
    singer: "",
    madeBy: "SONY MUSIC PUBLISHING KOREA",
  },
  {
    index: 18,
    title: "김병희",
    subTitle: "말이야",
    singer: "이홍기",
    madeBy: "작사 - 한성호 / 작곡 - 한승훈, 김재양",
  },
  {
    index: 19,
    title: "박경수 응원가",
    subTitle: "우리동네 HERO",
    singer: "노라조",
    madeBy: "작사 - 김수정, INNOVATOR / 작곡 SLY, 고진영",
  },
  {
    index: 20,
    title: "장성우 응원가",
    subTitle: "STRANGER BY THE DAY",
    singer: "이동호",
    madeBy: "작사 - 이동호 - 작곡 - 이동호",
  },
  {
    index: 21,
    title: "수원 kt 승리를 위해",
    subTitle: "태극기 휘날리며OST",
    singer: "",
    madeBy: "작곡 - 이동준",
  },
];

const TableContent = ({ index, title, subTitle, singer, madeBy }) => {
  const tdStyle = {
    verticalAlign: "middle",
    fontSize: "13px",
    height: "30px",
  };
  return (
    <tr style={{ borderTop: "1px solid #bdbdbd" }}>
      <td style={tdStyle}>{index}</td>
      <td style={tdStyle}>{title}</td>
      <td style={tdStyle}>{subTitle}</td>
      <td style={tdStyle}>{singer}</td>
      <td style={tdStyle}>{madeBy}</td>
    </tr>
  );
};

const SongCopyright = ({ setOpenCopyright, isMobile }) => {
  return (
    <div
      style={{
        backgroundColor: "#fff",
        marginTop: "10px",
        padding: "10px",
      }}
    >
      <table style={{ textAlign: "center", width: "100%" }}>
        <thead style={{ height: "30px" }}>
          <tr>
            <th>No</th>
            <th>응원가 곡명</th>
            <th>노래 제목</th>
            <th>가수</th>
            <th>저작자/퍼블리셔</th>
          </tr>
        </thead>
        <tbody>
          {SONG_LIST.map((song, index) => (
            <TableContent {...song} key={index} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SongCopyright;