import React from 'react'
import Link from '../common/Link'
import format from 'date-fns/format'
import Swiper from 'react-id-swiper'

const GallerySlide = ({ galleryList }) => {
  const params = {
    containerClass: 'swiper-container4',
    WrapperEl: 'ul',
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    slidesPerGroup: 1,
    slidesPerView: 1,
    spaceBetween: 0,
    breakpoints: {
      993: {
        slidesPerView: 3,
        spaceBetween: 30
      },
      769: {
        slidesPerView: 2,
        spaceBetween: 30
      }
    }
  };

  return (
    <>
      {galleryList &&
        <Swiper {...params}>
          {galleryList.map(article => (
            <li key={article.artcSeq} className="card">
              <Link to={'/media/photos/' + article.boardCatSeq}>
                <div className="img_wrap">
                  <img src={article.imgFilePath} alt="갤러리 썸네일 이미지" />
                </div>
                <div className="txt_wrap">
                  <span className="category">{article.boardCatSeq === 1 ? '경기' : article.boardCatSeq === 2 ? '훈련' : '행사'}</span>
                  <h4 className="content_tit">{article.artcTitle}</h4>
                  <span className="date">등록 일자 : {format(new Date(article.regDttm), 'yyyy년 MM월 dd일')}</span>
                </div>
              </Link>
            </li>
          ))}
        </Swiper>
      }
    </>
  )
}

export default GallerySlide
