import React from 'react'
import CheerMobileList from './CheerMobileList'

const CheerMobile = ({ cheerleaderList }) => {

  return (
    <>
      <CheerMobileList cheerleaderList={cheerleaderList} />
      <div className="page_btn_wrap"></div> {/* 여백 확보하려고 적용 */}
    </>
  )
}

export default CheerMobile
