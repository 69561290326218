import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { resourceRequest, useResource } from '../../modules/resource/resource'
import * as dialogActions from "../../modules/common/dialog"

const WizparkIntro = () => {
  const WIZPARK_INTRO_IMAGE_PC = 'WIZPARK_INTRO_PC'
  const WIZPARK_INTRO_IMAGE_M = 'WIZPARK_INTRO_M'
  const [imagePc, errorPc] = useResource(WIZPARK_INTRO_IMAGE_PC)
  const [imageM, errorM] = useResource(WIZPARK_INTRO_IMAGE_M)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(resourceRequest(WIZPARK_INTRO_IMAGE_PC))
    dispatch(resourceRequest(WIZPARK_INTRO_IMAGE_M))
  }, [])

  useEffect(() => {
    if (errorPc && errorPc.message) {
      dispatch(dialogActions.alertOpen(errorPc.message))
    }
  }, [errorPc, errorM])

  return (
    <article>
      <img className='img-whole pc' src={imagePc && imagePc.resource && imagePc.resource.value} alt="" />
      <img className='img-whole mobile' src={imageM && imageM.resource && imageM.resource.value} alt="" />
      <div className='title'>
        <h6>복합 문화공간의 첨단 야구장! 수원구장</h6>
        <div className='under-bar'></div>
      </div>
      <div className='item'>
        <div className='tit'>
          <h6>최적의 경기 환경 조성</h6>
        </div>
        <div className='txt'>
          <p>야구장의 온도와 습도, 조명을 자동 조절할 수 있는 유비쿼터스 센서 네트워크 시스템(USN)을 도입하여 선수단과 팬들에게 경기에 더욱 집중할 수 있는
              최적의 경기 환경을 제공합니다.
              </p>
        </div>
      </div>
      <div className='item'>
        <div className='tit'>
          <h6>스마트한 구장 시설</h6>
        </div>
        <div className='txt'>
          <p>구장 내 무선 인터넷(WIFI) 설치 등 ICT 인프라 확충을 통해 다양한 모바일 기기를 활용하여 입장 등록, 실시간 주차 정보, 지정석 찾기, 음식
              주문 등 다른 경기장에서 경험하지 못했던
              스마트한 콘텐츠를 이용하실 수 있습니다.

              </p>
        </div>
      </div>
      <div className='item'>
        <div className='tit'>
          <h6>다양한 관람층을 위한 복합 문화공간</h6>
        </div>
        <div className='txt'>
          <p>익사이팅석, 프랜들리서, 커플석, 패밀리석, 장애인석 등 다양한 관람층을 고려하여 완벽한 관람시설을 제공하고, 각종 센서와 모바일 기기가 설치된 체험존
              등을 제공하여 경기 관람 뿐
              아니라 오락, 레저, 교육의 복합 문화공간을 선보입니다.
          </p>
        </div>
      </div>
    </article>
  )
}

export default WizparkIntro
