import React from 'react'
import ContentTemplate from '../../components/common/ContentTemplate'
import SubContentTemplate from '../../components/common/SubContentTemplate'
import PageHeaderContainer from '../../containers/common/PageHeaderContainer'
import PageTitleTemplate from '../../components/common/PageTitleTemplate'
import BreadcrumbContainer from '../../containers/common/BreadcrumbContainer'
import PageTemplate from '../../components/common/PageTemplate'
import { WIZPARK_INTRO, WIZPARK_INTRO_TAB } from '../../constants/page'
import WizparkIntro from '../../components/wizpark/WizparkIntro'

const WizparkIntroPage = ({ match }) => {
  return (
    <PageTemplate className="park">
      <PageHeaderContainer title="Suwon kt wiz park" subtitle="suwon kt wiz park를 소개합니다." image={require('../../assets/img/park/sub_tit_park.png')} forwardTabs={WIZPARK_INTRO_TAB} currentPath={match.path} />
      <ContentTemplate>
        <PageTitleTemplate>
          <BreadcrumbContainer page={WIZPARK_INTRO} />
        </PageTitleTemplate>
        <SubContentTemplate className="park wizpark intro">
          <WizparkIntro />
        </SubContentTemplate>
      </ContentTemplate>
    </PageTemplate>
  )
}

export default WizparkIntroPage
