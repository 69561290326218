export const SET_LOADING = 'loading/SET_LOADING'

export const setLoading = isLoading => ({ type: SET_LOADING, isLoading })

const initialState = {
  isLoading: false
}

const loading = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        isLoading: action.isLoading
      }
    default:
      return state
  }
}

export default loading
