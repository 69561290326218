import React from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'

export default function Link(props) {
  const { to, onClick, isExt = false, ...rest } = props

  /* onClick 이벤트 존재할 때에는 a태그 사용 */
  if (onClick) {
    return (
      <a
        href='#'
        onClick={e => {
          e.preventDefault()
          onClick(e)
        }}
        {...rest}
      >
        {props.children}
      </a>
    )
  }
  /* isExt === true 일 때, a태그 사용하여 외부링크 */
  else if (isExt) {

    return (
      <a
        href={to || '#'}
        target={'_blank'}
        {...rest}
      >
        {props.children}
      </a>

    )
  }
  /* react-router-dom의 Link태그 사용 */
  else {
    return <RouterLink to={to || '#'} {...rest} />
  }
}

Link.propTypes = {
  to: PropTypes.string,
  onClick: PropTypes.func,
}
