import React from 'react'
import DatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

import ko from 'date-fns/locale/ko';
registerLocale('ko', ko)

const DatePickerWrap = ({ name, selected, handleChange }) => {

  return (
    <DatePicker name={name} id={name} dateFormat="yyyy-MM-dd" locale="ko" showMonthDropdown showYearDropdown dropdownMode="select" selected={selected} onChange={date => handleChange(name, date)} autoComplete='off' />
  )
}

export default DatePickerWrap
