import React from 'react'
import GraphWrap from './GraphWrap'
import numeral from 'numeral'

const TeamRanking = ({ match, year, teamRankingList, teamPitchingList, teamBattingList, teamWinLoseList, periodTeamRankList }) => {

  const teamCodeNames = teamWinLoseList && teamWinLoseList.reduce(function (acc, cur, i) {
    acc[cur.teamCode] = cur.teamName
    return acc
  }, {})

  const teamWinLoses = teamWinLoseList && teamWinLoseList.reduce(function (acc, cur, i) {
    acc[cur.teamCode + cur.vsTeamCode + '_WIN'] = cur.win
    acc[cur.teamCode + cur.vsTeamCode + '_LOSE'] = cur.lose
    acc[cur.teamCode + cur.vsTeamCode + '_DRAWN'] = cur.drawn
    return acc
  }, {})

  console.log(teamWinLoses)

  console.log(teamCodeNames)

  const vs = (teamCode, vsTeamCode) => {
    return teamWinLoses && ((teamWinLoses[teamCode + vsTeamCode + '_WIN'] || '0') + '-' + (teamWinLoses[teamCode + vsTeamCode + '_LOSE'] || '0') + '-' + (teamWinLoses[teamCode + vsTeamCode + '_DRAWN'] || '0'))
  }

  const TeamRankingRow = ({ team, index }) => {
    console.log(team)
    return (
      <tr className={team.teamCode === 'KT' ? 'kt' : ''}>
        <td className="mb_hide">{team.rank}</td>
        <td className="mb_hide">{team.teamName}</td>
        <td>{team.game}</td>
        <td>{team.win}</td>
        <td>{team.lose}</td>
        <td>{team.drawn}</td>
        <td>{team.wra}</td>
        {/* <td>{numberFormat.format(team.ab)}</td> */}
        <td>{numeral(team.ab).format('0,0')}</td>
        <td>{team.continue1}</td>
        <td>{team.bra}</td>
        <td>{team.lra}</td>
        <td>{team.hra}</td>
        <td>{team.er}</td>
        <td>{team.run}</td>
        <td>{team.r}</td>
        <td>{team.hr}</td>
      </tr>
    )
  }

  const TeamPitchingRow = ({ team, index }) => {
    return (
      <tr className={team.teamCode === 'KT' ? 'kt' : ''}>
        <td className="mb_hide">{team.teamName}</td>
        <td>{team.sh}</td>
        <td>{team.sf}</td>
        <td>{team.bb}</td>
        <td>{team.ib}</td>
        <td>{team.bbhp}</td>
        <td>{team.kk}</td>
        <td>{team.wp}</td>
        <td>{team.bk}</td>
        <td>{team.r}</td>
        <td>{team.er}</td>
        <td>{team.bs}</td>
        <td>{team.whip}</td>
        <td>{team.oavg}</td>
        <td>{team.qs}</td>
      </tr>
    )
  }

  const TeamBattingRow = ({ team, index }) => {
    return (
      <tr className={team.teamCode === 'KT' ? 'kt' : ''}>
        <td className="mb_hide">{team.teamName}</td>
        <td>{team.hit}</td>
        <td>{team.h2}</td>
        <td>{team.h3}</td>
        <td>{team.hr}</td>
        <td>{team.rbi}</td>
        <td>{team.sb}</td>
        <td>{team.bb}</td>
        <td>{team.ib}</td>
        <td>{team.hp}</td>
        <td>{team.kk}</td>
        <td>{team.gd}</td>
        <td>{team.slg}</td>
        <td>{team.bra}</td>
        <td>{team.err}</td>
        <td>{team.ops}</td>
        <td>{team.hra}</td>
      </tr>
    )
  }

  return (
    <div className="rank_record_wrap team_rank">

      <div className="season_team_rank">
        <article>
          <h4>{teamRankingList && teamRankingList[0].gyear} 시즌 팀 순위</h4>
          <GraphWrap match={match} xAxisList={periodTeamRankList && periodTeamRankList.map(team => team.date.substring(4, 6) + '.' + team.date.substring(6, 8))} valueList={periodTeamRankList && periodTeamRankList.map(team => team.rank)} />
        </article>
      </div>

      <div className="season_team_record">
        <article>
          <h4>{teamRankingList && teamRankingList[0].gyear} 시즌 팀 기록</h4>
          <div className="fixed_table_content">
            <div className="table_wrap fixed">
              <table>
                <caption>{teamRankingList && teamRankingList[0].gyear} 시즌 팀 기록</caption>
                <colgroup>
                  <col className="mb_hide" width="65px" />
                  <col className="mb_hide" width="*" />
                  <col width="68px" />
                  <col width="68px" />
                  <col width="68px" />
                  <col width="68px" />
                  <col width="70px" />
                  <col width="70px" />
                  <col width="68px" />
                  <col width="70px" />
                  <col width="70px" />
                  <col width="70px" />
                  <col width="70px" />
                  <col width="68px" />
                  <col width="68px" />
                  <col width="68px" />
                </colgroup>
                <thead>
                  <tr>
                    <th className="mb_hide">순위</th>
                    <th className="mb_hide">팀명</th>
                    <th>경기 수</th>
                    <th>승</th>
                    <th>패</th>
                    <th>무</th>
                    <th>승률</th>
                    <th>타수</th>
                    <th>연속</th>
                    <th>출루율</th>
                    <th>장타율</th>
                    <th>타율</th>
                    <th>자책점</th>
                    <th>득점</th>
                    <th>실점</th>
                    <th>홈런</th>
                  </tr>
                </thead>
                <tbody>
                  {teamRankingList && teamRankingList.map((team, index) => <TeamRankingRow key={index} team={team} index={index} />)}
                </tbody>
              </table>
              <table className="fixed_table">
                <colgroup>
                  <col width="42px" />
                  <col width="*" />
                </colgroup>
                <thead>
                  <tr>
                    <th>순위</th>
                    <th>팀명</th>
                  </tr>
                </thead>
                <tbody>
                  {teamRankingList && teamRankingList.map((team, index) => (
                    <tr className={team.teamCode === 'KT' ? 'kt' : ''}>
                      <td>{team.rank}</td>
                      <td>{team.teamName}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </article>
      </div>

      <div className="season_team_record pitcher">
        <article>
          <h4>{teamRankingList && teamRankingList[0].gyear} 시즌 팀 투수 기록</h4>
          <div className="fixed_table_content">
            <div className="table_wrap fixed">
              <table>
                <caption>{teamRankingList && teamRankingList[0].gyear} 시즌 팀 투수 기록</caption>
                <colgroup>
                  <col className="mb_hide" width="70px" />
                  <col width="65px" />
                  <col width="65px" />
                  <col width="65px" />
                  <col width="80px" />
                  <col width="65px" />
                  <col width="80px" />
                  <col width="70px" />
                  <col width="70px" />
                  <col width="70px" />
                  <col width="80px" />
                  <col width="*" />
                  <col width="80px" />
                  <col width="80px" />
                  <col width="65px" />
                </colgroup>
                <thead>
                  <tr>
                    <th className="mb_hide">팀명</th>
                    <th>희타</th>
                    <th>희비</th>
                    <th>볼넷</th>
                    <th>고의4구</th>
                    <th>사구</th>
                    <th>탈삼진</th>
                    <th>폭투</th>
                    <th>보크</th>
                    <th>실점</th>
                    <th>자책점</th>
                    <th>블론세이브</th>
                    <th>WHIP</th>
                    <th>피안타율</th>
                    <th>QS</th>
                  </tr>
                </thead>
                <tbody>
                  {teamPitchingList && teamPitchingList.map((team, index) => <TeamPitchingRow key={index} team={team} index={index} />)}
                </tbody>
              </table>
              <table className="fixed_table">
                <thead>
                  <tr>
                    <th>팀명</th>
                  </tr>
                </thead>
                <tbody>
                  {teamPitchingList && teamPitchingList.map((team, index) => (
                    <tr key={team.teamCode} className={team.teamCode === 'KT' ? 'kt' : ''} >
                      <td>{team.teamName}</td>
                    </tr>)
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </article>
      </div>

      <div className="season_team_record catcher">
        <article>
          <h4>{teamRankingList && teamRankingList[0].gyear} 시즌 팀 타자 기록</h4>
          <div className="fixed_table_content">
            <div className="table_wrap fixed">
              <table>
                <caption>{teamRankingList && teamRankingList[0].gyear} 시즌 팀 타자 기록</caption>
                <colgroup>
                  <col className="mb_hide" width="70px" />
                  <col width="62px" />
                  <col width="65px" />
                  <col width="65px" />
                  <col width="60px" />
                  <col width="60px" />
                  <col width="60px" />
                  <col width="60px" />
                  <col width="*" />
                  <col width="62px" />
                  <col width="62px" />
                  <col width="62px" />
                  <col width="72px" />
                  <col width="72px" />
                  <col width="60px" />
                  <col width="62px" />
                  <col width="72px" />
                </colgroup>
                <thead>
                  <tr>
                    <th className="mb_hide">팀명</th>
                    <th>안타</th>
                    <th>2루타</th>
                    <th>3루타</th>
                    <th>홈런</th>
                    <th>타점</th>
                    <th>도루</th>
                    <th>볼넷</th>
                    <th>고의4구</th>
                    <th>사구</th>
                    <th>삼진</th>
                    <th>병살</th>
                    <th>장타율</th>
                    <th>출루율</th>
                    <th>실책</th>
                    <th>OPS</th>
                    <th>타율</th>
                  </tr>
                </thead>
                <tbody>
                  {teamBattingList && teamBattingList.map((team, index) => <TeamBattingRow key={index} team={team} index={index} />)}
                </tbody>
              </table>
              <table className="fixed_table">
                <thead>
                  <tr>
                    <th>팀명</th>
                  </tr>
                </thead>
                <tbody>
                  {teamBattingList && teamBattingList.map((team, index) => (
                    <tr key={team.teamCode} className={team.teamCode === 'KT' ? 'kt' : ''} >
                      <td>{team.teamName}</td>
                    </tr>)
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </article>
      </div>

      <div className="season_team_winlose">
        <article>
          <h4>{teamRankingList && teamRankingList[0].gyear} 시즌 팀 간 승패표</h4>
          <div className="fixed_table_content">
            <div className="table_wrap fixed">
              <table>
                <caption>{teamRankingList && teamRankingList[0].gyear} 시즌 팀 간 승패표</caption>
                <colgroup>
                  <col className="mb_hide" width="*" />
                  <col width="100px" />
                  <col width="100px" />
                  <col width="100px" />
                  <col width="100px" />
                  <col width="100px" />
                  <col width="100px" />
                  <col width="100px" />
                  <col width="100px" />
                  <col width="100px" />
                  <col width="100px" />
                </colgroup>
                <thead>
                  <tr>
                    <th className="mb_hide">팀명</th>
                    <th className="kt">{teamCodeNames && teamCodeNames['KT']}<br />(승-패-무)</th>
                    <th>{teamCodeNames && teamCodeNames['SS']}<br />(승-패-무)</th>
                    <th>{teamCodeNames && teamCodeNames['OB']}<br />(승-패-무)</th>
                    <th>{teamCodeNames && teamCodeNames['LG']}<br />(승-패-무)</th>
                    <th>{teamCodeNames && teamCodeNames['WO']}<br />(승-패-무)</th>
                    <th>{teamCodeNames && teamCodeNames['LT']}<br />(승-패-무)</th>
                    <th>{teamCodeNames && teamCodeNames['SK']}<br />(승-패-무)</th>
                    <th>{teamCodeNames && teamCodeNames['NC']}<br />(승-패-무)</th>
                    <th>{teamCodeNames && teamCodeNames['HT']}<br />(승-패-무)</th>
                    <th>{teamCodeNames && teamCodeNames['HH']}<br />(승-패-무)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="kt">
                    <td className="mb_hide">KT</td>
                    <td className="kt">■</td>
                    <td>{vs('KT', 'SS')}</td>
                    <td>{vs('KT', 'OB')}</td>
                    <td>{vs('KT', 'LG')}</td>
                    <td>{vs('KT', 'WO')}</td>
                    <td>{vs('KT', 'LT')}</td>
                    <td>{vs('KT', 'SK')}</td>
                    <td>{vs('KT', 'NC')}</td>
                    <td>{vs('KT', 'HT')}</td>
                    <td>{vs('KT', 'HH')}</td>
                  </tr>
                  <tr>
                    <td className="mb_hide">삼성</td>
                    <td className="kt">{vs('SS', 'KT')}</td>
                    <td>■</td>
                    <td>{vs('SS', 'OB')}</td>
                    <td>{vs('SS', 'LG')}</td>
                    <td>{vs('SS', 'WO')}</td>
                    <td>{vs('SS', 'LT')}</td>
                    <td>{vs('SS', 'SK')}</td>
                    <td>{vs('SS', 'NC')}</td>
                    <td>{vs('SS', 'HT')}</td>
                    <td>{vs('SS', 'HH')}</td>
                  </tr>
                  <tr>
                    <td className="mb_hide">두산</td>
                    <td className="kt">{vs('OB', 'KT')}</td>
                    <td>{vs('OB', 'SS')}</td>
                    <td>■</td>
                    <td>{vs('OB', 'LG')}</td>
                    <td>{vs('OB', 'WO')}</td>
                    <td>{vs('OB', 'LT')}</td>
                    <td>{vs('OB', 'SK')}</td>
                    <td>{vs('OB', 'NC')}</td>
                    <td>{vs('OB', 'HT')}</td>
                    <td>{vs('OB', 'HH')}</td>
                  </tr>
                  <tr>
                    <td className="mb_hide">LG</td>
                    <td className="kt">{vs('LG', 'KT')}</td>
                    <td>{vs('LG', 'SS')}</td>
                    <td>{vs('LG', 'OB')}</td>
                    <td>■</td>
                    <td>{vs('LG', 'WO')}</td>
                    <td>{vs('LG', 'LT')}</td>
                    <td>{vs('LG', 'SK')}</td>
                    <td>{vs('LG', 'NC')}</td>
                    <td>{vs('LG', 'HT')}</td>
                    <td>{vs('LG', 'HH')}</td>
                  </tr>
                  <tr>
                    <td className="mb_hide">키움</td>
                    <td className="kt">{vs('WO', 'KT')}</td>
                    <td>{vs('WO', 'SS')}</td>
                    <td>{vs('WO', 'OB')}</td>
                    <td>{vs('WO', 'LG')}</td>
                    <td>■</td>
                    <td>{vs('WO', 'LT')}</td>
                    <td>{vs('WO', 'SK')}</td>
                    <td>{vs('WO', 'NC')}</td>
                    <td>{vs('WO', 'HT')}</td>
                    <td>{vs('WO', 'HH')}</td>
                  </tr>
                  <tr>
                    <td className="mb_hide">롯데</td>
                    <td className="kt">{vs('LT', 'KT')}</td>
                    <td>{vs('LT', 'SS')}</td>
                    <td>{vs('LT', 'OB')}</td>
                    <td>{vs('LT', 'LG')}</td>
                    <td>{vs('LT', 'WO')}</td>
                    <td>■</td>
                    <td>{vs('LT', 'SK')}</td>
                    <td>{vs('LT', 'NC')}</td>
                    <td>{vs('LT', 'HT')}</td>
                    <td>{vs('LT', 'HH')}</td>
                  </tr>
                  <tr>
                    <td className="mb_hide">SSG</td>
                    <td className="kt">{vs('SK', 'KT')}</td>
                    <td>{vs('SK', 'SS')}</td>
                    <td>{vs('SK', 'OB')}</td>
                    <td>{vs('SK', 'LG')}</td>
                    <td>{vs('SK', 'WO')}</td>
                    <td>{vs('SK', 'LT')}</td>
                    <td>■</td>
                    <td>{vs('SK', 'NC')}</td>
                    <td>{vs('SK', 'HT')}</td>
                    <td>{vs('SK', 'HH')}</td>
                  </tr>
                  <tr>
                    <td className="mb_hide">NC</td>
                    <td className="kt">{vs('NC', 'KT')}</td>
                    <td>{vs('NC', 'SS')}</td>
                    <td>{vs('NC', 'OB')}</td>
                    <td>{vs('NC', 'LG')}</td>
                    <td>{vs('NC', 'WO')}</td>
                    <td>{vs('NC', 'LT')}</td>
                    <td>{vs('NC', 'LT')}</td>
                    <td>■</td>
                    <td>{vs('NC', 'HT')}</td>
                    <td>{vs('NC', 'HH')}</td>
                  </tr>
                  <tr>
                    <td className="mb_hide">KIA</td>
                    <td className="kt">{vs('HT', 'KT')}</td>
                    <td>{vs('HT', 'SS')}</td>
                    <td>{vs('HT', 'OB')}</td>
                    <td>{vs('HT', 'LG')}</td>
                    <td>{vs('HT', 'WO')}</td>
                    <td>{vs('HT', 'LT')}</td>
                    <td>{vs('HT', 'LT')}</td>
                    <td>{vs('HT', 'NC')}</td>
                    <td>■</td>
                    <td>{vs('HT', 'HH')}</td>
                  </tr>
                  <tr>
                    <td className="mb_hide">한화</td>
                    <td className="kt">{vs('HH', 'KT')}</td>
                    <td>{vs('HH', 'SS')}</td>
                    <td>{vs('HH', 'OB')}</td>
                    <td>{vs('HH', 'LG')}</td>
                    <td>{vs('HH', 'WO')}</td>
                    <td>{vs('HH', 'LT')}</td>
                    <td>{vs('HH', 'LT')}</td>
                    <td>{vs('HH', 'NC')}</td>
                    <td>{vs('HH', 'HT')}</td>
                    <td>■</td>
                  </tr>
                </tbody>
              </table>
              <table className="fixed_table">
                <thead>
                  <tr>
                    <th>팀명</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="kt">
                    <td>KT</td>
                  </tr>
                  <tr>
                    <td>삼성</td>
                  </tr>
                  <tr>
                    <td>두산</td>
                  </tr>
                  <tr>
                    <td>LG</td>
                  </tr>
                  <tr>
                    <td>키움</td>
                  </tr>
                  <tr>
                    <td>롯데</td>
                  </tr>
                  <tr>
                    <td>SK</td>
                  </tr>
                  <tr>
                    <td>NC</td>
                  </tr>
                  <tr>
                    <td>KIA</td>
                  </tr>
                  <tr>
                    <td>한화</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </article>
      </div>
    </div >
  )
}

export default TeamRanking
