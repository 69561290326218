import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { resourceRequest, useResource } from '../../modules/resource/resource'
import * as dialogActions from "../../modules/common/dialog"

const IksanStadium = () => {
  const IKSAN_IMAGE_PC = 'IKSAN_IMAGE_PC'
  const IKSAN_IMAGE_M = 'IKSAN_IMAGE_M'
  const IKSAN_MAP_IMAGE_PC = 'IKSAN_MAP_IMAGE_PC'
  const IKSAN_MAP_IMAGE_M = 'IKSAN_MAP_IMAGE_M'
  const [imagePc, errorPc] = useResource(IKSAN_IMAGE_PC)
  const [imageM, errorM] = useResource(IKSAN_IMAGE_M)
  const [mapImagePc, mapErrorPc] = useResource(IKSAN_MAP_IMAGE_PC)
  const [mapImageM, mapErrorM] = useResource(IKSAN_MAP_IMAGE_M)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(resourceRequest(IKSAN_IMAGE_PC))
    dispatch(resourceRequest(IKSAN_IMAGE_M))
    dispatch(resourceRequest(IKSAN_MAP_IMAGE_PC))
    dispatch(resourceRequest(IKSAN_MAP_IMAGE_M))
  }, [])

  useEffect(() => {
    if (errorPc && errorPc.message) {
      dispatch(dialogActions.alertOpen(errorPc.message))
    }
    if (errorM && errorM.message) {
      dispatch(dialogActions.alertOpen(errorM.message))
    }
    if (mapErrorPc && mapErrorPc.message) {
      dispatch(dialogActions.alertOpen(mapErrorPc.message))
    }
    if (mapErrorM && mapErrorM.message) {
      dispatch(dialogActions.alertOpen(mapErrorM.message))
    }
  }, [errorPc, errorM, mapErrorPc, mapErrorM])

  return (
    <>
      <article className='arti'>
        <div className='tit'>
          <h6>퓨처스리그가 펼쳐지는 익산 야구장</h6>
        </div>
        <div className='desc'>
          <div className='img-wrap'>
            <img className='pc' src={imagePc && imagePc.resource && imagePc.resource.value} />
            <img className='m' src={imageM && imageM.resource && imageM.resource.value} />
          </div>
          <div className='t'>
            <p className='pc'>
              <strong>규격</strong> : 좌우 98m, 중앙 121m /
                <strong>관람석</strong> : 740석 / <strong>본부석</strong> : 덕아웃 /
                <strong>전광판</strong> : 전자식 / <strong>펜스</strong> : 고정식 /
                <strong>건립</strong> : 2011년 6월 / <strong>그라운드</strong> : 인조잔디
            </p>
            <p className='m'>
              <strong>규격</strong> : 좌우 98m, 중앙 121m / <br />
              <strong>관람석</strong> : 740석 / <strong>본부석</strong> : 덕아웃 / <br />
              <strong>전광판</strong> : 전자식 / <strong>펜스</strong> : 고정식 / <br />
              <strong>건립</strong> : 2011년 6월 / <strong>그라운드</strong> : 인조잔디 <br />
            </p>
          </div>
        </div>
      </article>
      <article className='arti'>
        <div className='tit'>
          <h6>오시는 길</h6>
        </div>
        <div className='desc'>
          <div className='img-wrap'>
            <img className='pc' src={mapImagePc && mapImagePc.resource && mapImagePc.resource.value} />
            <img className='m' src={mapImageM && mapImageM.resource && mapImageM.resource.value} />
          </div>
          <table className='guide'>
            <tbody>
              <tr>
                <th>
                  <img src={require('../../assets/img/park/map_pin.png')} alt="" />
                  <span className='txt'>
                    주소
                        </span>
                </th>
                <td>
                  <ul>
                    <li className='first'>
                      <p>
                        <span>주소 : 전라북도 익산시 무왕로 1397 익산야구장&nbsp;</span>
                        <span>(구 : 전라북도 익산시 부송동 180번지)</span>
                      </p>
                    </li>
                    <li>
                      <a href='http://naver.me/xnGHLo6Q' target='_blank' className='btn_location'>찾아오기</a>
                      <span className='notice'>※ 찾아오기 버튼을 누르시면, 출발지에서 야구장으로 가는 길을 쉽게 확인하실 수 있습니다.</span>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <th>
                  <img src={require('../../assets/img/park/map_bus.png')} alt="" />
                  <span className='txt'>
                    버스
                        </span>
                </th>
                <td className='bus'>
                  <div className='r-wrap'>
                    <div className='r'>
                      <div className='c first'>
                        <h6>
                          익산공설운동장 하차
                                    </h6>
                        <div className='line'>
                          <span className='chip green'>일반</span>
                          <p>
                            102-1, 102-2, 103, 103-1
                                        </p>
                        </div>
                      </div>
                      <div className='c'>
                        <h6>
                          종합경기장.파출소옆 하차
                                    </h6>
                        <div className='line'>
                          <span className='chip green'>일반</span>
                          <p>
                            1, 40, 58, 59, 59-1, 60, 60-1, 60-2, 60-3, 61, 62, 63, 63-1, 64, 65, 65-1, 102-1, 102-2, 103, 105
                          </p>
                        </div>
                        <div className='line'>
                          <span className='chip blue'>좌석</span>
                          <p>
                            222, 222-1 , 222-2, 222-3, 555
                                        </p>
                        </div>
                      </div>
                    </div>
                    <div className='r'>
                      <div className='c first'>
                        <h6>
                          이리팔봉초등학교 하차
                                    </h6>
                        <div className='line'>
                          <span className='chip green'>일반</span>
                          <p>
                            40, 55, 56, 58, 102-2, 103
                                        </p>
                        </div>
                      </div>
                      <div className='c'>
                        <h6>
                          팔봉동사무소 하차
                                    </h6>
                        <div className='line'>
                          <span className='chip green'>일반</span>
                          <p>
                            55, 56, 58, 59, 60, 60-1, 60-2, 60-3, 61, 62, 63, 63-1, 64, 65, 65-1, 103
                          </p>
                        </div>
                        <div className='line'>
                          <span className='chip blue'>좌석</span>
                          <p>
                            222, 222-1 , 222-2, 222-3, 555
                                        </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </article>
    </>
  )
}

export default IksanStadium
