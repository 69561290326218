// action types
// login
export const LOGIN_REQUEST = "login/LOGIN_REQUEST";
export const LOGIN_SUCCESS = "login/LOGIN_SUCCESS";
export const LOGIN_FAIL = "login/LOGIN_FAIL";
// logout
export const LOGOUT_REQUEST = "logout/LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "logout/LOGOUT_SUCCESS";
export const LOGOUT_FAIL = "logout/LOGOUT_FAIL";
//dormant status
export const INIT_CERT_STATUS = "login/INIT_CERT_STATUS";

// action creators
// login
export const loginRequest = (user, returnObj) => ({
  type: LOGIN_REQUEST,
  user,
  returnObj,
});
export const loginSuccess = (user, active = false) => ({
  type: LOGIN_SUCCESS,
  user,
  active,
});
export const loginFail = (certStatus = { ...initialState.certStatus }) => ({
  type: LOGIN_FAIL,
  certStatus,
});
// logout
export const logoutRequest = (user) => ({ type: LOGOUT_REQUEST, user });
export const logoutSuccess = () => ({ type: LOGOUT_SUCCESS });
export const logoutFail = () => ({ type: LOGOUT_FAIL });
//dormant status
export const initCertStatus = (certStatus) => ({
  type: INIT_CERT_STATUS,
});

// reducers
const initialState = {
  isLoggingIn: false,
  isLoggedIn:
    sessionStorage.getItem("sessionUser") !== "null" &&
    sessionStorage.getItem("sessionUser") !== null
      ? true
      : false,
  user:
    sessionStorage.getItem("sessionUser") &&
    JSON.parse(sessionStorage.getItem("sessionUser")),
  certStatus: {
    kind: "",
    active: false,
  },
};

const login = (state = initialState, action) => {
  var date = new Date();
  //var domain = '.ktwiz.co.kr';
  var domain = process.env.REACT_APP_DOMAIN;
  switch (action.type) {
    case LOGIN_REQUEST:
      console.log("domain=" + domain);
      return {
        ...state,
        isLoggingIn: true,
        isLoggedIn: false,
        isDormant: false,
        user: action.user,
      };
    case LOGIN_SUCCESS:
      date.setTime(date.getTime() + 365 * 60 * 60 * 24 * 1000);
      //document.cookie = 'tid=' + action.user.tid + ';Expires=' + date.toUTCString() + ';Domain=.ktwiz.co.kr;Path=/';
      // document.cookie = 'tid=' + action.user.tid + ';expires=' + date.toUTCString() + '; domain=' + domain;
      document.cookie = "tid=" + action.user.tid + ";domain=" + domain;
      console.log("tid = " + action.user.tid + ", domain = " + domain);

      sessionStorage.setItem("sessionUser", JSON.stringify(action.user)); // 로그인 성공시 세션 저장
      return {
        ...state,
        isLoggingIn: false,
        isLoggedIn: true,
        certStatus: { ...initialState.certStatus, active: action.active },
        user: action.user,
      };
    case LOGIN_FAIL:
      sessionStorage.setItem("sessionUser", null); // 로그인 실패시 세션 제거
      return {
        ...state,
        isLoggingIn: false,
        isLoggedIn: false,
        user: null,
        certStatus: action.certStatus,
      };
    case LOGOUT_REQUEST:
      return {
        ...state,
        isLoggingIn: false,
        isLoggedIn: false,
        user: action.user,
      };
    case LOGOUT_SUCCESS:
      date.setDate(date.getDate() - 1); // 세션 만료를 위한 시간 조정
      document.cookie =
        "tid=; expires=" + date.toUTCString() + "; domain=" + domain;
      sessionStorage.setItem("sessionUser", null); // 로그인 실패시 세션 제거
      return {
        ...state,
        isLoggingIn: false,
        isLoggedIn: false,
        user: null,
      };
    case LOGOUT_FAIL:
      return {
        ...state,
        user: null,
      };
    case INIT_CERT_STATUS:
      return {
        ...state,
        certStatus: { ...initialState.certStatus },
      };
    default:
      return state;
  }
};

export default login;
