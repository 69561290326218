import { put, takeEvery, all, call, fork } from "redux-saga/effects";
import { push } from "connected-react-router";
import { callLogin, callLogout } from "../../api/auth/login";
// actions
import * as loginActions from "../../modules/auth/login";
import * as dialogActions from "../../modules/common/dialog";
import { LOGIN_FAIL_TYPE } from "../../constants/enum";

/*
    로그인
*/
function* login({ user, returnObj }) {
  const result = yield call(callLogin, user);

  // error 에러코드 반환 시
  if (result.error) {
    const { error } = result;
    if (error.status === 2016 || error.status === 2019) {
      const { status } = error;
      const certPageType =
        status === 2016 ? LOGIN_FAIL_TYPE.DORMANT : LOGIN_FAIL_TYPE.NORMAL;

      const certStatus = { kind: certPageType, active: true };
      yield put(loginActions.loginFail({ ...certStatus }));
    } else {
      yield put(dialogActions.alertOpen(error.message));
      yield put(loginActions.loginFail());
    }
  }
  // user 정보가 있을 때
  else if (result.user) {
    const { returnUrl, isOutLink } = returnObj;
    // yield put(push('/')) // TODO: 추후 요청한 페이지로 이동하도록 변경

    if (isOutLink === "true") {
      yield put(loginActions.loginSuccess(result.user));
      
      if (returnUrl) {
        isOutLink === "true"
        ? (window.location.href = `https://${returnUrl}`)
        : yield put(push(returnUrl));
      } else {
        yield put(push("/"));
      }
    } else if (isOutLink === "self") {
      yield put(loginActions.loginSuccess(result.user, true));
    } else {
      yield put(loginActions.loginSuccess(result.user));
      
      returnUrl ? yield put(push(returnUrl)) : yield put(push("/"));
      
    }

    // if (isOutLink) {
    //   if (isOutLink !== "self") {
    //     if (returnUrl) {
    //       window.location.href = `https://${returnUrl}`;
    //     } else {
    //     }
    //   }
    // } else {
    //   if (returnUrl) {
    //     yield put(push(returnUrl)); // 이전 페이지로 이동
    //   } else {
    //     yield put(push("/"));
    //   }
    // }
  }
}

function* watchLogin() {
  yield takeEvery(loginActions.LOGIN_REQUEST, login);
}

/*
    로그아웃
*/
function* logout({ user }) {
  const result = yield call(callLogout, user);

  if (result) {
    yield put(loginActions.logoutSuccess());
    yield put(push("/"));
  } else {
    yield put(loginActions.logoutFail());
  }
}

function* watchLogout() {
  yield takeEvery(loginActions.LOGOUT_REQUEST, logout);
}

export default function* loginSaga() {
  yield all([fork(watchLogin), fork(watchLogout)]);
}
