import React from 'react'
import Link from './Link'

const SubContentTemplate = ({ children, className, tabs }) => {

  const Tabs = ({ tabs }) => {
    return (
      <div class="lnb_dep2_wrap">
        <ul>
          {tabs.map((tab, index) => (
            <li class={'dep4 ' + (index === 0 ? ' first' : '') + (tab.isActive ? ' active' : '')} key={index}>
              <Link to={tab.path} class="tab_team_rank">{tab.title}</Link>
            </li>
          ))}
        </ul>
      </div >
    )
  }

  return (
    <>
      {tabs && <Tabs tabs={tabs} />}
      <section className={"sub_content_wrap " + className}>
        {children}
      </section>
    </>
  )
}

export default SubContentTemplate
